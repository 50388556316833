import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "./index.css";

type Props = {
  /** Help text content */
  content?: string | null;
  open: boolean;
  /** Dialog title (optional) */
  title?: string;
  onClose: () => void;
};

const HelpGuideModal = (props: Props): JSX.Element => {
  const { content, open, title, onClose } = props;

  const { t } = useTranslation(["common", "screens"]);

  const missingHelpTextPrompt = t("screens:helpGuide.guideNotAvailable");

  return (
    <Modal
      centered
      className="nb-modal"
      isOpen={open}
      size="md"
      toggle={onClose}>
      <ModalHeader className="help-guide__modal__header">
        {title || "NB Orbit Guide"}
      </ModalHeader>
      <ModalBody>{content || missingHelpTextPrompt}</ModalBody>
      <ModalFooter className="help-guide__modal__footer">
        <button
          type="button"
          className="btn btn-primary rounded-pill"
          onClick={onClose}>
          {t("common:actions.close")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default HelpGuideModal;
