import _ from 'lodash';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginAction } from '../../Login/actions/loginAction';
import { Chart } from "react-google-charts";
import { dashboardAction } from '../actions/dashboardAction';
import { history } from '../../..';
import moment from 'moment';
import { t } from 'i18next';

// Utilities
import images from 'src/utils/images';

export interface IOrgAdminDashboardProps {
  loginAction: any,
  LoginReducer: any,
  dashboardAction: any,
  DashboardReducer: any,
}

export interface IOrgAdminDashboardState {
  dateData: any,
  selectStartDate: any,
  selectEndDate: any,
}

class OrgAdminDashboard extends React.Component<IOrgAdminDashboardProps, IOrgAdminDashboardState> {
  constructor(props: IOrgAdminDashboardProps) {
    super(props);
    this.state = {
      dateData: {},
      selectStartDate: false,
      selectEndDate: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.ViewIssuedKYC = this.ViewIssuedKYC.bind(this);
  }

  /**
   * Lifecycle Method
   */
  componentDidMount() {
    this.props.dashboardAction.getAllCountforDashboard();
    this.props.loginAction.getCurrentUser();
    this.props.dashboardAction.getAllCountforPADashboard();
  }

  /**
   * Method to redirect route for issue kyc credential screen
   */
  ViewIssuedKYC() {
    history.push('/issue-kyc-credentials')
  }

  handleChange(event: any) {
    const { dateData } = this.state;
    let fields = dateData;
    let selectStartDate = false;
    let selectEndDate = false;
    fields[event.target.name] = event.target.value;
    this.setState({
      dateData: fields,
    });

    this.props.dashboardAction.getAllCountforDashboard();
  }

  public render() {
    
    // Destructure all the required fields.
    const { countForDashboard } = this.props.DashboardReducer;
    const { kycIssuedList, kycWaitingList } = countForDashboard[6] ? countForDashboard[6] : []
    const { loggedInUser } = this.props.LoginReducer;
    const { dateData, selectStartDate, selectEndDate } = this.state;

    let speedometerCount: any = !_.isEmpty(countForDashboard) && parseInt(countForDashboard[2].verificationPercentage)
    let finalvalue: any = Math.round((180 / 100) * speedometerCount);
    let credentialPercentage: any = !_.isEmpty(countForDashboard) && countForDashboard[1].credentialPercentage;

    const minKYCRecordDisplay = 1

    return (
      <>
        <div className="row">
          <div className="col-sm-8"></div>
          {/* <div className="col-sm-4">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="startDate"
                placeholder="select"
                value={dateData.startDate !== '' ? dateData.startDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-prepend">
                <label className="input-group-text bg-white border-left-0 border-right-0" htmlFor="inputGroupSelect01">-</label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="endDate"
                value={dateData.endDate !== '' ? dateData.endDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-append">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
            </div>
          </div> */}
          {/* <div className="col-sm-2">
            <small className="text-danger">{selectStartDate ? 'Select Start Date' : ''}</small>
          </div> */}
        </div>
        <div className="row gutters-sm animated fadeIn">
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100 shadow">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:dashboard.issuers")}</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4 user-icon-group">
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                  </div>
                  <div className="col-sm-8">
                    {/* <label className="nb-label">Org Admin / Partner Admin </label> */}
                    {/* <label className="nb-label"><title>{t("screens:navigationDrawer.listItemSettings")}</title></label> */}
                    <label className="nb-label">{t("screens:dashboard.orgadmin")}</label>
                    <ProgressBar className="nb-progress-bar mb-3" now={
                      !_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].orgAdminCount : 0
                    } label={!_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].orgAdminCount + '%' : 0 + '%'} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 user-icon-group">
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                  </div>
                  <div className="col-sm-8">
                    <label className="nb-label">{t("screens:dashboard.issuer")}</label>
                    <ProgressBar className="nb-progress-bar mb-3" now={
                      !_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].internalIssuerCount : 0
                    } label={!_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].internalIssuerCount + '%' : 0 + '%'} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 user-icon-group">
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                  </div>
                  <div className="col-sm-8">
                  <label className="nb-label">{t("screens:dashboard.verifier")}</label>
                    <ProgressBar className="nb-progress-bar mb-3" now={
                      !_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].internalVerifierCount : 0
                    } label={!_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].internalVerifierCount + '%' : 0 + '%'} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4 user-icon-group">
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                    <img className="user-icon" src={images.placeholderProfile} />
                  </div>
                  <div className="col-sm-8">
                  <label className="nb-label">{t("screens:dashboard.both")}</label>
                    <ProgressBar className="nb-progress-bar mb-3" now={
                      !_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].internalBothCount : 0
                    } label={!_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[3]) ? countForDashboard[3].internalBothCount + '%' : 0 + '%'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100 shadow">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:dashboard.connectionsTitle")}</h1>
              </div>
              {!_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderOutsideCount !== 0 || !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderInsideCount !== 0 ?
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    // loader={<div>Loading Chart</div>}
                    loader={<div>{t("screens:dashboard.loadingChart")}</div>}
                    // TODO data={[
                    //   ['Org Type', 'Count'],
                    //   ['Outside Organization', !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderOutsideCount],
                    //   ['Inside Organization', !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderInsideCount],
                    // ]}insideOrg
                    data={[
                      [`${t("screens:dashboard.orgType")}`, `${t("screens:dashboard.count")}`],
                      [`${t("screens:dashboard.outsideOrg")}`, !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderOutsideCount],
                      [`${t("screens:dashboard.insideOrg")}`, !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderInsideCount],
                    ]}
                    options={{
                      title: `${t("screens:dashboard.connections")}`,
                      pieHole: 0.4,
                    }}
                    rootProps={{ 'data-testid': '3' }}
                  />
                </div>
                :
                <h1 className="text-primary text-center">{t("screens:dashboard.noConnections")}</h1>
              }
            </div>
          </div>
        </div>
        {!_.isEmpty(loggedInUser) && !_.isEmpty(loggedInUser.organization) && !_.isEmpty(loggedInUser.organization.subscription) &&
          loggedInUser.organization.subscription.id === 1 || loggedInUser.organization.subscription.id === 3 ?
          <div className="row gutters-sm animated fadeIn">
            <div className="col-md-12 col-lg-9 mb-30">
              <div className="card nb-card h-100 shadow">
                <div className="card-header bg-white border-bottom-0">
                  <h1 className="nb-title">{t("screens:dashboard.credentialsTitle")}</h1>
                </div>
                <div className="card-body">
                  {!_.isEmpty(credentialPercentage) && !_.isEmpty(credentialPercentage[1]) ?
                    <Chart
                      width={'100%'}
                      height={'400px'}
                      chartType="AreaChart"
                      loader={<div>{t("screens:dashboard.loadingChart")}</div>}

                      data={credentialPercentage}
                      options={{
                        title: `${t("screens:dashboard.credential")}`,
                        hAxis: { title: `${t("screens:dashboard.month")}`, titleTextStyle: { color: '#333' } },
                        vAxis: { minValue: 0 },
                        chartArea: { width: '80%', height: '80%' },
                        colors: ['#803DF8', '#254D91', '#9842D4', '#008BE6']
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                    :
                    <p className="text-primary text-center">{t("screens:dashboard.noCredentials")}</p>
                  }
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-3 mb-30">
              <div className="card nb-card mb-30 shadow">
                <div className="card-header bg-white border-bottom-0">
                  <div className="media">
                    <img src="assets/icons/new-schema-dashboard.svg" className="align-self-center mr-3" style={{ width: '68px' }} alt="..." />
                    <div className="media-body">
                      <h1 className="mt-0">{t("screens:dashboard.newSchemas")}</h1>
                      <div style={{ fontSize: '37px' }}>{
                        !_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[4]) ? countForDashboard[4].schemaCount : 0
                      }</div>
                    </div>
                  </div>
                </div>
                <div className="card-body">

                </div>
              </div>
              <div className="card nb-card shadow">
                <div className="card-header bg-white border-bottom-0">
                  <div className="media">
                    <img src="assets/icons/existing-schema-dashboard.svg" className="align-self-center mr-3" style={{ width: '68px' }} alt="..." />
                    <div className="media-body">
                      <h1 className="mt-0">{t("screens:dashboard.newCredentials")}</h1>
                      <div style={{ fontSize: '37px' }}>{
                        !_.isEmpty(countForDashboard) && !_.isEmpty(countForDashboard[5]) ? countForDashboard[5].credentialCount : 0
                      }</div>
                    </div>
                  </div>
                </div>
                <div className="card-body">

                </div>
              </div>
            </div>
          </div>
          :
          <></>
        }

        {!_.isEmpty(loggedInUser) && !_.isEmpty(loggedInUser.organization) ?
          <div className="row gutters-sm animated fadeIn">
            <div className="col-md-12 col-lg-12 mb-30">
              <div className="card nb-card h-100 shadow">
                <div className="card-header bg-white border-bottom-0">
                  <h1 className="nb-title">{t("screens:dashboard.KYCReminderTitle")}</h1>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                      <h6 className="nb-label">{t("screens:dashboard.proposalReceived")} <span className="badge badge-light nb-badge nb-badge-blue">{
                        !_.isEmpty(countForDashboard) && !_.isEmpty(kycWaitingList) ?
                          kycWaitingList.length : 0
                      }</span></h6>
                      <div className="row">
                        <div className="col-sm-12 ">
                          {!_.isEmpty(countForDashboard) && !_.isEmpty(kycWaitingList) ?
                            kycWaitingList.map((item: any, index: any) => {
                              return (
                                index <= minKYCRecordDisplay ?
                                  <>
                                    <div className="card mb-2 dashboard-KYC-status nb-box-blue">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col-sm-8 align-self-center">
                                            <p className="font-weight-bold overflow-ellips">{!_.isEmpty(item.holder) ? item.holder.name : ''}</p>
                                            <label className="text-muted">{item.kycName}</label>
                                          </div>
                                          <div className="col-sm-4 text-center">
                                            <div>
                                              <img className="nb-box-icon" src={images.placeholderProfile} />
                                            </div>
                                            <label className="text-muted">{moment(item.kycExpiryDate).format('MMM DD')}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  : null
                              )
                            })
                            :
                            <>
                              <div className="card mb-2 nb-box nb-box-blue">
                                <div className="card-body">
                                  <div className="row">
                                  {t("screens:dashboard.noWaiting")}
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <h6 className="nb-label">{t("screens:dashboard.qualified")} <span className="badge badge-light nb-badge nb-badge-purple">0</span></h6>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card mb-2 nb-box nb-box-purple">
                            <div className="card-body">
                              <div className="row">{t("screens:dashboard.noIssuedRecordFound")}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <h6 className="nb-label">{t("screens:dashboard.rejected")}<span className="badge badge-light nb-badge nb-badge-red">0</span></h6>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card mb-2 nb-box nb-box-red">
                            <div className="card-body">
                              <div className="row">{t("screens:dashboard.noIssuedRecordFound")}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      <h6 className="nb-label">{t("screens:dashboard.issued")} <span className="badge badge-light nb-badge nb-badge-green">{
                        !_.isEmpty(countForDashboard) && !_.isEmpty(kycIssuedList) ?
                          kycIssuedList.length : 0
                      }</span></h6>
                      <div className="row">
                        <div className="col-sm-12">
                          {!_.isEmpty(countForDashboard) && !_.isEmpty(kycIssuedList) ?
                            kycIssuedList.map((element: any, index: any) => {
                              return index <= minKYCRecordDisplay ?

                                <>
                                  <div className="card mb-2 nb-box nb-box-green">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-sm-8 align-self-center">
                                        <p className="font-weight-bold overflow-ellips">{!_.isEmpty(element) && element.holder && element.holder.name ? element.holder.name : ''}</p>
                                          <label className="text-muted overflow-ellips">{element.kycName}</label>
                                        </div>
                                        <div className="col-sm-4 text-center">
                                          <div>
                                            <img className="nb-box-icon" src={images.placeholderProfile} />
                                          </div>
                                          <label className="text-muted">{moment(element.kycExpiryDate).format('MMM DD')}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>

                                : null
                            })
                            :
                            <>
                              <div className="card mb-2 nb-box nb-box-green">
                                <div className="card-body">
                                  <div className="row">{t("screens:dashboard.noIssuedRecordFound")}</div>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 text-center">
                      <button className="btn nb-link" onClick={this.ViewIssuedKYC}>
                        <i className="fas fa-2x fa-ellipsis-h"></i>
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <></>
        }
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const DashboardReducer = state.DashboardReducer;
  return { LoginReducer, DashboardReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    dashboardAction: bindActionCreators(dashboardAction, dispatch),
  }
}

const connectedOrgAdminDashboard = connect(mapStateToProps, mapDispatchToProps)(OrgAdminDashboard);
export { connectedOrgAdminDashboard as OrgAdminDashboard };