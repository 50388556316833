import * as React from 'react';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import _ from 'lodash';


export interface IAddNewRuleProps {
  GovRuleAction: any,
  GovRuleReducer: any,
}

export interface IAddNewRuleState {
  isSelected: boolean,
  selectedRule: any,
  clearTypeahead: boolean,
  ruleTypeList: any,
  actionTypeList: any,
  conditionList: any,
  selectedConditionOn: any,
  selectedAction: any,
  selectedCondition: any,
}
export default class AddNewRule extends React.Component<IAddNewRuleProps, IAddNewRuleState> {
  constructor(props: IAddNewRuleProps) {
    super(props);

    this.state = {
      isSelected: false,
      selectedRule: {},
      selectedAction: {},
      selectedCondition: {},
      clearTypeahead: false,
      ruleTypeList: [
        { tag: "Credential Issuance" },
        { tag: "Schema Setup" },
        { tag: "Connection" },
        { tag: "Credential Classification" }
      ],
      actionTypeList: [
        { tag: "Issue Client Identity Card" },
        { tag: "Expiry date will be 30 days after from the issuing date" },
        { tag: "Issue OrgVC" },
        { tag: "Issue Login Credential" }
      ],
      conditionList: [
        { tag: "Completes 2 KYCs" },
        { tag: "Contains no Expiry Date" },
        { tag: "Is Client" },
        { tag: "Role is Issuer or Verifier" }
      ],
      selectedConditionOn: 'Holder',
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRuleTypeChange = this.handleRuleTypeChange.bind(this);
    this.handleActionTypeChange = this.handleActionTypeChange.bind(this);
    this.onSelectConditionOn = this.onSelectConditionOn.bind(this);
    this.handleConditionTypeChange = this.handleConditionTypeChange.bind(this);
    this.saveNewRule = this.saveNewRule.bind(this);
    this.createNew = this.createNew.bind(this);
  }
  handleInputChange() {
    this.setState({
      isSelected: false
    })
  }

  handleRuleTypeChange(selectedOptions: any) {
    let selectedData = !_.isEmpty(selectedOptions) && selectedOptions[0];
    this.setState({
      isSelected: true,
      selectedRule: !_.isEmpty(selectedData) && selectedData.tag,
    })
    this.checkValidation()
  }

  handleActionTypeChange(selectedOptions: any) {
    let selectedData = !_.isEmpty(selectedOptions) && selectedOptions[0];
    this.setState({
      isSelected: true,
      selectedAction: !_.isEmpty(selectedData) && selectedData.tag,
    })
    this.checkValidation()

  }
  onSelectConditionOn(selectedOptions: any) {
    this.setState({ selectedConditionOn: selectedOptions.target.value });
    this.checkValidation()

  }
  handleConditionTypeChange(selectedOptions: any) {
    let selectedData = !_.isEmpty(selectedOptions) && selectedOptions[0];
    this.setState({
      isSelected: true,
      selectedCondition: !_.isEmpty(selectedData) && selectedData.tag,
    })
    this.checkValidation()
  }

  checkValidation() {

    const { selectedCondition, selectedAction, selectedRule, selectedConditionOn } = this.state;
    if (selectedCondition !== '' && selectedAction !== '' && selectedRule !== '' && selectedConditionOn !== '') {
      // this.setState({ saveButton: false })
      // this.props.GovRuleAction.openSaveButton();
    } else {
      // this.setState({ saveButton: true })
    }
  }

  saveNewRule() {
    const { selectedCondition, selectedAction, selectedRule, selectedConditionOn } = this.state;
    const newRuleDetails = {
      "ruleType": selectedRule,
      "conditionOn": selectedConditionOn,
      "condition": selectedCondition,
      "action": selectedAction
    }
    this.props.GovRuleAction.saveNewRule(newRuleDetails);
  }

  createNew() {
    this.props.GovRuleAction.clearSelectedRule();
  }


  public render() {
    const { selectedRule, saveButton } = this.props.GovRuleReducer;
    const { clearTypeahead, ruleTypeList, actionTypeList, conditionList, selectedAction, selectedCondition, } = this.state;
    return (<>
      <div className="card nb-card h-100">
        <div className="card-header bg-white border-bottom-0">
          <h1 className="nb-title">Rule Setup</h1>
        </div>
        <div className="card-body p-5">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <label className="nb-label">Rule Type</label>
                {!_.isEmpty(selectedRule) ?
                  <input type="text" className="form-control" value={selectedRule.ruleType} disabled />
                  :
                  <Typeahead data={ruleTypeList} onChange={this.handleRuleTypeChange} onInputChange={this.handleInputChange}
                    clearTypeahead={clearTypeahead} placeholder="Select Rule type" disabled={false} />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <div className="nb-label">
                  If {!_.isEmpty(selectedRule) ? <input type="text" className="form-control" value={selectedRule.ruleCondition} disabled />
                    : <select className="form-control-sm text-primary" onChange={(event) => this.onSelectConditionOn(event)}>
                      <option value="Holder">Holder</option>
                      <option value="Attribute">Attribute</option>
                    </select>} of the following conditions are met
                                    </div>
                <div className="input-group mb-3">
                  {!_.isEmpty(selectedRule) ?
                    <input type="text" className="form-control" value={selectedRule.condition} disabled />
                    :
                    <Typeahead data={conditionList} onChange={this.handleConditionTypeChange} onInputChange={this.handleInputChange}
                      clearTypeahead={clearTypeahead} placeholder="Select Condition" disabled={false} />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="nb-label">Action will be</label>
                {!_.isEmpty(selectedRule) ?
                  <input type="text" className="form-control" value={selectedRule.action} disabled />
                  :
                  <Typeahead data={actionTypeList} onChange={this.handleActionTypeChange} onInputChange={this.handleInputChange}
                    clearTypeahead={clearTypeahead} placeholder="Select Action" disabled={false} />
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                {!_.isEmpty(selectedRule) ?
                  <button type="button" onClick={this.createNew} className="btn btn-block btn-nb-gradient-primary rounded-pill">CREATE ANOTHER RULE</button>
                  :
                  <button type="button" onClick={this.saveNewRule} className="btn btn-block btn-nb-gradient-primary rounded-pill"
                  disabled={ _.isEmpty(this.state.selectedRule) || _.isEmpty(this.state.selectedCondition) || _.isEmpty(this.state.selectedAction) ? true : false  }
                  >SAVE</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    );
  }
}