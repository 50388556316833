import _ from 'lodash';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import CropImageModal from '../../Register/components/cropImageModal';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ICreateNewWorkFlowProps {
  workflowAction: any,
  WorkflowReducer: any,
}

export interface ICreateNewWorkFlowState {
  taskData: any,
  workflowAttributes: any,
  errors: any,
  key: string,
  src: any,
  crop: any,
  isModalShow: boolean,
  croppedImageUrl: any,
  showCroppedImage: boolean,
  croppedImage: any,
  duplicateworkflowName: boolean,
}

export default class CreateNewWorkFlow extends React.Component<ICreateNewWorkFlowProps, ICreateNewWorkFlowState> {
  private imageRef: any;
  private fileUrl: any;
  constructor(props: ICreateNewWorkFlowProps) {
    super(props);

    this.state = {
      taskData: { repetition: '', method: '', verificationType: '', verificationTrigger: '', workflowname: '' },
      workflowAttributes: [],
      errors: {},
      key: 'TaskType',
      isModalShow: false,
      src: null,
      crop: {
        unit: '%',
        width: 30,
        height: 30,
        x: 25,
        y: 25,
        // aspect: 16 / 9,
      },
      croppedImageUrl: "",
      showCroppedImage: false,
      croppedImage: "",
      duplicateworkflowName: false,
    }
    this.nextStep = this.nextStep.bind(this);
    this.saveToList = this.saveToList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.clearCroppedImage = this.clearCroppedImage.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.makeClientCrop = this.makeClientCrop.bind(this);
    this.dataURLtoFile = this.dataURLtoFile.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);
    this.save = this.save.bind(this);
    this.saveToList = this.saveToList.bind(this);
    this.verificationTrigger = this.verificationTrigger.bind(this);
    this.verificationType = this.verificationType.bind(this);
    this.methodChange = this.methodChange.bind(this);
    this.repetitionChange = this.repetitionChange.bind(this);
    this.handleButtonChange = this.handleButtonChange.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    const { selectedWorkflow } = this.props.WorkflowReducer;
    const { taskData, workflowAttributes, croppedImageUrl } = this.state;
    if (taskData.workflowname !== selectedWorkflow.workflowname) {
      this.setState({
        taskData: {
          workflowname: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.workflowName : taskData.workflowname,
          workflowImage: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.workflowImage : croppedImageUrl,
          repetition: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.repetition : taskData.repetition,
          method: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.method : taskData.method,
          verificationType: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.verificationType : taskData.verificationType,
          verificationTrigger: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.verificationTrigger : taskData.verificationTrigger,
        },
        workflowAttributes: !_.isEmpty(selectedWorkflow) ? JSON.parse(selectedWorkflow.workflowAttributes) : workflowAttributes,
      })
    }
  }

  shouldComponentUpdate(newProps: ICreateNewWorkFlowProps) {
    const { selectedWorkflow } = newProps.WorkflowReducer;
    const { taskData, workflowAttributes, croppedImageUrl } = this.state;
    if (newProps !== this.props) {
      if (taskData.workflowname !== selectedWorkflow.workflowname) {
        this.setState({
          taskData: {
            workflowname: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.workflowName : taskData.workflowname,
            workflowImage: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.workflowImage : croppedImageUrl,
            repetition: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.repetition : taskData.repetition,
            method: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.method : taskData.method,
            verificationType: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.verificationType : taskData.verificationType,
            verificationTrigger: !_.isEmpty(selectedWorkflow) ? selectedWorkflow.verificationTrigger : taskData.verificationTrigger,
          },
          workflowAttributes: !_.isEmpty(selectedWorkflow) ? JSON.parse(selectedWorkflow.workflowAttributes) : workflowAttributes,
        })
      }
    }
    return true
  }


  validateForm() {

    let formData: any = this.state.taskData;
    let errors: any = {};
    let formIsValid: any = true;

    if (!formData["workflowname"]) {
      formIsValid = false;
      errors["workflowname"] = `${t("screens:newWorkflow.messageErrorTaskType")}`;
    }

    if (typeof formData["workflowname"] !== "undefined") {
      if (!formData["workflowname"].match(/^.{2,200}$/)) {
        formIsValid = false;
        // errors["workflowname"] = "*Please enter more than 2 and less than 200 characterss.";
        errors["workflowname"] = `${t("screens:newWorkflow.messageErrorTaskTypeSize")}`;
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  nextStep(key: string | any) {
    this.setState({ key });
  }

  onSelectFile(event: React.ChangeEvent<HTMLInputElement> | any) {
    var fileName = event.target.value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      if (event.target.files && event.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result, isModalShow: true })
        );
        reader.readAsDataURL(event.target.files[0]);
      }
      event.target.value = null;
    } else {
      // toastr.info(`Only jpg/jpeg and png files are allowed!`, ``);
      toastr.info(`${t("screens:newWorkflow.toastMsgImage")}`, ``);
    }

  };

  clearCroppedImage() {
    this.setState({ croppedImageUrl: "", showCroppedImage: false })
  }
  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage })
  }

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'cropped.jpg')
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');

    });
  }

  save() {
    this.setState({ showCroppedImage: true, isModalShow: false, src: null })
  }

  cancel() {
    this.setState({ isModalShow: false })
  }

  repetitionChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let fields = this.state.taskData;
    fields["repetition"] = event.currentTarget.value;
    if (event.currentTarget.value === 'Both') {
      let bothArray: any = ["startTime", "startDate", "duration"];
      this.setState({
        workflowAttributes: bothArray
      })
    }
    if (event.currentTarget.value === 'SingleTask') {
      let bothArray: any = ["date"];
      this.setState({
        workflowAttributes: bothArray
      })
    }
    if (event.currentTarget.value === 'RecurringTasks') {
      let bothArray: any = ["startTime", "startDate", "duration"];
      this.setState({
        workflowAttributes: bothArray
      })
    }
    this.setState({
      taskData: fields
    });
  }

  methodChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let fields = this.state.taskData;

    fields["method"] = event.currentTarget.value;
    this.setState({
      taskData: fields
    });
  }

  verificationType(event: React.ChangeEvent<HTMLInputElement> | any) {
    let fields = this.state.taskData;

    fields["verificationType"] = event.currentTarget.value;
    this.setState({
      taskData: fields
    });
  }

  verificationTrigger(event: React.ChangeEvent<HTMLInputElement> | any) {
    let fields = this.state.taskData;

    fields["verificationTrigger"] = event.currentTarget.value;
    this.setState({
      taskData: fields
    });
  }

  handleButtonChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let workflowAttributes = this.state.workflowAttributes;
    if (event.currentTarget.value !== 'date' && event.currentTarget.value !== 'startDate' && event.currentTarget.value !== 'duration') {
      if (workflowAttributes.includes(event.currentTarget.name)) {
        const index = workflowAttributes.indexOf(event.currentTarget.name);
        if (index > -1) {
          workflowAttributes.splice(index, 1);
        }
      } else {
        workflowAttributes.push(event.currentTarget.value)
      }
    }
    this.setState({
      workflowAttributes: workflowAttributes,
    });
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { taskData } = this.state;
    const { selectedWorkflow, workflowList } = this.props.WorkflowReducer;
    let fields = taskData;
    let duplicateworkflow = false;
    if (!fields.workflowname && !_.isEmpty(selectedWorkflow) && selectedWorkflow.workflowName) {
      fields["workflowname"] = selectedWorkflow.workflowName;
    }

    if (!_.isEmpty(workflowList)) {
      workflowList.map((item: any, index: any) => {
        if (item.workflowName === event.target.value) {
          duplicateworkflow = true;
        }
      })
      this.setState({
        duplicateworkflowName: duplicateworkflow
      })
    }

    fields[event.target.name] = event.target.value;
    this.setState({
      taskData: fields,
    });
    this.validateForm();
  }

  saveToList(e: any) {
    const { taskData, croppedImage, workflowAttributes } = this.state;
    let finalData =
    {
      workflowName: taskData.workflowname,
      workflowImage: croppedImage,
      repetition: taskData.repetition,
      method: taskData.method,
      workflowAttributes: workflowAttributes,
      verificationType: taskData.verificationType,
      verificationTrigger: taskData.verificationTrigger,
    }
    this.props.workflowAction.createWorkflow(finalData);
    this.setState({
      taskData: { repetition: '', method: '', verificationType: '', verificationTrigger: '', workflowname: '' },
      workflowAttributes: [],
    })
  }

  updateToList(e: any) {
    const { taskData, croppedImage, workflowAttributes } = this.state;
    const { selectedWorkflow } = this.props.WorkflowReducer;
    let finalData =
    {
      workflowName: taskData.workflowname,
      workflowImage: croppedImage !== "" ? croppedImage : selectedWorkflow.workflowImage,
      repetition: taskData.repetition,
      method: taskData.method,
      workflowAttributes: workflowAttributes,
      verificationType: taskData.verificationType,
      verificationTrigger: taskData.verificationTrigger,
    }
    this.props.workflowAction.updateWorkflow(selectedWorkflow.id, finalData);
    this.setState({
      taskData: { repetition: '', method: '', verificationType: '', verificationTrigger: '', workflowname: '' },
      workflowAttributes: [],
    })
  }

  public render() {
    let { taskData, key, crop, croppedImageUrl, src, isModalShow, showCroppedImage, workflowAttributes, duplicateworkflowName } = this.state;
    const { selectedWorkflow } = this.props.WorkflowReducer;
    return (
      <>
        <div className="card-body">
          <ul className="nav nav-pills mb-3 nb-tab-bars" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <a className={key === 'TaskType' || taskData.workflowname !== '' ? "nav-link active" : "nav-link active"} id={key} data-toggle="pill" href="#Types" role="tab" aria-controls="Types" aria-selected="true"
                aria-disabled={key === 'secondStep'}
              ></a>
            </li>
            <li className="nav-item" role="presentation">
              <a className={key === 'secondStep' || taskData.repetition !== '' ? "nav-link active" : "nav-link"} id={key} data-toggle="pill" href="#secondStep" role="tab" aria-controls="secondtab" aria-selected="false"
                aria-disabled={key === 'thirdStep'}
              ></a>
            </li>
            <li className="nav-item" role="presentation">
              <a className={key === 'thirdStep' || taskData.verificationType !== '' ? "nav-link active" : "nav-link"} id={key} data-toggle="pill" href="#thirdStep" role="tab" aria-controls="thirdtab" aria-selected="false"
                aria-disabled={key === 'fourthStep'}
              ></a>
            </li>
            <li className="nav-item" role="presentation">
              <a className={key === 'fourthStep' || taskData.verificationTrigger !== '' ? "nav-link active" : "nav-link"} id={key} data-toggle="pill" href="#fourthStep" role="tab" aria-controls="fourthtab" aria-selected="false"></a>
            </li>
          </ul>
          <div className="tab-content p-3" id="pills-tabContent">
            <div className={key === 'TaskType' ? "tab-pane fade show active" : "tab-pane fade show"} id="Types" role="tabpanel" aria-labelledby="TaskType">
              <div className="row">
                <div className="col-sm-12 text-center mb-3">
                  <h2>{t("screens:newWorkflow.taskType")}</h2>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <input type="text" className="form-control" name='workflowname'
                      value={taskData.workflowname}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
                <div className="text-danger">{this.state.errors.workflowname}</div>
                {duplicateworkflowName ?
                  <div className="text-danger">{t("screens:newWorkflow.duplicateWorkflowName")}</div>
                  : ''
                }
              </div>
              <div className="row">
                <div className="col-sm-12 text-right mb-3">
                  <button className="btn btn-link"
                    onClick={() => { this.nextStep('secondStep') }}
                    disabled={taskData.workflowname.trim() === "" || this.state.errors.workflowname ? true : false}
                  ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className={key === 'secondStep' ? "tab-pane fade show active" : "tab-pane fade"} id="secondtab" role="tabpanel" aria-labelledby="secondStep">
              <div>
                <div className="row">
                  <div className="col-sm-12 text-center mb-3">
                    <h2>{t("screens:newWorkflow.describeTask")}</h2>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4 align-self-center">
                        <div className="mb-2">
                          <h6>{t("screens:newWorkflow.defaultImage")}</h6>
                          <p className="small">{t("screens:newWorkflow.sizeImage")}</p>
                        </div>
                        {showCroppedImage === false ?
                          <div>
                            <div className="upload-btn-wrapper">
                              <button>{t("screens:newWorkflow.upload")}</button>
                              <input type="file" accept="image/*"
                                onChange={this.onSelectFile} name="workflowImage" />
                            </div>
                          </div>
                          :
                          <div>
                            <button className="btn btn-nb-outline-primary" onClick={this.clearCroppedImage}>{t("screens:newWorkflow.cancel")}</button>
                          </div>
                        }
                        {isModalShow && <CropImageModal
                          isModalShow={isModalShow}
                          src={src}
                          crop={crop}
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                          save={this.save}
                          cancel={this.cancel}
                        />}
                      </div>
                      <div className="col-sm-8">
                        {croppedImageUrl && showCroppedImage ?
                          <div className="card nb-card-image" style={{ backgroundImage: `url(${croppedImageUrl})` }}>
                            <div className="card-body"></div>
                          </div>
                          :
                          <div className="card nb-card-image" style={{ backgroundImage: `url(${!_.isEmpty(selectedWorkflow) ? selectedWorkflow.workflowImage : images.placeholderRectangleClear})` }}>
                            <div className="card-body"></div>
                          </div>
                        }
                      </div>
                      <div className="col-sm-3 align-self-end">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <h6>{t("screens:newWorkflow.repetition")}<span>*</span></h6>
                          </div>
                          <div className="col-sm-12">
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item p-0 border-bottom-0">
                                <label className="align-self-center" htmlFor="SingleTask">{t("screens:newWorkflow.singleTask")}
                                  <input type="checkbox" className="float-right mt-2" id="SingleTask"
                                    name="repetition"
                                    checked={taskData.repetition === 'SingleTask' ? true : false}
                                    value={'SingleTask'}
                                    onChange={(e) => this.repetitionChange(e)}
                                  /></label>
                              </li>
                              <li className="list-group-item p-0 border-bottom-0">
                                <label className="align-self-center" htmlFor="RecurringTasks">{t("screens:newWorkflow.recurringTasks")}
                                  <input type="checkbox" className="float-right mt-2" id="RecurringTasks"
                                    name="repetition"
                                    checked={taskData.repetition === 'RecurringTasks' ? true : false}
                                    value={'RecurringTasks'}
                                    onChange={(e) => this.repetitionChange(e)}
                                  /></label>
                              </li>
                              <li className="list-group-item p-0 border-bottom-0">
                                <label className="align-self-center" htmlFor="Both">{t("screens:newWorkflow.both")}
                                  <input type="checkbox" className="float-right mt-2" id="Both"
                                    checked={taskData.repetition === 'Both' ? true : false}
                                    value={'Both'}
                                    name="repetition"
                                    onChange={(e) => this.repetitionChange(e)}
                                  /></label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 mb-3">
                            <h6>{t("screens:newWorkflow.method")}<span>*</span></h6>
                          </div>
                          <div className="col-sm-12">
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item p-0 border-bottom-0">
                                <label className="align-self-center" htmlFor="InPerson">{t("screens:newWorkflow.inPerson")}
                                  <input type="checkbox" className="float-right mt-2" id="InPerson"
                                    onChange={(e) => this.methodChange(e)}
                                    value={'InPerson'}
                                    name="method"
                                    checked={taskData.method === 'InPerson' ? true : false}
                                  /></label>
                              </li>
                              <li className="list-group-item p-0 border-bottom-0">
                                <label className="align-self-center" htmlFor="Virtual">{t("screens:newWorkflow.virtual")}
                                  <input type="checkbox" className="float-right mt-2" id="Virtual"
                                    onChange={(e) => this.methodChange(e)}
                                    value={'Virtual'}
                                    name="method"
                                    checked={taskData.method === 'Virtual' ? true : false}
                                  /></label>
                              </li>
                              <li className="list-group-item p-0 border-bottom-0">
                                <label className="align-self-center" htmlFor="Both2">{t("screens:newWorkflow.both")}
                                  <input type="checkbox" className="float-right mt-2" id="Both2"
                                    onChange={(e) => this.methodChange(e)}
                                    value={'Both2'}
                                    name="method"
                                    checked={taskData.method === 'Both2' ? true : false}
                                  /></label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <button type="button"
                        className={
                          workflowAttributes.includes('title') ? "btn btn-block btn-outline-secondary active" : "btn btn-block btn-outline-secondary"}
                        name='title' value={'title'}
                        onClick={(e) => this.handleButtonChange(e)}
                      >{t("screens:newWorkflow.createNewWorkflow")}</button>
                    </div>
                    <div className="col-sm-4">
                      <button type="button" className={
                        workflowAttributes.includes('date') || taskData.repetition.includes('SingleTask') || taskData.repetition.includes('Both') ? "btn btn-block btn-outline-secondary active" :
                          "btn btn-block btn-outline-secondary"
                      }
                        name='date' value={'date'}
                        onClick={(e) => this.handleButtonChange(e)}
                        disabled={taskData.repetition === 'RecurringTasks' ? true : false}
                        title={taskData.repetition === 'SingleTask' ? `${t("screens:newWorkflow.requiredFieldSingle")}` : ''}
                      >{t("screens:newWorkflow.date")}</button>
                    </div>
                    <div className="col-sm-4">
                      <button type="button" className={
                        workflowAttributes.includes('startDate') || taskData.repetition.includes('RecurringTasks') || taskData.repetition.includes('Both') ? "btn btn-block btn-outline-secondary active" : "btn btn-block btn-outline-secondary"}
                        name='startDate' value={'startDate'}
                        onClick={(e) => this.handleButtonChange(e)}
                        disabled={taskData.repetition === 'SingleTask' ? true : false}
                        title={taskData.repetition === 'RecurringTasks' ? `${t("screens:newWorkflow.requiredFieldRecurring")}` : ''}
                      >{t("screens:newWorkflow.startTime")}</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <button type="button" className={
                        workflowAttributes.includes('duration') || taskData.repetition.includes('RecurringTasks') || taskData.repetition.includes('Both') ? "btn btn-block btn-outline-secondary active" : "btn btn-block btn-outline-secondary"}
                        name='duration' value={'duration'}
                        onClick={(e) => this.handleButtonChange(e)}
                        disabled={taskData.repetition === 'SingleTask' ? true : false}
                        title={taskData.repetition === 'RecurringTasks' ? `${t("screens:newWorkflow.requiredFieldRecurring")}` : ''}
                      >{t("screens:newWorkflow.duration")}</button>
                    </div>
                    <div className="col-sm-4">
                      <button type="button" className={
                        workflowAttributes.includes('location') ? "btn btn-block btn-outline-secondary active" : "btn btn-block btn-outline-secondary"}
                        name='location' value={'location'}
                        onClick={(e) => this.handleButtonChange(e)}
                      >{t("screens:newWorkflow.location")}</button>
                    </div>
                  </div>
                </>
                <div className="row">
                  <div className="col-sm-12 text-right mt-3">
                    <button className="btn btn-link"
                      onClick={() => this.nextStep('thirdStep')}
                      disabled={_.isEmpty(workflowAttributes) || taskData.repetition.trim() === "" || taskData.method.trim() === ""

                        ? true : false}
                    ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={key === 'thirdStep' ? "tab-pane fade show active" : "tab-pane fade"} id="thirdtab" role="tabpanel" aria-labelledby="thirdStep">
              <div className="row">
                <div className="col-sm-12 text-center mb-3">
                  <h2>{t("screens:newWorkflow.credentialRequired")}</h2>
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body text-center">
                          <label className="font-weight-bold">{t("screens:newWorkflow.specificCredential")}</label>
                          <div className="custom-control custom-checkbox">
                            <input className="form-check-input" type="checkbox" id="specificCredential"
                              name="verificationType"
                              value={'specificCredential'}
                              checked={taskData.verificationType === 'specificCredential' ? true : false}
                              onChange={(e) => this.verificationType(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body text-center">
                          <label className="font-weight-bold">{t("screens:newWorkflow.zeroKnowledgeProof")}</label>
                          <div className="custom-control custom-checkbox">
                            <input className="form-check-input" type="checkbox"
                              id="zkp"
                              name="verificationType"
                              value={'zkp'}
                              checked={taskData.verificationType === 'zkp' ? true : false}
                              onChange={(e) => this.verificationType(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-right mt-3">
                  <button className="btn btn-link"
                    onClick={() => this.nextStep('fourthStep')}
                    disabled={taskData.verificationType.trim() === "" ? true : false}
                  ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className={key === 'fourthStep' ? "tab-pane fade show active" : "tab-pane fade"} id="fourthtab" role="tabpanel" aria-labelledby="fourthStep">
              <div className="row">
                <div className="col-sm-12 text-center mb-3">
                  <h2>{t("screens:newWorkflow.verificationStarts")}</h2>
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body text-center">
                          <label className="font-weight-bold">{t("screens:newWorkflow.scanQR")}</label>
                          <small>{t("screens:newWorkflow.messageByHolders")}</small>
                          <div className="custom-control custom-checkbox">
                            <input className="form-check-input" type="checkbox" id="scanQR"
                              name="verificationTrigger"
                              value={'scanQR'}
                              checked={taskData.verificationTrigger === 'scanQR' ? true : false}
                              onChange={(e) => this.verificationTrigger(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="card">
                        <div className="card-body text-center">
                          <label className="font-weight-bold">{t("screens:newWorkflow.proofRequest")}</label>
                          <small>V{t("screens:newWorkflow.messageByVerifiers")}</small>
                          <div className="custom-control custom-checkbox">
                            <input className="form-check-input" type="checkbox" id="proofreq"
                              name="verificationTrigger"
                              value={'proofreq'}
                              checked={taskData.verificationTrigger === 'proofreq' ? true : false}
                              onChange={(e) => this.verificationTrigger(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-right mt-3">
                  {!_.isEmpty(selectedWorkflow) ?
                    <button className="btn btn-link"
                      onClick={(e) => this.updateToList(e)}
                    >{t("screens:newWorkflow.updateList")}
                    </button>
                    :
                    <button className="btn btn-link"
                      onClick={(e) => this.saveToList(e)}
                      disabled={!taskData.verificationTrigger ? true : false}
                    >{t("screens:newWorkflow.saveList")}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
