import "intl-pluralrules";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Utilities
import { importAll } from "src/utils/import";
import { overrideObjects } from "src/utils/override";
import { enAdmin as enAdminBase, enCommon as enCommonBase, enScreens as enScreensBase } from "./en";
import { frAdmin as frAdminBase, frCommon as frCommonBase, frScreens as frScreensBase } from "./fr";
import { loadHelpLocalizations } from "./remote";

// Dynamically import all appropriate language files during build step
const overrideLanguageContext = require.context("src/overrides", false, /\.(json)$/); // prettier-ignore
const overrideLanguages = importAll(overrideLanguageContext, false);

/** Supported app locales */
export type Locales = "en" | "fr";
/** Localization files (single level of nesting objects) */
export type LocalizationResource = Record<string, Record<string, string>>;

type EnglishAdmin = typeof enAdminBase;
type EnglishCommon = typeof enCommonBase;
type EnglishScreens = typeof enScreensBase;

interface ILanguageConfig {
  /** Language code */
  code: Locales;
  /** Language name/title */
  title: string;
}

export const LANGUAGES: ILanguageConfig[] = [
  { code: "en", title: "English" },
  { code: "fr", title: "Français" },
];

export const defaultNamespace = "common";
const resources = {
  en: {
    admin: overrideObjects<EnglishAdmin>(enAdminBase, overrideLanguages.english_admin ?? {}), // prettier-ignore
    common: overrideObjects<EnglishCommon>(enCommonBase, overrideLanguages.english_common ?? {}), // prettier-ignore
    help: {},
    screens: overrideObjects<EnglishScreens>(enScreensBase, overrideLanguages.english_screens ?? {}), // prettier-ignore
  },
  fr: {
    admin: overrideObjects<EnglishAdmin>(frAdminBase, overrideLanguages.french_admin ?? {}), // prettier-ignore
    common: overrideObjects<EnglishCommon>(frCommonBase, overrideLanguages.french_common ?? {}), // prettier-ignore
    help: {},
    screens: overrideObjects<EnglishScreens>(frScreensBase, overrideLanguages.french_screens ?? {}), // prettier-ignore
  },
} as const;

const currentLanguage = i18n.language;

/**
 * Set app language (from profile)
 *
 * NOTE: This only configures local app localization, and does not update anything else (cache, profile, etc)!
 */
const setLocalization = async (languageCode: Locales) => {
  const availableLanguages = Object.keys(resources);
  if (!availableLanguages.includes(languageCode)) return;

  if (languageCode !== null && languageCode !== currentLanguage) {
    await i18n.changeLanguage(languageCode);
  }
};

i18n
  // Select the best available language from device locale (replaces "lng" option!)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // TODO: Change to 'false' in production!
    debug: false,
    defaultNS: defaultNamespace,
    detection: {
      // Only lookup browser and local storage locale (ignore other options)
      order: ["localStorage", "navigator"],
    },
    fallbackLng: "en",
    ns: ["admin", "common", "help", "screens"],
    interpolation: {
      // NOTE: Not needed for React!
      escapeValue: false,
    },
    resources,
  });

// Load remote help localization bundles
loadHelpLocalizations().catch((e) => {
  console.error("Error while loading help localization bundles", e);
});

export { setLocalization, resources as languages };
