import * as React from 'react';
import {
  Modal,
  Button,
  ModalBody,
} from 'reactstrap';
import _ from 'lodash';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { taskConst } from 'src/View/types';
import { t } from 'i18next';

export interface IProofDetailsModalProps {
  isOpen: any,
  closeModal: any,
  data: any,
  action: any,
  reducer: any,
  hideVerifyButton: boolean,
  handleDeclinePresentation: any
}

export interface IProofDetailsModalState {
}

export default class ProofDetailsModal extends React.Component<IProofDetailsModalProps, IProofDetailsModalState> {
  constructor(props: IProofDetailsModalProps) {
    super(props);

    this.state = {
    }
    this.verifyProof = this.verifyProof.bind(this);
    this.handleDeclinePresentation = this.handleDeclinePresentation.bind(this)
    // this.revealedAttributes = this.revealedAttributes.bind(this);
  }

  handleDeclinePresentation() {
    this.props.handleDeclinePresentation()
  }

  verifyProof(event: any, data: any) {
    const { showInstantTaskList, showAutomatedProofs } = this.props.reducer
    let presentationExchangeId = {
      presentationExchangeId: data.presentationExchangeId
    }

    const initiator = showInstantTaskList ? taskConst.SHOW_INSTANT_TASK_LIST :
      showAutomatedProofs ? taskConst.SHOW_AUTOMATED_PROOFS :
        taskConst.SHOW_INSTANT_TASK_LIST

    this.props.action.verifyProof(presentationExchangeId, initiator)
  }

  // Accepts the array and key
  _groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {}); // empty object is the initial value for result object
  };


  public render() {
    const { isOpen, closeModal, data, hideVerifyButton } = this.props;

    let presentationData = !_.isEmpty(data) && data.presentation && JSON.parse(data.presentation);

    if (presentationData) {
      const requestedAttributes = JSON.parse(data.presentationRequest).requested_attributes
      const requestedPredicates = JSON.parse(data.presentationRequest).requested_predicates

      let presentationReceived: any = []
      const credentialNamesList = presentationData.identifiers.map((identifier: any) => {
        return {
          credentialName: identifier.cred_def_id.split(':')[4],
          credentialDefinitionId: identifier.cred_def_id,
          schemaLedgerId: identifier.schema_id
        }
      })

      const revealedAttributes = presentationData.requested_proof.hasOwnProperty('revealed_attr_groups') ?
        presentationData.requested_proof.revealed_attr_groups :
        presentationData.requested_proof.revealed_attrs

      const isUnRevealedAttributes = presentationData.requested_proof.hasOwnProperty('unrevealed_attrs')

      const predicates = Object.keys(presentationData.requested_proof.predicates).length > 0
      const selfAttestedAttributes = Object.keys(presentationData.requested_proof.self_attested_attrs).length > 0

      if (isUnRevealedAttributes) {
        const unrevealedAttrsInfo = presentationData.requested_proof.unrevealed_attrs
        Object.keys(unrevealedAttrsInfo).map((unAttrObjKey: any) => {
          const subProofIndex = unrevealedAttrsInfo[unAttrObjKey].sub_proof_index

          Object.keys(presentationData.proof['proofs'][subProofIndex].primary_proof.eq_proof.m).map((attrName: any) => {

            if (attrName !== 'master_secret' && unAttrObjKey.split('_')[1] === attrName) {

              Object.keys(requestedAttributes).map((attribute: any) => {
                if (attribute === unAttrObjKey) {
                  presentationReceived.push({
                    credentialName: credentialNamesList[subProofIndex].credentialName,
                    reveal: false,
                    [requestedAttributes[attribute].name]: presentationData.proof['proofs'][subProofIndex].primary_proof.eq_proof.m[attrName]
                  })
                }
              })

            }

          })

        })
      }

      if (predicates) {
        const predicatesInfo = presentationData.requested_proof.predicates
        Object.keys(predicatesInfo).map((predicateObjKey: any) => {
          const subProofIndex = predicatesInfo[predicateObjKey].sub_proof_index

          for (const key in presentationData.proof['proofs'][subProofIndex]) {
            if (Object.prototype.hasOwnProperty.call(presentationData.proof['proofs'][subProofIndex], key)) {
              const proofElements = presentationData.proof['proofs'][subProofIndex][key];

              for (const proofElement in proofElements) {
                if (Object.prototype.hasOwnProperty.call(proofElements, proofElement)) {
                  const element = proofElements[proofElement];

                  if (proofElement === 'eq_proof' && !element.hasOwnProperty('revealed_attrs')) {
                    presentationReceived.push({ attributes: proofElements.m })
                  }

                  if (proofElement === 'ge_proofs') {
                    element.map((predicates: any) => {
                      Object.keys(requestedPredicates).map((predicate: any) => {

                        if (predicates.predicate.attr_name === predicate.split('_')[1]) {
                          presentationReceived.push({
                            predicates: {
                              attr_name: requestedPredicates[predicate].name,
                              p_type: predicates.predicate.p_type,
                              value: predicates.predicate.value
                            }
                          })
                        }
                      })

                    })
                  }

                }
              }

            }
          }

        })

      }

      if (selfAttestedAttributes) {
        for (const key in presentationData.requested_proof.self_attested_attrs) {
          const value = presentationData.requested_proof.self_attested_attrs[key];

          presentationReceived.push({
            self_attested_attrs: {
              [key]: value
            }
          })

        }
      }

      for (let key in revealedAttributes) {
        const value = revealedAttributes[key];
        const subProofIndex = revealedAttributes[key].sub_proof_index

        Object.keys(requestedAttributes).map((attribute: any) => {
          if (attribute === key) {

            presentationReceived.push({
              credentialName: credentialNamesList[subProofIndex].credentialName,
              reveal: true,
              [requestedAttributes[attribute].name]: value.raw
            })
          }
        })

      }

      // Group by color as key to the person array
      const groupByArray = this._groupBy(presentationReceived, 'credentialName');

      return (
        <div >
          <Modal isOpen={isOpen} size="lg" className="nb-modal" toggle={closeModal}>
            <ModalHeader>
              <h4>{t("screens:verificationMonitoring.proofDetails")}</h4>
            </ModalHeader>
            <ModalBody>
              <div className="row mb-3" key={data.id}>
                <div className="col-sm-12" key={data.id}>

                  {!_.isEmpty(presentationData) &&
                    Object.keys(groupByArray).map((key: any, index: any) => {

                      if (key === 'undefined') {

                        return groupByArray[key].map((internalArrayElement: any, intArrayIdx: number) => {
                          if (internalArrayElement.hasOwnProperty('self_attested_attrs')) {
                            return (
                              <>
                                <p><b>{t("screens:verificationMonitoring.selfAttestedAttributes")}:</b></p>
                                <ul className="list-group list-group-flush" key={intArrayIdx}>
                                  {
                                    Object.keys(internalArrayElement).map((selfAttributes: any) => {
                                      return Object.keys(internalArrayElement[selfAttributes]).map((self: any, selfIndex: number) => {

                                        return (
                                          <li className="list-group-item" key={selfIndex}>
                                            <span className="nb-label">{self}: </span> {internalArrayElement[selfAttributes][self]}
                                          </li>
                                        )
                                      })
                                    })
                                  }
                                </ul>
                              </>
                            )

                          }
                          else {

                            return (
                              <>
                                <p><b>{t("screens:verificationMonitoring.credentialName")}: </b><b>{groupByArray[key].credentialName ? groupByArray[key].credentialName : ''}</b></p>
                                <p><b>{t("screens:verificationMonitoring.predicates")}:</b></p>
                                <ul className="list-group list-group-flush" key={index}>
                                  {

                                    Object.keys(internalArrayElement.predicates).map((predicateKey: any, predicateIdx: number) => {
                                      const predicateKeyComputed = predicateKey === 'attr_name' ? `${t("screens:verificationMonitoring.attributeName")}` :
                                        predicateKey === 'p_type' ? `${t("screens:verificationMonitoring.predicateType")}` :
                                          predicateKey === 'value' ? `${t("screens:verificationMonitoring.predicateValue")}` :
                                            ''

                                      const predicateValue = internalArrayElement.predicates[predicateKey] === 'GT' ? '>' :
                                        internalArrayElement.predicates[predicateKey] === 'LT' ? '<' :
                                          internalArrayElement.predicates[predicateKey] === 'EQ' ? '==' :
                                            internalArrayElement.predicates[predicateKey] === 'GE' ? '>=' :
                                              internalArrayElement.predicates[predicateKey] === 'LE' ? '<=' :
                                                internalArrayElement.predicates[predicateKey]
                                      return (
                                        <li className="list-group-item" key={predicateIdx}>
                                          <span className="nb-label">{predicateKeyComputed}: </span> {predicateValue}
                                        </li>
                                      )
                                    })
                                  }

                                </ul>
                              </>
                            )
                          }

                        })

                      }
                      else {
                        return (
                          <>
                            <p><b><span className='nb-label'>{t("screens:verificationMonitoring.credentialName")}: </span></b><b>{key ? key : ''}</b></p>
                            <p><b>{t("screens:verificationMonitoring.attributes")}:</b></p>
                            <ul className="list-group list-group-flush" key={index}>
                              {
                                groupByArray[key].map((attribute: any) => {

                                  return Object.keys(attribute).map((key: any, attrIndex: number) => {

                                    if (key !== 'credentialName' && key !== 'reveal') {
                                      const attributeValue = attribute[key]

                                      return (
                                        attributeValue?.includes('data:image') ?
                                          <li className="list-group-item" key={attrIndex}>
                                            <span className="nb-label">{key}: </span>
                                            <img src={attributeValue} alt={attribute} className="preview" height="50"></img>
                                          </li> :
                                          <li className="list-group-item" key={attrIndex}>
                                            <span className="nb-label">{key}: </span> {
                                              !attribute['reveal'] ?
                                                `${attributeValue.substring(0, 15).replace(/.(?=)/g, '*')}...${attributeValue.substring(attributeValue.length - 15).replace(/.(?=)/g, '*')}` :
                                                attributeValue
                                            }</li>
                                      )
                                    }
                                  })

                                })
                              }
                            </ul>
                          </>)
                      }
                    })
                  }

                  <ul className="list-group list-group-flush">

                    <li className="list-group-item text-success">
                      {
                        !_.isEmpty(data) && data.verified === "true" ? <span className="text-success"><i className="fas fa-check-circle text-success-light"></i> {t("screens:verificationMonitoring.messageVerified")}</span> :
                          !_.isEmpty(data) && data.verified === "false" ?
                            <span className="text-danger"><i className="fas fa-times-circle text-danger"></i> {t("screens:verificationMonitoring.messageRevoked")}</span> : ''
                      }
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-3">
                  {
                    !_.isEmpty(data) && data.proofState === "presentation_received" &&
                    <button type='button' className="btn btn-primary btn-block rounded-pill"
                      onClick={(e) => this.verifyProof(e, data)}
                      disabled={hideVerifyButton ? true : false}
                    >
                      {t("screens:verificationMonitoring.verify")}
                    </button>
                  }
                </div>
                <div className='col-md-3'>
                  {
                    !_.isEmpty(data) && data.proofState === "presentation_received" &&
                    <button type='button' className="btn btn-primary btn-block rounded-pill"
                      onClick={this.handleDeclinePresentation}
                      disabled={hideVerifyButton ? true : false}
                    >
                      {t("screens:verificationMonitoring.decline")}
                    </button>
                  }
                </div>
                <div className="col-md-3">
                  <Button variant="secondary" className="btn btn-secondary btn-block rounded-pill" onClick={closeModal}>
                    {t("screens:verificationMonitoring.close")}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      );
    }
    else {
      return (
        <div >
          <Modal isOpen={isOpen} size="lg" className="nb-modal" toggle={closeModal}>
            <ModalHeader>
              <h4>{t("screens:verificationMonitoring.proofDetails")}</h4>
            </ModalHeader>
            <ModalBody>
              <div className="row mb-3" key={data.id}>
                <div className="col-sm-12" key={data.id}>
                  <p className='nb-label'>{t("screens:verificationMonitoring.messageNoProof")}.</p>

                </div>
              </div>
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-3">

                </div>
                <div className="col-md-3">
                  <Button variant="secondary" className="btn btn-secondary btn-block rounded-pill" onClick={closeModal}>
                    {t("screens:verificationMonitoring.close")}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )
    }
  }
}

