import * as React from 'react';
import { connect } from 'react-redux';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import CredentialValueComponent from '../../../Common Components/Components/CredentialValue';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { importCredentialsAction } from '../actions/ImportCredentialAction';
import { addNewImportCredentialRecord } from '../../commonConst';
import { issuanceAction } from '../../IssueCredential/actions/issuanceAction';

export interface ISingleCredentialDetailsProps {
    TaskReducers: any,
    IssuanceReducer: any,
    ConnectionReducer: any,
    importCredentialsAction: any,
    issuanceAction: any
}

export interface ISingleCredentialDetailsState {
    clearTypeahead: boolean,
    isSelected: boolean,
    selectedCredDef: any,
    credDefId: any,
    connectionId: any,
    selectedConnection: any,
    isModalShow: boolean,
    credentialValues: Array<any>,
    theirDid: string
}

interface AddNewImportCredentialsRecord {
    connectionIds: string,
    credentialDefinitionId: string,
    attributeValues: string
}

class SingleCredentialDetails extends React.Component<ISingleCredentialDetailsProps, ISingleCredentialDetailsState> {
    constructor(props: ISingleCredentialDetailsProps) {
        super(props);

        this.state = {
            clearTypeahead: false,
            isSelected: false,
            selectedCredDef: {},
            credDefId: '',
            selectedConnection: {},
            connectionId: '',
            isModalShow: false,
            credentialValues: [],
            theirDid: ''
        }
        this.handleCancel = this.handleCancel.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleCredDefChange = this.handleCredDefChange.bind(this)
        this.handleConnectionChange = this.handleConnectionChange.bind(this)
        this.handleInputChangeForConnection = this.handleInputChangeForConnection.bind(this)
        this.showModal = this.showModal.bind(this)
        this.handleCredentialValueSubmit = this.handleCredentialValueSubmit.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.saveDetails = this.saveDetails.bind(this)
    }

    handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
        this.setState({
            clearTypeahead: false,
            isSelected: false,
            selectedCredDef: {},
            credDefId: '',
            selectedConnection: {},
            connectionId: '',
            isModalShow: false,
            credentialValues: [],
            theirDid: ''
        })
        this.props.issuanceAction.clearIssuanceCredential();
        event.preventDefault()
    }
    handleInputChange() {
        this.setState({
            isSelected: false,
            credDefId: ''
        })
    }
    handleCredDefChange(selectedOptions: any) {
        let selectedData = selectedOptions[0];
        this.setState({
            clearTypeahead: false,
            isSelected: true,
            selectedCredDef: selectedData,
            credDefId: selectedData && selectedData.credentialDefinitionId,
        })
    }
    handleConnectionChange(selectedOptions: any) {
        let selectedData = selectedOptions[0];
        this.setState({
            clearTypeahead: false,
            isSelected: true,
            selectedConnection: selectedData,
            connectionId: selectedData && selectedData.connectionId,
            theirDid: selectedData && selectedData.theirDid,
        })
    }
    handleInputChangeForConnection() {
        this.setState({
            isSelected: false,
            connectionId: ''
        })
    }
    showModal() {
        this.setState({
            isModalShow: true,
        })
    }
    handleCredentialValueSubmit(credentialValue: any) {
        const { selectedCredDef } = this.state
        const matchedCredentialValues: any = {}
        if (selectedCredDef && selectedCredDef.schema) {
            selectedCredDef.schema.attributes.map((ele: string, index: number) => {
                const key: string = JSON.parse(ele).name
                matchedCredentialValues[key] = credentialValue[index]
            }
            )
            this.setState({
                credentialValues: matchedCredentialValues
            })
        }
    }
    closeModal() {
        this.setState({
            isModalShow: false,
        })
    }
    saveDetails(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedCredDef: any, selectedConnection: any,) {
        const { credentialValues } = this.state
        const addNewImportCredentialsRecord: AddNewImportCredentialsRecord = {
            connectionIds: selectedConnection.connectionId,
            credentialDefinitionId: selectedCredDef.credentialDefinitionId,
            attributeValues: JSON.stringify(credentialValues)
        }
        this.props.importCredentialsAction.addNewImportCredentialsRecord(addNewImportCredentialsRecord)
    }

    public render() {
        const { createdCredDefsbyOrgId } = this.props.TaskReducers;
        const { connectionList } = this.props.ConnectionReducer;
        const {
            clearTypeahead,
            selectedCredDef,
            credentialValues,
            isModalShow,
            selectedConnection,
            theirDid
        } = this.state
        const { credentialName, connectionName, filledCredentialValue } = this.props.IssuanceReducer;
        return (
            <>
                <div className="card nb-card">
                    <div className="card-header bg-white border-bottom-0">
                        <h1 className="nb-title">{addNewImportCredentialRecord.ADD_IMPORT_CREDENTIAL_HEADER}</h1>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CREDENTIAL_NAME}</label>
                                    {credentialName ?
                                        <input type="text" className="form-control"
                                            name="connectionId"
                                            value={credentialName}
                                            disabled
                                        />
                                        :
                                        <Typeahead data={createdCredDefsbyOrgId} onChange={this.handleCredDefChange} onInputChange={this.handleInputChange}
                                            clearTypeahead={clearTypeahead} placeholder="Select Credential Name" disabled={false} />
                                    }
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CONNECTION_NAME}</label>
                                    {connectionName ?
                                        <input type="text" className="form-control"
                                            name="connectionId"
                                            value={connectionName}
                                            disabled
                                        />
                                        :
                                        <Typeahead data={connectionList} onChange={this.handleConnectionChange} onInputChange={this.handleInputChangeForConnection}
                                            clearTypeahead={clearTypeahead} placeholder="Select Connection" disabled={false} />
                                    }
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CONNECTION_DID}</label>
                                    <input type="text" className="form-control"
                                        name="connectionId"
                                        value={theirDid}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CREDENTIAL_VALUE}
                                        <button className="btn btn-link"
                                            onClick={this.showModal}
                                            disabled={!_.isEmpty(selectedCredDef) ? false : true}
                                        >
                                            <i className="fas fa-pencil-alt"></i>
                                        </button>
                                    </label>
                                    <div className="card" style={{ minHeight: '258px' }}>
                                        <div className="card-body bg-light">
                                            {!_.isEmpty(filledCredentialValue) ? filledCredentialValue.credential_proposal &&
                                                filledCredentialValue.credential_proposal.attributes.map((ele: any, index: any) => {
                                                    return (
                                                        <>
                                                            <li key={index}>{ele.name}:{ele.value}</li>
                                                        </>
                                                    )
                                                })
                                                :
                                                !_.isEmpty(credentialValues) && selectedCredDef && Object.keys(selectedCredDef).length >= 1 &&
                                                selectedCredDef.schema && selectedCredDef.schema.attributes &&
                                                selectedCredDef.schema.attributes.map((ele: any, index: any) => {
                                                    return (
                                                        <>
                                                            <li key={index}>{

                                                                JSON.parse(ele).name}({JSON.parse(ele).value}) :  {credentialValues[JSON.parse(ele).name]
                                                                }
                                                            </li>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                        <CredentialValueComponent
                                            isModalShow={isModalShow}
                                            selectedConnection={selectedConnection}
                                            selectedCredDef={selectedCredDef && selectedCredDef.schema}
                                            handleCredentialValueSubmit={this.handleCredentialValueSubmit}
                                            closeModal={this.closeModal}
                                            isEdit={true}
                                            modalTitle={''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                                    onClick={(e) => this.handleCancel(e)}
                                >
                                    {addNewImportCredentialRecord.CANCEL_BUTTON}
                                </button>
                            </div>
                            <div className="col-sm-6">

                                <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                                    onClick={(e) => this.saveDetails(e, selectedCredDef, selectedConnection)}
                                    disabled={!_.isEmpty(credentialValues) ? false : true}
                                >
                                    {addNewImportCredentialRecord.SAVE_BUTTON}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}


function mapStateToProps(state: any) {
    return {
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        importCredentialsAction: bindActionCreators(importCredentialsAction, dispatch),
        issuanceAction: bindActionCreators(issuanceAction, dispatch),
    }
}

const connectedSingleCredentialDetails = connect(mapStateToProps, mapDispatchToProps)(SingleCredentialDetails);
export { connectedSingleCredentialDetails as SingleCredentialDetails };
