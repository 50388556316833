import _ from 'lodash';
import * as React from 'react';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ISuccessPageProps {
  issuanceAction: any,
  IssuanceReducer: any

}

export interface ISuccessPageState {
}

export default class SuccessPage extends React.Component<ISuccessPageProps, ISuccessPageState> {
  constructor(props: ISuccessPageProps) {
    super(props);

    this.state = {
    }
  }

  issueanotherCredential(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    this.props.issuanceAction.issueCredentialComponent();
  }
  public render() {
    let { showIssueCredential, issuedCredential,connectionNames } = this.props.IssuanceReducer;
    let credName = !_.isEmpty(issuedCredential) && !_.isEmpty(issuedCredential.data.data) && issuedCredential?.data?.data?.credential_definition_id?.split(':')[4];

    return (
      <>
        <div className="card h-100">
          <div className="card-body text-center">
            <div className="row">
              <div className="col-sm-12 mb-5 mt-5">
                <img src={images.illustrationSuccess} alt="Success" />
              </div>
              <div className="col-sm-12">
                <h3 className="nb-label">{credName} {t("screens:issueCredentials.messageIssued1")} {connectionNames} {t("screens:issueCredentials.messageIssued2")}.</h3>
              </div>
              <div className="col-sm-12">
                <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                  onClick={(e) => this.issueanotherCredential(e)}
                >
                  {t("screens:issueCredentials.issueAnotherCredential")}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
