import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginAction } from '../../../Login/actions/loginAction';
import { schemaAction } from '../../../Schema/actions/schemaAction';
import { OrgProfileAction } from '../actions/orgProfileAction';
import OrgProfile from '../components/OrgProfile';

export interface IProfileProps {
  LoginReducer: any,
  loginAction: any,
  OrgProfileAction: any,
  orgProfileReducer: any,
  schemaAction: any,
  SchemaReducer: any,
}

export interface IProfileState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  schemaSortBy: string,
}

class Profile extends React.Component<IProfileProps, IProfileState> {
  constructor(props: IProfileProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      schemaSortBy: '',
    }
  }

  componentDidMount() {
    this.props.loginAction.getCurrentUser();
    // const { defaultSearchFields, item_perPage, pageNo, sortingOrder, schemaSortBy } = this.state;
    // this.props.schemaAction.getAllSchema(defaultSearchFields, item_perPage, pageNo, 'schema', sortingOrder, schemaSortBy);
    this.props.OrgProfileAction.getAllActivityofUser();

  }

  public render() {
    return (
      <>
        <OrgProfile {...this.props} />
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const orgProfileReducer = state.orgProfileReducer;
  const SchemaReducer = state.SchemaReducer;
  return { LoginReducer, orgProfileReducer, SchemaReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    OrgProfileAction: bindActionCreators(OrgProfileAction, dispatch),
    schemaAction: bindActionCreators(schemaAction, dispatch),
  }
}

const connectedProfile = connect(mapStateToProps, mapDispatchToProps)(Profile);
export { connectedProfile as Profile }
