import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CredDefResponsibilityType } from 'src/View/IssuersVerifiers/interfaces';
import { addNewImportCredentialRecord } from '../../commonConst';
import { connectionAction } from '../../Connection/actions/connectionAction';
import { TaskActions } from '../../Task/actions/taskActions';
import { importCredentialsAction } from '../actions/ImportCredentialAction';
import { ImportCredentialsList } from '../components/ImportCredentialsList';
import { ImportCredentialsSuccess } from '../components/ImportCredentialSuccess';
import { ShowImportCredentialsDetails } from '../components/ShowImportCredentialsDetails';
import { SingleCredentialDetails } from '../components/SingleCredentialDetails';

export interface IImportCredentialsProps {
    importCredentialsAction: any,
    ImportCredentialsReducer: any,
    TaskReducers: any,
    IssuanceReducer: any,
    TaskActions: any,
    ConnectionReducer: any,
    connectionAction: any,
    LoginReducer: any
}

export interface IImportCredentialsState {
    defaultSearchFields: string,
    connectionFilterValue: string,
    sortingOrder: string,
    connectionSortBy: string,
    item_perPage: number,
    pageNo: number,
}

class ImportCredentials extends React.Component<IImportCredentialsProps, IImportCredentialsState> {
    constructor(props: IImportCredentialsProps) {
        super(props);

        this.state = {
            defaultSearchFields: '',
            connectionFilterValue: 'all',
            sortingOrder: 'DESC',
            connectionSortBy: '',
            item_perPage: 0,
            pageNo: 1,
        }
    }

    componentDidMount() {
        const { loggedInUser } = this.props.LoginReducer;
        const { defaultSearchFields, connectionFilterValue, sortingOrder, connectionSortBy, item_perPage, pageNo } = this.state
        this.props.TaskActions.getAllCreateCredDefByOrgId(loggedInUser.organization.id, CredDefResponsibilityType.Issuance);
        this.props.connectionAction.getAllConnections(defaultSearchFields, connectionFilterValue, sortingOrder, connectionSortBy, item_perPage, pageNo);
    }

    public render() {
        const { TaskReducers, IssuanceReducer, ConnectionReducer, ImportCredentialsReducer } = this.props
        const { importCredentialList, isShowSelectedImportCredential, isImportCredentialSuccess } = this.props.ImportCredentialsReducer
        return (
            <>
                <div className="row gutters-sm min-h-100">
                    <div className="col-md-12 col-lg-6 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{addNewImportCredentialRecord.IMPORT_CREDENTIAL_LIST}</h1>
                            </div>
                            <div className="card-body">
                                {importCredentialList &&
                                    <ImportCredentialsList
                                        ImportCredentialsReducer={ImportCredentialsReducer}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    {isImportCredentialSuccess ?
                        <>
                            <div className="col-md-12 col-lg-6 mb-30">
                                <ImportCredentialsSuccess />
                            </div>
                        </>
                        :
                        <>
                            {!isShowSelectedImportCredential ?
                                <div className="col-md-12 col-lg-6 mb-30">
                                    <SingleCredentialDetails
                                        TaskReducers={TaskReducers}
                                        IssuanceReducer={IssuanceReducer}
                                        ConnectionReducer={ConnectionReducer}
                                    />
                                </div>
                                :
                                <div className="col-md-12 col-lg-6 mb-30">
                                    <ShowImportCredentialsDetails
                                        ImportCredentialsReducer={ImportCredentialsReducer}
                                    />
                                </div>
                            }
                        </>
                    }
                </div>
            </>
        );
    }
}


function mapStateToProps(state: any) {
    const { loggingIn } = state.LoginReducer;
    const LoaderReducer = state.LoaderReducer;
    const ImportCredentialsReducer = state.ImportCredentialsReducer
    const TaskReducers = state.TaskReducers;
    const IssuanceReducer = state.IssuanceReducer;
    const ConnectionReducer = state.ConnectionReducer;

    return { loggingIn, LoaderReducer, ImportCredentialsReducer, TaskReducers, IssuanceReducer, ConnectionReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        importCredentialsAction: bindActionCreators(importCredentialsAction, dispatch),
        TaskActions: bindActionCreators(TaskActions, dispatch),
        connectionAction: bindActionCreators(connectionAction, dispatch),
    }
}

const connectedImportCredentials = connect(mapStateToProps, mapDispatchToProps)(ImportCredentials);
export { connectedImportCredentials as ImportCredentials };
