import { t } from "i18next"

export const DIDDocEntries = ({ dnsName, ownerName, publicDID, handleDidDocReload }: any) => {
    return (<>
        <div className="col-md-12 mb-30">
            <div className="card nb-card h-100">
                <div className="card-header border-bottom-0" style={{ backgroundColor: '#F0F5FC' }}>
                    <div className="row">
                        <div className="col-sm-11">
                            <div className='media mb-3'>
                                <img src="assets/icons/checkmark-purple.svg" className="align-self-center mr-3" alt='badge' />
                                <div className='media-body align-self-center'>
                                    <h5><b>{t('screens:profile.didDocEntries')}</b></h5>
                                    <small className="text-muted">{t('screens:profile.didDocEntriesDescription')}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1">
                            <button
                                className="btn btn-default"
                                onClick={handleDidDocReload}
                            >
                                <i className="fa fa-repeat" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <p>
                                <i className="fa fa-globe"></i>&nbsp;
                                {t('screens:profile.labelDNS')}
                            </p>
                            <p>{dnsName ? dnsName : t('screens:profile.noRecordFound')}</p>
                        </div>
                        <div className="col-sm-12 form-group">
                            <p>
                                <i className="fa fa-user"></i>&nbsp;
                                {t("screens:profile.labelOrgName")}
                            </p>
                            <p>{ownerName}</p>
                        </div>
                        <div className="col-sm-12 form-group pb-5">
                            <p>
                                <i className="fa fa-bars"></i>&nbsp;
                                {t("screens:profile.publicDid")}
                            </p>
                            <p>{publicDID}</p>
                        </div>
                        <div className="col-sm-12 form-group">

                            <div className="alert-light" style={{ backgroundColor: "#FAFAFA" }}>
                                <div className="row">
                                    <div className="col-sm-1 text-right">
                                        <i className="fa fa-info-circle"></i>&nbsp;
                                    </div>
                                    <div className="col-sm-11">
                                        {t("screens:profile.didEntriesNote1")}
                                        <br />
                                        {t("screens:profile.didEntriesNote2")}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
