import React from "react";
import { availableClaimCredentialsList } from "../../commonConst";

// Utilities
import images from "src/utils/images";


interface IIssueClaimCredentialsProps {
    onShowClaimCredential: any,
    selectedChatConnection: any,
    chatsAction: any,
    availableCredentialList: any,
    receivedCredentialCount: any,
    isReadyToShowSelectedClaimCredential: boolean,
    selectedClaimCredential: any,
    availableCredentialCount: any,
    claimCredentialPageNo: number,
    claimCredentialItemsPerPage: number,
    claimCredentialSearchText: string
}
interface IIssueClaimCredentialsState {
    pageNo: number,
    itemsPerPage: number,
    searchText: string
}
export default class IssueClaimCredentials extends React.Component<IIssueClaimCredentialsProps, IIssueClaimCredentialsState> {

    constructor(props: IIssueClaimCredentialsProps) {
        super(props)
        this.state = {
            pageNo: 1,
            itemsPerPage: 0,
            searchText: ''
        }
        // Bind all the methods.
        this.closeForm = this.closeForm.bind(this)
        this.onRefreshAvailableCredentialList = this.onRefreshAvailableCredentialList.bind(this)
        this.selectedClaimCredentialRecord = this.selectedClaimCredentialRecord.bind(this)
        this.issueClaimCredential = this.issueClaimCredential.bind(this)
    }

    /**
     * Method used to close the claim credential module and show messages
     * @param event 
     */
    closeForm(event: any) {
        this.props.onShowClaimCredential()
    }

    /**
     * Method used to refresh the available claim credential list
     */
    onRefreshAvailableCredentialList() {
        const { selectedChatConnection } = this.props;
        const { pageNo, itemsPerPage, searchText } = this.state
        this.props.chatsAction.getAllClaimCredentialsDetails(selectedChatConnection.connectionId, pageNo, itemsPerPage, searchText)
    }

    /**
     * Method used to show the claim credential details
     * @param selectedRecord 
     */
    selectedClaimCredentialRecord(selectedRecord: any) {
        this.props.chatsAction.showSelectedClaimCredentialDetails(selectedRecord)
    }

    /**
     * Method used to issue claim credential.
     * @param selectedClaimCredential 
     */
    issueClaimCredential(selectedClaimCredential: any) {
        this.props.chatsAction.issueClaimCredential(selectedClaimCredential.id)
    }

    componentDidMount() {
        const { selectedChatConnection, claimCredentialPageNo, claimCredentialItemsPerPage, claimCredentialSearchText } = this.props;
        this.props.chatsAction.getAllClaimCredentialsDetails(selectedChatConnection.connectionId, claimCredentialPageNo, claimCredentialItemsPerPage, claimCredentialSearchText)
    }

    public render() {
        const { availableCredentialList, selectedChatConnection, receivedCredentialCount,
            isReadyToShowSelectedClaimCredential, selectedClaimCredential, availableCredentialCount } = this.props

        return (
            <>
                <div className="col-md-12 col-lg-6 mb-30">
                    <div className="card nb-card h-100">
                        <div className="card-header bg-white border-bottom-0">
                            <div className="row mb-3">
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <h1 className="nb-title">{availableClaimCredentialsList.CONNECTION_DETAILS}</h1>
                                        </div>
                                        <button type="button" className="btn btn-light"
                                            onClick={this.onRefreshAvailableCredentialList}
                                        ><i className="nb-ico nb-refresh"></i></button>
                                    </div>
                                </div>
                                <div className="col-sm-4 text-right">
                                    <button type="button" className="btn"
                                        onClick={(e) => this.closeForm(e)}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mb-5">
                                <div className="col-sm-2">
                                    <div className="col-sm-3">
                                        <img src={images.placeholderProfile} alt="profile" style={{ height: '80px', width: '80px' }} className="user-icon" />
                                    </div>
                                </div>
                                <div className="col-sm-10">
                                    <span className="h5 mr-2">{selectedChatConnection.theirLabel}</span>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12 col-lg-12 ">
                                    <span className="h5 mr-2">{availableClaimCredentialsList.RECEIVED_CREDENTIALS}</span>{receivedCredentialCount > 0 ?
                                        <>
                                            <span className="h5 mr-2">({receivedCredentialCount})</span>
                                            <small>(Click to view details)</small>
                                        </>
                                        :
                                        <>
                                            <small>Not received yet...</small>
                                        </>
                                    }
                                    <div className="row mt-3">
                                        {
                                            availableCredentialList.length > 0 && availableCredentialList.map((element: any, index: number) => {
                                                if (element && element.credentialExchangeId && (element.credentialExchangeId.state === 'credential_acked' || element.credentialExchangeId.state === 'credential_issued')) {
                                                    return (
                                                        <div className="col-sm-4" key={index}>
                                                            <div className="form-group">
                                                                <button key={index} type="button"
                                                                    className="btn btn-block btn-white mr-3 mb-3" data-toggle="modal"
                                                                    onClick={() => this.selectedClaimCredentialRecord(element)}
                                                                > <div className='overflow-eclips m-auto font-weight-bold'>
                                                                        {element.credentialDefinitionId.split(':')[4]}
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                        {availableCredentialList.length === 0 &&
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>{availableClaimCredentialsList.CREDENTIAL_NOT_AVAILABLE_MESSAGE}</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12 col-lg-12">
                                    <span className="h5 mr-2">{availableClaimCredentialsList.AVAILABLE_CREDENTIALS}</span>{
                                        availableCredentialCount > 0 ?
                                            <>
                                                <span className="h5 mr-2">({availableCredentialCount})</span>
                                                <small>(Click to view details)</small>
                                            </>
                                            :
                                            <>
                                                <small>Currently not available...</small>
                                            </>
                                    }
                                    <div className="row mt-3">
                                        {
                                            availableCredentialList.length > 0 && availableCredentialList.map((element: any, index: number) => {
                                                if (element.credentialExchangeId === null) {
                                                    return (
                                                        <div className="col-sm-4" key={index}>
                                                            <div className="form-group">
                                                                <button key={index} type="button"
                                                                    className="btn btn-block btn-white mr-3 mb-3" data-toggle="modal"
                                                                    onClick={() => this.selectedClaimCredentialRecord(element)}
                                                                > <div className='overflow-eclips m-auto font-weight-bold'>
                                                                        {element.credentialDefinitionId.split(':')[4]}
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                        {availableCredentialList.length === 0 &&
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>{availableClaimCredentialsList.CREDENTIAL_NOT_AVAILABLE_MESSAGE}</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div>
                                {isReadyToShowSelectedClaimCredential && selectedClaimCredential &&
                                    <>
                                        <span className="h5 mr-2">{availableClaimCredentialsList.CREDENTIAL_DETAILS}</span>
                                        <div className="card nb-card mt-3 shadow">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="media mb-3">
                                                            <img src='assets/icons/star-badge.svg' alt="" className="align-self-center mr-3" />
                                                            <div className="media-body">
                                                                <label>{selectedClaimCredential.credentialDefinitionId && selectedClaimCredential.credentialDefinitionId.split(':')[4]}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-8 align-self-center">
                                                        {selectedClaimCredential.attributeValues &&
                                                            Object.keys(JSON.parse(selectedClaimCredential.attributeValues)).map((key: string, index: number) => {
                                                                return (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-md-12 col-lg-6 col-xl-4">
                                                                            {key} :
                                                                        </div>
                                                                        <div className="col-md-12 col-lg-6 col-xl-8">
                                                                            {JSON.parse(selectedClaimCredential.attributeValues)[key]}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {selectedClaimCredential.credentialExchangeId && selectedClaimCredential.credentialExchangeId.state &&
                                                        (selectedClaimCredential.credentialExchangeId.state === 'credential_acked' ||
                                                            selectedClaimCredential.credentialExchangeId.state === 'credential_issued') &&
                                                        <div className="col-sm-4">
                                                            <label className="text-success">Credential issued</label>
                                                        </div>
                                                    }
                                                    {!selectedClaimCredential.credentialExchangeId &&
                                                        <div className="col-sm-4">
                                                            <button type="button" className="btn btn-sm btn-block btn-nb-outline-primary rounded-pill"
                                                                onClick={() => this.issueClaimCredential(selectedClaimCredential)}
                                                            >{availableClaimCredentialsList.CLAIM_BUTTON}</button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}