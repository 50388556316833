/**
 * Pause execution for the specified time
 *
 * NOTE: This function should only be used for debug purposes!
 *
 * @param time - Time to pause execution
 */
const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export { sleep };