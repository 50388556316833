import axios from "axios";
import { connectionConst, loaderConst } from "../../types";
import { config } from '../../../config';
import _ from "lodash";
import { loginAction } from "../../Login/actions/loginAction";
import { history } from '../../../index'
import { isJsonString } from "../../../utils/type";
import { toastr } from "react-redux-toastr";
import { t } from 'i18next';
import { UpdateAttributesOptions } from "../interfaces/UpdateAttributesOptions";
import { ConnectionRoutingState } from "../interfaces/enums/connection.enum";

enum ConnectionFor {
  ConnectWithPlatformAdmin = 'ConnectWithPlatformAdmin',
  OrganizationAdmin = 'organization-admin'
}

const getConnectionType = (alias: string): string => {
  let connectionType: string;
  if (alias === ConnectionFor.ConnectWithPlatformAdmin) {
    connectionType = `${t("screens:existingConnections.organization")}`;
  }
  else if (alias === ConnectionFor.OrganizationAdmin) {
    connectionType = `${t("screens:existingConnections.superAdmin")}`;
  }
  else {
    throw new Error(`${t("screens:existingConnections.messageNotFound")}`)
  }
  return connectionType;
}

const getConnectionTypeHolder = (type: any): string => {
  let connectionType: string;
  if (type === '2') {
    connectionType = `${t("screens:existingConnections.admin")}`;
  }
  else if (type === '3' || type === '4' || type === '5' || type === '7') {
    connectionType = `${t("screens:existingConnections.employee")}`;
  }
  else if (type === '6') {
    connectionType = `${t("screens:existingConnections.client")}`;
  }
  else {
    connectionType = type
  }
  return connectionType;
}

export const connectionAction = {
  //use
  getAllConnections(
    defaultSearchFields: any,
    filterValue: any,
    sortValue: string,
    connectionSortBy: string,
    itemsPerPage: number,
    pageNo: number,
    isDeleted: any,
    state?: ConnectionRoutingState,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })

        const params = {
          page: pageNo,
          itemsPerPage: itemsPerPage,
          ...(defaultSearchFields && { search_text: defaultSearchFields }),
          ...(filterValue && { type: filterValue }),
          ...(sortValue && { sortValue }),
          ...(connectionSortBy && { sortBy: connectionSortBy }),
          ...(isDeleted && { isDeleted }),
          ...(state && { state }),
        }

        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/connection/get-all-connection/by-orgID`,
          {
            headers: { "Authorization": `Bearer ${token}` },
            params
          })
          .then(connectionData => {
            let tempArray: any = [];

            tempArray = connectionData.data.data.data;
            tempArray.map((item: any, index: any) => {
              if (item.alias) {
                const type = `${(item.alias).split(':')[1]}`.toLowerCase();
                tempArray[index].holderType = isJsonString(item.alias) ?
                  getConnectionType(JSON.parse(item.alias).connectionFor) :
                  item.alias !== null ? `${t(`screens:existingConnections.${type}`)}` : ''
              }
              if (item.holders?.type) {
                tempArray[index].holderType = !_.isEmpty(item.holders) && item.holders.type ?
                  getConnectionTypeHolder(item.holders.type) : '';
              }

              tempArray[index].drivingLicenceKYCStatus = !_.isEmpty(item.holders) && item.holders.drivingLicenceKYCStatus;
              tempArray[index].passportKYCStatus = !_.isEmpty(item.holders) && item.holders.passportKYCStatus;
              tempArray[index].holderName = item.theirLabel; // !_.isEmpty(item.holders) ? item.holders.name :
              tempArray[index].employeeId = !_.isEmpty(item.holders) && item.holders.id;
              // Multiuse
              tempArray[index].connectionStatus = (item.state === 'invitation' && item.invitationMode === 'once') ? 'Sent' : (item.state === 'active') ? 'Active' : (item.invitationMode === 'multi') ? 'Active' : "";
              tempArray[index].type = !_.isEmpty(item.holders) && item.holders.type;
              tempArray[index].description = !_.isEmpty(item.invitationDescription) && item.invitationDescription;
              tempArray[index].usedBy = !_.isEmpty(item.usedBy) && item.usedBy;
              tempArray[index].email = !_.isEmpty(item.holders) ? item.holders.email : item.orgInvites && item.orgInvites.notificationEmail;
              tempArray[index].holderType = item.invitationMode === 'multi' ? 'Multi-use' : tempArray[index].holderType;


              // out-of-band
              if (item.connectionId !== null) {
                tempArray[index].theirDid = item.theirDid;
              } else {
                tempArray[index].theirDid = "";
              }
            })

            dispatch({
              type: connectionConst.GET_ALL_CONNECTIONS,
              payload: connectionData,
              tempArray,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllConnectionsDeleted(
    defaultSearchFields: any,
    filterValue: any,
    sortValue: string,
    connectionSortBy: string,
    item_perPage: number,
    pageNo: number,
    isDeleted: any,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/connection/get-all-connection/by-orgID?search_text=${defaultSearchFields}&type=${filterValue}&sortValue=${sortValue}&sortBy=${connectionSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}&isDeleted=${isDeleted}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(connectionData => {
            let tempArray: any = [];

            tempArray = connectionData.data.data.data;
            tempArray.map((item: any, index: any) => {
              // tempArray[index].holderType = isJsonString(item.alias) ?
              //   getConnectionType(JSON.parse(item.alias).connectionFor) :
              //   item.alias ? (item.alias).split(':')[1] :
              //     !_.isEmpty(item.holders) && item.holders.type ? getConnectionTypeHolder(item.holders.type) :
              //       tempArray[index].drivingLicenceKYCStatus = !_.isEmpty(item.holders) && item.holders.drivingLicenceKYCStatus;
              // tempArray[index].passportKYCStatus = !_.isEmpty(item.holders) && item.holders.passportKYCStatus;
              // tempArray[index].holderName = item.theirLabel; // !_.isEmpty(item.holders) ? item.holders.name :
              // tempArray[index].employeeId = !_.isEmpty(item.holders) && item.holders.id;
              // tempArray[index].lastChangedDateTime = moment(tempArray[index].lastChangedDateTime).format('MM-DD-YYYY')
              if (item.alias) {
                const type = `${(item.alias).split(':')[1]}`.toLowerCase();
                tempArray[index].holderType = isJsonString(item.alias) ?
                  getConnectionType(JSON.parse(item.alias).connectionFor) :
                  item.alias !== null ? `${t(`screens:existingConnections.${type}`)}` : ''
              }
              if (item.holders?.type) {
                tempArray[index].holderType = !_.isEmpty(item.holders) && item.holders.type ?
                  getConnectionTypeHolder(item.holders.type) : '';
              }

              tempArray[index].drivingLicenceKYCStatus = !_.isEmpty(item.holders) && item.holders.drivingLicenceKYCStatus;
              tempArray[index].passportKYCStatus = !_.isEmpty(item.holders) && item.holders.passportKYCStatus;
              tempArray[index].holderName = item.theirLabel; // !_.isEmpty(item.holders) ? item.holders.name :
              tempArray[index].employeeId = !_.isEmpty(item.holders) && item.holders.id;

              // Multiuse
              tempArray[index].connectionStatus = (item.state === 'invitation' && item.invitationMode === 'once') ? 'Sent' : (item.state === 'active') ? 'Active' : (item.invitationMode === 'multi') ? 'Active' : "";
              tempArray[index].type = !_.isEmpty(item.holders) && item.holders.type;
              tempArray[index].description = !_.isEmpty(item.invitationDescription) && item.invitationDescription;
              tempArray[index].usedBy = !_.isEmpty(item.usedBy) && item.usedBy;
              tempArray[index].email = !_.isEmpty(item.holders) && item.holders.email;
              tempArray[index].holderType = item.invitationMode === 'multi' ? 'Multi-use' : tempArray[index].holderType;

              // out-of-band
              if (item.connectionId !== null) {
                tempArray[index].theirDid = item.theirDid;
              } else {
                tempArray[index].theirDid = "";
              }
            })

            dispatch({
              type: connectionConst.GET_ALL_CONNECTIONS_DELETED,
              payload: connectionData,
              tempArray,
            })

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllPendingConnections(
    defaultSearchFields: any,
    filterValue: any,
    sortValue: string,
    connectionSortBy: string,
    item_perPage: number,
    pageNo: number,
    isDeleted: any,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/connection/get-all-pending-connection/by-orgID?search_text=${defaultSearchFields}&type=${filterValue}&sortValue=${sortValue}&sortBy=${connectionSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}&isDeleted=${isDeleted}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(connectionData => {
            let tempArray: any = [];

            tempArray = connectionData.data.data.data;
            tempArray.map((item: any, index: any) => {
              if (item.alias) {
                const type = `${(item.alias).split(':')[1]}`.toLowerCase();
                tempArray[index].holderType = isJsonString(item.alias) ?
                  getConnectionType(JSON.parse(item.alias).connectionFor) :
                  item.alias !== null ? `${t(`screens:existingConnections.${type}`)}` : ''
              }

              if (item.holders?.type) {
                tempArray[index].holderType = !_.isEmpty(item.holders) && item.holders.type ?
                  getConnectionTypeHolder(item.holders.type) : '';
                tempArray[index].email = item.holders.email
              }

              if (tempArray[index].holderType === 'ORGANIZATION') {
                if (item.organizationInvitation !== null) {
                  tempArray[index].email = !_.isEmpty(item.organizationInvitation) && item.organizationInvitation.toOrgEmail ?
                    item.organizationInvitation.toOrgEmail : '';
                }
                else {
                  tempArray[index].email = ''
                }
              }

              tempArray[index].drivingLicenceKYCStatus = !_.isEmpty(item.holders) && item.holders.drivingLicenceKYCStatus;
              tempArray[index].passportKYCStatus = !_.isEmpty(item.holders) && item.holders.passportKYCStatus;
              tempArray[index].holderName = item.theirLabel; // !_.isEmpty(item.holders) ? item.holders.name :
              tempArray[index].employeeId = !_.isEmpty(item.holders) && item.holders.id;
              // TODO Multiuse
              // tempArray[index].connectionStatus = item.state === 'invitation' ? 'Sent' : (item.state === 'active' ? 'Connected' : '');
              tempArray[index].connectionStatus = item.state === 'invitation' ? 'Sent' : (item.state === 'active' ? 'Active' : '');
              // out-of-band
              if (item.connectionId !== null) {
                tempArray[index].theirDid = item.theirDid;
              } else {
                tempArray[index].theirDid = "";
              }
            })
            dispatch({
              type: connectionConst.GET_ALL_PENDING_CONNECTIONS,
              payload: connectionData,
              tempArray,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllPAConnections(
    defaultSearchFields: any,
    type: any,
    sortValue: string,
    connectionSortBy: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/connection/get-all-connection/by-platform-admin?search_text=${defaultSearchFields}&type=${type}&sortValue=${sortValue}&connectionSortBy=${connectionSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(connectionData => {
            dispatch({
              type: connectionConst.PA_GET_ALL_CONNECTIONS,
              payload: connectionData,
            })
            !_.isEmpty(connectionData.data.data.data) &&
              this.getAllIssueCredentialByConnectionId(
                connectionData.data.data.data[0].connectionId,
                '',
                '',
                ''
                , 0,
                0,
                ''
              )

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  //use
  selectConnection(item: object | any, page: string) {
    return async (dispatch: any) => {
      dispatch({
        type: connectionConst.SELECTED_CONNECTION,
        payload: { item, page }
      })
      this.getAllIssueCredentialByConnectionId(item.connectionId, '', '', '', 0, 0, '')
    }
  },

  //Internal Credential List Selected Connection
  selectConnectionbyICL(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: connectionConst.SELECTED_CONNECTION_BY_ICL,
        payload: item
      })
      this.getAllIssueCredentialByConnectionId(item.connectionId, '', '', '', 0, 0, '')
      history.push('/connections')
    }
  },

  getAllIssueCredentialByConnectionId(
    connectionId: any,
    defaultSearchFields: any,
    credSortBy: string,
    sortValueParam: string,
    item_perPage: number,
    pageNo: number,
    status: any,
  ) {
    return async (dispatch: any) => {
      // debugger
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');

        return await axios.get(`${config.apiUrl}/issue-credential/get-all-issue-credential/${connectionId}?search_text=${defaultSearchFields}&sortValue=${credSortBy}&IssueCredSort=${sortValueParam}&itemsPerPage=${item_perPage}&page=${pageNo}&status=${status}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(issuedCredData => {
            return (
              dispatch({
                type: connectionConst.RECEIVED_CREDENTIAL,
                payload: issuedCredData
              }),
              dispatch({
                type: loaderConst.LOADER_FALSE
              })
            )
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);

            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectedReceivedCredentialRecord(credentialExchangeId: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/issue-credential/credential/by-cred-ex-id/${credentialExchangeId}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(selectedReceivedCredData => {
            return (
              dispatch({
                type: connectionConst.SELECTED_RECEIVED_CREDENTIAL,
                payload: selectedReceivedCredData
              }),
              dispatch({
                type: loaderConst.LOADER_FALSE
              })
            )
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  updateConnectionList(data: any, list: any) {
    return async (dispatch: any, getState: any) => {
      if (getState().ConnectionReducer.connectionListPageNo === 1) {
        dispatch({
          type: connectionConst.UPDATE_CONNECTION_LIST,
          payload: { list, data },
        })
      }
    }
  },

  updateReceivedCredentialList(data: any, list: any) {
    return async (dispatch: any, getState: any) => {
      if (getState().ConnectionReducer.receivedCredentialPageNo === 1) {
        dispatch({
          type: connectionConst.UPDATE_RECEIVED_CREDENTIAL_LIST,
          payload: { list, data },
        })
      }
    }
  },

  updatePageNo(pageNo: number) {
    return async (dispatch: any) => {
      dispatch({
        type: connectionConst.UPDATE_PAGE_NO,
        payload: pageNo
      })
    }
  },

  updateCredPageNo(pageNo: number) {
    return async (dispatch: any) => {
      dispatch({
        type: connectionConst.UPDATE_CRED_PAGE_NO,
        payload: pageNo
      })
    }
  },

  editConnection(selectedConnection: any) {
    return async (dispatch: any) => {
      dispatch({
        type: connectionConst.EDIT_CONNECTIONS,
        payload: selectedConnection,
      })
      this.getAllIssueCredentialByConnectionId(selectedConnection.connectionId, '', '', '', 0, 0, '')
    }
  },

  listView() {
    return async (dispatch: any) => {
      dispatch({
        type: connectionConst.LIST_VIEW_CONNECTION
      })
      // history.push('/edit-connections')
    }
  },

  defaultPage() {
    return async (dispatch: any) => {
      dispatch({
        type: connectionConst.DEFAULT_PAGE
      })
      // history.push('/edit-connections')
    }
  },

  viewExistingConnection(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: loaderConst.LOADER_TRUE
      })
      dispatch({
        type: connectionConst.VIEW_EXSITING_CONNECTION,
        payload: item,
      })
      this.getAllIssueCredentialByConnectionId(item.connectionId, '', '', '', 0, 0, '');
      dispatch({
        type: loaderConst.LOADER_FALSE
      })
    }
  },

  deleteConnection(selectedConnection: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.delete(`${config.apiUrl}/connection/delete-connection/${selectedConnection}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(connectionDeleted => {
            return (
              dispatch({
                type: connectionConst.CONNECTION_DELETED
              }),
              this.getAllConnections('', 'all', 'DESC', '', 10, 1, 'false'),
              toastr.success(`${t("screens:existingConnections.messageSuccess")}`, ``),
              dispatch({
                type: loaderConst.LOADER_FALSE
              })
            )
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllConnectedOrganizations(orgId: number, itemsPerPage: number, pageNo: number) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/connection/organization-connection/organization/${orgId}?itemsPerPage=${itemsPerPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(connectionData => {
            let tempArray: any = [];

            tempArray = connectionData.data.data.data;
            tempArray.map((item: any, index: any) => {
              tempArray[index].holderName = !_.isEmpty(item.holders) ? item.holders.name : item.theirLabel;
              tempArray[index].theirDid = item.theirDid;
            })

            dispatch({
              type: connectionConst.GET_ALL_CONNECTED_ORGS,
              payload: connectionData,
              tempArray,
            })

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  updateTheirPublicDid(theirDid: string, theirPublicDid: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.patch(`${config.apiUrl}/connection/their-did/${theirDid}/update-their-public-did?their_public_did=${theirPublicDid}`, '',
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(updatedPublicDIDResponse => {

            if (updatedPublicDIDResponse.data.affected === 1) {
              toastr.success(`${t("screens:existingConnections.messageUpdateSuccess")}`, ``)
            }
            else {
              toastr.error(`${t("screens:existingConnections.messageUpdateFailed")}`, ``)
            }

            this.getAllConnections('', 'all', 'DESC', '', 10, 1, 'false')

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  updateAttributes(theirDid: string, updateAttributes: UpdateAttributesOptions) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        // console.log(`updateAttributes => theirDid: ${theirDid} updateAttributes: ${JSON.stringify(updateAttributes)}`)
        return await axios.patch(`${config.apiUrl}/connection/update-attributes/${theirDid}`, updateAttributes,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(updatedAttributesResponse => {
            toastr.success(`${t("screens:existingConnections.messageUpdateSuccess")}`, ``)
            this.getAllConnections('', 'all', 'DESC', '', 10, 1, 'false')
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },
}
