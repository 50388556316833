import axios from "axios";
import { t } from "i18next";
import _ from "lodash";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { config } from "src/config";
import store from "src/store";
import { ListScreenOptions } from "src/types/common.interface";
import { CredentialState } from "src/View/commonConst";
import { loginAction } from "src/View/Login/actions/loginAction";
import { schemaAction } from "src/View/Schema/actions/schemaAction";
import { loaderConst, revokeCredentialConstants } from "src/View/types";

export const revokeCredentialActions = {

  issuedCredentialListById(orgId: number, listScreenOptions: ListScreenOptions) {
    return async (dispatch: any) => {
      // debugger;
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })

        let token = localStorage.getItem('token');

        return await axios.get(`${config.apiUrl}/issue-credential/issued/organization/${orgId}`,
          {
            headers: { "Authorization": `Bearer ${token}` }, params: {
              page: listScreenOptions.pageNo,
              items_per_page: listScreenOptions.itemsPerPage,
              search_text: listScreenOptions.search,
              credential_sort: listScreenOptions.sortBy,
              sort_value: listScreenOptions.sort
            }
          })
          .then(async issuedCredentialsListResponse => {
            let updatedPayload: any = issuedCredentialsListResponse.data.data.data
            updatedPayload.map(async (element: any, index: any) => {

              if (element.credentialDefinitionId === null) {
                updatedPayload[index].credentialDefinitionId = JSON.parse(element.credentialProposalDict).cred_def_id;
              }
              if (!_.isEmpty(element.connection)) {
                if (element.connection.id !== null) {
                  updatedPayload[index].connectionName = element.connection.theirLabel;
                } else {
                  updatedPayload[index].connectionName = "";
                }
              }
              else {
                updatedPayload[index].connectionName = "";
              }
              if (element.state === 'revoked') {
                updatedPayload[index].state = CredentialState.STATE_CREDENTIAL_REVOKED
              }
              if (element.revokedDateTime) {
                updatedPayload[index].revokedDateTime = moment(element.revokedDateTime).format('MM-DD-YYYY')
              }
            })

            dispatch({
              type: revokeCredentialConstants.GET_ALL_ISSUED_CREDENTIALS_RECORDS,
              payload: issuedCredentialsListResponse,
              updatedPayload
            })

            if (updatedPayload.length) {
              dispatch({
                type: revokeCredentialConstants.SELECTED_ISSUED_CREDENTIAL_RECORD,
                payload: updatedPayload[0]
              })
              const defaultSelectedRecord = updatedPayload[0]
              
              this.selectedReceivedCredentialRecord(defaultSelectedRecord.credentialExchangeId)
              const schemaId = defaultSelectedRecord.credentialDefinitions && Object.keys(defaultSelectedRecord.credentialDefinitions).length
                ? defaultSelectedRecord.credentialDefinitions.schemaLedgerId
                : JSON.parse(defaultSelectedRecord.credentialProposalDict)?.schema_id

              dispatch(schemaAction.getSchemaById(schemaId))
            }
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  showSelectedCredentialDetails(selectedCredentialDetails: any) {
    return async (dispatch: any) => {
      try {
        return (
          /* This dispatches method dispatch the stored credential details. */
          dispatch({
            type: revokeCredentialConstants.SELECTED_ISSUED_CREDENTIAL_RECORD,
            payload: selectedCredentialDetails
          })
        )
      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  selectedReceivedCredentialRecord(credentialExchangeId: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/issue-credential/credential/by-cred-ex-id/${credentialExchangeId}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(selectedReceivedCredData => {
            return (
              dispatch({
                type: revokeCredentialConstants.SELECTED_RECEIVED_CREDENTIAL,
                payload: selectedReceivedCredData
              }),
              dispatch({
                type: loaderConst.LOADER_FALSE
              })
            )
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  revokeCredential(selectedReceivedCredData: any, selectedComment: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let finalData = {
          connection_id: selectedReceivedCredData.connectionIds,
          cred_ex_id: selectedReceivedCredData.credentialExchangeId,
          cred_rev_id: selectedReceivedCredData.revocationId,
          rev_reg_id: selectedReceivedCredData.revocRegId,
          thread_id: selectedReceivedCredData.threadId,
          comment: selectedComment
        }

        let token = localStorage.getItem('token');
        return await axios.post(`${config.apiUrl}/issue-credential/revoke`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async (revokeCredentialData) => {
            setTimeout(() => {
              dispatch({
                type: revokeCredentialConstants.SELECTED_RECEIVED_CREDENTIAL,
                payload: revokeCredentialData
              })
            }, 2000);
            const listScreenOptions = {
              search: '',
              itemsPerPage: 10,
              pageNo: 1,
              sortBy: 'id',
              sort: 'DESC',
            }
            const { organization } = await store.getState().LoginReducer.loggedInUser
            this.issuedCredentialListById(organization.id, listScreenOptions)

            toastr.success(`${t("screens:issueCredentials.toastRevokeSuccessfully")}`, `${(selectedReceivedCredData.credentialDefinitionId).split(':')[4]}`);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },
}
