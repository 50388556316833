import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RemoveOrganizationPermission from '../../../Common Components/Components/RemoveOrganizationPermission';
import TableComponents from '../../../Common Components/Components/TableComponent'
import { onboardingAction } from '../../Onboarding/actions/onboardingAction';
import { OnLedgerListAction } from '../actions/OnLedgerListActions';
import { filterOnLedgerList, tHeadOnLedgerList } from '../constants';

export interface IOnLedgerListProps {
  onboardingAction: any,
  onStopOrganization: any,
  onLedgerListAction: any,
  onLedgerListReducer: any,
  onStartOrganization: any,
  onRemoveOrganization: any
}

export interface IOnLedgerListState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: any,
  kycRecordListSortBy: any,
  status: any,
  isRemoveOrganization: boolean,
  selectedOrganizationForRemove: any
}

class OnLedgerList extends React.Component<IOnLedgerListProps, IOnLedgerListState> {
  constructor(props: IOnLedgerListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      kycRecordListSortBy: '',
      status: 'no',
      isRemoveOrganization: false,
      selectedOrganizationForRemove: {}
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sorting = this.sorting.bind(this);
    this.filter = this.filter.bind(this);
    this.onStopOrganization = this.onStopOrganization.bind(this)
    this.onStartOrganization = this.onStartOrganization.bind(this)
    this.onRemoveOrganization = this.onRemoveOrganization.bind(this)
    this.onRemovePermission = this.onRemovePermission.bind(this)
    this.onCloseRemovePermissionModal = this.onCloseRemovePermissionModal.bind(this)
  }

  componentDidMount() {
    let { defaultSearchFields, status, sortingOrder, kycRecordListSortBy, item_perPage, pageNo } = this.state;
    this.props.onLedgerListAction.getAllOnBoardedOrganizationList(
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo);
  }

  onChangePage(page: any) {
    let { defaultSearchFields, status, sortingOrder, kycRecordListSortBy, item_perPage, pageNo } = this.state;
    this.setState({ pageNo: page })
    this.props.onLedgerListAction.getAllOnBoardedOrganizationList(
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      page);
  }

  showDetails(item: any) {
    this.props.onboardingAction.acceptOnboarding(item.id);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { status, item_perPage, sortingOrder, kycRecordListSortBy, pageNo } = this.state;
    if (event.key === 'Enter') {
      this.props.onLedgerListAction.getAllOnBoardedOrganizationList(
        event.target.value,
        status,
        kycRecordListSortBy,
        sortingOrder,
        item_perPage,
        pageNo
      );
    }

  }

  sorting = (kycRecordListSort: string) => {
    const { defaultSearchFields, status, item_perPage, sortingOrder, kycRecordListSortBy, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', kycRecordListSortBy: kycRecordListSort }) : this.setState({ sortingOrder: 'DESC', kycRecordListSortBy: kycRecordListSort });
    this.props.onLedgerListAction.getAllOnBoardedOrganizationList(
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    );
  }

  refresh = (event: any) => {
    const { defaultSearchFields, status, sortingOrder, kycRecordListSortBy, item_perPage, pageNo } = this.state;

    this.props.onLedgerListAction.getAllOnBoardedOrganizationList(
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    );
  }

  closeModal = () => {
  }

  filter(seletedFilterValue: any) {
    console.log("filter", seletedFilterValue);
    const { defaultSearchFields, sortingOrder, kycRecordListSortBy, item_perPage, pageNo } = this.state;
    this.setState({ status: seletedFilterValue })
    this.props.onLedgerListAction.getAllOnBoardedOrganizationList(
      defaultSearchFields,
      seletedFilterValue,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo);
  }

  onStopOrganization(selectedOrganization: any) {
    const { defaultSearchFields, status, item_perPage, sortingOrder, kycRecordListSortBy, pageNo } = this.state;
    this.props.onStopOrganization(
      selectedOrganization.id,
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    )
  }

  onStartOrganization(selectedOrganization: any) {
    const { defaultSearchFields, status, item_perPage, sortingOrder, kycRecordListSortBy, pageNo } = this.state;
    this.props.onStartOrganization(
      selectedOrganization.id,
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    )
  }

  onRemovePermission(selectedOrganization: any) {
    this.setState({ isRemoveOrganization: true, selectedOrganizationForRemove: selectedOrganization })
  }

  onCloseRemovePermissionModal() {
    this.setState({ isRemoveOrganization: false, selectedOrganizationForRemove: {} })
  }

  onRemoveOrganization() {
    const { defaultSearchFields, status, item_perPage, sortingOrder, kycRecordListSortBy, pageNo, selectedOrganizationForRemove } = this.state;
    this.props.onRemoveOrganization(
      selectedOrganizationForRemove.id,
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    )
    this.setState({ isRemoveOrganization: false })
  }
  public render() {
    
    const { onLedgerList, totalOrganizationRecords } = this.props.onLedgerListReducer
    const { isRemoveOrganization, selectedOrganizationForRemove } = this.state

    return (
      <div>
        <div className="card nb-card-noborder">
          <div className="card-header bg-white">
            <h1>Onboarded Organization</h1>
          </div>
          <div className="card-body">

            <TableComponents
              onChangePage={this.onChangePage}
              showElementDetails={this.showDetails}
              tHead={tHeadOnLedgerList}
              tableContent={onLedgerList}
              totalRecords={totalOrganizationRecords > 0 ? totalOrganizationRecords : 0}
              sorting={this.sorting}
              searching={this.onSearch}
              onStopOrganization={this.onStopOrganization}
              onStartOrganization={this.onStartOrganization}
              onRemoveOrganization={this.onRemovePermission}
              filter={filterOnLedgerList}
              onChangeFilter={this.filter}
              showTotalRecords={true}
            />
            {isRemoveOrganization &&
              <>
                <RemoveOrganizationPermission
                  isRemoveOrganization={isRemoveOrganization}
                  onCloseRemovePermissionModal={this.onCloseRemovePermissionModal}
                  selectedOrganizationForRemove={selectedOrganizationForRemove}
                  onRemoveOrganization={this.onRemoveOrganization}
                />
              </>}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const onLedgerListReducer = state.OnLedgerListReducer
  return { onLedgerListReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    onboardingAction: bindActionCreators(onboardingAction, dispatch),
    onLedgerListAction: bindActionCreators(OnLedgerListAction, dispatch)
  }
}

const connectedOnLedgerList = connect(mapStateToProps, mapDispatchToProps)(OnLedgerList);
export { connectedOnLedgerList as OnLedgerList };
