import { t } from "i18next";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginAction } from "src/View/Login/actions/loginAction";
import { presentationNegotiateAction } from "src/View/PresentationNegotiate/actions/PresentationNegotiateAction";
import { schemaAction } from "src/View/Schema/actions/schemaAction";
import { PresentationDeclinedList } from "../components/PresentationDeclinedList";
import { PresentationDetails } from "../components/PresentationDetails";

interface IPresentationDeclineProps {
    LoginReducer: any,
    PresentationNegotiateReducer: any,
    presentationNegotiateAction: any,
    schemaAction: any
}

interface IPresentationDeclineState {

}

class PresentationDecline extends React.Component<IPresentationDeclineProps, IPresentationDeclineState> {
    constructor(props: IPresentationDeclineProps) {
        super(props);

        this.state = {}
    }

    render(): React.ReactNode {
        return (
            <>
                <div className="row gutters-sm min-h-100">
                    <div className="col-md-12 col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:presentationDecline.presentationDeclineList")}</h1>
                            </div>
                            <div className="card-body">
                                {/* Component for get credential request list. */}
                                <PresentationDeclinedList {...this.props} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            {/* Component for show specific credential details */}
                            <PresentationDetails {...this.props} />
                        </div>
                    </div>

                </div>
            </>
        )
    }

}

function mapStateToProps(state: any) {
    const LoginReducer = state.LoginReducer;
    const PresentationNegotiateReducer = state.PresentationNegotiateReducer
    return {
        LoginReducer, PresentationNegotiateReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentationNegotiateAction: bindActionCreators(presentationNegotiateAction, dispatch),
        loginAction: bindActionCreators(loginAction, dispatch),
        schemaAction: bindActionCreators(schemaAction, dispatch),
    }
}

const presentationDecline = connect(mapStateToProps, mapDispatchToProps)(PresentationDecline);
export { presentationDecline as PresentationDecline };
