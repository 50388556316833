import _ from 'lodash';
import * as React from 'react';
import './Connection.css';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';
import NoRecordFoundComponent from 'src/Common Components/Components/NoRecordFound';

export interface IExistingConnectionDetailsProps {
  ConnectionReducer: any,
  connectionAction: any,
  selectedConnection: any,
}

export interface IExistingConnectionDetailsState {
  isCredentialSelected: boolean,
  count: number,
  isMoreSelected: boolean,
  isReceivedCredentialSelected: boolean,
}

export default class ExistingConnectionDetails extends React.Component<IExistingConnectionDetailsProps, IExistingConnectionDetailsState> {
  constructor(props: IExistingConnectionDetailsProps) {
    super(props);

    this.state = {
      isCredentialSelected: false,
      count: 4,
      isMoreSelected: false,
      isReceivedCredentialSelected: false,
    }
  }


  componentDidMount() {

    const { selectedConnection } = this.props.ConnectionReducer
    if (!_.isEmpty(selectedConnection)) {
      this.props.connectionAction.getAllIssueCredentialByConnectionId(selectedConnection.connectionId, '', '', '', 0, 0, '')
    }
  }

  deleteConnection(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any, selectedConnection: any): void {
    this.props.connectionAction.deleteConnection(selectedConnection.connectionId);
  }

  selectedReceivedCredentialRecord = async (credentialExchangeId: any) => {
    this.props.connectionAction.selectedReceivedCredentialRecord(credentialExchangeId)
    this.setState({
      isCredentialSelected: false,
      isReceivedCredentialSelected: true
    })
  }

  handleMore = () => {
    const { count } = this.state;
    this.setState({
      count: count + 4,
    })
  }

  public render() {
    const { selectedConnection, receivedCredential, viewExistingConnections, selectedReceivedCredData, isReceivedCredentialModalShow } = this.props.ConnectionReducer;
    const { count, isReceivedCredentialSelected } = this.state;

    let tagName;

    return (
      <>
        <div className="card nb-card h-90vh">
          <div className="card-header bg-white border-bottom-0">
            <h2 className="nb-title">{t("screens:existingConnections.connectionDetails")}</h2>
          </div>
          {(!_.isEmpty(selectedConnection)) ?
            <div className="card-body">
              <div className="list-group list-group-flush mb-3">
                <div className="list-group-item p-2">
                  <div className="row">
                    <div className="col-sm-6">
                      <label className="nb-label">{t("screens:existingConnections.givenName")}:</label>
                      <div>{selectedConnection.theirLabel ? selectedConnection.theirLabel : 'N/A'}</div>
                    </div>
                    <div className="col-sm-6">
                      <label className="nb-label">{t("screens:existingConnections.invitationType")}:</label>
                      <div>{selectedConnection.holderType ? selectedConnection.holderType : 'N/A'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-12">
                  <span className="nb-label">
                    {t("screens:existingConnections.credentialsIssued")} -
                  </span>
                  <small>({t("screens:existingConnections.clickDetails")})</small>
                  <div>
                    <div className="row mb-3">
                      {
                        receivedCredential && receivedCredential.length > 0
                          || !_.isEmpty(viewExistingConnections) && !_.isEmpty(receivedCredential)
                          ?
                          receivedCredential.slice(0, count).map((record: any, i: any) => {
                            tagName = record.credentialDefinitionId === null ? `${t("screens:existingConnections.credDefNull")}` :
                              record.credentialDefinitionId.split(':');
                            return (
                              record.credentialDefinitionId !== null &&
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <button key={i} type="button" title={
                                    record.credentialDefinitionId === null ? `${t("screens:existingConnections.credDefNull")}` :
                                      tagName[4]
                                  }
                                    className="btn btn-block btn-white mr-3 mb-3" data-toggle="modal"
                                    onClick={() => this.selectedReceivedCredentialRecord(record.credentialExchangeId)}
                                  >
                                    <div className="media mb-3 mt-3">
                                      <p className="text-overflow">{Array.isArray(tagName) ? tagName[4] : tagName}</p>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            )
                          }
                          )
                          :
                          <div className="col-sm-12">
                            <NoRecordFoundComponent />
                          </div>
                      }
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        {isReceivedCredentialSelected && selectedReceivedCredData && !_.isEmpty(selectedReceivedCredData) && isReceivedCredentialModalShow &&
                          <div className="card nb-card mb-3 shadow">
                            <div className="card-body">
                              {
                                Object.keys(selectedReceivedCredData).length >= 1 &&
                                  selectedReceivedCredData.credentialDefinitionId !== null ?
                                  <>
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <div className="media mb-3">
                                          <img src='assets/icons/star-badge.svg' className="align-self-center mr-3" />
                                          <div className="media-body">
                                            <h5 className="mt-0 title-overflow">{(selectedReceivedCredData.credentialDefinitionId).split(':')[4]}</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-12 align-self-center">
                                        {JSON.parse(selectedReceivedCredData.credentialProposalDict).credential_proposal.attributes.map((field: any, index: any) => {
                                          if (!field.hasOwnProperty("mime-type")) {
                                            return (
                                              <div className="row" key={index}>
                                                <div className="col-md-12 col-lg-6 col-xl-4">
                                                  {field.name} :
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-xl-8">
                                                  {field.value}
                                                </div>
                                              </div>
                                            )
                                          } else {
                                            return (
                                              <div className="row" key={index}>
                                                <div className="col-md-12 col-lg-6 col-xl-4">
                                                  {field.name} :
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-xl-8">
                                                  <img src={field.value} className="preview" height="50"></img>
                                                </div>
                                              </div>
                                            )
                                          }
                                        })}
                                      </div>

                                    </div>
                                  </> : <p>{t("screens:existingConnections.credentialNull")}</p>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>


                    {(receivedCredential && receivedCredential.length > 4) &&
                      <div className="col-sm-12 align-self-center">
                        <div className="form-group">
                          {
                            count >= receivedCredential.length ? <></> :
                              <button type="button"
                                className="btn nb-link"
                                onClick={() => this.handleMore()}
                              >{t("screens:existingConnections.seeMore")}</button>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>


                {/* {receivedCredential && receivedCredential.length >= 3 ||
                  !_.isEmpty(viewExistingConnections) && !_.isEmpty(receivedCredential) &&
                  <div className="col-sm-4 align-self-center">
                    <div className="form-group">
                      {
                        count === receivedCredential.length ? <button type="button"
                          className="btn nb-link"
                          onClick={() => this.handleMore()}
                        >{t("screens:existingConnections.seeMore")}</button> : <></>
                      }
                    </div>
                  </div>
                } */}
              </div>
              <div>
                {!_.isEmpty(selectedConnection) && !selectedConnection.isDeleted ?
                  <button type="button" className="btn btn-sm btn-block btn-nb-outline-primary rounded-pill"
                    onClick={(e) => this.deleteConnection(e, selectedConnection)}
                    disabled={_.isEmpty(selectedConnection)}
                  >{t("screens:existingConnections.deleteConnection")}</button> :
                  <></>
                }
              </div>
            </div> :
            <div className="col-sm-12">
              <NoRecordFoundComponent />
            </div>
          }
        </div>
      </>
    );
  }
}