import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import ShowQRCodeModal from './ShowQrCode';
import { t } from 'i18next';

let QRCode = require('qrcode.react');

export interface IScheduleTaskListDetailsTableProps {
  getAllInstantTaskAction: any,
  InstantTaskReducers: any,
  dataToShowScheduledTask: any,
  checkVerificationStatus: any,
}

export interface IScheduleTaskListDetailsTableState {
  defaultSearchFields: any,
  item_perPage: any,
  pageNo: any,
  sortingOrder: any,
  intantTaskSortBy: any,
  status: any,
  isModalShow: boolean,
}

export default class ScheduleTaskListDetailsTable extends React.Component<IScheduleTaskListDetailsTableProps, IScheduleTaskListDetailsTableState> {
  constructor(props: IScheduleTaskListDetailsTableProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      intantTaskSortBy: '',
      status: 'all',
      isModalShow: false,
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sorting = this.sorting.bind(this);
    this.refresh = this.refresh.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showQRModal = this.showQRModal.bind(this);
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, intantTaskSortBy, status } = this.state;
    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultSearchFields, status, sortingOrder, intantTaskSortBy, item_perPage, pageNo
    );
  }


  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, intantTaskSortBy, status } = this.state;
    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultSearchFields, status, sortingOrder, intantTaskSortBy, item_perPage, page
    );
  }

  showDetails(item: object | any) {
    this.props.getAllInstantTaskAction.selectedTask(item);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, pageNo, sortingOrder, intantTaskSortBy, status } = this.state;

    if (event.key === 'Enter') {
      this.props.getAllInstantTaskAction.getAllInstantTask(
        event.target.value, status, sortingOrder, intantTaskSortBy, item_perPage, pageNo
      );
    }
    this.setState({defaultSearchFields:event.target.value})
  }

  sorting = (connectionSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, intantTaskSortBy, status } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', intantTaskSortBy: connectionSortBy }) : this.setState({ sortingOrder: 'DESC', intantTaskSortBy: connectionSortBy });
    // let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultSearchFields, status, sortingOrder, intantTaskSortBy, item_perPage, pageNo
    );
  }


  refresh = (event: any) => {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, intantTaskSortBy, status } = this.state;
    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultSearchFields, status, sortingOrder, intantTaskSortBy, item_perPage, pageNo
    );
  }

  closeModal = () => {
    this.props.getAllInstantTaskAction.closeModal();
  }

  closeQRModal = () => {
    this.setState({
      isModalShow: false
    })
  }

  showQRModal = () => {
    this.setState({
      isModalShow: true,
    })
  }

  public render() {
    const { dataToShowScheduledTask, checkVerificationStatus } = this.props;
    const { isModalShow } = this.state;

    return (
      <>
        <table className="table nb-table">
          <thead>
            <tr>
              <th>{t("screens:verificationMonitoring.title")}</th>
              <th>{t("screens:verificationMonitoring.date")}</th>
              <th>{t("screens:verificationMonitoring.time")}</th>
              <th>{t("screens:verificationMonitoring.credReqInfo")}</th>
            </tr>
          </thead>
        </table>
        <div className="card">
          <div className="card-body">
            {
              !_.isEmpty(dataToShowScheduledTask) && dataToShowScheduledTask.map((ele: any, index: any) => {
                return (

                  <div className="row">
                    <div className="col-sm-4">
                      <ul>
                        <li className="mb-3"><i className="nb-ico nb-bag"></i> {t("screens:verificationMonitoring.workflowTitle")}: {!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_attributes.hasOwnProperty('title') ? ele.schedule_task_attributes.title : '--'}</li>
                        <li className="mb-3"><i className="nb-ico nb-calender-dark"></i> {t("screens:verificationMonitoring.workflowStartDate")}: {!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_attributes.hasOwnProperty('startDate') ? moment(ele.schedule_task_attributes.startDate).format('MM-DD-YYYY') : '--'}</li>
                        <li className="mb-3"><i className="nb-ico nb-time"></i> {t("screens:verificationMonitoring.workflowDuration")}: {!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_attributes.hasOwnProperty('duration') ? ele.schedule_task_attributes.duration : '--'}</li>
                        <li className="mb-3"><i className="nb-ico nb-calender-dark"></i> {t("screens:verificationMonitoring.workflowEndDate")}: {!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_attributes.hasOwnProperty('date') ? ele.schedule_task_attributes.date : '--'}</li>
                        <li className="mb-3"><i className="nb-ico nb-clip"></i> {t("screens:verificationMonitoring.workflowCredentialRequired")}: {!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_credDefId.split(':')[4]}</li>
                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <ul>
                        <li className="mb-3"><i className="nb-ico nb-bulb"></i> {t("screens:verificationMonitoring.workflowMethod")}: {!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_method}</li>
                        <li className="mb-3"><i className="nb-ico nb-location"></i> {t("screens:verificationMonitoring.workflowLocation")}: {!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_attributes.hasOwnProperty('location') ? ele.schedule_task_attributes.location : '--'}</li>
                        <li className="mb-3"><i className="nb-ico nb-issued-credentials"></i> {t("screens:verificationMonitoring.workflowNumberCredential")}: --</li>
                        <li className="mb-3"><i className="nb-ico nb-touch"></i> {t("screens:verificationMonitoring.workflowVerification")}: --</li>
                      </ul>
                    </div>
                    <div className="col-sm-4 text-center">
                      <div>
                        <QRCode value={!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_proofRequestUrl !== '' ? ele.schedule_task_proofRequestUrl : ''} size={100} />
                      </div>
                      <div>
                        <a className="btn nb-link small btn-sm mb-3"
                          onClick={(e) => this.showQRModal()}
                        >{t("screens:verificationMonitoring.workflowViewQRCode")}</a>
                      </div>
                      <ShowQRCodeModal
                        isOpen={isModalShow}
                        closeModal={this.closeQRModal}
                        QRData={!_.isEmpty(ele) && !_.isEmpty(ele.schedule_task_attributes) && ele.schedule_task_proofRequestUrl !== '' ? ele.schedule_task_proofRequestUrl : ''}
                      />
                      <div>
                        {!_.isEmpty(dataToShowScheduledTask) &&
                          <button className="btn btn-nb-outline-primary rounded-pill"
                            onClick={(e) => checkVerificationStatus(ele)}
                          >{t("screens:verificationMonitoring.workflowCheckStatus")}</button>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    );
  }
}
