import _ from 'lodash';
import * as React from 'react';
import images from 'src/utils/images';
import { myCredentials } from 'src/View/types';
import TypeaheadComponent from '../../../Common Components/Components/TypeaheadComponent';
import { t } from 'i18next';
import { history } from "../../../index";
import { getOperatorFromProofPredicateKey } from 'src/utils/strings';

enum PredicateType {
  LessThan = '<',
  LessThanOrEqualTo = '<=',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
}
interface AttributeValue {
  name: string,
  value: string
}

interface AttributeFilter {
  schema_id?: string
  schema_issuer_did?: string
  schema_name?: string
  schema_version?: string
  issuer_did?: string
  cred_def_id?: string
  attributeValue?: AttributeValue
}
interface RequestedAttributes {
  policyName: string,
  attributeNames: string[],
  restrictions: AttributeFilter[]
}

interface RequestedPredicates {
  policyName?: string,
  attributeName?: string,
  predicateType?: PredicateType | undefined,
  predicateValue?: number | undefined,
  restrictions?: AttributeFilter[]
}

export interface ICreatePresentationProposalProps {
  TaskReducers: any,
  TaskActions: any,
  connectionAction: any,
  ConnectionReducer: any,
  credentialAction: any,
  CredentialReducer: any,
  presentationProposalAction: any
  PresentationProposalReducer: any,
  MyCredentialReducer: any,
  myCredentialsAction: any,
  LoginReducer: any,
  presentationNegotiateAction: any
}

interface PolicyOptions {
  policyName: string,
  requestedAttributes: RequestedAttributes,
  requestedPredicates: RequestedPredicates[],
  selectedCredential: any[],
  isAllAttributesSelected?: boolean,
  selectedOrganization?: any,
}


export interface ICreatePresentationProposalState {
  defaultSearchFields: any,
  itemsPerPage: number,
  pageNo: number,
  sortingOrder: string,
  connectionSortBy: string,
  CredentialSortBy: string,
  clearTypeAhead: boolean,
  isSelected: boolean,
  clickedConnection: any[],
  isSelectedCredDef: boolean,
  isSelectedSchema: boolean,
  connectionFilterValue: any,
  attributeArray: string[],
  policyOptions: PolicyOptions[],
  clearTypeAheadOrganization: boolean,
  searchText: string,
  sortValue: string,
  sortBy: string,
  filterText: myCredentials,
  selfAttestedAttributeKey: string[],
  isDeleted: any,
  isValidProofRequest: boolean,
  isCredentialDefinitionListUpdated: boolean,
  isCreReqUpdated: boolean,
  selectedConnection: any,
  noVCOfSchemasList: any[]
}

export default class CreatePresentationProposal extends React.Component<ICreatePresentationProposalProps, ICreatePresentationProposalState> {
  constructor(props: ICreatePresentationProposalProps) {
    super(props);

    this.state = {
      searchText: "",
      sortValue: "",
      sortBy: "",
      filterText: myCredentials.stored,
      defaultSearchFields: '',
      itemsPerPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      CredentialSortBy: '',
      clearTypeAhead: false,
      isSelected: false,
      clickedConnection: [],
      isSelectedCredDef: false,
      isSelectedSchema: false,
      connectionFilterValue: 'all',
      policyOptions: [{
        policyName: '',
        requestedAttributes: {
          policyName: '',
          attributeNames: [],
          restrictions: [],
        },
        requestedPredicates: [],
        selectedCredential: [],
        isAllAttributesSelected: true,
      }],
      attributeArray: [],
      clearTypeAheadOrganization: false,
      selfAttestedAttributeKey: [],
      isDeleted: 'false',
      isValidProofRequest: true,
      isCredentialDefinitionListUpdated: false,
      isCreReqUpdated: false,
      selectedConnection: {},
      noVCOfSchemasList: []
    }

    this.sendProposal = this.sendProposal.bind(this);
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCredDefChange = this.handleCredDefChange.bind(this);
    this.handleCredDefInputChange = this.handleCredDefInputChange.bind(this);
    this.listView = this.listView.bind(this);
    this.allAttributesChange = this.allAttributesChange.bind(this);
    this.handlePredicateInputChange = this.handlePredicateInputChange.bind(this);
    this.handlePredicateValueChange = this.handlePredicateValueChange.bind(this);
    this.removePolicy = this.removePolicy.bind(this);
    this.createNewProposal = this.createNewProposal.bind(this);
    this.updateReProposal = this.updateReProposal.bind(this)
  }

  componentDidMount() {
    const { itemsPerPage, pageNo, searchText, sortValue, sortBy, filterText } = this.state;
    // Get All connection by orgId

    const { loggedInUser } = this.props.LoginReducer;
    this.props.connectionAction.getAllConnectedOrganizations(loggedInUser.organization.id, pageNo, itemsPerPage, searchText)

    // Get All credentials by orgId
    this.props.TaskActions.getAllCredDefForInstantTask();
    this.props.myCredentialsAction.getAllCredentialOfferList({ pageNo: 1, itemsPerPage: 0, searchText, sortValue, sortBy, filterText });
    this.props.TaskActions.getAllOrganization();

  }

  updateReProposal() {
    const historyState = history.location.state as any
    let selectedProofDetails: any;
    let selfAttestedAttributeKey: string[] = []
    let selectedConnection
    let rePolicyOptions: PolicyOptions[] = [{
      policyName: '',
      requestedAttributes: {
        policyName: '',
        attributeNames: [],
        restrictions: [],
      },
      requestedPredicates: [],
      selectedCredential: [],
      isAllAttributesSelected: true,
    }]
    const noVCOfSchemasList: any[] = []

    const { getAllCredDefForInstantTaskList } = this.props.TaskReducers
    const { credentialOfferReqData } = this.props.MyCredentialReducer

    // get the list of unique cred def for credential definition dropdown 
    const setCredentialDefinitions: any = this.createUniqueCredDefList(credentialOfferReqData, getAllCredDefForInstantTaskList);

    if (historyState && historyState.selectedProofDetails) {
      this.props.presentationProposalAction.createNewProposal();

      selectedConnection = historyState.connectionDetails
      selectedConnection.holderName = selectedConnection.theirLabel

      selectedProofDetails = historyState.selectedProofDetails;

      // console.log('selectedProofDetails', selectedProofDetails)

      if (Object.keys(selectedProofDetails).length > 0) {
        Object.keys(selectedProofDetails).map((type: any) => {
          // console.log('type', type)

          const referentKeys = selectedProofDetails[type]
          if (type !== 'self_attested_attributes' && type !== 'presentationExchangeId') {
            // console.log('referentKeys', referentKeys)

            if (Object.keys(referentKeys).length > 0) {
              referentKeys?.map((referentGroup: any, index: number) => {
                // console.log('referentGroup', referentGroup)

                Object.keys(referentGroup).map((key: any, idx: number) => {
                  // console.log('key', key)
                  const referent = referentGroup[key]
                  // console.log('referent', referent)
                  referent?.map((credential: any) => {
                    // console.log('credential===', credential)
                    if (credential.isSelected) {


                      if (!credential.cred_info.cred_def_id && credential.cred_info.schema_id) {
                        const credentialDefinitionIdRecords = setCredentialDefinitions.filter((credDefs: any) => credDefs.schema.schemaLedgerId === credential.cred_info.schema_id)
                        // console.log('if credentialDefinitionIdRecords', credentialDefinitionIdRecords)
                        if (credentialDefinitionIdRecords.length) {
                          credential.cred_info.cred_def_id = credentialDefinitionIdRecords[0].credentialDefinitionId
                        }
                        else {
                          noVCOfSchemasList.push({
                            credentialName: credential.cred_info.schema_id.split(':')[2],
                            index
                          })
                        }
                      }
                      else {
                        // console.log('else')

                        if (credential.cred_info.cred_def_id) {
                          const credentialDefinitionIdRecords = setCredentialDefinitions.filter((credDefs: any) => credDefs.credentialDefinitionId === credential.cred_info.cred_def_id)

                          if (!credentialDefinitionIdRecords.length) {
                            noVCOfSchemasList.push({
                              credentialName: credential.cred_info.cred_def_id.split(':')[4],
                              index
                            })
                          }
                        }
                      }

                      if (credential.cred_info.schema_id || credential.cred_info.cred_def_id) {

                        let tempRequestedAttributes: any = []
                        let tempRequestedPredicates: any = []

                        if (type === 'requested_attributes') {
                          tempRequestedAttributes.push({
                            policyName: credential.cred_info.cred_def_id,
                            attributeNames: Object.keys(credential.cred_info.attrs),
                            restrictions: [{
                              cred_def_id: credential.cred_info.cred_def_id,
                            }]

                          })
                        }
                        if (type === 'requested_predicates') {
                          // console.log('credential.credentialInfo', credential.credentialInfo)

                          Object.keys(credential.credentialInfo.predAttrs).map((predAttr: any) => {
                            // console.log('predAttr', predAttr)
                            const predicateString = predAttr
                            const predicateType = getOperatorFromProofPredicateKey(predicateString)

                            // console.log('predicateString', predicateString)
                            // console.log('predicateType', predicateType)

                            tempRequestedPredicates.push({
                              policyName: credential.cred_info.referent,
                              attributeName: (predicateString.split(predicateType)[0]).trim(),
                              predicateType,
                              restrictions: [{
                                cred_def_id: credential.cred_info.cred_def_id
                              }],
                              predicateValue: Number((predicateString.split(predicateType)[1]).trim())
                            })
                          })

                        }

                        rePolicyOptions[index] = {
                          policyName: credential.cred_info.cred_def_id,
                          requestedAttributes: tempRequestedAttributes,
                          requestedPredicates: tempRequestedPredicates,
                          isAllAttributesSelected: true,
                          selectedCredential: [],
                          selectedOrganization: ''
                        }
                      }
                    }
                  })
                })
              })
            }
          }
          else {
            if (type === 'self_attested_attributes') {
              referentKeys?.map((referentGroup: any, index: number) => {

                Object.keys(referentGroup).map((key: any, idx: number) => {
                  const referent = referentGroup[key]
                  // console.log('referent', referent)
                  referent?.map((credential: any) => {
                    if (credential.isSelected) {
                      Object.keys(credential.credentialInfo.attrs).map((attribute: any) => {
                        selfAttestedAttributeKey.push(attribute)
                      })
                    }
                  })
                })

              })

            }
          }
        })
      }

      // console.log('noVCOfSchemasList', noVCOfSchemasList)

      this.setState({
        policyOptions: rePolicyOptions,
        selfAttestedAttributeKey,
        selectedConnection,
        noVCOfSchemasList
      }, () => {
        // console.log('policyOptions', rePolicyOptions)
        Object.keys(selectedProofDetails).map((type: any) => {
          // historyState.selectedProofDetails.map((credential: any, index: any) => {
          if (type !== 'self_attested_attributes' && type !== 'presentationExchangeId') {
            const referentKeys = selectedProofDetails[type]
            // console.log('referentKeys', referentKeys)
            if (Object.keys(referentKeys).length > 0) {
              referentKeys?.map((referentGroup: any, index: number) => {
                // console.log('referentGroup', referentGroup)

                Object.keys(referentGroup).map((key: any, idx: number) => {
                  // console.log('key', key)
                  const referent = referentGroup[key]
                  // console.log('referent', referent)
                  referent?.map((credential: any) => {
                    // console.log('credential===', credential)
                    if (!credential.isSelected) {
                      return;
                    }

                    if (credential.cred_info.cred_def_id) {
                      // match the cred def from list
                      const selectedCredDef = setCredentialDefinitions.filter((x: any) => x.credentialDefinitionId === credential.cred_info.cred_def_id)
                      // console.log('selectedCredDef', selectedCredDef)
                      // send to handleCredDefChange
                      selectedCredDef.length && this.handleCredDefChange(index, selectedCredDef)

                    }
                  })
                })
              })
            }
          }
        })
      })


    }
  }

  componentDidUpdate(prevProps: Readonly<ICreatePresentationProposalProps>): void {
    if (prevProps !== this.props) {
      if (prevProps.TaskReducers.getAllCredDefForInstantTaskList !== this.props.TaskReducers.getAllCredDefForInstantTaskList
        || prevProps.MyCredentialReducer.credentialOfferReqData !== this.props.MyCredentialReducer.credentialOfferReqData) {

        let { isCredentialDefinitionListUpdated, isCreReqUpdated } = this.state
        if (prevProps.TaskReducers.getAllCredDefForInstantTaskList !== this.props.TaskReducers.getAllCredDefForInstantTaskList
          && this.props.TaskReducers.getAllCredDefForInstantTaskList.length) {
          isCredentialDefinitionListUpdated = true
        }
        if (prevProps.MyCredentialReducer.credentialOfferReqData !== this.props.MyCredentialReducer.credentialOfferReqData
          && this.props.MyCredentialReducer.credentialOfferReqData.length) {
          isCreReqUpdated = true
        }

        this.setState({
          isCredentialDefinitionListUpdated,
          isCreReqUpdated
        }, () => {
          if (isCredentialDefinitionListUpdated && isCreReqUpdated)
            this.updateReProposal()
        })
      }
    }


  }


  checkProofRequestData(policyOptions: any) {
    let allRequestedAttributes = policyOptions.map((a: any) => a.requestedAttributes.attributeNames);
    let allSelectedCredentials = policyOptions.map((a: any) =>
      a.selectedCredential?.[0].attributes ? a.selectedCredential?.[0].attributes : a.selectedCredential?.[0].schema.attributes
    );

    let imageCounter = 0;

    allSelectedCredentials.forEach((credential: any, idx: number) => {
      credential.forEach((attribute: any) => {
        const foundIndex = allRequestedAttributes[idx].findIndex((a: any) => a === JSON.parse(attribute).name)
        if (foundIndex !== -1) {
          if (JSON.parse(attribute).value === 'image') {
            imageCounter++
          }
        }
      })
    })

    return imageCounter
  }

  sendProposal(event: any) {
    const { clickedConnection, policyOptions, selfAttestedAttributeKey, selectedConnection } = this.state;
    const imageCounter = this.checkProofRequestData(policyOptions)

    if (imageCounter <= 2) {
      // remove the empty attribute and predicate object and create a combine requested attributes form each policy attributes
      const attributes: any = []
      const predicates: any = []
      policyOptions.map((policyValue: PolicyOptions) => {

        policyValue.requestedAttributes.attributeNames.map((attributeName: string) => {

          attributes.push({
            attributeName: attributeName,
            policyName: policyValue.requestedAttributes.policyName,
            restrictions: policyValue.requestedAttributes.restrictions
          })
        })
        policyValue.requestedPredicates.map((predicatesValue: any) => {
          if (predicatesValue.predicateType !== undefined && predicatesValue.predicateValue !== undefined)
            predicates.push(predicatesValue)
        })
      })
      if (selfAttestedAttributeKey.length > 0) {
        selfAttestedAttributeKey.map((attr: any) => {
          attributes.push({
            attributeName: attr,
            restrictions: []
          })
        })
      }

      // Remove the attribute from the requested attributes if it has an predicate attached
      attributes.forEach((attribute: any, attributeIndex: number) =>
        predicates.forEach((predicate: any) => {
          if (JSON.stringify(attribute.restrictions) === JSON.stringify(predicate.restrictions)) {

            if (attribute.attributeName === predicate.attributeName) {
              attributes.splice(attributeIndex, 1)
            }
          }
        })
      )

      const presentationProposalOptions = {
        connectionId: !_.isEmpty(clickedConnection) ? clickedConnection[0].connectionId : selectedConnection.connectionId,
        attributes,
        predicates,
      }

      // Decline the previous proof request before sending the new presentation
      const historyState = history.location.state as any
      if (historyState?.selectedProofDetails) {
        const { presentationExchangeId } = historyState.selectedProofDetails
        this.props.presentationNegotiateAction.sendProblemReportForPresentationExchange('Re-Proposal sent.', presentationExchangeId)
      }

      // console.log('----- presentationProposalOptions', presentationProposalOptions)

      this.props.presentationProposalAction.createPresentationProposal(presentationProposalOptions);

      this.setState({
        clickedConnection: [],
        policyOptions: [{
          policyName: '',
          requestedAttributes: {
            policyName: '',
            attributeNames: [],
            restrictions: [],
          },
          requestedPredicates: [],
          selectedCredential: [],
          isAllAttributesSelected: true,
        }],
        clearTypeAhead: true,
        selfAttestedAttributeKey: [],
        selectedConnection: {}
      }, () => {
        // Clear the history state if any
        const historyState = history.location.state as any
        if (historyState?.selectedProofDetails) {
          let state = { ...historyState };
          delete state.selectedProofDetails;
          delete state.connectionDetails;
          history.replace({ ...history.location, state });
        }
      })

    }
    else {
      this.setState({ isValidProofRequest: false })
    }
  }

  handleConnectionChange(selectedOptions: any) {
    this.setState({
      isSelected: true,
      clickedConnection: selectedOptions,
      clearTypeAhead: false,
    })
  }

  handleInputChange() {
    this.setState({
      clickedConnection: [...this.state.clickedConnection],
    })
  }

  /**
   * Method used to handle selected credential details
   * @param selectedCredDefOptions 
   */
  handleCredDefChange(index: any, selectedCredDef: any) {
    let selectedData = selectedCredDef[0];

    this.props.TaskActions.clearSelectedIssuerOrganizationName()
    const updatePolicyOptions = this.state.policyOptions

    if (selectedData) {
      this.props.TaskActions.getSelectedCredDefDetails(selectedData.credentialDefinitionId)

      const namesArray: string[] = []
      selectedData?.schema?.attributes.map((value: string) => {
        namesArray.push(JSON.parse(value).name)
      })

      updatePolicyOptions[index].selectedCredential?.push(selectedData)
      updatePolicyOptions[index].requestedAttributes = {
        policyName: selectedData?.schema?.schemaLedgerId,
        attributeNames: namesArray,
        restrictions: [{
          cred_def_id: selectedData?.credentialDefinitionId,
        }]
      }
    }
    else {
      updatePolicyOptions[index].selectedCredential = []
      updatePolicyOptions[index].requestedAttributes = {
        policyName: '',
        attributeNames: [],
        restrictions: []
      }
    }

    this.setState({
      isSelectedCredDef: true,
      policyOptions: updatePolicyOptions
    })
  }

  /**
   * Method used to handle selected credential details
   */
  handleCredDefInputChange() {
    this.props.TaskActions.clearSelectedIssuerOrganizationName()
    this.setState({
      isSelectedCredDef: false
    })
  }

  allAttributesChange(policyIndex: number, event: React.ChangeEvent<HTMLInputElement> | any) {
    const updatePolicyOptions = this.state.policyOptions

    const selectedCredentialsAttributes = updatePolicyOptions[policyIndex].selectedCredential?.[0].schema.attributes

    if (!updatePolicyOptions[policyIndex].isAllAttributesSelected) {

      selectedCredentialsAttributes.map((attributeData: string) => {
        const attributeName = JSON.parse(attributeData).name

        if (!updatePolicyOptions[policyIndex].requestedAttributes.attributeNames.includes(attributeName)) {
          updatePolicyOptions[policyIndex].requestedAttributes.attributeNames.push(attributeName)
        }
      })
      updatePolicyOptions[policyIndex].isAllAttributesSelected = true
    }
    else {
      updatePolicyOptions[policyIndex].requestedAttributes.attributeNames = []
      updatePolicyOptions[policyIndex].isAllAttributesSelected = false
    }


    this.setState({
      policyOptions: updatePolicyOptions
    })
  }

  handlePredicateInputChange(policyIndex: number, event: React.ChangeEvent<HTMLInputElement> | any) {
    const { value, name } = event.target;
    let updatePolicyOptions = this.state.policyOptions;

    const attributeName = name

    const credentialId = updatePolicyOptions[policyIndex].selectedCredential?.[0].credentialDefinitionId

    const predicateValues = Object.values(PredicateType)
    const predicateIndexFound = updatePolicyOptions[policyIndex].requestedPredicates.findIndex(x => x.attributeName === attributeName)

    if (!predicateValues.includes(value)) {
      updatePolicyOptions[policyIndex].requestedPredicates.splice(predicateIndexFound, 1)
    }
    else {
      if (predicateIndexFound > -1) {
        updatePolicyOptions[policyIndex].requestedPredicates[predicateIndexFound].predicateType = value
        if (!(updatePolicyOptions[policyIndex].requestedPredicates[predicateIndexFound]).hasOwnProperty('restrictions')) {
          updatePolicyOptions[policyIndex].requestedPredicates[predicateIndexFound].restrictions = [{
            cred_def_id: credentialId
          }]
        }
      }
      else
        updatePolicyOptions[policyIndex].requestedPredicates.push({
          policyName: credentialId,
          attributeName: attributeName,
          predicateType: value,
          restrictions: [{
            cred_def_id: credentialId
          }],
          predicateValue: undefined
        })
    }

    this.setState({
      policyOptions: updatePolicyOptions
    });
  }

  handlePredicateValueChange(policyIndex: number, event: any) {
    const { value, name } = event.target;
    let updatePolicyOptions = this.state.policyOptions;

    const attributeName = name

    const credentialId = updatePolicyOptions[policyIndex].selectedCredential?.[0].credentialDefinitionId
    const predicateIndex: any = updatePolicyOptions[policyIndex].requestedPredicates.findIndex(item => item.attributeName === attributeName)

    if (predicateIndex > -1) {
      updatePolicyOptions[policyIndex].requestedPredicates[predicateIndex].predicateValue = Number(value)

      if (!(updatePolicyOptions[policyIndex].requestedPredicates[predicateIndex]).hasOwnProperty('restrictions')) {
        updatePolicyOptions[policyIndex].requestedPredicates[predicateIndex].restrictions = [{
          cred_def_id: credentialId
        }]
      }
    }
    else {
      updatePolicyOptions[policyIndex].requestedPredicates.push({
        policyName: credentialId,
        attributeName: attributeName,
        predicateType: undefined,
        restrictions: [{
          cred_def_id: credentialId
        }],
        predicateValue: Number(value)
      })
    }

    this.setState({
      policyOptions: updatePolicyOptions
    });
  }

  attributeSelectionChange(policyIndex: number, event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    const updatePolicyOptions = this.state.policyOptions

    const attributeArray = updatePolicyOptions[policyIndex].requestedAttributes.attributeNames
    const predicateArray = updatePolicyOptions[policyIndex].requestedPredicates
    const updateAttributeArray: Array<any> = attributeArray
    const updatePredicateArray: Array<any> = predicateArray

    if (!attributeArray.includes(value)) {
      updateAttributeArray.push(value);
      updatePredicateArray.push({
        attributeName: value,
        policyName: updatePolicyOptions[policyIndex].policyName,
        predicateType: undefined,
        predicateValue: undefined,
      })
    }
    else {
      const attributeIndex: any = attributeArray.indexOf(value)
      const predicateIndex: any = predicateArray.findIndex(item => item.attributeName === value)
      updateAttributeArray.splice(attributeIndex, 1)
      updatePredicateArray.splice(predicateIndex, 1)
    }

    const attributes = updatePolicyOptions[policyIndex].selectedCredential?.[0].hasOwnProperty('schema') ?
      updatePolicyOptions[policyIndex].selectedCredential?.[0].schema.attributes :
      updatePolicyOptions[policyIndex].selectedCredential?.[0].attributes

    // update the selected Attribute & predicate array
    updatePolicyOptions[policyIndex].requestedAttributes.attributeNames = updateAttributeArray
    updatePolicyOptions[policyIndex].requestedPredicates = updatePredicateArray

    //update the all attribute selection flag 
    updatePolicyOptions[policyIndex].isAllAttributesSelected = attributeArray.length === attributes.length
    this.setState({
      attributeArray: updateAttributeArray,
      policyOptions: updatePolicyOptions
    });
  }

  addPolicy(event: React.MouseEvent<HTMLButtonElement>) {
    const updatePolicyOptions = this.state.policyOptions
    updatePolicyOptions.push({
      policyName: '',
      requestedAttributes: {
        policyName: '',
        attributeNames: [],
        restrictions: [],
      },
      requestedPredicates: [],
      selectedCredential: [],
      isAllAttributesSelected: true,
    })

    this.setState({
      policyOptions: updatePolicyOptions
    })
    // this.isBtnDisable();
    event.preventDefault();
  }

  removePolicy(event: any, index: number) {
    event.preventDefault();

    const previousPolicyOptions = [...this.state.policyOptions]
    previousPolicyOptions.splice(index, 1);

    this.setState({
      policyOptions: previousPolicyOptions
    })
  }

  removeSelfAttestedAttribute(event: React.MouseEvent<HTMLButtonElement>, i: number) {
    event.preventDefault();

    let keys = [...this.state.selfAttestedAttributeKey];
    keys.splice(i, 1);

    return this.setState({ selfAttestedAttributeKey: keys })

  }


  onChangeSelfAttributeKey(event: React.ChangeEvent<HTMLInputElement> | any, index: any) {
    event.preventDefault();
    let updateField = this.state.selfAttestedAttributeKey;
    updateField[event.target.name] = (event.target.value);

    this.setState({
      selfAttestedAttributeKey: updateField,
    })
  }

  createSelfAttestedAttribute() {
    const { selfAttestedAttributeKey } = this.state;

    return selfAttestedAttributeKey.map((field: any, index: any) => {
      return index === 0 ?
        <div key={index}>
          <div className="row">
            <label className="col-sm-1 col-form-label nb-label pr-0"> {index + 1}. <span className='text-danger'>*</span></label>
            <div className="col-sm-6">

              <input type="text" className="form-control"
                name={index} id="attributeKey"
                value={field}
                onChange={(e) => this.onChangeSelfAttributeKey(e, index)}
              />

            </div>
            <div className="col-sm-2">
              <button className="btn float-left"
                onClick={(e) => this.removeSelfAttestedAttribute(e, index)}
              ><i className="fas fa-minus-circle" />
              </button>
            </div>
          </div>
        </div>
        :
        // -------------------------------
        <div key={index}>
          <div className="row">
            <label className="col-sm-1 col-form-label nb-label pr-0"> {index + 1}. <span className='text-danger'>*</span></label>
            <div className="col-sm-6">

              <input type="text" className="form-control"
                name={index} id="attributeKey"
                value={field}
                onChange={(e) => this.onChangeSelfAttributeKey(e, index)}
              />

            </div>
            <div className="col-sm-2">
              <button className="btn float-left"
                onClick={(e) => this.removeSelfAttestedAttribute(e, index)}
              ><i className="fas fa-minus-circle" />
              </button>
            </div>
          </div>
        </div>
    })
  }

  addSelfAttestedAttribute(event: any) {
    let updateValue: any = this.state.selfAttestedAttributeKey;
    updateValue.push('');
    this.setState({
      selfAttestedAttributeKey: updateValue,
    })
    event.preventDefault();
  }


  createUniqueCredDefList(credentialOfferReqData: any, getAllCredDefForInstantTaskList: any) {
    const uniqueCredential = new Set();
    const setCredentialDefinitions: any = [];

    credentialOfferReqData.map((data: any) => {
      if (!uniqueCredential.has(data.credentialDefinitionId)) {
        uniqueCredential.add(data.credentialDefinitionId);

        const index = getAllCredDefForInstantTaskList.indexOf(getAllCredDefForInstantTaskList.find((x: any) =>
          x.credentialDefinitionId === data.credentialDefinitionId));

        if (index > -1)
          setCredentialDefinitions.push(getAllCredDefForInstantTaskList[index]);
      }
    });
    return setCredentialDefinitions;
  }



  createPolicyDetails() {
    const { policyOptions, clearTypeAhead, noVCOfSchemasList } = this.state
    const { getAllCredDefForInstantTaskList } = this.props.TaskReducers
    const { credentialOfferReqData } = this.props.MyCredentialReducer
    const predicates = ['<=', '<', '>', '>=']

    const setCredentialDefinitions: any = this.createUniqueCredDefList(credentialOfferReqData, getAllCredDefForInstantTaskList);

    return policyOptions.map((policyValue: PolicyOptions, policyIndex: number) => {

      let attributes: any
      if (policyValue.selectedCredential?.[0]?.hasOwnProperty('schema')) {
        attributes = policyValue.selectedCredential?.[0].schema.attributes
      }
      else {
        attributes = policyValue.selectedCredential?.[0]?.attributes
      }

      const historyState = history.location.state as any

      return <div key={policyIndex}>

        <div className='row'>
          <div className='col-sm-12'>
            <div className='form-group'>
              <div className="card">

                <div className="card-body" >
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className="form-group">
                        {
                          ((historyState && historyState.selectedProofDetails) && noVCOfSchemasList[policyIndex] && !policyValue.selectedCredential?.length)
                          && <small className='text-danger'>
                            {t("screens:newProposal.credentialNotFound")}{` "${noVCOfSchemasList[policyIndex]?.credentialName}"`}
                          </small>
                        }
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="nb-label">{t("screens:newProposal.credentialDefinition")}</label>
                        <TypeaheadComponent
                          idx={`${policyIndex}`}
                          onChange={this.handleCredDefChange.bind(this, policyIndex)}
                          data={setCredentialDefinitions}
                          onInputChange={this.handleCredDefInputChange}
                          clearTypeahead={clearTypeAhead}
                          placeholder={t("screens:newProposal.selectCredDef")}
                          selectedValue={policyValue.selectedCredential}
                          disabled={false} />
                      </div>

                    </div>

                    <div className="col-sm-6">
                      <button className="btn float-right"
                        onClick={(e) => this.removePolicy(e, policyIndex)}
                      ><i className="fas fa-minus-circle" />
                      </button>

                    </div>
                  </div>

                  {!_.isEmpty(attributes) && (policyValue.requestedAttributes) ?
                    <div className='row'>
                      <div className="col-sm-12">
                        <div className="table-responsive">
                          <table className="table border">
                            <thead>
                              <tr>
                                <th>
                                  <label className="align-self-center" htmlFor=''>
                                    <input type="checkbox" id='allAttributes' key='allAttributes'
                                      name={`allAttributes-${policyIndex}`}
                                      checked={policyValue.isAllAttributesSelected}
                                      onChange={(e) => this.allAttributesChange(policyIndex, e)}
                                    /></label>
                                </th>
                                <th>{t("screens:newProposal.names")}</th>
                                <th>{t("screens:newProposal.operator")}</th>
                                <th>{t("screens:newProposal.value")}</th>
                              </tr>
                            </thead>

                            {
                              !_.isEmpty(attributes) && attributes.map((attribute: any, index: any) => {
                                const isAttributeSelected = (policyValue.requestedAttributes.attributeNames).filter(f => f === (JSON.parse(attribute).name)).length > 0
                                const matchingPredicate = policyValue.requestedPredicates.find((x: any) => x.attributeName === (JSON.parse(attribute).name))
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>
                                        <label className="align-self-center" htmlFor={JSON.parse(attribute).name}>
                                          <input type="checkbox" id={JSON.parse(attribute).name}
                                            name={`attributesCheck-${policyIndex}-${index}`}
                                            value={JSON.parse(attribute).name}
                                            onChange={(e) => this.attributeSelectionChange(policyIndex, e)}
                                            checked={isAttributeSelected}
                                          /></label>
                                      </td>
                                      <td><p className={!isAttributeSelected ? 'text-muted' : ''}>{JSON.parse(attribute).name}</p></td>

                                      <td>
                                        <select id="predicates"
                                          className='form-control'
                                          name={`${JSON.parse(attribute).name}`}
                                          onChange={(e) => this.handlePredicateInputChange(policyIndex, e)} disabled={!isAttributeSelected}
                                        >
                                          <option value=""></option>
                                          {
                                            predicates.map((value: string, preIdx: number) => {
                                              return <option
                                                key={preIdx}
                                                value={value}
                                                selected={matchingPredicate && matchingPredicate.predicateType === value}
                                              >
                                                {value}
                                              </option>
                                            })
                                          }
                                        </select>
                                      </td>
                                      <td>
                                        <input type="text" className="form-control"
                                          name={`${JSON.parse(attribute).name}`}
                                          autoComplete="off"
                                          value={matchingPredicate && matchingPredicate.predicateValue}
                                          onChange={(e) => this.handlePredicateValueChange(policyIndex, e)}
                                          disabled={!isAttributeSelected}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                )

                              })
                            }
                          </table>
                        </div>
                      </div>
                    </div> :
                    <div></div>
                  }

                </div>

              </div>
            </div>
          </div>
        </div>
      </div >

    })
  }

  listView(event: any) {
    this.setState({
      defaultSearchFields: '',
      itemsPerPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      CredentialSortBy: '',
      clearTypeAhead: false,
      isSelected: false,
      clickedConnection: [],
      isSelectedCredDef: false,
      isSelectedSchema: false,
      clearTypeAheadOrganization: false,
    })
    this.props.TaskActions.clearForm()
  }

  createNewProposal() {
    this.setState({
      policyOptions: [{
        policyName: '',
        requestedAttributes: {
          policyName: '',
          attributeNames: [],
          restrictions: [],
        },
        requestedPredicates: [],
        selectedCredential: [],
        isAllAttributesSelected: true,
      }]
    })
    this.props.presentationProposalAction.createNewProposal();
  }

  showUpdteAttribute() {
    console.log("**** showUpdteAttribute")
  }

  public render() {
    const { clearTypeAhead: clearTypeahead, clickedConnection, selfAttestedAttributeKey, policyOptions, isValidProofRequest, selectedConnection } = this.state;
    const { connectedOrgs } = this.props.ConnectionReducer;
    const { createNewProposal } = this.props.PresentationProposalReducer

    return (
      <>

        <div className='row'>
          <div className="card-body p-5">
            {!createNewProposal ?
              <>
                {/* SELECT CONNECTION */}
                <div className="row">
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="nb-label">{t("screens:newProposal.connectionList")}</label>
                              {
                                Object.keys(selectedConnection).length
                                  ? <React.Fragment>
                                    <input type='text'
                                      name='re-propose-connection'
                                      className='form-control'
                                      disabled={true}
                                      value={`${selectedConnection.holderName} (${selectedConnection.theirDid})`}
                                    />
                                  </React.Fragment>
                                  : <TypeaheadComponent
                                    data={connectedOrgs}
                                    onChange={this.handleConnectionChange}
                                    onInputChange={this.handleInputChange}
                                    clearTypeahead={clearTypeahead}
                                    placeholder={t("screens:newProposal.selectConnectionList")}
                                    selectedValue={clickedConnection}
                                    disabled={false} />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* SELECT CREDENTIAL AND PREDICATE */}
                <div className='row'>
                  <div className='col-sm-12'>
                    {
                      this.createPolicyDetails()
                    }
                    <div className="col-sm-12">
                      <div className="form-group">
                        <button className="btn btn-block btn-nb-outline-primary rounded-pill"
                          onClick={(e) => this.addPolicy(e)}
                        >{t("screens:newProposal.addCredential")} </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* SELF-ATTESTED ATTRIBUTES */}
                {selfAttestedAttributeKey.length > 0 ?
                  <li className="remove-underline">
                    <div className="row ml-3">
                      <div className="col-sm-12">
                        <label className="nb-label">{t("screens:newProposal.addAttribute")}:</label>
                        <ul>
                          {this.createSelfAttestedAttribute()}
                        </ul>
                      </div>
                    </div>
                  </li>
                  : ''
                }

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group mt-5">
                      <button className="btn btn-block btn-nb-outline-primary rounded-pill"
                        onClick={(e) => this.addSelfAttestedAttribute(e)}
                      >{t("screens:newProposal.addSelfAttribute")}</button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <div className='text-danger mb-2'>{!isValidProofRequest ? 'Please select only 2 image attributes for a Proof Request.' : ''}</div>
                    <button type="button" className="btn btn-block btn-nb-outline-primary rounded-pill"
                      onClick={(e) => this.sendProposal(e)}
                      disabled={
                        (_.isEmpty(clickedConnection) && !selectedConnection.holderName)
                        || _.isEmpty(policyOptions[0]?.selectedCredential)
                        || selfAttestedAttributeKey.findIndex((x: any) => x === '' || !x) !== -1
                        || (_.isEmpty(policyOptions)
                          || Object.keys(policyOptions[0].requestedAttributes).length === 0
                          || policyOptions[0].requestedAttributes.attributeNames.length === 0
                        )
                      }
                    >{t("screens:newProposal.send")}</button>
                  </div>
                </div>
              </> :
              // BANNER SCREEN
              <div className="col-sm-12">
                <div className="text-center">
                  <img alt="Add success" src={images.illustrationAddSuccess} className="mb-5" />
                </div>
                <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                  onClick={this.createNewProposal}>
                  {t("screens:newProposal.createNewProposal")}
                </button>
              </div>

            }

          </div>
        </div>

      </>
    );
  }
}

