import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    value: string;
    isHidden: boolean;
    index: number;
    onLinkClick: (index: number) => void;
    mimeType?: string;
}

const MaskedData: React.FC<Props> = ({
    value,
    isHidden,
    index,
    onLinkClick,
    mimeType,
}) => {
    const { t } = useTranslation();
    // console.log('value', value)
    return (
        <>
            <>
                {!value?.includes('data:image/') ? (
                    // <label>
                    <>
                        {isHidden ? '-' : value}
                    </>
                    // </label>
                ) : null}
                {value?.includes('data:image/') || mimeType === 'image/jpeg' ? (
                    !isHidden ? <img src={value} alt='' className="preview" height="50"></img>

                        : (
                            <>
                                {value}
                            </>
                        )
                ) : null}
            </>
            {/* <button
                onClick={() => onLinkClick(index)}
                className='text-info'
            >
                {!isHidden ? t('actions.hide') : t('actions.show')}
            </button> */}

        </>
    );
};

export default MaskedData;
