import React from "react";
import { ListScreenOptions } from "src/types/common.interface";
import { PresentProofState } from "src/View/commonConst";
import TableComponents from "../../../Common Components/Components/TableComponent";

/* Interface for Props variables*/
interface IPresentationDeclinedListProps {
    LoginReducer: any,
    PresentationNegotiateReducer: any,
    presentationNegotiateAction: any,
    schemaAction: any
}
/* Interface for local states variables*/
interface IPresentationDeclinedListState {
    listScreenOptions: ListScreenOptions,
    selectedPresentationRecord: any
}

export class PresentationDeclinedList extends React.Component<IPresentationDeclinedListProps, IPresentationDeclinedListState> {
    constructor(props: IPresentationDeclinedListProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            listScreenOptions: {
                itemsPerPage: 10,
                pageNo: 1,
                filter: PresentProofState.STATE_ABANDONED
            },
            selectedPresentationRecord: {}
        }

        this.onChangePage = this.onChangePage.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.sorting = this.sorting.bind(this);

    }

    /* Default lifecycle method to call get all credential list */
    componentDidMount() {
        const { listScreenOptions } = this.state;
        const { presentationList } = this.props.PresentationNegotiateReducer;
        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)

        if (presentationList.length > 0) {
            this.props.presentationNegotiateAction.showSelectedCredentialDetails(presentationList[0])
        }

    }

    onChangePage(page: any) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page

        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
    }

    showDetails(item: any) {
        this.props.presentationNegotiateAction.showSelectedCredentialDetails(item)
        
        this.setState({
            selectedPresentationRecord: item,
        })
    }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { listScreenOptions } = this.state;

        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions });
            this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
        }
    }

    sorting = (itemSortBy: string) => {
        const { listScreenOptions } = this.state;

        listScreenOptions.sort = (listScreenOptions.sort === 'DESC') ? 'ASC' : 'DESC'
        listScreenOptions.sortBy = itemSortBy
        this.setState({ listScreenOptions })
        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
    }

    refresh = () => {
        const listScreenOptions = {
            itemsPerPage: 10,
            pageNo: 1,
            filter: PresentProofState.STATE_ABANDONED
        }

        this.setState({ listScreenOptions })
        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
        const { presentationList } = this.props.PresentationNegotiateReducer;
        if (presentationList.length > 0) {
            this.props.presentationNegotiateAction.showSelectedCredentialDetails(presentationList[0])
        }
    }

    public render() {
        const { presentationList, tHead, totalPresentations, selectedPresentationRecord } = this.props.PresentationNegotiateReducer;

        return (
            <div>
                <TableComponents
                    onChangePage={this.onChangePage}
                    showElementDetails={this.showDetails}
                    tHead={tHead}
                    tableContent={presentationList}
                    totalRecords={totalPresentations}
                    selectedRecord={selectedPresentationRecord}
                    sorting={this.sorting}
                    searching={this.onSearch}
                    refresh={this.refresh}
                />
            </div>
        );
    }
}
