import * as React from 'react';

export default class PageLoader extends React.Component {

  public render() {
    return (
      <div className="page-loader">
        <span className="loader">
          <i className="text-dark fas fa-3x fa-circle-notch fa-spin"></i>
        </span>
      </div>
    );
  }
}
