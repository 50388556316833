import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { credentialAction } from '../actions/credentialAction';
import { schemaAction } from '../../Schema/actions/schemaAction';
import { CreateSchemaCredentialDef } from '../components/CreateSchemaCredentialDef';
import { CredentialDetail } from '../components/CredentialDetail';
import CredentialList from '../components/CredentialList';
import { t } from 'i18next';

export interface ICredentialProps {
  credentialAction: any,
  schemaAction: any,
  CredentialReducer: any,
  SchemaReducer: any,
}
export interface ICredentialState {
  defaultSearchFields: any,
  supportRevocation:any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  CredentialSortBy: string,
}

class CredentialSchemaDefComponent extends React.Component<ICredentialProps, ICredentialState> {
  constructor(props: ICredentialProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      supportRevocation:'',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
    }
  }

  componentDidMount() {
    const { defaultSearchFields, supportRevocation, item_perPage, pageNo, sortingOrder, CredentialSortBy } = this.state;

    this.props.credentialAction.getAllCredentialByOrgId(defaultSearchFields, supportRevocation, sortingOrder, CredentialSortBy, item_perPage, pageNo);
    
    this.props.schemaAction.getSchemaList();
    this.props.schemaAction.getAllExternalOrganizationList();

  }

  public render() {
    const {
      modalShow,
    } = this.props.CredentialReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100 animated fadeIn">
          <div className="col-md-12 col-lg-5 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <div className="card-title m-0">
                  <h1 className="nb-title">{t("screens:createNewCredential.existingCredentials")}</h1>
                </div>
              </div>
              <div className="card-body">
                <CredentialList {...this.props} />
              </div>
            </div>
          </div>
          {modalShow === true ?
            <div className="col-md-12 col-lg-7 mb-30">
              <CredentialDetail {...this.props} />
            </div>
            :
            <div className="col-md-12 col-lg-7 mb-30">
              <CreateSchemaCredentialDef {...this.props} />
            </div>
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const CredentialReducer = state.CredentialReducer;
  const LoaderReducer = state.LoaderReducer
  const SchemaReducer = state.SchemaReducer;
  return {
    CredentialReducer,
    LoaderReducer,
    SchemaReducer,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    credentialAction: bindActionCreators(credentialAction, dispatch),
    schemaAction: bindActionCreators(schemaAction, dispatch),
  }
}

const connectedCredentialComponent = connect(mapStateToProps, mapDispatchToProps)(CredentialSchemaDefComponent);
export { connectedCredentialComponent as CredentialSchemaDefComponent };
