import * as React from 'react';
import { connect } from 'react-redux';
import './AddMultiuse.css'
import { history } from '../../../index'
import _ from 'lodash';

// Utilities
import { t } from 'i18next';
import QRMultiuse from './QRMultiuse';

/* Interface for Props variables*/
export interface IAddMultiuseProps {
    MultiuseReducer: any,
    multiuseAction: any,
    type: string,
}

/* Interface for local states variables*/
export interface IAddMultiuseState {
    formData: any,
    errors: any,
    usedBy: string,
    invitationSendSuccess: boolean
}

export default class AddMultiuse extends React.Component<IAddMultiuseProps, IAddMultiuseState> {
    constructor(props: IAddMultiuseProps) {
        super(props);

        /* Initialization of state variables*/
        this.state = {
            formData: {},
            errors: {},
            usedBy: 'EMPLOYEE',
            invitationSendSuccess: false
        }
        /* Binding the method */
        this.handleChange = this.handleChange.bind(this);
        this.confirmMultiuseInvitation = this.confirmMultiuseInvitation.bind(this);
        this.usedByChange = this.usedByChange.bind(this);
    }

    /**
    * Method to use get event from text field and set the values in state variable
    * @param event Parameter to get event from text field. 
    */
    handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
        let formData = this.state.formData;
        formData[event.target.name] = event.target.value;

        this.setState({
            formData: formData,
            invitationSendSuccess: false
        });
        this.validateForm()
    }

    createMultiuse(formData: any) {
        this.props.multiuseAction.createMultiuse("MULTIUSE", formData);
        this.inviteNewMultiuse();
    }

    /**
     * Method for send nultiuse connection invitation
     */

    confirmMultiuseInvitation() {
        const { formData } = this.state;
        formData['usedBy'] = this.state.usedBy

        this.setState({
            formData: formData,
            usedBy: this.state.usedBy,
            invitationSendSuccess: false
        });
        this.createMultiuse(formData)
    }

    /**
    * Method to validate the form field.
    * @returns It returns the form validation result
    */
    validateForm() {
        let formData: any = this.state.formData;
        let errors: any = {};
        let formIsValid: any = true;

        if (!formData["invitationName"]) {
            formIsValid = false;
            errors["invitationName"] = `${t("screens:invitationMultiuse.messageErrorName")}`;
        }
        if (!formData["invitationDescription"]) {
            formData["invitationDescription"] = '';
        }
        this.setState({
            errors: errors,
            formData: formData,
            usedBy: this.state.usedBy,
            invitationSendSuccess: false
        });
        return formIsValid;
    }

    inviteNewMultiuse() {
        this.setState({
            formData: {},
            errors: {},
            usedBy: 'EMPLOYEE',
            invitationSendSuccess: true,
        });
    }

    handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
        event.preventDefault();

        this.setState({
            formData: {},
            errors: {},
            usedBy: 'EMPLOYEE',
            invitationSendSuccess: false
        })
        history.push("/create-connection")
    }

    usedByChange(event: React.ChangeEvent<HTMLInputElement> | any) {
        this.setState({
            usedBy: event.currentTarget.value
        });
    }

    public render() {
        const { usedBy, formData, errors, invitationSendSuccess } = this.state;
        const { qrData, showErrorMessage } = this.props.MultiuseReducer;
        return (
            <div className="card nb-card h-100">
                <div className="card-header bg-white border-bottom-0">
                    <h1 className="nb-title">{t("screens:invitationMultiuse.newMultiuse")}</h1>
                </div>
                <div className="card-body">
                    {!invitationSendSuccess ?
                        <>
                            <div className="mb-5">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="nb-label">{t("screens:invitationMultiuse.invitationName")}</label>
                                        <input type="text" className="form-control" name="invitationName" value={formData.name}
                                            onChange={(e) => this.handleChange(e)} />
                                        {
                                            errors.name ?
                                                <div className="text-danger">
                                                    <small><i className="fas fa-info-circle mr-1"></i>{errors.name}</small>
                                                </div> :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="nb-label">{t("screens:invitationMultiuse.invitationDescription")}<span className="font-italic">{t("screens:invitationMultiuse.invitationOptional")}</span></label>
                                        <textarea className="form-control overflow-eclips m-auto" rows={5} name="invitationDescription" value={formData.description}
                                            onChange={(e) => this.handleChange(e)} />

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="nb-label">{t("screens:invitationMultiuse.usedBy")}</label>
                                        <div className="card">
                                            <div className="card-body" >
                                                <div className="custom-control custom-radio">
                                                    <input className="custom-control-input"
                                                        type="radio"
                                                        id="usedByEmployee"
                                                        name="usedBy"
                                                        value="EMPLOYEE"
                                                        onChange={this.usedByChange}
                                                        checked={usedBy === "EMPLOYEE"}
                                                    />
                                                    <label className="custom-control-label" htmlFor="usedByEmployee">
                                                        {t("screens:invitationMultiuse.usedByEmployee")}
                                                    </label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input className="custom-control-input"
                                                        type="radio"
                                                        id="usedByClient"
                                                        name="usedBy"
                                                        value="CLIENT"
                                                        onChange={this.usedByChange}
                                                        checked={usedBy === "CLIENT"}
                                                    />
                                                    <label className="custom-control-label" htmlFor="usedByClient">
                                                        {t("screens:invitationMultiuse.usedByClient")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ml-2 mr-2">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                                                    onClick={(e) => this.handleCancel(e)}>
                                                    {t("screens:invitationMultiuse.cancel")}
                                                </button>
                                            </div>
                                            <div className="col-sm-6">
                                                <button className="btn btn-block rounded-pill btn-nb-outline-primary"
                                                    disabled={showErrorMessage || !_.isEmpty(errors) || !formData.invitationName ? true : false}
                                                    onClick={this.confirmMultiuseInvitation}>
                                                    {t("screens:invitationMultiuse.confirm")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="col-sm-12">
                            <QRMultiuse qrData={qrData} MultiuseReducer={this.props.MultiuseReducer} multiuseAction={this.props.multiuseAction} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {}
}

function mapDispatchToProps(dispatch: any) {
    return {}
}

const connectedAddMultiuse = connect(mapStateToProps, mapDispatchToProps)(AddMultiuse);
export { connectedAddMultiuse as AddMultiuse };