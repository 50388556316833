export enum commonConst {
    PAGE_RECORD = 10
}

export enum acceptCredentialConstant {
    MY_CREDENTIALS = "My Credentials",
    CREDENTIAL_DETAILS = "Credential Details",
    ACCEPT_CREDENTIAL_SUCCESS_MESSAGE = "Credential accepted successfully...",
    CREDENTIAL_NAME = "Credential name",
    CONNECTION_NAME = "Given name",
    CREDENTIAL_VALUE = "Credential values",
    ACCEPT_BUTTON = "ACCEPT",
    DECLINE_BUTTON = "DECLINE",
    RE_PROPOSE_BUTTON = "RE-PROPOSE",
}

export enum organizationToOrganizationConnection {
    ORGANIZATION_CONNECTION_LIST = "Organizations",
    SEND_CONNECTION_INVITATION = "Connect With Organization",
    CONNECTION_DETAILS = "Connection Details",
    EMAIL_ADDRESS = "Email Address",
    CONNECTION_NAME = "Given Name",
    INVITATION_URL = "Invitation URL",
    SEND_INVITATION_BUTTON = "SEND INVITATION",
    INVITE_NEW_ORGANIZATION = "INVITE NEW ORGANIZATION",
    CONNECT_BUTTON = "CONNECT",
    CATEGORY = "Category",
    ACCEPT_CONNECTION_INVITATION_SUCCESS_MESSAGE = "Connection Accepted ...",
    ACCEPT_CONNECTION_INVITATION = "Accept Connection Invitation",
    SEND_ACCEPT_BUTTON = "CONNECT",
    CONNECTION_URL = "Connection URL",
    ACCEPT_NEW_ORGANIZATION = "ACCEPT NEW CONNECTION INVITATION",
}

export enum addNewHolder {
    HEADER_TEXT = "Add a New",
    HOLDER_NAME = "Given Name",
    HOLDER_EMAIL = "Email Address",
    ERROR_MESSAGE = "Already Exist",
    SEND_LABEL_TEXT = "SENT",
    SEND_BUTTON_TEXT = "SEND INVITATION",
    INVITE_NEW_BUTTON_TEXT = "INVITE NEW"
}

export enum addNewAdmin {
    HEADER_TEXT = "Add a New",
    ADMIN_NAME = "Given Name",
    ADMIN_EMAIL = "Email Address",
    ERROR_MESSAGE = "Already Exist",
    SEND_LABEL_TEXT = "SENT",
    SEND_BUTTON_TEXT = "SEND INVITATION",
    INVITE_NEW_BUTTON_TEXT = "INVITE NEW"
}

export enum showQRCodeConstant {
    showQRCodePageHeader = "You're invited to connect",
    connectionMessage = "Scan the QR code with your NB wallet to connect with"
}

export enum presentProofRequest {
    PRESENT_PROOF_REQUEST_LIST = "Present Proof Request List",
    PROOF_DETAILS = "Request Details",
    PRESENT_PROOF_DETAILS_HEADER = "Proof Request",
    CONNECTION_NAME = "Given Name",
    CREDENTIAL_TYPE = "Credential Type",
    CREDENTIAL_NAME = "Credential Name",
    ATTRIBUTES = "Attributes",
    PRESENT_PROOF_AVAILABLE_CREDENTIAL = "Available Credentials",
    GENERATED_PRESENT_PROOF = "Generated Present Proof",
    ATTRIBUTE_VALUES = "Attribute Values",
    SEND_PROOF_BUTTON = "Send",
    NO_REQUEST_RECEIVED = "No request received...",
    NO_CREDENTIAL_AVAILABLE = "No credential available...",
    SEND_PROOF_SUCCESS = "Generate and send proof successfully...",
    NO_CREDENTIAL_DETAILS_AVAILABLE = "No credential details available...",
    PROOF_PREDICATES = 'Proof Predicates',
    PROOF_PREDICATE_TYPE = 'Predicate Type',
    PROOF_PREDICATE_VALUE = 'Predicate Value',
    PROOF_PREDICATE_ATTRIBUTE = 'Attribute Name',
    SELF_ATTESTED_ATTRIBUTES = 'Self Attested Attributes'
}

export enum addNewImportCredentialRecord {
    ADD_IMPORT_CREDENTIAL_HEADER = 'Import Credential',
    CREDENTIAL_NAME = 'Credential Name',
    CONNECTION_NAME = 'Given Name',
    CONNECTION_ID = 'Connection ID',
    CONNECTION_DID = 'Connection DID',
    CREDENTIAL_VALUE = 'Credential Value',
    CANCEL_BUTTON = 'CANCEL',
    SAVE_BUTTON = 'SAVE',
    IMPORT_CREDENTIAL_DETAILS = 'Import Credential Details',
    SUCCESS_MESSAGE = 'Import credential record added successfully.',
    IMPORT_ANOTHER_CREDENTIAL_BUTTON = 'IMPORT ANOTHER CREDENTIAL',
    IMPORT_CREDENTIAL_LIST = 'Import Credential List'
}
export enum basicMessageConstant {
    CHATS = "Chats",
    CONNECTION_LIST = "Connection List",
    NO_MESSAGE_YET = "No messages yet",
    CLAIM_CREDENTIAL_BUTTON = "Claim Credential"
}

export enum availableClaimCredentialsList {
    CONNECTION_DETAILS = "Connection Details",
    RECEIVED_CREDENTIALS = "Received Credentials",
    CREDENTIAL_NOT_AVAILABLE_MESSAGE = "Currently not available any credential...",
    AVAILABLE_CREDENTIALS = "Available Credentials",
    CREDENTIAL_DETAILS = "Credentials Details",
    CLAIM_BUTTON = "CLAIM"
}

export enum presentationProposalRequest {
    PRESENTATION_PROPOSAL_LIST = 'Presentation Proposal List',
    NO_PROPOSAL_RECEIVED = "No proposal received...",
    PROPOSAL_DETAILS = 'Proposal Details',
    NEW_PROPOSAL = 'New Proposal',
}

export enum credentialProposalRequest {
    CREDENTIAL_PROPOSAL_LIST = 'Credential Proposal List',
    NO_PROPOSAL_RECEIVED = "No proposal received...",
    PROPOSAL_DETAILS = 'Proposal Details',
    NEW_PROPOSAL = 'New Credential Proposal',
}

/**
 * Enumerator for identify the type of cred defs  
 */
export enum CredDefTypes {
    SPECIAL_PURPOSE = 'specialPurpose',
    NOT_SPECIAL_PURPOSE = 'notSpecialPurpose',
    ALL_PURPOSE = 'allPurpose',
}

export enum CredentialState {
    STATE_PROPOSAL_SENT = 'proposal_sent',
    STATE_PROPOSAL_RECEIVED = 'proposal_received',
    STATE_OFFER_SENT = 'offer_sent',
    STATE_OFFER_RECEIVED = 'offer_received',
    STATE_REQUEST_SENT = 'request_sent',
    STATE_REQUEST_RECEIVED = 'request_received',
    STATE_ISSUED = 'credential_issued',
    STATE_CREDENTIAL_RECEIVED = 'credential_received',
    STATE_ACKED = 'credential_acked',
    STATE_CREDENTIAL_REVOKED = 'credential_revoked',
    STATE_ABANDONED = 'abandoned'
}

export enum PresentProofState {
    STATE_PROPOSAL_SENT = 'proposal_sent',
    STATE_PROPOSAL_RECEIVED = 'proposal_received',
    STATE_REQUEST_SENT = 'request_sent',
    STATE_REQUEST_RECEIVED = 'request_received',
    STATE_PRESENTATION_SENT = 'presentation_sent',
    STATE_PRESENTATION_RECEIVED = 'presentation_received',
    STATE_VERIFIED = 'verified',
    STATE_PRESENTATION_ACKED = 'presentation_acked',
    STATE_ABANDONED = 'abandoned'
}

export enum CredentialRole {
    ROLE_ISSUER = 'issuer',
    ROLE_HOLDER = 'holder'
}

export enum PresentProofRole {
    ROLE_PROVER = 'prover',
    ROLE_VERIFIER = 'verifier'
}
