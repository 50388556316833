import { presentationRequestConstants } from "../../types";
import { t } from 'i18next';

const initialState = {
    presentationList: [],
    totalPresentations: 0,
    tHead: [],
    selectedPresentationRecord: {},
    createNewRequest: false,
}
export function PresentationRequestReducer(state = initialState, action: any) {
    let tHead = [{
        'name': `${t("screens:newProofRequest.date")}`,
        'label': "createDateTime"
    }, {
        'name': `${t("screens:newProofRequest.givenName")}`,
        'label': "connectionName"
    }, {
        'name': `${t("screens:newProofRequest.reason")}`,
        'label': "requestReason"
    }, {
        'name': `${t("screens:newProofRequest.status")}`,
        'label': "state"
    }, {
        'name': ``,
        'label': "proposalViewBtn"
    }]

    switch (action.type) {

        case presentationRequestConstants.SHOW_NEW_REQUEST:
            return Object.assign({}, state, {
                createNewRequest: true,
                emailSent: false,
                showErrorMessage: false,
            });
        case presentationRequestConstants.CREATE_NEW_REQUEST:
            return Object.assign({}, state, {
                createNewRequest: false,
                selectedPresentationRecord: action.payload
            });
        /* Get all present proof request list */
        case presentationRequestConstants.GET_ALL_PRESENTATION_REQUEST_LIST:
            console.log('action.payload', action.payload)
            return Object.assign({}, state, {
                presentationList: action.payload.data,
                totalPresentations: action.payload.totalItems,
                tHead: tHead,
            });

        case presentationRequestConstants.SELECTED_PRESENTATION_REQUEST_RECORD:
            return Object.assign({}, state, {
                selectedPresentationRecord: action.payload
            })

        default:
            return state
    }

}
