import * as React from 'react';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import CropImageModal from '../components/cropImageModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { RegistrationAction } from '../actions/registerAction';
import RegistrationLayout from '../components/RegistrationLayout';
import PendingRequest from '../components/PendingRequest';
import SuccessPage from '../components/SuccessPage';
import RequestFailed from '../components/RequestFailed';
import PageLoader from '../../../Common Components/Components/pageLoader';
import { toastr } from 'react-redux-toastr';

/* Interface for Props variables*/
export interface IRegisterProps {
  RegistrationAction: Function | any,
  selectedRecord?: any,
  LoginReducer: any,
  RegisterReducer: any,
  OnboardingReducer: any,
  LoaderReducer: any,
}

/* Interface for local states variables*/
export interface IRegisterState {
  fields: any,
  errors: any,
  clearTypeahead: boolean,
  selectedOrgName: any,
  src: any,
  crop: any,
  isModalShow: boolean,
  croppedImageUrl: any,
  showCroppedImage: boolean,
  croppedImage: any,
  type: string,
  isEdit: boolean,
  schemas: any,
}

class Register extends React.Component<IRegisterProps, IRegisterState> {
  private imageRef: any;
  private fileUrl: any;
  constructor(props: IRegisterProps) {
    super(props);
    /* Initialization of state variables*/
    this.state = {
      fields: {
        subscription: 1
      },
      errors: {},
      clearTypeahead: false,
      selectedOrgName: "",
      isModalShow: false,
      src: null,
      crop: {
        unit: '%',
        width: 30,
        height: 30,
        x: 25,
        y: 25,
      },
      croppedImageUrl: "",
      showCroppedImage: false,
      croppedImage: "",
      type: 'password',
      isEdit: false,
      schemas: [
        { tag: 1 },
        { tag: 2 },
        { tag: 3 },
        { tag: 4 },
        { tag: 5 },
        { tag: 6 },
        { tag: 7 },
        { tag: 8 },
        { tag: 9 },
        { tag: 10 }
      ]
    }
    /* Binding the method */
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.makeClientCrop = this.makeClientCrop.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);
    this.save = this.save.bind(this);
    this.dataURLtoFile = this.dataURLtoFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.cancelRegistrationForm = this.cancelRegistrationForm.bind(this);
    this.clearCroppedImage = this.clearCroppedImage.bind(this);
    this.handleSchemaChange = this.handleSchemaChange.bind(this);
    this.handleCountryName = this.handleCountryName.bind(this)
    this.cancel = this.cancel.bind(this);
    this.handleStateName = this.handleStateName.bind(this);
  }

  /* Default react life-cycle method, it calls after render method executed. */
  componentDidMount() {
    const { loggedInRecord } = this.props.LoginReducer;
    if (!localStorage.getItem('orgId')) {
      localStorage.setItem("orgId", loggedInRecord.userDetails.organization.id);
    }
    this.props.RegistrationAction.getAllCountries()
    this.props.RegistrationAction.getRegistrationDetails((loggedInRecord && loggedInRecord.userDetails && loggedInRecord.userDetails.organization && loggedInRecord.userDetails.organization.id) ? loggedInRecord.userDetails.organization.id : localStorage.getItem('orgId'));
  }

  /**
   * Method used to set the selected schema from dropdown
   * @param selectedOptions Parameter used to get selected schema details
   */
  handleSchemaChange(selectedOptions: any) {
    let fields = this.state.fields;
    if (selectedOptions[0] && selectedOptions[0].tag) {
      fields["schema"] = selectedOptions[0].tag;
    } else {
      fields["schema"] = ''
    }
    this.setState({
      fields: fields
    })
  }

  /* Select country ISO from selected county record */
  handleCountryName(selectedOption: any) {
    let fields = this.state.fields;
    if (selectedOption[0] && selectedOption[0].iso) {
      this.props.RegistrationAction.getAllStatesByCountry(selectedOption[0].id)
      fields["countryISO"] = selectedOption[0].iso;
      fields["state"] = '';
    } else {
      fields["countryISO"] = ''
      fields["state"] = '';
    }
    this.setState({
      fields: fields
    })
  }

  /**
   * Method used to set state value from selected option from dropdown.  
   * @param selectedOption Parameter used to get selected state details.
   */
  handleStateName(selectedOption: any) {
    let fields = this.state.fields;
    if (selectedOption[0] && selectedOption[0].state_code) {
      fields["state"] = selectedOption[0].state_code;
      fields["countryISO"] = fields.countryISO.includes('-')
        ? fields.countryISO.replace(fields.countryISO.split('-')[1], selectedOption[0].state_code)
        : `${fields.countryISO}-${selectedOption[0].state_code}`;
    } else {
      fields["state"] = '';
    }
    this.setState({
      fields: fields
    })
  }

  /**
   * Method used to set the organization subscription
   * @param event Parameter used to get selected subscription
   */
  subscriptionChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let fields = this.state.fields;
    fields["subscription"] = parseInt(event.currentTarget.value);
    this.setState({
      fields: fields
    });
  }

  /**
   * Method used to set selected organization logo file
   * @param event Parameter used to get selected logo file details.
   */
  onSelectFile(event: React.ChangeEvent<HTMLInputElement> | any) {
    var fileName = event.target.value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      if (event.target.files && event.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result, isModalShow: true })
        );
        reader.readAsDataURL(event.target.files[0]);
      }
      event.target.value = null;
    } else {
      toastr.info(`Only jpg/jpeg and png files are allowed!`, ``);
    }

  };

  /**
   * Method used to clear selected and cropped logo image 
   */
  clearCroppedImage() {
    this.setState({ croppedImageUrl: "", showCroppedImage: false })
  }

  handleOrgChange(selectedOptions: any) {
    let fields = this.state.fields;
    fields["subscription"] = selectedOptions[0].tag;
    this.setState({
      fields: fields
    })
  }

  /**
   * Method used to set the form values
   * @param event Parameter used to get input box values
   */
  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { orgData } = this.props.RegisterReducer;
    let fields = this.state.fields;
    if (!fields.orgName && orgData.orgName) {
      fields["orgName"] = orgData.orgName;
    }
    fields[event.target.name] = event.target.value;
    fields["adminUsername"] = orgData.adminName;
    fields["adminEmail"] = orgData.adminEmail;
    this.setState({
      fields: fields
    });
  }

  /**
   * Method used to submit registration form values.
   * @param event 
   */
  submituserRegistrationForm = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const { fields, croppedImage } = this.state;
    if (this.validateForm()) {
      console.log("fields------", fields)
      const formData = new FormData()
      formData.append('orgName', fields.orgName)
      formData.append('description', fields.description)
      formData.append('displayName', fields.displayName)
      formData.append('logoUrl', croppedImage)
      formData.append('address', fields.address)
      formData.append('adminEmail', fields.adminEmail)
      formData.append('adminContact', `${fields.adminContact}`)
      formData.append('subscription', fields.subscription)
      formData.append('orgCategory', fields.orgCategory)
      formData.append('adminPassword', fields.adminPassword)
      formData.append('adminUsername', fields.adminUsername)
      formData.append('schema', fields.schema)
      formData.append('companyRegistrationNo', fields.companyRegistrationNo)
      formData.append('countryISO', fields.countryISO)
      formData.append('state', fields.state)

      this.props.RegistrationAction.registration(formData);
    }

  }

  /**
   * Method used to validate the form values
   * @returns 
   */
  validateForm() {
    let fields: any = this.state.fields;
    const { states } = this.props.RegisterReducer;
    let errors: any = {};
    let formIsValid: any = true;

    if (!fields["orgName"]) {
      formIsValid = false;
      errors["orgName"] = "*Please enter your Organization Name.";
    }

    if (!fields["orgCategory"]) {
      formIsValid = false;
      errors["orgCategory"] = "*Please enter your Industry Name.";
    }

    if (typeof fields["displayName"] !== "undefined") {
      if (!fields["displayName"].match(/^.{4,20}$/)) {
        formIsValid = false;
        errors["displayName"] = "*Please enter Minimum 4 and Maximum 19 Characters";
      }
    }

    if (!fields["adminContact"]) {
      formIsValid = false;
      errors["adminContact"] = "*Please enter your mobile no.";
    }

    if (typeof fields["adminContact"] !== "undefined") {
      if (!fields["adminContact"].match(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/)) {
        formIsValid = false;
        errors["adminContact"] = "*Please enter valid contact number.";
      }
    }

    if (!fields["countryISO"]) {
      formIsValid = false;
      errors["countryISO"] = "Please select your country";
    }

    if (!fields["state"] && states && states.data.length > 0) {
      formIsValid = false;
      errors["state"] = "Please select your state";
    }

    if (!fields["companyRegistrationNo"]) {
      formIsValid = false;
      errors["companyRegistrationNo"] = "Please enter your company registration no.";
    }

    if (fields["description"]) {
      if (fields["description"].length >= 500) {
        formIsValid = false;
        errors["description"] = "Do not enter more than 500 character";
      }
    }

    if (fields["address"]) {
      if (fields["address"] >= 100) {
        formIsValid = false;
        errors["address"] = "Do not enter more than 100 character";
      }
    }

    if (!fields["schema"]) {
      formIsValid = false;
      errors["schema"] = "*Please select schema.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;

  }

  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  /**
   * Method used to set cropped image
   * @param crop 
   */
  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  /**
   * Method used to convert image data URL in to file 
   * @param dataurl Parameter used to get image data URL
   * @param filename Parameter used to get image file name
   */
  dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage })
  }

  /**
   * Method used get cropped image values and set it.
   * @param image Parameter used to get actual image
   * @param crop Parameter used to get cropped image values
   * @param fileName Parameter ued to get image file name
   * @returns It return the cropped image URL
   */
  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'cropped.jpg')
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');

    });
  }

  /**
   * Method used to show modal for crop image
   */
  save() {
    this.setState({ showCroppedImage: true, isModalShow: false, src: null })
  }

  /**
   * Method used to cancel the modal
   */
  cancel() {
    this.setState({ isModalShow: false })
  }
  handleClick = () => {
    this.state.type === 'password' ? this.setState({ type: 'text' }) : this.setState({ type: 'password' })
  }

  /**
   * Method used to clear the form values
   * @param event 
   */
  cancelRegistrationForm(event: React.ChangeEvent<HTMLInputElement> | any) {

    this.setState({
      fields: {},
      errors: {},
      clearTypeahead: false,
      selectedOrgName: "",
      isModalShow: false,
      src: null,
      crop: {
        unit: '%',
        width: 30,
        // aspect: 16 / 9,
      },
      croppedImageUrl: "",
      showCroppedImage: false,
      croppedImage: "",
      type: 'password',
    })
  }

  public render() {
    const { orgData, showSuccessPage, countries, states } = this.props.RegisterReducer;
    const { loader } = this.props.LoaderReducer;
    const { crop, croppedImageUrl, src, isModalShow, showCroppedImage, fields, schemas } = this.state;
    const disableFields = _.isEmpty(orgData);
    return (

      <RegistrationLayout status={orgData && orgData.isOnBoarded}>
        {!_.isEmpty(orgData) && orgData.isOnBoarded === 1 && !showSuccessPage ?
          <PendingRequest {...this.props} />
          :
          !_.isEmpty(orgData) && orgData.isOnBoarded === 3 && !showSuccessPage ?
            <RequestFailed />
            :
            showSuccessPage ?
              <SuccessPage {...this.props} />
              :
              <>
                {
                  loader ?
                    <PageLoader></PageLoader>
                    : null
                }
                <div className="row">
                  <div className="col-sm-12">
                    <h1>Registration</h1>
                    <hr />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                    <label className="nb-label">Company Logo<span>*</span></label>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-9">
                            {croppedImageUrl && showCroppedImage && (
                              <div className="card nb-card-image" style={{ backgroundImage: `url(${croppedImageUrl})` }}>
                                <div className="Card-body"></div>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-3 align-self-center">
                            {showCroppedImage === false ? <div>
                              <div className="upload-btn-wrapper float-right">
                                <button disabled={disableFields}>CHANGE</button>
                                <input type="file" accept="image/png,image/gif,image/jpeg"
                                  onChange={this.onSelectFile} name="logoUrl" disabled={disableFields} />
                              </div>
                            </div>
                              :
                              <div>
                                <button className="btn btn-nb-outline-primary" onClick={this.clearCroppedImage}>Cancel</button>
                              </div>
                            }
                            {isModalShow && <CropImageModal
                              isModalShow={isModalShow}
                              src={src}
                              crop={crop}
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                              save={this.save}
                              cancel={this.cancel}
                            />}
                          </div>
                        </div>
                      </div>
                    </div>
                    {showCroppedImage ?
                      '' :
                      <span className="text-danger">Please Select Logo</span>
                    }
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Admin User Name<span>*</span></label>
                      <input type="text" className="form-control" name="adminUsername"
                        value={!_.isEmpty(orgData) ? orgData.adminName :
                          this.state.fields.adminUsername
                        } onChange={(e) => this.handleChange(e)}
                        readOnly={orgData && orgData.adminName ? true : disableFields ? true : false}
                      />
                      <div className="text-danger">{this.state.errors.adminUsername}</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="nb-label">Display Name<span>*</span></label>
                      <input type="text" className="form-control" name="displayName"
                        autoComplete="off"
                        value={!_.isEmpty(orgData) && orgData.displayName !== null ? orgData.displayName :
                          this.state.fields.displayName
                        }
                        onChange={(e) => this.handleChange(e)}
                        readOnly={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.displayName}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Admin Email<span>*</span></label>
                      <input type="email" className="form-control" name="adminEmail"
                        value={!_.isEmpty(orgData) && orgData.adminEmail !== null ? orgData.adminEmail :
                          this.state.fields.adminEmail
                        } onChange={(e) => this.handleChange(e)}
                        readOnly={orgData && orgData.adminEmail ? true : disableFields ? true : false}
                      />
                      <div className="text-danger">{this.state.errors.adminEmail}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Organization Name<span>*</span></label>
                      <input type="text" className="form-control" name="orgName"
                        value={!this.state.fields.orgName ? orgData.orgName :
                          this.state.fields.orgName
                        }
                        onChange={(e) => this.handleChange(e)}
                        readOnly={true}
                      />
                      <div className="text-danger">{this.state.errors.orgName}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Admin Contact<span>*</span></label>
                      <input type="text" className="form-control" name="adminContact" autoComplete="off" maxLength={13}
                        placeholder="NNN-NNN-NNNN"
                        value={this.state.fields.adminContact}
                        onChange={(e) => this.handleChange(e)}
                        readOnly={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.adminContact}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Industry<span>*</span></label>
                      <input type="text" className="form-control" name="orgCategory" autoComplete="off"
                        value={!_.isEmpty(orgData) && orgData.orgCategory !== null ? orgData.orgCategory :
                          this.state.fields.orgCategory}
                        onChange={(e) => this.handleChange(e)}
                        readOnly={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.orgCategory}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Company Registration No<span>*</span></label>
                      <input type="text" className="form-control" name="companyRegistrationNo" autoComplete="off"
                        value={!_.isEmpty(orgData) && orgData.companyRegistrationNo !== null ? orgData.companyRegistrationNo :
                          this.state.fields.companyRegistrationNo}
                        onChange={(e) => this.handleChange(e)}
                        readOnly={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.companyRegistrationNo}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Company Registration Country<span>*</span></label>
                      <Typeahead data={countries} onChange={this.handleCountryName} onInputChange={this.handleCountryName}
                        clearTypeahead={this.state.clearTypeahead} placeholder=""
                        disabled={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.countryISO}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Company Registration State<span>*</span></label>
                      {states.length === 0 || (states && states.data && states.data.length === 0) ?
                        <input type="text" className="form-control" name="state" disabled />
                        :
                        <Typeahead data={states.data} onChange={this.handleStateName} onInputChange={this.handleStateName}
                          clearTypeahead={this.state.clearTypeahead} placeholder="" disabled={disableFields}
                        />
                      }
                      <div className="text-danger">{this.state.errors.state}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Address<span>*</span></label>
                      <textarea className="form-control" name="address" style={{ height: '113px' }}
                        value={!_.isEmpty(orgData) && orgData.address !== null ? orgData.address :
                          this.state.fields.address
                        }
                        onChange={(e) => this.handleChange(e)}
                        readOnly={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.address}</div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Description<span>*</span></label>
                      <textarea className="form-control" name="description" style={{ height: '113px' }}
                        value={!_.isEmpty(orgData) && orgData.description !== null ? orgData.description :
                          this.state.fields.description
                        }
                        onChange={(e) => this.handleChange(e)}
                        readOnly={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.description}</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="nb-label">Role to Subscribe<span>*</span></label>
                      <div className="card">
                        <div className="card-body" >
                          <div className="custom-control custom-radio">
                            <input type="radio" id="issuer" name="subscription" className="custom-control-input" value={1}
                              onChange={(e) => this.subscriptionChange(e)} checked={this.state.fields.subscription === 1}
                              disabled={disableFields}
                            />
                            <label className="custom-control-label" htmlFor="issuer">Issuer</label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input type="radio" id="verifier" name="subscription" className="custom-control-input" value={2}
                              onChange={(e) => this.subscriptionChange(e)} checked={this.state.fields.subscription === 2}
                              disabled={disableFields}
                            />
                            <label className="custom-control-label" htmlFor="verifier">Verifier</label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input type="radio" id="verifierandverifier" name="subscription" className="custom-control-input" value={3}
                              onChange={(e) => this.subscriptionChange(e)} checked={this.state.fields.subscription === 3}
                              disabled={disableFields}
                            />
                            <label className="custom-control-label" htmlFor="verifierandverifier">Issuer & Verifier</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <label className="nb-label">Schemas<span>*</span></label>
                      <Typeahead data={schemas} onChange={this.handleSchemaChange} onInputChange={this.handleSchemaChange}
                        clearTypeahead={this.state.clearTypeahead} placeholder=""
                        disabled={disableFields}
                      />
                      <div className="text-danger">{this.state.errors.schema}</div>
                    </div>
                  </div>
                  <div className="col-sm-6 align-self-end ml-auto">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          {/* <a type="button" className="btn btn-block rounded-pill btn-outline-primary mr-3" href="/login"> */}
                          <a type="button" className="btn btn-block rounded-pill btn-outline-primary mr-3" href="/">
                            BACK
                          </a>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <button type="button" className="btn btn-block rounded-pill btn-primary" onClick={(e) => this.submituserRegistrationForm(e)}
                          disabled={showCroppedImage === false || !fields.description || !fields.subscription || !fields.orgCategory || !fields.adminContact || !fields.displayName ? true : false}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
        }
      </RegistrationLayout>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const RegisterReducer = state.RegisterReducer;
  const OnboardingReducer = state.OnboardingReducer;
  const LoaderReducer = state.LoaderReducer;
  return { LoginReducer, RegisterReducer, OnboardingReducer, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    RegistrationAction: bindActionCreators(RegistrationAction, dispatch),
  }
}

const register = connect(mapStateToProps, mapDispatchToProps)(Register);
export { register as Register };
