import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { issuanceAction } from "src/View/IssueCredential/actions/issuanceAction";
import { connectionAction } from "src/View/Connection/actions/connectionAction";
import { TaskActions } from "src/View/Task/actions/taskActions";
import { credentialAction } from "src/View/Credentials/actions/credentialAction";
import SuccessPage from "../components/SuccessPage";
import { t } from 'i18next';
import { myCredentialsAction } from "src/View/MyCredentials/action/myCredentialsAction";
import { CredentialProposalList } from "../components/CredentialProposalList";
import CreateCredentialProposal from "../components/CreateCredentialProposal";
import { CredentialProposalDetails } from "../components/CredentialProposalDetails";
import { credentialProposalAction } from "../actions/credentialProposalAction";



/* Interface for Props variables*/
interface ICredentialProposalProps {
  CredentialProposalReducer: any,
  CredentialProposalAction: any,
  connectionAction: any,
  ConnectionReducer: any,
  issuanceAction: any,
  IssuanceReducer: any,
  LoginReducer: any,
  TaskReducers: any,
  TaskActions: any,
  credentialAction: any,
  CredentialReducer: any,
  myCredentialReducer: any,
  myCredentialsAction: any,
}

/* Interface for local states variables*/
interface ICredentialProposalState {
  showProofDetails: boolean,
  selectedProofDetails: any,
  selectedConnectionName: string,
  requestedAttributesDetails: any[],
  requestedPredicatesDetails: any[],
  page: number,
  itemPerPage: number,
  searchText: string,
  status: any,
  sortValue: any,
  sortBy: any,
  showAttributeValues: boolean
  isDefault: boolean,
  presentationExchangeId: string,
  selectedCredentials: any[],
  credentialsToReveal: any[],
  showCredentialDetails: boolean
}

class CredentialProposal extends React.Component<ICredentialProposalProps, ICredentialProposalState> {
  constructor(props: ICredentialProposalProps) {
    super(props)
    /* Initialization of state variables*/
    this.state = {
      showProofDetails: false,
      selectedProofDetails: {},
      selectedConnectionName: "",
      requestedAttributesDetails: [],
      page: 1,
      itemPerPage: 10,
      searchText: "",
      status: '',
      sortValue: '',
      sortBy: '',
      showAttributeValues: true,
      isDefault: true,
      presentationExchangeId: '',
      requestedPredicatesDetails: [],
      selectedCredentials: [],
      credentialsToReveal: [],
      showCredentialDetails: false
    }

    this.selectedProposalRecord = this.selectedProposalRecord.bind(this)
    this.createNewProposal = this.createNewProposal.bind(this)
  }

  /* Method to call the get presentation requests method */
  componentDidMount() {
    this.props.CredentialProposalAction.requestCredentialComponent()
  }

  /* Method used to show selected proof request details */
  selectedProposalRecord(item: any) {
    this.props.issuanceAction.showSelectedCredentialDetails(item)
    this.props.CredentialProposalAction.requestCredentialComponent()
    this.setState({
      showCredentialDetails: true
    })
  }

  createNewProposal() {
    this.props.CredentialProposalAction.requestCredentialComponent()
    this.setState({
      showCredentialDetails: false
    })
  }

  /* UI to display the all the child component */
  public render() {
    const { credentialReqData: credentialProposalList, selectedCredentialRecord } = this.props.IssuanceReducer
    let { showSuccessProposalCredentialComponent } = this.props.CredentialProposalReducer;
    const { showCredentialDetails } = this.state

    return (
      <>
        <div className="row gutters-sm min-h-100 animated fadeIn">
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">

                <h1 className="nb-title">{t("screens:credentialProposal.credentialProposalList")}</h1>
                <button className="btn btn-sm btn-nb-outline-primary float-right"
                  onClick={this.createNewProposal}>
                  <i className="fa fa-plus"></i>&nbsp;
                  {t("screens:credentialProposal.create")}
                </button>

              </div>
              <div className="card-body">
                  {/* Component for display the proof request list */}
                  <CredentialProposalList
                    credentialProposalList={credentialProposalList}
                    selectedProposalRecord={this.selectedProposalRecord}
                  />
              </div>
            </div>
          </div>

          {showSuccessProposalCredentialComponent ?
            <div className="col-md-12 col-lg-6 mb-30">
              <SuccessPage {...this.props} />
            </div>
            :
            <>
              <div className="col-md-12 col-lg-6 mb-30">
                <div className="card nb-card h-100">
                  {
                    showCredentialDetails
                      ? <CredentialProposalDetails selectedCredentialRecord={selectedCredentialRecord} />
                      : <CreateCredentialProposal {...this.props} />
                  }
                </div>
              </div>
            </>
          }

        </div>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  const CredentialProposalReducer = state.CredentialProposalReducer;
  const LoginReducer = state.LoginReducer;
  const IssuanceReducer = state.IssuanceReducer;
  const ConnectionReducer = state.ConnectionReducer;
  const TaskReducers = state.TaskReducers;
  const CredentialReducer = state.CredentialReducer;
  const myCredentialReducer = state.myCredentialReducer

  return { CredentialProposalReducer, LoginReducer, IssuanceReducer, ConnectionReducer, TaskReducers, CredentialReducer, myCredentialReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    CredentialProposalAction: bindActionCreators(credentialProposalAction, dispatch),
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
    connectionAction: bindActionCreators(connectionAction, dispatch),
    TaskActions: bindActionCreators(TaskActions, dispatch),
    credentialAction: bindActionCreators(credentialAction, dispatch),
    myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
  }
}

const credentialProposal = connect(mapStateToProps, mapDispatchToProps)(CredentialProposal);
export { credentialProposal as CredentialProposal };
