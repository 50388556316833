import _ from 'lodash';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import TypeaheadComponent from '../../../Common Components/Components/TypeaheadComponent';
// import LoginCredentialModal from './LoginCredentialModal';

export interface IPlatformConfigProps {
  PlatformConfigActions: any,
  PlatformConfigReducers: any
}

export interface IPlatformConfigState {

}

export default class PlatformConfigDetails extends React.Component<IPlatformConfigProps, IPlatformConfigState> {
  constructor(props: IPlatformConfigProps) {
    super(props);

    this.state = {

    }

  }

  componentDidMount() {
    this.props.PlatformConfigActions.getPlatformConfigDetails();
  }


  public render() {
    const { platformConfigData } = this.props.PlatformConfigReducers;
    const { } = this.state;
    return (
      <>

        <div className="row">
          <div className="col-sm-12">
            <div className="card nb-card-noborder">
              <div className="card-header bg-white">
                <h1>
                  Platform config
                  </h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">External-IP:</label>
                          <input type="text" className="form-control" disabled value={platformConfigData.externalIP} />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">SG-APIKey:</label>
                          <input type="text" className="form-control" disabled value={platformConfigData.sgApiKey} />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">Genesis-URL:</label>
                          <input type="text" className="form-control" disabled value={platformConfigData.genesisURL} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">Internal-IP:</label>
                          <input type="text" className="form-control" disabled value={platformConfigData.lastInternalIP} />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">SG-Email:</label>
                          <input type="text" className="form-control" disabled value={platformConfigData.sgEmailFrom} />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
