import Axios from "axios";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { createWalletConst, loaderConst } from "../../types";
import { t } from 'i18next';

export const createWalletAction = {

  createWallet(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/agent/spin-up`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: createWalletConst.CREATE_WALLET,
              payload: response
            })
            toastr.success(`${t("screens:createWallet.messageAgentSpined")}`,`${t("screens:createWallet.messageCreatingWallet")}`)

          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  errorAgentSpinup() {
    return async (dispatch:any) => {
      try {
        dispatch({
          type: createWalletConst.ERROR_AGENT_SPINUP,
        })
      } catch (error) {
        throw error;
      }
    }
  }
}