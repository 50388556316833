import axios from "axios";
import _ from "lodash";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, multiuseConnectionConst } from "../../types";
import moment from "moment";
import { t } from "i18next";

export const multiuseAction = {
    getQRdata(type: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.CREDENTIAL_LIST_LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.post(`${config.apiUrl}/connection/${type}/create`, "",
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(data => {
                        if (data.data.data) {
                            var res = data.data.data;
                            return (
                                dispatch({
                                    type: multiuseConnectionConst.GET_MULTIUSE_QR,
                                    payload: res.shorteningUrl
                                })
                            )
                        }
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);

                        throw error
                    })
            } catch (error) {
                throw error;
            }
        }
    },

    createMultiuse(
        type: string,
        formData: any
    ) {
        return async (dispatch: any) => {
            try {
                let token = localStorage.getItem('token');
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })

                return await axios.post(`${config.apiUrl}/connection/${type}/create`, "",
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(data => {
                        if (data.data.data) {
                            var res = data.data.data;
                            formData['connectionInvitation'] = res.shorteningUrl
                            return axios.post(`${config.apiUrl}/multiuse/add-multi`, formData,
                                { headers: { "Authorization": `Bearer ${token}` } })
                                .then(data => {
                                    console.log(`ass-multiuse data: ${JSON.stringify(data)} formData: ${JSON.stringify(formData)}`)
                                    dispatch({
                                        type: multiuseConnectionConst.GET_MULTIUSE_QR,
                                        payload: res.shorteningUrl
                                    })
                                    dispatch({
                                        type: multiuseConnectionConst.MULTIUSE_SENT
                                    })

                                    this.getMultiuseList(type, "", 10, 1, 'DESC')
                                    dispatch({
                                        type: loaderConst.LOADER_FALSE
                                    })
                                })
                        }
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        throw error
                    })
            } catch (error) {
                throw error;
            }
        }
    },

    getMultiuseList(
        type: any,
        defaultSearchFields: any,
        item_perPage: number,
        pageNo: number,
        sortingOrder: string,
    ) {
        return async (dispatch: any) => {
            try {
                let token = localStorage.getItem('token');
                return await axios.get(`${config.apiUrl}/multiuse/connections?search_text=${defaultSearchFields}&type=${type}&sortingOrder=${sortingOrder}&itemsPerPage=${item_perPage}&page=${pageNo}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        let tmpArray: any = [];
                        // console.log(`Multi list response ${JSON.stringify(response)}`)
                        tmpArray = !_.isEmpty(response) && response.data && response.data.data && response.data.data.data;
                        tmpArray.map((ele: any, index: any) => {
                            tmpArray[index].invitationName = ele.name;
                            tmpArray[index].status = ele.connectionStatus;
                            tmpArray[index].email = ele.email;
                            tmpArray[index].type = ele.type === '6' ? `${t(`screens:invitationMultiuse.usedByClient`)}` : `${t(`screens:invitationMultiuse.usedByEmployee`)}`
                        })
                        dispatch({
                            type: multiuseConnectionConst.GET_MULTIUSE_LIST,
                            payload: response,
                            tmpArray,
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
                    .catch(error => {
                        console.log(`Multi list ERROR ${error}`)
                        loginAction.handleResponse(error.response, dispatch);

                        throw error
                    })
            } catch (error) {
                console.log(`Multi list ERROR -try ${error}`)
                throw error;
            }
        }
    },

    selectmMultiuse(data: any) {
        return async (dispatch: any) => {
            dispatch({
                type: multiuseConnectionConst.SELECT_MULTIUSE,
                payload: data
            })
        }
    },

    clearSelection() {
        return async (dispatch: any) => {
            dispatch({
                type: multiuseConnectionConst.CLEAR_SELECTION,
            })
        }
    },

    inviteNewMultiuse() {
        return async (dispatch: any) => {
            dispatch({
                type: multiuseConnectionConst.INVITE_NEW_MULTIUSE,
            })
        }
    },
}