import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';

// Utilities
import images from "src/utils/images";
import { t } from 'i18next';
import { isJSON } from 'src/utils/json';

export interface ICredentialValueProps {
  isModalShow: boolean,
  selectedConnection: any,
  selectedCredDef: any,
  closeModal: any,
  handleCredentialValueSubmit?: any,
  credentialState?: string,
  selectedCredential?: any,
  isEdit: boolean,
  modalTitle: string
}

export interface ICredentialValueState {
  credentialValues: Array<any>,
  isFormatError: boolean,
  formatErrorIndex: number,
  isImageError: boolean,
  imageErrorIndex: number,
  textErrorIndexes: any,
  imageErrorIndexes: any,
}

export default class CredentialValueComponent extends React.Component<ICredentialValueProps, ICredentialValueState> {
  constructor(props: ICredentialValueProps) {
    super(props);

    this.state = {
      credentialValues: [],
      isFormatError: false,
      formatErrorIndex: 0,
      isImageError: false,
      imageErrorIndex: -1,
      textErrorIndexes: [],
      imageErrorIndexes: [],
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCredentialValueSubmit = this.handleCredentialValueSubmit.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
  }

  handleChange(event: React.DOMAttributes<HTMLInputElement> | any, index: any, label: any) {
    const { credentialValues, textErrorIndexes } = this.state;
    let formatError = false
    let updateAttribute = credentialValues;
    updateAttribute[index] = event.target.value;

    if (label === 'DD/MM/YYYY' && (!/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(updateAttribute[index]))) {
      formatError = true
    }

    if (label === 'number' && (!/^[0-9]*$/.test(updateAttribute[index]))) {
      formatError = true
    }

    if (updateAttribute[index] === '') {
      formatError = false
    }

    if (formatError && !textErrorIndexes.includes(index)) {
      textErrorIndexes.push(index)
    }

    if (!formatError) {
      const indexFound = textErrorIndexes.indexOf(index);
      if (indexFound > -1) {
        textErrorIndexes.splice(indexFound, 1);
      }
    }

    this.setState({
      credentialValues: updateAttribute,
      isFormatError: formatError,
      formatErrorIndex: index,
      textErrorIndexes: textErrorIndexes
    })
  }

  handleChangeImage(event: React.DOMAttributes<HTMLInputElement> | any, index: any, label: any) {
    const { credentialValues, imageErrorIndexes } = this.state;
    let updateAttribute = credentialValues;
    const iFile = event.target.files

    const indexFound = imageErrorIndexes.indexOf(index);

    if (iFile && iFile.length > 0) {
      let reader = new FileReader();
      const fSize = iFile[0].size
      const file = Math.round((fSize / 1024));

      // The size of the file.
      if (file >= 100) {
        if (indexFound === -1) {
          imageErrorIndexes.push(index)
        }
        updateAttribute[index] = '';
      }
      else {
        updateAttribute[index] = event.target.value;
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          updateAttribute[index] = reader.result
          this.setState({
            credentialValues: updateAttribute,
          })
        };
        if (indexFound > -1) {
          imageErrorIndexes.splice(indexFound, 1);
        }
      }
    }

    this.setState({
      imageErrorIndexes
    })
  }

  handleCredentialValueSubmit() {
    const { credentialValues, isFormatError } = this.state;
    const { selectedCredDef } = this.props

    if (!isFormatError) {
      if (credentialValues.length === 0) {
        const attributes = (selectedCredDef && isJSON(selectedCredDef)) ?
          ((JSON.parse(selectedCredDef)).credential_proposal.attributes) :
          selectedCredDef?.attributes
        let updateAttribute = credentialValues;
        attributes?.map((attribute: any, index: number) => {
          updateAttribute[index] = '';
        })

      }
      this.props.handleCredentialValueSubmit(credentialValues)
      this.props.closeModal()
    }
  }

  public render() {
    const { isModalShow, selectedConnection, selectedCredDef, isEdit, modalTitle } = this.props;
    let { credentialValues, textErrorIndexes, imageErrorIndexes } = this.state;
    console.log('selectedCredDef', selectedCredDef)
    let attributes = (selectedCredDef && isJSON(selectedCredDef)) ?
      ((JSON.parse(selectedCredDef)).credential_proposal.attributes) :
      selectedCredDef?.attributes

    return (
      <Modal isOpen={isModalShow} className="nb-modal animated slideInRight" size='lg' toggle={this.props.closeModal}>
        <ModalBody>
          <div className="card-header bg-white border-bottom-0">
            <h1 className="nb-title">{modalTitle}</h1>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <ul className="list-group list-group-flush mb-3">
                <li className="list-group-item p-1">
                  <div className="row">
                    <div className="col-sm-3">
                      <label className="font-weight-bold">{t("screens:issueCredentials.givenName")}:</label>
                    </div>
                    <div className='col-sm-9'>
                      <p>
                        {selectedConnection && selectedConnection.theirLabel}
                      </p>
                    </div>
                  </div>
                </li>

                <li className="list-group-item p-1">
                  <div className="row">
                    <div className="col-sm-3">
                      <label className="font-weight-bold">{t("screens:issueCredentials.credentialName")}:</label>
                    </div>
                    <div className='col-sm-9'>
                      <p>
                        {selectedCredDef && selectedCredDef.schemaName}
                      </p>
                    </div>
                  </div>
                </li>

                <li className="list-group-item p-1">
                  <div className="row">
                    <div className="col-sm-3">
                      <label className="font-weight-bold">{t("screens:issueCredentials.attributes")}:</label>
                    </div>
                    <div className='col-sm-9'>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">

                      {

                        attributes?.map((label: any, index: any) => {
                          label = isJSON(label) ? label : JSON.stringify(label)

                          return (
                            <div className="col-md-12 col-lg-12">
                              <div className="form-group" key={index}>
                                <label>{JSON.parse(label).name}</label>
                                {
                                  isEdit ?
                                    JSON.parse(label).value === 'image' || JSON.parse(label).value.includes('image/') || JSON.parse(label).hasOwnProperty('mime-type') ?
                                      <>
                                        <input type="file" className="form-control a" accept="image/png,image/gif,image/jpeg"
                                          placeholder={JSON.parse(label).value}
                                          onChange={(e) => this.handleChangeImage(e, index, JSON.parse(label).value)}
                                        />
                                        {
                                          imageErrorIndexes.includes(index) ?
                                            <small className="text-danger">
                                              {t("screens:issueCredentials.messageImageSize")}
                                            </small> :
                                            <img src={!_.isEmpty(credentialValues) ? credentialValues[index] : isJSON(selectedCredDef) ? JSON.parse(label).value : ''}
                                              alt=''
                                              className="preview mt-2"
                                              height="80" />
                                        }
                                      </>
                                      : <input type="text" className="form-control"
                                        value={!_.isEmpty(credentialValues) ? credentialValues[index] : isJSON(selectedCredDef) ? JSON.parse(label).value : ''}
                                        placeholder={JSON.parse(label).value}
                                        onChange={(e) => this.handleChange(e, index, JSON.parse(label).value)}
                                        disabled={false}
                                      />
                                    :
                                    JSON.parse(label).value === 'image' || JSON.parse(label).value.includes('image/') || JSON.parse(label).hasOwnProperty('mime-type') ?
                                      <img src={!_.isEmpty(credentialValues) ? credentialValues[index] : isJSON(selectedCredDef) ? JSON.parse(label).value : ''}
                                        alt=''
                                        className="preview  mt-2"
                                        height="80" />
                                      : <input type="text" className="form-control"
                                        value={JSON.parse(label).value}
                                        disabled={true}
                                      />
                                }

                                {
                                  textErrorIndexes.includes(index) ?
                                    <small className="text-danger">
                                      <b>{JSON.parse(label).name}</b> {t("screens:issueCredentials.messageNotMatchFormat")} ({JSON.parse(label).value}) {t("screens:issueCredentials.messageSpecifiedFormat")}
                                    </small> : null
                                }
                              </div>
                            </div>
                          )

                        })
                      }
                    </div>
                  </div>

                </li>

              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8"></div>
            <div className="col-sm-4">
              <button type="button"
                className="btn btn-block btn-nb-outline-primary rounded-pill"
                data-dismiss="modal"
                onClick={(e) => this.handleCredentialValueSubmit()}
                disabled={textErrorIndexes.length > 0 || imageErrorIndexes.length > 0}
              >{t("screens:issueCredentials.done")}</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}