import { t } from 'i18next';

export const tHeadConnection = [{
  'name': `${t("screens:existingConnections.constGivenName")}`,
  'label': "holderName"
}, {
  'name': `${t("screens:existingConnections.constStatus")}`,
  'label': "connectionStatus"
}, {
  'name': `${t("screens:existingConnections.constEmail")}`,
  'label': "email"
}, {
  'name': `${t("screens:existingConnections.constType")}`,
  'label': "holderType"
}, {
  'name': `${t("screens:existingConnections.constDateAdded")}`,
  'label': "createDateTime"
}, {
  'name': '',
  'label': "details"
}];
export const tHeadPendingConnection = [{
  'name': `${t("screens:existingConnections.constGivenName")}`,
  'label': "holderName"
}, {
  'name': `${t("screens:existingConnections.constStatus")}`,
  'label': "connectionStatus"
}, {
  'name': `${t("screens:existingConnections.constEmail")}`,
  'label': "email"
}, {
  'name': `${t("screens:existingConnections.constType")}`,
  'label': "holderType"
}, {
  'name': `${t("screens:existingConnections.constDateAdded")}`,
  'label': "createDateTime"
}];
export const tHeadDeletedConnection = [{
  'name': `${t("screens:deletedConnections.constGivenName")}`,
  'label': "holderName"
}, {
  'name': `${t("screens:deletedConnections.constStatus")}`,
  'label': "connectionStatus"
}, {
  'name': `${t("screens:deletedConnections.constEmail")}`,
  'label': "email"
}, {
  'name': `${t("screens:deletedConnections.constType")}`,
  'label': "holderType"
}, {
  'name': `${t("screens:deletedConnections.constDateAdded")}`,
  'label': "createDateTime"
}, {
  'name': '',
  'label': "details"
}];
export const tHeadPAConnection = [{
  'name': `${t("screens:existingConnections.constDateAdded")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:existingConnections.constConnectionDID")}`,
  'label': "theirDid"
}, {
  'name': `${t("screens:existingConnections.constOptIn")}`,
  'label': "optIn"
}];
export const tHeadCredReqWaitingList = [{
  'name': `${t("screens:existingConnections.constCredentialName")}`,
  'label': "credentialDefinitionId"
}, {
  'name': `${t("screens:existingConnections.constDateTime")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:existingConnections.constState")}`,
  'label': "state"
}, {
  'name': `${t("screens:existingConnections.constDetails")}`,
  'label': "details"
}];
export const tHeadConnectionActivityLog = [{
  'name': `${t("screens:existingConnections.constJoinedDate")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:existingConnections.constGivenName")}`,
  'label': "holderName"
}, {
  'name': `${t("screens:existingConnections.constEmployeeId")}`,
  'label': "connectionId"
}, {
  'name': `${t("screens:existingConnections.constActivity")}`,
  'label': "initiator"
}];

export const tHeadforEditConnection = [{
  'name': `${t("screens:existingConnections.constGivenName")}`,
  'label': "holderName"
}, {
  'name': `${t("screens:existingConnections.constType")}`,
  'label': "holderType"
}, {
  'name': '',
  'label': "view"
}];

export const filterExistingConnections = [{
  'name': `${t("screens:existingConnections.constDateFromNewToOld")}`,
  'tag': 'DESC'
}, {
  'name': `${t("screens:existingConnections.constDateFromOldToNew")}`,
  'tag': 'ASC'
}, {
  'name': `${t("screens:existingConnections.constAlphabeticalAToZ")}`,
  'tag': 'holderName'
}, {
  'name': `${t("screens:existingConnections.constAlphabeticalZToA")}`,
  'tag': 'theirLabel2'
}, {
  'name': `${t("screens:existingConnections.constAll")}`,
  'tag': 'all'
},
{
  'name': `${t("screens:existingConnections.constAllEmployee")}`,
  'tag': 'employee'
}, {
  'name': `${t("screens:existingConnections.constAllClient")}`,
  'tag': 'client'
}, 
// TODO Filter
// {
//   'name': `${t("screens:existingConnections.constAllOrganization")}`,
//   'tag': 'organization'
// }
];

export const filterPAConnection = [{
  'name': `${t("screens:existingConnections.constAllPA")}`,
  'tag': 'all'
}, {
  'name': `${t("screens:existingConnections.constEmployee")}`,
  'tag': 'employee'
}, {
  'name': `${t("screens:existingConnections.constClient")}`,
  'tag': 'client'
},
];
