import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { config } from "../../../config";
import { commonConst, CredDefTypes } from "../../commonConst";
import { loginAction } from "../../Login/actions/loginAction";
import { userConstants, loaderConst, connectionConst, issuanceConst } from "../../types";
import { connectionAction } from "../../Connection/actions/connectionAction"
import _ from "lodash";
import { t } from 'i18next';

export const credentialAction = {
  getAllCredentialByOrgId(
    defaultSearchFields: any,
    supportRevocation: any,
    sortValue: string,
    CredentialSortBy: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');

        supportRevocation = supportRevocation === '' ? '' : (supportRevocation === 'nonRevoke') ? false : true

        return await Axios.get(`${config.apiUrl}/cred-def/list/by-organization?search_text=${defaultSearchFields}&supportRevocation=${supportRevocation}&sortValue=${sortValue}&CredDefSortBy=${CredentialSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(credDef => {
            dispatch({
              type: userConstants.GETALL_SUCCESS_CREDEF,
              payload: credDef
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getPADefaultCredential(
    defaultSearchFields: any,
    supportRevocation: any,
    sortValue: string,
    CredentialSortBy: string,
    item_perPage: number,
    pageNo: number,
    page?: string
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.get(`${config.apiUrl}/cred-def/get-all/default?search_text=${defaultSearchFields}&supportRevocation=${supportRevocation}&sortValue=${sortValue}&CredDefSortBy=${CredentialSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(credDef => {
            dispatch({
              type: userConstants.GETALL_DEFAULT_SUCCESS_CREDEF,
              payload: credDef
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  saveCredential(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/cred-def/create`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(credDef => {
            dispatch({
              type: userConstants.CREATED_CREDDEF,
              payload: credDef
            })
            dispatch({
              type: userConstants.CLEAR_SELECTED_SCHEMA
            })

            setTimeout(() => {
              dispatch({
                type: userConstants.SHOW_SUCCESS_CREDENTIAL_PAGE,
              })
            }, 2000);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            toastr.success(`${t("screens:issueCredentials.messageCredDef")}`, `${credDef.data.data.tag}`);

            this.getAllCredentialByOrgId('', '', 'DESC', '', commonConst.PAGE_RECORD, 1);
            // dispatch(this.getAllCredential('', commonConst.PAGE_RECORD, 1, 'credDef', '', ''))

          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  saveCredentialDef(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/cred-def/create-cred-def-db`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(credDef => {
            dispatch({
              type: userConstants.CREATED_CREDDEF_DB,
              payload: credDef
            })
            dispatch({
              type: userConstants.CLEAR_SELECTED_SCHEMA
            })

            setTimeout(() => {
              dispatch({
                type: userConstants.SHOW_SUCCESS_CREDENTIAL_PAGE,
              })
            }, 2000);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            toastr.success(`${t("screens:issueCredentials.messageCreatedSuccessfully")}`, '');
            this.getAllCredentialByOrgId('', '', 'DESC', '', commonConst.PAGE_RECORD, 1);
            // dispatch(this.getAllCredential('', commonConst.PAGE_RECORD, 1, 'credDef', '', ''))
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectCredential(item: object, page: string) {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.SELECTED_CREDDEF,
        payload: { item, page }
      })
    }
  },

  showForm() {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.SHOW_CREDDEF_FROM
      })
      dispatch({
        type: userConstants.CLEAR_SELECTED_SCHEMA
      })
    }
  },


  revokeCredential(selectedReceivedCredData: any, selectedComment: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let finalData = {
          connection_id: selectedReceivedCredData.connectionIds,
          cred_ex_id: selectedReceivedCredData.credentialExchangeId,
          cred_rev_id: selectedReceivedCredData.revocationId,
          rev_reg_id: selectedReceivedCredData.revocRegId,
          thread_id: selectedReceivedCredData.threadId,
          comment: selectedComment
        }

        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/issue-credential/revoke`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(revodeCredentialData => {
            setTimeout(() => {
              dispatch({
                type: connectionConst.SELECTED_RECEIVED_CREDENTIAL,
                payload: revodeCredentialData
              })
            }, 2000);
            toastr.success(`${t("screens:issueCredentials.toastRevokeSuccessfully")}`, `${(selectedReceivedCredData.credentialDefinitionId).split(':')[4]}`);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  showCreateCredentialPage() {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.CREATE_CREDENTIAL_PAGE,
      })
    }
  },

  getAllInternalCredentialByOrgId(
    defaultSearchFields: any,
    internalCredDefSortBy: any,
    status: any,
    sortValue: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.get(`${config.apiUrl}/holder/internal-credentials?search_text=${defaultSearchFields}&sortBy=${internalCredDefSortBy}&status=${status}&sortValue=${sortValue}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async internalCredDefData => {
            let tempArray: any = [];
            tempArray = internalCredDefData.data.data.data;

            if (internalCredDefData.data.data.data.length >= 1) {
              internalCredDefData.data.data.data.map((element: any, idx: any) => {
                let issuerName: any = [];
                let issuerDept: any = [];
                let issuanceCount: any = [];
                if (element.responsiblevc.length >= 1) {
                  element.responsiblevc.map((vcele: any, index: any) => {
                    if (index < element.responsiblevc.length && issuerName.length != element.responsiblevc.length) {
                      issuerName.push({ name: vcele.holder.name })
                      issuerDept.push({ dept: vcele.holder.department })
                      issuanceCount.push({ issuanceCount: vcele.holder.issuance === null ? 0 : vcele.holder.issuance })

                      internalCredDefData.data.data.data[idx].issuerNames = issuerName;
                      internalCredDefData.data.data.data[idx].issuerDept = issuerDept;
                      internalCredDefData.data.data.data[idx].issuanceCount = issuanceCount;
                    }
                  })
                }
              })
            }

            dispatch({
              type: userConstants.GETALL_INTERNAL_CREDENTIALS,
              payload: internalCredDefData,
              tempArray
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectinternalCredDef(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.SELECT_INTERNAL_CREDENTIAL,
        payload: item
      })
    }
  },

  getHolderDetails(
    defaultSearchFields: any,
    credentialDefinitionId: any,
    holderSortBy: any,
    status: any,
    sortingOrder: any,
    item_perPage: any,
    pageNo: any,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.get(`${config.apiUrl}/holder/internal-credentials/holder-details/?search_text=${defaultSearchFields}&credentialDefinitionId=${credentialDefinitionId}&sortBy=${holderSortBy}&status=${status}&sortValue=${sortingOrder}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(holderDetailsList => {
            let tempArray: any = [];
            tempArray = holderDetailsList.data.data.data;
            if (!_.isEmpty(tempArray)) {
              tempArray.map((element: any, index: any) => {
                if (!_.isEmpty(element.connections) && !_.isEmpty(element.connections.holders)) {
                  tempArray[index].holderName = element.connections.holders.name;
                  tempArray[index].holderSource = element.connections.holders.role.name;
                  //Check for Proof
                  tempArray[index].holderProof = element.connections.holders.drivingLicenceKYCStatus === true ? `${t("screens:issueCredentials.drivingLicence")}` : element.connections.holders.passportKYCStatus === true ? `${t("screens:issueCredentials.passport")}`  : 'N/A';
                  tempArray[index].status = element.isRevoked ? <p className="text-danger">{t("screens:issueCredentials.revoked")}</p> : <p className="text-success">{t("screens:issueCredentials.active")}</p>;
                }
              })
            }
            dispatch({
              type: userConstants.GETALL_HOLDER_DETAILS,
              payload: holderDetailsList,
              tempArray
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  tableScroll(selectedRecord: any, index: any) {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.TABLE_SCROLL_DATA,
        payload: { selectedRecord, index }
      })
    }
  },

  clearHolderDetails() {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.CLEAR_HOLDER_DETAIL_LIST,
      })
    }
  },

  getCredentialDefinitionsByPairwiseDid(pairwiseDid: string, credDefTypes: CredDefTypes) {
       
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.get(`${config.apiUrl}/holder/credential-definition/organization/${pairwiseDid}?credType=${credDefTypes}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(credentialDefinitionResponse => {
            
            dispatch({
              type: issuanceConst.GET_ALL_CRED_DEF_BY_PAIRWISE_DID,
              payload: credentialDefinitionResponse,              
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

}
