import * as React from 'react';
import RegistrationLayout from './RegistrationLayout';

export interface IRequestFailedProps {
}

export interface IRequestFailedState {
}

export default class RequestFailed extends React.Component<IRequestFailedProps, IRequestFailedState> {
  constructor(props: IRequestFailedProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        <RegistrationLayout>
          Request Rejected.
          {/* <a href="/login" className="btn btn-link">Back to Login</a> */}
          <a href="/" className="btn btn-link">Back to Login</a>
        </RegistrationLayout>
      </div>
    );
  }
}
