import _ from 'lodash';
import * as React from 'react';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ISuccessPageProps {
  CredentialProposalAction: any,
  CredentialProposalReducer: any

}

export interface ISuccessPageState {
}

export default class SuccessPage extends React.Component<ISuccessPageProps, ISuccessPageState> {
  constructor(props: ISuccessPageProps) {
    super(props);

    this.state = {
    }
  }

  createAnotherProposal(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    this.props.CredentialProposalAction.requestCredentialComponent();
  }

  public render() {
    let { proposedCredential,connectionName } = this.props.CredentialProposalReducer;
    let credName = !_.isEmpty(proposedCredential) && proposedCredential?.data?.data?.credential_proposal_dict.cred_def_id?.split(':')[4];

    return (
      <>
        <div className="card h-100">
          <div className="card-body text-center">
            <div className="row">
              <div className="col-sm-12 mb-5 mt-5">
                <img src={images.illustrationSuccess} alt="Success" />
              </div>
              <div className="col-sm-12">
                <h3 className="nb-label">{t("screens:credentialProposal.messageProposal1")} <b>{credName}</b> {t("screens:credentialProposal.messageProposal2")} 
                <b>{connectionName}</b> {t("screens:credentialProposal.messageProposal3")}</h3>
              </div>
              <div className="col-sm-12">
                <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                  onClick={(e) => this.createAnotherProposal(e)}
                >
                  {t("screens:credentialProposal.createAnotherProposal")}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
