import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { loginAction } from '../actions/loginAction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { version, config } from '../../../config';
// import TermsAndConditions from './TermsandCondition';
import { history } from '../../../index';
import { toastr } from 'react-redux-toastr';
// Components
import HelpGuide from 'src/Common Components/Components/HelpGuide';
import { socket, SocketContext } from 'src/contexts/SocketContext';

/*Interface for props values */
export interface ILoginFormProps extends WithTranslation<["common", "screens"]> {
  loggedInUser?: any | undefined,
  loginAction: any,
  user: any,
  LoginReducer: any,
  switchToScanQR: any
}

/*Interface for local state values */
export interface ILoginFormState {
  formData: any,
  errors: any,
  // showTerms: boolean,
  // acceptTerms: boolean,
  pushNotificationToken: string
}

export default class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
  static contextType?= SocketContext

  constructor(props: ILoginFormProps) {
    super(props);

    /* Initialization of local state variables. */
    this.state = {
      formData: {},
      errors: {},
      // showTerms: false,
      // acceptTerms: false,
      pushNotificationToken: ''
    }
    /* Bind all the methods. */
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    // this.termsChange = this.termsChange.bind(this);
    // this.closeModal = this.closeModal.bind(this);
    // this.acceptTerms = this.acceptTerms.bind(this);
  }

  async componentDidMount() {

    await this.connectSocket()

    // console.log('*** Login Form componentDidMount - After connectSocket() ')
  }

  async connectSocket() {
    // console.log(`*** LoginForm (before !socket.connected) ----  socket.id: ${socket.id}  socket.connected: ${socket.connected}`)
    if (!socket.connected) {
      socket.connect()
      // console.log(`LoginForm (inside !socket.connected) ---- SOCKET  NEW Connection estalished (io) ${socket.id} ${socket.connected}`)
    }
    // console.log(`*** Login Form ---- SOCKET Connection estalished (io) ${socket.id} ${socket.connected}`)

    if (!socket.id) {
      socket.on('connect', async () => {
        // console.log(`*** Login Form   (inside 'on.connect') NEW socket.id ${socket.id} ${socket.connected} `)
        await this.loginSocket()
      })
    } else {
      await this.loginSocket()
    }

    // socket.on('notification', (socketId, payload) => {
    //   console.log(`Login Form Listen from BE - notification - socketId: ${socketId}  ${JSON.stringify(payload)}`);
    //   if (payload && payload.data) {
    //     if (payload.data.type === 'Notification') {
    //       console.log(`SOCKET receive Notification`)
    //     }
    //   }
    // })

    //     // SOCKET - Unregister Events
    //     // console.log('Unregistering Events....')
    //     // socket.off('connect')
    //     // socket.off('loginpassword')
    //     // socket.off('notification')

    // socket.on('disconnect', async () => {
    //   console.log(`*** Login Form  - Socket Disconnected ${socket.id}`);
    // })
    // })
  }

  async loginSocket() {
    // console.log(`*** LoginForm  -  loginSocket ${socket.id} ${socket.connected} `);
    this.setState({ pushNotificationToken: socket.id });
    this.props.loginAction.getCurrentUser();
    // console.log(`*** Login Form ---- after getCurrentUser() ${this.state.pushNotificationToken}`)

    socket.on('loginpassword', (socketId, payload) => {
      // console.log(`loginpassword - ON  => ${JSON.stringify(payload)} socketId: ${socketId}`)

      if (payload && payload.data) {
        if (payload.data.type === "LOGIN") {
          const userRecord = JSON.parse(payload.data.record);
          const loginPayload = userRecord.userDetails;
          const token = userRecord.accessToken;
          /* Method call to set current user in redux-store. */
          this.props.loginAction.setCurrentUser(loginPayload);
          // console.log(`loginPayload  => ${JSON.stringify(loginPayload)}`)
        }
      }
    })
  }
  /**
   * Method used for getting the login form values and set it in state variable.
   * @param event 
   */
  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let formData = this.state.formData;
    formData[event.target.name] = event.target.value;
    formData['socketId'] = this.state.pushNotificationToken
    this.setState({
      formData: formData
    });

    // console.log(`handleChang formData => ${JSON.stringify(formData)}`)
    /* Method call for validate the typed value in login form */
    this.validateForm();
  }

  /**
   * Method used to accept term and condition of platform.
   * @param event 
   */
  // termsChange(event: React.ChangeEvent<HTMLInputElement> | any) {
  //   let fields = this.state.formData;
  //   fields["terms"] = event.currentTarget.value;
  //   this.setState({
  //     formData: fields,
  //     showTerms: true,
  //   });
  // }

  /**
   * Method used for calling the login action
   * @param event 
   */

  login(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { formData } = this.state;
    this.props.loginAction.login(formData)
  }

  /**
   * Method used to validate the form field  
   * @returns Validate the form field and return the error message or value
   */
  validateForm() {
    let formData: any = this.state.formData;
    let errors: any = {};
    let formIsValid: any = true;

    if (!formData["fullName"] && typeof formData["fullName"] !== "undefined") {
      formIsValid = false;
      errors["fullName"] = "*Please enter Full Name only.";
    }

    if (typeof formData["emailAddress"] !== "undefined") {
      //regular expression for emailAddress validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(formData["emailAddress"])) {
        formIsValid = false;
        errors["emailAddress"] = "*Please enter valid email-ID.";
      }
    }

    if (!formData["initialPassword"] && typeof formData["initialPassword"] !== "undefined") {
      formIsValid = false;
      errors["initialPassword"] = "*Please enter Initial Password only.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  /**
   * Method used for closing the terms and condition modal
   */
  // closeModal() {
  //   this.setState({
  //     showTerms: false,
  //     acceptTerms: false,
  //   })
  // }

  /**
   * Method used to accept terms and condition
   */
  // acceptTerms() {
  //   this.setState({
  //     showTerms: false,
  //     acceptTerms: true,
  //   })
  // }

  public render() {

    const { t } = this.props

    const { loggedInUser } = this.props.LoginReducer
    const { userDetails } = loggedInUser
    const { formData, errors } = this.state
    const { switchToScanQR } = this.props

    if (!_.isEmpty(userDetails) && localStorage.length > 0) {
      if (userDetails.role.id !== 1 && userDetails.organization.agentSpinupStatus === 0 && userDetails.organization.isOnBoarded === 1) {
        return (<>{history.push('/pending-state')}</>)
      } else if (userDetails.role.id !== 1 && userDetails.organization.agentSpinupStatus === 0 && userDetails.organization.isOnBoarded === 0) {
        // toastr.message(`Complete Your Registration Process`, `Please go to first time login`)
        toastr.message(`${t("screens:login.toastMsgRegistration1")}`, `${t("screens:login.toastMsgRegistration2")}`)
      } else {
        if (userDetails.role.id === 1) {
          return (<>{history.push('/platformAdmin-dashboard')}</>)
        } else if (userDetails.role.id === 2) {
          if (userDetails.organization.isOnBoarded === 2 && userDetails.organization.agentSpinupStatus === 1 && userDetails.organization.isDashboard === false) {
            return (<>{history.push('/create-wallet')}</>)
          } else
            if (userDetails.organization.isOnBoarded === 2 && userDetails.organization.agentSpinupStatus === 0 && userDetails.organization.isDashboard === false) {
              return (<>{history.push('/create-wallet')}</>)
            } else if (userDetails.organization.agentSpinupStatus === 2 && userDetails.organization.isDashboard === false) {
              return (<>{history.push('/create-wallet')}</>)
              // history.push('/dashboard')
            } else if (userDetails.organization.agentSpinupStatus === 2 && userDetails.organization.isDashboard === true) {
              if (!_.isEmpty(userDetails.organization.subscription) && userDetails.organization.subscription.id === 1) {
                return (<>{history.push('/orgAdmin-dashboard')}</>)
              } else if (!_.isEmpty(userDetails.organization.subscription) && userDetails.organization.subscription.id === 2) {
                return (<>{history.push('/orgAdmin-dashboard')}</>)
              } else if (!_.isEmpty(userDetails.organization.subscription) && userDetails.organization.subscription.id === 3) {
                return (<>{history.push('/orgAdmin-dashboard')}</>)
              }
            } else if (userDetails.organization.agentSpinupStatus === 0 && userDetails.organization.isDashboard === false && userDetails.organization.isOnBoarded === 3) {
              return (<>{history.push('/request-failed')}</>)
            }
        } else if (userDetails.role.id === 3 && userDetails.isActive) {
          return (<>{history.push('/issuer-dashboard')}</>)
        } else if (userDetails.role.id === 4 && userDetails.isActive) {
          return (<>{history.push('/verifier-dashboard')}</>)
        } else if (userDetails.role.id === 5 && userDetails.isActive) {
          return (<>{history.push('/both-dashboard')}</>)
        } else {
          // toastr.info(`Currently, you are not activated for a login`, ``);
          toastr.info(`${t("screens:login.toastMsgNotActivated")}`, ``);
        }
      }
    } else {
      return (
        <div>
          <div className="text-center">
            <HelpGuide buttonClassName="mb-5" contentKey="help:login.titleHelp" persistent>
              <h1 className="mb-5 nb-title-700">{t("screens:login.title", { companyName: t("common:app.companyNameFull") })}</h1>
            </HelpGuide>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="nb-label">{t("screens:login.loginNameLabel")}</label>
                <input type="text" className="form-control" name="fullName" value={formData.fullName ? formData.fullName : ""}
                  onChange={(e) => this.handleChange(e)} />
                <div className="text-danger">{errors.fullName}</div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="nb-label">{t("screens:login.loginEmailLabel")}</label>
                <input type="text" className="form-control" name="emailAddress" value={formData.emailAddress ? formData.emailAddress : ""}
                  onChange={(e) => this.handleChange(e)} />
                <div className="text-danger">{errors.emailAddress}</div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="nb-label">{t("screens:login.loginPasswordLabel")}</label>
                <HelpGuide>
                  <input type="text" className="form-control" name="initialPassword" value={formData.initialPassword ? formData.initialPassword : ""}
                    onChange={(e) => this.handleChange(e)} />
                </HelpGuide>
                <div className="text-danger">{errors.initialPassword}</div>
              </div>
            </div>
            {/* <div className="col-sm-12">
              <div className="form-group">
                <HelpGuide inline>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="customCheck1" name="terms" value={"terms"} checked={acceptTerms ? true : false}
                      onChange={(e) => this.termsChange(e)}
                    />
                    <label className="custom-control-label nb-label mb-0" htmlFor="customCheck1">
                      {t("screens:login.loginTermsLabel")}
                    </label>
                  </div>
                </HelpGuide>
              </div>
            </div> */}
            <div className="col-sm-12">
              <div className="form-group">
                <HelpGuide>
                  <button type="button" className="btn btn-nb-primary btn-lg btn-block"
                    // disabled={!_.isEmpty(errors) || !formData.fullName || !formData.emailAddress || !formData.initialPassword || !acceptTerms}
                    disabled={!_.isEmpty(errors) || !formData.fullName || !formData.emailAddress || !formData.initialPassword}
                    onClick={this.login}
                  >
                    <i className="fas fa-fingerprint" />&nbsp;
                    {t("screens:login.loginButton")}
                  </button>
                </HelpGuide>
              </div>
            </div>
            {/*  // split screen
             <div className="col-sm-12">
              <HelpGuide contentKey="help:login.existingLoginPrompt" inline>
                <button type="button" className="nb-link" onClick={switchToScanQR}>
                  {t("screens:login.loginExistingPrompt")}
                </button>
              </HelpGuide>
            </div> */}
            <div className="col-sm-12">
              <HelpGuide contentKey="help:login.existingLoginPrompt" inline>
                <button type="button" className="nb-link" onClick={switchToScanQR}>
                  {t("screens:login.loginExistingPrompt")}
                </button>
              </HelpGuide>
            </div>
            <div className="col-sm-12">
              <small className="text-muted">{t("common:app.version", { version: version.version })}</small>
            </div>
          </div>
          {/* Terms and condition modal component  */}
          {/* <TermsAndConditions isOpen={this.state.showTerms} closeModal={this.closeModal} acceptTerms={this.acceptTerms} /> */}
        </div>

      );
    }
  }
}

function mapStateToProps(state: any) {
  const { loggingIn, loggedInUser, presentProofRequest } = state.LoginReducer;
  return { loggingIn, presentProofRequest, loggedInUser }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
  }
}

const connectedLoginForm = withTranslation(['common', "screens"])(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
export { connectedLoginForm as LoginForm };
