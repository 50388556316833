import { loaderConst } from "../../types";
const initialState = {
  loader: false,
  connectionListLoader: false,
  credentialsListLoader: false,
  credAvailableLoader: false,
}

export function LoaderReducer(state = initialState, action: any) {
  switch (action.type) {
    case loaderConst.LOADER_TRUE:
      return Object.assign({},
        state,
        {
          loader: true
        });
    case loaderConst.LOADER_FALSE:
      return Object.assign({},
        state,
        {
          loader: false
        });
    case loaderConst.CONNECTION_LIST_LOADER_TRUE:
      return Object.assign({},
        state, {
        connectionListLoader: true
      })
    case loaderConst.CONNECTION_LIST_LOADER_FALSE:
      return Object.assign({},
        state, {
        connectionListLoader: false
      })
    case loaderConst.CREDENTIAL_LIST_LOADER_TRUE:
      return Object.assign({},
        state, {
        credentialsListLoader: true
      })
    case loaderConst.CREDENTIAL_LIST_LOADER_FALSE:
      return Object.assign({},
        state, {
        credentialsListLoader: false
      })
    case loaderConst.CRED_AVAILABLE_LIST_LOADER_TRUE:
      return Object.assign({},
        state, {
        credAvailableLoader: true
      })
    case loaderConst.CRED_AVAILABLE_LIST_LOADER_FALSE:
      return Object.assign({},
        state, {
        credAvailableLoader: false
      })
    default:
      return state
  }
}

