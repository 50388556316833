import React from "react";
import { connect } from "react-redux";
import { presentProofRequest } from "../../commonConst";
import { ProofRequestDetails } from "../components/ProofRequestDetails";
import { ProofRequestList } from "../components/ProofRequestList";
import _ from 'lodash';
import { GenerateProof } from "../components/GenerateProofRequest";
import { SendProofSuccess } from "../components/SendProofSuccess";
import { bindActionCreators } from "redux";
import { presentProofAction } from "../action/presentProofAction";
import { StoredCredentialList } from "../components/StoredCredentialList";
import { t } from 'i18next';
import ProofDeclineSent from "../components/ProofDeclineSent";

/* Interface for Props variables*/
interface IPresentProofProps {
    PresentProofReducer: any,
    presentProofAction: any
}

/* Interface for local states variables*/
interface IPresentProofState {
    showProofDetails: boolean,
    selectedProofDetails: any,
    selectedConnectionName: string,
    requestedAttributesDetails: any[],
    requestedPredicatesDetails: any[],
    page: number,
    itemPerPage: number,
    searchText: string,
    showAttributeValues: boolean
    isDefault: boolean,
    presentationExchangeId: string,
    selectedCredentials: any[],
    credentialsToReveal: any[],
    connectionDetails: any
}

interface AttributeDetails {
    name: string,
    referentName: string,
    reveal?: boolean
    value?: string,
}

class PresentProof extends React.Component<IPresentProofProps, IPresentProofState> {
    constructor(props: IPresentProofProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            showProofDetails: false,
            selectedProofDetails: {},
            selectedConnectionName: "",
            requestedAttributesDetails: [],
            page: 1,
            itemPerPage: 10,
            searchText: "",
            showAttributeValues: true,
            isDefault: true,
            presentationExchangeId: '',
            requestedPredicatesDetails: [],
            selectedCredentials: [],
            credentialsToReveal: [],
            connectionDetails: {}
        }
        /* Binding the method */
        this.onShowProofDetails = this.onShowProofDetails.bind(this)
        this.onClose = this.onClose.bind(this)
        this.getAllRequiredCredentials = this.getAllRequiredCredentials.bind(this)
        this.selectedCredentialRecord = this.selectedCredentialRecord.bind(this)
        this.updateSelectedCredentials = this.updateSelectedCredentials.bind(this)
        this.onChangeCredentialSelection = this.onChangeCredentialSelection.bind(this)
        /*  // SELECTIVE DISCLOSURE removed till ACA-Py issue gets resolved */
        //  this.onChangeRevealAttributes = this.onChangeRevealAttributes.bind(this) 
        this.onChangeSelfAttributeValue = this.onChangeSelfAttributeValue.bind(this)
    }

    /* Method to call the get presentation requests method */
    componentDidMount() {
        const { page, itemPerPage, searchText } = this.state
        this.getAllPresentationRequests(page, itemPerPage, searchText)
    }

    /* Method to get presentation records*/
    getAllPresentationRequests(page: number, itemPerPage: number, searchText: string) {
        this.props.presentProofAction.getAllPresentProofRequest(page, itemPerPage, searchText);
    }

    /* Method to show the selected proof details. */
    onShowProofDetails(item: any) {
        const restrictionsArray: Array<any> = [];
        const requestedAttributesDetails: Array<any> = [];
        const requestedPredicatesDetails: Array<any> = [];
        const requestDetails: any = item && JSON.parse(item.presentationRequest);

        /* pseudo code for get all credential definition in one array from request details*/
        if (item) {

            for (const attributeData in requestDetails?.requested_attributes) {
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_attributes, attributeData)) {
                    const element = requestDetails.requested_attributes[attributeData];

                    // check if requested attribute is self attested or not
                    if (element.restrictions && element.restrictions.length) {
                        // not a self attested attribute                        
                        element.restrictions.map((restrictionData: any) => {
                            // check for duplicate restrictions
                            if (!restrictionsArray.find((x) => JSON.stringify(x) === JSON.stringify(restrictionData))) {
                                restrictionsArray.push(restrictionData)
                            }
                        })
                    }
                    else {
                        // self attested attribute assign dummy restriction
                        if (!restrictionsArray.find((x) => JSON.stringify(x) === JSON.stringify({ cred_def_id: '' })))
                            restrictionsArray.push({ cred_def_id: '' })
                    }
                }
            }

            for (const predicate in requestDetails.requested_predicates) {
                let predicateData: any = {}
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_predicates, predicate)) {
                    const { restrictions, ...otherPredicateData } = requestDetails.requested_predicates[predicate];
                    predicateData = { ...otherPredicateData }
                    const proofRequestPredicates: any = {}

                    restrictions.map((restrictionData: any) => {
                        proofRequestPredicates['credentialName'] = Object.keys(restrictionData)[0] === 'cred_def_id' ?
                            (Object.values(restrictionData)[0] as string).split(':')[4] :
                            (Object.values(restrictionData)[0] as string).split(':')[2]
                        proofRequestPredicates['referentName'] = predicate
                        proofRequestPredicates[Object.keys(restrictionData)[0]] = Object.values(restrictionData)[0]
                        proofRequestPredicates['credentialFields'] = predicateData
                    })
                    requestedPredicatesDetails.push(proofRequestPredicates)
                }
            }

            this.setState({ selectedConnectionName: item.connectionName, connectionDetails: item.connection })
        }

        /* pseudo code for display the attribute names as well as display the credential name*/
        for (const key of restrictionsArray) {
            const proofRequestAttributes: any = {}
            proofRequestAttributes[`${Object.keys(key)}`] = Object.values(key)[0]
            proofRequestAttributes['credentialId'] = ""
            proofRequestAttributes['presentationExchangeId'] = item.presentationExchangeId

            const attributes: AttributeDetails[] = []

            for (const attributeData in requestDetails.requested_attributes) {
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_attributes, attributeData)) {
                    const element = requestDetails.requested_attributes[attributeData];

                    // eslint-disable-next-line no-loop-func
                    if (element.restrictions && element.restrictions.length) {
                        element.restrictions.map((restrictionData: any) => {

                            if (Object.values(restrictionData)[0] === Object.values(key)[0]) {
                                attributes.push({
                                    name: element.name,
                                    referentName: attributeData,
                                    reveal: true,
                                })
                            }
                        })
                    }
                    else {
                        if (Object.values(key)[0] === '')
                            attributes.push({
                                name: element.name,
                                referentName: attributeData,
                            })
                    }
                }
            }

            proofRequestAttributes['credentialType'] = Object.keys(key)[0] === 'cred_def_id' ? 'Credential Definition' : 'Schema'
            proofRequestAttributes['credentialName'] = Object.keys(key)[0] === 'cred_def_id' ?
                (Object.values(key)[0] as string).split(':')[4] :
                (Object.values(key)[0] as string).split(':')[2]

            proofRequestAttributes['credentialFields'] = (typeof attributes) === 'string' ? [attributes] : attributes

            requestedAttributesDetails.push(proofRequestAttributes);
        }

        this.setState({ requestedAttributesDetails, requestedPredicatesDetails })
        this.getAllRequiredCredentials(item)
    }

    /* Method to get all required credential list */
    getAllRequiredCredentials(presentationRequest: any) {

        if (presentationRequest) {
            this.setState({ presentationExchangeId: presentationRequest.presentationExchangeId })
            this.props.presentProofAction.fetchCredentialsForPresentationRequest(presentationRequest.presentationExchangeId);
        }
    }

    updateSelectedCredentials(credentialsForPresentation: any) {

        const uniqueCredential = new Set()
        const setCredentials: any = []

        credentialsForPresentation.map((credential: any, credentialIndex: number) => {
            if (!uniqueCredential.has(credential.cred_info.schema_id)) {
                uniqueCredential.add(credential.cred_info.schema_id)
                setCredentials.push(credentialsForPresentation[credentialIndex])
            }
        })

        this.setState({
            selectedCredentials: setCredentials,
            credentialsToReveal: setCredentials
        })
        this.selectedCredentialRecord(setCredentials)
    }

    /* Method to display the selected credential records */
    selectedCredentialRecord(credentials: any) {
        const { requestedAttributesDetails, requestedPredicatesDetails } = this.state
        let requestedAttributes: any = requestedAttributesDetails
        let requestedPredicates: any = requestedPredicatesDetails

        if (!_.isEmpty(credentials)) {
            requestedAttributes.map((element: any) => {

                let hasReferentInFetchedCredential = false
                if (element.hasOwnProperty('cred_def_id')) {
                    const credentialIndex = credentials.indexOf(credentials.find((x: any) => x.cred_info.cred_def_id === element.cred_def_id))

                    if (credentialIndex > -1 && credentials[credentialIndex].hasOwnProperty('presentation_referents')) {
                        element.credentialFields.map((field: AttributeDetails) => {

                            hasReferentInFetchedCredential = credentials[credentialIndex].presentation_referents.includes(field.referentName) ? true : false
                            field.value = credentials[credentialIndex] ?
                                credentials[credentialIndex].cred_info.attrs[field.name] : ''
                        })

                        if (hasReferentInFetchedCredential) {
                            element['credentialId'] = credentials[credentialIndex] ?
                                credentials[credentialIndex].cred_info.referent : ''
                        }
                    }
                }
                else {
                    const credentialIndex = credentials.indexOf(credentials.find((x: any) => x.cred_info.schema_id === element.schema_id))

                    if (credentialIndex > -1 && credentials[credentialIndex].hasOwnProperty('presentation_referents')) {
                        element.credentialFields.map((field: AttributeDetails) => {
                            hasReferentInFetchedCredential = credentials[credentialIndex].presentation_referents.includes(field.referentName) ? true : false

                            field.value = credentials[credentialIndex] ?
                                credentials[credentialIndex].cred_info.attrs[field.name] : ''
                        })

                        if (hasReferentInFetchedCredential) {
                            element['credentialId'] = credentials[credentialIndex] ?
                                credentials[credentialIndex].cred_info.referent : ''
                        }
                    }
                }
            })

            requestedPredicates.map((element: any) => {
                if (element.hasOwnProperty('cred_def_id')) {
                    const credentialIndex = credentials.indexOf(credentials.find((x: any) => x.cred_info.cred_def_id === element.cred_def_id))

                    if (credentialIndex > -1 && credentials[credentialIndex].hasOwnProperty('presentation_referents')) {

                        if (credentials[credentialIndex].presentation_referents.includes(element.referentName)) {
                            element['credentialId'] = credentials[credentialIndex] ?
                                credentials[credentialIndex].cred_info.referent : ''
                        }
                    }
                }
                else {
                    const credentialIndex = credentials.indexOf(credentials.find((x: any) => x.cred_info.schema_id === element.schema_id))

                    if (credentialIndex > -1 && credentials[credentialIndex].hasOwnProperty('presentation_referents')) {
                        if (credentials[credentialIndex].presentation_referents.includes(element.referentName)) {
                            element['credentialId'] = credentials[credentialIndex] ?
                                credentials[credentialIndex].cred_info.referent : ''
                        }
                    }
                }
            })

            this.setState({
                selectedCredentials: [...credentials],
                requestedAttributesDetails: [...requestedAttributes],
            })
        }
    }

    /* 
        SELECTIVE DISCLOSURE removed till ACA-Py issue gets resolved
    */

    // onChangeRevealAttributes(event: any) {
    //     const selectedCredentialReferent = event.target.name.split('--')[0]
    //     const selectedAttributeIndex = event.target.name.split('--')[1]
    //     const selectedAttributeKey = event.target.name.split('--')[2]

    //     const { requestedAttributesDetails } = this.state
    //     const presentingAttributes = [...requestedAttributesDetails]
    //     const credentialFieldIndex = presentingAttributes[selectedAttributeIndex].credentialFields.indexOf(presentingAttributes[selectedAttributeIndex].credentialFields.find((x: any) => x.name === selectedAttributeKey))


    //     presentingAttributes[selectedAttributeIndex].credentialFields[credentialFieldIndex].reveal =
    //         presentingAttributes[selectedAttributeIndex].credentialFields[credentialFieldIndex].reveal ? false : true

    //     this.setState({ credentialsToReveal: [...presentingAttributes] })
    // }

    /* Method to closed the success message*/
    onClose() {
        const { presentProofRequestList } = this.props.PresentProofReducer
        if (presentProofRequestList.length > 0) {
            this.onShowProofDetails(presentProofRequestList[0])
        } else {
            this.props.presentProofAction.onCloseSuccess()
            this.setState({ requestedAttributesDetails: [] })
        }
    }

    onChangeCredentialSelection(event: any) {
        const selectedCredentialIndex = event.target.name.split('-')[1]
        const { credentialsForPresentationRequest: storedCredentials } = this.props.PresentProofReducer
        const { selectedCredentials: updateSelectedCredentials } = this.state

        const checkedReferent = storedCredentials[selectedCredentialIndex].cred_info.referent
        const checkedCredential = storedCredentials[selectedCredentialIndex].cred_info.cred_def_id

        const duplicateReferent = updateSelectedCredentials.find((x: any) => x?.cred_info?.referent === checkedReferent)
        const duplicateCredential = updateSelectedCredentials.find((x: any) => x?.cred_info?.cred_def_id === checkedCredential)

        if (duplicateReferent) {
            const index = updateSelectedCredentials.indexOf(duplicateReferent)
            updateSelectedCredentials.splice(index, 1)
        }
        else {
            if (duplicateCredential) {
                const index = updateSelectedCredentials.indexOf(duplicateCredential)
                updateSelectedCredentials.splice(index, 1)
            }
            updateSelectedCredentials.push(storedCredentials[selectedCredentialIndex])
        }

        this.selectedCredentialRecord(updateSelectedCredentials)
    }

    onChangeSelfAttributeValue(event: React.ChangeEvent<HTMLInputElement> | any) {
        event.preventDefault();
        const requestedAttributesDetails = this.state.requestedAttributesDetails

        const credentialIndex = event.target.name.split('-')[0]
        const attributeIndex = event.target.name.split('-')[1]

        requestedAttributesDetails[credentialIndex].credentialFields[attributeIndex].value = event.target.value

        this.setState({
            requestedAttributesDetails: [...requestedAttributesDetails],
        })
    }

    /* UI to display the all the child component */
    public render() {
        const { requestedAttributesDetails, requestedPredicatesDetails, selectedConnectionName, showAttributeValues, presentationExchangeId,
            selectedCredentials, credentialsToReveal, connectionDetails } = this.state
        const { sendProofSuccess, presentProofRequestList, credentialsForPresentationRequest, isDeclineSent } = this.props.PresentProofReducer

        const attributesExists = requestedAttributesDetails.some((o: any) => o.hasOwnProperty('credentialId'))
        const predicateExists = !_.isEmpty(requestedPredicatesDetails) ? requestedPredicatesDetails.some((o: any) => o.hasOwnProperty('credentialId')) : true

        return (
            <>
                <div className="row gutters-sm min-h-100 animated fadeIn">
                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:presentProof.presentProofList")}</h1>
                            </div>
                            <div className="col-sm-12">
                                {/* Component for display the proof request list */}
                                {presentProofRequestList.length > 0 &&
                                    <ProofRequestList
                                        onShowProofDetails={this.onShowProofDetails}
                                        presentProofRequestList={presentProofRequestList}
                                        getAllPresentationRequests={this.getAllPresentationRequests}
                                    />
                                }
                                {presentProofRequestList.length === 0 &&
                                    <ProofRequestList
                                        onShowProofDetails={this.onShowProofDetails}
                                        presentProofRequestList={presentProofRequestList}
                                        getAllPresentationRequests={this.getAllPresentationRequests}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            {!sendProofSuccess && !isDeclineSent &&
                                <>
                                    <div className="card-header bg-white border-bottom-0">
                                        <h1 className="nb-title">{t("screens:presentProof.requestDetails")}</h1>
                                    </div>
                                    <div className="card-body">
                                        {!_.isEmpty(requestedAttributesDetails) || !_.isEmpty(requestedPredicatesDetails) ?
                                            <>
                                                {/* Component for showing the request details  */}
                                                <ProofRequestDetails
                                                    // key={presentationExchangeId}
                                                    selectedProofDetails={{ requestedAttributesDetails, requestedPredicatesDetails }}
                                                    selectedConnectionName={selectedConnectionName}
                                                />
                                                {/* Component for showing the stored credential list */}
                                                {
                                                    <StoredCredentialList
                                                        // key={credentialsForPresentationRequest}
                                                        storedCredentials={credentialsForPresentationRequest}
                                                        updateSelectedCredentials={this.updateSelectedCredentials}
                                                        selectedCredentialRecord={this.selectedCredentialRecord}
                                                        selectedCredentials={selectedCredentials}
                                                        onChangeCredentialSelection={this.onChangeCredentialSelection}
                                                    />
                                                }
                                                {/* Component for showing the selected credential attribute values  */}
                                                {showAttributeValues && !_.isEmpty(selectedCredentials) &&
                                                    (!attributesExists || !predicateExists) ?
                                                    <>
                                                        <div className="blank-credential-details-body mt-3">
                                                            <label className="">{t("screens:presentProof.noCredentialProof")}</label>
                                                        </div>
                                                    </> :
                                                    <GenerateProof
                                                        // key={presentationExchangeId}
                                                        connectionDetails={connectionDetails}
                                                        attributeValues={requestedAttributesDetails}
                                                        predicateValues={requestedPredicatesDetails}
                                                        credentialsToReveal={credentialsToReveal}
                                                        presentationExchangeId={presentationExchangeId}
                                                        /*  SELECTIVE DISCLOSURE removed till ACA-Py issue gets resolved */
                                                        //  onChangeRevealAttributes={this.onChangeRevealAttributes} 
                                                        onChangeSelfAttributeValue={this.onChangeSelfAttributeValue}
                                                    />
                                                }
                                                {_.isEmpty(credentialsForPresentationRequest) &&
                                                    <>
                                                        <div className="card-body">
                                                            <div className="blank-credential-details-body">
                                                                <label>{t("screens:presentProof.noCredentialAvailable")}</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                            : null}
                                        {_.isEmpty(requestedAttributesDetails) && _.isEmpty(requestedPredicatesDetails) &&
                                            <>
                                                <div className="blank-credential-details-body">
                                                    <label className="">{t("screens:presentProof.noRequestReceived")}</label>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                            }
                            {sendProofSuccess &&
                                <>
                                    <div className="card-header bg-white border-bottom-0">
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <h1 className="nb-title">{t("screens:presentProof.requestDetails")}</h1>
                                            </div>
                                            <div className="col-sm-2 text-right">
                                                <button type="button" className="btn"
                                                    onClick={this.onClose}
                                                >
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <SendProofSuccess />
                                    </div>
                                </>
                            }
                            {
                                isDeclineSent && <ProofDeclineSent onClickHandler={this.onClose}></ProofDeclineSent>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentProofReducer = state.PresentProofReducer;

    return { PresentProofReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentProofAction: bindActionCreators(presentProofAction, dispatch),

    }
}

const presentProof = connect(mapStateToProps, mapDispatchToProps)(PresentProof);
export { presentProof as PresentProof };
