import React from 'react'
import '../../../Common Components/DefaultLayout/RegistrationLayout.css';

// Utilities
import images from "src/utils/images";

export interface IRegistrationLayoutProps {
  status?: any,
}

export interface IRegistrationLayoutState {
}

export default class RegistrationLayout extends React.Component<IRegistrationLayoutProps, IRegistrationLayoutState> {
  constructor(props: IRegistrationLayoutProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="container-fluid position-absolute" style={{ bottom: '0', top: '0', width: '100%' }}>
        <div className="row d-flex h-100 align-items-stretch">
          <div className="col-md-4 col-lg-3 p-0 drawer login-bg position-fixed h-100">
            <div>
              <div className="p-3 mb-3">
                <img alt="Company logo" src={images.companyLogo} style={{ width: '200px' }} />
              </div>
              <ul className="nav flex-column nav-verticle-steps timeline-nav">
                <li className="nav-item">
                  <span className="nav-count">1</span>
                  {/* <NavLink to="/register" exact className="nav-link" activeClassName="active" aria-disabled> */}
                  <span className="nav-title">REGISTRATION</span>
                  {/* </NavLink> */}
                </li>
                <li className="nav-item">
                  <span className="nav-count">2</span>
                  {/* <NavLink to="/create-wallet" exact className="nav-link" activeClassName="active" aria-disabled> */}
                  <span className="nav-title">CREATE WALLET</span>
                  {/* </NavLink> */}
                </li>
                <li className="nav-item">
                  <span className="nav-count">3</span>
                  {/* <NavLink to="/set-DID-Public" exact className="nav-link" activeClassName="active" aria-disableds> */}
                  <span className="nav-title">PUBLIC DID</span>
                  {/* </NavLink> */}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8 col-lg-9 offset-lg-3">
            <div className="row">
              <div className="col-sm-12 p-5">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
