export function displayDateFormat(inputDate: string) {
  const numberRegex = /^\d+$/;
  if(numberRegex.test(inputDate)){
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);
    return `${day}/${month}/${year}`;
  }
  return inputDate;
}

