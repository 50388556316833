import _ from "lodash";
import { notificationConstants } from "../../types";
import { tHeadNotification } from "../constants";

const initialState = {
  notificationList: [],
  totalNotifications: 0,
  tHeadNotification: [],
  totalUnreadNotifications: 0
}

export function NotificationReducer(state = initialState, action: any) {

  switch (action.type) {

    case notificationConstants.GET_ALL_NOTIFICATIONS:
      return Object.assign({},
        state,
        {
          notificationList: action.payload.data,
          tHeadNotification: tHeadNotification,
          totalNotifications: action.payload.totalItems
        });

    case notificationConstants.GET_COUNT_NOTIFICATIONS:
      return Object.assign({},
        state,
        {
          totalUnreadNotifications: action.payload.data,
        });

    case notificationConstants.HIGHLIGHT_NOTIFICATION_STATUS:
      return Object.assign({}, state, {
        isNewMessageReceived: true,
      });
    default:
      return state
  }
}