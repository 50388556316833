import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';

export interface IAdminDetailsProps {
  AdminReducer: any,
  adminAction: any,
}

export interface IAdminDetailsState {
}

class AdminDetails extends React.Component<IAdminDetailsProps, IAdminDetailsState> {
  constructor(props: IAdminDetailsProps) {
    super(props);

    this.state = {
    }
    this.closeDetails = this.closeDetails.bind(this);
  }

  closeDetails() {
    this.props.adminAction.clearSelection();
  }

  public render() {
    const { selectAdmin } = this.props.AdminReducer
    return (
      <div className="card nb-card h-100">
        <div className="card-header bg-white border-bottom-0">
          <h1 className="nb-title">{t("screens:onboardingNewAdmins.employeeDetails")}<i className="fas fa-times float-right" onClick={this.closeDetails}></i></h1>
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className="row">
                <div className="col-sm-6">
                  <label>{t("screens:onboardingNewAdmins.givenName")}:</label>
                </div>
                <div className="col-sm-6 text-right">
                  <label className="font-weight-bold">
                    {selectAdmin.name}
                  </label>
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-sm-6">
                  <label>{t("screens:onboardingNewAdmins.email")}:</label>
                </div>
                <div className="col-sm-6 text-right">
                  <label className="font-weight-bold">
                    {selectAdmin.email}
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {}
}

function mapDispatchToProps(dispatch: any) {
  return {
    // adminAction: bindActionCreators(adminAction, dispatch),
  }
}

const connectedAdminDetails = connect(mapStateToProps, mapDispatchToProps)(AdminDetails);
export { connectedAdminDetails as AdminDetails };
