import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { t } from 'i18next';

export interface ICropImageModalProps {
    isModalShow: boolean,
    src:any,
    crop:any,
    onImageLoaded:any,
    onComplete:any,
    onChange:any,
    save: any,
    cancel: any,
}

export interface ICropImageModalState {
}

export default class CropImageModal extends React.Component<ICropImageModalProps, ICropImageModalState> {
  constructor(props: ICropImageModalProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
      const { isModalShow, src, crop, onChange, onComplete, onImageLoaded, save , cancel} = this.props;
    return (
        <Modal isOpen={isModalShow} className="nb-modal animated slideInRight">
          <ModalBody>
            <>
            <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onComplete}
                onChange={onChange}
            />
            </>
          </ModalBody>
          <ModalFooter>
            <button type="button"
              className="btn btn-nb-gradient rounded-pill"
              data-dismiss="modal"
              onClick={save}
            >{t("screens:register.modalSave")}</button>
            <button type="button"
              className="btn btn-nb-gradient rounded-pill"
              data-dismiss="modal"
              onClick={cancel}
            >{t("screens:register.modalCancel")}</button>
          </ModalFooter>
  
          {/* ----End of Modal for CredDef Issue--- */}
        </Modal>
    );
  }
}
