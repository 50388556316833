import { t } from "i18next"
import images from "../../../../../utils/images"
import { useDispatch } from "react-redux"
import { onboardingAction } from "src/View/Onboarding/actions/onboardingAction"
import { history } from "../../../../../index"

interface Props {
    connectedOrgName: string
}

const AcceptConnectionSuccess: React.FC<Props> = ({ connectedOrgName }) => {

    const dispatch = useDispatch()
    const viewMyConnections = () => {
        history.push('/connections')
    }
    const close = () => {
        dispatch(onboardingAction.clearInvitation())
    }

    return (
        <div className="col-sm-12">
            <div className="row">

                <div className="col-sm-2">

                </div>
                <div className="col-sm-8">

                    <div className="text-center">
                        <div className="form-group">

                            <img alt="Add success" src={images.illustrationAddSuccess} className="mb-5" />
                            <h2>{t("screens:acceptInvitationSuccess.heading")}</h2>
                            <p dangerouslySetInnerHTML={{ __html: t('screens:acceptInvitationSuccess.successMessage', { withOrg: connectedOrgName }) }} />
                            <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                                onClick={viewMyConnections}>
                                {t("screens:acceptInvitationSuccess.viewMyConnectionsBtn")}
                            </button>
                        </div>
                        <div className="text-center ">
                            <p
                                className="text-primary"
                                style={{ cursor: 'pointer' }}
                                onClick={close}>
                                {t("screens:acceptInvitationSuccess.closeBtn")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2">

                </div>
            </div>
        </div>
    )
}

export default AcceptConnectionSuccess
