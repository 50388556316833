import { combineReducers } from "redux";
import loginReducer  from "./View/Login/reducers/loginReducer";
import { reducer as toasterReducer } from "react-redux-toastr";
import { SchemaReducer } from "./View/Schema/reducers/schemaReducer";
import { CredentialReducer } from "./View/Credentials/reducers/credentialReducer";
import { ConnectionReducer } from "./View/Connection/reducers/connectionReducer";
import { DashboardReducer } from "./View/Dashboard/reducers/dashboardReducer";
import { LoaderReducer } from "./View/Dashboard/reducers/loaderReducer";
import { IssuanceReducer } from "./View/IssueCredential/reducers/issuanceReducer";
import { HolderReducer } from "./View/Holder/reducers/holderReducers";
import { OnboardingReducer } from "./View/Onboarding/reducers/onboardingReducer";
import { RegisterReducer } from "./View/Register/reducers/registerReducer";
import { EmailTemplatesReducers } from "./View/Settings/EmailTemplates/reducers/emailTemplatesReducers";
import { WorkflowReducer } from "./View/Verification-Workflow/reducers/workflowReducer"
import { OnLedgerListReducer } from "./View/Ledger/reducers/OnLedgerListReducer";
import { LoginMonitoringListReducer } from "./View/LoginMonitoring/reducers/LoginMonitoringReducer";
import { CreateWalletReducer } from "./View/Create-Wallet/reducers/createWalletReducer";
import { setDIDReducer } from "./View/Set-DID-Public/reducers/setDIDReducer";
import { orgProfileReducer } from "./View/Settings/ProfileSetting/reducers/orgProfileReducer";
import { TaskReducers } from "./View/Task/reducers/taskReducer";
import { IssuersReducers } from "./View/IssuersVerifiers/reducer/issuersReducer";
import { InstantTaskReducers } from "./View/VerificationMonitoring/reducers/instantTaskReducer";
import { PlatformConfigReducers } from "./View/Platform-Config/reducers/platformConfigReducer";
import { AgentStatusReducer } from "./View/AgentsStatus/reducers/agentStatusReducer";
import { GovRuleReducer } from "./View/Governance/reducers/GovRuleReducer";
import { MyCredentialsReducer } from "./View/MyCredentials/reducers/myCredentialReducer";
import { PresentProofReducer } from "./View/PresentProof/reducers/presentProofReducer";
import { ImportCredentialsReducer } from "./View/ClaimCredential/reducers/ImportCredentialsReducer";
import { ChatsReducer } from "./View/Chats/reducers/chatsReducers";
import { AdminReducer } from "./View/Admin/reducers/adminReducers";
import { PresentationProposalReducer } from "./View/PresentationProposal/reducers/presentationProposalReducer";
import { CredentialProposalReducer } from "./View/CredentialProposal/reducers/credentialProposalReducer";
import { NotificationReducer } from "./View/Notification/reducers/notificationReducer";
import { NegotiateCredentialReducer } from "./View/CredentialNegotiate/reducers/NegotiateCredentialReducer";
import { RevokeCredentialReducer } from "./View/RevokeCredential/reducers/revoke-credential.reducer";
import { CredentialOfferReducer } from "./View/CredentialOffer/reducers/credentialOfferReducer";
import { PresentationRequestReducer } from "./View/PresentationRequest/reducers/presentationRequestReducer";
import { PresentationNegotiateReducer } from "./View/PresentationNegotiate/reducers/PresentationNegotiateReducer";
import { MultiuseReducer } from "./View/Multiuse/reducers/multiuseReducer";

const rootReducer = combineReducers({
    // put here all reducer
    LoginReducer: loginReducer,
    toastr: toasterReducer,
    SchemaReducer: SchemaReducer,
    CredentialReducer: CredentialReducer,
    ConnectionReducer: ConnectionReducer,
    DashboardReducer: DashboardReducer,
    LoaderReducer: LoaderReducer,
    IssuanceReducer: IssuanceReducer,
    HolderReducer: HolderReducer,
    OnboardingReducer: OnboardingReducer,
    RegisterReducer: RegisterReducer,
    EmailTemplatesReducers: EmailTemplatesReducers,
    WorkflowReducer: WorkflowReducer,
    OnLedgerListReducer: OnLedgerListReducer,
    LoginMonitoringListReducer: LoginMonitoringListReducer,
    CreateWalletReducer: CreateWalletReducer,
    setDIDReducer: setDIDReducer,
    orgProfileReducer: orgProfileReducer,
    TaskReducers: TaskReducers,
    IssuersReducers: IssuersReducers,
    InstantTaskReducers: InstantTaskReducers,
    PlatformConfigReducers: PlatformConfigReducers,
    AgentStatusReducer: AgentStatusReducer,
    GovRuleReducer: GovRuleReducer,
    MyCredentialReducer: MyCredentialsReducer,
    PresentProofReducer: PresentProofReducer,
    ImportCredentialsReducer: ImportCredentialsReducer,
    ChatsReducer: ChatsReducer,
    AdminReducer: AdminReducer,
    PresentationProposalReducer: PresentationProposalReducer,
    PresentationRequestReducer: PresentationRequestReducer,
    CredentialOfferReducer: CredentialOfferReducer,
    CredentialProposalReducer: CredentialProposalReducer,
    NotificationReducer: NotificationReducer,
    NegotiateCredentialReducer: NegotiateCredentialReducer,
    RevokeCredentialReducer: RevokeCredentialReducer,
    PresentationNegotiateReducer: PresentationNegotiateReducer,
    MultiuseReducer: MultiuseReducer
})
export default rootReducer;