import * as React from 'react';
import { history } from "../../../index";
import RegistrationLayout from '../../Register/components/RegistrationLayout';

// Utilities
import images from 'src/utils/images';

export interface IDefaultCredDefSuccessProps {
}

export interface IDefaultCredDefSuccessState {
}

export default class DefaultCredDefSuccess extends React.Component<IDefaultCredDefSuccessProps, IDefaultCredDefSuccessState> {
  constructor(props: IDefaultCredDefSuccessProps) {
    super(props);

    this.state = {
    }
  }
  home = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => {
    localStorage.clear();
    history.push('/');
  };

  componentDidMount() {
    setTimeout(() => {
      localStorage.clear();
      history.push('/');
    }, 10000);
  }

  public render() {
    return (
      <>
        <RegistrationLayout>
          <div className="text-center">
            <img alt="Success" className="mb-3" src={images.illustrationSuccess} />
            <h1>Default Credential Definition Created Successfully!</h1>
            <br />
            <div className="alert alert-info" role="alert">
              <i className="fas fa-info-circle"></i>Please check your email for next process. <button className="btn nb-link" onClick={this.home}>BACK TO HOME</button>
            </div>
          </div>
        </RegistrationLayout>
      </>
    );
  }
}
