import * as React from 'react';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { filterPAConnection, tHeadforEditConnection, tHeadPAConnection } from '../constants';

export interface IPAConnectionsListProps {
  connectionAction: any,
  ConnectionReducer: any,
  editConnection?: any,
}

export interface IPAConnectionsListState {
  defaultSearchFields: any,
  item_perPage: any,
  pageNo: any,
  sortingOrder: any,
  connectionSortBy: any,
  type: any,
}

export default class PAConnectionsList extends React.Component<IPAConnectionsListProps, IPAConnectionsListState> {
  constructor(props: IPAConnectionsListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      type: '',
    }
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.filter = this.filter.bind(this);
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, connectionSortBy, type } = this.state;
    this.props.connectionAction.updatePageNo(page);
    this.props.connectionAction.getAllPAConnections(defaultSearchFields, type, sortingOrder, connectionSortBy, item_perPage, page,);
  }

  showDetails(item: object | any) {
    this.props.connectionAction.selectConnection(item);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortingOrder, connectionSortBy, pageNo, type } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.connectionAction.getAllPAConnections(event.target.value, type, sortingOrder, connectionSortBy, item_perPage, pageNo,);
    }
  }

  sorting = (connectionSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo, type } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', connectionSortBy: connectionSortBy }) : this.setState({ sortingOrder: 'DESC', connectionSortBy: connectionSortBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.connectionAction.getAllPAConnections(defaultSearchFields, type, sortValue, connectionSortBy, item_perPage, pageNo,);
  }


  refresh = (event: any) => {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, connectionSortBy, type } = this.state;
    this.props.connectionAction.getAllPAConnections(defaultSearchFields, type, sortingOrder, connectionSortBy, item_perPage, pageNo,);
  }

  filter = (selectedFilterValue: any) => {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, connectionSortBy } = this.state;
    this.props.connectionAction.getAllPAConnections(defaultSearchFields, selectedFilterValue, sortingOrder, connectionSortBy, item_perPage, pageNo,);
  }
  public render() {
    const { paConnectionList, paTotalConnections } = this.props.ConnectionReducer;
    return (
      <>
        <TableComponents
          onChangePage={this.onChangePage}
          showElementDetails={this.showDetails}
          tHead={tHeadPAConnection}
          tableContent={paConnectionList}
          totalRecords={paTotalConnections}
          sorting={this.sorting}
          searching={this.onSearch}
          refresh={this.refresh}
        />
      </>
    );
  }
}
