import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { IssuerActions } from '../actions/issuerActions';
import { credentialAction } from '../../Credentials/actions/credentialAction';
import { TaskActions } from '../../Task/actions/taskActions';
import { t } from 'i18next';
import { CredDefResponsibilityType, InternalIssuerVerifierOptions } from '../interfaces';
import { RoleType } from 'src/View/types';
import EditInternalIssuersVerifiers from '../components/EditInternalIssuersVerifiers';
import InternalIssuersList from '../components/InternalIssuersList';
import InternalIssuerVerifierDetails from '../components/InternalIssuerVerifierDetails';

export interface IInternalIssuersVerifiersProps {
  IssuerActions: any,
  IssuersReducers: any,
  CredentialReducer: any,
  credentialAction: any,
  TaskActions: any,
  TaskReducers: any,
  LoginReducer: any
}

export interface IInternalIssuersVerifiersState {
  internalIssuerOptions: InternalIssuerVerifierOptions,
}

class InternalIssuersVerifiers extends React.Component<IInternalIssuersVerifiersProps, IInternalIssuersVerifiersState> {
  constructor(props: IInternalIssuersVerifiersProps) {
    super(props);
    this.state = {
      internalIssuerOptions: {
        defaultSearchFields: '',
        itemsPerPage: 10,
        pageNo: 1,
        sortingOrder: 'DESC',
        internalIssuerSortBy: '',
        filterStatus: 'all',
        type: RoleType.ISSUER_VERIFIER
      }
    }
  }

  componentDidMount() {
    const { loggedInUser } = this.props.LoginReducer;
    const { internalIssuerOptions } = this.state;
    this.props.IssuerActions.getInternalIssuersVerifiers(internalIssuerOptions);
    this.props.IssuerActions.listView();
    this.props.TaskActions.getAllCreateCredDefByOrgId(loggedInUser.organization.id, CredDefResponsibilityType.All);
  }

  public render() {
    const { editInternalIssuerVerifier, listViewOfInternalIssuerVerifier, editInternalIssuerVerifiers } = this.props.IssuersReducers;
    const { loggedInUser } = this.props.LoginReducer;
    let subscriptionId = loggedInUser.organization.subscription.id

    return (
      <>
        {editInternalIssuerVerifier && !listViewOfInternalIssuerVerifier ?
          <EditInternalIssuersVerifiers
            editInternalIssuerVerifierData={editInternalIssuerVerifiers}
            {...this.props}
          />
          :
          <div className="row gutters-sm min-h-100">
            <div className="col-md-12 col-lg-8 mb-30">
              <div className="card nb-card h-100">
                <div className="card-header bg-white border-bottom-0">
                  <h1 className="nb-title">
                  {
                        subscriptionId === 1
                          ? t("screens:existingIssuersVerifiers.issuerTitle")
                          : subscriptionId === 2
                            ? t("screens:existingIssuersVerifiers.verifierTitle")
                            : t("screens:existingIssuersVerifiers.bothTitle")
                      }
                  </h1>
                </div>
                <div className="card-body">
                  <InternalIssuersList {...this.props} />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mb-30">
              <InternalIssuerVerifierDetails {...this.props} />
            </div>
          </div>
        }
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const IssuersReducers = state.IssuersReducers;
  const CredentialReducer = state.CredentialReducer;
  const TaskReducers = state.TaskReducers;
  const LoginReducer = state.LoginReducer
  return { IssuersReducers, CredentialReducer, TaskReducers, LoginReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    IssuerActions: bindActionCreators(IssuerActions, dispatch),
    credentialAction: bindActionCreators(credentialAction, dispatch),
    TaskActions: bindActionCreators(TaskActions, dispatch),
  }
}

const ConnectedInternalIssuersVerifiers = connect(mapStateToProps, mapDispatchToProps)(InternalIssuersVerifiers);
export { ConnectedInternalIssuersVerifiers as InternalIssuersVerifiers };
