import axios from "axios";
import { issuanceAction } from "../../IssueCredential/actions/issuanceAction";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { credentialOfferConstants, loaderConst, userConstants } from "../../types"
import { toastr } from "react-redux-toastr";
import { t } from "i18next";
import { CredentialState } from "src/View/commonConst";
import { TaskActions } from "src/View/Task/actions/taskActions";
import store from "src/store";
import { CredDefResponsibilityType } from "src/View/IssuersVerifiers/interfaces";

export const credentialOfferAction = {

    createNewOffer() {
        return async (dispatch: any) => {
            dispatch({
                type: credentialOfferConstants.CREATE_NEW_OFFER,
            })
        }
    },

    clearCredentialProposal() {
        return async (dispatch: any) => {
            dispatch({
                type: credentialOfferConstants.CLEAR_CREDENTIAL_OFFER,
            })
            dispatch({
                type: credentialOfferConstants.CLEAR_SCHEMA
            })
        }
    },

    sendOffer(finalData: any, credentialName: any) {
        return async (dispatch: any) => {
          // debugger;
          try {
            dispatch({
              type: loaderConst.LOADER_TRUE
            })
            let finalDatas = {
              auto_issue: true,
              connection_id: finalData.connection_id,
              cred_def_id: finalData.cred_def_id,
              comment: '',
              attributes: finalData.attributes
            }
    
            let connectionName = finalData.selectedConnection;
            let token = localStorage.getItem('token');
            return await axios.post(`${config.apiUrl}/issue-credential/send-offer`, finalDatas,
              { headers: { "Authorization": `Bearer ${token}` } })
              .then(async issueCredential => {
                dispatch({
                  type: credentialOfferConstants.OFFERED_CREDENTIAL,
                  payload: issueCredential,
                  connectionName
                })
                dispatch({
                  type: credentialOfferConstants.SUCCESS_OFFER_CREDENTIAL
                })
                const listScreenOptions = {
                    search: '',
                    itemsPerPage: 10,
                    pageNo: 1,
                    sortBy: 'id',
                    sort: 'DESC',
                    filter: CredentialState.STATE_OFFER_SENT
                  }

                dispatch(issuanceAction.getAllCredentialRequest(listScreenOptions))

                const { organization } = await store.getState().LoginReducer.loggedInUser
                
                dispatch(TaskActions.getAllCreateCredDefByOrgId(organization.id, CredDefResponsibilityType.Issuance))
                
                dispatch({
                  type: loaderConst.LOADER_FALSE
                })
                // TODO toastr.success(`${issueCredential.data.message}`, `${credentialName}`);
                toastr.success(`${t("screens:issueCredentials.toastMsgIssueCredOffer")}`, `${credentialName}`);
              })
              .catch(error => {
                loginAction.handleResponse(error.response, dispatch);
    
                dispatch({
                  type: loaderConst.LOADER_FALSE
                })
              })
          } catch (error) {
            throw error;
          }
        }
      },

    offerCredentialComponent() {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: credentialOfferConstants.CREATE_ANOTHER_CREDENTIAL_OFFER,
                })
            } catch (error) {
                throw error;
            }
        }
    },

    clearIssuanceCredential() {
        return async (dispatch: any) => {
          dispatch({
            type: credentialOfferConstants.CLEAR_CREDENTIAL_OFFER,
          })
          dispatch({
            type: userConstants.CLEAR_SCHEMA
          })
        }
      },
}
