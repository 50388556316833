import { credentialProposal } from "../../types";

const initialState = {
    loading: true,
    hasNextPage: false,
    hasPreviousPage: false,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    totalItems: 0,
    showSuccessProposalCredentialComponent: false,
    proposedCredential: {},
    connectionName: ''
}

export function CredentialProposalReducer(state = initialState, action: any) {


    switch (action.type) {
        case credentialProposal.CLEAR_CREDENTIAL_PROPOSAL:
            return Object.assign({}, state, {
                filledCredentialValue: {},
            });
        case credentialProposal.SUCCESS_PROPOSE_CREDENTIAL:
            return Object.assign({}, state, {
                showSuccessProposalCredentialComponent: true
            })

        case credentialProposal.CREATE_ANOTHER_CREDENTIAL_PROPOSAL:
            return Object.assign({}, state, {
                showSuccessProposalCredentialComponent: false
            })

        case credentialProposal.PROPOSED_CREDENTIAL:
            return Object.assign({}, state, {
                proposedCredential: action.payload,
                connectionName: action.connectionName,
            })
        default:
            return state;
    }
}
