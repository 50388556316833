import { t } from 'i18next';

export const tHeadCredentialList = [{
  'name': `${t("screens:issueCredentials.headGivenName")}`,
  'label': "connectionName"
}, {
  'name': `${t("screens:issueCredentials.headCredentialName")}`,
  'label': "credentialDefinitionId"
}, {
  'name': `${t("screens:issueCredentials.headDate")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:issueCredentials.headStatus")}`,
  'label': "state"
}, {
  'name': '',
  'label': "credViewBtn"
}];



export const tHeadIssueKycCredentials = [{
  'name': 'DATE',
  'label': "createDateTime"
}, {
  'name': 'GIVEN NAME',
  'label': "connectionName"
}, {
  'name': 'CONNECTION DID',
  'label': "connectionDID"
}, {
  'name': 'KYC DETAILS',
  'label': "showKycDetails"
},
// {
//   'name': 'KYC RESULT',
//   'label': "kycResult"
// }, 
{
  'name': 'STATUS',
  'label': "kycStatus"
}, {
  'name': '',
  'label': "issuebtnkycStatus"
}];

export const filterIssueKycCredentials = [{
  'name': 'all',
  'tag': 'all'
}, {
  'name': 'Proposal Request',
  'tag': 'proposal_request'
}, {
  'name': 'Offer Sent',
  'tag': 'offer_sent'
}, {
  'name': 'Request Received',
  'tag': 'request_received',
}, {
  'name': 'Credential Issued',
  'tag': 'credential_issued',
}, {
  'name': 'Credential Acked',
  'tag': 'credential_acked'
},
];

export const filterCredRequestWaitingList = [{
  'name': 'All',
  'tag': 'all'
}, {
  'name': 'Revocable',
  'tag': 'revoke'
}, {
  'name': 'Non Revocable',
  'tag': 'nonRevoke'
}
];

export const proposalTableHeadings = [{
  'name': `${t("screens:issueCredentials.headDate")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:issueCredentials.headGivenName")}`,
  'label': "connectionName"
},{
  'name': `${t("screens:issueCredentials.headCredentialName")}`,
  'label': "credentialDefinitionId"
}, {
  'name': `${t("screens:issueCredentials.headStatus")}`,
  'label': "state"
}, {
  'name': ``,
  'label': "proposalViewBtn"
}]

export const negotiateProposalTableHeadings = [{
  'name': `${t("screens:issueCredentials.headGivenName")}`,
  'label': "connectionName"
}, {
  'name': `${t("screens:issueCredentials.headCredentialName")}`,
  'label': "credentialDefinitionId"
}, {
  'name':  `${t("screens:issueCredentials.headDate")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:issueCredentials.headStatus")}`,
  'label': "state"
}, {
  'name': `${t("screens:issueCredentials.headAction")}`,
  'label': 'proposalViewBtn'
}]
