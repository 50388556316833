import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { agentStatusAction } from '../actions/agentStatusAction';
import AgentStatusList from '../components/AgentStatusList';

export interface IAgentStatusProps {
  agentStatusAction: any,
  AgentStatusReducer: any,
}

export interface IAgentStatusState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  status:any,
  // connectionSortBy: string,
}

class AgentStatus extends React.Component<IAgentStatusProps, IAgentStatusState> {
  constructor(props: IAgentStatusProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      status:'all'
    }
  }
  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, status } = this.state;
    this.props.agentStatusAction.getAllAgentStatusList(defaultSearchFields, status, sortingOrder, item_perPage, pageNo);
  }
  public render() {


    return (
      <>
        {
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="card nb-card-noborder">
                <div className="card-header bg-white">
                  <h1>Agent status List</h1>
                </div>
                <div className="card-body">
                  <AgentStatusList {...this.props} />
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}


function mapStateToProps(state: any) {
  const AgentStatusReducer = state.AgentStatusReducer;
  return { AgentStatusReducer }
}

function mapDispatchToProps(dispatch: any) {

  return {
    agentStatusAction: bindActionCreators(agentStatusAction, dispatch),
  }
}

const connectedAgentStatus = connect(mapStateToProps, mapDispatchToProps)(AgentStatus);
export { connectedAgentStatus as AgentStatus };