
import axios from "axios";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { PresentProofState } from "src/View/commonConst";
import { CredDefResponsibilityType } from "src/View/IssuersVerifiers/interfaces";
import { presentationRequestAction } from "src/View/PresentationRequest/actions/presentationRequestAction";
import { config } from "../../../config";
import { issuanceAction } from "../../IssueCredential/actions/issuanceAction";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, presentationRequestConstants, taskConst } from "../../types";


export const TaskActions = {
  showInstantTask() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SHOW_INSTANT_TASK,
      })
    }
  },

  clickScheduledTask(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SHOW_SCHEDULED_TASK,
        payload: item
      })
    }
  },

  sendInstantTask(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.post(`${config.apiUrl}/verifier/send/instant-task-request`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: taskConst.SEND_INSTANT_TASK_REQUEST,
              payload: response,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            dispatch({
              type: presentationRequestConstants.SHOW_NEW_REQUEST
            })
            const listOptions = {
              pageNo: 1,
              itemsPerPage: 10,
              filter: PresentProofState.STATE_REQUEST_SENT
            }
            dispatch(presentationRequestAction.getAllPresentProofRequest(listOptions))
            !_.isEmpty(response) ? toastr.success(`${response.data.message}`, ``) : toastr.info(`API response is empty`, ``)
          })
          .catch(error => {
            console.log("error", error.response);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllCreateCredDefByOrgId(orgId: number, vcType: CredDefResponsibilityType) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/cred-def/all/organization/${orgId}?filter=${vcType}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            dispatch({
              type: taskConst.GET_ALL_CREATED_CREDDEF_BY_ORGID,
              payload: data,
            })

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            issuanceAction.getAllCredential('', 10, 1);
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  createBasicInfo(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.post(`${config.apiUrl}/verifier/create/schedule-task`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: taskConst.CREATE_BASIC_INFO,
              payload: response,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })

            // toastr.success(`${response.data.message}`, ``)
          })
          .catch(error => {
            console.log("error", error.response);
            dispatch({
              type: taskConst.CREATE_BASIC_INFO_FAIL,
              payload: error.response,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  updateScheduleTask(finalData: any, id: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.put(`${config.apiUrl}/verifier/update-schedule-task/${id}`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: taskConst.UPDATE_SCHEDULE_TASK,
              payload: response,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })

            toastr.success(`${response.data.message}`, ``)
          })
          .catch(error => {
            console.log("error", error.response);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  clearForm() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.CLEAR_INSTANT_TASK_FORM
      })
    }
  },

  clearFormScheduleTask() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.CLEAR_SCHEDULE_TASK_FORM
      })
    }
  },

  getAllCredDefForInstantTask() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/cred-def/get-all/tasks`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            dispatch({
              type: taskConst.GET_ALL_CREATED_CREDDEF_FOR_INSTANT_TASK,
              payload: data,
            })

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            issuanceAction.getAllCredential('', 10, 1);
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllSchemas(orgId: number, vcType: CredDefResponsibilityType) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/schema/tasks/organization/${orgId}?filter=${vcType}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            dispatch({
              type: taskConst.GET_ALL_SCHEMA_FOR_INSTANT_TASK,
              payload: data,
            })

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            issuanceAction.getAllCredential('', 10, 1);
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  hideInstantTask() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.HIDE_INSTANT_TASK,
      })
    }
  },

  attributesData(selectedCredDefOptions: any) {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SELECTED_CREDEF_OPTION_FOR_ATTRIBUTES,
        payload: selectedCredDefOptions
      })
    }
  },

  /**
   * Method used to call get single cred-def details with organization
   * @param credentialDefinitionId Parameter used to get credential definition id
   * @returns It return the credential details
   */
  getSelectedCredDefDetails(credentialDefinitionId: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/cred-def/get-by-cred-def-id/${credentialDefinitionId}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(credentialDefinitionDetails => {
            dispatch({
              type: taskConst.GET_SINGLE_CRED_DEF_DETAILS,
              payload: credentialDefinitionDetails.data.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error
      }
    }
  },

  getSelectedSchemaDetails(schemaLedgerId: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/schema/get-by-id/${schemaLedgerId}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(schemaDetails => {
            dispatch({
              type: taskConst.GET_SINGLE_SCHEMA_DETAILS,
              payload: schemaDetails.data.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error
      }
    }
  },

  /**
   * Method used to clear the selected credential organization name
   * @returns Dispatch the action for clear the selected issuer organization
   */
  clearSelectedIssuerOrganizationName() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: taskConst.CLEAR_SELECTED_ISSUER_ORGANIZATION
        })

      } catch (error) {
        throw error
      }
    }
  },

  getAllOrganization() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/organization/get-organizations-instant-task`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(organizationsList => {
            dispatch({
              type: taskConst.ORGANIZATIONS_LIST,
              payload: organizationsList.data.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error
      }
    }
  },

  getSelectedOrganizationCredentials(orgId: number, vcType: CredDefResponsibilityType) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/cred-def/organization/${orgId}?filter=${vcType}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(credentialDefinitionsByOrganizationsList => {
            dispatch({
              type: taskConst.CREDENTIAL_DEFINITION_LIST_BY_ORGANIZATION,
              payload: credentialDefinitionsByOrganizationsList.data.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error
      }
    }
  }

}