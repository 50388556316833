import { emailTemplate, userConstants } from "../../../types";


// debugger
const initialState = {
    emailTemplateData:{},
    emailTemplatesList:[],
    selecteEmailTemp:{},
    updatedEmailTemp:{},
}

export function EmailTemplatesReducers(state = initialState, action: any) {
  // debugger
  switch (action.type) {
    case userConstants.LOADING:
      return Object.assign({}, state, { loading: true });

    case emailTemplate.UPDATE_EMAIL_TEMPLATE:
        return Object.assign({}, state, {
            updatedEmailTemp: action.payload.data.data,
            selecteEmailTemp:{},
        })
    case emailTemplate.GET_EMAIL_PURPOSE:
        return Object.assign({}, state, {
            emailTemplatesList: action.payload.data.data,
            selecteEmailTemp: action.payload.data.data[0],
            // updatedEmailTemp:{}
        })
    case emailTemplate.GET_SELECTED_EMAIL_TEMPLATE:
        return Object.assign({}, state, {
            selecteEmailTemp:action.payload.data.data,
            updatedEmailTemp:{}
        })
    default:
      return state
  }
}

