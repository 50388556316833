import { credentialNegotiate } from "src/View/types"
import { negotiateCredentialTableHeadings } from "../constants"

const initialState = {
    credentialRecords: [],
    hasPreviousPage: false,
    hasNextPage: false,
    lastPage: 0,
    nextPage: 0,
    totalCredentialRecords: 0,
    previousPage: 0,
    tHeadNegotiate: [],
    selectedCredentialRecord: {},
    selectedSchema: {}
}

export const NegotiateCredentialReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case credentialNegotiate.GET_ALL_NEGOTIATE_CREDENTIALS_RECORDS:
            return Object.assign({}, state, {
                credentialRecords: action.updatedPayload,
                hasPreviousPage: action.payload.data.data.hasPreviousPage,
                hasNextPage: action.payload.data.data.hasNextPage,
                lastPage: action.payload.data.data.lastPage,
                nextPage: action.payload.data.data.nextPage,
                totalCredentialRecords: action.payload.data.data.totalItems,
                previousPage: action.payload.data.data.previousPage,
                tHeadNegotiate: negotiateCredentialTableHeadings,
            })

            case credentialNegotiate.SELECTED_NEGOTIATE_CREDENTIAL_RECORD:
                return Object.assign({}, state, {
                  selectedCredentialRecord: action.payload,
                  selectedSchema: action.selectedSchema,
                })

        default:
            return state;
    }
}
