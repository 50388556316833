export const userConstants = {
  // Login types
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  GET_LOGGED_USER: 'GET_LOGGED_USER',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  SAVE_LOGIN_DATA: 'SAVE_LOGIN_DATA',

  //Get
  GETALL_REQUEST: 'GETALL_REQUEST',
  GETALL_SUCCESS: 'GETALL_SUCCESS',
  GETALL_FAILURE: 'GETALL_FAILURE',

  //Delete 
  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  //Loading
  LOADING: 'LOADING',

  //Schema
  CREATE_SCHEMA: 'CREATE_SCHEMA',
  CREATE_SCHEMA_DEF: 'CREATE_SCHEMA_DEF',
  CREATE_SCHEMA_FAILURE: 'CREATE_SCHEMA_FAILURE',
  CREATE_SCHEMA_DEF_FAILURE: 'CREATE_SCHEMA_DEF_FAILURE',
  GETALL_SUCCESS_SCHEMA: 'GETALL_SUCCESS_SCHEMA',
  CREATE_SCHEMA_SUCCESS: 'CREATE_SCHEMA_SUCCESS',
  GET_SCHEMA_BY_ID: 'GET_SCHEMA_BY_ID',
  CLEAR_SCHEMA: 'CLEAR_SCHEMA',
  GET_SCHEMA_LIST: 'GET_SCHEMA_LIST',
  GET_EXTERNAL_ORGANIZATION_LIST: 'GET_EXTERNAL_ORGANIZATION_LIST',
  SELECTED_SCHEMA: 'SELECTED_SCHEMA',
  SHOW_SCHEMA_FROM: 'SHOW_SCHEMA_FROM',
  CLEAR_SELECTED_SCHEMA: 'CLEAR_SELECTED_SCHEMA',
  SELECTED_SCHEMA_LEDGER: 'SELECTED_SCHEMA_LEDGER',
  GET_DEFAULT_SCHEMA: 'GET_DEFAULT_SCHEMA',
  SELECTED_DEFAULT_SCHEMA: 'SELECTED_DEFAULT_SCHEMA',
  CREATE_SCHEMA_ERROR: 'CREATE_SCHEMA_ERROR',

  //Credential Definition
  GETALL_REQUEST_CREDEF: 'GETALL_REQUEST_CREDEF',
  GETALL_SUCCESS_CREDEF: 'GETALL_SUCCESS_CREDEF',
  GETALL_FAILURE_CREDEF: 'GETALL_FAILURE_CREDEF',
  CREATE_CREDDEF: 'CREATE_CREDDEF',
  CREATE_CREDDEF_FAILURE: 'CREATE_CREDDEF_FAILURE',
  CLEAR_CREDDEF: 'CLEAR_CREDDEF',
  GETALL_DEFAULT_SUCCESS_CREDEF: 'GETALL_DEFAULT_SUCCESS_CREDEF',

  SELECTED_CREDDEF: 'SELECTED_CREDDEF',
  SHOW_CREDDEF_FROM: 'SHOW_CREDDEF_FROM',
  GETALL_INTERNAL_CREDENTIALS: 'GETALL_INTERNAL_CREDENTIALS',
  SELECT_INTERNAL_CREDENTIAL: 'SELECT_INTERNAL_CREDENTIAL',
  GETALL_HOLDER_DETAILS: 'GETALL_HOLDER_DETAILS',
  CREATED_CREDDEF: 'CREATED_CREDDEF',
  CREATED_CREDDEF_DB: 'CREATED_CREDDEF_DB',
  TABLE_SCROLL_DATA: 'TABLE_SCROLL_DATA',
  CLEAR_HOLDER_DETAIL_LIST: 'CLEAR_HOLDER_DETAIL_LIST',

  //Count of Schema,Connection,Credential Definition
  GET_COUNT: 'GET_COUNT',

  //For Re-login after session expires
  RE_LOGIN: 'RE_LOGIN',
  REVOKE_CREDENTIAL: 'REVOKE_CREDENTIAL',
  SHOW_SUCCESS_CREDENTIAL_PAGE: 'SHOW_SUCCESS_CREDENTIAL_PAGE',
  CREATE_CREDENTIAL_PAGE: 'CREATE_CREDENTIAL_PAGE',

};

export const connectionConst = {
  GET_ALL_CONNECTIONS: 'GET_ALL_CONNECTIONS',
  SELECTED_CONNECTION: 'SELECTED_CONNECTION',
  GET_CREDENTIALS_BY_USER: 'GET_CREDENTIALS_BY_USER',
  CLEAR_CONNECTION: 'CLEAR_CONNECTION',
  CLEAR_CONNECTION_FOR_ISSUE_CREDDEF: 'CLEAR_CONNECTION_FOR_ISSUE_CREDDEF',
  RECEIVED_CREDENTIAL: 'RECEIVED_CREDENTIAL',
  SELECTED_RECEIVED_CREDENTIAL: 'SELECTED_RECEIVED_CREDENTIAL',
  UPDATE_CONNECTION_LIST: 'UPDATE_CONNECTION_LIST',
  UPDATE_PAGE_NO: 'UPDATE_PAGE_NO',
  UPDATE_RECEIVED_CREDENTIAL_LIST: 'UPDATE_RECEIVED_CREDENTIAL_LIST',
  UPDATE_CRED_PAGE_NO: 'UPDATE_CRED_PAGE_NO',
  TABLE_CONNECTIONS: 'TABLE_CONNECTIONS',
  EDIT_CONNECTIONS: 'EDIT_CONNECTIONS',
  LIST_VIEW_CONNECTION: 'LIST_VIEW_CONNECTION',
  DEFAULT_PAGE: 'DEFAULT_PAGE',
  PA_GET_ALL_CONNECTIONS: 'PA_GET_ALL_CONNECTIONS',
  VIEW_EXSITING_CONNECTION: 'VIEW_EXSITING_CONNECTION',
  SELECTED_CONNECTION_BY_ICL: 'SELECTED_CONNECTION_BY_ICL',
  CONNECTION_DELETED: 'CONNECTION_DELETED',
  GET_ALL_CONNECTIONS_DELETED: 'GET_ALL_CONNECTIONS_DELETED',
  GET_ALL_CONNECTED_ORGS: 'GET_ALL_CONNECTED_ORGS',
  GET_ALL_PENDING_CONNECTIONS: 'GET_ALL_PENDING_CONNECTIONS',
}

export const loaderConst = {
  LOADER_TRUE: 'LOADER_TRUE',
  LOADER_FALSE: 'LOADER_FALSE',
  CONNECTION_LIST_LOADER_TRUE: 'CONNECTION_LIST_LOADER_TRUE',
  CONNECTION_LIST_LOADER_FALSE: 'CONNECTION_LIST_LOADER_FALSE',
  CREDENTIAL_LIST_LOADER_TRUE: 'CREDENTIAL_LIST_LOADER_TRUE',
  CREDENTIAL_LIST_LOADER_FALSE: 'CREDENTIAL_LIST_LOADER_FALSE',
  CRED_AVAILABLE_LIST_LOADER_TRUE: 'CRED_AVAILABLE_LIST_LOADER_TRUE',
  CRED_AVAILABLE_LIST_LOADER_FALSE: 'CRED_AVAILABLE_LIST_LOADER_FALSE',
}

export const issuanceConst = {
  SELECTED_ISSUANCE_CREDENTIAL: 'SELECTED_ISSUANCE_CREDENTIAL',
  CLEAR_ISSUANCE_CREDENTIAL: 'CLEAR_ISSUANCE_CREDENTIAL',
  ISSUE_CREDENTIAL: 'ISSUE_CREDENTIAL',
  GET_ALL_CREDENTIALS: 'GET_ALL_CREDENTIALS',
  ISSUE_CREDENTIALS: 'ISSUE_CREDENTIALS',
  SELECTE_ISSUE_CREDENTIAL_BY_WAITING_LIST: 'SELECTE_ISSUE_CREDENTIAL_BY_WAITING_LIST',
  GET_ALL_CREDENTIALS_REQUEST: 'GET_ALL_CREDENTIALS_REQUEST',
  SUCCESS_ISSUE_CREDENTIAL: 'SUCCESS_ISSUE_CREDENTIAL',
  ISSUE_ANOTHER_CREDENTIAL: 'ISSUE_ANOTHER_CREDENTIAL',
  OFFER_CREDENTIAL_ISSUE: 'OFFER_CREDENTIAL_ISSUE',
  GET_ALL_CREDENTIAL_PROPOSAL: 'GET_ALL_CREDENTIAL_PROPOSAL',

  GET_ALL_CRED_DEF_BY_PAIRWISE_DID: 'GET_ALL_CRED_DEF_BY_PAIRWISE_DID',
  GET_ALL_CREDENTIALS_RECORD: 'GET_ALL_CREDENTIALS_RECORD',
  SELECTED_CREDENTIAL_DETAILS: 'SELECTED_CREDENTIAL_DETAILS'
}

export const organizationConst = {
  GET_ALL_REQUEST: 'GET_ALL_REQUEST',
  SELECTED_EXISTINGORGANIZATION: 'SELECTED_EXISTINGORGANIZATION',
  EDIT_EXISTING_ORGANIZATION: 'EDIT_EXISTING_ORGANIZATION',
  GET_INITIAL_PASSWORD: 'GET_INITIAL_PASSWORD',
  GET_REGISTRY_DETAILS: 'GET_REGISTRY_DETAILS',
  SHOW_SUBMIT_PAGE: 'SHOW_SUBMIT_PAGE',
  HIDE_SUBMIT_PAGE: 'HIDE_SUBMIT_PAGE',
  SELECTED_ONBOARDING_ORGANIZATION: 'SELECTED_ONBOARDING_ORGANIZATION',
  ONBOARDING_ORGANIZATION_ACCEPTED: 'ONBOARDING_ORGANIZATION_ACCEPTED',
  ONBOARDING_ORGANIZATION_REJECTED: 'ONBOARDING_ORGANIZATION_REJECTED',
  ADD_ORGANIZATION: 'ADD_ORGANIZATION',
  SHOW_MESSAGE_FOR_ORGNAME: 'SHOW_MESSAGE_FOR_ORGNAME',
  SHOW_MESSAGE_FOR_ADMIN_EMAIL: 'SHOW_MESSAGE_FOR_ADMIN_EMAIL',
  HIDE_MESSAGE_FOR_ORGNAME: 'HIDE_MESSAGE_FOR_ORGNAME',
  HIDE_MESSAGE_FOR_ADMIN_EMAIL: 'HIDE_MESSAGE_FOR_ADMIN_EMAIL',
  CLEAR_TYPEAHEAD: 'CLEAR_TYPEAHEAD',
  GET_ALL_CREDENTIAL_OFFER_REQUEST_LIST: "GET_ALL_CREDENTIAL_OFFER_REQUEST_LIST",
  GET_ALL_ORGANIZATION_CONNECTION: 'GET_ALL_ORGANIZATION_CONNECTION',
  GENERATE_CONNECTION_INVITATION: 'GENERATE_CONNECTION_INVITATION',
  GET_ALL_ONBOARD_ORGANIZATION: 'GET_ALL_ONBOARD_ORGANIZATION',
  SEND_INVITATION_TO_ORGANIZATION: 'SEND_INVITATION_TO_ORGANIZATION',
  INVITE_NEW_ORG: 'INVITE_NEW_ORG',
  ACCEPT_INVITATION: 'ACCEPT_INVITATION',
  SAVE_INVITATION: 'SAVE_INVITATION',
  GET_ALL_COUNTRIES: 'GET_ALL_COUNTRIES',
  KOMPANY_VERIFICATION: 'KOMPANY_VERIFICATION',
  GET_ALL_STATES: 'GET_ALL_STATES',
  CLOSE_SUCCESS: "CLOSE_SUCCESS",
  SELECTED_INVITATION: 'SELECTED_INVITATION',
  GET_ALL_INVITATION: 'GET_ALL_INVITATION',
  SHOW_SELECTED_INVITATION_DETAILS: "SHOW_SELECTED_INVITATION_DETAILS",
  CLEAR_INVITATION: 'CLEAR_INVITATION',
  CLEAR_SELECTED_INVITATION_DETAILS: 'CLEAR_SELECTED_INVITATION_DETAILS',
  CHECK_EXISTING_ORG_EMAILID: 'CHECK_EXISTING_ORG_EMAILID',
  HIDE_CHECK_EXISTING_ORG_EMAILID: 'HIDE_CHECK_EXISTING_ORG_EMAILID'
}

export const holderConst = {
  GET_HOLDER_QR: 'GET_HOLDER_QR',
  CREATE_HOLDER: 'CREATE_HOLDER',
  GET_HOLDER_LIST: 'GET_HOLDER_LIST',
  SELECT_HOLDER: 'SELECT_HOLDER',
  SELECT_CLIENT: 'SELECT_CLIENT',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  SHOW_NEW_INVITE: 'SHOW_NEW_INVITE',
  INVITE_NEW_HOLDER: 'INVITE_NEW_HOLDER',
  EMAIL_SENT: 'EMAIL_SENT',
  CHECK_EXISTING_HOLDER_EMAILID: 'CHECK_EXISTING_HOLDER_EMAILID',
  CHECK_EXISTING_HOLDER_NAME: 'CHECK_EXISTING_HOLDER_NAME',
  HIDE_CHECK_EXISTING_HOLDER_EMAILID: 'HIDE_CHECK_EXISTING_HOLDER_EMAILID',
  HIDE_CHECK_EXISTING_HOLDER_NAME: 'HIDE_CHECK_EXISTING_HOLDER_NAME',
}

export const adminConnectionConst = {
  GET_ADMIN_QR: 'GET_ADMIN_QR',
  CREATE_ADMIN: 'CREATE_ADMIN',
  GET_ADMIN_LIST: 'GET_ADMIN_LIST',
  SELECT_ADMIN: 'SELECT_ADMIN',
  EMAIL_SENT: 'EMAIL_SENT',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  SHOW_NEW_INVITE: 'SHOW_NEW_INVITE',
  INVITE_NEW_ADMIN: 'INVITE_NEW_ADMIN',
  CHECK_EXISTING_ADMIN_EMAILID: 'CHECK_EXISTING_ADMIN_EMAILID',
  CHECK_EXISTING_ADMIN_NAME: 'CHECK_EXISTING_ADMIN_NAME',
  HIDE_CHECK_EXISTING_ADMIN_EMAILID: 'HIDE_CHECK_EXISTING_ADMIN_EMAILID',
  HIDE_CHECK_EXISTING_ADMIN_NAME: 'HIDE_CHECK_EXISTING_ADMIN_NAME',
  ERROR_IN_CONNECTION: 'ERROR_IN_CONNECTION'
}

export const registerConst = {
  CLEAR_FORM: 'CLEAR_FORM',
}

export const verification = {
  GET_PRESENT_PROOF_REQUEST: 'GET_PRESENT_PROOF_REQUEST',
  CLEAR_FORM: 'CLEAR_FORM',
}

export const emailTemplate = {
  UPDATE_EMAIL_TEMPLATE: 'UPDATE_EMAIL_TEMPLATE',
  GET_EMAIL_PURPOSE: 'GET_EMAIL_PURPOSE',
  GET_SELECTED_EMAIL_TEMPLATE: 'GET_SELECTED_EMAIL_TEMPLATE',
  SHOW_EDIT_EMAIL_TEMPLATE: 'SHOW_EDIT_EMAIL_TEMPLATE',
  HIDE_EDIT_EMAIL_TEMPLATE: 'HIDE_EDIT_EMAIL_TEMPLATE',
}

export const workflowConst = {
  WORKFLOW_LIST: 'WORKFLOW_LIST',
  SELECT_WORKFLOW: 'SELECT_WORKFLOW',
  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  DEFAULT_CREATE_WORKFLOW: 'DEFAULT_CREATE_WORKFLOW',
  UPDATE_WORKFLOW: 'UPDATE_WORKFLOW',
  DELETE_WORKFLOW: 'DELETE_WORKFLOW',
  SHOW_WORKFLOW: 'SHOW_WORKFLOW',
}

export const issueKycCredentials = {
  ISSUE_KYC_CREDENTIALS: 'ISSUE_KYC_CREDENTIALS',
  SHOW_KYC_DETAILS_MODAL: 'SHOW_KYC_DETAILS_MODAL',
  CLOSE_KYC_DETAILS_MODAL: 'CLOSE_KYC_DETAILS_MODAL',
}

export const kycRecordList = {
  GET_ALL_KYC_RECORDS: 'GET_ALL_KYC_RECORDS',
  SELECTED_ORGANIZATION_FOR_SHOW_HOLDER: 'SELECTED_ORGANIZATION_FOR_SHOW_HOLDER',
  CLOSE_HOLDER_DETAILS_MODAL: 'CLOSE_HOLDER_DETAILS_MODAL',
}

export const onLedgerList = {
  GET_ALL_ONLEDGER_LIST: 'GET_ALL_ONLEDGER_LIST',
  STOP_ORGANIZATION: 'STOP_ORGANIZATION',
  GET_ALL_ONBOARD_ORGANIZATION_WITH_STATUS: 'GET_ALL_ONBOARD_ORGANIZATION_WITH_STATUS'
}

export const createWalletConst = {
  CREATE_WALLET: 'CREATE_WALLET',
  ERROR_AGENT_SPINUP: 'ERROR_AGENT_SPINUP',
}

export const settingUpDashboardConst = {
  DEFAULT_CRED_DEF_CREATED: 'DEFAULT_CRED_DEF_CREATED',
  UPDATE_ORG_PROFILE: 'UPDATE_ORG_PROFILE',
  SHOW_MSG_CREATE_CREDDEF: 'SHOW_MSG_CREATE_CREDDEF',
  SHOW_MSG_LOGIN_CREDDEF: 'SHOW_MSG_LOGIN_CREDDEF',
  GET_ALL_ACTIVITY_COUNT: 'GET_ALL_ACTIVITY_COUNT',
  SHOW_MSG_KYC_PASSPORT_CRED_DEF: 'SHOW_MSG_KYC_PASSPORT_CRED_DEF',
  SHOW_MSG_KYC_DRIVERS_LICENSE_CRED_DEF: 'SHOW_MSG_KYC_DRIVERS_LICENSE_CRED_DEF',
  CONNECT_WITH_PLATFORM_ADMIN: 'CONNECT_WITH_PLATFORM_ADMIN'
}

export const taskConst = {
  SHOW_INSTANT_TASK: 'SHOW_INSTANT_TASK',
  SEND_INSTANT_TASK_REQUEST: 'SEND_INSTANT_TASK_REQUEST',
  GET_ALL_INSTANT_TASK: 'GET_ALL_INSTANT_TASK',
  SHOW_PRESENT_PROOF: 'SHOW_PRESENT_PROOF',
  CLOSE_PRESENT_PROOF: 'CLOSE_PRESENT_PROOF',
  GET_ALL_CREATED_CREDDEF_BY_ORGID: 'GET_ALL_CREATED_CREDDEF_BY_ORGID',
  PRESENT_PROOF_VERIFIED: 'PRESENT_PROOF_VERIFIED',
  SHOW_SCHEDULED_TASK: 'SHOW_SCHEDULED_TASK',
  CREATE_BASIC_INFO: 'CREATE_BASIC_INFO',
  CREATE_BASIC_INFO_FAIL: 'CREATE_BASIC_INFO_FAIL',
  UPDATE_SCHEDULE_TASK: 'UPDATE_SCHEDULE_TASK',
  CLEAR_INSTANT_TASK_FORM: 'CLEAR_INSTANT_TASK_FORM',
  CLEAR_SCHEDULE_TASK_FORM: 'CLEAR_SCHEDULE_TASK_FORM',
  GET_LIST_BY_SELECTED_WORK_FLOW: 'GET_LIST_BY_SELECTED_WORK_FLOW',
  GET_ALL_CREATED_CREDDEF_FOR_INSTANT_TASK: 'GET_ALL_CREATED_CREDDEF_FOR_INSTANT_TASK',
  HIDE_INSTANT_TASK: 'HIDE_INSTANT_TASK',
  SHOW_INSTANT_TASK_LIST: 'SHOW_INSTANT_TASK_LIST',
  SHOW_DETAILS_OF_SCHEDULED_TASK: 'SHOW_DETAILS_OF_SCHEDULED_TASK',
  CHECK_VERIFICATION_STATUS: 'CHECK_VERIFICATION_STATUS',
  SHOW_NORMAL_TABLE: 'SHOW_NORMAL_TABLE',
  SELECTED_CREDEF_OPTION_FOR_ATTRIBUTES: 'SELECTED_CREDEF_OPTION_FOR_ATTRIBUTES',
  CLEAR_FORM: 'CLEAR_FORM',
  GET_SINGLE_CRED_DEF_DETAILS: 'GET_SINGLE_CRED_DEF_DETAILS',
  CLEAR_SELECTED_ISSUER_ORGANIZATION: 'CLEAR_SELECTED_ISSUER_ORGANIZATION',
  GET_ALL_SCHEMA_FOR_INSTANT_TASK: 'GET_ALL_SCHEMA_FOR_INSTANT_TASK',
  GET_SINGLE_SCHEMA_DETAILS: 'GET_SINGLE_SCHEMA_DETAILS',
  ORGANIZATIONS_LIST: 'ORGANIZATIONS_LIST',
  CREDENTIAL_DEFINITION_LIST_BY_ORGANIZATION: 'CREDENTIAL_DEFINITION_LIST_BY_ORGANIZATION',
  GET_ALL_AUTOMATED_PRESENTATIONS: 'GET_ALL_AUTOMATED_PRESENTATIONS',
  SHOW_AUTOMATED_PROOFS: 'SHOW_AUTOMATED_PROOFS',
}

export const issuersVerifiersConstants = {
  GET_ALL_EMPLOYEES: 'GET_ALL_EMPLOYEES',
  ADD_ISSUER_VERIFIER_SUCCESS: 'ADD_ISSUER_VERIFIER_SUCCESS',
  ISSUE_LOGIN_CREDENTIAL: 'ISSUE_LOGIN_CREDENTIAL',
  ISSUE_LOGIN_CREDENTIAL_FAILED: 'ISSUE_LOGIN_CREDENTIAL_FAILED',
  CLEAR_ISSUER_VERIFIER_FORM_DATA: 'CLEAR_ISSUER_VERIFIER_FORM_DATA',
  ADD_ANOTHER_ISSUER_VERIFIER: 'ADD_ANOTHER_ISSUER_VERIFIER',
  GET_ALL_ROLES: 'GET_ALL_ROLES',
  GET_ALL_INTERNAL_ISSUER_VERIFIER: 'GET_ALL_INTERNAL_ISSUER_VERIFIER',
  SELECTED_INTERNAL_ISSUER_VERIFIER: 'SELECTED_INTERNAL_ISSUER_VERIFIER',
  EDIT_INTERNAL_ISSUER_VERIFIER: 'EDIT_INTERNAL_ISSUER_VERIFIER',
  UPDATE_ISSUER_VERIFIER: 'UPDATE_ISSUER_VERIFIER',
  VIEW_INTERNAL_ISSUER_VERIFIER: 'VIEW_INTERNAL_ISSUER_VERIFIER',
  LIST_VIEW_INTERNAL_ISSUER_VERIFIER: 'LIST_VIEW_INTERNAL_ISSUER_VERIFIER',
}

export const agentStatus = {
  GET_ALL_AGENT_STATUS_LIST: 'GET_ALL_AGENT_STATUS_LIST',
  UPDATE_PAGE_NO: 'UPDATE_PAGE_NO',
}

export const platformConfig = {
  GET_PLATFORM_CONFIG_DETAILS: 'GET_PLATFORM_CONFIG_DETAILS'
}

export const govRule = {
  GET_ALL_RULE_LIST: 'GET_ALL_RULE_LIST',
  UPDATE_PAGE_NO: 'UPDATE_PAGE_NO',
  SELECTED_GOV_RULE: 'SELECTED_GOV_RULE',
  SAVE_NEW_RULE: 'SAVE_NEW_RULE',
  CLEAR_SELECTED_RULE: 'CLEAR_SELECTED_RULE',
  OPEN_SAVE_BUTTON: 'OPEN_SAVE_BUTTON',
  AFTER_SAVE_RULE: 'AFTER_SAVE_RULE'
}

export const loginMonitoringConst = {
  GET_LOGIN_MONITORING_LIST: 'GET_LOGIN_MONITORING_LIST',
}

export const dashboardConst = {
  GET_ALL_DASHBOARD_COUNT: 'GET_ALL_DASHBOARD_COUNT',
  GET_ALL_PA_DASHBOARD_COUNT: 'GET_ALL_PA_DASHBOARD_COUNT',
}

export const myCredential = {
  GET_ALL_CREDENTIAL_OFFER: "GET_ALL_CREDENTIAL_OFFER",
  SHOW_SELECTED_CREDENTIAL_DETAILS: "SHOW_SELECTED_CREDENTIAL_DETAILS",
  ACCEPT_CREDENTIAL_OFFER: "ACCEPT_CREDENTIAL_OFFER",
  RE_PROPOSE_CREDENTIAL: 'RE_PROPOSE_CREDENTIAL',
  CREDENTIAL_PROBLEM_REPORTED: 'CREDENTIAL_PROBLEM_REPORTED'
}

export const credentialNegotiate = {
  GET_ALL_NEGOTIATE_CREDENTIALS_RECORDS: "GET_ALL_NEGOTIATE_CREDENTIALS_RECORDS",
  SELECTED_NEGOTIATE_CREDENTIAL_RECORD: "SELECTED_NEGOTIATE_CREDENTIAL_RECORD",
  ACCEPT_CREDENTIAL_OFFER: "ACCEPT_CREDENTIAL_OFFER",
  RE_PROPOSE_CREDENTIAL: 'RE_PROPOSE_CREDENTIAL',
  CREDENTIAL_OFFER_SENT: 'CREDENTIAL_OFFER_SENT',
  UPDATE_TRUST_REGISTRY_RESULT_STATE: 'UPDATE_TRUST_REGISTRY_RESULT_STATE'
}

export const presentProof = {
  GET_ALL_PRESENT_PROOF_REQUEST_LIST: "GET_ALL_PRESENT_PROOF_REQUEST_LIST",
  GET_ALL_AVAILABLE_CREDENTIAL_LIST: "GET_ALL_AVAILABLE_CREDENTIAL_LIST",
  SHOW_SELECTED_PROOF_REQUEST_DETAILS: "SHOW_SELECTED_PROOF_REQUEST_DETAILS",
  SHOW_SELECTED_CREDENTIAL_FOR_PROOF_DETAILS: "SHOW_SELECTED_CREDENTIAL_FOR_PROOF_DETAILS",
  SEND_PROOF_REQUEST: "SEND_PROOF_REQUEST",
  CLOSE_SUCCESS: "CLOSE_SUCCESS",
  FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST: 'FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST',
  // RE_PROPOSE_PROOF_REQUEST: 'RE_PROPOSE_PROOF_REQUEST',
  DECLINED_PROOF_REQUEST: 'DECLINED_PROOF_REQUEST',
  CLOSE_DECLINE_SENT: 'CLOSE_DECLINE_SENT'
}

export const presentationProposalConstants = {
  GET_ALL_PRESENTATION_PROPOSAL_LIST: 'GET_ALL_PRESENTATION_PROPOSAL_LIST',
  CREATE_PRESENTATION_PROPOSAL: 'CREATE_PRESENTATION_PROPOSAL',
  SHOW_NEW_PROPOSAL: 'SHOW_NEW_PROPOSAL',
  CREATE_NEW_PROPOSAL: 'CREATE_NEW_PROPOSAL',
  SELECTED_PRESENTATION_PROPOSAL_RECORD: 'SELECTED_PRESENTATION_PROPOSAL_RECORD'
}

export const presentationRequestConstants = {
  GET_ALL_PRESENTATION_REQUEST_LIST: 'GET_ALL_PRESENTATION_REQUEST_LIST',
  CREATE_PRESENTATION_PROPOSAL: 'CREATE_PRESENTATION_PROPOSAL',
  SHOW_NEW_REQUEST: 'SHOW_NEW_REQUEST',
  CREATE_NEW_REQUEST: 'CREATE_NEW_REQUEST',
  SELECTED_PRESENTATION_REQUEST_RECORD: 'SELECTED_PRESENTATION_REQUEST_RECORD'
}

export const basicMessage = {
  CHAT_DETAILS: "CHAT_DETAILS",
  SELECTED_CONNECTION: 'SELECTED_CONNECTION',
  SHOW_SELECTED_CONNECTION: 'SHOW_SELECTED_CONNECTION',
  CLOSE_AVAILABLE_CREDENTIALS: 'CLOSE_AVAILABLE_CREDENTIALS',
  CLAIM_CREDENTIAL_LIST: 'CLAIM_CREDENTIAL_LIST',
  SHOW_SELECTED_CLAIM_CREDENTIAL_DETAILS: 'SHOW_SELECTED_CLAIM_CREDENTIAL_DETAILS',
  ISSUE_CLAIM_CREDENTIAL: 'ISSUE_CLAIM_CREDENTIAL',
  CLOSE_SELECTED_CLAIM_CREDENTIAL_DETAILS: 'CLOSE_SELECTED_CLAIM_CREDENTIAL_DETAILS',
  SEND_BASIC_MESSAGE: "SEND_BASIC_MESSAGE",
  GET_ALL_CONNECTION_WITH_BASIC_MESSAGE: "GET_ALL_CONNECTION_WITH_BASIC_MESSAGE",
  READ_UNREAD_MESSAGE: 'READ_UNREAD_MESSAGE',
  REMOVE_CHAT_DETAILS: 'REMOVE_CHAT_DETAILS',
  UPDATE_BASIC_MESSAGE_CONNECTION_LIST: 'UPDATE_BASIC_MESSAGE_CONNECTION_LIST',
  HIGHLIGHT_REFRESH_BUTTON: 'HIGHLIGHT_REFRESH_BUTTON'
}

export const importCredentials = {
  GET_IMPORT_CREDENTIAL_LIST: "GET_IMPORT_CREDENTIAL_LIST",
  ADD_NEW_IMPORT_CREDENTIALS_RECORD: "ADD_NEW_IMPORT_CREDENTIALS_RECORD",
  IMPORT_ANOTHER_CREDENTIAL: 'IMPORT_ANOTHER_CREDENTIAL',
  SHOW_SELECTED_IMPORT_CREDENTIAL_RECORD: 'SHOW_SELECTED_IMPORT_CREDENTIAL_RECORD',
  REMOVE_SELECTED_IMPORT_CREDENTIAL_RECORD: 'REMOVE_SELECTED_IMPORT_CREDENTIAL_RECORD'
}
export const chats = {
  CHATS_CONNECTION_LIST: 'CHATS_CONNECTION_LIST',
  SELECT_NEW_CONNECTION: 'SELECT_NEW_CONNECTION',
  CHATS_SEND_BASIC_MESSAGE: 'CHATS_SEND_BASIC_MESSAGE',
  UPDATE_UNREAD_MESSAGE_STATUS: 'UPDATE_UNREAD_MESSAGE_STATUS',
  ON_NOTIFICATION_UPDATE_LIST: 'ON_NOTIFICATION_UPDATE_LIST',
  UNMOUNT_FROM_CHATS_DETAILS: 'UNMOUNT_FROM_CHATS_DETAILS',
  HIGHLIGHT_NOTIFICATION_STATUS: 'HIGHLIGHT_NOTIFICATION_STATUS',
  IMPORT_CLAIM_CREDENTIAL_LIST: 'IMPORT_CLAIM_CREDENTIAL_LIST',
  SELECTED_CLAIM_CREDENTIAL_DETAILS: 'SELECTED_CLAIM_CREDENTIAL_DETAILS',
  ISSUE_AVAILABLE_CLAIM_CREDENTIAL: 'ISSUE_AVAILABLE_CLAIM_CREDENTIAL',
  GET_MESSAGES_BY_CONNECTION: 'GET_MESSAGES_BY_CONNECTION'
}

export const credentialProposal = {
  GET_ALL_CREDENTIAL_PROPOSAL_LIST: 'GET_ALL_CREDENTIAL_PROPOSAL_LIST',
  CREATE_CREDENTIAL_PROPOSAL: 'CREATE_CREDENTIAL_PROPOSAL',
  SHOW_NEW_PROPOSAL: 'SHOW_NEW_PROPOSAL',
  CREATE_NEW_PROPOSAL: 'CREATE_NEW_PROPOSAL',
  CLEAR_SCHEMA: 'CLEAR_SCHEMA',
  CLEAR_CREDENTIAL_PROPOSAL: 'CLEAR_CREDENTIAL_PROPOSAL',
  SUCCESS_PROPOSE_CREDENTIAL: 'SUCCESS_PROPOSE_CREDENTIAL',
  CREATE_ANOTHER_CREDENTIAL_PROPOSAL: 'CREATE_ANOTHER_CREDENTIAL_PROPOSAL',
  PROPOSED_CREDENTIAL: 'PROPOSED_CREDENTIAL'
}

export const credentialNegotiationConstants = {
  SHOW_SELECTED_PROPOSAL_RECEIVED_RECORD: 'SHOW_SELECTED_PROPOSAL_RECEIVED_RECORD',
  SHOW_NEGOTIATE_PROPOSAL_MODAL: 'SHOW_NEGOTIATE_PROPOSAL_MODAL',
  CLOSE_NEGOTIATE_PROPOSAL_MODAL: 'CLOSE_NEGOTIATE_PROPOSAL_MODAL',
}

export const notificationConstants = {
  GET_ALL_NOTIFICATIONS: 'GET_ALL_NOTIFICATION',
  GET_COUNT_NOTIFICATIONS: 'GET_COUNT_NOTIFICATIONS',
  SELECTED_NOTIFICATION: 'SELECTED_NOTIFICATION',
  NOTIFICATION_DELETED: 'NOTIFICATION_DELETED',
  NOTIFICATION_READ: 'NOTIFICATION_READ',

  NOTIFICATION_CONNECTION: 'Connection',
  NOTIFICATION_CREDENTIAL: 'Credential',
  NOTIFICATION_PROOF: 'Proof',
  NOTIFICATION_BASIC_MESSAGE: 'Basic Message',

  SOCKET: 'Socket',
  LIST_VIEW_NOTIFICATION: 'LIST_VIEW_NOTIFICATION',
  HIGHLIGHT_NOTIFICATION_STATUS: 'HIGHLIGHT_NOTIFICATION_STATUS',
}

export enum myCredentials {
  all = 'All',
  offer_received = 'Pending',
  credential_acked = 'Accepted',
  stored = 'Stored'
}

export enum offerNegotiateInitiator {
  offer = 'offer',
  negotiate = 'negotiate'
}

export enum RoleType {
  PLATFORM_ADMIN = '1',
  ORG_ADMIN = '2',
  ISSUER = '3',
  VERIFIER = '4',
  ISSUER_VERIFIER = '5',
  CLIENT = '6',
  NONE = '7'
}

export const revokeCredentialConstants = {
  GET_ALL_ISSUED_CREDENTIALS_RECORDS: 'GET_ALL_ISSUED_CREDENTIALS_RECORDS',
  SELECTED_ISSUED_CREDENTIAL_RECORD: 'SELECTED_ISSUED_CREDENTIAL_RECORD',
  SELECTED_RECEIVED_CREDENTIAL: 'SELECTED_RECEIVED_CREDENTIAL'
}

export const credentialOfferConstants = {
  GET_ALL_CREDENTIAL_OFFER_LIST: 'GET_ALL_CREDENTIAL_OFFER_LIST',
  CREATE_CREDENTIAL_OFFER: 'CREATE_CREDENTIAL_OFFER',
  SHOW_NEW_OFFER: 'SHOW_NEW_OFFER',
  CREATE_NEW_OFFER: 'CREATE_NEW_OFFER',
  CLEAR_SCHEMA: 'CLEAR_SCHEMA',
  CLEAR_CREDENTIAL_OFFER: 'CLEAR_CREDENTIAL_OFFER',
  SUCCESS_OFFER_CREDENTIAL: 'SUCCESS_OFFER_CREDENTIAL',
  CREATE_ANOTHER_CREDENTIAL_OFFER: 'CREATE_ANOTHER_CREDENTIAL_OFFER',
  OFFERED_CREDENTIAL: 'OFFERED_CREDENTIAL'
}

export const presentationNegotiateConstants = {
  DECLINED_PRESENTATION_REQUEST: 'DECLINED_PRESENTATION_REQUEST',
  FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST: 'FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST',
  SEND_PROOF_REQUEST: 'SEND_PROOF_REQUEST',
  CLOSE_SUCCESS: 'CLOSE_SUCCESS',
  PRESENT_PROOF_VERIFIED: 'PRESENT_PROOF_VERIFIED',
  GET_ALL_PRESENTATION_LIST: 'GET_ALL_PRESENTATION_LIST',
  SELECTED_NEGOTIATE_RECORD: 'SELECTED_NEGOTIATE_RECORD',
  RE_PROPOSE_PROOF_REQUEST: 'RE_PROPOSE_PROOF_REQUEST'
}

export const orgProfileConstants = {
  SET_DID_DETAILS: 'SET_DID_DETAILS',
  SET_DNS_ENTRIES: 'SET_DNS_ENTRIES',
  SET_ISSUANCE_AUTHORITY: 'SET_ISSUANCE_AUTHORITY',
  CHECK_DID_AUTHENTIC: 'CHECK_DID_AUTHENTIC',
  SET_PUBLIC_DNS_ENTRIES: 'SET_PUBLIC_DNS_ENTRIES'
}

export const multiuseConnectionConst = {
  SEND_MULTI_INVITATION: 'SEND_MULTI_INVITATION',
  GET_MULTIUSE_QR: 'GET_MULTIUSE_QR',
  CREATE_MULTIUSE: 'CREATE_MULTIUSE',
  GET_MULTIUSE_LIST: 'GET_MULTIUSE_LIST',
  SELECT_MULTIUSE: 'SELECT_MULTIUSE',
  MULTIUSE_SENT: 'MULTIUSE_SENT',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  SHOW_NEW_INVITE: 'SHOW_NEW_INVITE',
  INVITE_NEW_MULTIUSE: 'INVITE_NEW_MULTIUSE',
  CHECK_EXISTING_MULTIUSE_EMAILID: 'CHECK_EXISTING_MULTIUSE_EMAILID',
  HIDE_CHECK_EXISTING_MULTIUSE_EMAILID: 'HIDE_CHECK_EXISTING_MULTIUSE_EMAILID',
  ERROR_IN_CONNECTION: 'ERROR_IN_CONNECTION',
  HIDE_MULTIUSE_QR: 'HIDE_MULTIUSE_QR',
}