import { t } from 'i18next';

export const revokeCredentialTableHeadings = [{
  'name': `${t("screens:revokeCredential.headDate")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:revokeCredential.headRevokeDateTime")}`,
  'label': "revokedDateTime"
}, {
  'name': `${t("screens:revokeCredential.headGivenName")}`,
  'label': "connectionName"
}, {
  'name': `${t("screens:revokeCredential.headCredentialName")}`,
  'label': "credentialDefinitionId"
}, {
  'name': `${t("screens:revokeCredential.headStatus")}`,
  'label': "state"
}, {
  'name': `${t("screens:revokeCredential.headAction")}`,
  'label': 'revokeViewBtn'
}];
