import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { credentialAction } from '../../Credentials/actions/credentialAction';
import { TaskActions } from '../../Task/actions/taskActions';
import { IssuerActions } from '../actions/issuerActions';
import AddIssuerVerifier from '../components/AddIssuerVerifier';

export interface IIssuerVerifierProps {
    IssuerActions: any,
    IssuersReducers: any,
    credentialAction: any,
    CredentialReducer: any,
    TaskActions: any,
    TaskReducers: any,
    LoginReducer: any
}

export interface IIssuerVerifierState {
}

class IssuerVerifier extends React.Component<IIssuerVerifierProps, IIssuerVerifierState> {
  constructor(props: IIssuerVerifierProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <>
       <AddIssuerVerifier {...this.props}/> 
      </>
    );
  }
}

function mapStateToProps(state:any) {
    const IssuersReducers = state.IssuersReducers;
    const CredentialReducer = state.CredentialReducer;
    const TaskReducers = state.TaskReducers;
    const LoginReducer = state.LoginReducer
    return{
        IssuersReducers, CredentialReducer,TaskReducers, LoginReducer
    }
}

function mapDispatchToProps(dispatch:any) {
    return {
        IssuerActions: bindActionCreators(IssuerActions, dispatch),
        credentialAction: bindActionCreators(credentialAction, dispatch),
        TaskActions: bindActionCreators(TaskActions, dispatch),
    }
}

const connectedIssuerVerifier = connect(mapStateToProps, mapDispatchToProps) (IssuerVerifier);
export { connectedIssuerVerifier as IssuerVerifier }
