import React, { ReactElement, useState } from "react";

// Components
import HelpGuideModal from "src/Common Components/Components/HelpGuide/HelpGuideModal";

// Delay modal slightly to allow previous modal to close (animate) properly
const MODAL_OPEN_DELAY = 200;

interface IHelpGuideState {
  content: string | null;
  title?: string;
}

type HelpGuideOptions = Partial<IHelpGuideState>;

export interface IHelpGuideContext {
  /** Close help guide */
  closeGuide: () => void;
  /** Show help guide */
  showGuide: (content: string | null, options?: HelpGuideOptions) => void;
}

type HelpGuideProviderProps = {
  children?: ReactElement | ReactElement[];
};

// @ts-ignore - Will be set by context provider
const HelpGuideContext = React.createContext<IHelpGuideContext>({});

const HelpGuideProvider = (props: HelpGuideProviderProps): ReactElement => {
  const { children } = props;

  const [helpGuide, setHelpGuide] = useState<IHelpGuideState | null>(null);

  /**
   * Close the help guide
   */
  const closeGuide = () => {
    setHelpGuide(null);
  }

  /**
   * Open the help guide
   *
   * @param content - Help guide content
   * @param options - Snackbar options
   */
  const showGuide = (content: string | null, options: HelpGuideOptions = {}): void => {
    // Close the previous help guide
    closeGuide();

    // Use short timeout to allow close animation to finish
    setTimeout(() => {
      setHelpGuide({ content, ...options });
    }, MODAL_OPEN_DELAY);
  };

  return (
    <HelpGuideContext.Provider
      value={{ closeGuide, showGuide }}
    >
      {children}
      <HelpGuideModal
        content={helpGuide?.content}
        open={Boolean(helpGuide)}
        title={helpGuide?.title}
        onClose={closeGuide}
      />
    </HelpGuideContext.Provider>
  );
};

export { HelpGuideContext, HelpGuideProvider };
