import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { LoginMonitoringListAction } from '../actions/LoginMonitoringListAction';
import { filterLoginMonitoringList, tHeadLoginMonitoringList } from '../constants';

export interface ILoginMonitoringListProps {
  LoginMonitoringListAction: any,
  LoginMonitoringListReducer: any,
}

export interface ILoginMonitoringListState {
  pageNo: number,
  item_perPage: number,
  defaultSearchFields: any,
  loginMonitoringStatus: any,
  loginMonitoringCredSort: string,
  sortValue: string,
}

class LoginMonitoringList extends React.Component<ILoginMonitoringListProps, ILoginMonitoringListState> {
  constructor(props: ILoginMonitoringListProps) {
    super(props);

    this.state = {
      pageNo: 1,
      item_perPage: 10,
      defaultSearchFields: '',
      loginMonitoringStatus: 'all',
      loginMonitoringCredSort: '',
      sortValue: 'DESC',
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sorting = this.sorting.bind(this);
    this.filter = this.filter.bind(this);
  }

  componentDidMount() {
    const { pageNo, item_perPage, defaultSearchFields, loginMonitoringStatus, loginMonitoringCredSort, sortValue } = this.state;
    this.props.LoginMonitoringListAction.getAllLoginMonitoringList(
      pageNo,
      item_perPage,
      defaultSearchFields,
      loginMonitoringStatus,
      loginMonitoringCredSort,
      sortValue,
    );
  }

  onChangePage(page: any) {
    const { item_perPage, defaultSearchFields, loginMonitoringStatus, loginMonitoringCredSort, sortValue } = this.state;
    this.props.LoginMonitoringListAction.getAllLoginMonitoringList(
      page,
      item_perPage,
      defaultSearchFields,
      loginMonitoringStatus,
      loginMonitoringCredSort,
      sortValue,
    );
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { pageNo, item_perPage, loginMonitoringStatus, loginMonitoringCredSort, sortValue } = this.state;
    this.setState({ defaultSearchFields: event.target.value })
    if (event.key === 'Enter') {
      this.props.LoginMonitoringListAction.getAllLoginMonitoringList(
        pageNo,
        item_perPage,
        event.target.value,
        loginMonitoringStatus,
        loginMonitoringCredSort,
        sortValue,
      );
    }

  }

  sorting = (kycRecordListSort: string) => {
    const { pageNo, item_perPage, defaultSearchFields, loginMonitoringStatus, loginMonitoringCredSort, sortValue } = this.state;
    this.state.sortValue === 'DESC' ? this.setState({ sortValue: 'ASC', loginMonitoringCredSort: kycRecordListSort }) : this.setState({ sortValue: 'DESC', loginMonitoringCredSort: kycRecordListSort });
    this.props.LoginMonitoringListAction.getAllLoginMonitoringList(
      pageNo,
      item_perPage,
      defaultSearchFields,
      loginMonitoringStatus,
      loginMonitoringCredSort,
      sortValue,
    );
  }

  refresh = (event: any) => {
    let { pageNo, item_perPage, defaultSearchFields, loginMonitoringStatus, loginMonitoringCredSort, sortValue } = this.state;
    this.props.LoginMonitoringListAction.getAllLoginMonitoringList(
      pageNo,
      item_perPage,
      defaultSearchFields,
      loginMonitoringStatus,
      loginMonitoringCredSort,
      sortValue,
    );
  }

  closeModal = () => {
  }

  async filter(selectedFilterValue: any) {
    let { pageNo, item_perPage, defaultSearchFields, loginMonitoringCredSort, sortValue } = this.state;

    this.setState({ loginMonitoringStatus: selectedFilterValue })

    await this.props.LoginMonitoringListAction.getAllLoginMonitoringList(
      pageNo,
      item_perPage,
      defaultSearchFields,
      selectedFilterValue,
      loginMonitoringCredSort,
      sortValue,
    );
  }

  public render() {
    const { loginMonitoringList, totalLoginMonitoring, selectedLoginMonitoringRecord } = this.props.LoginMonitoringListReducer;
    return (
      <div>
        <div className="card nb-card-noborder shaodw">
          <div className="card-header bg-white">
            <h1>LOGIN MONITORING</h1>
          </div>
          <div className="card-body">

            <TableComponents
              onChangePage={this.onChangePage}
              // showElementDetails={this.showDetails}
              tHead={tHeadLoginMonitoringList}
              tableContent={loginMonitoringList}
              totalRecords={totalLoginMonitoring}
              selectedRecord={selectedLoginMonitoringRecord}
              // sorting={this.sorting}
              searching={this.onSearch}
              filter={filterLoginMonitoringList}
              onChangeFilter={this.filter}
              refresh={this.refresh}
            />

          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const LoginMonitoringListReducer = state.LoginMonitoringListReducer
  return { LoginMonitoringListReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    LoginMonitoringListAction: bindActionCreators(LoginMonitoringListAction, dispatch),
  }
}

const connectedLoginMonitoringList = connect(mapStateToProps, mapDispatchToProps)(LoginMonitoringList);
export { connectedLoginMonitoringList as LoginMonitoringList };
