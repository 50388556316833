import axios from "axios"
import { loaderConst, onLedgerList } from "../../types"
import { config } from "../../../config";
import { toastr } from "react-redux-toastr";
import { loginAction } from "../../Login/actions/loginAction";
import _ from "lodash";

export const OnLedgerListAction = {
  getAllOnLedgerList() {
    return async (dispatch: any) => {
      dispatch({
        type: onLedgerList.GET_ALL_ONLEDGER_LIST,
      })
    }
  },

  getAllOnBoardedOrganizationList(
    defaultSearchFields: any,
    status: any,
    kycRecordListSortBy: string,
    sortingOrder: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/organization/with-running-status?search_text=${defaultSearchFields}&organizationRunningStatus=${status}&onboardRequestSort=${kycRecordListSortBy}&sortValue=${sortingOrder}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            let OnboardingList = (!_.isEmpty(data) && data.data && data.data.data && data.data.data.data);
            OnboardingList.forEach((element: any, index: any) => {
              OnboardingList[index].imageUrl = element.logoUrl;
            });
            dispatch({
              type: onLedgerList.GET_ALL_ONBOARD_ORGANIZATION_WITH_STATUS,
              payload: data,
              OnboardingList,
            })

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch((error: { response: any; }) => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  stopOrganization(
    organizationId: number,
    defaultSearchFields: any,
    status: any,
    kycRecordListSortBy: string,
    sortingOrder: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Api call for stopping the organization */
        return await axios.post(`${config.apiUrl}/admin/stop/organization-container/${organizationId}`, "",
          { headers: { "Authorization": `Bearer ${token}` } })
          .then((stopOrganizationResponse: any) => {
            toastr.success(`Organization stopped`, `${stopOrganizationResponse.data.message}`);
            this.getAllOnBoardedOrganizationList(defaultSearchFields, status, kycRecordListSortBy, sortingOrder, item_perPage, pageNo)
            dispatch({
              type: onLedgerList.STOP_ORGANIZATION,
              payload: stopOrganizationResponse.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })

      } catch (error) {
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        throw error
      }
    }
  },

  startOrganization(
    organizationId: number,
    defaultSearchFields: any,
    status: any,
    kycRecordListSortBy: string,
    sortingOrder: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Api call for stopping the organization */
        return await axios.post(`${config.apiUrl}/admin/start/organization-container/${organizationId}`, "",
          { headers: { "Authorization": `Bearer ${token}` } })
          .then((stopOrganizationResponse: any) => {
            toastr.success(`Organization started`, `${stopOrganizationResponse.data.message}`);
            this.getAllOnBoardedOrganizationList(defaultSearchFields, status, kycRecordListSortBy, sortingOrder, item_perPage, pageNo)
            dispatch({
              type: onLedgerList.STOP_ORGANIZATION,
              payload: stopOrganizationResponse.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })

      } catch (error) {
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        throw error
      }
    }
  },

  removeOrganization(
    organizationId: number,
    defaultSearchFields: any,
    status: any,
    kycRecordListSortBy: string,
    sortingOrder: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Api call for stopping the organization */
        return await axios.post(`${config.apiUrl}/admin/remove/organization-container/${organizationId}`, "",
          { headers: { "Authorization": `Bearer ${token}` } })
          .then((stopOrganizationResponse: any) => {
            toastr.success(`Organization removed`, `${stopOrganizationResponse.data.message}`);
            this.getAllOnBoardedOrganizationList(defaultSearchFields, status, kycRecordListSortBy, sortingOrder, item_perPage, pageNo)
            dispatch({
              type: onLedgerList.STOP_ORGANIZATION,
              payload: stopOrganizationResponse.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })

      } catch (error) {
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        throw error
      }
    }
  }
}