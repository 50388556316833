import { t } from 'i18next';

 export const tHeadNotification = [{
    'name': `${t("screens:notification.headDateCreated")}`,
    'label': "createDateTime"
  }, {
    'name': `${t("screens:notification.headType")}`,
    'label': "notificationType"
  }, {
    'name': `${t("screens:notification.headMessage")}`,
    'label': "notificationMessage"
  }, {
    'name': `${t("screens:notification.headSenderOrgId")}`,
    'label': "senderOrgLabel"
  }, {
    'name': `${t("screens:notification.headRead")}`,
    'label': "isRead"
  },  {
    'name': `${t("screens:notification.headAction")}`,
    'label': "details" 
  },  {
    'name': `${t("screens:notification.headDelete")}`,
    'label': "delete"  
}];

  

export const filterNotifications = [{
  'name': `${t("screens:notification.constAll")}`,
  'tag' : 'all'
}, {
  'name': `${t("screens:notification.constRevocable")}`,
  'tag' : 'revoke'
}, {
  'name': `${t("screens:notification.constNonRevocable")}`,
  'tag' : 'nonRevoke'
}
];
