import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { showQRCodeConstant } from "../../../commonConst";

/* Interface for Props variables*/
interface IShowQRCodeProps extends RouteComponentProps {

}
/* Interface for local states variables*/
interface IShowQRCodeState {
    qrCodeDetails: any
}

class ShowQRCode extends React.Component<IShowQRCodeProps, IShowQRCodeState> {
    constructor(props: IShowQRCodeProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            qrCodeDetails: {}
        }
    }
    /* Default lifecycle method to get QRCode details and decode in base64 */
    componentDidMount() {
        const { details }: any = this.props.match.params
        const stringToBase64 = JSON.parse(Buffer.from(details, 'base64').toString('ascii'))
        this.setState({ qrCodeDetails: stringToBase64 })
    }
    public render() {
        const { qrCodeDetails } = this.state
        return (
            <>
                <div className="container-fluid h-100 login-bg">
                    <div className="row h-100">
                        <div className="col-md-12 offset-lg-3 col-lg-6 align-self-center">
                            <div className="card h-100">
                                <div className="card-header bg-white border-bottom-0">
                                    <h1 className="nb-title">{showQRCodeConstant.showQRCodePageHeader}.</h1>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="nb-label">{showQRCodeConstant.connectionMessage} <span className="show-organization-name">{qrCodeDetails.organizationName}</span>.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-sm-12 d-flex justify-content-center mt-5">
                                            {qrCodeDetails.connectionInvitation &&
                                                <img src={qrCodeDetails.connectionInvitation} alt='qrCode' />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}


const showQRCode = connect()(ShowQRCode);
export { showQRCode as ShowQRCode };
