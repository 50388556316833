import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { version, config } from '../../../config';
// import { messaging } from '../../../init-fcm';
import { loginAction } from '../actions/loginAction';
import { history } from '../../../index';
import { toastr } from 'react-redux-toastr';
import { withTranslation, WithTranslation } from 'react-i18next';
import io from "socket.io-client";

// Utilities
import images from "src/utils/images";

const { detect } = require('detect-browser');
const browser = detect();
// const message: any = messaging;

/* Interface for props values */

export interface IPlatformAdminLoginProps extends WithTranslation<["admin"]> {
  loginAction: any,
  user: any,
  LoginReducer: any,
  LoaderReducer: any,
}
/* Interface for local state values */
export interface IPlatformAdminLoginState {
  loginData: any,
  pushNotificationToken: any,
  // fireBaseWarn: boolean,
  // fireBaseWarnMessage: string,
  // fireBaseWarnColor: string,
  errors: any
}

class PlatformAdminLogin extends React.Component<IPlatformAdminLoginProps, IPlatformAdminLoginState> {
  constructor(props: IPlatformAdminLoginProps) {
    super(props);

    /* Initialization of local state variables. */
    this.state = {
      loginData: {},
      errors: {},
      pushNotificationToken: '',
      // fireBaseWarn: false,
      // fireBaseWarnMessage: '',
      // fireBaseWarnColor: ''
    }
    /* Bind all the methods. */
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.connectSocket()
  }

  async connectSocket() {
    // const socket = io('https://nbsocketapi.nborbit.io');
    const socket = io(config.apiUrl);
    if (socket) {
      // if (socket.connected) {
      console.log("Login Platform Admin ---- SOCKET Connection estalished (io) ")

      socket.on('connect', async () => {
        console.log('*** SOCKET LOGIN - Connected (socket.on)');
        this.setState({ pushNotificationToken: socket.id });
        this.props.loginAction.getCurrentUser();
      })
    } else {
      console.log("Could not estalished a connection")
    }
  }
  /**
   * Method used for getting the login form values and set it in state variable
   * @param event 
   */
  handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    let formData = this.state.loginData;
    formData[event.target.name] = event.target.value;
    this.setState({
      loginData: formData
    });
    /* Method call for validate the typed value in login form */
    this.validateForm();
  }

  /**
   * Method used to validate the form field.  
   * @returns Validate the form field and return the error message or value.
   */
  validateForm() {
    let formData: any = this.state.loginData;
    let errors: any = {};
    let formIsValid: any = true;

    if (typeof formData["username"] !== "undefined") {
      //regular expression for username validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(formData["username"])) {
        formIsValid = false;
        errors["username"] = "*Please enter valid email-ID.";
      }
    }

    if (!formData["password"] && typeof formData["password"] !== "undefined") {
      formIsValid = false;
      errors["password"] = "*Please enter Password.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  /**
   * Method used to calling the login action
   * @param event 
   */
  handleSubmit = (event: React.FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();
    const { username, password } = this.state.loginData;
    const { pushNotificationToken } = this.state;
    if (username && username !== '' && password && password !== '') {
      this.props.loginAction.loginAction(username, password, pushNotificationToken);
      console.log(`username: ${username} password: ${password} pushNotificationToken: ${pushNotificationToken}`)
    }
    this.setState({
      errors: {}
    })
  }

  public render() {
    const { t } = this.props;
    const { loggedInUser } = this.props.LoginReducer;
    const { loginData, errors } = this.state;

    /* Checking the different status of logged in user and based on that redirect to next page */
    if (!_.isEmpty(loggedInUser) && localStorage.length > 0) {

      if (loggedInUser.role.id !== 1 && loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isOnBoarded === 1) {
        return (<>{history.push('/pending-state')}</>)
      } else if (loggedInUser.role.id !== 1 && loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isOnBoarded === 0) {
        toastr.message(`Complete Your Registration Process`, `Please go to first time login`)
      } else {
        if (loggedInUser.role.id === 1) {
          return (<>{history.push('/platformAdmin-dashboard')}</>)
        } else if (loggedInUser.role.id === 2) {
          if (loggedInUser.organization.isOnBoarded === 2 && loggedInUser.organization.agentSpinupStatus === 1 && loggedInUser.organization.isDashboard === false) {
            return (<>{history.push('/create-wallet')}</>)
          } else
            if (loggedInUser.organization.isOnBoarded === 2 && loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isDashboard === false) {
              return (<>{history.push('/create-wallet')}</>)
            } else if (loggedInUser.organization.agentSpinupStatus === 2 && loggedInUser.organization.isDashboard === false) {
              return (<>{history.push('/create-wallet')}</>)
            } else if (loggedInUser.organization.agentSpinupStatus === 2 && loggedInUser.organization.isDashboard === true) {
              if (!_.isEmpty(loggedInUser.organization.subscription) && loggedInUser.organization.subscription.id === 1) {
                return (<>{history.push('/orgAdmin-dashboard')}</>)
              } else if (!_.isEmpty(loggedInUser.organization.subscription) && loggedInUser.organization.subscription.id === 2) {
                return (<>{history.push('/orgAdmin-dashboard')}</>)
              } else if (!_.isEmpty(loggedInUser.organization.subscription) && loggedInUser.organization.subscription.id === 3) {
                return (<>{history.push('/orgAdmin-dashboard')}</>)
              }
            } else if (loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isDashboard === false && loggedInUser.organization.isOnBoarded === 3) {
              return (<>{history.push('/request-failed')}</>)
            }
        } else if (loggedInUser.role.id === 3 && loggedInUser.isActive) {
          return (<>{history.push('/issuer-dashboard')}</>)
        } else if (loggedInUser.role.id === 4 && loggedInUser.isActive) {
          return (<>{history.push('/verifier-dashboard')}</>)
        } else if (loggedInUser.role.id === 5 && loggedInUser.isActive) {
          return (<>{history.push('/both-dashboard')}</>)
        } else {
          toastr.info(`Currently, you are not activated for a login`, ``);
        }
      }
    } else {
      return (
        <>
          <div className="container-fluid h-100 login-bg">
            <div className="row h-100">
              <div className="col-md-12 offset-lg-3 col-lg-6 align-self-center">
                <div className="card shadow">
                  <div className="card-header text-center bg-white border-bottom-0">
                    <img alt="Company logo" src={images.companyLogo} height="100" />
                  </div>
                  <div className="card-body p-5">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-white" id="basic-addon1"><i className="fas fa-envelope"></i></span>
                            </div>
                            <input type="text" className="border-left-0 form-control"
                              placeholder={t("admin:login.loginUsernameLabel")} autoComplete="off"
                              value={loginData.username ? loginData.username : ""}
                              name="username" onChange={this.handleChange}
                            />
                          </div>
                          <div className="text-danger">{errors.username}</div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text bg-white" id="basic-addon1"><i className="fas fa-key"></i></span>
                            </div>
                            <input type="password" className="border-left-0 form-control"
                              placeholder={t("admin:login.loginPasswordLabel")} autoComplete="off"
                              value={loginData.password ? loginData.password : ""}
                              name="password" onChange={this.handleChange}
                            />
                          </div>
                          <div className="text-danger">{errors.password}</div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <button type="button"
                            className="btn btn-nb-primary btn-block btn-lg btn-block"
                            onClick={(e) => this.handleSubmit(e)}
                            disabled={!_.isEmpty(errors) || !loginData.username || !loginData.password ? true : false}
                          >
                            {t("admin:login.loginButton")}</button>
                        </div>
                      </div>
                      <div className="col-sm-12 text-center">
                        <small className="text-muted">{t("admin:login.loginVersion")} {version.version}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const LoaderReducer = state.LoaderReducer;
  return { LoginReducer, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
  }
}

const connectedPlatformAdminLogin = withTranslation(["admin"])(connect(mapStateToProps, mapDispatchToProps)(PlatformAdminLogin));
export { connectedPlatformAdminLogin as PlatformAdminLogin };