import _ from 'lodash';
import * as React from 'react';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { filterExistingConnections } from '../constants';

export interface IPendingConnectionsListProps {
  connectionAction: any,
  ConnectionReducer: any,
  editConnection?: any,
  selectedConnectionForEdit?: any,
}

export interface IPendingConnectionsListState {
  defaultSearchFields: any,
  item_perPage: any,
  pageNo: any,
  sortingOrder: any,
  connectionSortBy: any,
  filterValue: any,
  isDeleted: any,
}

export default class PendingConnectionsList extends React.Component<IPendingConnectionsListProps, IPendingConnectionsListState> {
  constructor(props: IPendingConnectionsListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      filterValue: 'all',
      isDeleted: 'false',
    }
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.viewExistingConnection = this.viewExistingConnection.bind(this);
    this.filter = this.filter.bind(this);
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, connectionSortBy, filterValue, isDeleted } = this.state;
    this.props.connectionAction.updatePageNo(page);
    this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, sortingOrder, connectionSortBy, item_perPage, page, isDeleted);
  }

  showDetails(item: object | any) {
    this.props.connectionAction.selectConnection(item);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortingOrder, connectionSortBy, pageNo, filterValue, isDeleted } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.connectionAction.getAllPendingConnections(event.target.value, filterValue, sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
    }

  }

  sorting = (connectionSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo, filterValue, isDeleted } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', connectionSortBy: connectionSortBy }) : this.setState({ sortingOrder: 'DESC', connectionSortBy: connectionSortBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, sortValue, connectionSortBy, item_perPage, pageNo, isDeleted);
  }


  refresh = (event: any) => {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, connectionSortBy, filterValue, isDeleted } = this.state;
    this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
  }

  viewExistingConnection(item: object | any) {
    this.props.connectionAction.viewExistingConnection(item);
  }

  filter = (selectedFilterValue: any) => {
    const { defaultSearchFields, sortingOrder, connectionSortBy, item_perPage, pageNo, filterValue, isDeleted } = this.state;
    switch (selectedFilterValue) {
      case 'ASC':
        this.setState({ sortingOrder: 'ASC' })
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, 'ASC', connectionSortBy, item_perPage, pageNo, isDeleted);
        break;
      case 'DESC':
        this.setState({ sortingOrder: 'DESC' })
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, 'DESC', connectionSortBy, item_perPage, pageNo, isDeleted);
        break;
      case 'theirLabel':
        this.setState({ sortingOrder: 'ASC', connectionSortBy: 'theirLabel' })
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, 'ASC', 'theirLabel', item_perPage, pageNo, isDeleted);
        break;
      case 'holderName':
        this.setState({ sortingOrder: 'ASC', connectionSortBy: 'theirLabel' })
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, 'ASC', 'theirLabel', item_perPage, pageNo, isDeleted);
        break;
      case 'theirLabel2':
        this.setState({ sortingOrder: 'DESC', connectionSortBy: 'theirLabel' })
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, 'DESC', 'theirLabel', item_perPage, pageNo, isDeleted);
        break;
      case 'all':
        this.setState({ filterValue: selectedFilterValue })
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, 'all', sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
        break;
      case 'employee':
        this.setState({ filterValue: selectedFilterValue })
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, 'employee', sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
        break;
      case 'client':
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, 'client', sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
        this.setState({ filterValue: selectedFilterValue })
        break;
      case 'multi':
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, 'multi', sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
        this.setState({ filterValue: selectedFilterValue })
        break;
      // TODO Filter
      // case 'organization':
      //   this.props.connectionAction.getAllPendingConnections(defaultSearchFields, 'organization', sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
      //   this.setState({ filterValue: selectedFilterValue })
      //   break;
      default:
        this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
        break;
    }
  }

  public render() {
    const {
      connectionList,
      totalConnections,
      tHeadPendingConnection,
      listViewOfConnection,
    } = this.props.ConnectionReducer;
    return (
      <>
        <TableComponents
          onChangePage={this.onChangePage}
          showElementDetails={!listViewOfConnection ? this.viewExistingConnection : this.showDetails}
          tHead={tHeadPendingConnection}
          tableContent={connectionList}
          totalRecords={totalConnections}
          sorting={this.sorting}
          searching={this.onSearch}
          refresh={this.refresh}
          filter={filterExistingConnections}
          onChangeFilter={this.filter}
        />
      </>
    );
  }
}
