import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import RegistrationLayout from '../../Register/components/RegistrationLayout';
import { history } from '../../../index';
import { bindActionCreators } from 'redux';
import { setDIDAction } from '../actions/setDIDAction';
import SettingUpDashboard from '../components/settingUpDashboard';
import { loginAction } from '../../Login/actions/loginAction';
import CopyToClipboard from "react-copy-to-clipboard";

// Utilities
import images from 'src/utils/images';

/* Interface for Props variables*/
export interface ISetDIDPublicProps {
  loginAction: any,
  LoginReducer: any,
  createWalletAction: any,
  CreateWalletReducer: any,
  setDIDAction: any,
  setDIDReducer: any,
}

/* Interface for local states variables*/
export interface ISetDIDPublicState {
  switchToSettingUpDashboard: boolean,
  copiedDid: boolean,
  copiedVerkey: boolean,
}

class SetDIDPublic extends React.Component<ISetDIDPublicProps, ISetDIDPublicState> {
  constructor(props: ISetDIDPublicProps) {
    super(props);

    /* Initialization of state variables*/
    this.state = {
      switchToSettingUpDashboard: false,
      copiedDid: false,
      copiedVerkey: false,
    }
    /* Binding the method */
    this.goToDashboard = this.goToDashboard?.bind(this);
    this.onCopiedInvitation = this.onCopiedInvitation.bind(this);
  }
  /* Default react life-cycle method, it calls after render method executed. */
  componentDidMount() {
    this.props.loginAction.getCurrentUser();
  }

  /* Method used to get updated props values */
  componentWillReceiveProps(newProps: ISetDIDPublicProps) {
    const { showDashboard } = newProps.setDIDReducer;
    if (showDashboard === true) {
      this.setState({
        switchToSettingUpDashboard: false
      })
      localStorage.clear();
    }
  }
  /* Method used to call the setting up dashboard action method */
  goToDashboard = (event: any) => {
    const { loggedInUser } = this.props.LoginReducer;
    let orgId = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.id;
    this.props.setDIDAction.settingUpDashboard(orgId)
    this.setState({
      switchToSettingUpDashboard: true,
    })
  }

  /**
   * Method used to copy the did and verkey
   * @param value Parameter used to get value for copy
   * @returns 
   */
  onCopiedInvitation(value: any) {
    switch (value) {
      case 'copiedDid':
        return this.setState({ copiedDid: true })
      case 'copiedVerkey':
        return this.setState({ copiedVerkey: true })
      default:
        return null
    }

  }

  /**
   * Method used to reset the copied state value
   * @param event 
   */
  onAfterCopiedInvitation = (event: React.MouseEvent<HTMLButtonElement> | any) => {
    setTimeout(() => {
      this.setState({
        copiedDid: false,
        copiedVerkey: false,
      });
    }, 1000);
    event.preventDefault();
  }
  public render() {
    const { loggedInUser } = this.props.LoginReducer;
    const { showDashboard } = this.props.setDIDReducer;
    const { switchToSettingUpDashboard } = this.state;
    let condition: number = 0
    if (localStorage.length === 0) {
      console.log(`first-time-user - Set  DID Public`)
      history.push('/first-time-user');
    }

    return (
      <>
        {switchToSettingUpDashboard ? <SettingUpDashboard {...this.props} /> :
          <RegistrationLayout status={!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.agentSpinupStatus}>
            <div className="row animated fadeIn">
              <div className="col-sm-12">
                <h1 className="nb-h1">PUBLIC DID</h1>
                <hr className="nb-title-border-b" />
              </div>
              <div className="col-sm-12">
                <div className="card nb-border-radius shadow mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <img src={!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.logoUrl} alt=""
                          style={{ maxHeight: '150px' }}
                        />
                      </div>
                      <div className="col-sm-6 align-self-center">
                        <div className="media float-right">
                          <img src={images.placeholderProfile} className="align-self-center mr-3 user-icon" alt="" />
                          <div className="media-body">
                            <h1>{!_.isEmpty(loggedInUser) && loggedInUser.username}</h1>
                            <div>
                              <small>{!_.isEmpty(loggedInUser) && loggedInUser.email}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="card nb-border-radius h-100">
                  <div className="card-header nb-bg-light text-center p-4">
                    <h1 className="nb-h2">CREATE WALLET</h1>
                  </div>
                  <div className="card-body" style={{ minHeight: '250px' }}>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <img src="assets/icons/create-wallet.svg" style={{ width: '265px' }} alt="" />
                      </div>
                      {/* <div className="col-sm-12 text-center">
                        <a className="font-weight-bold">Wallet Name: {!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.walletName}</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card nb-border-radius h-100">
                  <div className="card-header nb-bg-light text-center p-4">
                    <h1 className="nb-h2">PUBLIC DID</h1>
                  </div>
                  <div className="card-body" style={{ minHeight: '250px' }}>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <div className="form-group">
                          <label className="font-weight-bold text-nb-light"><img src="assets/icons/leaf.svg" /> DID is public</label>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">DID</span>
                            </div>
                            <input type="text" className="form-control"
                              value={!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.did}
                              readOnly={true}
                            />
                            <div className="input-group-append">
                              <CopyToClipboard
                                text={!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.did}
                                onCopy={() => this.onCopiedInvitation('copiedDid')}
                              >
                                <div className="input-group-append">
                                  <button className="btn btn-outline-secondary" type="button" onClick={this.onAfterCopiedInvitation}>
                                    {this.state.copiedDid ? (
                                      <span className="text-success">Copied</span>
                                    ) : <i className="far fa-copy" />}

                                  </button>
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Verkey</span>
                            </div>
                            <input type="text" className="form-control"
                              value={!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.verkey}
                              readOnly={true}
                            />
                            <div className="input-group-append">
                              <CopyToClipboard
                                text={!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.verkey}
                                onCopy={() => this.onCopiedInvitation('copiedVerkey')}
                              >
                                <div className="input-group-append">
                                  <button className="btn btn-outline-secondary" type="button" onClick={this.onAfterCopiedInvitation}>
                                    {this.state.copiedVerkey ? (
                                      <span className="text-success">Copied</span>
                                    ) : <i className="far fa-copy" />}

                                  </button>
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 text-center mt-3">
                <div className="form-group">
                  <button type="button" className="btn btn-nb-blue rounded-pill"
                    onClick={this.goToDashboard}
                  >CREATE DEFAULT CREDENTIAL DEFINITION</button>
                </div>
              </div>
            </div>
          </RegistrationLayout>
        }
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const setDIDReducer = state.setDIDReducer;
  return { setDIDReducer, LoginReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    setDIDAction: bindActionCreators(setDIDAction, dispatch),
  }
}

const connectedSetDIDPublic = connect(mapStateToProps, mapDispatchToProps)(SetDIDPublic);
export { connectedSetDIDPublic as SetDIDPublic };
