import { t } from "i18next"
import _ from "lodash"

export const PresentationRequestDetails = ({ selectedProofDetails, selectedConnectionName, presentationExchangeId }: any) => {

    const { requestedAttributesDetails, requestedPredicatesDetails } = selectedProofDetails

    return (
        <>
            <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:presentProof.proofRequest")}</h1>
            </div>
            <ul className="fa-ul nb-list" key={presentationExchangeId}>
                
                {/* Proof request details block */}
                <li className="pl-1 pr-0">
                    {requestedAttributesDetails.length > 0 ?
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-bold nb-label">{t("screens:presentProof.requestedAttributes")}</label>
                            </div>
                        </div> :
                        <></>
                    }
                    {requestedAttributesDetails.length > 0 && requestedAttributesDetails.map((element: any, index: number) => {
                        return (
                            <div className="row ml-3" key={index}>
                                <div className="col-sm-12 mb-2">
                                    <i className="fa-li nb-ico nb-organization"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.givenName")}:</label>
                                    <label>{selectedConnectionName} </label>
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <i className="fa-li nb-ico nb-credential"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.credentialType")}:</label>
                                    <label>{element.credentialType}</label>
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <i className="fa-li nb-ico nb-credential"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.credentialName")}:</label>
                                    <label>{element.credentialName ?? '-'}</label>
                                </div>
                                <div className="col-sm-12 mb-2">
                                    <i className="fa-li nb-ico nb-attribute"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.attributes")}:</label>
                                    {element.credentialFields?.map((item: string, index: number) => {
                                        return (<span key={index} className="badge badge-light nb-badge nb-badge-blue mr-2">{Object.values(item)[0]}</span>)
                                    })}
                                </div>
                            </div>
                        )
                    })}
                    {requestedPredicatesDetails.length > 0 ?
                        <div className="row mt-3">
                            <div className="col-sm-12">
                                <label className="font-weight-bold nb-label">{t("screens:presentProof.requestedPredicates")}</label>
                            </div>
                        </div> :
                        <></>
                    }
                    {
                        requestedPredicatesDetails.length > 0 && requestedPredicatesDetails.map((predicate: any, pIndex: number) => {
                            return (
                                <div className="row ml-3" key={pIndex}>
                                    <div className="col-sm-6 mb-2">
                                        <i className="fa-li nb-ico nb-credential"></i>
                                        <label className="font-weight-bold">{t("screens:presentProof.credentialName")}:</label>
                                        <label>{predicate.credentialName}</label>
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <i className="fa-li nb-ico nb-attribute"></i>
                                        <span className="font-weight-bold">{t("screens:presentProof.condition")}: <span className="badge badge-light nb-badge nb-badge-blue mr-2">{predicate.credentialFields.name}</span> {predicate.credentialFields.p_type} {predicate.credentialFields.p_value}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </li>
            </ul>
        </>
    )
}
