import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingAction } from '../actions/onboardingAction'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PageLoader from '../../../Common Components/Components/pageLoader';
import { toastr } from "react-redux-toastr";


export interface INewOrganizationProps {
  onboardingAction: any,
  LoaderReducer: any,
}

export interface INewOrganizationState {
  formData: any,
  errors: any,
}

class RegisterOrganization extends React.Component<INewOrganizationProps, INewOrganizationState> {
  constructor(props: INewOrganizationProps) {
    super(props);

    this.state = {
      formData: {},
      errors: {},
    }
    this.handleChange = this.handleChange.bind(this);
    this.addExternalOrganization = this.addExternalOrganization.bind(this);
  }

  componentDidMount() {
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    let formData = this.state.formData;
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData,
    });
    event.preventDefault();
    this.validateForm()
  }

  addExternalOrganization(event: React.ChangeEvent<HTMLInputElement> | any) {

    const { formData } = this.state;
   
    let finalData = {
      orgName: formData.orgName.replace(/\s\s+/g, ' ').trim(),
    }
    if (Object.values(finalData).includes("")) {
      toastr.error(`Please fill all the field`, "")
    } else {
      this.props.onboardingAction.addExternalOrganization(finalData)
      this.setState({
        formData: {},
      });
    }
  }

  validateForm() {
    let formData: any = this.state.formData;
    let errors: any = {};
    let formIsValid: any = true;
    if (typeof formData["orgName"] !== "undefined") {
      if (!formData["orgName"].match(/^.{6,}$/) || formData["orgName"].trim().length < 5) {
        formIsValid = false;
        errors["orgName"] = "Please enter more than 5 characters";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  public render() {
    const { formData } = this.state;
    const { loader } = this.props.LoaderReducer;
    return (
      <div>
        {
          loader ?
            <PageLoader></PageLoader>
            : null
        }
        <div className="card nb-card-noborder">
          <div className="card-header bg-white">
            <h1>Register Organization</h1>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="label-nb">Organization Name<span>*</span></label>
                      <input type="text" className="form-control" name="orgName" autoComplete="off"
                        value={formData.orgName ? formData.orgName : ''}
                        onChange={this.handleChange}
                      />
                      </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button type="button" className="btn btn-nb-gradient-primary btn-block rounded-pill"
                        onClick={this.addExternalOrganization}
                        disabled={Object.values(this.state.formData).includes("")}>
                        Register
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const LoaderReducer = state.LoaderReducer;

  return { LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    onboardingAction: bindActionCreators(onboardingAction, dispatch),
  }
}

const connectedNewOrganization = connect(mapStateToProps, mapDispatchToProps)(RegisterOrganization);
export { connectedNewOrganization as RegisterOrganization };
