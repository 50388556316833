import { t } from "i18next"
import _ from "lodash"
import DisplayCredentialCommentComponent from "src/Common Components/Components/Comments/DisplayCredentialComment"
import { CredentialState } from "src/View/commonConst"
import { isJSON } from "src/utils/json"
import "./style.css";
import images from "src/utils/images"
import { SchemaDateSuffix } from "src/View/Schema/models/schema.enum"
import { displayDateFormat } from "src/utils/date.utils"
import { CommentsCredential } from "src/Common Components/Components/Comments/enums"
import DisplayVPCredentialCommentComponent from "src/Common Components/Components/Comments/VerifiedPerson/DisplayCredentialComment"

export const NegotiateCredentialDetails = ({
    selectedCredentialRecord,
    handleDecline,
    handleVerifyIssuerInTrustRegistry,
    handleRePropose,
    handleCounterProposal,
    handleSendOfferNegotiate,
    isOfferAccepted,
    isProblemReported,
    handleTrustDecision,
    trustRegistryResult
}: any) => {

    const { credentialExchangeId, credentialProposalDict, connectionName, state } = selectedCredentialRecord || {}
    const credentialProposalDictObj = !_.isEmpty(selectedCredentialRecord) && credentialProposalDict && JSON.parse(credentialProposalDict)
    const attributes = !_.isEmpty(selectedCredentialRecord) && credentialProposalDictObj.credential_proposal.attributes;

    return (
        <>
            <div className="card-header bg-white border-bottom-0">
                <div className="row">

                    <div className="col-sm-6">
                        <div className="frame">
                            <div className="div-wrapper nb-title">
                                <div className="text-wrapper">{t("screens:negotiateCredential.credentialDetails")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        {
                            (state === CredentialState.STATE_OFFER_RECEIVED
                                || state === "Offer Accepted"
                                || state === CredentialState.STATE_ISSUED
                                || state === CredentialState.STATE_ACKED)
                                && trustRegistryResult !== null ?
                                trustRegistryResult === true ?
                                    <>
                                        <span className="badge badge-light nb-badge nb-badge-green mr-2">
                                            <i className="fa fa-2xs fa-solid fa-circle"></i>  {t("screens:negotiateCredential.successTrustRegistry")}
                                        </span>
                                    </>
                                    : <>
                                        <span className="badge badge-light nb-badge nb-badge-red mr-2">
                                            <i className="fa fa-2xs fa-solid fa-circle"></i>  {t("screens:negotiateCredential.failedTrustRegistry")}
                                        </span>
                                    </>
                                : <></>
                        }
                    </div>
                    <div className="col-sm-3">
                        {
                            state === CredentialState.STATE_OFFER_RECEIVED &&
                            <>
                                <small className="text-primary">
                                    {t("screens:negotiateCredential.received")}
                                </small>
                            </>
                        }
                        {
                            state === "Offer Accepted" &&
                            <>
                                <small className="text-primary">
                                    {t("screens:negotiateCredential.accepted")}
                                </small>
                            </>
                        }
                        {
                            state === CredentialState.STATE_PROPOSAL_RECEIVED &&
                            <small className="text-primary">
                                {t("screens:negotiateCredential.proposalReceived")}
                            </small>
                        }
                        {
                            (state === CredentialState.STATE_ISSUED
                                || state === CredentialState.STATE_ACKED)
                            &&
                            <>
                                <small className="text-primary">
                                    {t("screens:negotiateCredential.acked")}
                                </small>
                            </>
                        }

                    </div>
                </div>
            </div>
            {!_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">
                        <div className="mb-5">
                            <div className="mb-2">
                                <div className="row">

                                    <div className="col-sm-6">
                                        <div className="label">

                                            <div className="details-label">
                                                {t("screens:negotiateCredential.credentialName")}:
                                            </div>
                                        </div>
                                        <div className="value">

                                            <div className="details-value">
                                                {selectedCredentialRecord?.credentialDefinitionId?.split(':')[4]}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="label">

                                            <div className="details-label">
                                                {
                                                    state === CredentialState.STATE_PROPOSAL_RECEIVED
                                                        ? t("screens:negotiateCredential.proposedFrom")
                                                        : t("screens:negotiateCredential.givenName")
                                                } :

                                            </div>
                                        </div>
                                        <div className="value">
                                            <div className="details-value">
                                                {connectionName}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <hr />

                            <div className="mb-2">
                                <div className="row">
                                    <div className="col-sm-12">

                                        <div className="cred-data-title">
                                            <div className="text-wrapper">
                                                {t("screens:negotiateCredential.credentialDataTitle")}
                                            </div>
                                        </div>
                                        <div className="cred-data-label">
                                            <div className="text-wrapper">
                                                {
                                                    state === CredentialState.STATE_ISSUED
                                                        || state === CredentialState.STATE_ACKED
                                                        || state === CredentialState.STATE_OFFER_SENT
                                                        ? t("screens:negotiateCredential.credentialIssuedDataDescription")
                                                        : t("screens:negotiateCredential.credentialRequestDataDescription")}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="mb-2">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table className="table table-borderless">
                                            <tbody>
                                                {
                                                    attributes.map((tableItem: any, tableIndex: any) => {
                                                        if (!tableItem.hasOwnProperty("mime-type")) {
                                                            return (
                                                                <tr key={tableIndex}>
                                                                    <td className="table-header-wrapper">{tableItem.name}</td>
                                                                    <td>
                                                                        <input
                                                                            name="email"
                                                                            value={
                                                                                (tableItem.name).includes(SchemaDateSuffix.DATE_SUFFIX)
                                                                                    ? displayDateFormat(tableItem.value)
                                                                                    : tableItem.value
                                                                            }
                                                                            type="text"
                                                                            disabled
                                                                            placeholder=""
                                                                            className='table-data-wrapper text-left'
                                                                        />

                                                                    </td>
                                                                </tr>
                                                            )
                                                        } else {
                                                            return (
                                                                <tr key={tableIndex}>
                                                                    <td>{tableItem.name}</td>
                                                                    <td>
                                                                        <img src={tableItem.value} alt={tableItem.name} className="preview" height="50" />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="mb-2">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {
                                            isJSON(credentialProposalDictObj.comment)
                                                ?
                                                credentialProposalDictObj?.schema_id?.split(':')[2] === CommentsCredential.VERIFIED_PERSON
                                                    ? <DisplayVPCredentialCommentComponent data={JSON.parse(credentialProposalDictObj.comment)} />
                                                    : <DisplayCredentialCommentComponent data={(JSON.parse(credentialProposalDictObj.comment))} />
                                                : <p>{credentialProposalDictObj.comment}</p>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <>
                            {state === CredentialState.STATE_OFFER_RECEIVED &&
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-nb-outline-primary btn-block rounded-pill"
                                        onClick={() => handleTrustDecision(credentialExchangeId)}
                                        disabled={isOfferAccepted}
                                    >
                                        {t("screens:negotiateCredential.accept")}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-nb-outline-primary btn-block rounded-pill"
                                        onClick={() => handleRePropose(credentialExchangeId)}
                                    >
                                        {t("screens:negotiateCredential.rePropose")}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-nb-outline-primary btn-block rounded-pill"
                                        onClick={handleDecline}
                                        disabled={isProblemReported}
                                    >
                                        {t("screens:negotiateCredential.decline")}
                                    </button>
                                </>
                            }
                            {state === CredentialState.STATE_PROPOSAL_RECEIVED &&
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-nb-outline-primary btn-block rounded-pill"
                                        onClick={() => handleSendOfferNegotiate(credentialExchangeId)}
                                    >
                                        {t("screens:negotiateCredential.offer")}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-nb-outline-primary btn-block rounded-pill"
                                        onClick={() => handleCounterProposal(credentialExchangeId)}
                                    >
                                        {t("screens:negotiateCredential.edit")}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-nb-outline-primary btn-block rounded-pill"
                                        onClick={handleDecline}
                                        disabled={isProblemReported}
                                    >
                                        {t("screens:negotiateCredential.declineProposal")}
                                    </button>
                                </>
                            }
                        </>
                    </div>

                </>
            }
            {
                _.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">
                        <div className="blank-credential-details-body">
                            <label className="">{t("screens:negotiateCredential.noRequestReceived")}</label>
                        </div>
                    </div>
                </>
            }
        </>
    )
}