import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import MaskedData from './MaskedData.component';


interface Props extends WithTranslation<['common', 'screens']> {
    name: string;
    value: string;
    showAllAttribute: boolean;
    index: number;
    onLinkClick: (index: number) => void;
}

const ProofAttributeComponent: React.FC<Props> = ({
    name,
    value,
    showAllAttribute,
    index,
    onLinkClick,
}) => {
    return (
        <li>
            <label className="font-weight-bold">
                {/* SELECTIVE DISCLOSURE removed till ACA-Py issue gets resolved */}

                {/* <input type="checkbox"
                    id='Credential'
                    key={`attributes-${credentialIndex}`}
                    name={`${credential.credentialId}--${credentialIndex}--${credentialData.name}`}
                    checked={credentialData.reveal}
                    onChange={(e) => this.props.onChangeRevealAttributes(e)}
                />  */}
                {name}:&nbsp;
                <MaskedData
                    isHidden={!showAllAttribute}
                    value={value}
                    index={index}
                    onLinkClick={() => onLinkClick(index)}
                />
            </label>

        </li>
    );
};
export default withTranslation(['common', 'screens'])(ProofAttributeComponent);
