import _ from 'lodash';
import * as React from 'react';
import Chart from 'react-google-charts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../../..';
import { loginAction } from '../../Login/actions/loginAction';
import { dashboardAction } from '../actions/dashboardAction';

// Utilities
import images from 'src/utils/images';

export interface IPlatformAdminDashboardProps {
  loginAction: any,
  LoginReducer: any,
  DashboardReducer: any,
  dashboardAction: any,
}

export interface IPlatformAdminDashboardState {
}

class PlatformAdminDashboard extends React.Component<IPlatformAdminDashboardProps, IPlatformAdminDashboardState> {
  constructor(props: IPlatformAdminDashboardProps) {
    super(props);

    this.state = {
    }
    this.ViewIssuedKYC = this.ViewIssuedKYC.bind(this);
  }

  componentDidMount() {
    this.props.loginAction.getCurrentUser();
    this.props.dashboardAction.getAllCountforPADashboard();
  }

  ViewIssuedKYC() {
    history.push('/issue-kyc-credentials')
  }

  public render() {
    const { loggedInUser } = this.props.LoginReducer;
    const { countForPADashboard } = this.props.DashboardReducer;
    console.log("countForPADashboard----------------", countForPADashboard)
    let arraydata: Array<any> = !_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].kycCount) ? countForPADashboard[0].kycCount : [[]]

    return (
      <>
        {/* <div className="row">
          <div className="col-sm-6 mb-30">
            <h4>Welcome! {!_.isEmpty(loggedInUser) && loggedInUser.username} </h4>
          </div>
        </div> */}
        {/* <div className="row gutters-sm animated fadeIn">
          <div className="col-md-12 col-lg-12 mb-30">
            <div className="card nb-card h-100 shadow">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title-3">KYC Issuance Reminder</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <h6 className="nb-label">WAITING <span className="badge badge-light nb-badge nb-badge-blue">{
                      !_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].kycWaitingList) ? countForPADashboard[0].kycWaitingList.length : 0
                    }</span></h6>
                    <div className="row">
                      <div className="col-sm-12">
                        {!_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].kycWaitingList) ?
                          countForPADashboard[0].kycWaitingList.map((item: any, index: any) => {
                            return (
                              index < 3 ?
                                <>
                                  <div className="card mb-2 nb-box nb-box-blue">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-sm-8 align-self-center">
                                          <p className="font-weight-bold overflow-ellips">{!_.isEmpty(item.holder) ? item.holder.name : ''}</p>
                                          <label className="text-muted">{item.kycName}</label>
                                        </div>
                                        <div className="col-sm-4 text-center">
                                          <div>
                                            <img className="nb-box-icon" src={images.placeholderProfile} />
                                          </div>
                                          <label className="text-muted">{moment(item.kycExpiryDate).format('MMM DD')}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                : null
                            )
                          })
                          :
                          <>
                            <div className="card mb-2 nb-box nb-box-blue">
                              <div className="card-body">
                                <div className="row">
                                  No KYC in Waiting.
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      </div>

                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <h6 className="nb-label">QUALIFIED <span className="badge badge-light nb-badge nb-badge-purple">0</span></h6>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card mb-2 nb-box nb-box-purple">
                          <div className="card-body">
                            <div className="row">
                              No Issued Record Found
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <h6 className="nb-label">REJECTED <span className="badge badge-light nb-badge nb-badge-red">0</span></h6>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card mb-2 nb-box nb-box-red">
                          <div className="card-body">
                            <div className="row">
                              No Issued Record Found
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <h6 className="nb-label">ISSUED <span className="badge badge-light nb-badge nb-badge-green">{
                      !_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].kycIssuedList) ?
                        countForPADashboard[0].kycIssuedList.length : 0
                    }</span></h6>
                    <div className="row">
                      <div className="col-sm-12">
                        {!_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].kycIssuedList) ?
                          countForPADashboard[0].kycIssuedList.map((element: any, index: any) => {
                            return index < 3 ?

                              <>
                                <div className="card mb-2 nb-box nb-box-green">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-sm-8 align-self-center">
                                        <p className="font-weight-bold overflow-ellips">{!_.isEmpty(element) ? element.holder.name : ''}</p>
                                        <label className="text-muted overflow-ellips">{element.kycName}</label>
                                      </div>
                                      <div className="col-sm-4 text-center">
                                        <div>
                                          <img className="nb-box-icon" src={images.placeholderProfile} />
                                        </div>
                                        <label className="text-muted">{moment(element.kycExpiryDate).format('MMM DD')}</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>

                              : null
                          })
                          :
                          <>
                            <div className="card mb-2 nb-box nb-box-green">
                              <div className="card-body">
                                <div className="row">
                                  No Issued Record Found
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 text-center">
                    <button className="btn nb-link" onClick={this.ViewIssuedKYC}>
                      <i className="fas fa-2x fa-ellipsis-h"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row  gutters-sm animated fadeIn">
          {/* <div className="col-md-12 col-lg-7 mb-30">
            <div className="card nb-card h-100 shadow">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title-3">Top KYC Request Orgs </h1>
              </div>
              <div className="card-body">
                {!_.isEmpty(arraydata) ?
                  <Chart
                    width={'100%'}
                    height={'300px'}
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={
                      arraydata
                    }

                    options={{
                      // Material design options
                      colors: ['#9A66CD', '#018BE6', '#F7C137'],
                      chartArea: { width: '80%' },
                      chart: {
                        title: 'Top KYC Request Orgs',
                        subtitle: 'KYCs and Rank',
                      },
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '2' }}
                  />
                  :
                  <>
                    <div className="card mb-2 nb-box nb-box-green">
                      <div className="card-body">
                        <div className="row">
                          No Record Found
                    </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div> */}
          <div className="col-md-12 col-lg-5 mb-30">
            <div className="card nb-card h-100 shadow">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title-3">Partner Organizations</h1>
              </div>
              <div className="card-body">
                <Chart
                  width={'100%'}
                  height={'auto'}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Organization', 'Organization(NB)'],
                    ['Verifier Orgs', !_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].partnerOrgPercentage) ? countForPADashboard[0].partnerOrgPercentage.issuerOrgCount : 0],
                    ['Issuer Orgs', !_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].partnerOrgPercentage) ? countForPADashboard[0].partnerOrgPercentage.verifierOrgCount : 0],
                    ['Issuer & Verifier Orgs', !_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].partnerOrgPercentage) ? countForPADashboard[0].partnerOrgPercentage.bothOrgCount : 0],
                    ['Platform', !_.isEmpty(countForPADashboard) && !_.isEmpty(countForPADashboard[0].partnerOrgPercentage) ? countForPADashboard[0].partnerOrgPercentage.platformOrgCount : 0],
                  ]}
                  options={{
                    pieSliceText: 'label',
                    title: 'Connections',
                    pieStartAngle: 100,
                    chartArea: { width: '100%', height: '100%' },
                    colors: ['#9A66CD', '#018BE6', '#F7C137', '#999966'],
                  }}
                  rootProps={{ 'data-testid': '4' }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const DashboardReducer = state.DashboardReducer;
  return { LoginReducer, DashboardReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    dashboardAction: bindActionCreators(dashboardAction, dispatch),
  }
}

const connectedPlatformAdminDashboard = connect(mapStateToProps, mapDispatchToProps)(PlatformAdminDashboard);
export { connectedPlatformAdminDashboard as PlatformAdminDashboard };