import React from "react";
import { connect } from "react-redux";
import { presentProofRequest } from "../../commonConst";
import { t } from 'i18next';

/* Interface for Props variables*/
interface IProofRequestDetailsProps {
    storedCredentials: any,
    selectedCredentialRecord: any,
    updateSelectedCredentials: any,
    selectedCredentials: any,
    onChangeCredentialSelection: any
}

/* Interface for local states variables*/
interface IProofRequestDetailsState {
    selectedCredentials: any
}

class StoredCredentialList extends React.Component<IProofRequestDetailsProps, IProofRequestDetailsState> {
    constructor(props: IProofRequestDetailsProps) {
        super(props)

        this.state = {
            selectedCredentials: props.selectedCredentials
        }
    }

    /* Default lifecycle method used to show the default 1st credential details*/
    componentDidMount() {
        this.props.updateSelectedCredentials(this.props.storedCredentials)
    }

    /* Lifecycle method used to get updated state value and show the selected credential details */
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.storedCredentials !== this.props.storedCredentials) {
            this.props.updateSelectedCredentials(this.props.storedCredentials)
        }
    }

    /* UI to show stored credential list from wallet */
    render() {
        const { storedCredentials, selectedCredentials } = this.props
        
        return (
            <>
                {/* Available credential block */}
                <ul className="fa-ul nb-list">
                    <li className="mt-3 remove-underline">
                        <i className="fa-li nb-ico nb-credentials"></i>
                        <span className="h5">{t("screens:presentProof.availableCredentials")}</span>
                    </li>
                    <li>
                        <div className="row mt-3">
                            {storedCredentials && storedCredentials.length > 0 ?
                                <>
                                    {storedCredentials.map((credential: any, index: number) => {
                                        return (
                                            <div className="col-sm-4" key={index} >
                                                <div className="form-group">
                                                    <label className="align-self-center" htmlFor=''>
                                                        <input type="checkbox" id='Credential' key='allAttributes'
                                                            name={`credential-${index}`}
                                                            checked={selectedCredentials?.filter((x: any) => x?.cred_info?.referent === credential.cred_info.referent).length > 0}
                                                            onChange={(e) => this.props.onChangeCredentialSelection(e)}
                                                        />{` ${credential.cred_info.cred_def_id.split(":")[4]}`}</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                : <>
                                    <div className="card-body">
                                        <div className="blank-credential-details-body">
                                            <label className="">{t("screens:presentProof.noCredentialAvailable")}</label>
                                        </div>
                                    </div>
                                </>}
                        </div>
                    </li>
                </ul>
            </>
        )
    }
}

const storedCredentialList = connect()(StoredCredentialList);
export { storedCredentialList as StoredCredentialList };