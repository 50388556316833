import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import TableComponents from "../../../Common Components/Components/TableComponent";
import { notificationAction } from "../actions/notificationAction";

import { t } from 'i18next';

export interface INotificationListProps {
  notificationAction: any,
  NotificationReducer: any,
  notificationList: any,
  totalNotifications: any
}

export interface INotificationListState {
  page: number,
  itemPerPage: number,
  searchText: string
}

class NotificationList extends React.Component<INotificationListProps, INotificationListState> {
  constructor(props: INotificationListProps) {
    super(props);

    this.state = {
      page: 1,
      itemPerPage: 10,
      searchText: ""
    }

    this.onChangePage = this.onChangePage.bind(this)
    // this.selectedNotificationRecord = this.selectedNotificationRecord.bind(this)
    this.search = this.search.bind(this)
    this.showDetails = this.showDetails.bind(this);
    this.deleteElementDetails = this.deleteElementDetails.bind(this);
    this.refresh = this.refresh.bind(this)
  }

  onChangePage(page: number) {
    const { itemPerPage, searchText } = this.state;
    this.setState({ page })
    this.props.notificationAction.getAllNotificationsList(page, itemPerPage, searchText);
  }

  search(event: React.KeyboardEvent<HTMLInputElement> | any) {
    const { page, itemPerPage } = this.state;
    if (event.key === 'Enter') {
      this.setState({ searchText: event.target.value })
      this.props.notificationAction.getAllNotificationsList(page, itemPerPage, event.target.value);
    }
  }

  showDetails(item: any) {
    this.props.notificationAction.selectNotification(item)
  }

  deleteElementDetails(item: any) {
    this.props.notificationAction.deleteNotification(item.id)
  }

  refresh() {
    const defaultPayload = {
      page: 1,
      itemPerPage: 10,
      searchText: ""
    }
    this.setState(defaultPayload)
    this.props.notificationAction.getAllNotificationsList(defaultPayload.page, defaultPayload.itemPerPage, defaultPayload.searchText);
  }

  public render() {
    const { tHeadNotification, notificationList, totalNotifications } = this.props.NotificationReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-md-12 col-lg-12 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:notification.notificationList")}</h1>
              </div>
              <div className="card-body">
                <TableComponents
                  onChangePage={this.onChangePage}
                  showElementDetails={this.showDetails}
                  tHead={tHeadNotification}
                  tableContent={notificationList}
                  totalRecords={totalNotifications}
                  searching={this.search}
                  deleteElementDetails={this.deleteElementDetails}
                  refresh={this.refresh}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  const NotificationReducer = state.NotificationReducer;

  return { NotificationReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    notificationAction: bindActionCreators(notificationAction, dispatch),
  }
}


const notificationList = connect(mapStateToProps, mapDispatchToProps)(NotificationList);
export { notificationList as NotificationList };