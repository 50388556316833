import { trim } from 'lodash';
import * as React from 'react';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingAction } from '../actions/onboardingAction'
import { emailTemplateActions } from '../../Settings/EmailTemplates/actions/editEmailTemplatesAction';
import { EditorState } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PageLoader from '../../../Common Components/Components/pageLoader';
import { toastr } from "react-redux-toastr";


export interface INewOrganizationProps {
  onboardingAction: any,
  OnboardingReducer: any,
  EmailTemplatesReducers: any,
  emailTemplateActions: any,
  LoaderReducer: any,
}

export interface INewOrganizationState {
  formData: any,
  errors: any,
  type: string,
  validPeriod: any,
  editorState: any,
  editStateforSignature: any,
  defaultSearchFields: any,
  // emailBanner: any,
}

class NewOrganization extends React.Component<INewOrganizationProps, INewOrganizationState> {
  constructor(props: INewOrganizationProps) {
    super(props);

    this.state = {
      formData: {},
      errors: {},
      type: 'password',
      validPeriod: [
        { tag: '1 Day' },
        { tag: '2 Days' },
        { tag: '3 Days' },
        { tag: '4 Days' },
        { tag: '5 Days' },
        { tag: '6 Days' },
        { tag: '7 Days' },
        { tag: '8 Days' },
        { tag: '9 Days' },
        { tag: '10 Days' }
      ],
      editorState: EditorState.createEmpty(),
      editStateforSignature: EditorState.createEmpty(),
      defaultSearchFields: '',
      // emailBanner: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.addOrganizationInvitation = this.addOrganizationInvitation.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    // let id = 3;
    this.props.onboardingAction.getInitialPassword();
    // this.props.emailTemplateActions.getEmailTemplatesById(id);
  }

  // componentWillReceiveProps(newProps: INewOrganizationProps) {
  //   const { selecteEmailTemp } = newProps.EmailTemplatesReducers;
  //   let newEditorState;
  //   let newemailSignature;
  //   if (selecteEmailTemp && selecteEmailTemp.emailContent !== undefined && selecteEmailTemp.emailContent !== null) {
  //     let rawContent: RawDraftContentState = selecteEmailTemp && selecteEmailTemp.emailContent;
  //     const contentState: ContentState = convertFromRaw(rawContent);
  //     newEditorState = EditorState.createWithContent(contentState);
  //   }
  //   if (selecteEmailTemp && selecteEmailTemp.emailSignature !== undefined && selecteEmailTemp.emailSignature !== null) {
  //     let rawContent: RawDraftContentState = selecteEmailTemp && selecteEmailTemp.emailSignature;
  //     const contentState: ContentState = convertFromRaw(rawContent);
  //     newemailSignature = EditorState.createWithContent(contentState);
  //   }
  //   this.setState({
  //     emailBanner: selecteEmailTemp.emailBanner,
  //     editorState: newEditorState,
  //     editStateforSignature: newemailSignature
  //   })
  // }

  handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    const { initialPassword } = this.props.OnboardingReducer
    let formData = this.state.formData;
    formData["adminPassword"] = initialPassword
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData,
    });
    event.preventDefault();
    if (event.target.name === "orgName") {
      this.props.onboardingAction.getEmailIdandOrgName(event.target.value, 'orgName');
    } else if (event.target.name === "adminEmail") {
      this.props.onboardingAction.getEmailIdandOrgName(event.target.value, 'adminEmail');
    }
    this.validateForm()
  }

  handleOrgChange(selectedOptions: any) {
    let formData = this.state.formData;
    if (selectedOptions[0] && selectedOptions[0].tag) {
      formData["validPeriod"] = selectedOptions[0].tag;
    } else {
      formData["validPeriod"] = ''
    }
    this.setState({
      formData: formData
    })
  }

  handleInputChange() {

  }

  addOrganizationInvitation(event: React.ChangeEvent<HTMLInputElement> | any) {

    const { formData, editorState, editStateforSignature } = this.state;
    // let emailContent = convertToRaw(editorState.getCurrentContent());
    // let emailSignature = convertToRaw(editStateforSignature.getCurrentContent());

    // let message = {
    //   emailBanner : emailBanner,
    //   emailContent : emailContent,
    //   emailSignature : emailSignature,
    // };
    // formData.message = message;
    let finalData = {
      adminEmail: formData.adminEmail,
      adminName: formData.adminName.replace(/\s\s+/g, ' ').trim(),
      adminPassword: formData.adminPassword,
      message: formData.message.replace(/\s\s+/g, ' ').trim(),
      orgName: formData.orgName.replace(/\s\s+/g, ' ').trim(),
      validPeriod: formData.validPeriod
    }
    if (Object.values(finalData).includes("")) {
      toastr.error(`Please fill all the field`, "")
    } else {
      console.log("finalData------", finalData);
      this.props.onboardingAction.addOrganizationInvitation(finalData)
      this.setState({
        formData: {},
        type: 'password',
      });
    }
  }

  validateForm() {

    let formData: any = this.state.formData;
    let errors: any = {};
    let formIsValid: any = true;
    if (typeof formData["orgName"] !== "undefined") {
      if (!formData["orgName"].match(/^.{6,}$/) || formData["orgName"].trim().length < 5) {
        formIsValid = false;
        errors["orgName"] = "Please enter more than 5 characters";
      }
    }

    // if (!formData["orgName"]) {
    //   formIsValid = false;
    //   errors["orgName"] = "*Please enter your Organization Name.";
    // }

    if (typeof formData["adminName"] !== "undefined") {
      if (!formData["adminName"].match(/^.{6,}$/) || formData["adminName"].trim().length < 5) {
        formIsValid = false;
        errors["adminName"] = "Please enter more than 5 characters";
      }
    }

    if (typeof formData["adminEmail"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(formData["adminEmail"])) {
        formIsValid = false;
        errors["adminEmail"] = "*Please enter valid email-ID.";
      }
    }

    if (!formData["validPeriod"]) {
      formIsValid = false;
      errors["validPeriod"] = "*Please select password valid period.";
    }

    if (formData["message"]) {
      formIsValid = false;
      errors["message"] = "*Please enter email message.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  handleClick = () => {
    this.state.type === 'password' ? this.setState({ type: 'text' }) : this.setState({ type: 'password' })
  }

  public render() {
    const { formData, validPeriod, editorState, editStateforSignature } = this.state;
    const { initialPassword, showErrorMessageForOrgName, showErrorMessageForAdminEmail, clearTypeahead } = this.props.OnboardingReducer;
    const { selecteEmailTemp } = this.props.EmailTemplatesReducers;
    const { loader } = this.props.LoaderReducer;
    let message = 'Already Existed'
    return (
      <div>
        {
          loader ?
            <PageLoader></PageLoader>
            : null
        }
        <div className="card nb-card-noborder">
          <div className="card-header bg-white">
            <h1>Invite Organization</h1>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="label-nb">Organization Name<span>*</span></label>
                      <input type="text" className="form-control" name="orgName" autoComplete="off"
                        value={formData.orgName ? formData.orgName : ''}
                        onChange={this.handleChange}
                      />
                      <div className="text-danger">{this.state.errors.orgName}</div>
                      <div className="text-danger">{showErrorMessageForOrgName ? message : ''}</div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="label-nb">Admin Name<span>*</span></label>
                      <input type="text" className="form-control" name="adminName" autoComplete="off" value={formData.adminName ? formData.adminName : ''} onChange={this.handleChange} />
                      <div className="text-danger">{this.state.errors.adminName}</div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="label-nb">Admin Email<span>*</span></label>
                      <input type="email" className="form-control" name="adminEmail" autoComplete="off" value={formData.adminEmail ? formData.adminEmail : ''} onChange={this.handleChange} />
                      <div className="text-danger">{this.state.errors.adminEmail}</div>
                      <div className="text-danger">{showErrorMessageForAdminEmail ? message : ''}</div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="label-nb">Initial Password<span>*</span></label>
                      <div className="input-group mb-3">
                        <input type={this.state.type} className="form-control" name="initialPassword" value={initialPassword} onChange={this.handleChange} readOnly />
                        <div className="input-group-append">
                          <button className="btn btn-nb-outline-primary" type="button" onClick={this.handleClick}>
                            {this.state.type === "text" ?
                              <i className="fas fa-eye-slash"></i>

                              :
                              <i className="fa fa-eye"></i>
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="label-nb">Valid Period<span>*</span></label>
                      <Typeahead data={validPeriod} onChange={this.handleOrgChange} onInputChange={this.handleInputChange}
                        clearTypeahead={clearTypeahead} placeholder="" disabled={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="label-nb">Email Message<span>*</span></label>
                      {/* <div className="card nb-card">
                        <div className="card-header bg-white border-bottom-0">
                          <img src={selecteEmailTemp && selecteEmailTemp.emailBanner} alt="Organization.png"
                          width='470px' height='100px'
                          />
                        </div>
                        <div className="card-body">
                          <Editor
                            editorState={editorState}
                            onEditorStateChange={(newEditorState) => this.onEditorChange(newEditorState)}
                            spellCheck={true}
                            readOnly={false}
                            toolbarHidden={true}
                          />
                          <p>--</p>
                          <Editor
                            editorState={editStateforSignature}
                            onEditorStateChange={this.onSignatureChange}
                            spellCheck={true}
                            readOnly={false}
                            toolbarHidden={true}
                          />
                        </div>
                      </div> */}
                      <textarea className="form-control" name="message" value={formData.message ? formData.message : ''} onChange={this.handleChange} style={{ minHeight: '314px' }} />
                      <div className="text-danger">{this.state.errors.emailmessage}</div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button type="button" className="btn btn-nb-gradient-primary btn-block rounded-pill"
                        onClick={this.addOrganizationInvitation}
                        disabled={Object.keys(this.state.formData).length < 6 || Object.values(this.state.formData).includes("") || this.state.errors.adminEmail}>
                        SEND
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const OnboardingReducer = state.OnboardingReducer;
  const EmailTemplatesReducers = state.EmailTemplatesReducers;
  const LoaderReducer = state.LoaderReducer;

  return { OnboardingReducer, EmailTemplatesReducers, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    onboardingAction: bindActionCreators(onboardingAction, dispatch),
    emailTemplateActions: bindActionCreators(emailTemplateActions, dispatch),
  }
}

const connectedNewOrganization = connect(mapStateToProps, mapDispatchToProps)(NewOrganization);
export { connectedNewOrganization as NewOrganization };
