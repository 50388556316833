import _ from 'lodash';
import * as React from 'react';
import ContentLoader from '../../../Common Components/Components/ContentLoader';
import RegistrationLayout from '../../Register/components/RegistrationLayout';
import { socket, SocketContext } from 'src/contexts/SocketContext';
// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ICreateWalletComponentProps {
  LoginReducer: any,
  createWalletAction: any,
  CreateWalletReducer: any,
  status?: any,
  LoaderReducer: any,
  loginAction: any,
}

export interface ICreateWalletComponentState {
  walletData: {
    walletName: any,
    password: any,
  }
  type: any,
}

export default class CreateWalletComponent extends React.Component<ICreateWalletComponentProps, ICreateWalletComponentState> {
  static contextType?= SocketContext

  constructor(props: ICreateWalletComponentProps) {
    super(props);

    this.state = {
      walletData: {
        walletName: '',
        password: '',
      },
      type: 'password',

    }
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.walleteLoader = this.walleteLoader.bind(this);
    // this.onFocus = this.onFocus.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    await this.connectSocket()
    console.log('componentDidMount - Create Wallet ')
  }

  async connectSocket() {
    if (socket) {
      socket.on('notification', (socketId, payload) => {
        console.log(`Create Wallet - SOCKET - notification - socketId: ${socketId} payload: ${JSON.stringify(payload)}`);
        if (payload && payload.data) {
          if (payload.data.type === 'AGENTSPINUP') {
            console.log(`Create Wallet AGENTSPINUP- receive Notification`)
            // this.props.notificationAction.getCountNotification()
            this.props.loginAction.getCurrentUser();
          } else if (payload.data.type === 'ERRORAGENTSPINUP') {
            this.props.createWalletAction.errorAgentSpinup();
          }
        }
      })
    } else {
      console.log("Could not estalished a connection")
    }
  }

  handleClick() {
    this.state.type === 'password' ? this.setState({ type: 'text' }) : this.setState({ type: 'password' })
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    event.persist();
    const { name, value } = event.target;
    const { walletData } = this.state;
    this.setState({
      walletData: {
        ...walletData,
        [name]: value
      }
    })
  }

  createWallet(event: any) {
    let { walletData } = this.state;
    let finalData = {
      walletName: walletData.walletName,
      walletPassword: walletData.password
    }

    this.props.createWalletAction.createWallet(finalData);
  }

  walleteLoader() {
    const { errorAgentSpinup } = this.props.CreateWalletReducer;
    return (
      <>
        {
          errorAgentSpinup ?
            <div className="card-body" style={{ minHeight: '250px' }}>
              <div className="row text-center" style={{ backgroundColor: 'rgb(255 255 255 / 69%)' }}>
                <div className="col-sm-12">
                  <ContentLoader />
                </div>
                <div className="col-sm-12">
                  <p className="text-danger">{t("screens:createWallet.messageCrashed")}</p>
                  <br />
                  <p className="text-danger">{t("screens:createWallet.messageRefresh")}</p>
                </div>
              </div>
            </div>
            :
            <div className="card-body" style={{ minHeight: '250px' }}>
              <div className="row text-center" style={{ backgroundColor: 'rgb(255 255 255 / 69%)' }}>
                <div className="col-sm-12">
                  <ContentLoader />
                </div>
                <div className="col-sm-12">
                  <p className="text-success"> {t("screens:createWallet.messageCreated")}</p>
                  <br />
                  <p className="text-success"> {t("screens:createWallet.messageWait")}</p>
                </div>
              </div>
            </div>
        }
      </>
    )
  }

  logout() {
    this.props.loginAction.logout()
  }

  public render() {
    const { loggedInUser } = this.props.LoginReducer;
    const { walletData, type } = this.state;
    const { loader } = this.props.LoaderReducer;
    const { createWallet, errorAgentSpinup } = this.props.CreateWalletReducer;
    const disablefield = !_.isEmpty(createWallet) && createWallet.agentSpinupStatus === 1;
    console.log("errorAgentSpinup", errorAgentSpinup);
    return (
      <>
        <RegistrationLayout>
          <div className="row animated fadeIn">
            <div className="col-sm-12">
              <h1 className="nb-h1">{t("screens:createWallet.createOrgWallet")}</h1>
              <hr className="nb-title-border-b" />
            </div>
            <div className="col-sm-12">
              <div className="card nb-border-radius shadow mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <img src={!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.logoUrl} style={{ maxHeight: '100px' }} />
                    </div>
                    <div className="col-sm-6 align-self-center">
                      <div className="media float-right">
                        <img src={images.placeholderProfile} className="align-self-center mr-3 user-icon" />
                        <div className="media-body">
                          <h1>{!_.isEmpty(loggedInUser) && loggedInUser.username}</h1>
                          <div>
                            <small>{!_.isEmpty(loggedInUser) && loggedInUser.email}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="card nb-border-radius">
                <div className="card-header nb-bg-light text-center p-4">
                  <h1 className="nb-h2">{t("screens:createWallet.createWallet")}</h1>
                </div>
                {loader === true ? this.walleteLoader() :
                  !_.isEmpty(createWallet) && createWallet.agentSpinupStatus === 1 ?
                    this.walleteLoader() :
                    !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.agentSpinupStatus &&
                      loggedInUser.organization.agentSpinupStatus === 1 ?
                      this.walleteLoader() :
                      <>
                        <div className="card-body" style={{ minHeight: '250px' }}>
                          <div className="row">
                            {/* <div className="col-sm-12">
                              <div className="form-group">
                                <label className="nb-label">Wallet Name<span>*</span></label>
                                <input type="text" className="form-control"
                                  name='walletName'
                                  autoComplete="off"
                                  value={walletData.walletName}
                                  onChange={this.handleChange}
                                  readOnly={disablefield}
                                // onFocus={this.onFocus}
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-sm-12">
                              <div className="form-group">
                                <label className="nb-label">Password<span>*</span></label>
                                <div className="input-group">
                                  <input type={type} className="form-control border-right-0"
                                    name='password'
                                    autoComplete="off"
                                    value={walletData.password}
                                    onChange={this.handleChange}
                                    readOnly={disablefield}
                                  />

                                  <div className="input-group-append">
                                    {/* <span className="input-group-text bg-white">
                                      <i className="fas fa-eye" onClick={this.handleClick}></i></span> */}
                            {/* <button className="btn btn-nb-outline-primary" type="button" onClick={this.handleClick}>
                                      {this.state.type === "text" ?
                                        <i className="fas fa-eye-slash"></i>

                                        :
                                        <i className="fa fa-eye"></i>
                                      }
                                    </button>
                                  </div>
                                </div>
                                <p className="text-warning">Note: Please note wallet password for future reference</p>
                              </div>
                            </div> */}
                            <div className="col-sm-12">

                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <button type="button" className="btn btn-nb-blue btn-block rounded-pill"
                                  // disabled={walletData.walletName !== "" && walletData.password !== "" ? false : true}
                                  onClick={(e) => this.createWallet(e)}
                                >{t("screens:createWallet.create")}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                }
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card nb-border-radius h-100">
                <div className="card-header nb-bg-light text-center p-4">
                  <h1 className="nb-h2 text-muted">{t("screens:createWallet.setPublicDid")}</h1>
                </div>
                <div className="card-body bg-light">
                </div>
              </div>
            </div>
            {/* <div className="col-sm-12 mt-5">
            <button className="btn btn-nb-outline-primary btn-block rounded-pill" onClick={this.logout}
              disabled={!errorAgentSpinup ? true : false}
            >HOME</button>
            </div> */}
          </div>
        </RegistrationLayout>
      </>
    );
  }
}
