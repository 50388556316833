import { t } from "i18next";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProblemReportComponent from "src/Common Components/Components/ProblemReportModal";
import TableComponents from "src/Common Components/Components/TableComponent";
import { ListScreenOptions } from "src/types/common.interface";
import { getConnectionName } from "src/utils/agent.utils";
import { camelCase } from "src/utils/strings";
import { PresentProofState } from "src/View/commonConst";
import { connectionAction } from "src/View/Connection/actions/connectionAction";
import { credentialAction } from "src/View/Credentials/actions/credentialAction";
// import { credentialProposalAction } from "src/View/CredentialProposal/actions/credentialProposalAction";
import { loginAction } from "src/View/Login/actions/loginAction";
import { myCredentialsAction } from "src/View/MyCredentials/action/myCredentialsAction";
import { presentationProposalAction } from "src/View/PresentationProposal/action/presentationProposalAction";
import CreatePresentationProposal from "src/View/PresentationProposal/components/CreatePresentationProposal";
import { schemaAction } from "src/View/Schema/actions/schemaAction";
import { TaskActions } from "src/View/Task/actions/taskActions";
import { presentationNegotiateAction } from "../actions/PresentationNegotiateAction";
import ProofCredentialsHeaderComponent from "../components/ProofCredentialsHeader.component";
import { SendProofSuccess } from "../components/SendProofSuccess";
import { transformPresentation, transformProofObject } from "../utils/PresentationNegotiate.utils";

interface IPresentationNegotiateProps {
    LoginReducer: any,
    schemaAction: any,
    loginAction: any,
    myCredentialsAction: any,
    presentationNegotiateAction: any,
    PresentationNegotiateReducer: any,
    SchemaReducer: any

    TaskReducers: any,
    TaskActions: any,
    connectionAction: any,
    ConnectionReducer: any,
    credentialAction: any,
    CredentialReducer: any,
    presentationProposalAction: any
    PresentationProposalReducer: any,
    MyCredentialReducer: any
}

interface IPresentationNegotiateState {
    listScreenOptions: ListScreenOptions,
    selectedPresentation: any,
    isModalProblemReportShow: boolean,
    requestDeclineReasonList: string[],
    presentationDeclineReasonList: string[],
    isRePropose: boolean,
    isNegotiateProposal: boolean,
    errorMessage: string,
    showError: boolean,
    retrievedCredentials: any[],
    presentationExchangeId: string,
    connectionRecord: any,
    credentialRecord: any,
    missingAttribute: any,
    isChange: boolean,
    proofRequestType: string,
    referentKey: string,
    presentationData: any,
    requestList: any[],
    selectedRequest: any,
    totalRecords: number,
    hideVerifyButton: boolean,
    proofRequest: any
}

class PresentationNegotiate extends React.Component<IPresentationNegotiateProps, IPresentationNegotiateState> {
    constructor(props: IPresentationNegotiateProps) {
        super(props);

        this.state = {
            listScreenOptions: {
                itemsPerPage: 10,
                pageNo: 1
            },
            selectedPresentation: {},
            isModalProblemReportShow: false,
            requestDeclineReasonList: [
                `${t("screens:negotiatePresentation.unTrust")}`,
                `${t("screens:negotiatePresentation.noCredential")}`,
                `${t("screens:negotiatePresentation.noInterest")}`
            ],
            presentationDeclineReasonList: [
                `${t("screens:negotiatePresentation.invalidPresentation")}`
            ],
            isRePropose: false,
            isNegotiateProposal: false,
            errorMessage: '',
            showError: false,
            retrievedCredentials: [],
            presentationExchangeId: '',
            connectionRecord: {},
            credentialRecord: {},
            missingAttribute: [],
            isChange: false,
            proofRequestType: '',
            referentKey: '',
            presentationData: {},
            requestList: [],
            selectedRequest: {},
            totalRecords: 0,
            hideVerifyButton: false,
            proofRequest: {}
        }
        this.onChangePage = this.onChangePage.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.sorting = this.sorting.bind(this);

        this.handleDeclinePresentation = this.handleDeclinePresentation.bind(this)
        this.handleDeclineModalClose = this.handleDeclineModalClose.bind(this)
        this.handleProblemReportSubmit = this.handleProblemReportSubmit.bind(this)

        this.transformProofObject = this.transformProofObject.bind(this)
        this.onClose = this.onClose.bind(this)
        this.updateSelfAttestedAttributeValue = this.updateSelfAttestedAttributeValue.bind(this)
        this.sendProof = this.sendProof.bind(this)
        this.verifyProof = this.verifyProof.bind(this)

    }

    componentDidMount() {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;
        const { presentationList } = this.props.PresentationNegotiateReducer;

        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)

        if (presentationList.length > 0) {
            this.props.presentationNegotiateAction.showSelectedCredentialDetails(presentationList[0])
        }

        this.props.myCredentialsAction.getAllCredentialOfferList(listScreenOptions);
        this.props.connectionAction.getAllConnectedOrganizations(loggedInUser.organization.id, listScreenOptions.pageNo, listScreenOptions.itemsPerPage, '')
        this.props.TaskActions.getAllCredDefForInstantTask();
        this.props.loginAction.getCurrentUser()
    }

    componentDidUpdate(prevProps: Readonly<IPresentationNegotiateProps>, prevState: Readonly<IPresentationNegotiateState>, snapshot?: any): void {
        if (
            JSON.stringify(prevProps.PresentationNegotiateReducer.presentationList) !== JSON.stringify(this.props.PresentationNegotiateReducer.presentationList)
            || JSON.stringify(this.state.requestList) !== JSON.stringify(this.props.PresentationNegotiateReducer.presentationList)

        ) {
            const { presentationList, totalPresentations } = this.props.PresentationNegotiateReducer
            console.log('did update', prevProps.PresentationNegotiateReducer.presentationList !== presentationList)
            this.setState({
                requestList: presentationList,
                selectedRequest: presentationList[0] ? presentationList[0] : {},
                totalRecords: totalPresentations
            }, () => {
                this.showDetails(this.state.selectedRequest)
            })

        }
        // hideVerifyButton
        if (prevProps.PresentationNegotiateReducer.hideVerifyButton !== this.props.PresentationNegotiateReducer.hideVerifyButton) {
            this.setState({
                hideVerifyButton: this.props.PresentationNegotiateReducer.hideVerifyButton
            })
        }

        if (prevProps.PresentationNegotiateReducer.isRePropose !== this.props.PresentationNegotiateReducer.isRePropose) {
            this.setState({
                isRePropose: this.props.PresentationNegotiateReducer.isRePropose
            })
        }
    }

    onChangePage(page: any) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page
        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
    }

    async transformProofObject(record: any, retrievedCredentials: any) {

        const { displayObject, missingAttributeArray }: any = await transformProofObject(record, retrievedCredentials)
        // console.log('displayObject====', displayObject)
        const proofRequest = record && JSON.parse(record.presentationRequest)

        this.setState({
            credentialRecord: displayObject,
            proofRequest
        })

        if (missingAttributeArray.length !== 0) {
            this.setState({
                missingAttribute: missingAttributeArray,
                errorMessage: t('screens:sendProof.errorNoProofCredentials'),
                showError: true
            })
        }
    }


    async showDetails(record: any) {
        let retrievedCredentials: any[] = []
        if (record
            && (record.proofState === PresentProofState.STATE_REQUEST_RECEIVED
                || record.proofState === PresentProofState.STATE_REQUEST_SENT
                || record.proofState === PresentProofState.STATE_ABANDONED
            )
        ) {
            if (record.proofState !== PresentProofState.STATE_REQUEST_SENT)
                retrievedCredentials = await this.props.presentationNegotiateAction.fetchCredentialsForPresentationRequest(record.presentationExchangeId)

            console.log('<<<retrievedCredentials', retrievedCredentials)
            if (!retrievedCredentials || retrievedCredentials.length === 0) {
                this.setState({
                    errorMessage: t('screens:sendProof.errorNoProofCredentials'),
                    showError: true
                })
            }
            else {
                this.setState({
                    errorMessage: t('screens:sendProof.errorNoProofCredentials'),
                    showError: false
                })
            }

            this.transformProofObject(record, retrievedCredentials)
        }
        let presentationData = {}
        if (record?.presentation) {
            const presentationRecord = await transformPresentation(record)

            presentationData = presentationRecord
            console.log('presentationRecord', presentationRecord)
        }

        this.setState({
            retrievedCredentials,
            presentationExchangeId: record?.presentationExchangeId,
            connectionRecord: record?.connection,
            selectedPresentation: record,
            presentationData,
            selectedRequest: record,
            hideVerifyButton: false,
            isRePropose: false
        })

        this.props.presentationNegotiateAction.showSelectedCredentialDetails(record)

        return retrievedCredentials;

    }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { listScreenOptions } = this.state;

        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions });
            this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
        }
    }

    sorting = (itemSortBy: string) => {
        const { listScreenOptions } = this.state;

        listScreenOptions.sort = (listScreenOptions.sort === 'DESC') ? 'ASC' : 'DESC'
        listScreenOptions.sortBy = itemSortBy
        this.setState({ listScreenOptions })
        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
    }

    refresh = () => {
        const listScreenOptions = {
            itemsPerPage: 10,
            pageNo: 1,
        }

        this.setState({ listScreenOptions })
        this.props.presentationNegotiateAction.getAllPresentationRequestsForNegotiate(listScreenOptions)
        const { presentationList } = this.props.PresentationNegotiateReducer;
        if (presentationList.length > 0) {
            this.props.presentationNegotiateAction.showSelectedCredentialDetails(presentationList[0])
        }
    }

    handleDeclinePresentation() {
        this.setState({ isModalProblemReportShow: true })
    }

    handleDeclineModalClose() {

        this.setState({
            isModalProblemReportShow: false,
        })

    }

    handleProblemReportSubmit(reason: string, exchangeId: string) {
        this.props.presentationNegotiateAction.sendProblemReportForPresentationExchange(reason, exchangeId)
        this.handleDeclineModalClose()
        this.refresh()

    }

    async sendProof() {
        try {
            const { credentialRecord, presentationExchangeId } = this.state

            const credentials: any = []
            const predicates: any = []
            let selfAttestedAttributes: any = {};

            // console.log('credentialRecord', credentialRecord)

            Object.keys(credentialRecord.requested_attributes).length
                && credentialRecord.requested_attributes.map((referentGroup: any) => {
                    // console.log('referentGroup', referentGroup)
                    const keys = Object.keys(referentGroup)[0].split('|');
                    const refCreds: any = Object.values(referentGroup)[0]
                    // console.log('keys', keys)

                    keys.map((item) => {
                        // console.log('item', item)
                        // console.log('referentGroup[item]', referentGroup[item])
                        const selectedCredential = refCreds.filter((i: any) => i.isSelected)[0]

                        // console.log('selectedCredential===', selectedCredential)

                        const object = {
                            credentialReferent: selectedCredential.cred_info.referent,
                            credentialId: (selectedCredential.cred_info).hasOwnProperty('cred_def_id') ?
                                selectedCredential.cred_info.cred_def_id :
                                selectedCredential.cred_info.schema_id,
                            reveal: true,
                            attributeKey: item

                        }
                        // console.log('object', object)

                        credentials.push(object)
                    });
                });

            Object.keys(credentialRecord.requested_predicates).length
                && credentialRecord.requested_predicates.map((referentGroup: any) => {
                    // console.log('p referentGroup', referentGroup)
                    const keys = Object.keys(referentGroup)[0].split('|');
                    const refCreds: any = Object.values(referentGroup)[0]
                    // console.log('keys', keys)

                    keys.map((item) => {
                        // console.log('item', item)
                        // console.log('referentGroup[item]', referentGroup[item])
                        const selectedCredential = refCreds.filter((i: any) => i.isSelected)[0]

                        // console.log('selectedCredential===', selectedCredential)

                        const object = {
                            credentialReferent: selectedCredential.cred_info.referent,
                            credentialId: (selectedCredential.cred_info).hasOwnProperty('cred_def_id') ?
                                selectedCredential.cred_info.cred_def_id :
                                selectedCredential.cred_info.schema_id,
                            attributeKey: item
                        };
                        // console.log('p object', object)
                        predicates.push(object);
                    });
                });

            Object.keys(credentialRecord.self_attested_attributes).length
                && credentialRecord.self_attested_attributes.map((referentGroup: any) => {
                    const keys = Object.keys(referentGroup)[0].split('|');
                    const refCreds: any = Object.values(referentGroup)[0]
                    // console.log('s keys', keys)

                    keys.map((item) => {
                        // console.log('s item', item)
                        // console.log('referentGroup[item]', referentGroup[item])
                        const selectedCredential = refCreds.filter((i: any) => i.isSelected)[0]

                        // console.log('selectedCredential===', selectedCredential)
                        Object.keys(selectedCredential.credentialInfo.attrs).map((attribute: any) => {
                            Object.assign(selfAttestedAttributes, {
                                [item]: selectedCredential.credentialInfo.attrs[attribute]
                            })
                        })
                    });
                })

            const proofPresentation = {
                "credentials": credentials,
                'predicates': predicates,
                selfAttestedAttributes,
                "presentationExchangeId": presentationExchangeId
            }

            console.log('proofPresentation', proofPresentation)

            /* Call the send proof API */
            this.props.presentationNegotiateAction.sendPresentation(proofPresentation)

        } catch (error) {
            console.error(error)
            this.setState({
                errorMessage: t('screens:sendProof.errorNoProofCredentials'),
                showError: true
            })
        }
    };

    verifyProof(data: any) {
        // const { showInstantTaskList, showAutomatedProofs } = this.props.reducer
        let presentationExchangeId = {
            presentationExchangeId: data.presentationExchangeId
        }

        // const initiator = showInstantTaskList ? taskConst.SHOW_INSTANT_TASK_LIST :
        //     showAutomatedProofs ? taskConst.SHOW_AUTOMATED_PROOFS :
        //         taskConst.SHOW_INSTANT_TASK_LIST

        this.props.presentationNegotiateAction.verifyProof(presentationExchangeId)
    }

    updateSelfAttestedAttributeValue(type: string, updateKey: string, event: any) {
        const { credentialRecord } = this.state;
        let tempCredentialRecord = credentialRecord;
        const selfAttestedAttributesKeys = credentialRecord[type]
        let tempCredentialList = selfAttestedAttributesKeys

        selfAttestedAttributesKeys?.map((referentGroup: any, index: number) => {

            let tempCredentialGroup = referentGroup
            Object.keys(referentGroup).map((key: any) => {
                // console.log('key', key)
                const referent = referentGroup[key]
                // console.log('referent', referent)

                referent?.map((credential: any, credIdx: number) => {
                    // console.log('credential', credential)
                    if (credential.isSelected) {
                        if (referent[credIdx].presentation_referents.findIndex((x: any) => x === updateKey) !== -1) {
                            referent[credIdx].credentialInfo.attrs[event.target.name] = event.target.value
                        }
                    }

                    tempCredentialGroup[key][credIdx] = credential
                })
                tempCredentialList[index] = tempCredentialGroup
            })
            tempCredentialRecord[type] = tempCredentialList
        })

        this.setState({
            credentialRecord: { ...tempCredentialRecord },
            isChange: false
        })
    };

    onChangeHandler(type: string, key: string, credentialId: string) {
        this.setState({
            isChange: true,
            referentKey: key,
            proofRequestType: type
        }, () => {
            this.checkboxHandler(credentialId)
        })
    };

    checkboxHandler(credentialId: string) {
        const { credentialRecord, proofRequestType, referentKey } = this.state


        let tempCredentialRecord = credentialRecord;
        const referentKeys = credentialRecord[proofRequestType]
        let tempCredentialList = referentKeys
        // console.log('referentKeys', referentKeys)


        if (Object.keys(referentKeys).length > 0) {
            referentKeys?.map((referentGroup: any, index: number) => {
                // console.log('referentGroup', referentGroup)
                let tempCredentialGroup = referentGroup
                // Object.keys(referentGroup).map((key: any, idx: number) => {
                // console.log('key', key)
                const referent = referentGroup[referentKey]
                // console.log('referent', referent)

                referent?.map((credential: any, credIdx: number) => {
                    // console.log('credential', credential)
                    // console.log('credentialId', credentialId)
                    if (credential.cred_info) {
                        if (credential.cred_info.referent === credentialId) {
                            credential.isSelected = true;
                        } else {
                            credential.isSelected = false;
                        }
                    }
                    else {
                        if (!credentialId) {
                            credential.isSelected = true
                        }
                        else {
                            credential.isSelected = false
                        }
                    }
                    // console.log('credential after', credential)
                    // tempCredentialRecord[proofRequestType][referentKey] = credential;
                    tempCredentialGroup[referentKey][credIdx] = credential
                })
                tempCredentialList[index] = tempCredentialGroup
                // console.log('tempCredentialGroup', tempCredentialGroup)
                // console.log(tempCredentialList)
                // })

            })
            tempCredentialRecord[proofRequestType] = tempCredentialList
            // console.log('tempCredentialRecord', tempCredentialRecord)
        }

        this.setState({
            credentialRecord: { ...tempCredentialRecord },
            isChange: false
        })
    };

    renderCredential(type: string) {
        const { credentialRecord, showError, selectedRequest } = this.state

        if (Object.keys(credentialRecord).length > 0) {
            const referentKeys = credentialRecord[type]
            // console.log('referentKeys', referentKeys)
            if (Object.keys(referentKeys).length > 0)
                return referentKeys?.map((referentGroup: any, index: number) => {
                    // console.log('referentGroup', referentGroup)

                    return Object.keys(referentGroup).map((key: any, idx: number) => {
                        // console.log('key', key)
                        const referent = referentGroup[key]
                        // console.log('referent', referent)

                        return referent?.map((credential: any) => {
                            // console.log('credential===', credential)
                            if (!credential.isSelected) {
                                // console.log('is selected')
                                return;
                            }
                            return (
                                <>
                                    <ProofCredentialsHeaderComponent
                                        credential={credential}
                                        index={index}
                                        referent={referent}
                                        type={type}
                                        onCheckBoxClick={(credentialId) => this.onChangeHandler(type, key, credentialId)}
                                    />
                                    {
                                        showError
                                        && !(credential.cred_info).hasOwnProperty('referent')
                                        && selectedRequest.proofState !== PresentProofState.STATE_REQUEST_SENT &&
                                        (
                                            <div className="card-body">
                                                <div className="blank-credential-details-body">
                                                    <label>{t("screens:presentProof.noCredentialAvailable")}</label>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            );
                        })

                    })

                })
        }
    };

    renderSelfAttestedAttributes(type: string) {
        const { credentialRecord, showError, selectedRequest } = this.state

        if (Object.keys(credentialRecord).length > 0) {

            const selfAttestedAttributesKeys = credentialRecord[type];

            return selfAttestedAttributesKeys.map((referentGroup: any) => {

                // console.log('s referentGroup', referentGroup)
                return Object.keys(referentGroup).map((key: any) => {
                    // console.log('s key', key)

                    const referent = referentGroup[key]
                    // console.log('referent', referent)
                    return referent?.map((credential: any, index: any) => {
                        // console.log('credential<<===', credential)
                        if (!credential.isSelected) {
                            // console.log('is selected')
                            return;
                        }
                        return (
                            <>
                                <ProofCredentialsHeaderComponent
                                    credential={credential}
                                    index={index}
                                    referent={referent}
                                    type={type}
                                    onCheckBoxClick={(credentialId) => this.onChangeHandler(type, key, credentialId)}
                                    updateSelfAttestedAttributeValue={(e) => this.updateSelfAttestedAttributeValue(type, key, e)}
                                />
                                {
                                    showError
                                    && selectedRequest.proofState !== PresentProofState.STATE_REQUEST_SENT &&
                                    (
                                        <div className="card-body">
                                            <div className="blank-credential-details-body">
                                                <label>{t("screens:presentProof.noCredentialAvailable")}</label>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        );
                    })

                })
            })

        }
    };

    reProposeProofRequest(credentialRecord: any, presentationExchangeId: string) {
        credentialRecord.presentationExchangeId = presentationExchangeId
        const pickedConnectionDetails = (({ id, connectionId, invitationKey, myDid, state, theirDid, theirLabel }) =>
            ({ id, connectionId, invitationKey, myDid, state, theirDid, theirLabel }))(this.state.connectionRecord);
        this.props.presentationNegotiateAction.reProposeProofRequest(credentialRecord, pickedConnectionDetails)
    }

    onClose() {
        const { presentationList } = this.props.PresentationNegotiateReducer

        if (presentationList.length > 0) {

            this.showDetails(presentationList[0])

        }
        // else {
        this.props.presentationNegotiateAction.onCloseSuccess()
        // this.setState({ requestedAttributesDetails: [] })
        // }
    }

    setCredential() {
        this.setState({
            isChange: false
        })
    };

    render(): React.ReactNode {
        const { tHead, selectedPresentationRecord, sendProofSuccess } = this.props.PresentationNegotiateReducer;
        const { isModalProblemReportShow, requestDeclineReasonList, presentationDeclineReasonList, isRePropose,
            credentialRecord, connectionRecord, isChange, showError, selectedPresentation, presentationData,
            requestList, totalRecords, selectedRequest, hideVerifyButton, presentationExchangeId, } = this.state

        let connectionName
        if (connectionRecord) {
            connectionName = getConnectionName(connectionRecord);
        }

        const declineReasonList = selectedPresentationRecord && selectedPresentationRecord.proofState === PresentProofState.STATE_REQUEST_RECEIVED
            ? requestDeclineReasonList
            : presentationDeclineReasonList

        return (
            <>
                <div className="row gutters-sm min-h-100">
                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:negotiatePresentation.presentationRequestList")}</h1>
                            </div>
                            <div className="card-body">
                                <TableComponents
                                    onChangePage={this.onChangePage}
                                    showElementDetails={this.showDetails}
                                    tHead={tHead}
                                    tableContent={requestList}
                                    totalRecords={totalRecords}
                                    selectedRecord={selectedRequest}
                                    sorting={this.sorting}
                                    searching={this.onSearch}
                                    refresh={this.refresh}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            {!sendProofSuccess && !isRePropose
                                // && !isDeclineSent 
                                &&
                                <>
                                    <div className="card-header bg-white border-bottom-0">
                                        <h1 className="nb-title">{t("screens:presentProof.requestDetails")}</h1>
                                    </div>
                                    <div className="card-body">
                                        {connectionName && connectionName !== '' ? (

                                            <div className="row ml-12">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <h5 className="font-weight-bold">{t("screens:presentProof.givenName")}: {connectionName} </h5>
                                                    </div>
                                                </div>
                                            </div>

                                        ) : null}
                                        <hr />
                                        {credentialRecord !== undefined && !Object.keys(presentationData).length
                                            && !isChange ? (
                                            <>
                                                <div className="row ml-12">
                                                    {this.renderCredential('requested_attributes')}
                                                </div>
                                                <div className="row ml-12">
                                                    {this.renderCredential('requested_predicates')}
                                                </div>
                                                <div className="row ml-12">
                                                    {this.renderSelfAttestedAttributes('self_attested_attributes')}
                                                </div>
                                            </>
                                        ) : null
                                        }
                                        {/* {console.log('presentationData', presentationData)} */}
                                        {
                                            (credentialRecord
                                                && Object.keys(credentialRecord).length > 0
                                                // && missingAttribute.length === 0
                                                && !Object.keys(presentationData).length
                                            )
                                                ? (
                                                    <>
                                                        {!isChange ? (
                                                            selectedPresentation.proofState === PresentProofState.STATE_REQUEST_RECEIVED ?
                                                                (<div className="row">
                                                                    <div className="col-sm-3 mt-2">
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                                                                            disabled={showError}
                                                                            onClick={this.sendProof}
                                                                        >
                                                                            {t("screens:presentProof.send")}
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                                                                            disabled={false}
                                                                            onClick={this.handleDeclinePresentation}
                                                                        >
                                                                            {t("screens:presentProof.decline")}
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-sm-3 mt-2">
                                                                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                                                                            disabled={false}
                                                                            onClick={() => this.reProposeProofRequest(credentialRecord, presentationExchangeId)}
                                                                        >
                                                                            {t("screens:presentProof.rePropose")}
                                                                        </button>
                                                                    </div>
                                                                </div>)
                                                                :
                                                                selectedPresentation.proofState === PresentProofState.STATE_PRESENTATION_RECEIVED ?
                                                                    (<div className="row">
                                                                        <div className="col-sm-3 mt-2">
                                                                        </div>
                                                                        <div className="col-sm-3 mt-2">
                                                                            <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                                                                                disabled={showError}
                                                                            // onClick={this.sendProof}
                                                                            >
                                                                                {t("screens:presentProof.verify")}
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-sm-3 mt-2">
                                                                            <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                                                                                disabled={false}
                                                                            // onClick={this.handleDeclinePresentation}
                                                                            >
                                                                                {t("screens:presentProof.decline")}
                                                                            </button>
                                                                        </div>

                                                                    </div>)
                                                                    : <></>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                )
                                                : <>
                                                    {
                                                        presentationData ?
                                                            <>
                                                                <div className="row mb-3" key={selectedPresentation?.id}>
                                                                    <div className="col-sm-12" key={selectedPresentation?.id}>

                                                                        {!_.isEmpty(presentationData) &&
                                                                            Object.keys(presentationData).map((key: any, index: any) => {

                                                                                if (key === 'undefined') {

                                                                                    return presentationData[key].map((internalArrayElement: any, intArrayIdx: number) => {
                                                                                        if (internalArrayElement.hasOwnProperty('self_attested_attrs')) {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="col-sm-12 form-group">
                                                                                                        <div className="card card-body">
                                                                                                            <div className="form-group">
                                                                                                                <label className="font-weight-bold">{t("screens:verificationMonitoring.selfAttestedAttributes")}:</label>
                                                                                                                <ul className="list-group list-group-flush" key={intArrayIdx}>
                                                                                                                    {
                                                                                                                        Object.keys(internalArrayElement).map((selfAttributes: any) => {
                                                                                                                            return Object.keys(internalArrayElement[selfAttributes]).map((self: any, selfIndex: number) => {

                                                                                                                                return (
                                                                                                                                    <li className="list-group-item" key={selfIndex}>
                                                                                                                                        <span className="nb-label">{self}: </span> {internalArrayElement[selfAttributes][self]}
                                                                                                                                    </li>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        })
                                                                                                                    }
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )

                                                                                        }
                                                                                        else {

                                                                                            return (
                                                                                                <> <div className="col-sm-12 form-group">
                                                                                                    <div className="card card-body">
                                                                                                        <div className="form-group">
                                                                                                            <label className="font-weight-bold">{t("screens:verificationMonitoring.credentialName")}: {presentationData[key].credentialName ? presentationData[key].credentialName : internalArrayElement.predicates.credentialName}</label>
                                                                                                            <p><b>{t("screens:verificationMonitoring.predicates")}:</b></p>
                                                                                                            <ul className="list-group list-group-flush" key={index}>
                                                                                                                {

                                                                                                                    Object.keys(internalArrayElement.predicates).map((predicateKey: any, predicateIdx: number) => {
                                                                                                                        if (predicateKey !== 'credentialName') {
                                                                                                                            const predicateKeyComputed = predicateKey === 'attr_name' ? `${t("screens:verificationMonitoring.attributeName")}` :
                                                                                                                                predicateKey === 'p_type' ? `${t("screens:verificationMonitoring.predicateType")}` :
                                                                                                                                    predicateKey === 'value' ? `${t("screens:verificationMonitoring.predicateValue")}` :
                                                                                                                                        ''

                                                                                                                            const predicateValue = internalArrayElement.predicates[predicateKey] === 'GT' ? '>' :
                                                                                                                                internalArrayElement.predicates[predicateKey] === 'LT' ? '<' :
                                                                                                                                    internalArrayElement.predicates[predicateKey] === 'EQ' ? '==' :
                                                                                                                                        internalArrayElement.predicates[predicateKey] === 'GE' ? '>=' :
                                                                                                                                            internalArrayElement.predicates[predicateKey] === 'LE' ? '<=' :
                                                                                                                                                internalArrayElement.predicates[predicateKey]
                                                                                                                            return (
                                                                                                                                <li className="list-group-item" key={predicateIdx}>
                                                                                                                                    <span className="nb-label">{predicateKeyComputed}: </span> {predicateValue}
                                                                                                                                </li>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })
                                                                                                                }

                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                </>
                                                                                            )
                                                                                        }

                                                                                    })

                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="col-sm-12 form-group">
                                                                                                <div className="card card-body">
                                                                                                    <div className="form-group">
                                                                                                        <label className="font-weight-bold">{t("screens:verificationMonitoring.credentialName")}: {key ? key : ''}</label>
                                                                                                        <p><b>{t("screens:verificationMonitoring.attributes")}:</b></p>
                                                                                                        <ul className="list-group list-group-flush" key={index}>
                                                                                                            {
                                                                                                                presentationData[key].map((attribute: any) => {

                                                                                                                    return Object.keys(attribute).map((key: any, attrIndex: number) => {

                                                                                                                        if (key !== 'credentialName' && key !== 'reveal') {
                                                                                                                            const attributeValue = attribute[key]

                                                                                                                            return (
                                                                                                                                attributeValue?.includes('data:image') ?
                                                                                                                                    <li className="list-group-item" key={attrIndex}>
                                                                                                                                        <label className="font-weight-bold">{key}: </label>
                                                                                                                                        <img src={attributeValue} alt={attribute} className="preview" height="50"></img>
                                                                                                                                    </li> :
                                                                                                                                    <li className="list-group-item" key={attrIndex}>
                                                                                                                                        <label className="font-weight-bold">{key}:  {
                                                                                                                                            !attribute['reveal'] ?
                                                                                                                                                `${attributeValue.substring(0, 15).replace(/.(?=)/g, '*')}...${attributeValue.substring(attributeValue.length - 15).replace(/.(?=)/g, '*')}` :
                                                                                                                                                attributeValue
                                                                                                                                        }
                                                                                                                                        </label>
                                                                                                                                    </li>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })

                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>)
                                                                                }
                                                                            })
                                                                        }

                                                                        <ul className="list-group list-group-flush">

                                                                            <li className="list-group-item text-success">
                                                                                {
                                                                                    !_.isEmpty(selectedPresentation) && selectedPresentation.verified === "true"
                                                                                        ? <span className="text-success"><i className="fas fa-check-circle text-success-light"></i> {t("screens:verificationMonitoring.messageVerified")}</span>
                                                                                        : !_.isEmpty(selectedPresentation) && selectedPresentation.verified === "false"
                                                                                            ? <span className="text-danger"><i className="fas fa-times-circle text-danger"></i> {t("screens:verificationMonitoring.messageRevoked")}</span>
                                                                                            : ''
                                                                                }
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6"></div>
                                                                    <div className="col-md-3">
                                                                        {
                                                                            !_.isEmpty(selectedPresentation) && selectedPresentation.proofState === "presentation_received" &&
                                                                            <button type='button' className="btn btn-primary btn-block rounded-pill"
                                                                                onClick={() => this.verifyProof(selectedPresentation)}
                                                                                disabled={hideVerifyButton ? true : false}
                                                                            >
                                                                                {t("screens:verificationMonitoring.verify")}
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        {
                                                                            !_.isEmpty(selectedPresentation) && selectedPresentation.proofState === "presentation_received" &&
                                                                            <button type='button' className="btn btn-primary btn-block rounded-pill"
                                                                                onClick={this.handleDeclinePresentation}
                                                                                disabled={hideVerifyButton ? true : false}
                                                                            >
                                                                                {t("screens:verificationMonitoring.decline")}
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    {/* <div className="col-md-3">
                                                                        <Button variant="secondary" className="btn btn-secondary btn-block rounded-pill" onClick={closeModal}>
                                                                            {t("screens:verificationMonitoring.close")}
                                                                        </Button>
                                                                    </div> */}
                                                                </div>
                                                            </>
                                                            :
                                                            <h1>No Proofs</h1>
                                                    }
                                                </>
                                        }
                                    </div>
                                </>
                            }
                            {sendProofSuccess && !isRePropose &&
                                <>
                                    <div className="card-header bg-white border-bottom-0">
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <h1 className="nb-title">{t("screens:presentProof.requestDetails")}</h1>
                                            </div>
                                            <div className="col-sm-2 text-right">
                                                <button type="button" className="btn"
                                                    onClick={this.onClose}
                                                >
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <SendProofSuccess />
                                    </div>
                                </>
                            }
                            {
                                isRePropose &&
                                <CreatePresentationProposal {...this.props} />
                            }

                        </div>
                    </div>
                </div>
                {
                    Object.keys(selectedPresentationRecord).length && isModalProblemReportShow &&
                    <ProblemReportComponent
                        isModalShow={isModalProblemReportShow}
                        reasonList={declineReasonList}
                        selectedRecordId={selectedPresentationRecord.presentationExchangeId}
                        selectedRecordGivenName={selectedPresentationRecord?.connectionName}
                        closeModal={this.handleDeclineModalClose}
                        handleProblemReportSubmit={this.handleProblemReportSubmit}
                        modalTitle={t("screens:negotiatePresentation.presentationDeclined")}
                        credentialState={t(`screens:negotiatePresentation.${camelCase(selectedPresentationRecord?.proofState)}`)}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentationNegotiateReducer = state.PresentationNegotiateReducer;
    const LoginReducer = state.LoginReducer;
    const SchemaReducer = state.SchemaReducer;
    const ConnectionReducer = state.ConnectionReducer;
    const TaskReducers = state.TaskReducers;
    const CredentialReducer = state.CredentialReducer;
    const PresentationProposalReducer = state.PresentationProposalReducer;
    const MyCredentialReducer = state.MyCredentialReducer;

    return {
        LoginReducer,
        SchemaReducer,
        PresentationNegotiateReducer,
        ConnectionReducer,
        TaskReducers,
        CredentialReducer,
        PresentationProposalReducer,
        MyCredentialReducer,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        presentationNegotiateAction: bindActionCreators(presentationNegotiateAction, dispatch),
        loginAction: bindActionCreators(loginAction, dispatch),
        schemaAction: bindActionCreators(schemaAction, dispatch),
        myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
        TaskActions: bindActionCreators(TaskActions, dispatch),
        connectionAction: bindActionCreators(connectionAction, dispatch),
        credentialAction: bindActionCreators(credentialAction, dispatch),
        presentationProposalAction: bindActionCreators(presentationProposalAction, dispatch),
    }
}

const presentationNegotiate = connect(mapStateToProps, mapDispatchToProps)(PresentationNegotiate);
export { presentationNegotiate as PresentationNegotiate };
