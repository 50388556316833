const DisplayVPCredentialCommentComponent = ({ data }: any) => {
    const renderDetails = (details: any) => {
      return Object.keys(details).map((key, index) => {
        const value = details[key];
  
        if (typeof value === 'object' && value !== null) {
          console.log(value)
          if (key === 'box') {
            return (
              <div key={index} className="row pt-2">
                {renderBoxDetails(value)}
              </div>
            );
          } else if (key === 'list') {
            return (
              <ul key={index} className="list">
                {Object.keys(value).map((subKey, subIndex) => (
                  <li key={subIndex} className="remove-underline">
                    {subKey.replace(/_|-/g, ' ')}: {value[subKey]}
                  </li>
                ))}
              </ul>
            );
          } else {
            return (<label className="font-weight-bold  pt-2">{key.replace(/_|-/g, ' ')}: {renderDetails(value)}</label>)
          }
        } else {
          return (
            <div key={index} className="default">
              {key}: {value}
            </div>
          );
        }
      });
    };
  
    const renderBoxDetails = (boxDetails: any) => {
      return Object.keys(boxDetails).map((key, index) => (
        <div className='col-md-3'>
          <div className="card nb-card shadow">
            <div className="card-header bg-white border-bottom-0 p-2">
              <h6>{key}</h6>
            </div>
            <div className="card-body">
              <p className='text-primary'>{boxDetails[key]}</p>
            </div>
          </div>
        </div>
      ))
    };
  
    return <div>{renderDetails(data)}</div>;
  };
  
  export default DisplayVPCredentialCommentComponent;