import './display-comment.css'

export const DisplayCards = ({ boxDetails }: any) => {
    return (
        <div className="row form-group">
            {boxDetails.map((details: any, index: any) => (
                <div className='col-md-3 pr-0'>
                    <div className="card nb-card shadow" style={{ height: '135px' }}>
                        <div className="document-label p-2 pt-3">
                            {details.label}
                        </div>
                        <div className="card-body vertical-align p-2">
                            <div className='document-value'>
                                {details.value}
                            </div>
                        </div>
                    </div>
                </div>

            ))}
        </div>
    )
}
