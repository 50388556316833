import { importCredentials } from "../../types";

const initialState = {
    importCredentialList: [{}],
    importCredentialTHead: [],
    isImportCredentialSuccess: false,
    isShowSelectedImportCredential: false,
    newImportCredentialRecord: {},
    hasNextPage: false,
    hasPreviousPage: false,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    totalItems: 0,
    selectedImportCredential: {}
}
/* Column name for connection list table */
let importCredentialTHead = [{
    'name': 'GIVEN NAME',
    'label': "connectionName"
}, {
    'name': 'CREDENTIAL NAME',
    'label': "credentialDefinitionId"
}, {
    'name': 'DATE',
    'label': "createDateTime"
}, {
    'name': 'ACTION',
    'label': "details"
}];
export function ImportCredentialsReducer(state = initialState, action: any) {
    switch (action.type) {
        /* Get all on-boarded organization list API responses and set them into corresponding variables. */
        case importCredentials.GET_IMPORT_CREDENTIAL_LIST:
            return Object.assign({},
                state,
                {
                    importCredentialList: action.payload.data.data.data,
                    hasPreviousPage: action.payload.data.data.hasPreviousPage,
                    hasNextPage: action.payload.data.data.hasNextPage,
                    lastPage: action.payload.data.data.lastPage,
                    nextPage: action.payload.data.data.nextPage,
                    totalItems: action.payload.data.data.totalItems,
                    previousPage: action.payload.data.data.previousPage,
                    importCredentialTHead: importCredentialTHead,
                });
        case importCredentials.ADD_NEW_IMPORT_CREDENTIALS_RECORD:
            return Object.assign({},
                state,
                {
                    newImportCredentialRecord: action.payload,
                    isImportCredentialSuccess: true
                });
        case importCredentials.IMPORT_ANOTHER_CREDENTIAL:
            return Object.assign({},
                state,
                {
                    isImportCredentialSuccess: false
                });
        case importCredentials.SHOW_SELECTED_IMPORT_CREDENTIAL_RECORD:
            return Object.assign({},
                state,
                {
                    selectedImportCredential: action.payload,
                    isShowSelectedImportCredential: true
                });
        case importCredentials.REMOVE_SELECTED_IMPORT_CREDENTIAL_RECORD:
            return Object.assign({},
                state,
                {
                    isShowSelectedImportCredential: false
                });
        default:
            return state;
    }
}
