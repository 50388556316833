import { dashboardConst, userConstants } from "../../types";

const initialState = {
  countForDashboard: [],
  countForPADashboard: []
}

export function DashboardReducer(state = initialState, action: any) {
  switch (action.type) {
    case dashboardConst.GET_ALL_DASHBOARD_COUNT:
      return Object.assign({},
        state,
        {
          countForDashboard: action.payload.data.data
        });
    case dashboardConst.GET_ALL_PA_DASHBOARD_COUNT:
      return Object.assign({},
        state,
        {
          countForPADashboard: action.payload.data.data
        });
    default:
      return state
  }
}