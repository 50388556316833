import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { parseSchema } from 'src/utils/agent.utils';
import ProofAttributeComponent from '../../PresentProof/components/ProofAttribute.component';

// interface Props extends WithTranslation<['common', 'screens']> {
interface Props {
    referent: any;
    index: number;
    credential: any;
    type: string;
    // onChangeHandler: () => void;
    onCheckBoxClick: (credentialId: string) => void;
    updateSelfAttestedAttributeValue?: (e: any) => void
}

const ProofCredentialsHeaderComponent: React.FC<Props> = ({
    referent,
    index,
    credential,
    type,
    onCheckBoxClick,
    updateSelfAttestedAttributeValue
}) => {

    const [showHideElements, setShowHideElements] = useState<any>([]);
    const [isShowAll, setIsShowAll] = useState(true);

    useEffect(() => {
        setShowHideElements(
            Array(credential.cred_info && Object.entries(credential.cred_info?.attrs).length).fill(
                isShowAll,
            ),
        );
    }, [credential]);

    const onShowHideAllClick = () => {
        // let newArr = [...showHideElements];
        // if (!isShowAll) {
        //     newArr.fill(showHideElements.length).fill(true);
        // } else {
        //     newArr.fill(showHideElements.length).fill(false);
        // }
        // setShowHideElements(newArr);
        // setIsShowAll(!isShowAll);
    };

    const attributes = type === 'requested_attributes' || type === 'self_attested_attributes' ?
        Object.entries(credential.credentialInfo.attrs) : Object.entries(credential.credentialInfo.predAttrs)

    let schemaOrCredDef
    if (type === 'requested_attributes') {
        schemaOrCredDef = credential.cred_info && credential.cred_info.schema_id
            ? 'schema'
            : credential.cred_info && credential.cred_info.cred_def_id
                ? 'credDef'
                : ''

    }

    return (
        <div className="col-sm-12 form-group">
            <div className="card card-body">
                <div className="form-group">
                    {
                        <>
                            {
                                type === 'request_attributes' ?
                                    <label className="font-weight-bold">{type === schemaOrCredDef && `${t("screens:presentProof.credentialName")}:`} {schemaOrCredDef !== '' && schemaOrCredDef === 'schema' && parseSchema(credential.cred_info !== null ? credential.cred_info.schema_id : 'y').name}
                                        {schemaOrCredDef !== '' && schemaOrCredDef === 'credDef' && (credential.cred_info.cred_def_id).split(':')[4]} {!schemaOrCredDef && t("screens:verificationMonitoring.selfAttestedAttributes")}</label>
                                    : type === 'self_attested_attributes'
                                        ? t("screens:verificationMonitoring.selfAttestedAttributes")
                                        : <label className="font-weight-bold">{t("screens:presentProof.credentialName")}: {parseSchema(credential.cred_info !== null ? credential.cred_info.schema_id : '').name}</label>
                            }
                            {
                                credential.cred_info?.schema_id === '' && <p className='text-danger'>No credential match the proof request</p>
                            }
                        </>
                    }
                </div>
                {
                    referent.length > 1 &&
                    <div className="form-group">
                        <span className='text-info mb-1'>Please select credential to present</span>
                    </div>
                }

                <div className='col-sm-12'>
                    <div className='row'>

                        {referent.length > 1 ? (
                            referent.map((ref: any) => {
                                return (<div className="col-sm-4" key={index} >
                                    <label className="align-self-center checkbox-inline" htmlFor=''>
                                        <input type="checkbox" id='Credential' key='allAttributes'
                                            name={`credential-${index}`}
                                            checked={ref.isSelected}
                                            onChange={(e) => onCheckBoxClick(ref.cred_info?.referent)}
                                        />{` ${ref.cred_info ? ref.cred_info.cred_def_id.split(":")[4] : 'Enter Manually'}`}</label>
                                </div>)
                            })
                        ) : null}
                    </div>

                </div>
                <div className='col-sm-12'>
                    {
                        attributes
                            .sort()
                            .map(([name, value], j) => {
                                return (
                                    <ul className="fa-ul nb-list">

                                        {credential.cred_info ?

                                            <ProofAttributeComponent
                                                showAllAttribute={showHideElements[j]}
                                                value={value as string}
                                                name={name}
                                                index={j}
                                                onLinkClick={(i) => {
                                                    let newArr = [...showHideElements];
                                                    newArr[i] = newArr[i] ? false : true;
                                                    setShowHideElements(newArr);
                                                    setIsShowAll(newArr.filter(Boolean).length < newArr.length / 2);
                                                }}
                                            />
                                            :
                                            <>
                                                <label className="align-self-center checkbox-inline" htmlFor=''>{name}: </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name={name}
                                                    id="attributeValue"
                                                    value={value as string}
                                                    placeholder={name}
                                                    onChange={(e) => updateSelfAttestedAttributeValue?.(e)}
                                                />
                                            </>
                                        }
                                    </ul>
                                );
                            })
                    }
                </div>
            </div>

        </div>

    );
};
// export default withTranslation(['common', 'screens'])(
//     ProofCredentialsHeaderComponent,
// );

export default ProofCredentialsHeaderComponent
