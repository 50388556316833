import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { importCredentialsAction } from '../actions/ImportCredentialAction';

export interface IImportCredentialsListProps {
    // importCredentialList: Array<any>,
    // totalOrganization: number,
    // importCredentialTHead: Array<any>,
    importCredentialsAction: any,
    ImportCredentialsReducer: any
}

export interface IImportCredentialsListState {
    searchFields: string,
    item_perPage: number,
    pageNo: number
}

class ImportCredentialsList extends React.Component<IImportCredentialsListProps, IImportCredentialsListState> {
    constructor(props: IImportCredentialsListProps) {
        super(props);

        this.state = {
            searchFields: '',
            item_perPage: 10,
            pageNo: 1

        }
        this.onChangePage = this.onChangePage.bind(this)
        this.selectedImportCredentialRecord = this.selectedImportCredentialRecord.bind(this)
        this.search = this.search.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    componentDidMount() {
        const { searchFields, item_perPage, pageNo } = this.state
        this.props.importCredentialsAction.getImportCredentialList(searchFields, item_perPage, pageNo)
    }

    onChangePage(page: any) {
        const { searchFields, item_perPage } = this.state
        this.setState({ pageNo: page })
        this.props.importCredentialsAction.getImportCredentialList(searchFields, item_perPage, page)
    }

    selectedImportCredentialRecord(selectedRecordDetails: any) {
        console.log("selected cred details---", selectedRecordDetails);
        this.props.importCredentialsAction.showSelectedImportCredentialRecord(selectedRecordDetails)
    }

    search(event: React.KeyboardEvent<HTMLInputElement> | any) {
        const { item_perPage, pageNo } = this.state
        if (event.key === 'Enter') {
            this.setState({ searchFields: event.target.value })
            this.props.importCredentialsAction.getImportCredentialList(event.target.value, item_perPage, pageNo)
        }
    }

    refresh() {
        const { searchFields, item_perPage, pageNo } = this.state
        this.props.importCredentialsAction.getImportCredentialList(searchFields, item_perPage, pageNo)
    }

    public render() {
        const { importCredentialTHead, importCredentialList, totalItems } = this.props.ImportCredentialsReducer
        return (
            <>
                <TableComponents
                    onChangePage={this.onChangePage}
                    showElementDetails={this.selectedImportCredentialRecord}
                    tHead={importCredentialTHead}
                    tableContent={importCredentialList}
                    totalRecords={totalItems}
                    // sorting={this.sorting}
                    searching={this.search}
                    // filter={filterCredRequestWaitingList}
                    refresh={this.refresh}
                // onChangeFilter={this.filter}
                />
            </>
        );
    }
}


function mapStateToProps(state: any) {
    return {
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        importCredentialsAction: bindActionCreators(importCredentialsAction, dispatch),
    }
}

const connectedImportCredentialsList = connect(mapStateToProps, mapDispatchToProps)(ImportCredentialsList);
export { connectedImportCredentialsList as ImportCredentialsList };
