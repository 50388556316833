import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ListScreenOptions } from "src/types/common.interface";
import { CredentialState } from "src/View/commonConst";
import { issuanceAction } from "src/View/IssueCredential/actions/issuanceAction";
import TableComponents from "../../../Common Components/Components/TableComponent";
import { credentialOfferAction } from "../actions/credentialOfferAction";

/* Interface for Props variables*/
interface ICredentialOfferListProps {
    IssuanceReducer?: any
    issuanceAction?: any,
    credentialOfferList: any,
    selectedOfferRecord: any
}

/* Interface for local states variables*/
interface ICredentialOfferListState {
    showCredentialDetails: boolean,
    listScreenOptions: ListScreenOptions
}

class CredentialOfferList extends React.Component<ICredentialOfferListProps, ICredentialOfferListState> {
    constructor(props: ICredentialOfferListProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {            
            showCredentialDetails: false,
            listScreenOptions: {
                pageNo: 1,
                itemsPerPage: 10,
                sortBy: '',
                sort: '',
                search: '',
                filter: CredentialState.STATE_OFFER_SENT
            }
        }

        /* Binding the method */
        this.onChangePage = this.onChangePage.bind(this)

        this.search = this.search.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    componentDidMount(): void {
        const { listScreenOptions } = this.state;
        this.props.issuanceAction.getAllCredentialRequest(listScreenOptions);
    }

    /*Method used to set selected page no form pagination */
    onChangePage(page: number) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page
        this.setState({ 
            listScreenOptions 
        })
        // const { tHead, credentialReqData: credentialProposalList, totalCredentialReq } = this.props.IssuanceReducer;
        this.props.issuanceAction.getAllCredentialRequest(listScreenOptions);
        // if (credentialOfferReqData.length > 0) {
        //     this.props.myCredentialsAction.showSelectedCredentialDetails(credentialOfferReqData[0])
        // }
    }

    /* Method used to search the content from list */
    search(event: React.KeyboardEvent<HTMLInputElement> | any) {
        const { listScreenOptions } = this.state;
        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions })
            this.props.issuanceAction.getAllCredentialRequest(listScreenOptions);
        }
    }

    /* Method used to refresh the present proof request list */
    refresh() {
        const defaultPayload = {
            pageNo: 1,
            itemsPerPage: 10,
            sortBy: '',
            sort: '',
            search: '',
            filter: CredentialState.STATE_OFFER_SENT
        }
        this.setState({listScreenOptions: defaultPayload})
        this.props.issuanceAction.getAllCredentialRequest(defaultPayload);
    }

    /* UI to show proof request list */
    public render() {
        const { tHead, credentialReqData: credentialOfferList, totalCredentialReq } = this.props.IssuanceReducer;

        return (
            <>
                <div>
                    <TableComponents
                        onChangePage={this.onChangePage}
                        showElementDetails={this.props.selectedOfferRecord}
                        tHead={tHead}
                        tableContent={credentialOfferList}
                        totalRecords={totalCredentialReq}
                        searching={this.search}
                        refresh={this.refresh}
                    // selectedRecord={}
                    />
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const IssuanceReducer = state.IssuanceReducer;

    return {  IssuanceReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        credentialOfferAction: bindActionCreators(credentialOfferAction, dispatch),
        issuanceAction: bindActionCreators(issuanceAction, dispatch),
    }
}

const credentialOfferList = connect(mapStateToProps, mapDispatchToProps)(CredentialOfferList);
export { credentialOfferList as CredentialOfferList };
