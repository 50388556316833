import * as React from 'react';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { filterAgentStatusList, tHeadAgentStatus } from '../constants';

export interface IAgentStatusListProps {
    agentStatusAction: any,
    AgentStatusReducer: any,
    // editConnection?: any,
}

export interface IAgentStatusListState {
    defaultSearchFields: any,
    item_perPage: any,
    pageNo: any,
    sortingOrder: any,
    status: any,
}

export default class AgentStatusList extends React.Component<IAgentStatusListProps, IAgentStatusListState> {
    constructor(props: IAgentStatusListProps) {
        super(props);

        this.state = {
            defaultSearchFields: '',
            item_perPage: 10,
            pageNo: 1,
            sortingOrder: 'DESC',
            status: 'all'
        }
        // this.showDetails = this.showDetails.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.sorting = this.sorting.bind(this);
        this.filter = this.filter.bind(this);
    }

    componentWillUnmount(){
        this.setState({
            defaultSearchFields: '',
            item_perPage: 10,
            pageNo: 1,
            sortingOrder: 'DESC',
            status: 'all'
        })
    }

    onChangePage(page: any) {
        const { defaultSearchFields, item_perPage, sortingOrder, status } = this.state;
        // this.props.agentStatusAction.updatePageNo(page);
        this.props.agentStatusAction.getAllAgentStatusList(defaultSearchFields, status, sortingOrder, item_perPage, page);
    }

    // showDetails(item: object | any) {
    //     this.props.agentStatusAction.selectConnection(item);
    // }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { item_perPage, sortingOrder, pageNo, status } = this.state;
        if (event.key === 'Enter') {
            this.setState({ defaultSearchFields: event.target.value });
            this.props.agentStatusAction.getAllAgentStatusList(event.target.value, status, sortingOrder, item_perPage, pageNo);
        }
    }

    sorting = (connectionSortBy: string) => {
        const { defaultSearchFields, item_perPage, pageNo, status } = this.state;
        this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC' }) : this.setState({ sortingOrder: 'DESC' });
        let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
        this.props.agentStatusAction.getAllAgentStatusList(defaultSearchFields, status, sortValue, item_perPage, pageNo);
    }


    refresh = (event: any) => {
        const { defaultSearchFields, item_perPage, pageNo, sortingOrder, status } = this.state;
        this.props.agentStatusAction.getAllAgentStatusList(defaultSearchFields, status, sortingOrder, item_perPage, pageNo);
    }

    filter = (selectedFilterValue: any) => {
        const { defaultSearchFields, item_perPage, pageNo, sortingOrder } = this.state;

        this.props.agentStatusAction.getAllAgentStatusList(defaultSearchFields, selectedFilterValue, sortingOrder, item_perPage, pageNo);

        this.setState({status:selectedFilterValue})
    }

    public render() {
        const {
            agentStatusList,
            totalAgents,
        } = this.props.AgentStatusReducer;
        return (
            <>
                <TableComponents
                    onChangePage={this.onChangePage}
                    // showElementDetails={this.showDetails}
                    tHead={tHeadAgentStatus}
                    tableContent={agentStatusList}
                    totalRecords={totalAgents}
                    // selectedRecord={selectedConnection}
                    sorting={this.sorting}
                    searching={this.onSearch}
                    refresh={this.refresh}
                    filter={filterAgentStatusList}
                    onChangeFilter={this.filter}
                />
            </>
        );
    }
}
