import { t } from 'i18next';

export const negotiateCredentialTableHeadings = [{
  'name': `${t("screens:issueCredentials.headDate")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:issueCredentials.headGivenName")}`,
  'label': "connectionName"
}, {
  'name': `${t("screens:issueCredentials.headCredentialName")}`,
  'label': "credentialDefinitionId"
}, {
  'name': `${t("screens:issueCredentials.headStatus")}`,
  'label': "state"
}, {
  'name': `${t("screens:issueCredentials.headAction")}`,
  'label': 'proposalViewBtn'
}]

export enum PreferredTrustMethod {
  Trust_Decision_Helper = 'Trust Decision Helper',
  Accept_Credential = 'Accept Credential'
}
