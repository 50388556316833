import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OnLedgerListAction } from '../actions/OnLedgerListActions';
import { OnLedgerList } from '../components/OnLedgerList';

export interface IOnLedgerProps {
  OnLedgerListAction: any
}

export interface IOnLedgerState {
}

class OnLedger extends React.Component<IOnLedgerProps, IOnLedgerState> {
  constructor(props: IOnLedgerProps) {
    super(props);

    this.state = {
    }
    this.onStopOrganization = this.onStopOrganization.bind(this)
    this.onStartOrganization = this.onStartOrganization.bind(this)
    this.onRemoveOrganization = this.onRemoveOrganization.bind(this)
  }

  onStopOrganization = (organizationId: number,
    defaultSearchFields: any,
    status: any,
    kycRecordListSortBy: any,
    sortingOrder: any,
    item_perPage: number,
    pageNo: number,
  ) => {
    this.props.OnLedgerListAction.stopOrganization(organizationId,
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    )

  }

  onStartOrganization = (organizationId: number,
    defaultSearchFields: any,
    status: any,
    kycRecordListSortBy: any,
    sortingOrder: any,
    item_perPage: number,
    pageNo: number,
  ) => {
    this.props.OnLedgerListAction.startOrganization(organizationId,
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    )

  }

  onRemoveOrganization = (organizationId: number,
    defaultSearchFields: any,
    status: any,
    kycRecordListSortBy: any,
    sortingOrder: any,
    item_perPage: number,
    pageNo: number,
  ) => {
    this.props.OnLedgerListAction.removeOrganization(organizationId,
      defaultSearchFields,
      status,
      kycRecordListSortBy,
      sortingOrder,
      item_perPage,
      pageNo
    )

  }

  public render() {
    return (
      <>
        <OnLedgerList
          onStopOrganization={this.onStopOrganization}
          onStartOrganization={this.onStartOrganization}
          onRemoveOrganization={this.onRemoveOrganization}
        />
      </>
    );
  }
}



function mapStateToProps(state: any) {
  return {
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    OnLedgerListAction: bindActionCreators(OnLedgerListAction, dispatch),
  }
}

const connectedOnLedger = connect(mapStateToProps, mapDispatchToProps)(OnLedger);
export { connectedOnLedger as OnLedger };
