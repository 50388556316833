import _ from 'lodash';
import * as React from 'react';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ISuccessPresentationRequestProps {
  PresentationRequestAction: any,
  PresentationRequestReducer: any

}

export interface ISuccessPresentationRequestState {
}

export default class SuccessPresentationRequest extends React.Component<ISuccessPresentationRequestProps, ISuccessPresentationRequestState> {
  constructor(props: ISuccessPresentationRequestProps) {
    super(props);

    this.state = {
    }
  }

  createAnotherRequest(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    this.props.PresentationRequestAction.createNewRequest();
  }

  public render() {
    let { selectedPresentationRecord } = this.props.PresentationRequestReducer;
    let schemaName = ''
    let connectionName = ''
    if (selectedPresentationRecord && selectedPresentationRecord.presentationRequest) {
      let jsonAttibutes = JSON.parse((selectedPresentationRecord.presentationRequest))
      if (jsonAttibutes['requested_attributes'].hasOwnProperty('additionalProp1')) {
        if (jsonAttibutes['requested_attributes']['additionalProp1']['restrictions'][0].hasOwnProperty('schema_id')) {
          schemaName = jsonAttibutes['requested_attributes']['additionalProp1']['restrictions'][0].schema_id?.split(':')[2]
        } else if (jsonAttibutes['requested_attributes']['additionalProp1']['restrictions'][0].hasOwnProperty('cred_def_id')) {
          schemaName = jsonAttibutes['requested_attributes']['additionalProp1']['restrictions'][0].cred_def_id?.split(':')[4]
        }
      } else {
        if (jsonAttibutes['requested_attributes'].hasOwnProperty('0_name_uuid')) {
          if (jsonAttibutes['requested_attributes']['0_name_uuid']['restrictions'][0].hasOwnProperty('schema_id')) {
            schemaName = jsonAttibutes['requested_attributes']['0_name_uuid']['restrictions'][0].schema_id?.split(':')[2]
          } else if (jsonAttibutes['requested_attributes']['0_name_uuid']['restrictions'][0].hasOwnProperty('cred_def_id')) {
            schemaName = jsonAttibutes['requested_attributes']['0_name_uuid']['restrictions'][0].cred_def_id?.split(':')[4]
          }
        } else {
          if (jsonAttibutes['requested_attributes'].hasOwnProperty('0_number_uuid')) {
            if (jsonAttibutes['requested_attributes']['0_number_uuid']['restrictions'][0].hasOwnProperty('schema_id')) {
              schemaName = jsonAttibutes['requested_attributes']['0_number_uuid']['restrictions'][0].schema_id?.split(':')[2]
            } else if (jsonAttibutes['requested_attributes']['0_number_uuid']['restrictions'][0].hasOwnProperty('cred_def_id')) {
              schemaName = jsonAttibutes['requested_attributes']['0_number_uuid']['restrictions'][0].cred_def_id?.split(':')[4]
            }
          }
        }
      }
      connectionName = !_.isEmpty(selectedPresentationRecord.presentationRequest) ? selectedPresentationRecord.connectionName : ""
    }

    return (
      <>
        <div className="card h-100">
          <div className="card-body text-center">
            <div className="row">
              <div className="col-sm-12 mb-5 mt-5">
                <img src={images.illustrationSuccess} alt="Success" />
              </div>
              <div className="col-sm-12">
                <h3 className="nb-label">{t("screens:newProofRequest.messageRequest1")}
                  <b>{schemaName} </b>{t("screens:newProofRequest.messageRequest2")}
                  <b>{connectionName}.</b></h3>
              </div>
              <div className="col-sm-12">
                <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                  onClick={(e) => this.createAnotherRequest(e)}
                >
                  {t("screens:newProofRequest.createAnotherRequest")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
