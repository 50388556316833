import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { io } from 'socket.io-client';
import { history } from '../../..';
import { EmptyCart } from '../../../Common Components/Components/EmptyCart';
import { BothDashboard } from '../../Dashboard/components/BothDashboard';
import { loginAction } from '../../Login/actions/loginAction';
import RegistrationLayout from '../../Register/components/RegistrationLayout';
import { SetDIDPublic } from '../../Set-DID-Public/container/SetDIDPublic';
import { createWalletAction } from '../action/createWalletAction';
import CreateWalletComponent from '../components/CreateWalletComponent';

export interface ICreateWalletProps {
  loginAction: any,
  LoginReducer: any,
  createWalletAction: any,
  CreateWalletReducer: any,
  LoaderReducer: any,
}

export interface ICreateWalletState {
}

class CreateWallet extends React.Component<ICreateWalletProps, ICreateWalletState> {
  constructor(props: ICreateWalletProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    this.props.loginAction.getCurrentUser()
      .then((respo: any) => {
        console.log("in create wallet did mount", respo);
      })
      .catch((error: any) => {
        console.log("error-------------", error);
      })
  }

  public render() {
    const { loggedInUser } = this.props.LoginReducer;
    const { createdWallet } = this.props.CreateWalletReducer;
    const { loader } = this.props.LoaderReducer;
    console.log("loggedInUser------------------", loggedInUser)
    if (localStorage.length === 0) {
      if (loggedInUser.isSecondLogin) {
        history.push('/authenticate')
      } else {
        history.push('/first-time-user')
      }
    }
    if (!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.agentSpinupStatus === 2 && loggedInUser.organization.isDashboard === true) {
      if (!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.role.id === 1) {
        return (<>{history.push('/platformAdmin-dashboard')}</>);
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.role.id === 2) {
        if (loggedInUser && loggedInUser.organization && loggedInUser.organization.subscription && loggedInUser.organization.subscription.id === 1) {
          return (<>{history.push('/orgAdmin-dashboard')}</>);
        } else if (loggedInUser && loggedInUser.organization && loggedInUser.organization.subscription && loggedInUser.organization.subscription.id === 2) {
          return (<>{history.push('/orgAdmin-dashboard')}</>);
        } else {
          return (
            <>  {history.push('/orgAdmin-dashboard')}</>
          )
        }
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.role.id === 3) {
        return (<>{history.push('/issuer-dashboard')}</>);
      }
      else if (!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.role.id === 4) {
        return (<>{history.push('/verifier-dashboard')}</>);
      }
      else if (!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.role.id === 5) {
        return (<>{history.push('/both-dashboard')}</>);

      }
    } else {
      return (
        <>
          {
            !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.agentSpinupStatus === 2 && !_.isEmpty(loggedInUser) && loggedInUser.organization.isDashboard === false ?
              <SetDIDPublic {...this.props} /> :
              !_.isEmpty(createdWallet) && createdWallet.agentSpinupStatus === 1 && loggedInUser.organization.isDashboard === false ?
                <CreateWalletComponent {...this.props} />
                :
                !_.isEmpty(loggedInUser) && loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isDashboard === false ?
                  <CreateWalletComponent {...this.props} />
                  : !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.agentSpinupStatus === 1 && loggedInUser.organization.isDashboard === false ?
                    <CreateWalletComponent {...this.props} />
                    :
                    <EmptyCart></EmptyCart>
          }
        </>
      );
    }
  }
}


function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const CreateWalletReducer = state.CreateWalletReducer;
  const LoaderReducer = state.LoaderReducer;
  return { LoginReducer, CreateWalletReducer, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    createWalletAction: bindActionCreators(createWalletAction, dispatch),
  }
}

const connectedCreateWallet = connect(mapStateToProps, mapDispatchToProps)(CreateWallet);
export { connectedCreateWallet as CreateWallet };
