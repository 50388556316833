import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingAction } from '../actions/onboardingAction';
import { SocketContext } from 'src/contexts/SocketContext';
import { t } from 'i18next';
import TableComponents from 'src/Common Components/Components/TableComponent';
import AcceptConnection from '../Components/AcceptConnection/accept-connection.component';
import { useContext, useEffect, useState } from 'react';

const AcceptInvite: React.FC = () => {

    const dispatch = useDispatch();

    const [defaultSearchFields, setDefaultSearchFields] = useState<string>('')
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const [inviteList, setInviteList] = useState([])
    const [pageNo, setPageNo] = useState<number>(1)
    const socket = useContext(SocketContext)

    const {
        organizationInvitationList,
        totalInvitations,
        invitationTHead,
        selectedInvitationDetails,
        acceptSendSuccess
    } = useSelector((state: any) => state.OnboardingReducer);

    useEffect(() => {
        dispatch(onboardingAction.getOrganizationConnectionListReceived(defaultSearchFields, itemsPerPage, pageNo));
        if (organizationInvitationList.length > 0) {
            dispatch(onboardingAction.showSelectedInvitationDetails(organizationInvitationList[0]))
        }
        if (socket) {
            socket.on('notification', (socketId, payload) => {
                console.log(` Accept Invite - notification - socketId: ${socketId} payload: ${JSON.stringify(payload)}`);
                if (payload && payload.data) {
                    if (payload.data.type === 'Connection') {
                        console.log(`Accept Invite - receive Notification Connection`)
                        dispatch(onboardingAction.getOrganizationConnectionListReceived(defaultSearchFields, itemsPerPage, pageNo))

                    }
                }
            })
        } else {
            console.log("Could not establish a connection")
        }
    }, [])

    useEffect(() => {
        if (organizationInvitationList.length > 0) {
            dispatch(onboardingAction.showSelectedInvitationDetails(organizationInvitationList[0]))
        }
        setInviteList(organizationInvitationList)
    }, [dispatch, acceptSendSuccess, organizationInvitationList])

    const updateList = () => {
        dispatch(onboardingAction.getOrganizationConnectionListReceived('', 10, 1));
    };


    const onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        if (event.key === 'Enter') {
            setDefaultSearchFields(event.target.value);
            dispatch(onboardingAction.getOrganizationConnectionListReceived(event.target.value, itemsPerPage, pageNo));
        }
    }

    const onChangePage = (page: number) => {
        setPageNo(page);
        dispatch(onboardingAction.getOrganizationConnectionListReceived(defaultSearchFields, itemsPerPage, page));
    }

    const selectedInvitationRecord = (invitation: object | any) => {
        console.log('invite', invitation)
        dispatch(onboardingAction.showSelectedInvitationDetails(invitation))
    }

    /* Method for refresh or getting updated organization connection list */
    const refresh = () => {
        setDefaultSearchFields('')
        setItemsPerPage(10)
        setPageNo(1)

        dispatch(onboardingAction.getOrganizationConnectionListReceived('', 10, 1));
    }

    const clearAcceptInvitation = (event: React.DOMAttributes<HTMLButtonElement> | any) => {
        event.preventDefault();
        dispatch(onboardingAction.clearInvitation())
    }

    return (<>
        <div className="row gutters-sm min-h-100 animated fadeIn">
            <div className="col-md-12 col-lg-6 mb-30">
                <div className="card nb-card h-100">
                    <div className="card-header bg-white border-bottom-0">
                        <h1 className="nb-title">{t("screens:onboardingNewOrganization.invitedOrganizations")}</h1>
                        <button className="btn btn-sm btn-nb-outline-primary float-right"
                            onClick={clearAcceptInvitation}>
                            {t("screens:acceptInvitation.create")}
                        </button>
                    </div>

                    <div className="card-body">
                        {inviteList &&
                            <div className="col-sm-12">
                                <TableComponents
                                    onChangePage={onChangePage}
                                    showElementDetails={selectedInvitationRecord}
                                    tHead={invitationTHead}
                                    // tableContent={organizationInvitationList}
                                    tableContent={inviteList}
                                    totalRecords={totalInvitations}
                                    searching={onSearch}
                                    refresh={refresh}
                                    selectedRecord={selectedInvitationDetails}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-12 col-lg-6 mb-30">
                <>
                    {/* Send connection invitation component call */}
                    <AcceptConnection
                        selectedInvitationDetails={selectedInvitationDetails}
                        updateList={updateList}
                    />
                </>
            </div>
        </div>
    </>)
}

export default AcceptInvite;
