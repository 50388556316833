import images from "src/utils/images";
import { t } from 'i18next';

const ProofDeclineSent = (onClickHandler: any) => {

    return (
        <>
            <div className="card-header bg-white border-bottom-0">
                <div className="row">
                    <div className="col-sm-10">
                        <h1 className="nb-title">{t("screens:presentProof.requestDetails")}</h1>
                    </div>
                    <div className="col-sm-2 text-right">
                        <button type="button" className="btn"
                            onClick={onClickHandler}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="col-sm-12">

                    <div className="text-center">
                        <div className="mb-5 mt-5">
                            <img src={images.illustrationSuccess} alt="Success" />
                        </div>
                        <div>
                            <h3 className="nb-label">{t("screens:presentProof.sentProofDecline")}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProofDeclineSent
