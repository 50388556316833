import _ from "lodash";
import { connectionConst } from "../../types";
import { tHeadConnection, tHeadConnectionActivityLog, tHeadCredReqWaitingList, tHeadforEditConnection, tHeadPendingConnection, tHeadDeletedConnection } from "../constants";

const initialState = {
  loading: true,
  connectionList: [],
  selectedConnection: {},
  credentialData: [],
  selectedcredentialData: {},
  hasNextPage: false,
  hasPreviousPage: false,
  lastPage: 0,
  nextPage: 0,
  previousPage: 0,
  selectedConnectionForIssueCredDef: {},
  totalConnections: 0,
  totalCredentials: 0,
  fromPage: 'connection',
  receivedCredential: [],
  selectedReceivedCredData: {},
  loader: false,
  connectionListPageNo: 1,
  receivedCredentialPageNo: 1,
  tHeadConnection: [],
  tHeadPendingConnection: [],
  tHeadDeletedConnection: [],
  tHeadCredReqWaitingList: [],
  tHeadConnectionActivityLog: [],
  editConnection: false,
  listViewOfConnection: false,
  isReceivedCredentialModalShow: false,
  paConnectionList: [],
  selectedConnectionForEdit: {},
  viewExistingConnections: {},
  selectedConnectionbyICL: {},
  connectedOrgs: [],
}

export function ConnectionReducer(state = initialState, action: any) {

  switch (action.type) {//use
    case connectionConst.GET_ALL_CONNECTIONS:
      let temp;
      if (action.page === 'dashboard') {
        temp = Object.assign({}, state, {
          connectionList: action.tempArray,
          hasPreviousPage: action.payload.data.data.hasPreviousPage,
          hasNextPage: action.payload.data.data.hasNextPage,
          lastPage: action.payload.data.data.lastPage,
          nextPage: action.payload.data.data.nextPage,
          totalConnections: _.isEmpty(action.payload.data.data) ? 0 : action.payload.data.data.totalItems,
          previousPage: action.payload.data.data.previousPage,
          selectedConnection: _.isEmpty(action.tempArray) ? {} : action.tempArray[0],
          fromPage: action.page,
          tHeadConnection: tHeadConnection,
          tHeadConnectionActivityLog: tHeadConnectionActivityLog,
        })
      } else {
        temp = Object.assign({}, state, {
          connectionList: _.isEmpty(action.tempArray) ? [] : action.tempArray,
          selectedConnection: _.isEmpty(action.tempArray) ? {} : action.tempArray[0],
          selectedConnectionForIssueCredDef: _.isEmpty(action.tempArray) ? {} : action.tempArray[0],
          fromPage: action.page,
          hasPreviousPage: action.payload.data.data.hasPreviousPage,
          hasNextPage: action.payload.data.data.hasNextPage,
          lastPage: action.payload.data.data.lastPage,
          nextPage: action.payload.data.data.nextPage,
          totalConnections: _.isEmpty(action.payload.data.data) ? 0 : action.payload.data.data.totalItems,
          previousPage: action.payload.data.data.previousPage,
          tHeadConnection: tHeadConnection,
          tHeadConnectionActivityLog: tHeadConnectionActivityLog,
          receivedCredential: []
        });
      }
      return temp;

    case connectionConst.GET_ALL_CONNECTIONS_DELETED:
      let tempdel;
      tempdel = Object.assign({}, state, {
        connectionList: _.isEmpty(action.tempArray) ? [] : action.tempArray,
        selectedConnection: _.isEmpty(action.tempArray) ? {} : action.tempArray[0],
        selectedConnectionForIssueCredDef: _.isEmpty(action.tempArray) ? {} : action.tempArray[0],
        fromPage: action.page,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        hasNextPage: action.payload.data.data.hasNextPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        totalConnections: _.isEmpty(action.payload.data.data) ? 0 : action.payload.data.data.totalItems,
        previousPage: action.payload.data.data.previousPage,
        tHeadDeletedConnection: tHeadDeletedConnection,
        tHeadConnectionActivityLog: tHeadConnectionActivityLog,
      });
      return tempdel;

    case connectionConst.GET_ALL_PENDING_CONNECTIONS:
      let tempPending = Object.assign({}, state, {
        connectionList: _.isEmpty(action.tempArray) ? [] : action.tempArray,
        selectedConnection: _.isEmpty(action.tempArray) ? {} : action.tempArray[0],
        selectedConnectionForIssueCredDef: _.isEmpty(action.tempArray) ? {} : action.tempArray[0],
        fromPage: action.page,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        hasNextPage: action.payload.data.data.hasNextPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        totalConnections: _.isEmpty(action.payload.data.data) ? 0 : action.payload.data.data.totalItems,
        previousPage: action.payload.data.data.previousPage,
        tHeadPendingConnection: tHeadPendingConnection,
        tHeadConnectionActivityLog: tHeadConnectionActivityLog,
      });
      return tempPending;

    case connectionConst.SELECTED_CONNECTION: //use
      // console.log(`action.payload.item: ${JSON.stringify(action.payload.item)}`)
      return Object.assign({}, state, {
        selectedConnection: action.payload.item,
        fromPage: action.payload.page,
        isReceivedCredentialModalShow: false,
      });

    case connectionConst.SELECTED_CONNECTION_BY_ICL:
      return Object.assign({}, state, {
        // editConnection: true,
        // selectedConnectionForEdit: action.payload,
        // tHeadConnection: tHeadforEditConnection,
        // listViewOfConnection: false,
        selectedConnectionbyICL: action.payload,
        selectedConnection: {},
        romPage: action.payload.page,
        isReceivedCredentialModalShow: false,

      });
    case connectionConst.GET_CREDENTIALS_BY_USER:
      return Object.assign({}, state, {
        credentialData: _.isEmpty(action.payload.data.data.data) ? [] : action.payload.data.data.data,
        selectedcredentialData: _.isEmpty(action.payload.data.data.data) ? [] : action.payload.data.data.data[0],
        hasPreviousPage: action.payload.data.hasPreviousPage,
        hasNextPage: action.payload.data.hasNextPage,
        lastPage: action.payload.data.lastPage,
        nextPage: action.payload.data.nextPage,
        totalCredentials: action.payload.data.totalItems,
        previousPage: action.payload.data.previousPage,
      });
    case connectionConst.CLEAR_CONNECTION:
      return Object.assign({}, state, {
        selectedConnection: {},
      })
    case connectionConst.CLEAR_CONNECTION_FOR_ISSUE_CREDDEF:
      return Object.assign({},
        state,
        {
          selectedConnectionForIssueCredDef: {}
        })
    case connectionConst.RECEIVED_CREDENTIAL:
      return Object.assign({},
        state,
        {
          receivedCredential: action.payload.data.data.credentialData.data,
          tHeadCredReqWaitingList: tHeadCredReqWaitingList,
          hasPreviousPage: action.payload.data.data.credentialData.hasPreviousPage,
          hasNextPage: action.payload.data.data.credentialData.hasNextPage,
          lastPage: action.payload.data.data.credentialData.lastPage,
          nextPage: action.payload.data.data.credentialData.nextPage,
          totalReceivedCredentials: action.payload.data.data.credentialData.totalItems,
        })
    case connectionConst.SELECTED_RECEIVED_CREDENTIAL:
      return Object.assign({},
        state,
        {
          selectedReceivedCredData: action.payload.data.data.data,
          isReceivedCredentialModalShow: true,
        })
    case connectionConst.UPDATE_CONNECTION_LIST:
      let obj = JSON.parse(action.payload.data)
      let connection_id = JSON.parse(action.payload.data).connectionId
      if (action.payload.list[0] && connection_id !== action.payload.list[0].connectionId) {
        if (action.payload.list.length >= 10) {
          action.payload.list.pop();
        }
        action.payload.list.splice(0, 0, obj);
      }
      return Object.assign({},
        state,
        {
          connectionList: action.payload.list,
        })
    case connectionConst.UPDATE_PAGE_NO:
      return Object.assign({},
        state,
        {
          connectionListPageNo: action.payload,
        })
    case connectionConst.UPDATE_CRED_PAGE_NO:
      return Object.assign({},
        state,
        {
          receivedCredentialPageNo: action.payload,
        })
    case connectionConst.UPDATE_RECEIVED_CREDENTIAL_LIST:
      let id = JSON.parse(action.payload.data).connectionId
      for (var i = 0; i < action.payload.list.length; i++) {
        if (action.payload.list[i] && id === action.payload.list[i].connectionId) {
          action.payload.list[i].isNotify = true;
        }
      }
      return Object.assign({},
        state,
        {
          connectionList: action.payload.list,
        })
    case connectionConst.EDIT_CONNECTIONS:
      return Object.assign({}, state, {
        editConnection: true,
        selectedConnectionForEdit: action.payload,
        tHeadConnection: tHeadforEditConnection,
        listViewOfConnection: false,
      })

    case connectionConst.LIST_VIEW_CONNECTION:
      return Object.assign({}, state, {
        listViewOfConnection: true,
      })

    case connectionConst.DEFAULT_PAGE:
      return Object.assign({}, state, {
        listViewOfConnection: false,
        editConnection: false
      })
    case connectionConst.PA_GET_ALL_CONNECTIONS:
      return Object.assign({}, state, {
        paConnectionList: action.payload.data.data.data,
        paTotalConnections: action.payload.data.data.totalItems,
      })
    case connectionConst.VIEW_EXSITING_CONNECTION:
      return Object.assign({}, state, {
        viewExistingConnections: action.payload,
        selectedConnectionForEdit: {},
        isReceivedCredentialModalShow: false,
      })
    case connectionConst.GET_ALL_CONNECTED_ORGS:
      return Object.assign({}, state, {
        connectedOrgs: action.tempArray
      })
    default:
      return state
  }
} 