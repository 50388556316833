import * as React from 'react';
import './Sidebar.css'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { loginAction } from '../../../View/Login/actions/loginAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import PageLoader from '../../Components/pageLoader';
import { version } from '../../../config';
import IdleTimer from 'react-idle-timer'
import { history } from '../../..';

// Utilities
import images from "src/utils/images";
import { socket } from 'src/contexts/SocketContext';


export interface ISidebarProps extends WithTranslation<["common", "screens"]> {
  loggedInUser?: any | undefined,
  loginAction: any,
  cssClass: any,
  LoaderReducer: any,
  sidebarOpen: boolean,
}

export interface ISidebarState {
  adminMenuConfigs: any,
  adminOnboarding: any,
  adminCredentials: any,
  adminVerification: any,
  organizationMenuConfigs: any,
  // orgIssuers: any,
  // orgVerifiers: any,
  orgIssuersVerifiers: any,
  orgConnections: any,
  orgCredentials: any,
  orgCredentialIssuance: any,
  issuerCredentials: any
  orgVerification: any,
  settings: any,
  verifierMenuConfigs: any,
  issuerMenuConfigs: any,
  bothMenuConfigs: any,
  defaultMenu: any,
  basicMessage: any,
}

class SidebarComponent extends React.Component<ISidebarProps, ISidebarState> {
  constructor(props: ISidebarProps) {
    super(props);

    this.state = {
      ...this.getNavigationLinks(),
    }

    this.getNavigationLinks = this.getNavigationLinks.bind(this);
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.logoutRequest = this.logoutRequest.bind(this);
  }

  /**
   * Set navigation links in local state
   *
   * NOTE: This is not an ideal pattern, but existed previously and had to be moved out
   *         of constructor to enable updating with NODs.
   */
  getNavigationLinks() {
    const { t } = this.props;

    return {
      defaultMenu: [
        { title: t("screens:navigationDrawer.listItemDashboard"), path: '/dashboard', icon: 'nb-ico nb-dashboard' },
      ],
      // ADMIN SUB MENUS
      adminMenuConfigs: [
        { title: t("screens:navigationDrawer.listItemDashboard"), path: '/platformAdmin-dashboard', icon: 'nb-ico nb-dashboard' },
        { title: 'Platform Config', path: '/platform-config', icon: 'nb-ico nb-platform-config' },
      ],

      adminOnboarding: [
        { title: 'Invite', path: '/onboard-organization' },
        { title: 'Verify Details', path: '/organization-list' },
        { title: 'Onboarded', path: '/on-ledger' },
        { title: 'Register Organization', path: '/register-organization' },

      ],

      adminCredentials: [
        { title: 'Default Credentials', path: '/platformAdmin-cred-def' },
        { title: 'Default Schema', path: '/platformAdmin-schema' },
        { title: 'Create Schema and Cred Def DB', path: '/create-schema-credential-def' },
      ],

      adminVerification: [
        { title: 'Login Monitoring', path: '/login-monitoring' },
      ],

      // ORGANIZATION ADMIN SUB MENUS
      organizationMenuConfigs: [
        { title: t("screens:navigationDrawer.listItemDashboard"), path: '/orgAdmin-dashboard', icon: 'nb-ico nb-dashboard-dark' },
      ],

      orgIssuersVerifiers: [
        // { title: t("screens:navigationDrawer.listItemIssuersVerifiersAdd"), path: '/add-issuer-verifier', icon: 'nb-ico nb-dashboard-dark' },
        { title: t("screens:navigationDrawer.listItemSchemasCredentials"), path: '/configuration/schema-cred', icon: 'nb-ico nb-dashboard-dark' },
        { title: t("screens:navigationDrawer.listItemPoliciesEmployee"), path: '/employee', icon: 'nb-ico nb-dashboard-dark' },

        { title: t("screens:navigationDrawer.listItemExistingIssuersVerifiers"), path: '/existing-issuers-verifiers', icon: 'nb-ico nb-dashboard-dark' },
      ],

      // orgIssuers: [
      //   // { title: t("screens:navigationDrawer.listItemIssuersAdd"), path: '/add-issuer', icon: 'nb-ico nb-dashboard-dark' },
      //   { title: t("screens:navigationDrawer.listItemPoliciesEmployee"), path: '/employee', icon: 'nb-ico nb-dashboard-dark' },
      //   { title: t("screens:navigationDrawer.listItemExistingIssuers"), path: '/existing-issuers', icon: 'nb-ico nb-dashboard-dark' },
      // ],

      // orgVerifiers: [
      //   { title: t("screens:navigationDrawer.listItemVerifiersAdd"), path: '/add-verifier', icon: 'nb-ico nb-dashboard-dark' },
      //   { title: t("screens:navigationDrawer.listItemExistingVerifiers"), path: '/existing-verifiers', icon: 'nb-ico nb-dashboard-dark' },
      // ],

      orgConnections: [
        { title: t("screens:navigationDrawer.listItemConnectionsCreate"), path: '/create-connection', icon: 'nb-ico nb-dashboard-dark' },
        { title: t("screens:navigationDrawer.listItemConnectionsAccept"), path: '/accept-invite', icon: 'nb-ico nb-dashboard-dark' },
        { title: t("screens:navigationDrawer.listItemConnectionsMyConnections"), path: '/connections', icon: 'nb-ico nb-connection-dark' },
        { title: t("screens:navigationDrawer.listItemConnectionsDeleted"), path:  '/deleted-connections', icon: 'nb-ico nb-dashboard-dark' },
      ],

      orgCredentials: [
        { title: t("screens:navigationDrawer.listItemOrganizationCredentialsWallet"), path: '/organization-credentials/wallet', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemOrganizationCredentialsRequest"), path: '/organization-credentials/request', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemOrganizationCredentialsShare"), path: '/organization-credentials/share', icon: 'nb-ico nb-dashboard' },
      ],

      orgCredentialIssuance: [
        { title: t("screens:navigationDrawer.listItemCredentialIssuanceOffer"), path: '/credential-issuance/offer', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemCredentialIssuanceNegotiation"), path: '/credential-issuance/negotiate' },
        { title: t("screens:navigationDrawer.listItemCredentialIssuanceCompleted"), path: '/credential-issuance/completed', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemCredentialIssuanceRevoke"), path: '/credential-issuance/revoke', icon: 'nb-ico nb-dashboard' },
        // { title: t("screens:navigationDrawer.listItemCredentialIssue"), path: '/issue-credentials', icon: 'nb-ico nb-dashboard' },
        // { title: t("screens:navigationDrawer.listItemCredentialIssuanceInternalList"), path: '/internal-credentials-list', icon: 'nb-ico nb-dashboard' },
        // { title: t("screens:navigationDrawer.listItemCredentialIssueKYC"), path: '/issue-kyc-credentials' },
        // Disable Menu { title: 'Import Credentials', path: '/import-credentials' },
      ],

      issuerCredentials: [
        { title: t("screens:navigationDrawer.listItemCredentialIssuanceProposal"), path: '/credential-issuance/request', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemCredentialIssuanceOffer"), path: '/credential-issuance/offer', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemCredentialIssuanceNegotiation"), path: '/credential-issuance/negotiate' },
        // { title: t("screens:navigationDrawer.listItemCredentialIssuanceInternalList"), path: '/internal-credentials-list', icon: 'nb-ico nb-dashboard' },
      ],

      // orgVerification: [
      //   { title: t("screens:navigationDrawer.listItemNewProposal"), path: '/presentation-proposal', icon: 'nb-ico nb-dashboard' },
      //   { title: t("screens:navigationDrawer.listItemNewWorkflow"), path: '/verification-workflow', icon: 'nb-ico nb-dashboard' },
      //   { title: t("screens:navigationDrawer.listItemNewTask"), path: '/task-type', icon: 'nb-ico nb-dashboard' },
      //   { title: t("screens:navigationDrawer.listItemPresentProof"), path: '/present-proof', icon: 'nb-ico nb-dashboard' },
      //   { title: t("screens:navigationDrawer.listItemVerificationMonitoring"), path: '/verification-monitoring', icon: 'nb-ico nb-dashboard' },
      // ],

      orgVerification: [
        // { title: t("screens:navigationDrawer.listItemProofsScheduled"), path: '/proofs/scheduled', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemCredentialVerificationRequest"), path: '/credential-verification/request', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemCredentialVerificationNegotiate"), path: '/credential-verification/negotiate', icon: 'nb-ico nb-dashboard' },
        { title: t("screens:navigationDrawer.listItemCredentialVerificationCompleted"), path: '/credential-verification/completed', icon: 'nb-ico nb-dashboard' },
      ],

      settings: [
        { title: t("screens:navigationDrawer.listItemProfileSettings"), path: '/profile-settings' },
      ],

      // VERIFIER SUB MENUS
      verifierMenuConfigs: [
        { title: t("screens:navigationDrawer.listItemDashboard"), path: '/verifier-dashboard', icon: 'nb-ico nb-dashboard-dark' },
      ],

      // ISSUER SUB MENUS
      issuerMenuConfigs: [
        { title: t("screens:navigationDrawer.listItemDashboard"), path: '/issuer-dashboard', icon: 'nb-ico nb-dashboard-dark' },
      ],

      // BOTH SUB MENUS
      bothMenuConfigs: [
        { title: t("screens:navigationDrawer.listItemDashboard"), path: '/both-dashboard', icon: 'nb-ico nb-dashboard-dark' },
      ],

      // Basic Message
      basicMessage: [
        { title: t("screens:navigationDrawer.listItemChats"), path: '/chats', icon: '' }
      ],
      // Notification
      notifications: [
        { title: t("screens:navigationDrawer.listItemNotifications"), path: '/notification', icon: '' }
      ]
    };
  }

  handleOnAction(event: any) {
    // console.log('user did something', event)
  }

  handleOnActive(event: any) {
    console.log('user is active', event)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle(event: any) {
    if (localStorage.length > 0 && this.props.loggedInUser.id === 1) {
      localStorage.clear();
      history.push('/palogin')
    } else if (localStorage.length > 0 && this.props.loggedInUser.id !== 1) {
      socket.disconnect()
      localStorage.clear();
      
      if (this.props.loggedInUser.isSecondLogin) {
        history.push('/authenticate')
      } else {
        console.log(`first-time-user - loggedInUser.id ${this.props.loggedInUser.id}`)
        history.push('/first-time-user')
      }
    }
  }

  componentDidMount() {
    this.props.loginAction.getCurrentUser();
  }

  componentDidUpdate(prevProps: ISidebarProps) {
    // Update navigation links whenever localization changes, which can be detected
    //   by a change in "t-function" equality (odd but true).
    if (this.props.t !== prevProps.t) {
      this.setState(this.getNavigationLinks());
    }
  }

  logoutRequest() {
    this.props.loginAction.logout();
  }

  public render() {
    const { loggedInUser, cssClass, t } = this.props;
    const { loader } = this.props.LoaderReducer;
    const { 
      adminMenuConfigs,
      adminOnboarding,
      adminCredentials,
      adminVerification,
      organizationMenuConfigs,
      // orgIssuers,
      // orgVerifiers,
      orgIssuersVerifiers,
      orgConnections,
      orgCredentials,
      orgCredentialIssuance,
      issuerCredentials,
      orgVerification,
      settings,
      verifierMenuConfigs,
      issuerMenuConfigs,
      bothMenuConfigs,
      defaultMenu,
    } = this.state;
    const role = loggedInUser && loggedInUser.role && loggedInUser.role.id;
    const subscription = loggedInUser && loggedInUser.organization && loggedInUser.organization.subscription && loggedInUser.organization.subscription.id;
    const menuList = role === 1 ? adminMenuConfigs :
      role === 2 && subscription === 1 ? organizationMenuConfigs :
        role === 2 && subscription === 2 ? organizationMenuConfigs :
          role === 2 && subscription === 3 ? organizationMenuConfigs :
            role === 3 ? issuerMenuConfigs :
              role === 4 ? verifierMenuConfigs :
                role === 5 ? bothMenuConfigs :
                  defaultMenu;
    return (
      <>
        <IdleTimer
          timeout={1000 * 60 * 15}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        {
          loader ?
            <PageLoader></PageLoader> :
            null
        }
        <ProSidebar className={cssClass}>
          <SidebarHeader>
            {!_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization && loggedInUser.organization.logoUrl &&
              role === 1 ?
              <div className="pt-3 pb-3 text-center">
                <img className="img img-fluid" src={images.companyLogoWhite}
                  alt="nb orbit white logo" style={{ maxHeight: '80px', maxWidth: "80%" }} />
              </div> :
              role === 2 ?
                <>
                  <div className="pt-3 pb-3 text-center">
                    <img className="img img-fluid"
                      alt='logo'
                      src={loggedInUser.organization.logoUrl ? loggedInUser.organization.logoUrl : ''}
                      style={{ maxHeight: '80px' }}
                    />
                  </div>
                  <div className="pb-3 text-center">
                    <h6>{loggedInUser.organization.displayName}</h6>
                  </div>
                </>
                :
                <>
                  <div className="pt-3 pb-3 text-center">
                    <img className="img img-fluid"
                      alt='logo'
                      src={!_.isEmpty(loggedInUser) && loggedInUser.organization.logoUrl ? loggedInUser.organization.logoUrl : ''}
                      style={{ maxHeight: '80px' }}
                    />
                  </div>
                  <div className="pb-3 text-center">
                    <h6>{!_.isEmpty(loggedInUser) && loggedInUser.organization.displayName ? loggedInUser.organization.displayName : ''}</h6>
                  </div>
                </>
            }
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              {menuList && menuList.map((item: any) =>
                <MenuItem key={item.path} icon={<i className={item.icon}></i>}><NavLink to={item.path} className="menu-item">
                  {item.title}
                </NavLink></MenuItem>
              )}
              {role === 1 ?
                <>
                  <SubMenu title="Organization" icon={<i className="nb-ico nb-onboarding"></i>}>
                    {adminOnboarding.map((item: any, index: any) => <MenuItem key={index}>
                      <NavLink to={item.path} className="menu-item">
                        {item.title}
                      </NavLink>
                    </MenuItem>)}
                  </SubMenu>
                  <MenuItem key={'/platformAdmin-connection'} icon={<i className="nb-ico nb-connection"></i>}
                  ><NavLink to={'/platformAdmin-connection'} className="menu-item">
                      Connections
                    </NavLink></MenuItem>
                  <SubMenu title={t("screens:navigationDrawer.listItemOrganizationCredentials")} icon={<i className="nb-ico nb-org-cred"></i>}>
                    {adminCredentials.map((item: any, index: any) => <MenuItem key={index}>
                      <NavLink to={item.path} className="menu-item">
                        {item.title}
                      </NavLink>
                    </MenuItem>)}
                  </SubMenu>
                  <SubMenu title={t("screens:navigationDrawer.listItemCredentialVerification")} icon={<i className="nb-ico nb-verification"></i>}>
                    {adminVerification.map((item: any, index: any) => <MenuItem key={index}>
                      <NavLink to={item.path} className="menu-item">
                        {item.title}
                      </NavLink>
                    </MenuItem>)}
                  </SubMenu>
                  <MenuItem key={'/agent-status'} icon={<i className="nb-ico nb-agent-status"></i>}
                  ><NavLink to={'/agent-status'} className="menu-item">
                      Agent Status
                    </NavLink></MenuItem>
                  <MenuItem key={'/email-templates'} icon={<i className="nb-ico nb-envolop"></i>}
                  ><NavLink to={'/email-templates'} className="menu-item">
                      Edit Email Templates
                    </NavLink></MenuItem>
                </>
                : role === 2 ?
                  <>

                    <SubMenu title={t("screens:navigationDrawer.listItemConnections")} icon={<i className="nb-ico nb-onboarding"></i>}>
                      {orgConnections.map((item: any, index: any) => <MenuItem key={index}>
                        <NavLink to={item.path} className="menu-item">
                          {item.title}
                        </NavLink>
                      </MenuItem>)}
                    </SubMenu>

                    {
                      subscription === 3 &&
                      <SubMenu title={t("screens:navigationDrawer.listItemConfiguration")} icon={<i className="nb-ico nb-issuer-dark"></i>}>
                        {orgIssuersVerifiers.map((item: any, index: any) => <MenuItem key={index}>
                          <NavLink to={item.path} className="menu-item">
                            {item.title}
                          </NavLink>
                        </MenuItem>)}
                      </SubMenu>
                    }
                    {/* {
                      subscription === 1 &&
                      <SubMenu title={t("screens:navigationDrawer.listItemPolicies")} icon={<i className="nb-ico nb-issuer-dark"></i>}>
                        {orgIssuers.map((item: any, index: any) => <MenuItem key={index}>
                          <NavLink to={item.path} className="menu-item">
                            {item.title}
                          </NavLink>
                        </MenuItem>)}
                      </SubMenu>
                    }
                    {
                      subscription === 2 &&
                      <SubMenu title={t("screens:navigationDrawer.listItemPolicies")} icon={<i className="nb-ico nb-issuer-dark"></i>}>
                        {orgVerifiers.map((item: any, index: any) => <MenuItem key={index}>
                          <NavLink to={item.path} className="menu-item">
                            {item.title}
                          </NavLink>
                        </MenuItem>)}
                      </SubMenu>
                    } */}

                    <SubMenu title={t("screens:navigationDrawer.listItemOrganizationCredentials")} icon={<i className="nb-ico nb-org-cred"></i>}>
                      {orgCredentials.map((item: any, index: any) => <MenuItem key={index}>
                        <NavLink to={item.path} className="menu-item">
                          {item.title}
                        </NavLink>
                      </MenuItem>)}
                    </SubMenu>

                    {subscription === 1 || subscription === 3 ?
                      <SubMenu title={t("screens:navigationDrawer.listItemCredentialIssuance")} icon={<i className="nb-ico nb-credentials"></i>}>
                        {orgCredentialIssuance.map((item: any, index: any) => <MenuItem key={index}>
                          <NavLink to={item.path} className="menu-item">
                            {item.title}
                          </NavLink>
                        </MenuItem>)}
                      </SubMenu>
                      : null}
                    {subscription === 2 || subscription === 3 ?
                      <>
                        <SubMenu title={t("screens:navigationDrawer.listItemCredentialVerification")} icon={<i className="nb-ico nb-verification-dark"></i>}>
                          {orgVerification.map((item: any, index: any) => <MenuItem key={index}>
                            <NavLink to={item.path} className="menu-item">
                              {item.title}
                            </NavLink>
                          </MenuItem>)}
                        </SubMenu>
                      </>
                      : null
                    }

                    <MenuItem key={'/chats'} icon={<i className="nb-ico nb-governance-dark"></i>}
                    ><NavLink to={'/chats'} className="menu-item">
                        {t("screens:navigationDrawer.listItemChats")}
                      </NavLink>
                    </MenuItem>

                    <SubMenu title={t("screens:navigationDrawer.listItemSettings")} icon={<i className="nb-ico nb-setting-dark"></i>}>
                      {settings.map((item: any, index: any) => <MenuItem key={index}>
                        <NavLink to={item.path} className="menu-item">
                          {item.title}
                        </NavLink>
                      </MenuItem>)}
                    </SubMenu>

                  </>
                  : role === 3 ?
                    <>
                      <MenuItem key={'/connections'} icon={<i className="nb-ico nb-connection-dark"></i>}
                      ><NavLink to={'/connections'} className="menu-item">
                          {t("screens:navigationDrawer.listItemConnectionsMyConnections")}
                        </NavLink>
                      </MenuItem>
                      <MenuItem key={'/deleted-connections'} icon={<i className="nb-ico nb-connection-dark"></i>}
                      ><NavLink to={'/deleted-connections'} className="menu-item">
                          {t("screens:navigationDrawer.listItemConnectionsMyConnections")}
                        </NavLink>
                      </MenuItem>
                      <SubMenu title="Credentials" icon={<i className="nb-ico nb-credentials"></i>}>
                        {issuerCredentials.map((item: any, index: any) => <MenuItem key={index}>
                          <NavLink to={item.path} className="menu-item">
                            {item.title}
                          </NavLink>
                        </MenuItem>)}
                      </SubMenu>

                      <MenuItem key={'/chats'} icon={<i className="nb-ico nb-governance-dark"></i>}
                      ><NavLink to={'/chats'} className="menu-item">
                          {t("screens:navigationDrawer.listItemChats")}
                        </NavLink>
                      </MenuItem>

                      <MenuItem key={'/profile-settings'} icon={<i className="nb-ico nb-setting-dark"></i>}
                      ><NavLink to={'/profile-settings'} className="menu-item">
                          {t("screens:navigationDrawer.listItemProfileSettings")}
                        </NavLink>
                      </MenuItem>
                    </>
                    : role === 4 ?
                      <>
                        <SubMenu title="Verification" icon={<i className="nb-ico nb-verification-dark"></i>}>
                          {orgVerification.map((item: any, index: any) => <MenuItem key={index}>
                            <NavLink to={item.path} className="menu-item">
                              {item.title}
                            </NavLink>
                          </MenuItem>)}
                        </SubMenu>

                        <MenuItem key={'/chats'} icon={<i className="nb-ico nb-governance-dark"></i>}
                        ><NavLink to={'/chats'} className="menu-item">
                            {t("screens:navigationDrawer.listItemChats")}
                          </NavLink>
                        </MenuItem>

                        <MenuItem key={'/profile-settings'} icon={<i className="nb-ico nb-setting-dark"></i>}
                        ><NavLink to={'/profile-settings'} className="menu-item">
                            {t("screens:navigationDrawer.listItemProfileSettings")}
                          </NavLink>
                        </MenuItem>

                      </>
                      : role === 5 ?
                        <>
                          <MenuItem key={'/connections'} icon={<i className="nb-ico nb-connection-dark"></i>}
                          ><NavLink to={'/connections'} className="menu-item">
                              {t("screens:navigationDrawer.listItemConnectionsMyConnections")}
                            </NavLink>
                          </MenuItem>
                          <MenuItem key={'/deleted-connections'} icon={<i className="nb-ico nb-connection-dark"></i>}
                          ><NavLink to={'/deleted-connections'} className="menu-item">
                              {t("screens:navigationDrawer.listItemConnectionsDeleted")}
                            </NavLink>
                          </MenuItem>
                          <SubMenu title="Credentials" icon={<i className="nb-ico nb-credentials"></i>}>
                            {issuerCredentials.map((item: any, index: any) => <MenuItem key={index}>
                              <NavLink to={item.path} className="menu-item">
                                {item.title}
                              </NavLink>
                            </MenuItem>)}
                          </SubMenu>
                          <SubMenu title="Verifications" icon={<i className="nb-ico nb-verification-dark"></i>}>
                            {orgVerification.map((item: any, index: any) => <MenuItem key={index}>
                              <NavLink to={item.path} className="menu-item">
                                {item.title}
                              </NavLink>
                            </MenuItem>)}
                          </SubMenu>
                          <MenuItem key={'/chats'} icon={<i className="nb-ico nb-governance-dark"></i>}
                          ><NavLink to={'/chats'} className="menu-item">
                              {t("screens:navigationDrawer.listItemChats")}
                            </NavLink>
                          </MenuItem>

                          <MenuItem key={'/profile-settings'} icon={<i className="nb-ico nb-setting-dark"></i>}
                          ><NavLink to={'/profile-settings'} className="menu-item">
                              {t("screens:navigationDrawer.listItemProfileSettings")}
                            </NavLink>
                          </MenuItem>
                        </>
                        :
                        <></>
              }
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem className="copyrights">
                <div className="text-center d-block copyrights">
                  <div>{t("screens:navigationDrawer.powered")} </div>
                  <small>{t("screens:navigationDrawer.version")} {version.version}</small>
                </div>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { loggedInUser } = state.LoginReducer;
  const LoaderReducer = state.LoaderReducer;
  return { loggedInUser, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
  }
}

const connectedSidebar = withTranslation(["common", "screens"])(connect(mapStateToProps, mapDispatchToProps)(SidebarComponent));
export default connectedSidebar;