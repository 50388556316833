import axios from "axios";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { issuersVerifiersConstants, loaderConst } from "../../types";
import { t } from 'i18next';
import { InternalIssuerVerifierOptions, IssueLoginCredentialInitiator } from "../interfaces";

export enum APIInitiator {
  Issuer = 'issuer',
  Verifier = 'verifier'
}

export const IssuerActions = {
  /**
   * Method to get Employee list 
   * @returns It return employee list
   */
  getAllEmployees() {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call to get all employee list and dispatch the get all employee action */
        return await axios.get(`${config.apiUrl}/holder/employee`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(employeeData => {
            dispatch({
              type: issuersVerifiersConstants.GET_ALL_EMPLOYEES,
              payload: employeeData,
            })
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Handle the error and throw it */
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method to add new issuer.
   * @param finalData This parameter includes issuer details.
   * @returns It return the add issuer response.
   */
  addIssuerVerifier(finalData: any, initiator: APIInitiator) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader. */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage. */
        let token = localStorage.getItem('token');
        /* API call for add issuer and dispatch add issuer success action. */
        return await axios.post(`${config.apiUrl}/holder/issuer-verifier`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: issuersVerifiersConstants.ADD_ISSUER_VERIFIER_SUCCESS,
              payload: response,
            })
            /* Method call to get updated employee list. */
            this.getAllEmployees()
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Show success message using toaster. */
            // TODO toastr.success(`${response.data.message}`, ``)
            if (finalData.roleId === 3) {
              toastr.success(`${t("screens:addIssuerVerifier.toastMsgAddIssuer")}`, ``);
            }
            else if (finalData.roleId === 4) {
              toastr.success(`${t("screens:addIssuerVerifier.toastMsgAddVerifier")}`, ``);
            }
            else {
              toastr.success(`${t("screens:addIssuerVerifier.toastMsgAddIssuerAndVerifier")}`, ``);
            }

          })
          .catch(error => {
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Handle the error and throw it */
            throw error;
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method used to issue login credential
   * @param id Parameter used for get specific ID.
   * @returns It return credential issue response.
   */
  issueLoginCredential(id: any, initiator: IssueLoginCredentialInitiator) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader. */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage. */
        let token = localStorage.getItem('token');
        /* API call to issue login credential to specific employee ID and dispatch the issue login credential method. */
        return await axios.post(`${config.apiUrl}/holder/issue-login-cred/employee/${id}`, '',
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            if(initiator === IssueLoginCredentialInitiator.ADD) {
              dispatch({
                type: issuersVerifiersConstants.ISSUE_LOGIN_CREDENTIAL,
              })
            }
            /* Dispatch method to start the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Show success message using toaster. */
            // TODO toastr.success(`${response.data.message}`, ``)
            toastr.success(`${t("screens:addIssuerVerifier.toastMsgIssueLogin")}`, ``);
          })
          .catch(error => {
            /* Dispatch method to start the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* If credential issuance fail then Dispatch the issue login credential failed action. */
            dispatch({
              type: issuersVerifiersConstants.ISSUE_LOGIN_CREDENTIAL_FAILED,
            })
            /* Show information message using toaster. */
            // toastr.info(`Check Connection`, ``);
            toastr.info(`${t("screens:addIssuerVerifier.toastMsgCheckConnection")}`, ``);
            /* Handle the error and throw it */
            throw error;
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method used to dispatch the ADD_ANOTHER_ISSUER action
   * @returns 
   */
  addAnotherIssuer() {
    return async (dispatch: any) => {
      dispatch({
        type: issuersVerifiersConstants.ADD_ANOTHER_ISSUER_VERIFIER,
      })
    }
  },

  /**
   * Method used to get available roles in system
   * @returns It return the list of roles and its subscription
   */
  getRole() {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader. */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage. */
        let token = localStorage.getItem('token');
        /* API call to get user roles and its subscription */
        return await axios.get(`${config.apiUrl}/admin/get-role/org-subscription`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(roles => {
            let roleDB = (!_.isEmpty(roles) && roles.data && roles.data.data);
            roleDB.forEach((element: any, index: any) => {
              roleDB[index].name = `${t(`screens:addIssuerVerifier.${(element.name.toLowerCase()).replace(/\s/g, "")}`)}`;
            });
            dispatch({
              type: issuersVerifiersConstants.GET_ALL_ROLES,
              payload: roles,
            })
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Handle the error and throw it. */
        throw error;
      }
    }
  },

  /**
   * Method to get all employee list or internal issuer list.
   * @param defaultSearchFields Parameter for searching the data in table. 
   * @param status Parameter for mentation the request status.
   * @param InternalIssuerSortBy Parameter for set sorting order e.q ASC or DECD.
   * @param sortingOrder Parameter for set sort by field e.q organization name.
   * @param item_perPage Parameter for set how many item required for per page.
   * @param pageNo Parameter for set current page no pagination.
   * @returns It return the employee list or internal issuer list.
   */
  getInternalIssuersVerifiers(internalIssuerOptions: InternalIssuerVerifierOptions) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader. */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })

        const { defaultSearchFields, filterStatus, internalIssuerSortBy, itemsPerPage, pageNo, sortingOrder, type } = internalIssuerOptions

        /* Get authentication token from local storage. */
        let token = localStorage.getItem('token');
        /* API call to get all internal issuer list  */
        return await axios.get(`${config.apiUrl}/holder/employee-list?search_text=${defaultSearchFields}&status=${filterStatus}&sort_by=${internalIssuerSortBy}&sort_value=${sortingOrder}&items_per_page=${itemsPerPage}&page=${pageNo}&type=${type}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(internalIssuerVerifiersList => {
            let tmpArray = internalIssuerVerifiersList.data.data.data;
            if (internalIssuerVerifiersList && !_.isEmpty(tmpArray)) {
              tmpArray.forEach((element: any, index: any) => {
                tmpArray[index].issuerName = element.name
                tmpArray[index].userRole = element.role.name
              });
            }
            dispatch({
              type: issuersVerifiersConstants.GET_ALL_INTERNAL_ISSUER_VERIFIER,
              payload: internalIssuerVerifiersList,
              tmpArray,
            })
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Handle the error and throw it. */
        throw error;
      }
    }
  },

  /**
   * Method used to store selected specific issuer details in redux store.
   * @param item Parameter includes specific issuer details. 
   */
  selectInternalIssuers(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: issuersVerifiersConstants.SELECTED_INTERNAL_ISSUER_VERIFIER,
        payload: item,
      })
    }
  },

  /**
   * Method used to view internal issuer details.
   * @param item Parameter includes specific issuer details.
   */
  viewInternalIssuerVerifiers(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: issuersVerifiersConstants.VIEW_INTERNAL_ISSUER_VERIFIER,
        payload: item,
      })
    }
  },

  /**
   * Method used to edit specific issuer details
   * @param selectedIssuers Parameter includes specific issuer details.
   */
  editInternalIssuerVerifiers(selectedIssuers: any) {
    return async (dispatch: any) => {
      dispatch({
        type: issuersVerifiersConstants.EDIT_INTERNAL_ISSUER_VERIFIER,
        payload: selectedIssuers,
      })
    }
  },

  /**
   * Method used to update the issuer details.
   * @param finalData Parameter includes updated values of specific internal issuer.
   * @returns It return the updated issuer details
   */
  updateIssuerVerifier(finalData: any, initiator: APIInitiator) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader. */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage. */
        let token = localStorage.getItem('token');
        /* API call for update the specific issuer details and dispatch the action. */
        return await axios.post(`${config.apiUrl}/holder/update-internal-issuer`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: issuersVerifiersConstants.UPDATE_ISSUER_VERIFIER,
              payload: response
            })
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Show success message using toaster. */
            // TODO toastr.success(`${response.data.message}`, ``)
            if (initiator === APIInitiator.Issuer)
              toastr.success(`${t("screens:addIssuerVerifier.toastMsgIssuerUpdated")}`, ``);
            else
              toastr.success(`${t("screens:addIssuerVerifier.toastMsgVerifierUpdated")}`, ``);
          })
          .catch(error => {
            /* Dispatch method to stop the page loader. */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Handle the error and throw it. */
            throw error;
          })
      } catch (error) {
        /* Handle the error and throw it. */
        throw error;
      }
    }
  },

  /**
   * Method to dispatch the view internal issuer details
   */
  listView() {
    return async (dispatch: any) => {
      dispatch({
        type: issuersVerifiersConstants.LIST_VIEW_INTERNAL_ISSUER_VERIFIER
      })
    }
  },

  /**
   * Method used to clear the issuer details form.
   */
  clearForm() {
    return async (dispatch: any) => {
      dispatch({
        type: issuersVerifiersConstants.CLEAR_ISSUER_VERIFIER_FORM_DATA
      })
    }
  }
}
