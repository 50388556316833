import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { presentProofRequest } from "../../commonConst";
import { presentProofAction } from "../action/presentProofAction";
import _ from 'lodash';
import { t } from 'i18next';

/* Interface for Props variables*/
interface IProofRequestDetailsProps {
    selectedProofDetails: any,
    selectedConnectionName: string,
    presentProofAction: any,
    PresentProofReducer: any
}
/* Interface for local states variables*/
interface IProofRequestDetailsState {
    connectionName: string,
    requestedAttributesDetails: any,
    page: number,
    itemPerPage: number,
    searchText: string
}

class ProofRequestDetails extends React.Component<IProofRequestDetailsProps, IProofRequestDetailsState> {
    constructor(props: IProofRequestDetailsProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            connectionName: "",
            requestedAttributesDetails: [],
            page: 1,
            itemPerPage: 10,
            searchText: ""
        }
        /* Binding the method */
        this.selectedCredentialRecord = this.selectedCredentialRecord.bind(this)
    }

    /* Method to get selected credential details */
    selectedCredentialRecord(credentialDetails: any) {
        const { selectedCredentialDetails } = this.props.PresentProofReducer;
        let attributesDetails: any = selectedCredentialDetails
        const credentialValues = JSON.parse(credentialDetails.credential)

        attributesDetails.map((element: any) => {
            if (element.credentialDefinition === credentialValues.cred_def_id) {
                const attributes: any = {}
                element.credentialFields.map((field: string) => {
                    const attributeName = Object.values(field)[0]
                    attributes[attributeName] = credentialValues.attrs[attributeName]
                })
                element['credentialFieldsWithValues'] = attributes;
                element['credentialId'] = credentialValues.referent
            }
        })
        this.props.presentProofAction.showSelectedCredentialForProofDetails(attributesDetails)
    }

    /* UI to show selected proof request details */
    public render() {
        const { selectedProofDetails, selectedConnectionName } = this.props;
        const { requestedAttributesDetails, requestedPredicatesDetails } = selectedProofDetails

        return (
            <>
                <ul className="fa-ul nb-list" key={requestedAttributesDetails[0].presentationExchangeId}>
                    <li className="remove-underline">
                        <i className="fa-li nb-ico nb-verification-dark"></i>
                        <span className="h5">{t("screens:presentProof.proofRequest")}</span>
                    </li>
                    {/* Proof request details block */}
                    <li className="pl-1 pr-0">
                        {requestedAttributesDetails.length > 0 ?
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="font-weight-bold nb-label">{t("screens:presentProof.requestedAttributes")}</label>
                                </div>
                            </div> :
                            <></>
                        }
                        {requestedAttributesDetails.length > 0 && requestedAttributesDetails.map((element: any, index: number) => {
                            return (
                                <div className="row ml-3" key={index}>
                                    <div className="col-sm-12 mb-2">
                                        <i className="fa-li nb-ico nb-organization"></i>
                                        <label className="font-weight-bold">{t("screens:presentProof.givenName")}:</label>
                                        <label>{selectedConnectionName} </label>
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <i className="fa-li nb-ico nb-credential"></i>
                                        <label className="font-weight-bold">{t("screens:presentProof.credentialType")}:</label>
                                        <label>{element.credentialType}</label>
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <i className="fa-li nb-ico nb-credential"></i>
                                        <label className="font-weight-bold">{t("screens:presentProof.credentialName")}:</label>
                                        <label>{element.credentialName ?? '-'}</label>
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <i className="fa-li nb-ico nb-attribute"></i>
                                        <label className="font-weight-bold">{t("screens:presentProof.attributes")}:</label>
                                        {element.credentialFields?.map((item: string, index: number) => {
                                            return (<span key={index} className="badge badge-light nb-badge nb-badge-blue mr-2">{Object.values(item)[0]}</span>)
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                        {requestedPredicatesDetails.length > 0 ?
                            <div className="row mt-3">
                                <div className="col-sm-12">
                                    <label className="font-weight-bold nb-label">{t("screens:presentProof.requestedPredicates")}</label>
                                </div>
                            </div> :
                            <></>
                        }
                        {
                            requestedPredicatesDetails.length > 0 && requestedPredicatesDetails.map((predicate: any, pIndex: number) => {
                                return (
                                    <div className="row ml-3" key={pIndex}>
                                        <div className="col-sm-6 mb-2">
                                            <i className="fa-li nb-ico nb-credential"></i>
                                            <label className="font-weight-bold">{t("screens:presentProof.credentialName")}:</label>
                                            <label>{predicate.credentialName}</label>
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <i className="fa-li nb-ico nb-attribute"></i>
                                            <span className="font-weight-bold">{t("screens:presentProof.condition")}: <span className="badge badge-light nb-badge nb-badge-blue mr-2">{predicate.credentialFields.name}</span> {predicate.credentialFields.p_type} {predicate.credentialFields.p_value}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </li>
                </ul>
            </>
        )
    }
}


function mapStateToProps(state: any) {
    const PresentProofReducer = state.PresentProofReducer;
    return { PresentProofReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentProofAction: bindActionCreators(presentProofAction, dispatch),
    }
}

const proofRequestDetails = connect(mapStateToProps, mapDispatchToProps)(ProofRequestDetails);
export { proofRequestDetails as ProofRequestDetails };
