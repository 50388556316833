import * as React from 'react';
import InternalIssuersList from './InternalIssuersList';
import IssuerVerifierDetails from './IssuerVerifierDetails';
import { t } from 'i18next';

export interface IEditInternalIssuersVerifiersProps {
  IssuerActions: any,
  IssuersReducers: any,
  editInternalIssuerVerifierData: any,
  CredentialReducer: any,
  TaskActions: any,
  TaskReducers: any,
  LoginReducer: any
}

export interface IEditInternalIssuersVerifiersState {
}

export default class EditInternalIssuersVerifiers extends React.Component<IEditInternalIssuersVerifiersProps, IEditInternalIssuersVerifiersState> {
  constructor(props: IEditInternalIssuersVerifiersProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { loggedInUser } = this.props.LoginReducer;
    let subscriptionId = loggedInUser.organization.subscription.id

    return (
      <>
        <div className="row gutters-sm min-h-100 animated fadeIn">
          <div className="col-lg-4 mb-3">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{
                  subscriptionId === 1
                    ? t("screens:existingIssuersVerifiers.issuerTitle")
                    : subscriptionId === 2
                      ? t("screens:existingIssuersVerifiers.verifierTitle")
                      : t("screens:existingIssuersVerifiers.bothTitle")
                }</h1>
              </div>
              <div className="card-body">
                <InternalIssuersList
                  {...this.props}
                  editInternalIssuerVerifierData={this.props.editInternalIssuerVerifierData}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8 mb-3">
            <IssuerVerifierDetails
              {...this.props}
              editInternalIssuerVerifierData={this.props.editInternalIssuerVerifierData}
            />
          </div>
        </div>
      </>
    );
  }
}
