import React from "react";
import { connect } from "react-redux";
import { MyCredentialList } from "../components/MyCredentialList";
import { CredentialDetail } from "../components/CredentialDetails";
import { t } from 'i18next';
import { SuccessPage } from "../components/SuccessPage";

interface IMyCredentialsProps {
    MyCredentialReducer: any
}

interface IMyCredentialsState {
    selectedCredentialDetails: any
}

class MyCredentials extends React.Component<IMyCredentialsProps, IMyCredentialsState> {
    constructor(props: IMyCredentialsProps) {
        super(props)
        this.state = {
            selectedCredentialDetails: {}
        }
        this.selectedCredentialDetails = this.selectedCredentialDetails.bind(this)
    }

    /* Method to set selected request details in local state variable. */
    selectedCredentialDetails(item: any) {
        this.setState({ selectedCredentialDetails: item })
    }

    public render() {
        const { showSuccessPage } = this.props.MyCredentialReducer;
        return (
            <>
                <div className="row gutters-sm min-h-100">
                    <div className="col-md-12 col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:myCredentials.myCredentials")}</h1>
                            </div>
                            <div className="card-body">
                                {/* Component for get credential request list. */}
                                <MyCredentialList />
                            </div>
                        </div>
                    </div>
                    {showSuccessPage ?

                        <div className="col-md-12 col-lg-5 mb-30">
                            {/* Component for show success message. */}
                            <SuccessPage />
                        </div>
                        :
                        <div className="col-md-12 col-lg-5 mb-30">
                            {/* Component for show specific credential details */}
                            <CredentialDetail />
                        </div>
                    }
                </div>
            </>
        );
    }
}

function mapStateToProps(state: any) {
    const MyCredentialReducer = state.MyCredentialReducer;
    return {
        MyCredentialReducer
    }

}


const acceptCredentials = connect(mapStateToProps)(MyCredentials);
export { acceptCredentials as AcceptCredentials };
