import * as React from 'react';
import _ from 'lodash';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { ListScreenOptions } from 'src/types/common.interface';
import { OrganizationCredentialSort } from 'src/types/enums/organization.enums';
import CredentialValueComponent from 'src/Common Components/Components/CredentialValue';

export interface ICredReqWaitingListProps {
  IssuanceReducer?: any,
  LoginReducer?: any,
  LoaderReducer?: any,
  issuanceAction?: any,
  connectionAction?: any,
  ConnectionReducer?: any,
}

export interface ICredReqWaitingListState {
  defaultSearchFields: any,
  credDefSearch: any,
  item_perPage: number,
  pageNo: number,
  isSelected: boolean,
  selectedCredDef: any,
  credDefId: any,
  credentialValues: Array<any>,
  isConnectionSelected: boolean,
  sortingOrder: string,
  connectionSortBy: string,
  isModalShow: boolean,
  isReceivedCredentialModalShow: boolean,
  waitingListFilterValue: string,
  sortingCred: string,
  credsortingBy: string,
  isWatingListPagination: boolean,
  isWatingListSort: boolean,
  enableLoader: boolean,
  issuedCredential: any,
  clearTypeahead: boolean,
  credRequestSortBy: any,
  filterValue: any,
  organizationCredentialSort: OrganizationCredentialSort,
  isModalViewShow: boolean,
  selectedCredential: any,
}

export default class CredReqWaitingList extends React.Component<ICredReqWaitingListProps, ICredReqWaitingListState> {
  constructor(props: ICredReqWaitingListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      credDefSearch: '',
      item_perPage: 10,
      pageNo: 1,
      isSelected: false,
      selectedCredDef: {},
      credDefId: '',
      credentialValues: [],
      isConnectionSelected: false,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      isModalShow: false,
      isReceivedCredentialModalShow: false,
      waitingListFilterValue: '',
      sortingCred: '',
      credsortingBy: '',
      isWatingListPagination: true,
      isWatingListSort: true,
      enableLoader: false,
      issuedCredential: {},
      clearTypeahead: false,
      credRequestSortBy: '',
      filterValue: 'all',
      organizationCredentialSort: OrganizationCredentialSort.id,
      isModalViewShow: false,
      selectedCredential: {}
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.search = this.search.bind(this);
    this.filter = this.filter.bind(this);
    this.selectedReceivedCredentialRecord = this.selectedReceivedCredentialRecord.bind(this);


  }


  onChangePage(page: any) {
    const { loggedInUser } = this.props.LoginReducer;
    const { item_perPage, defaultSearchFields, sortingOrder, filterValue,
      organizationCredentialSort } = this.state;

    const listScreenOptions: ListScreenOptions = {
      search: defaultSearchFields,
      itemsPerPage: item_perPage,
      pageNo: page,
      sortBy: organizationCredentialSort,
      sort: sortingOrder,
      filter: filterValue
    }

    this.props.connectionAction.updateCredPageNo(page);
    // this.props.issuanceAction.getAllCredentialRequest(page, item_perPage, defaultSearchFields, filterValue, sortingOrder, credRequestSortBy);
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
  }

  sorting = (connectionSortBy: string) => {
    const { loggedInUser } = this.props.LoginReducer;
    const { item_perPage, sortingCred, filterValue,
      pageNo, defaultSearchFields, organizationCredentialSort } = this.state;

    sortingCred === 'DESC' ? this.setState({ sortingCred: 'ASC', credsortingBy: connectionSortBy }) : this.setState({ sortingCred: 'DESC', credsortingBy: connectionSortBy });
    let sortValue = sortingCred === 'DESC' ? "ASC" : "DESC";
    // this.props.issuanceAction.getAllCredentialRequest(pageNo, item_perPage, defaultSearchFields, filterValue, sortValue, credRequestSortBy);
    const listScreenOptions: ListScreenOptions = {
      search: defaultSearchFields,
      itemsPerPage: item_perPage,
      pageNo,
      sortBy: organizationCredentialSort,
      sort: sortValue,
      filter: filterValue
    }
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
  }

  selectedReceivedCredentialRecord = (issuedCredential: any) => {
    this.props.issuanceAction.selectedIssueCredentialByWaitingList(issuedCredential)
  }

  search = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { loggedInUser } = this.props.LoginReducer;
    const { item_perPage, pageNo, sortingOrder, filterValue, organizationCredentialSort } = this.state;
    if (event.key === 'Enter') {
      this.setState({ credDefSearch: event.target.value });
      // this.props.issuanceAction.getAllCredentialRequest(pageNo, item_perPage, event.target.value, filterValue, sortingOrder, credRequestSortBy);
      const listScreenOptions: ListScreenOptions = {
        search: event.target.value,
        itemsPerPage: item_perPage,
        pageNo,
        sortBy: organizationCredentialSort,
        sort: sortingOrder,
        filter: filterValue
      }
      this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
    }
  }


  refresh = (event: any) => {
    const { loggedInUser } = this.props.LoginReducer;
    let { defaultSearchFields, item_perPage, pageNo, sortingOrder, filterValue, organizationCredentialSort } = this.state;
    this.props.issuanceAction.getAllCredential('', 0, 0);

    const listScreenOptions: ListScreenOptions = {
      search: defaultSearchFields,
      itemsPerPage: item_perPage,
      pageNo,
      sortBy: organizationCredentialSort,
      sort: sortingOrder,
      filter: filterValue
    }
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)

  }

  filter(credReqFilterValue: any) {
    const { loggedInUser } = this.props.LoginReducer;
    let { defaultSearchFields, item_perPage, pageNo, sortingOrder, organizationCredentialSort } = this.state;
    this.setState({ filterValue: credReqFilterValue })
    // this.props.issuanceAction.getAllCredentialRequest(pageNo, item_perPage, defaultSearchFields, credReqFilterValue, sortingOrder, credRequestSortBy)
    const listScreenOptions: ListScreenOptions = {
      search: defaultSearchFields,
      itemsPerPage: item_perPage,
      pageNo,
      sortBy: organizationCredentialSort,
      sort: sortingOrder,
      filter: credReqFilterValue
    }
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
  }

  showModal = (issuedCredential: any) => {
    this.setState({
      selectedCredential: issuedCredential,
      isModalViewShow: true,
    })
  }

  closeModal = () => {
    this.setState({
      isModalViewShow: false,
    })
  }

  handleDone = () => {
    // No handling required.
  }

  public render() {
    const { credentialRecords, tHead, totalCredentialRecords } = this.props.IssuanceReducer;
    const { isModalViewShow, selectedCredential } = this.state

    return (
      <div>
        <TableComponents
          onChangePage={this.onChangePage}
          showElementDetails={this.showModal}
          tHead={tHead}
          tableContent={credentialRecords}
          totalRecords={totalCredentialRecords}
          sorting={this.sorting}
          searching={this.search}
          refresh={this.refresh}
        />
        <CredentialValueComponent
          isModalShow={isModalViewShow}
          selectedConnection={selectedCredential.connection}
          selectedCredDef={selectedCredential.credentialProposalDict}
          closeModal={this.closeModal}
          credentialState={selectedCredential?.state ?? ''}
          handleCredentialValueSubmit={this.handleDone}
          isEdit={false}
          modalTitle={''}
        />
      </div>

    );
  }
}
