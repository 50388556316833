import { issuersVerifiersConstants } from "../../types";

const initialState = {
  employeeData: [],
  addedIssuerVerifier: {},
  issueLoginCredential: false,
  roles: [],
  internalIssuerVerifierData: [],
  selectedInternalIssuerVerifier: {},
  editInternalIssuerVerifier: false,
  editInternalIssuerVerifiers: {},
  viewInternalIssuerVerifier: {},
  listViewOfInternalIssuerVerifier: false,
  totalInternalIssuersVerifiers: 0,
}

export function IssuersReducers(state = initialState, action: any) {

  switch (action.type) {
    case issuersVerifiersConstants.GET_ALL_EMPLOYEES:
      return Object.assign({}, state, {
        employeeData: action.payload.data.data,
      });

    case issuersVerifiersConstants.ADD_ISSUER_VERIFIER_SUCCESS:
      return Object.assign({}, state,
        {
          addedIssuerVerifier: action.payload.data.data,
        })
    case issuersVerifiersConstants.ISSUE_LOGIN_CREDENTIAL:
      return Object.assign({}, state,
        {
          issueLoginCredential: true,
          addedIssuerVerifier: {},
        })
    case issuersVerifiersConstants.ADD_ANOTHER_ISSUER_VERIFIER:
      return Object.assign({}, state,
        {
          issueLoginCredential: false,
          addedIssuerVerifier: {},

        })
    case issuersVerifiersConstants.GET_ALL_ROLES:
      return Object.assign({}, state,
        {
          roles: action.payload.data.data,

        })
    case issuersVerifiersConstants.GET_ALL_INTERNAL_ISSUER_VERIFIER:
      return Object.assign({}, state,
        {
          internalIssuerVerifierData: action.tmpArray,
          totalInternalIssuersVerifiers: action.payload.data.data.totalItems,
          selectedInternalIssuerVerifier: action.payload.data.data.data[0],

        })
    case issuersVerifiersConstants.SELECTED_INTERNAL_ISSUER_VERIFIER:
      return Object.assign({}, state,
        {
          selectedInternalIssuerVerifier: action.payload,

        })
    case issuersVerifiersConstants.EDIT_INTERNAL_ISSUER_VERIFIER:
      return Object.assign({}, state,
        {
          editInternalIssuerVerifier: true,
          editInternalIssuerVerifiers: action.payload,
          listViewOfInternalIssuerVerifier: false,
        })
    case issuersVerifiersConstants.UPDATE_ISSUER_VERIFIER:
      return Object.assign({}, state,
        {
          editInternalIssuerVerifier: true,
          editInternalIssuerVerifiers: {},
          viewInternalIssuerVerifier: action.payload.data.data,
        })
    case issuersVerifiersConstants.VIEW_INTERNAL_ISSUER_VERIFIER:
      return Object.assign({}, state,
        {
          viewInternalIssuerVerifier: action.payload,
          editInternalIssuerVerifiers: {},

        })
    case issuersVerifiersConstants.LIST_VIEW_INTERNAL_ISSUER_VERIFIER:
      return Object.assign({}, state, {
        listViewOfInternalIssuerVerifier: true,
        viewInternalIssuerVerifier: {},
        editInternalIssuerVerifier: false,
      })
    case issuersVerifiersConstants.ISSUE_LOGIN_CREDENTIAL_FAILED:
      return Object.assign({}, state, {
        addedIssuerVerifier: {},
        issueLoginCredential: false,
      })
    case issuersVerifiersConstants.CLEAR_ISSUER_VERIFIER_FORM_DATA:
      return Object.assign({}, state, {
        addedIssuerVerifier: {},
        issueLoginCredential: false,
      })
    default:
      return state
  }
}
