import images from 'src/utils/images';
import './display-comment.css'
import { DisplayAccordion } from "./DisplayAccordion";
import { DisplayCards } from "./DisplayCards";

const DisplayCredentialCommentComponent = ({ data }: any) => {
  console.log('data', data)

  return (
    <div className="app">
      {
        data && data.title &&
        <div className='row col-sm-12'>
          <div className='form-group'>
            <img className="comment-header-icon" alt="certified" src={images.certificate} />
            <span className='comment-header pb-2'>{data.title}</span>
          </div>
        </div>
      }
      {
        Array.isArray(data) && data.length > 0 &&

        data.map((details: any, index: any) => {

          if (details.type === 'text') {

            if (Array.isArray(details.details) && details.type === 'box') {
              return <DisplayCards boxDetails={details} />
            }
            return (
              <div className='form-group'>
                <img className="comment-header-icon" alt="certified" src={images.certificate} />
                <span className='comment-header pb-2'>{details.title}</span>
              </div>
            )
          }
          if (details.type === 'boolean') {
            return (
              <div className='row form-group'>
                <div className='col-sm-6'>
                  <span className='comment-header pb-2'>{details.title}</span>
                </div>
                <div className='col-sm-6 text-right'>
                  {details.value && <img src={details.value ? images.greenCheck : ''} alt='value' />}
                </div>
              </div>
            )
          }
          if (details.type === 'table') {
            return <DisplayAccordion
              key={index}
              title={details.title}
              details={details.details}
            />
          }
          if (details.type === 'box') {
            return <DisplayCards boxDetails={details} />
          }

        })
      }
      {
        data.Accordions &&
        <div className='row'>
          <div className='col-sm-12'>
            {data.Accordions.map((accordion: any, index: any) => (
              <DisplayAccordion
                key={index}
                title={accordion.title}
                details={accordion.details}
              />
            ))}
          </div>
        </div>
      }
      {
        data.boxes &&
        <div className='row'>
          <div className='col-sm-12'>
            <h1 className='pb-3'>{data.boxes.title}</h1>
          </div>
          <div className='col-sm-12'>
            {data.boxes.details.map((cardDetails: any, index: any) => {
              return <DisplayCards boxDetails={cardDetails} />
            })}
          </div>
        </div>
      }
    </div>
  );
};

export default DisplayCredentialCommentComponent;
