import { Accordion, Card, } from "react-bootstrap";
import images from "src/utils/images";

const renderTableDetails = (itemDetails: any, tabIndex?: any) => {

    return (
        Array.isArray(itemDetails)
            ? itemDetails.map((tableItem: any, tableIndex: any) => {
                return (<tr key={tableIndex}>
                    <td className="table-header-wrapper">{tableItem.label}</td>
                    <td className='table-data-wrapper'>{tableItem.value}</td>
                </tr>)
            })
            :
            (<tr key={tabIndex}>
                <td className="table-header-wrapper">{itemDetails.label}</td>
                <td className='table-data-wrapper'>{itemDetails.value}</td>
            </tr>)
    )
}

export const DisplayAccordion = ({ title, details }: any) => {
    return (
        <div className='form-group'>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" >
                        <img className='mr-2' src={images.idBadge} alt='badge' />{title}
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            {Array.isArray(details) &&
                                <table className="table table-borderless">
                                    <tbody>

                                        {details.map((tableItem: any, tableIndex: any) => {
                                            if (tableItem.hasOwnProperty('title')) {
                                                return (<>
                                                    <tr>
                                                        <td className="table-title-wrapper">{tableItem.title}</td>
                                                        <td></td>
                                                    </tr>

                                                    {renderTableDetails(tableItem.details)}
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </>
                                                )
                                            }
                                            else {

                                                return renderTableDetails(tableItem, tableIndex)
                                            }
                                        })}
                                    </tbody>
                                </table>
                            }

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};
