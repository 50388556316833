import { organizationConst } from "../../types";
import { t } from 'i18next';

/* Initial value declation */
const initialState = {
  initialPassword: '',
  tHead: [],
  organizationList: [],
  selectedOrganization: {},
  selectedOrg: {},
  onboardingOrg: false,
  rejectedOrg: false,
  showErrorMessageForOrgName: false,
  showErrorMessageForAdminEmail: false,
  clearTypeahead: false,
  kompanyVerificationResponse: {},
  // sendConnectionInvitationResponse: {},
  // acceptConnectionInvitationResponse: {},
  formData: {},
  invitationTHead: [],
  totalInvitations: 0,
  organizationInvitationList: [],
  connectionTHead: [],
  selectedInvitationDetails: {},
  selectOrganization: {},
  showErrorMessage: false,
  acceptSendSuccess: false,
  connectedOrgName: ''
}

export function OnboardingReducer(state = initialState, action: any) {
  /* Column name for organization list table */
  let tHead = [{
    'name': 'ORGANIZATION',
    'label': "orgName"
  }, {
    'name': 'VERIFY DETAILS',
    'label': "details"
  }, {
    'name': 'ACTIVE',
    'label': "isActive"
  }, {
    'name': 'STATUS',
    'label': "isOnBoarded"
  }];

  /* Column name for connection list table */
  let connectionTHead = [{
    'name': `${t("screens:onboardingNewOrganization.headGivenName")}`,
    'label': "toOrgName"
  }, {
    'name': `${t("screens:onboardingNewOrganization.headEmail")}`,
    'label': "toOrgEmail"
  }, {
    'name': `${t("screens:onboardingNewOrganization.headStatus")}`,
    'label': "status"
  }
  ];

  let invitationTHead = [{
    'name': `${t("screens:acceptInvitation.headGivenName")}`,
    'label': "toOrgName"
  }, {
    'name': `${t("screens:acceptInvitation.headEmail")}`,
    'label': "toOrgEmail"
  }, {
    'name': `${t("screens:acceptInvitation.headStatus")}`,
    'label': "status"
  }, {
    'name': `${t("screens:acceptInvitation.headDate")}`,
    'label': "createDateTime"
  }, {
    'name': '',
    'label': "details"
  }
  ];


  switch (action.type) {
    /* Generated initial password set them into an initial password variable. */
    case organizationConst.GET_INITIAL_PASSWORD:
      return Object.assign({},
        state,
        {
          initialPassword: action.payload.data.data,
          showErrorMessageForOrgName: false,
          clearTypeahead: false,
        });
    /* Get all on-boarded organization list API responses and set them into corresponding variables. */
    case organizationConst.GET_ALL_REQUEST:
      return Object.assign({},
        state,
        {
          organizationList: action.OnboardingList,
          totalOrganization: action.payload.data.data.totalItems,
          tHead: tHead
        });
    /* Get specific organization details and set them into its variable. */
    case organizationConst.SELECTED_ONBOARDING_ORGANIZATION:
      return Object.assign({},
        state,
        {
          selectedOrg: action.payload.data.data,
        });
    /* Accept organization onboard request */
    case organizationConst.ONBOARDING_ORGANIZATION_ACCEPTED:
      return Object.assign({},
        state,
        {
          onboardingOrg: true,
        });
    /* Reject organization onboard request */
    case organizationConst.ONBOARDING_ORGANIZATION_REJECTED:
      return Object.assign({},
        state,
        {
          rejectedOrg: true,
        })
    /* Set true for show error message on organization admin UI */
    case organizationConst.SHOW_MESSAGE_FOR_ORGNAME:
      return Object.assign({},
        state,
        {
          showErrorMessageForOrgName: true
        })
    /* Set true for show error message on platform admin UI */
    case organizationConst.SHOW_MESSAGE_FOR_ADMIN_EMAIL:
      return Object.assign({},
        state,
        {
          showErrorMessageForAdminEmail: true
        })
    /* Set false for show error message on organization admin UI */
    case organizationConst.HIDE_MESSAGE_FOR_ORGNAME:
      return Object.assign({},
        state,
        {
          showErrorMessageForOrgName: false,
        })
    /* Set false for show error message on platform admin UI */
    case organizationConst.HIDE_MESSAGE_FOR_ADMIN_EMAIL:
      return Object.assign({},
        state,
        {
          showErrorMessageForAdminEmail: false,
        })
    /* Clear any dropdown values */
    case organizationConst.CLEAR_TYPEAHEAD:
      return Object.assign({},
        state,
        {
          clearTypeahead: true,
        })
    /* Get all organization connection list response set them into corresponding variables.*/
    case organizationConst.GET_ALL_ORGANIZATION_CONNECTION:
      return Object.assign({},
        state,
        {
          totalOrganizationConnection: action.payload.totalOrganizationConnection,
          organizationConnectionList: action.payload.organizationConnectionList,
          connectionTHead
        })
    /* Generate connection invitation and set them into corresponding variables*/
    case organizationConst.GENERATE_CONNECTION_INVITATION:
      return Object.assign({},
        state,
        {
          connectionInvitation: action.payload
        })
    /* Get all on boarded organization list for sending the connection invitation*/
    case organizationConst.GET_ALL_ONBOARD_ORGANIZATION:
      return Object.assign({},
        state,
        {
          onboardOrganizationList: action.payload
        })

    /* Kompany verification */
    case organizationConst.KOMPANY_VERIFICATION:
      return Object.assign({},
        state,
        {
          kompanyVerificationResponse: action.payload
        }
      )

    /* Send the connection invitation to organization */
    case organizationConst.SEND_INVITATION_TO_ORGANIZATION:
      return Object.assign({},
        state,
        {
          sendConnectionInvitationResponse: action.payload
        })

    /* Accept connection invitation */
    case organizationConst.ACCEPT_INVITATION:
      return Object.assign({},
        state,
        {
          acceptConnectionInvitationResponse: action.payload,
          acceptSendSuccess: true,
          connectedOrgName: action.orgName
        })

    case organizationConst.GET_ALL_INVITATION:
      return Object.assign({},
        state,
        {
          totalInvitations: action.payload.data.data.data.totalItems,
          organizationInvitationList: action.payload.data.data.data,
          invitationTHead
        })

    /* Save connection invitation */
    case organizationConst.SAVE_INVITATION:
      return Object.assign({},
        state,
        {
          saveConnectionInvitationResponse: action.payload,
        })

    case organizationConst.SHOW_SELECTED_INVITATION_DETAILS:
      return Object.assign({}, state, {
        selectedInvitationDetails: action.payload,
      });

    case organizationConst.CLEAR_SELECTED_INVITATION_DETAILS:
      return Object.assign({}, state, {
        selectedInvitationDetails: {},
        formData: {},
        errors: {},
        acceptSendSuccess: false,
        connectedOrgName: ''
      });

    case organizationConst.CHECK_EXISTING_ORG_EMAILID:
      return Object.assign({}, state, {
        showErrorMessage: true,
      });

    case organizationConst.HIDE_CHECK_EXISTING_ORG_EMAILID:
      return Object.assign({}, state, {
        showErrorMessage: false,
      });

    case organizationConst.INVITE_NEW_ORG:
      return Object.assign({}, state, {
        inviteNewOrganization: false,
      });

    default:
      return state
  }
}

