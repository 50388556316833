import { adminConnectionConst } from "../../types";
import { t } from 'i18next';

const initialState = {
  qrData: "",
  adminList: [],
  totalItems: 0,
  selectAdmin: {},
  // selectedClient:{},
  tHead: [],
  inviteNewAdmin: false,
  emailSent: false,
  // inviteNewClient: false,
  showErrorMessage: false,
  showErrorMessageName: false,
  isErrorInConnection: false
}

export function AdminReducer(state = initialState, action: any) {
  let tHead = [{
    'name': `${t("screens:onboardingNewAdmins.givenNameHead")}`,
    'label': "adminName"
  }, {
    'name': `${t("screens:onboardingNewAdmins.emailHead")}`,
    'label': "email"
  }, {
    'name': `${t("screens:onboardingNewAdmins.statusHead")}`,
    'label': "connectionStatus"
  }];
  switch (action.type) {
    case adminConnectionConst.GET_ADMIN_QR:
      return Object.assign({}, state, {
        qrData: action.payload,
        showErrorMessage: false,
      });
    case adminConnectionConst.GET_ADMIN_LIST:
      return Object.assign({}, state, {
        adminList: action.tmpArray,
        totalItems: action.payload.data.data.totalItems,
        tHead: tHead,
        selectAdmin: {},
        showErrorMessageName: false,
        showErrorMessage: false,
      });
    case adminConnectionConst.SELECT_ADMIN:
      return Object.assign({}, state, {
        selectAdmin: action.payload,
      });
    // case adminConnectionConst.SELECT_CLIENT:
    //   return Object.assign({}, state, {
    //     selectedClient: action.payload,
    //   });
    case adminConnectionConst.CLEAR_SELECTION:
      return Object.assign({}, state, {
        selectAdmin: {},
        inviteNewAdmin: false,
        // selectedClient: {},
      });
    case adminConnectionConst.SHOW_NEW_INVITE:
      return Object.assign({}, state, {
        inviteNewAdmin: true,
        emailSent: false,
        showErrorMessage: false,
      });
    case adminConnectionConst.EMAIL_SENT:
      return Object.assign({}, state, {
        emailSent: true,
        showErrorMessage: false,
      });
    case adminConnectionConst.INVITE_NEW_ADMIN:
      return Object.assign({}, state, {
        inviteNewAdmin: false,
      });
    case adminConnectionConst.CHECK_EXISTING_ADMIN_EMAILID:
      return Object.assign({},
        state,
        {
          showErrorMessage: true,
          isErrorInConnection: false
        });
    case adminConnectionConst.CHECK_EXISTING_ADMIN_NAME:
      return Object.assign({},
        state,
        {
          showErrorMessageName: true,
          isErrorInConnection: false
        });
    case adminConnectionConst.HIDE_CHECK_EXISTING_ADMIN_EMAILID:
      return Object.assign({},
        state,
        {
          showErrorMessage: false,
          isErrorInConnection: false
        })
    case adminConnectionConst.HIDE_CHECK_EXISTING_ADMIN_NAME:
      return Object.assign({},
        state,
        {
          showErrorMessageName: false,
          isErrorInConnection: false
        })
    case adminConnectionConst.ERROR_IN_CONNECTION:
      return Object.assign({},
        state,
        {
          isErrorInConnection: true,
        })
    default:
      return state
  }
}

