import { orgProfileConstants, settingUpDashboardConst } from "../../../types";

const initialState = {
    profileData: {},
    activityData: [],
    didDetails: {},
    dnsEntries: [],
    issuanceAuthorization: '',
    authenticData: {},
    publicDnsEntries: []
}

export function orgProfileReducer(state = initialState, action: any) {
    switch (action.type) {
        case settingUpDashboardConst.UPDATE_ORG_PROFILE:
            return Object.assign({},
                state,
                {
                    profileData: action.payload.data.data,
                    headerProfileLogo: action.payload.data.data.profileLogo
                })
        case settingUpDashboardConst.GET_ALL_ACTIVITY_COUNT:
            return Object.assign({},
                state,
                {
                    activityData: action.payload.data.data,
                    profileData: {}
                })

        case orgProfileConstants.SET_DID_DETAILS:
            return Object.assign({},
                state,
                {
                    didDetails: action.payload.data.data,
                })

        case orgProfileConstants.SET_DNS_ENTRIES:
            return Object.assign({}, state,
                {
                    dnsEntries: action.payload.data.data,
                })

        case orgProfileConstants.SET_PUBLIC_DNS_ENTRIES:
            return Object.assign({}, state,
                {
                    publicDnsEntries: action.payload.data.data,
                })

        case orgProfileConstants.CHECK_DID_AUTHENTIC:
            return Object.assign({}, state,
                {
                    authenticData: action.payload.data.data,
                })

        case orgProfileConstants.SET_ISSUANCE_AUTHORITY:
            return Object.assign({}, state,
                {
                    issuanceAuthorization: action.payload.data.data,
                })
        default:
            return state;
    }
}