import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, presentationProposalConstants } from "../../types"
import { t } from 'i18next';
import _ from "lodash";
import { ListScreenOptions } from "src/types/common.interface";
import { PresentProofState } from "src/View/commonConst";

export const presentationProposalAction = {

    createNewProposal() {
        return async (dispatch: any) => {
            dispatch({
                type: presentationProposalConstants.CREATE_NEW_PROPOSAL,
            })
        }
    },

    /**
     * Method to get present proof request list
     * @param page Parameter for current page no for pagination.
     * @param itemPerPage Parameter for setting the how many number of item required per page.
     * @param searchText This parameter use for searching the content in database.
     * @returns Get all present proof records.
     */
    getAllPresentationRequests(listScreenOptions: ListScreenOptions) {
        console.log('listScreenOptions',listScreenOptions)
        return async (dispatch: any) => {
            try {
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Get authentication token from local storage */
                let token = localStorage.getItem('token');
                const params = {
                    page: listScreenOptions.pageNo,
                    items_per_page: listScreenOptions.itemsPerPage,
                    ...(listScreenOptions.search && { search_text: listScreenOptions.search }),
                    ...(listScreenOptions.sort && { sort_value: listScreenOptions.sort }),
                    ...(listScreenOptions.sortBy && { present_proof_sort: listScreenOptions.sortBy }),
                    ...(listScreenOptions.filter && { filter_text: listScreenOptions.filter }),
                }
                /* API call to get proof request records and dispatch the get all present proof request list */
                return await axios.get(`${config.apiUrl}/verifier/present-proofs`,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        params
                    })
                    .then(getAllPresentationList => {
                        /* Add the connection name in response */
                        // getAllProposalList.data.data.data.map((element: any) => {
                        let tmpArray: any = [];
                        tmpArray = !_.isEmpty(getAllPresentationList) && getAllPresentationList.data && getAllPresentationList.data.data && getAllPresentationList.data.data.data;
                        tmpArray.map((ele: any, index: any) => {
                            tmpArray[index].proofState = ele.state;
                            tmpArray[index].state = `${t(`screens:verificationMonitoring.${ele.state.toLowerCase()}`)}`;
                            // out-of-band
                            if (ele.connectionId) {
                                tmpArray[index].connectionName = ele.connections?.theirLabel;
                            } else {
                                tmpArray[index].connectionName = ""
                            }
                            // out-of-band
                            // if (element.connectionId) {
                            //     return (element['connectionName'] = element.connections?.theirLabel)
                            // } else {
                            //     return (element['connectionName'] = "")
                            // }
                            // })
                        })
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentationProposalConstants.GET_ALL_PRESENTATION_PROPOSAL_LIST,
                            payload: getAllPresentationList.data.data,
                            tmpArray
                        })
                        if (tmpArray.length) {
                            dispatch({
                                type: presentationProposalConstants.SELECTED_PRESENTATION_PROPOSAL_RECORD,
                                payload: tmpArray[0]
                            })
                        }
                    }).catch((error: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch)
                    })

            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    /**
     * Method to send the proof presentation.
     * @param proofPresentation Parameter includes the send proof presentation payload
     * @returns It return the response of send proof request API
     */
    createPresentationProposal(proofPresentation: any) {
        return async (dispatch: any) => {
            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                return await axios.post(`${config.apiUrl}/verifier/prover/send-proposal`, proofPresentation, {
                    headers: { "Authorization": `Bearer ${token}` }
                })
                    .then((presentationProposalResponse: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentationProposalConstants.CREATE_PRESENTATION_PROPOSAL,
                            payload: presentationProposalResponse.data.data.data
                        })

                        const getAllListAPIParameter = {
                            pageNo: 1,
                            itemsPerPage: 10,
                            filter: PresentProofState.STATE_PROPOSAL_SENT
                        }
                        this.getAllPresentationRequests(getAllListAPIParameter)
                        dispatch({
                            type: presentationProposalConstants.SHOW_NEW_PROPOSAL
                        })
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                throw error;
            }
        }
    },

    showSelectedCredentialDetails(item: any) {
        return async (dispatch: any) => {
            dispatch({
                type: presentationProposalConstants.SELECTED_PRESENTATION_PROPOSAL_RECORD,
                payload: item
            })
        }
    }

}
