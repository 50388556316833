export const tHeadOnLedgerList = [{
  'name': 'ORGANIZATION',
  'label': "orgName"
}, {
  'name': 'DID',
  'label': "did"
}, {
  'name': 'ON-LEDGER',
  'label': "isNym"
}, {
  'name': 'IS PUBLIC DID',
  'label': "agentSpinupStatus"
}, {
  'name': 'STATUS',
  'label': "organizationRunningStatus"
}, {
  'name': 'ACTION',
  'label': "updateOrganizationStatus"
}];

export const filterOnLedgerList = [{
  'name': 'All',
  'tag': 'all'
}, {
  'name': 'Stopped',
  'tag': 'Stop'
}, {
  'name': 'Active',
  'tag': 'Active'
}, {
  'name': 'Removed',
  'tag': 'Removed'
}
];