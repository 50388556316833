import * as React from 'react';

// Utilities
import images from 'src/utils/images';

export interface IPendingRequestProps {
  // LoaderReducer:any
}

export interface IPendingRequestState {
}

export default class PendingRequest extends React.Component<IPendingRequestProps, IPendingRequestState> {
  constructor(props: IPendingRequestProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    // const { loader } = this.props.LoaderReducer;

    return (
      <>
        {/* {
          loader ?
            <PageLoader></PageLoader>
            : null
        } */}
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8">
            <div className="card">
              <div className="card-header bg-white">
                <h1>Registration</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 text-center mb-3">
                    <img src={images.illustrationSuccess} alt="Pending approval" />
                  </div>
                  <div className="col-lg-12 mb-3 text-center">
                    <h3>Approval is pending !</h3>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <a type="button" className="btn btn-block rounded-pill btn-outline-primary mr-3" href="/login">
                        BACK TO LOGIN
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
