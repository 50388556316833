import { t } from 'i18next';

export const tHeadInstantTaskList = [{
  'name': `${t("screens:verificationMonitoring.constDate")}`,
  'label': "createDateTime"
}, {
  'name': `${t("screens:verificationMonitoring.constGivenName")}`,
  'label': "connectionName"
}, {
  'name': `${t("screens:verificationMonitoring.constConnectionDID")}`,
  'label': "theirDid"
}, {
  'name': `${t("screens:verificationMonitoring.constProofRequest")}`,
  'label': "details"
}, {
  'name': `${t("screens:verificationMonitoring.constStatus")}`,
  'label': "state"
}, {
  'name': `${t("screens:verificationMonitoring.constResult")}`,
  'label': "verified"
}
];

export const tHeadScheduleTaskList = [
  {
    'name': `${t("screens:verificationMonitoring.constTitle")}`,
    'label': "title"
  },
  {
    'name': `${t("screens:verificationMonitoring.constStartDate")}`,
    'label': "startDate"
  },
  {
    'name': `${t("screens:verificationMonitoring.constDuration")}`,
    'label': "duration"
  },
  {
    'name': `${t("screens:verificationMonitoring.constCredentialRequired")}`,
    'label': "credentialRequired"
  },
  {
    'name': '',
    'label': "threeBtn"
  },
]

export const tHeadScheduledTaskShowDetails = [
  {
    'name': `${t("screens:verificationMonitoring.constTitle")}`,
    'label': "title"
  },
  {
    'name': `${t("screens:verificationMonitoring.constDate")}`,
    'label': "startDate"
  },
  {
    'name': `${t("screens:verificationMonitoring.constTime")}`,
    'label': "duration"
  },
  {
    'name': `${t("screens:verificationMonitoring.constCredReqInfo")}`,
    'label': "credentialRequired"
  },
  {
    'name': '',
    'label': "qrcodeshow"
  },
]

export const tHeadVerificationDetails = [
  // {
  //   'name': 'NUMBER',
  //   'label': ""
  // },
  {
    'name': `${t("screens:verificationMonitoring.constConnection")}`,
    'label': "verificationconnectionId"
  },
  {
    'name': `${t("screens:verificationMonitoring.constTime")}`,
    'label': "verificationTime"
  },
  {
    'name': `${t("screens:verificationMonitoring.constStatus")}`,
    'label': "verificationStatus"
  },
  {
    'name': `${t("screens:verificationMonitoring.constVerified")}`,
    'label': "stverified"
  },

]
