import React, { createContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import store from './store';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from 'react-redux-toastr';
import { LoginPage } from "./View/Login/components/login";
import { QRLoginPage } from "./View/Login/components/authenticate";
import { Register } from './View/Register/container/Register';
import SignUp from './Common Components/DefaultLayout/mockups/signUp';
import { PlatformAdminLogin } from './View/Login/components/Platform-Admin-login';
import { CreateWallet } from './View/Create-Wallet/container/CreateWallet';
import { EmptyCart } from './Common Components/Components/EmptyCart';
import { ShowQRCode } from './View/Settings/EmailTemplates/container/ShowQRCode';
import DefaultCredDefSuccess from './View/Set-DID-Public/components/DefaultCredDefSuccess';

// Utilities
import { ContextProvider } from 'src/contexts';
import { colors } from "src/utils/theme";
import { setColorCssVariables } from "src/utils/colors";
import { socket, SocketContext } from './contexts/SocketContext';


// NOTE: Initialize colour variables on document load (may not be best behaviour!)
setColorCssVariables(colors);

export const history = createBrowserHistory();
  
const AppWrapper = (
  <React.StrictMode>
    <ContextProvider>
      <SocketContext.Provider value={socket}>
      <Provider store={store}>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={QRLoginPage} />
              {/*  split screen <Route exact path="/login" component={LoginPage} /> */}
              <Route exact path="/first-time-user" component={LoginPage} />
              <Route exact path="/authenticate" component={QRLoginPage} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/palogin" component={PlatformAdminLogin} />
              <Route exact path="/register" component={() => <Register selectedRecord={{}} />} />
              <Route exact path="/create-wallet" component={CreateWallet} />
              <Route exact path="/success" component={DefaultCredDefSuccess} />
              <Route exact path="/empty" component={EmptyCart} />
              <Route exact path="/qr-code/:details" component={(props: any) => <ShowQRCode {...props} />} />
              <Route exact path="" component={App} />
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
          <ReduxToastr
            timeOut={3000}
            newestOnTop={true}
            preventDuplicates
            position="top-right"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
            progressBar
            closeOnToastrClick={false}
          />
      </Provider>
      </SocketContext.Provider>
    </ContextProvider>
  </React.StrictMode>
);

ReactDOM.render(
  AppWrapper,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
