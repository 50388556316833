import * as React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { t } from 'i18next';

let QRCode = require('qrcode.react');

export interface IShowQRCodeModalProps {
  isOpen: any,
  closeModal: any,
  QRData: any,
}

export interface IShowQRCodeModalState {
}

export default class ShowQRCodeModal extends React.Component<IShowQRCodeModalProps, IShowQRCodeModalState> {
  constructor(props: IShowQRCodeModalProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { isOpen, closeModal, QRData } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} size="lg" className="nb-modal" toggle={closeModal}>
          <ModalHeader>
            <h4>{t("screens:verificationMonitoring.proofDetails")}</h4>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12 text-center mb-3">
                <QRCode value={QRData} size={400} />
              </div>
            </div>

            <div className="col-sm-12 text-right">
              <Button variant="secondary" className="btn btn-secondary rounded-pill" onClick={closeModal}>
              {t("screens:verificationMonitoring.close")}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
