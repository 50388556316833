import { t } from "i18next"
import _ from "lodash"
import DisplayCredentialCommentComponent from "src/Common Components/Components/Comments/DisplayCredentialComment";
import DisplayVPCredentialCommentComponent from "src/Common Components/Components/Comments/VerifiedPerson/DisplayCredentialComment";
import { CommentsCredential } from "src/Common Components/Components/Comments/enums";
import { SchemaDateSuffix } from "src/View/Schema/models/schema.enum";
import { displayDateFormat } from "src/utils/date.utils";
import { isJSON } from "src/utils/json";

export const CredentialDetails = ({ NegotiateCredentialReducer }: any) => {
    const { selectedCredentialRecord } = NegotiateCredentialReducer;

    const credentialProposalDictObj = !_.isEmpty(selectedCredentialRecord) && JSON.parse(selectedCredentialRecord.credentialProposalDict)

    const attributes = !_.isEmpty(selectedCredentialRecord) && credentialProposalDictObj.credential_proposal.attributes;

    return (
        <>
            <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:declinedCredentials.credentialDetails")}</h1>
            </div>
            {!_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">
                        <div className="mb-5">
                            <ul className="nb-list">
                                <li className="pl-1 pr-0">
                                    <div>
                                        <label className="font-weight-bold">{t("screens:declinedCredentials.credentialName")}: </label>
                                        <p>{!_.isEmpty(selectedCredentialRecord)
                                            && selectedCredentialRecord?.credentialDefinitionId?.split(':')[4]} </p>
                                    </div>
                                </li>
                                <li className="pl-1 pr-0">
                                    <div>
                                        <label className="font-weight-bold">{t("screens:declinedCredentials.givenName")}: </label>
                                        <p>{!_.isEmpty(selectedCredentialRecord)
                                            && selectedCredentialRecord.connectionName}</p>
                                    </div>
                                </li>
                                <li className="pl-1 pr-0 ">
                                    <>
                                        <label className="font-weight-bold">{t("screens:declinedCredentials.credentialValues")}: </label>
                                        <ul>
                                            {selectedCredentialRecord && attributes.map((item: any, index: any) => {
                                                if (!item.hasOwnProperty("mime-type")) {
                                                    return <li key={index} className="remove-underline"><label className="font-weight-bold">{item.name}: {(item.name).includes(SchemaDateSuffix.DATE_SUFFIX)
                                                        ? displayDateFormat(item.value)
                                                        : item.value}</label></li>
                                                } else {
                                                    return <>
                                                        <li key={index} className="remove-underline">
                                                            <label className="font-weight-bold">{item.name}: <img src={item.value} alt={item.name} className="preview" height="50"></img></label>
                                                        </li>
                                                    </>
                                                }
                                            })}
                                        </ul>
                                    </>
                                </li>
                                <li className="pl-1 pr-0 ">
                                    <label className="font-weight-bold">{t("screens:negotiateCredential.commentTitle")}: </label>
                                    {
                                        isJSON(credentialProposalDictObj.comment)
                                            ? credentialProposalDictObj?.schema_id?.split(':')[2] === CommentsCredential.VERIFIED_PERSON
                                                ? <DisplayVPCredentialCommentComponent data={JSON.parse(credentialProposalDictObj.comment)} />
                                                : <DisplayCredentialCommentComponent data={(JSON.parse(credentialProposalDictObj.comment))} />
                                            : <p>{credentialProposalDictObj.comment}</p>
                                    }
                                </li>
                            </ul>
                        </div>

                    </div>

                </>
            }
            {_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">
                        <div className="blank-credential-details-body">
                            <label className="">{t("screens:declinedCredentials.noRequestReceived")}</label>
                        </div>
                    </div>
                </>
            }
        </>
    )
}