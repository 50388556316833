export const hasDupsObjects = (array: any) => {

    return array.map(function (value: any) {
        if (value.tag)
            return value.tag + value.type
        else
            return value.credentialDefinitionId + value.rVC_type

    }).some(function (value: any, index: number, array: any) {
        return array.indexOf(value) !== array.lastIndexOf(value);
    })
}
