import _ from 'lodash';
import * as React from 'react';
import InstantTask from './InstantTask';
import ScheduleTask from './ScheduledTask';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ITaskTypeListProps {
  TaskReducers: any,
  TaskActions: any,
  connectionAction: any,
  ConnectionReducer: any,
  credentialAction: any,
  CredentialReducer: any,
  WorkflowReducer: any,
  IssuanceReducer: any,
  LoginReducer: any
}

export interface ITaskTypeListState {
  showScheduledTaskList: boolean,
  selectedScheduleTask: any,
}

export default class TaskTypeList extends React.Component<ITaskTypeListProps, ITaskTypeListState> {
  constructor(props: ITaskTypeListProps) {
    super(props);

    this.state = {
      showScheduledTaskList: false,
      selectedScheduleTask: {}
    }

    this.clickInstantTask = this.clickInstantTask.bind(this);
    this.createScheduleTask = this.createScheduleTask.bind(this);
    this.showScheduleTaskList = this.showScheduleTaskList.bind(this);
  }

  clickInstantTask(event: any) {
    this.props.TaskActions.showInstantTask()
    this.setState({
      showScheduledTaskList: false,
      selectedScheduleTask:{}
    })
  }

  createScheduleTask(event: any, item: any) {
    this.props.TaskActions.clickScheduledTask(item);
    // if(_.isEmpty(scheduleTaskData)){
    //   this.setState({
    //     selectedScheduleTask: {},
    //   })
    // }else {
      this.setState({
        selectedScheduleTask: item
      })
    // }

  }

  showScheduleTaskList(event: any) {
    const { showScheduledTaskList } = this.state;
    this.props.TaskActions.hideInstantTask();
    this.setState({
      showScheduledTaskList: !showScheduledTaskList
    })
  }

  public render() {
    const { showScheduledTaskList, selectedScheduleTask } = this.state;
    const { showInstantTask, showScheduledTask} = this.props.TaskReducers;
    const { workflowList } = this.props.WorkflowReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-md-12 col-lg-3 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:createNewtask.taskType")}</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button type="button" className={showInstantTask ? "btn btn-lg bg-light btn-nb-gradient-primary btn-block" : "btn btn-lg bg-light btn-hover-gradient btn-block"}
                        onClick={(e) => this.clickInstantTask(e)}
                      >{t("screens:createNewtask.instantTask")}</button>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button type="button" className={showScheduledTaskList ? "btn btn-lg bg-light btn-nb-gradient-primary btn-block" : "btn btn-lg bg-light btn-hover-gradient btn-block"}
                        onClick={(e) => this.showScheduleTaskList(e)}
                      >{t("screens:createNewtask.scheduledTask")}</button>
                      <ul className="nav flex-column shadow-sm dropdown-btn-list">
                        {showScheduledTaskList &&
                          !_.isEmpty(workflowList) &&
                          workflowList.map((element: any, index: any) => {
                            return (
                              <li className={element.id === selectedScheduleTask.id ?
                                "nav-item btn-nb-purple" : "nav-item"
                              }>
                                <a className="nav-link"
                                  onClick={(e) => this.createScheduleTask(e, element)}
                                >
                                  {element.workflowName}
                                </a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showInstantTask ?
            <InstantTask {...this.props} />
            : showScheduledTask ?
              <ScheduleTask {...this.props} /> :
              <div className="col-md-12 col-lg-9 mb-30">
                <div className="card nb-card h-100">
                  <div className="card-header bg-white border-bottom-0">
                    <h1 className="nb-title">{t("screens:createNewtask.addTaskWorkflow")}</h1>
                  </div>
                  <div className="card-body p-5">
                    <div className="row">
                      <div className="col-sm-12 mb-5 text-center">
                        <img className="img img-fluid" src={images.illustrationConfig} alt="Config"/>
                      </div>
                      <div className="col-sm-12">
                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill"
                          onClick={this.clickInstantTask}
                        >{t("screens:createNewtask.createInstantTask")}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            // <ScheduleTask />
          }
        </div>
      </>
    );
  }
}

