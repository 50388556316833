import { loginMonitoringConst } from "../../types";
import { tHeadLoginMonitoringList } from "../constants";


const initialState = {
  loginMonitoringList: [],
  totalLoginMonitoring: 0,
  selectedLoginMonitoringRecord:{}
}

export function LoginMonitoringListReducer(state = initialState, action: any) {
  switch (action.type) {
    case loginMonitoringConst.GET_LOGIN_MONITORING_LIST:
      return Object.assign({}, state, {
        loginMonitoringList: action.tempArray,
        totalLoginMonitoring: action.payload.data.data.totalItems,
        selectedLoginMonitoringRecord: action.tempArray[0]
      })
    default:
      return state;
  }
}