import { t } from "i18next"
import _ from "lodash"
import { SchemaDateSuffix } from "src/View/Schema/models/schema.enum";
import { displayDateFormat } from "src/utils/date.utils";

export const CredentialOfferDetails = ({ selectedCredentialRecord }: any) => {

    console.log('selectedCredentialRecord', JSON.stringify(JSON.parse(selectedCredentialRecord.credentialProposalDict)))

    const attributes = !_.isEmpty(selectedCredentialRecord) && JSON.parse(selectedCredentialRecord.credentialProposalDict).credential_proposal.attributes;
    console.log('attributes', attributes)

    return (
        <>
            {/* <div className="card nb-card h-100"> */}
            <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:credentialOffer.credentialOfferDetails")}</h1>
            </div>
            {!_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">
                        <div className="mb-5">
                            <ul className="nb-list">
                                <li className="pl-1 pr-0">
                                    <div>
                                        <label className="font-weight-bold">{t("screens:credentialOffer.credentialName")}: </label>
                                        <label className="font-weight-bold">{!_.isEmpty(selectedCredentialRecord)
                                            && selectedCredentialRecord?.credentialDefinitionId?.split(':')[4]} </label>
                                    </div>
                                </li>
                                <li className="pl-1 pr-0">
                                    <div>
                                        <label className="font-weight-bold">{t("screens:credentialOffer.givenName")}: </label>
                                        <label className="font-weight-bold">{!_.isEmpty(selectedCredentialRecord)
                                            && selectedCredentialRecord.connectionName}</label>
                                    </div>
                                </li>
                                <li className="pl-1 pr-0 ">
                                    <>
                                        <label className="font-weight-bold">{t("screens:credentialOffer.credentialValues")}: </label>
                                        <ul>
                                            {selectedCredentialRecord && attributes.map((item: any, index: any) => {
                                                if (!item.hasOwnProperty("mime-type")) {
                                                    return <li key={index} className="remove-underline"><label className="font-weight-bold">{item.name}: { (item.name).includes(SchemaDateSuffix.DATE_SUFFIX) 
                                                        ? displayDateFormat(item.value)
                                                        : item.value}</label></li>
                                                } else {
                                                    return <>
                                                        <li key={index} className="remove-underline">
                                                            <label className="font-weight-bold">{item.name}: <img src={item.value} alt={item.name} className="preview" height="50"></img></label>
                                                        </li>
                                                    </>
                                                }
                                            })}
                                        </ul>
                                    </>
                                </li>
                            </ul>
                        </div>
                        <>
                            {!_.isEmpty(selectedCredentialRecord) &&
                                <>
                                    {/* <button type="button" className="btn btn-nb-outline-primary rounded-pill" onClick={() => this.acceptCredential(selectedCredentialDetails.credentialExchangeId)}>
                                   {t("screens:credentialOffer.deleteCredential")}
                                </button> */}
                                </>
                            }
                        </>
                    </div>

                </>
            }
            {_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-header bg-white border-bottom-0">
                        <h1 className="nb-title">{t("screens:credentialOffer.credentialDetails")}</h1>
                    </div>
                    <div className="card-body">
                        <div className="blank-credential-details-body">
                            <label className="">{t("screens:credentialOffer.noRequestReceived")}</label>
                        </div>
                    </div>
                </>
            }
            {/* </div> */}
        </>
    )
}
