import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { credentialAction } from '../actions/credentialAction';
import { schemaAction } from '../../Schema/actions/schemaAction';
import _ from 'lodash';
import { CredentialDetail } from '../components/CredentialDetail';
import PADefaultCredDef from '../components/PADefaultCredDef';

export interface ICredentialProps {
  credentialAction: any,
  schemaAction: any,
  CredentialReducer: any,
  SchemaReducer: any,
}
export interface ICredentialState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  CredentialSortBy: string,
  hideClose: boolean,
  supportRevocation:any,
}

class PACredentialComponent extends React.Component<ICredentialProps, ICredentialState> {
  constructor(props: ICredentialProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
      hideClose: true,
      supportRevocation: '',
    }
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, CredentialSortBy, supportRevocation } = this.state;

    this.props.credentialAction.getPADefaultCredential(defaultSearchFields, supportRevocation, sortingOrder, CredentialSortBy, item_perPage, pageNo,);

    this.props.schemaAction.getSchemaList();
  }

  public render() {
    const {
      modalShow,
    } = this.props.CredentialReducer;
    return (
      <>
        <div className="row animated fadeIn">
          <div className="col-lg-12 col-xl-5">
            <div className="card nb-card-noborder">
              <div className="card-header bg-white">
                <div className="card-title m-0">
                  <h1>Default Credentials</h1>
                </div>
              </div>
              <div className="card-body">
                <PADefaultCredDef {...this.props} />
              </div>
            </div>
          </div>
          {
            <div className="col-lg-12 col-xl-7">
              <CredentialDetail hideClose={this.state.hideClose} {...this.props} />
            </div>
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const CredentialReducer = state.CredentialReducer;
  const LoaderReducer = state.LoaderReducer
  const SchemaReducer = state.SchemaReducer;
  return {
    CredentialReducer,
    LoaderReducer,
    SchemaReducer,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    credentialAction: bindActionCreators(credentialAction, dispatch),
    schemaAction: bindActionCreators(schemaAction, dispatch),
  }
}

const connectedCredentialComponent = connect(mapStateToProps, mapDispatchToProps)(PACredentialComponent);
export { connectedCredentialComponent as PACredentialComponent };
