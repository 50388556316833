import { userConstants } from "../../types";


// debugger
const initialState = {
  schemaGet: true,
  schema: [],
  selectedSchema: {},
  selectedSchemaDatail: {},
  modalShow: false,
  fromPage: 'schema',
  totalSchemas: 0,
  schemaList: [],
  tHeadSchema: [],
  schemaLedgerId: '',
  selectedDefaultSchema: {},
  defaultSchema: [],
  totalDefaultSchemas: 0,
  showDetailsofDefaultSchema: false,
  newSchema:{},
}

export function SchemaReducer(state = initialState, action: any) {
  let tHeadSchema = [{
    'name': 'Schema Name',
    'label': "schemaName"
  }, {
    'name': 'Joined Date/Time',
    'label': "createDateTime"
  }, {
    'name': 'Details',
    'label': "details"
  }];
  // debugger
  switch (action.type) {
    case userConstants.LOADING:
      return Object.assign({}, state, { loading: true });
    case userConstants.GETALL_REQUEST:
      return Object.assign({}, state, { schemaGet: true });
    case userConstants.GETALL_SUCCESS_SCHEMA:
      return Object.assign({}, state, {
        schema: action.payload.data.data.data,
        tHeadSchema: tHeadSchema,
        hasNextPage: action.payload.data.data.hasNextPage,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        previousPage: action.payload.data.data.previousPage,
        totalSchemas: action.payload.data.data.totalItems,
        selectedSchemaDatail: {},
        modalShow: false,
        fromPage: 'schema'
      });
    case userConstants.GETALL_FAILURE:
      return Object.assign({},
        state,
        {
          error: action.error
        });
    case userConstants.CREATE_SCHEMA:
      return Object.assign({},
        state,
        {
          newSchema: action.payload,
          schemaLedgerId: action.payload.data.data.schemaLedgerId,
        });
    case userConstants.CREATE_SCHEMA_DEF:
      return Object.assign({},
        state,
        {
          newSchema: action.payload,
          schemaLedgerId: action.payload.data.data.schemaLedgerId,
        });
    case userConstants.CREATE_SCHEMA_FAILURE:
      return Object.assign({},
        state,
        {
          newSchema:{},
          schemaLedgerId:'',
          error: action.error
        });
    case userConstants.CREATE_SCHEMA_DEF_FAILURE:
      return Object.assign({},
        state,
        {
          newSchema:{},
          schemaLedgerId:'',
          error: action.error
        });
    case userConstants.GET_SCHEMA_BY_ID:
      return Object.assign({},
        state,
        {
          selectedSchema: action.payload.data.data
        })
    case userConstants.SELECTED_SCHEMA:
      return Object.assign({},
        state,
        {
          selectedSchemaDatail: action.payload.item,
          modalShow: true,
          fromPage: 'dashboard'
        });
    case userConstants.SHOW_SCHEMA_FROM:
      return Object.assign({},
        state,
        {
          modalShow: false,
          selectedSchemaDatail: {}
        });
    case userConstants.CLEAR_SCHEMA:
      return Object.assign({},
        state,
        {
          selectedSchema: {}
        });
    case userConstants.GET_SCHEMA_LIST:
      return Object.assign({},
        state,
        {
          schemaList: action.payload.data.data
        });
    case userConstants.GET_EXTERNAL_ORGANIZATION_LIST:
      return Object.assign({},
        state,
        {
          externalOrganizationList: action.payload.data.data
        });
    case userConstants.CLEAR_SELECTED_SCHEMA:
      return Object.assign({},
        state,
        {
          schemaLedgerId: ''
        });
    case userConstants.SELECTED_SCHEMA_LEDGER:
      return Object.assign({},
        state,
        {
          schemaLedgerId: action.payload.schemaLedgerId
        });
    case userConstants.GET_DEFAULT_SCHEMA:
      return Object.assign({},
        state,
        {
          defaultSchema: action.payload.data.data.data,
          tHeadSchema: tHeadSchema,
          hasNextPage: action.payload.data.data.hasNextPage,
          hasPreviousPage: action.payload.data.data.hasPreviousPage,
          lastPage: action.payload.data.data.lastPage,
          nextPage: action.payload.data.data.nextPage,
          previousPage: action.payload.data.data.previousPage,
          totalDefaultSchemas: action.payload.data.data.totalItems,
          selectedDefaultSchema: action.payload.data.data.data[0],
        });
    case userConstants.SELECTED_DEFAULT_SCHEMA:
      return Object.assign({},
        state,
        {
          selectedDefaultSchema: action.payload,
          showDetailsofDefaultSchema: true,
        });
    default:
      return state
  }
}

