import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { chatsAction } from "../action/chatsAction";
import { itemPerPageForChatsMessages } from "../chatsConstants";
import ChatMessages from "../components/ChatMessages";
import ChatsConnectionList from "../components/ChatsConnectionList";
import IssueClaimCredentials from "../components/IssueClaimCredentials";
import { t } from 'i18next';

interface IChatsProps {
    chatsAction: any,
    ChatsReducer: any
}

interface IChatsState {
    searchText: string,
    itemPerPage: number,
    page: number,
    filterValue: string,
    showClaimCredential: boolean,
    claimCredentialPageNo: number,
    claimCredentialItemsPerPage: number,
    claimCredentialSearchText: string,
    itemPerPageForMessages: number

}
class Chats extends React.Component<IChatsProps, IChatsState> {
    constructor(props: IChatsProps) {
        super(props)
        this.state = {
            searchText: '',
            itemPerPage: 10,
            page: 1,
            filterValue: '',
            showClaimCredential: false,
            claimCredentialPageNo: 1,
            claimCredentialItemsPerPage: 0,
            claimCredentialSearchText: '',
            itemPerPageForMessages: itemPerPageForChatsMessages
        }
        // Bind all the methods.
        this.onChangePage = this.onChangePage.bind(this)
        this.showDetails = this.showDetails.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.sendBasicMessage = this.sendBasicMessage.bind(this)
        this.refresh = this.refresh.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.onShowClaimCredential = this.onShowClaimCredential.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    /**
    * Method used to change the page no value in pagination
    * @param page 
    */
    onChangePage(page: any) {
        const { searchText, itemPerPage, filterValue } = this.state
        this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, filterValue)
    }

    /**
     * Method used to shoe the specific connection messages.
     * @param item 
     */
    showDetails(item: object | any) {
        this.setState({ itemPerPageForMessages: itemPerPageForChatsMessages })
        this.props.chatsAction.onSelectNewConnection(item)
    }

    /**
     * Method used to search record from database based on entered text.
     * @param searchText 
     */
    onSearch(searchText: string) {
        const { itemPerPage, filterValue, page } = this.state
        this.setState({
            searchText
        })
        this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, filterValue)
    }

    /**
     * Method used to change the show claim credential flag.
     */
    onShowClaimCredential() {
        this.setState({ showClaimCredential: !this.state.showClaimCredential })
    }

    /**
     * Method used to get selected filter value from dropdown
     * @param selectedFilterValue 
     */
    onFilter(selectedFilterValue: string) {
        const { searchText, itemPerPage, page } = this.state

        switch (selectedFilterValue) {
            case 'all':
                this.setState({ filterValue: selectedFilterValue })
                this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, selectedFilterValue)
                break;
            case 'employee':
                this.setState({ filterValue: selectedFilterValue })
                this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, selectedFilterValue)
                break;
            case 'client':
                this.setState({ filterValue: selectedFilterValue })
                this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, selectedFilterValue)
                break;
            default:
                this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, selectedFilterValue)
                break;
        }
    }

    /**
     * Method used to send the message to selected connection.
     * @param basicMessage 
     */
    sendBasicMessage(basicMessage: string) {
        const { selectedChatConnection, chatsConnectionList } = this.props.ChatsReducer
        const sendMessageData = {
            content: basicMessage
        }
        this.props.chatsAction.sendBasicMessage(selectedChatConnection, sendMessageData, chatsConnectionList)
    }

    /**
     * Method used to refresh the connection list
     */
    refresh() {
        this.setState({
            searchText: '',
            itemPerPage: 10,
            page: 1,
            filterValue: '',
        })
        this.props.chatsAction.getAllChatsConnectionList('', 10, 1, '')
    }

    componentDidMount() {
        const { searchText, itemPerPage, page, filterValue } = this.state
        this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, filterValue)
        // SOCKET DISABLE FIREBASE
        // navigator.serviceWorker.addEventListener("message", (message) => {
        //     const { type } = message.data.firebaseMessaging.payload.data
        //     if (type === "BASIC_MESSAGE") {
        //         this.props.chatsAction.getAllChatsConnectionList(searchText, itemPerPage, page, filterValue)
        //     }
        // })
    }

    /**
     * Method used to handle scroll for showing the messages.
     */
    handleScroll() {
        const { selectedChatConnection } = this.props.ChatsReducer
        const { itemPerPageForMessages } = this.state
        this.props.chatsAction.getMessagesByConnectionId(selectedChatConnection.organization.id, selectedChatConnection.connectionId, itemPerPageForMessages > 10 ? itemPerPageForMessages + 10 : itemPerPageForMessages)
        this.setState({ itemPerPageForMessages: itemPerPageForMessages + 10 })
    }

    componentWillUnmount() {
        this.props.chatsAction.unmountFromChatDetails()
        localStorage.setItem('isUnmountChats', "true")
    }

    public render() {
        const { selectedChatConnection, chatsConnectionList, totalConnections, isNewMessageReceived,
            availableCredentialList, receivedCredentialCount, isReadyToShowSelectedClaimCredential, selectedClaimCredential, availableCredentialCount, messageRecords } = this.props.ChatsReducer
        const { chatsAction } = this.props
        const { showClaimCredential, claimCredentialPageNo, claimCredentialItemsPerPage, claimCredentialSearchText } = this.state
        return (
            <>
                <div className="row gutters-sm min-h-100">

                    {/* Display Connection List */}
                    <div className="col-md-12 col-lg-6 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:chat.connectionList")}</h1>
                            </div>
                            <div className="card-body">
                                <ChatsConnectionList
                                    selectedChatConnection={selectedChatConnection}
                                    onChangePage={this.onChangePage}
                                    chatsConnectionList={chatsConnectionList}
                                    totalConnections={totalConnections}
                                    showDetails={this.showDetails}
                                    onSearch={this.onSearch}
                                    chatsAction={chatsAction}
                                    refresh={this.refresh}
                                    onFilter={this.onFilter}
                                    isNewMessageReceived={isNewMessageReceived}
                                />
                            </div>
                        </div>
                    </div>
                    {!showClaimCredential &&
                        <ChatMessages
                            selectedChatConnection={selectedChatConnection}
                            sendBasicMessage={this.sendBasicMessage}
                            chatsConnectionList={chatsConnectionList}
                            chatsAction={chatsAction}
                            onShowClaimCredential={this.onShowClaimCredential}
                            messageRecords={messageRecords}
                            handleScroll={this.handleScroll}
                        />
                    }
                    {showClaimCredential &&

                        <IssueClaimCredentials
                            onShowClaimCredential={this.onShowClaimCredential}
                            selectedChatConnection={selectedChatConnection}
                            chatsAction={chatsAction}
                            availableCredentialList={availableCredentialList}
                            receivedCredentialCount={receivedCredentialCount}
                            isReadyToShowSelectedClaimCredential={isReadyToShowSelectedClaimCredential}
                            selectedClaimCredential={selectedClaimCredential}
                            availableCredentialCount={availableCredentialCount}
                            claimCredentialPageNo={claimCredentialPageNo}
                            claimCredentialItemsPerPage={claimCredentialItemsPerPage}
                            claimCredentialSearchText={claimCredentialSearchText}
                        />
                    }
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const ChatsReducer = state.ChatsReducer;
    return {
        ChatsReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        chatsAction: bindActionCreators(chatsAction, dispatch),
    }
}

const chats = connect(mapStateToProps, mapDispatchToProps)(Chats);
export { chats as Chats };