import * as React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';

// Utilities
import images from "src/utils/images";

export interface IRemoveOrganizationPermissionProps {
    isRemoveOrganization: boolean
    onCloseRemovePermissionModal: Function | any
    selectedOrganizationForRemove: any,
    onRemoveOrganization: Function | any
}

export interface IRemoveOrganizationPermissionState {
}


export default class RemoveOrganizationPermission extends React.Component<IRemoveOrganizationPermissionProps, IRemoveOrganizationPermissionState> {
    constructor(props: IRemoveOrganizationPermissionProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        const { isRemoveOrganization, onCloseRemovePermissionModal, onRemoveOrganization, selectedOrganizationForRemove } = this.props
        return (
            <Modal isOpen={isRemoveOrganization} className="nb-modal animated slideInRight"
                toggle={onCloseRemovePermissionModal}
            >
                <ModalBody>
                    <div className="text-center w-100">
                        <img src={images.companyLogo} className="mb-3" width="250" alt="Company logo" />
                        <div className="mb-3">
                            <h6>Remove Organization</h6>
                        </div>
                    </div>
                    <div className='text-center'>
                        <label className="nb-label">Are you sure you! You want to remove <b className='text-danger'>{selectedOrganizationForRemove.orgName}</b></label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-secondary rounded-pill"
                        onClick={onCloseRemovePermissionModal}
                    >CANCEL</button>
                    <button type="button" className="btn btn-danger rounded-pill" data-dismiss="modal"
                        onClick={onRemoveOrganization}
                    >REMOVE</button>
                </ModalFooter>

            </Modal>
        );
    }
}

function mapStateToProps() {
    return {}
}

function mapDispatchToProps() {
    return {
    }
}

const connectedRemoveOrganizationPermission = connect(mapStateToProps, mapDispatchToProps)(RemoveOrganizationPermission);
export { connectedRemoveOrganizationPermission as RemoveOrganizationPermission };