import { myCredential } from "../../types";
import { t } from 'i18next';

const initialState = {
    selectedCredentialDetails: {},
    credentialOfferReqData: [],
    totalCredentialOfferReq: 0,
    showSuccessPage: false,
    tHead: [],
    isOfferAccepted: false,
    credentialProblemReportResponse: {},
    isProblemReported: false
}

export function MyCredentialsReducer(state = initialState, action: any) {
    /* Accept credential list table column header name list */
    let tHead = [{
        'name': `${t("screens:myCredentials.headDate")}`,
        'label': "createDateTime"
    }, {
        'name': `${t("screens:myCredentials.headGivenName")}`,
        'label': "connectionName"
    }, {
        'name': `${t("screens:myCredentials.headCredentialName")}`,
        'label': "credentialDefinitionId"
    }, {
        'name': `${t("screens:myCredentials.headStatus")}`,
        'label': "state"
    }, {
        'name': ``,
        'label': "details"
    }];
    switch (action.type) {
        /* get all credential offer list */
        case myCredential.GET_ALL_CREDENTIAL_OFFER:
            return Object.assign({}, state, {
                credentialOfferReqData: action.payload.data,
                totalCredentialOfferReq: action.payload.totalItems,
                tHead
            });
        /* Store selected credential details */
        case myCredential.SHOW_SELECTED_CREDENTIAL_DETAILS:
            return Object.assign({}, state, {
                selectedCredentialDetails: action.payload,
                showSuccessPage: false,
                isOfferAccepted: false,
                isProblemReported: false
            });
        /* Accept credential offer */
        case myCredential.ACCEPT_CREDENTIAL_OFFER:
            return Object.assign({}, state, {
                acceptCredentialResponse: action.payload,
                showSuccessPage: true,
                isOfferAccepted: true,
                isProblemReported: false
            })

            case myCredential.CREDENTIAL_PROBLEM_REPORTED:
                return Object.assign({}, state, {
                    credentialProblemReportResponse: action.payload,
                    showSuccessPage: true,
                    isProblemReported: true
                })
    
        case myCredential.RE_PROPOSE_CREDENTIAL:
            return Object.assign({}, state, {
                selectedCredentialDetails: action.payload,
                showSuccessPage: false
            });
        default:
            return state;
    }
}
