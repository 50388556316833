import axios from "axios";
import { t } from "i18next";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { config } from "src/config";
import { ListScreenOptions } from "src/types/common.interface";
import { loginAction } from "src/View/Login/actions/loginAction";
import { loaderConst, presentationNegotiateConstants } from "src/View/types";
import { history } from '../../../index'

export const presentationNegotiateAction = {

    getAllPresentationRequestsForNegotiate(listScreenOptions: ListScreenOptions) {

        return async (dispatch: any) => {
            try {
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Get authentication token from local storage */
                let token = localStorage.getItem('token');
                const params = {
                    page: listScreenOptions.pageNo,
                    items_per_page: listScreenOptions.itemsPerPage,
                    ...(listScreenOptions.search && { search_text: listScreenOptions.search }),
                    ...(listScreenOptions.sort && { present_proof_sort: listScreenOptions.sort }),
                    ...(listScreenOptions.sortBy && { sort_value: listScreenOptions.sortBy }),
                    ...(listScreenOptions.filter && { filter_text: listScreenOptions.filter }),
                }
                /* API call to get proof request records and dispatch the get all present proof request list */
                return await axios.get(`${config.apiUrl}/verifier/proof-requests`,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        },
                        params
                    })
                    .then(getAllPresentationList => {
                        console.log()
                        /* Add the connection name in response */
                        // getAllProposalList.data.data.data.map((element: any) => {
                        let tmpArray: any = [];
                        tmpArray = !_.isEmpty(getAllPresentationList) && getAllPresentationList.data && getAllPresentationList.data.data && getAllPresentationList.data.data.data;
                        tmpArray.map((ele: any, index: any) => {
                            tmpArray[index].proofState = ele.state;
                            tmpArray[index].state = `${t(`screens:verificationMonitoring.${ele.state.toLowerCase()}`)}`;
                            // out-of-band
                            if (ele.connectionId) {
                                tmpArray[index].connectionName = ele.connection?.theirLabel;
                            } else {
                                tmpArray[index].connectionName = ""
                            }
                            // out-of-band
                            // if (element.connectionId) {
                            //     return (element['connectionName'] = element.connections?.theirLabel)
                            // } else {
                            //     return (element['connectionName'] = "")
                            // }
                            // })
                        })
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentationNegotiateConstants.GET_ALL_PRESENTATION_LIST,
                            payload: getAllPresentationList,
                            tmpArray,
                            selectedPresentationRecord: (getAllPresentationList.data.data.data).length > 0 ? (getAllPresentationList.data.data.data)[0] : {}
                        })
                        if ((getAllPresentationList.data.data.data).length > 0) {
                            dispatch({
                                type: presentationNegotiateConstants.SELECTED_NEGOTIATE_RECORD,
                                payload: (getAllPresentationList.data.data.data)[0]
                            })
                            
                        }
                    }).catch((error: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch)
                    })

            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    sendProblemReportForPresentationExchange(reason: string, presentationExchangeId: string) {
        return async (dispatch: any) => {

            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Payload to accept credential offer */
                const payload = {
                    reason,
                    presentationExchangeId
                }
                /* API call for accepting credential offer and dispatch the accept credential offer action */
                return await axios.post(`${config.apiUrl}/verifier/present-proof/problem-report`, payload,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(async (problemReportResponse) => {

                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })

                        dispatch({
                            type: presentationNegotiateConstants.DECLINED_PRESENTATION_REQUEST,
                        })

                        if (problemReportResponse.data) {
                            const getAllListAPIParameter = {
                                itemsPerPage: 10,
                                pageNo: 1
                            }
                            this.getAllPresentationRequestsForNegotiate(getAllListAPIParameter)
                        }
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    fetchCredentialsForPresentationRequest(presentationExchangeId: string) {
        return async (dispatch: any) => {
            try {
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Get authentication token from local storage */
                let token = localStorage.getItem('token');

                return await axios.get(`${config.apiUrl}/verifier/records/${presentationExchangeId}/stored-credentials`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(credentialsForPresentation => {
                        console.log('\ncredentialsForPresentation\n', credentialsForPresentation.data)
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentationNegotiateConstants.FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST,
                            payload: credentialsForPresentation.data
                        })
                        return credentialsForPresentation.data
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        const getAllListAPIParameter = {
                            itemsPerPage: 10,
                            pageNo: 1
                        }
                        this.getAllPresentationRequestsForNegotiate(getAllListAPIParameter)
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch)
                        throw error
                    })
            } catch (error) {
                throw error
            }
        }
    },

    sendPresentation(proofPresentation: any) {

        return async (dispatch: any) => {
            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                return await axios.post(`${config.apiUrl}/verifier/send-presentation`, proofPresentation, { headers: { "Authorization": `Bearer ${token}` } })
                    .then((proofRequestResponse: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentationNegotiateConstants.SEND_PROOF_REQUEST,
                            payload: proofRequestResponse.data.data.data
                        })

                        const getAllListAPIParameter = {
                            itemsPerPage: 10,
                            pageNo: 1,
                        }
                        this.getAllPresentationRequestsForNegotiate(getAllListAPIParameter)
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                throw error;
            }
        }
    },

    onCloseSuccess() {
        return async (dispatch: any) => {
            try {
                return (
                    dispatch({
                        type: presentationNegotiateConstants.CLOSE_SUCCESS,
                    })
                )

            } catch (error) {
                throw error
            }
        }
    },

    verifyProof(presentationExchangeId: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.post(`${config.apiUrl}/verifier/present-proof/records/verify-presentation`, presentationExchangeId,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        dispatch({
                            type: presentationNegotiateConstants.PRESENT_PROOF_VERIFIED,
                            payload: response,
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })

                        const getAllListAPIParameter = {
                            itemsPerPage: 10,
                            pageNo: 1,
                        }
                        this.getAllPresentationRequestsForNegotiate(getAllListAPIParameter)

                        // toastr.success(`${response.data.message}`, ``)
                        toastr.success(`${t("screens:verificationMonitoring.messagePresentProofSuc")}`, ``)
                    })
                    .catch(error => {
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        loginAction.handleResponse(error.response, dispatch);
                        throw error;
                    })
            } catch (error) {
                throw error;
            }
        }
    },

    showSelectedCredentialDetails(item: any) {
        return async (dispatch: any) => {
            dispatch({
                type: presentationNegotiateConstants.SELECTED_NEGOTIATE_RECORD,
                payload: item
            })
        }
    },

    reProposeProofRequest(selectedProofDetails: any, connectionDetails: any) {
        return async (dispatch: any) => {
            try {
                history.push("/credential-verification/negotiate", { selectedProofDetails, connectionDetails });
                return (
                    /* This dispatches method dispatch the stored credential details. */
                    dispatch({
                        type: presentationNegotiateConstants.RE_PROPOSE_PROOF_REQUEST,
                        payload: { selectedProofDetails, connectionDetails }
                    })
                )
            } catch (error) {
                console.error('Error re prop')
                /* Handle the error and throw it */
                throw error
            }
        }
    }
}