import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { chats, loaderConst } from "../../types";
import _ from "lodash";
import { itemPerPageForChatsMessages } from "../chatsConstants";

interface SendBasicMessage {
    content: string
}
export const chatsAction = {

    /**
     * Method to get all connection list for chats
     * @param searchText 
     * @param itemPerPage 
     * @param page 
     * @param filterValue 
     * @returns It return the connection list
     */
    getAllChatsConnectionList(
        searchText: string,
        itemPerPage: number,
        page: number,
        filterValue: string
    ) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');

                return await axios.get(`${config.apiUrl}/basic-message/get-all-connection-with-chat-messages/by-orgID?search_text=${searchText}&itemsPerPage=${itemPerPage}&page=${page}&filterType=${filterValue}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then((connectionData) => {
                        let tempArray: any = [];
                        tempArray = connectionData.data.data.data;
                        tempArray.map((item: any, index: any) => {
                            console.log("-----", item.basicMessages.filter((element: any) => element.isMessageRead === false).length)
                            tempArray[index].holderType = !_.isEmpty(item.holders) && item.holders.type;
                            tempArray[index].holderName = !_.isEmpty(item.holders) ? item.holders.name : item.theirLabel;
                            tempArray[index].employeeId = !_.isEmpty(item.holders) && item.holders.id;
                            tempArray[index].totalMessageReceived = !_.isEmpty(item.basicMessages) && item.basicMessages.filter((element: any) => element.isMessageRead === false).length > 0 ?
                                item.basicMessages.filter((element: any) => element.isMessageRead === false).length : "--";
                            tempArray[index].lastReceivedMessageDate = (item.basicMessages.filter((element: any) => element.content !== '')).splice(-1).length > 0 ?
                                item.basicMessages.splice(-1)[0].lastChangedDateTime : "--"
                        })
                        /* API call for updating the unread message status */
                        if (tempArray.length > 0) {
                        this.updateUnreadMessageStatus(tempArray[0].connectionId, tempArray)
                        /* API call for get first connection record messages */
                        this.getMessagesByConnectionId(tempArray[0].organization.id, tempArray[0].connectionId, itemPerPageForChatsMessages)
                        }
                        dispatch({
                            type: chats.CHATS_CONNECTION_LIST,
                            payload: tempArray,
                            totalConnections: connectionData.data.data.totalItems,
                            fromPage: connectionData.data.data.page,
                            hasPreviousPage: connectionData.data.data.hasPreviousPage,
                            hasNextPage: connectionData.data.data.hasNextPage,
                            lastPage: connectionData.data.data.lastPage,
                            nextPage: connectionData.data.data.nextPage,
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE,
                        })

                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })


            } catch (error) {
                console.error('Error in get all connection list action', error)
                throw error
            }
        }
    },

    /**
     * Method used to store the selected connection record.
     * @param selectedNewConnection 
     * @returns It return the selected connection record details.
     */
    onSelectNewConnection(selectedNewConnection: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: chats.SELECT_NEW_CONNECTION,
                    payload: selectedNewConnection,
                })

            } catch (error) {
                console.log('Error in select new connection:::', error)
                throw error
            }
        }
    },

    /**
     * The method used to send the Basic message to the selected connection.
     * @param selectedConnection 
     * @param sendBasicMessage 
     * @param chatsConnectionList 
     * @returns It return the response of send basic message API.
     */
    sendBasicMessage(selectedConnection: any, sendBasicMessage: SendBasicMessage, chatsConnectionList: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.post(`${config.apiUrl}/basic-message/connections/${selectedConnection.connectionId}/send-message`, sendBasicMessage,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(sendBasicMessageResponse => {
                        chatsConnectionList.map((item: any, index: number) => {
                            if (item.connectionId === sendBasicMessageResponse.data.data.connectionId) {
                                chatsConnectionList[index].basicMessages.unshift(sendBasicMessageResponse.data.data)
                            }
                        })
                        this.getMessagesByConnectionId(selectedConnection.organization.id, selectedConnection.connectionId, itemPerPageForChatsMessages)
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: chats.CHATS_SEND_BASIC_MESSAGE,
                            payload: sendBasicMessageResponse,
                            chatsConnectionList
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })

            } catch (error) {
                console.error(`Error in sending the basic message:::${JSON.stringify(error)}`)
                throw error
            }
        }
    },

    /**
     * Method used to update the status of unread messages.
     * @param connectionId 
     * @param chatsConnectionList 
     * @returns It return the updated chat connection list
     */
    updateUnreadMessageStatus(connectionId: string, chatsConnectionList: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.put(`${config.apiUrl}/basic-message/update-unread-message-status/${connectionId}`, '',
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then((updatedRecords) => {
                        chatsConnectionList.map((item: any, index: any) => {
                            if (item.connectionId === connectionId) {
                                chatsConnectionList[index].totalMessageReceived = "--";
                            }
                        })
                        dispatch({
                            type: chats.UPDATE_UNREAD_MESSAGE_STATUS,
                            payload: updatedRecords,
                            chatsConnectionList: chatsConnectionList
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })

                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })

            } catch (error) {
                console.error('Error in updating the unread message status', error);
                throw error
            }
        }
    },

    /**
     * The method used on after receiving the notification, update the unread message status and get updated message list.
     * @param chatsConnectionList 
     * @param messageDetails 
     * @param selectedChatConnection 
     * @returns 
     */
    onReceivedNotification(chatsConnectionList: any, messageDetails: any, selectedChatConnection: any) {
        return async (dispatch: any) => {
            try {
                // API call for update unread message status.
                this.updateUnreadMessageStatus(messageDetails.connectionId, chatsConnectionList)
                // API call to get updated messages for selected connection.
                this.getMessagesByConnectionId(selectedChatConnection.organization.id, selectedChatConnection.connectionId, itemPerPageForChatsMessages)

            } catch (error) {
                console.error('Error in update received message in list:::', error)
                throw error;
            }
        }
    },

    /**
     * Method used to remove store values.
     * @returns 
     */
    unmountFromChatDetails() {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: chats.UNMOUNT_FROM_CHATS_DETAILS,
                    chatsConnectionList: {},
                    selectedChatConnection: {}
                })

            } catch (error) {
                console.log('Error in remove unmount from chat details:::', error)
                throw error
            }
        }
    },

    /**
     * Method used on after receive the notification to set flag for highlight the refresh button
     * @param selectedChatConnection 
     * @returns 
     */
    highlightNotificationReceived(selectedChatConnection: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: chats.HIGHLIGHT_NOTIFICATION_STATUS,
                    payload: selectedChatConnection
                })

            } catch (error) {
                console.log("Error in highlight the refresh button action")
                throw error
            }
        }
    },

    /**
     * Method used to get all claim credential list for specific connection
     * @param connectionId 
     * @param pageNo 
     * @param itemsPerPage 
     * @param searchText 
     * @returns 
     */
    getAllClaimCredentialsDetails(connectionId: string, pageNo: number, itemsPerPage: number, searchText: string) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.get(`${config.apiUrl}/claim-credentials/connection/${connectionId}?page:${pageNo}&itemsPerPage:${itemsPerPage}&search_text:${searchText}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(claimCredentials => {
                        let availableCredentialCount = 0;
                        let receivedCredentialCount = 0;
                        claimCredentials.data.data.data.forEach((element: any) => {
                            if (element &&
                                element.credentialExchangeId &&
                                (element.credentialExchangeId.state === 'credential_acked' || element.credentialExchangeId.state === 'credential_issued')) {
                                receivedCredentialCount++
                            } else if (element.credentialExchangeId === null) {
                                availableCredentialCount++
                            }
                        })
                        dispatch({
                            type: chats.IMPORT_CLAIM_CREDENTIAL_LIST,
                            payload: claimCredentials.data.data,
                            availableCredentialCount,
                            receivedCredentialCount
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                console.error('Error in get all credential details:::', error)
                throw error
            }
        }
    },

    /**
     * Method used to maintain the flag for showing the credential details
     * @param selectedClaimCredential 
     * @returns 
     */
    showSelectedClaimCredentialDetails(selectedClaimCredential: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: chats.SELECTED_CLAIM_CREDENTIAL_DETAILS,
                    payload: selectedClaimCredential
                })

            } catch (error) {
                console.error('Error in show available credential', error)
                throw error
            }
        }
    },

    /**
     * Method used to issue claim credential to selected connection.
     * @param claimId 
     * @returns 
     */
    issueClaimCredential(claimId: number) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.post(`${config.apiUrl}/issue-credential/claim-credential/${claimId}`, "",
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(issueClaimCredential => {
                        const connectionId = issueClaimCredential.data.data.connection_id
                        this.getAllClaimCredentialsDetails(connectionId, 1, 0, '')
                        dispatch({
                            type: chats.ISSUE_AVAILABLE_CLAIM_CREDENTIAL,
                            payload: issueClaimCredential
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                console.log('Error in issue claim credential:::', error)
                throw error
            }
        }
    },

    /**
     * Method used to get all specific connection messages
     * @param orgId 
     * @param connectionId 
     * @param itemPerPage 
     * @returns It return the sent and received messages list.
     */
    getMessagesByConnectionId(orgId: number, connectionId: string, itemPerPage: number) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.get(`${config.apiUrl}/basic-message/org/${orgId}/connection/${connectionId}/${itemPerPage}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(basicMessageData => {
                        const messageRecord = basicMessageData.data.data
                        dispatch({
                            type: chats.GET_MESSAGES_BY_CONNECTION,
                            payload: messageRecord
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                console.error(`Error in get all message by connection id:::${JSON.stringify(error)}`);
                throw error;
            }
        }
    }
}