import { taskConst } from "../../types";

const initialState = {
  showInstantTask: false,
  sendTaskSuccess: {},
  createdCredDefsbyOrgId: [],
  showScheduledTask: false,
  scheduleTaskData: {},
  createdScheduleTask: {},
  updatedScheduleTask: {},
  getAllCredDefForInstantTaskList: [],
  selectedCredDefData: {},
  credentialDefinitionDetails: {},
  schemasForInstantTaskList: [],
  schemaDetails: {},
  organizationList: [],
  credentialDefinitionListByOrganization: [],
}

export function TaskReducers(state = initialState, action: any) {

  switch (action.type) {
    case taskConst.SHOW_INSTANT_TASK:
      return Object.assign({}, state, {
        showInstantTask: true
      });
    case taskConst.SHOW_SCHEDULED_TASK:
      return Object.assign({}, state, {
        scheduleTaskData: action.payload,
        showScheduledTask: true
      });
    case taskConst.SEND_INSTANT_TASK_REQUEST:
      return Object.assign({}, state,
        {
          showInstantTask: false,
        })
    case taskConst.GET_ALL_CREATED_CREDDEF_BY_ORGID:
      return Object.assign({}, state,
        {
          createdCredDefsbyOrgId: action.payload.data.data,
        })
    case taskConst.CREATE_BASIC_INFO:
      return Object.assign({}, state,
        {
          createdScheduleTask: action.payload.data.data,
          selectedCredDefData: {},
        })
    case taskConst.UPDATE_SCHEDULE_TASK:
      return Object.assign({}, state,
        {
          updatedScheduleTask: action.payload.data.data,
        })
    case taskConst.CLEAR_INSTANT_TASK_FORM:
      return Object.assign({}, state,
        {
          showInstantTask: false,
          showScheduledTask: false,
          credentialDefinitionDetails: {},
          schemaDetails: {},
        })
    case taskConst.CLEAR_SCHEDULE_TASK_FORM:
      return Object.assign({}, state,
        {
          showInstantTask: false,
          showScheduledTask: false,
          updatedScheduleTask: {},
          createdScheduleTask: {},
          scheduleTaskData: {},
        })
    case taskConst.GET_ALL_CREATED_CREDDEF_FOR_INSTANT_TASK:
      return Object.assign({}, state,
        {
          getAllCredDefForInstantTaskList: action.payload.data.data,
        })
    case taskConst.GET_ALL_SCHEMA_FOR_INSTANT_TASK:
      return Object.assign({}, state, {
        schemasForInstantTaskList: action.payload.data.data
      })
    case taskConst.HIDE_INSTANT_TASK:
      return Object.assign({}, state,
        {
          showInstantTask: false,
        })
    case taskConst.SELECTED_CREDEF_OPTION_FOR_ATTRIBUTES:
      return Object.assign({}, state,
        {
          selectedCredDefData: action.payload,
        })
    case taskConst.CREATE_BASIC_INFO_FAIL:
      return Object.assign({}, state,
        {
          createdScheduleTask: {},
        })
    case taskConst.GET_SINGLE_CRED_DEF_DETAILS:
      return Object.assign({}, state,
        {
          credentialDefinitionDetails: action.payload,
        })
    case taskConst.GET_SINGLE_SCHEMA_DETAILS:
      return Object.assign({}, state,
        {
          schemaDetails: action.payload,
        })
    case taskConst.CLEAR_SELECTED_ISSUER_ORGANIZATION:
      return Object.assign({}, state,
        {
          credentialDefinitionDetails: {},
          schemaDetails: {},
          // credentialDefinitionListByOrganization: [],
          // organizationList: [],
        })
    case taskConst.ORGANIZATIONS_LIST:
      return Object.assign({}, state,
        {
          organizationList: action.payload,
        })
    case taskConst.CREDENTIAL_DEFINITION_LIST_BY_ORGANIZATION:
      return Object.assign({}, state,
        {
          credentialDefinitionListByOrganization: action.payload,
        })
    default:
      return state
  }
}

