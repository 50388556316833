import React from 'react'

// Utilities
import images from "src/utils/images";

const SignUp = () => {
    return (
        <div className="container-fluid h-100">
            <div className="row h-100">
                <div className="col-md-12 offset-lg-3 col-lg-6 align-self-center">
                    <div className="card shadow">
                        <div className="card-header text-center bg-white border-bottom-0">
                            <img alt="Company logo" src={images.companyLogo} height="100" />
                        </div>
                        <div className="card-body p-5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-white" id="basic-addon1"><i className="fas fa-envelope"></i></span>
                                            </div>
                                            <input type="text" className="border-left-0 form-control" placeholder="Username" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-white" id="basic-addon1"><i className="fas fa-key"></i></span>
                                            </div>
                                            <input type="password" className="border-left-0 form-control" placeholder="Password" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <button type="button" className="btn btn-nb-blue btn-block btn-lg btn-block">LOGIN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SignUp;