import clsx from "clsx";
import React, { ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Utilities
import { useHelpGuide } from "src/hooks";

import "./index.css";

type Props = {
  /** Class name for button component (can offset parent margins, etc) */
  buttonClassName?: string;
  /** Help text localization key (preferred over content!) */
  contentKey?: string | null;
  children: ReactNode;
  /** Whether help guide should be hidden */
  hidden?: boolean;
  /** Hover delay for showing action on hover */
  hoverDelayIn?: number;
  /** Hover delay for hiding action after hover */
  hoverDelayOut?: number;
  /** Whether guide wrapper is inline or block (full width) */
  inline?: boolean;
  /** Whether guide action is always shown (uses hover otherwise) */
  persistent?: boolean;
  /** Help text title (optional) */
  title?: string;
  /** Class name for wrapper component */
  wrapperClassName?: string;
};

// TODO: Figure out how to display/match content (replace "content" prop with key from JSON files)
// TODO: Determine whether content exists in loaded files (display generic "missing" prompt if not?)
// TODO: Support displaying links via text recognition?
// TODO: Consider adding support for analytics (v2)

const HelpGuide = (props: Props): JSX.Element => {
  const {
    buttonClassName,
    children,
    contentKey,
    hidden = false,
    hoverDelayIn = 250,
    hoverDelayOut = 300,
    inline,
    persistent = false,
    title,
    wrapperClassName,
  } = props;

  // Help Guide context is responsible for managing modal state and display
  const { showGuide } = useHelpGuide();
  const [showAction, setShowAction] = useState(false);

  const { t } = useTranslation(["help", "screens"]);
  const missingGuideText = t("screens:helpGuide.guideNotAvailable");
  const content: string | null = contentKey ? t(contentKey as any, missingGuideText) : null;

  /** Action delay is utilized to hover in/out delay (for better experience) */
  const toggleActionDelayRef = useRef<NodeJS.Timeout | null>(null);

  /** Clear action delay for hiding/showing */
  const clearActionDelay = () => {
    if (toggleActionDelayRef.current) {
      clearTimeout(toggleActionDelayRef.current);
    }
  };

  /** Hide help action/button (after hover) */
  const hideHelpAction = () => {
    if (persistent) return;

    // Clear any potential delay for showing tooltip (to prevent showing after leaving!)
    clearActionDelay();

    // Since the help action is positioned outside the hover area, hiding the help action must
    //   be delayed to continue showing while the user tries to view the help.
    toggleActionDelayRef.current = setTimeout(() => {
      setShowAction(false);
    }, hoverDelayOut);
  };

  /** Show help action/button (on hover) */
  const showHelpAction = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (persistent) return;

    // NOTE: Disabled elements should not be able to show the help action, as the 'onMouseLeave'
    //         event is not fired on disabled elements (but 'onMouseEnter' is!).
    if ((e?.target as any)?.disabled) return;

    // Clear existing close delay timeout to prevent automatically closing when user moves off
    //   element to the shown action/button (would otherwise trigger hide).
    clearActionDelay();

    // Delay displaying the tooltip to avoid showing help action unnecessarily
    toggleActionDelayRef.current = setTimeout(() => {
      setShowAction(true);
    }, hoverDelayIn);
  };

  // NOTE: Must return Fragment to ensure valid JSX (for typing)
  if (hidden) return <>{children}</>;

  return (
    <div
      className={clsx(
        "help-guide__wrapper",
        inline && "is-inline",
        wrapperClassName
      )}
      onMouseEnter={showHelpAction}
      onMouseLeave={hideHelpAction}>
      {children}
      {Boolean(showAction || persistent) && (
        <button
          className={clsx("help-guide__button", buttonClassName)}
          onClick={() => showGuide(content, { title })}>
          <i className="fas fa-circle-question"></i>
        </button>
      )}
    </div>
  );
};

export default HelpGuide;
