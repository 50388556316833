import React from 'react';

interface IProps {
  items: any,
  onChangePage: any,
  initialPage: number,
}

interface IState {
  pager: any,
}
class PaginationComponent extends React.Component<IProps, IState>{
  constructor(props: IProps) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidMount() {
    // set page if items array isn't empty
    if (this.props.items && this.props.items) {
      this.setPage(this.props.initialPage);
    }
  }

  componentDidUpdate(newProps: IProps) {
    if(newProps.items !== this.props.items){
      this.setPage(this.props.initialPage)
    }
  }

  setPage(page: any) {
    // console.log("this.props.initialPage",this.props.initialPage);
    var items = this.props.items;
    var pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(items, page);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(page);
  }

  getPager(totalItems: any, currentPage: any) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    var pageSize: number = 10;

    // calculate total pages
    var totalPages: any = Math.ceil(totalItems / pageSize);

    var startPage: any, endPage: any;
    if (totalPages <= 3) {
      // less than 5 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 5 total pages so calculate start and end pages
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    var pages: any = [...Array((endPage + 1) - startPage)].map((ele, i) => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  render() {
    var pager = this.state.pager;
    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <nav className="nb-pagination" aria-label="Page navigation example" style={{display : 'inline-block'}}>
        <ul className="pagination">
          <li className={pager.currentPage === 1 ? 'disabled page-item' : 'page-item'}>
            <a className="page-link" onClick={() => this.setPage(1)} title="First"><i className="fas fa-angle-double-left"></i></a>
          </li>
          <li className={pager.currentPage === 1 ? 'disabled page-item' : 'page-item'}>
            <a className="page-link" onClick={() => this.setPage(pager.currentPage - 1)} title="Previous"><i className="fas fa-angle-left"></i></a>
          </li>
          {pager.pages.map((page: any, index: any) =>
            <li key={index} className={pager.currentPage === page ? 'active page-item' : 'page-item'}>
              <a className="page-link" onClick={() => this.setPage(page)}>{page}</a>
            </li>
          )}
          <li className={pager.currentPage === pager.totalPages ? 'disabled page-item' : 'page-item'}>
            <a className="page-link" onClick={() => this.setPage(pager.currentPage + 1)} title="Next"><i className="fas fa-angle-right"></i></a>
          </li>
          <li className={pager.currentPage === pager.totalPages ? 'disabled page-item' : 'page-item'}>
            <a className="page-link" onClick={() => this.setPage(pager.totalPages)} title="last"><i className="fas fa-angle-double-right"></i></a>
          </li>
        </ul>
      </nav>
    );
  }
}
export default PaginationComponent