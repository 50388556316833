import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListScreenOptions } from 'src/types/common.interface';
import { OrganizationCredentialSort } from 'src/types/enums/organization.enums';
import { connectionAction } from '../../Connection/actions/connectionAction';
import { credentialAction } from '../../Credentials/actions/credentialAction';
import { TaskActions } from '../../Task/actions/taskActions';
import { issuanceAction } from '../actions/issuanceAction';
import CredReqWaitingList from '../components/CredReqWaitingList';
import IssueCredentialComponent from '../components/IssueCredentialsComponent';
import SuccessPage from '../components/SuccessPage';
import { t } from 'i18next';
import { CredDefResponsibilityType } from 'src/View/IssuersVerifiers/interfaces';

export interface IIssueCredentialsProps {
  IssuanceReducer: any,
  CredentialReducer: any,
  LoaderReducer: any,
  LoginReducer: any,
  ConnectionReducer: any,
  issuanceAction: any,
  credentialAction: any,
  connectionAction: any,
  TaskActions: any,
  TaskReducers: any,
}

export interface IIssueCredentialsState {
  defaultSearchFields: any,
  item_perPage: any,
  sortingOrder: any,
  connectionSortBy: any,
  CredentialSortBy: any,
  credRequestSortBy: any,
  pageNo: any,
  connectionFilterValue: any,
  filterValue: any,
  isDeleted: any,
  organizationCredentialSort: OrganizationCredentialSort
}

class IssueCredentials extends React.Component<IIssueCredentialsProps, IIssueCredentialsState> {
  constructor(props: IIssueCredentialsProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      CredentialSortBy: '',
      credRequestSortBy: '',
      pageNo: 1,
      connectionFilterValue: 'all',
      filterValue: 'all',
      isDeleted: 'false',
      organizationCredentialSort: OrganizationCredentialSort.id,
    }
  }

  componentDidMount() {
    const { loggedInUser } = this.props.LoginReducer;

    const { defaultSearchFields, item_perPage, sortingOrder, connectionSortBy, pageNo, connectionFilterValue,
      filterValue, isDeleted, organizationCredentialSort } = this.state;
    const listScreenOptions: ListScreenOptions = {
      search: defaultSearchFields,
      itemsPerPage: item_perPage,
      pageNo,
      sortBy: organizationCredentialSort,
      sort: sortingOrder,
      filter: filterValue
    }
    this.props.connectionAction.getAllConnections(defaultSearchFields, connectionFilterValue, sortingOrder, connectionSortBy, 0, pageNo, isDeleted);
    // this.props.issuanceAction.getAllCredentialRequest( pageNo, item_perPage, defaultSearchFields, filterValue, sortingOrder, credRequestSortBy)

    this.props.TaskActions.getAllCreateCredDefByOrgId(loggedInUser.organization.id, CredDefResponsibilityType.Issuance);
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
  }

  public render() {
    let { showIssueCredential } = this.props.IssuanceReducer;

    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:issueCredentials.credentialList")}</h1>
              </div>
              <div className="card-body">
                <CredReqWaitingList {...this.props} />
              </div>
            </div>
          </div>
          {showIssueCredential ?
            <>
              <div className="col-md-12 col-lg-6 mb-30">
                <SuccessPage {...this.props} />
              </div>
            </>
            :
            <>
              <div className="col-md-12 col-lg-6 mb-30">
                <IssueCredentialComponent {...this.props} />
              </div>
            </>
          }
        </div>
      </>
    );
  }
}


function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const LoaderReducer = state.LoaderReducer;
  const IssuanceReducer = state.IssuanceReducer;
  const CredentialReducer = state.CredentialReducer;
  const ConnectionReducer = state.ConnectionReducer;
  const TaskReducers = state.TaskReducers;
  return {
    LoginReducer, LoaderReducer, IssuanceReducer, CredentialReducer, ConnectionReducer, TaskReducers,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
    credentialAction: bindActionCreators(credentialAction, dispatch),
    connectionAction: bindActionCreators(connectionAction, dispatch),
    TaskActions: bindActionCreators(TaskActions, dispatch),
  }
}

const connectedIssueCredentials = connect(mapStateToProps, mapDispatchToProps)(IssueCredentials);
export { connectedIssueCredentials as IssueCredentials };