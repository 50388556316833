import React from "react";
import { connect } from "react-redux";

// Utilities
import images from "src/utils/images";
import { t } from 'i18next';

class SuccessPage extends React.Component {

    public render() {
        return (
            <>
                <div className="card nb-card h-100">
                    <div className="card-body text-center">
                        <div className="row">
                            <div className="col-sm-12 mb-5 mt-5">
                                <img src={images.illustrationSuccess} alt="Success" />
                            </div>
                            <div className="col-sm-12">
                                <h3 className="nb-label">{t("screens:myCredentials.messageSuccess")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const MyCredentialReducer = state.MyCredentialReducer;
    return {
        MyCredentialReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {

    }
}

const successPage = connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
export { successPage as SuccessPage };
