import _ from 'lodash';
import React from 'react'
import NoRecordFoundComponent from '../../../Common Components/Components/NoRecordFound';
import TableComponents from '../../../Common Components/Components/TableComponent'
import { tHeadScheduleTaskList } from '../constant';

interface IScheduleTaskListProps {
  getAllInstantTaskAction: any,
  InstantTaskReducers: any,
  status:any,
  selectedWorkFlow: any,
}
interface IScheduleTaskListState {
  item_perPage: any,
  pageNo: any,
  defaultSearchFields: any,
  scheduleTaskSort: any,
  sortValue: any,
  status: any
}

export default class ScheduleTaskList extends React.Component<IScheduleTaskListProps, IScheduleTaskListState> {
  constructor(props: IScheduleTaskListProps) {
    super(props)
    this.state = {
      item_perPage: 10,
      pageNo: 1,
      defaultSearchFields: '',
      scheduleTaskSort: '',
      sortValue: 'DESC',
      status: ''
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.editElementOfScheduleTask = this.editElementOfScheduleTask.bind(this);
    this.deleteElementDetails = this.deleteElementDetails.bind(this);
    this.checkVerificationStatus = this.checkVerificationStatus.bind(this);
  }
  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortValue, scheduleTaskSort } = this.state;
    const { status,selectedWorkFlow } = this.props;
    // this.props.getAllInstantTaskAction.getAllInstantTask(defaultSearchFields, status, sortValue, scheduleTaskSort, item_perPage, page);
    // this.props.getAllInstantTaskAction.getListByWorkFlow(item_perPage, page, defaultSearchFields, scheduleTaskSort, sortValue, status, workFlowId)
    switch (status) {
      case 'all':
        this.setState({ status: status})
        this.props.getAllInstantTaskAction.getListByWorkFlow(item_perPage, page, defaultSearchFields, scheduleTaskSort, sortValue, 'all', selectedWorkFlow.id)
        break;
      case 'upcoming':
        this.setState({ status: status})
        this.props.getAllInstantTaskAction.getListByWorkFlow(item_perPage, page, defaultSearchFields, scheduleTaskSort, sortValue, 'Upcoming', selectedWorkFlow.id)
        break;
      case 'ongoing':
        this.setState({ status: status})
        this.props.getAllInstantTaskAction.getListByWorkFlow(item_perPage, page, defaultSearchFields, scheduleTaskSort, sortValue, 'OnGoing', selectedWorkFlow.id)
        break;
      case 'finished':
        this.setState({ status: status })
        this.props.getAllInstantTaskAction.getListByWorkFlow(item_perPage, page, defaultSearchFields, scheduleTaskSort, sortValue, 'Finished', selectedWorkFlow.id)
        break;
      default:
        break;
    }

  }

  showDetails(item: any) {
    const { selectedWFTaskList } = this.props.InstantTaskReducers;
    let duplicate: any = [];
    !_.isEmpty(selectedWFTaskList) && selectedWFTaskList.map((element: any, index: any) => {
      if (element.schedule_task_id === item.schedule_task_id) {
        duplicate.push(element);
      }
      return duplicate
    })

    this.props.getAllInstantTaskAction.showDetailsofScheduleTask(duplicate);
  }

  editElementOfScheduleTask(editItem: any) {
    console.log("editItem----", editItem);
  }

  deleteElementDetails(deleteItem: any) {
    console.log("delete deleteItem", deleteItem);
  }

  checkVerificationStatus(details: any) {
    this.props.getAllInstantTaskAction.checkVerificationStatus(details.schedule_task_id);
  }

  public render() {
    const { selectedWFTaskList, totalSelectedWFTaskList} = this.props.InstantTaskReducers
      let tableData: any = [];
      !_.isEmpty(selectedWFTaskList) && selectedWFTaskList.map((element: any, index: any) => {

      return tableData.push(element.tableHeadData[0])
    })
    return (
      <div>

        {
          !_.isEmpty(tableData) ?
            <TableComponents
              onChangePage={this.onChangePage}
              tHead={tHeadScheduleTaskList}
              tableContent={tableData}
              totalRecords={totalSelectedWFTaskList}
              showElementDetails={this.showDetails}
              // editElementOfScheduleTask={this.editElementOfScheduleTask}
              // deleteElementDetails={this.deleteElementDetails}
              checkVerificationStatus={this.checkVerificationStatus}
            />
            :
            <NoRecordFoundComponent />
        }
      </div>
    )
  }
}
