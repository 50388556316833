import axios from "axios";
import { toastr } from "react-redux-toastr";
import { userConstants, loaderConst } from "../../types";
import { config } from '../../../config'
import { commonConst } from "../../commonConst";
import { loginAction } from "../../Login/actions/loginAction";
import { t } from 'i18next';

export const schemaAction = {

  getAllSchema(
    defaultSearchFields: any,
    item_perPage: number,
    pageNo: number,
    page: string,
    sortValue: string,
    schemaSortBy: string,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/schema/get-all-by-orgid?search_text=${defaultSearchFields}&itemsPerPage=${item_perPage}&page=${pageNo}&sortValue=${sortValue}&schemaSortBy=${schemaSortBy}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(schemas => {
            dispatch({
              type: userConstants.GETALL_SUCCESS_SCHEMA,
              payload: schemas
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getSchemaList() {
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/schema/getAll`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(schemas => {
            
            dispatch({
              type: userConstants.GET_SCHEMA_LIST,
              payload: schemas
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        throw error;
      }
    }
  },

  saveSchema(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.post(`${config.apiUrl}/schema/create`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async schemas => {
            // debugger;
            dispatch({
              type: userConstants.CREATE_SCHEMA,
              payload: schemas
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            // toastr.success('Schema Created Successfully', `${schemas.data.data.schemaName}`);
            toastr.success(`${t("screens:createNewCredential.messageSchema")}`, `${schemas.data.data.schemaName}`);
            this.getAllSchema('', commonConst.PAGE_RECORD, 1, 'schemas', '', '')
          }).catch(error => {
          
            dispatch({
              type: userConstants.CREATE_SCHEMA_FAILURE,
              payload: error
            })
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  saveSchemaDef(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.post(`${config.apiUrl}/schema/create-schema-db`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async schemas => {
            // debugger;
            dispatch({
              type: userConstants.CREATE_SCHEMA_DEF,
              payload: schemas
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
           toastr.success('Created Successfully','');

          }).catch(error => {
            dispatch({
              type: userConstants.CREATE_SCHEMA_DEF_FAILURE,
              payload: error
            })
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },


  getSchemaById(
    schemaLedgerId: any
  ) {
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/schema/get-by-id/${schemaLedgerId}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(schemas => {
            dispatch({
              type: userConstants.GET_SCHEMA_BY_ID,
              payload: schemas
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectSchema(item: object, page: string) {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.SELECTED_SCHEMA,
        payload: { item, page }
      })
    }
  },

  showForm() {
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.SHOW_SCHEMA_FROM
      })
    }
  },

  selectSchemaLedger(data:any){
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.SELECTED_SCHEMA_LEDGER,
        payload: data,
      })
    }
  },

  getDefaultSchema(
    defaultSearchFields: any,
    sortValue:any,
    schemaSortBy:any,
    items_perpage:any,
    page:any
  ) {
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/schema/get-all/default?search_text=${defaultSearchFields}&sortValue=${sortValue}&schemaSortBy=${schemaSortBy}&itemsPerPage=${items_perpage}&page=${page}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(defaultSchema => {
            dispatch({
              type: userConstants.GET_DEFAULT_SCHEMA,
              payload: defaultSchema
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectedDefaultSchemas(selectedSchema:any){
    return async (dispatch: any) => {
      dispatch({
        type: userConstants.SELECTED_DEFAULT_SCHEMA,
        payload: selectedSchema,
      })
    }
  },

  getAllExternalOrganizationList(){
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/organization/get-external-organizations`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(schemas => {
            dispatch({
              type: userConstants.GET_EXTERNAL_ORGANIZATION_LIST,
              payload: schemas
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        throw error;
      }
    }
  }
}
