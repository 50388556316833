import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectionAction } from '../actions/connectionAction';
import _ from 'lodash';
import {
  issuanceAction
} from '../../IssueCredential/actions/issuanceAction';
import { schemaAction } from '../../Schema/actions/schemaAction';
import ExistingConnectionsList from '../components/ExistingConnectionsList';
import ExistingConnectionDetails from '../components/ExistingConnectionDetails';
import EditConnection from '../components/EditConnection';
import PAConnectionsList from '../components/PAConnectionList';


export interface IConnectionProps {
  connectionAction: any,
  ConnectionReducer: any,
  issuanceAction: any,
  schemaAction: any,
}

export interface IConnectionState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  connectionSortBy: string,
  type: any,
}

class PAConnectionComponent extends React.Component<IConnectionProps, IConnectionState> {
  constructor(props: IConnectionProps) {
    super(props);
    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      type: ''
    }
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, connectionSortBy , type} = this.state;
    this.props.connectionAction.getAllPAConnections(defaultSearchFields, type, sortingOrder, connectionSortBy, item_perPage, pageNo,);
  }

  componentWillUnmount() {
    this.props.connectionAction.defaultPage()
  }

  public render() {
    let {
      editConnection,
      selectedConnection,
      tHeadforEditConnection,
      listViewOfConnection
    } = this.props.ConnectionReducer;
    return (
      <>
        {
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="card nb-card-noborder">
                <div className="card-header bg-white">
                  <h1>Connections</h1>
                </div>
                <div className="card-body">
                  <PAConnectionsList editConnection={editConnection} {...this.props} />
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const ConnectionReducer = state.ConnectionReducer;
  return { ConnectionReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    connectionAction: bindActionCreators(connectionAction, dispatch),
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
    schemaAction: bindActionCreators(schemaAction, dispatch),
  }
}

const connections = connect(mapStateToProps, mapDispatchToProps)(PAConnectionComponent);
export { connections as PAConnectionComponent };
