import { t } from "i18next"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { onboardingAction } from "../../../actions/onboardingAction"

interface Props {
    selectedInvitationDetails: any,
    updateList: () => void;
}

interface IAcceptConnectionForm {
    name: string,
    email: string,
    connectionInvitation: string
}

const defaultFormFields: IAcceptConnectionForm = {
    name: '',
    email: '',
    connectionInvitation: ''
}

const defaultEmptyState = {
    name: false,
    email: false,
    connectionInvitation: false
}

const AcceptConnectionForm: React.FC<Props> = ({ selectedInvitationDetails, updateList }) => {
    const dispatch = useDispatch();

    const [formFields, setFormFields] = useState(defaultFormFields)
    const { name, email, connectionInvitation } = formFields

    const [emailError, setEmailError] = useState('');
    const [isEmpty, setIsEmpty] = useState(defaultEmptyState)

    const { organization } = useSelector((state: any) => state.LoginReducer.loggedInUser);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target

        setFormFields({ ...formFields, [name]: value })
    }

    const handleInputBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setIsEmpty({
            ...isEmpty,
            [name]: value.trim() === '',
        });
    };

    // Email validation function
    const validateEmail = (email: string) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailPattern.test(email);
    };

    // Event handler to validate email format
    const handleEmailBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        setEmailError(validateEmail(value) ? '' : 'Invalid email format');
    };

    // Check if there are any errors (any field is empty or email format is invalid)
    const hasErrors = Object.values(isEmpty).some((isEmptyValue) => isEmptyValue) || emailError !== '';

    const acceptInvitation = () => {

        let acceptConnectionRequestBody
        if (Object.keys(selectedInvitationDetails).length) {
            acceptConnectionRequestBody = {
                fromOrgId: organization.id,
                toOrgName: selectedInvitationDetails.toOrgName,
                toOrgEmail: selectedInvitationDetails.toOrgEmail,
                connectionInvitation: selectedInvitationDetails.invitationUrl
            }
        } else {
            acceptConnectionRequestBody = {
                fromOrgId: organization.id,
                toOrgName: name,
                toOrgEmail: email,
                connectionInvitation: connectionInvitation
            }
        }

        dispatch(onboardingAction.acceptConnection(acceptConnectionRequestBody))
        dispatch(onboardingAction.getOrganizationConnectionListReceived('', 10, 1));
        /* Reset send connection invitation related the local state variable */
        updateList()
        setEmailError('')
        setIsEmpty(defaultEmptyState)
        setFormFields(defaultFormFields)

    }
    const saveInvitation = () => {

        const saveConnectionRequestBody = {
            fromOrgId: organization.id,
            toOrgName: name,
            toOrgEmail: email,
            connectionInvitation
        }

        /* Send invitation to organization action method call */
        dispatch(onboardingAction.saveConnectionInvitation(saveConnectionRequestBody))
        dispatch(onboardingAction.getOrganizationConnectionListReceived('', 10, 1));
        
        updateList()
    }

    return (
        <>
            <div className="col-sm-12">
                <div className="form-group">
                    <label className="nb-label">{t("screens:acceptInvitation.givenName")}</label>
                    <>
                        <input type="text" className="form-control" name="name"
                            value={Object.keys(selectedInvitationDetails).length
                                ? selectedInvitationDetails.toOrgName
                                : name}
                            disabled={Object.keys(selectedInvitationDetails).length ? true : false}
                            onChange={handleChange}
                            onBlur={handleInputBlur}
                        />
                        {
                            isEmpty?.name &&
                            <div className="text-danger">
                                <small><i className="fas fa-info-circle mr-1"></i>{t("screens:acceptInvitation.messageErrorName")}</small>
                            </div>
                        }
                    </>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="form-group">
                    <label className="nb-label">{t("screens:acceptInvitation.email")}</label>
                    <>
                        <input type="text" className="form-control" name="email"
                            value={Object.keys(selectedInvitationDetails).length
                                ? selectedInvitationDetails.toOrgEmail
                                : email}
                            disabled={Object.keys(selectedInvitationDetails).length ? true : false}
                            onChange={handleChange}
                            onBlur={handleEmailBlur}
                        />
                        {
                            emailError &&
                            <div className="text-danger">
                                <small><i className="fas fa-info-circle mr-1"></i>{t("screens:acceptInvitation.messageErrorEmail")}</small>
                            </div>
                        }
                    </>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="form-group">
                    <label className="nb-label">{t("screens:acceptInvitation.connectionURL")}</label>
                    <>
                        <textarea className="form-control overflow-eclips m-auto" rows={9} name="connectionInvitation"
                            value={Object.keys(selectedInvitationDetails).length
                                ? selectedInvitationDetails.invitationUrl
                                : connectionInvitation}
                            disabled={Object.keys(selectedInvitationDetails).length ? true : false}
                            onChange={handleChange}
                            onBlur={handleInputBlur}
                        />

                        {
                            isEmpty?.connectionInvitation &&
                            <div className="text-danger">
                                <small><i className="fas fa-info-circle mr-1"></i>{t("screens:acceptInvitation.messageErrorConnection")}</small>
                            </div>
                        }
                    </>
                </div>
                <button className="btn btn-sm btn-block btn-nb-outline-primary rounded-pill"
                    disabled={hasErrors
                        || (!Object.keys(selectedInvitationDetails).length
                            && (name === '' || email === '' || connectionInvitation === ''))
                        ? true : false}

                    onClick={acceptInvitation}>
                    {t("screens:acceptInvitation.connect")}
                </button>
                {
                    !Object.keys(selectedInvitationDetails).length
                        ?
                        <button className="btn btn-sm btn-block btn-nb-outline-primary rounded-pill"
                            disabled={hasErrors || name === '' || email === '' || connectionInvitation === '' ? true : false}
                            onClick={saveInvitation}>
                            {t("screens:acceptInvitation.save")}
                        </button>
                        :
                        <></>
                }

            </div>
        </>
    )
}

export default AcceptConnectionForm
