import axios from "axios";
import _ from "lodash";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, loginMonitoringConst } from "../../types"

export const LoginMonitoringListAction = {
  getAllLoginMonitoringList(
    pageNo: number,
    item_perPage: number,
    defaultSearchFields: any,
    loginMonitoringStatus: any,
    loginMonitoringCredSort: string,
    sortValue: string,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/admin/login-monitoring?page=${pageNo}&itemsPerPage=${item_perPage}&search_text=${defaultSearchFields}&loginMonitoringStatus=${loginMonitoringStatus}&loginMonitoringCredSort=${loginMonitoringCredSort}&sortValue=${sortValue}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(logingMonitoringList => {
            let tempArray: any = [];
            tempArray = !_.isEmpty(logingMonitoringList.data.data.data) && logingMonitoringList.data.data.data;
            if (!_.isEmpty(tempArray)) {
              tempArray.map((element: any, index: any) => {
                tempArray[index].activeState = element.active;
                tempArray[index].verifiedStatus = element.status;
              })
            }
            dispatch({
              type: loginMonitoringConst.GET_LOGIN_MONITORING_LIST,
              payload: logingMonitoringList,
              tempArray
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch)
            throw error;
          })
      } catch (error) {
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        throw error;
      }
    }
  },
}