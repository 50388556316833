import { t } from "i18next";
import { useSelector } from "react-redux";
import AcceptConnectionForm from "./Components/accept-connection-form.component";
import AcceptConnectionSuccess from "./Components/accept-connection-success.component";

interface Props {
    selectedInvitationDetails: any,
    updateList: () => void;
}

const AcceptConnection: React.FC<Props> = ({ selectedInvitationDetails, updateList }) => {
    const { acceptSendSuccess, connectedOrgName } = useSelector((state: any) => state.OnboardingReducer);

    return (
        <div className="card nb-card h-100">
            <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:acceptInvitation.acceptConnectionInvitation")}</h1>
            </div>
            <div className="card-body">
                <div className="row">
                    {
                        !acceptSendSuccess
                            ? <AcceptConnectionForm
                                selectedInvitationDetails={selectedInvitationDetails}
                                updateList={updateList}
                            />
                            : <AcceptConnectionSuccess connectedOrgName={connectedOrgName} />
                    }
                </div>
            </div>
        </div>
    )
}

export default AcceptConnection;
