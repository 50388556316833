import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginAction } from '../../Login/actions/loginAction';
import { dashboardAction } from '../actions/dashboardAction';
import { Chart } from "react-google-charts";
import { t } from 'i18next';

export interface IIssuerDashboardProps {
  loginAction: any,
  LoginReducer: any,
  dashboardAction: any,
  DashboardReducer: any,
}

export interface IIssuerDashboardState {
  dateData: any,
  selectStartDate: any,
  selectEndDate: any,
}

class IssuerDashboard extends React.Component<IIssuerDashboardProps, IIssuerDashboardState> {
  constructor(props: IIssuerDashboardProps) {
    super(props);

    this.state = {
      dateData: {},
      selectStartDate: false,
      selectEndDate: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.dashboardAction.getAllCountforDashboard();
    this.props.loginAction.getCurrentUser();

  }

  handleChange(event: any) {
    const { dateData } = this.state;
    let fields = dateData;
    let selectStartDate = false;
    let selectEndDate = false;
    fields[event.target.name] = event.target.value;
    this.setState({
      dateData: fields,
    });

    this.props.dashboardAction.getAllCountforDashboard(dateData.startDate, dateData.endDate);
  }

  public render() {
    const { loggedInUser } = this.props.LoginReducer;
    const { countForDashboard } = this.props.DashboardReducer;
    const { dateData, selectStartDate, selectEndDate } = this.state;
    let showMonth = moment().clone().startOf('month').format('MMM-DD')
    let da = moment(showMonth).toDate();
    let credentialPercentage:any = !_.isEmpty(countForDashboard) && countForDashboard[1].credentialPercentage;
    return (
      <>
         <div className="row">
          {/* <div className="col-sm-8"></div>
          <div className="col-sm-4">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="startDate"
                placeholder="select"
                value={dateData.startDate !== '' ? dateData.startDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-prepend">
                <label className="input-group-text bg-white border-left-0 border-right-0" htmlFor="inputGroupSelect01">-</label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="endDate"
                value={dateData.endDate !== '' ? dateData.endDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-append">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <small className="text-danger">{selectStartDate ? 'Select Start Date' : ''}</small>
          </div> */}
        </div>
        <div className="row animated fadeIn mb-3">
          <div className="col-md-12 col-lg-9">
            <div className="card nb-card mb-3">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:dashboard.credentialsTitle")}</h1>
              </div>
              <div className="card-body">
                <Chart
                  width={'100%'}
                  height={'400px'}
                  chartType="AreaChart"
                  loader={<div>{t("screens:dashboard.loadingChart")}</div>}

                  data={credentialPercentage}
                  options={{
                    title: `${t("screens:dashboard.credential")}`,
                    hAxis: { title: `${t("screens:dashboard.month")}`, titleTextStyle: { color: '#333' } },
                    vAxis: { minValue: 0 },
                    chartArea: { width: '80%', height: '80%' },
                    colors: ['#803DF8', '#254D91', '#9842D4', '#008BE6']
                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-3 mb-3">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:dashboard.connectionsTitle")}</h1>
              </div>
              <div className="card-body">
               {!_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderOutsideCount !==0 || !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderInsideCount !== 0 ?
                <Chart
                  width={'100%'}
                  height={'auto'}
                  chartType="PieChart"
                  loader={<div>{t("screens:dashboard.loadingChart")}</div>}
                  data={[
                    [`${t("screens:dashboard.language")}`, `${t("screens:dashboard.speakers")}`],
                    [`${t("screens:dashboard.outsideOrg")}`, !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderOutsideCount],
                    [`${t("screens:dashboard.insideOrg")}`, !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderInsideCount],
                  ]}
                  options={{
                    title: `${t("screens:dashboard.connections")}`,
                    pieHole: 0.4,
                  }}
                  rootProps={{ 'data-testid': '3' }}
                />
                :
                <h1 className="text-primary text-center">{t("screens:dashboard.noConnections")}</h1>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const DashboardReducer = state.DashboardReducer;
  return { LoginReducer, DashboardReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    dashboardAction: bindActionCreators(dashboardAction, dispatch),
  }
}

const connectedIssuerDashboard = connect(mapStateToProps, mapDispatchToProps)(IssuerDashboard);
export { connectedIssuerDashboard as IssuerDashboard };