import { t } from "i18next";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProblemReportComponent from "src/Common Components/Components/ProblemReportModal";
import TableComponents from "src/Common Components/Components/TableComponent";
import { ListScreenOptions } from "src/types/common.interface";
import { camelCase } from "src/utils/strings";
import { CredentialState } from "src/View/commonConst";
import { credentialProposalAction } from "src/View/CredentialProposal/actions/credentialProposalAction";
import { loginAction } from "src/View/Login/actions/loginAction";
import { myCredentialsAction } from "src/View/MyCredentials/action/myCredentialsAction";
import { schemaAction } from "src/View/Schema/actions/schemaAction";
import { OrgProfileAction } from "src/View/Settings/ProfileSetting/actions/orgProfileAction";
import { offerNegotiateInitiator } from "src/View/types";
import { NegotiateCredentialAction } from "../actions/NegotiateCredentialAction";
import { AuthenticateIssuerModal } from "../component/AuthenticateIssuerModal";
import { CounterProposal } from "../component/CounterProposal";
import { ReProposeCredential } from "../component/ReProposeCredential";
import { TrustDecisionModal } from "../component/TrustDecisionModal";
import { PreferredTrustMethod } from "../constants";
import { NegotiateCredentialDetails } from "../component/NegotiateDetails/NegotiateCredentialDetails";

interface INegotiateCredentialProps {
    NegotiateCredentialReducer: any,
    LoginReducer: any,
    NegotiateCredentialAction: any,
    schemaAction: any,
    loginAction: any,
    myCredentialsAction: any,
    credentialProposalAction: any,
    SchemaReducer: any,
    MyCredentialReducer: any,
    CredentialProposalReducer: any,
    OrgProfileAction: any,
    orgProfileReducer: any,
    LoaderReducer: any
}

interface INegotiateCredentialState {
    listScreenOptions: ListScreenOptions,
    selectedCredentialRecord: any,
    isModalProblemReportShow: boolean,
    offerDeclineReasonList: string[],
    proposalDeclineReasonList: string[],
    isRePropose: boolean,
    isNegotiateProposal: boolean,
    requestList: any[],
    selectedRequest: any,
    totalRecords: number,
    isOfferAccepted: boolean,
    isProblemReported: boolean,
    showAuthenticateIssuerModal: boolean
    showTrustDecisionModal: boolean,
    preferredMethod: PreferredTrustMethod,
    trustRegistryResult: boolean | null
}

class NegotiateCredential extends React.Component<INegotiateCredentialProps, INegotiateCredentialState> {
    constructor(props: INegotiateCredentialProps) {
        super(props);

        this.state = {
            listScreenOptions: {
                search: '',
                itemsPerPage: 10,
                pageNo: 1,
                sortBy: 'id',
                sort: 'DESC',
                filter: 'all'
            },
            selectedCredentialRecord: {},
            isModalProblemReportShow: false,
            offerDeclineReasonList: [
                `${t("screens:negotiateCredential.insufficientData")}`,
                `${t("screens:negotiateCredential.invalidData")}`
            ],
            proposalDeclineReasonList: [
                `${t("screens:negotiateCredential.notRequired")}`,
                `${t("screens:negotiateCredential.invalidData")}`
            ],
            isRePropose: false,
            isNegotiateProposal: false,
            requestList: [],
            selectedRequest: {},
            totalRecords: 0,
            isOfferAccepted: false,
            isProblemReported: false,
            showAuthenticateIssuerModal: false,
            showTrustDecisionModal: false,
            preferredMethod: PreferredTrustMethod.Trust_Decision_Helper,
            trustRegistryResult: null
        }

        this.onChangePage = this.onChangePage.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.sorting = this.sorting.bind(this);

        this.handleDeclineProposal = this.handleDeclineProposal.bind(this)
        this.handleDeclineModalClose = this.handleDeclineModalClose.bind(this)
        this.handleProblemReportSubmit = this.handleProblemReportSubmit.bind(this)
        this.handleReProposeComponent = this.handleReProposeComponent.bind(this)
        this.handleReProposeCancel = this.handleReProposeCancel.bind(this)
        this.handleCounterProposalComponent = this.handleCounterProposalComponent.bind(this)
        this.handleCounterProposalCancel = this.handleCounterProposalCancel.bind(this)

        this.handleCredentialProposal = this.handleCredentialProposal.bind(this)
        this.handleSendOfferNegotiate = this.handleSendOfferNegotiate.bind(this)
        this.handleAcceptCredential = this.handleAcceptCredential.bind(this)
        this.handleAuthenticateIssuerModalClose = this.handleAuthenticateIssuerModalClose.bind(this)
        this.handleVerifyIssuerInTrustRegistry = this.handleVerifyIssuerInTrustRegistry.bind(this)
        this.handleCheckCredentialIssuanceAuthority = this.handleCheckCredentialIssuanceAuthority.bind(this)
        this.handleTrustDecision = this.handleTrustDecision.bind(this)
        this.handleTrustDecisionModalClose = this.handleTrustDecisionModalClose.bind(this)
        this.handlePreferredMethodChange = this.handlePreferredMethodChange.bind(this)
        this.handleVerifyTrustRegistry = this.handleVerifyTrustRegistry.bind(this)
        this.handleGetDIDDoc = this.handleGetDIDDoc.bind(this)
    }

    componentDidMount() {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;
        const { credentialRecords } = this.props.NegotiateCredentialReducer;
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
        if (credentialRecords.length > 0) {
            this.props.NegotiateCredentialAction.showSelectedCredentialDetails(credentialRecords[0])
        }

        this.props.loginAction.getCurrentUser()
    }

    componentDidUpdate(prevProps: Readonly<INegotiateCredentialProps>, prevState: Readonly<INegotiateCredentialState>, snapshot?: any): void {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;

        // isOfferAccepted
        if (prevProps.MyCredentialReducer.isOfferAccepted !== this.props.MyCredentialReducer.isOfferAccepted) {
            console.log('did update accept', this.props.MyCredentialReducer.isOfferAccepted)
            const response = this.props.MyCredentialReducer.acceptCredentialResponse
            const { requestList } = this.state
            if (response && Object.keys(response.data.data).length) {
                const updatedCredExId = response.data.data.credentialExchangeId
                let foundIndex = requestList.findIndex(x => x.credentialExchangeId === updatedCredExId);
                if (foundIndex !== -1) {
                    requestList[foundIndex].state = response.data.data.state === CredentialState.STATE_REQUEST_SENT ? 'Offer Accepted' : response.data.data.state;
                }
            }
            this.setState({
                requestList: [...requestList],
                isOfferAccepted: this.props.MyCredentialReducer.isOfferAccepted,
                selectedRequest: requestList[0] || {},
            })
        }

        if (prevProps.MyCredentialReducer.isProblemReported !== this.props.MyCredentialReducer.isProblemReported) {
            console.log('did update problem report', this.props.MyCredentialReducer.isProblemReported)
            const response = this.props.MyCredentialReducer.credentialProblemReportResponse
            const { requestList } = this.state
            if (response && Object.keys(response.data.data).length) {
                const updatedCredExId = response.data.data.credentialExchangeId
                let foundIndex = requestList.findIndex(x => x.credentialExchangeId === updatedCredExId);
                if (foundIndex !== -1) {
                    requestList[foundIndex].state = response.data.data.state;
                    let that = this
                    setTimeout(function () {
                        that.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
                    }, 1500)
                }
            }
            this.setState({
                requestList: [...requestList],
                isProblemReported: this.props.MyCredentialReducer.isProblemReported,
                selectedRequest: requestList[0] || {},
            })
        }

        if (prevProps.CredentialProposalReducer.proposedCredential !== this.props.CredentialProposalReducer.proposedCredential) {
            console.log('did update cred proposal/ re-proposal', this.props.CredentialProposalReducer.proposedCredential)
            const response = this.props.CredentialProposalReducer.proposedCredential
            const { requestList } = this.state
            if (response && Object.keys(response.data.data).length) {
                const updatedCredExId = response.data.data.credential_exchange_id
                let foundIndex = requestList.findIndex(x => x.credentialExchangeId === updatedCredExId);
                if (foundIndex !== -1) {
                    requestList[foundIndex].state = response.data.data.state;
                }
            }
            this.setState({
                requestList: [...requestList],
                // isProblemReported: this.props.MyCredentialReducer.isProblemReported,
                selectedRequest: requestList[0] || {},
            })
        }

        if (JSON.stringify(prevProps.NegotiateCredentialReducer.credentialRecords) !== JSON.stringify(this.props.NegotiateCredentialReducer.credentialRecords)
            || JSON.stringify(this.state.requestList) !== JSON.stringify(this.props.NegotiateCredentialReducer.credentialRecords)
        ) {
            console.log('did update', prevProps.NegotiateCredentialReducer.credentialRecords !== this.props.NegotiateCredentialReducer.credentialRecords)

            this.setState({
                requestList: [...this.props.NegotiateCredentialReducer.credentialRecords],
                selectedRequest: this.props.NegotiateCredentialReducer.credentialRecords[0],
                totalRecords: this.props.NegotiateCredentialReducer.totalCredentialRecords
            })

            const { selectedCredentialRecord } = this.props.NegotiateCredentialReducer;
            if (Object.keys(selectedCredentialRecord).length) {
                const schemaId = selectedCredentialRecord.credentialDefinitions && Object.keys(selectedCredentialRecord.credentialDefinitions).length
                    ? selectedCredentialRecord.credentialDefinitions.schemaLedgerId
                    : JSON.parse(selectedCredentialRecord.credentialProposalDict)?.schema_id

                this.props.schemaAction.getSchemaById(schemaId)

            }
        }

        if(JSON.stringify(prevProps.NegotiateCredentialReducer.selectedCredentialRecord) !== JSON.stringify(this.props.NegotiateCredentialReducer.selectedCredentialRecord)){
            this.setState({
                selectedCredentialRecord:this.props.NegotiateCredentialReducer.selectedCredentialRecord,
                trustRegistryResult: this.props.NegotiateCredentialReducer.selectedCredentialRecord.trustRegistryResult
            })
        }
    }

    onChangePage(page: any) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page
        const { loggedInUser } = this.props.LoginReducer;
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
    }

    showDetails(item: any) {
        this.props.NegotiateCredentialAction.showSelectedCredentialDetails(item)
        const schemaId = item.credentialDefinitions && Object.keys(item.credentialDefinitions).length
            ? item.credentialDefinitions.schemaLedgerId
            : JSON.parse(item.credentialProposalDict)?.schema_id

        this.props.schemaAction.getSchemaById(schemaId)
        this.setState({
            selectedCredentialRecord: item,
            isRePropose: false,
            isNegotiateProposal: false,
            selectedRequest: item,
            isOfferAccepted: false,
            isProblemReported: false,
            trustRegistryResult: item.trustRegistryResult
        })
    }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;

        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions });
            this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
        }
    }

    sorting = (itemSortBy: string) => {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;

        listScreenOptions.sort = (listScreenOptions.sort === 'DESC') ? 'ASC' : 'DESC'
        listScreenOptions.sortBy = itemSortBy
        this.setState({ listScreenOptions })
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)

    }

    refresh = () => {
        const listScreenOptions = {
            search: '',
            itemsPerPage: 10,
            pageNo: 1,
            sortBy: 'id',
            sort: 'DESC',
            filter: 'all'
        }
        const { loggedInUser } = this.props.LoginReducer;

        this.setState({ listScreenOptions })
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
        const { credentialRecords } = this.props.NegotiateCredentialReducer;
        if (credentialRecords.length > 0) {
            this.props.NegotiateCredentialAction.showSelectedCredentialDetails(credentialRecords[0])
        }
    }

    handleDeclineProposal() {
        this.setState({ isModalProblemReportShow: true })
    }

    handleDeclineModalClose() {

        this.setState({
            isModalProblemReportShow: false,
        })

        // this.props.NegotiateCredentialAction.closeNegotiateProposalModal()
    }

    handleProblemReportSubmit(reason: string, exchangeId: string) {
        this.props.myCredentialsAction.sendProblemReportForCredentialExchange(reason, exchangeId)
        this.handleDeclineModalClose()
        this.refresh()

    }

    handleReProposeComponent(credentialExchangeId: string) {
        console.log('credentialExchangeId', credentialExchangeId)
        this.setState({
            isRePropose: true
        })
    }

    handleReProposeCancel() {
        this.setState({
            isRePropose: false
        })
    }

    handleCounterProposalComponent(credentialExchangeId: string) {
        console.log('credentialExchangeId', credentialExchangeId)
        this.setState({
            isNegotiateProposal: true,
        })
    }

    handleCounterProposalCancel() {
        this.setState({
            isNegotiateProposal: false
        })
        this.refresh()
    }

    handleCredentialProposal(proposalData: any) {
        this.props.credentialProposalAction.sendProposal(proposalData)
        this.handleReProposeCancel()
        this.refresh()

    }

    handleSendOfferNegotiate(credentialExchangeId: string) {
        let credentialOfferOptions: any = { credentialExchangeId }

        this.props.NegotiateCredentialAction.sendCredentialOfferOrNegotiate(credentialOfferOptions, offerNegotiateInitiator.negotiate);
        this.handleCounterProposalCancel()
    }

    handleAcceptCredential(credentialExchangeId: string) {
        this.props.myCredentialsAction.acceptCredentialOffer(credentialExchangeId);
        this.setState({
            showAuthenticateIssuerModal: false
        })
    }

    handleTrustDecision(credentialExchangeId: string) {
        if (this.state.trustRegistryResult !== null) {
            this.props.myCredentialsAction.acceptCredentialOffer(credentialExchangeId);
        }
        else {
            this.setState({
                showTrustDecisionModal: true
            })
        }
    }

    handleTrustDecisionModalClose() {
        this.setState({
            showTrustDecisionModal: false
        })
    }

    handleVerifyIssuerInTrustRegistry() {
        const { preferredMethod } = this.state
        if (preferredMethod === PreferredTrustMethod.Trust_Decision_Helper) {

            this.setState({
                showTrustDecisionModal: false,
                showAuthenticateIssuerModal: true,
                trustRegistryResult: null
            })
        }
        else {
            this.setState({
                showTrustDecisionModal: false,
                trustRegistryResult: false
            })
        }
    }

    handleVerifyTrustRegistry(match: boolean, credentialExchangeId: string) {
        this.props.NegotiateCredentialAction.updateTrustRegistryResult({ trustRegistryResult: match }, credentialExchangeId)
        this.setState({
            trustRegistryResult: match,
            showAuthenticateIssuerModal: false
        })
    }

    handlePreferredMethodChange(event: any) {
        this.setState({
            preferredMethod: event.target.name
        })
    }

    handleAuthenticateIssuerModalClose() {

        this.setState({
            showAuthenticateIssuerModal: false,
        })

        // this.props.NegotiateCredentialAction.closeNegotiateProposalModal()
    }

    handleCheckCredentialIssuanceAuthority(did: string, credentialDefinitionId: string) {
        // TODO: get the credential issuance authority check
        this.props.OrgProfileAction.getIssuanceAuthorization(did, credentialDefinitionId)
    }

    handleCheckDIDAuthentic(did: string) {
        this.props.OrgProfileAction.checkDIDAuthentic(did)
    }

    handleGetIssuerDNSEntries(did: string) {
        this.props.OrgProfileAction.getDNSEntries(did)

    }

    handleGetDIDDoc(did: string) {
        this.props.OrgProfileAction.getDIDDoc(did)
    }



    render(): React.ReactNode {
        const { myCredentialsAction, NegotiateCredentialAction, orgProfileReducer, LoaderReducer } = this.props
        const { tHeadNegotiate, selectedCredentialRecord } = this.props.NegotiateCredentialReducer;
        const { isModalProblemReportShow, offerDeclineReasonList, proposalDeclineReasonList, isRePropose, isNegotiateProposal, requestList,
            selectedRequest, totalRecords, isOfferAccepted, isProblemReported, showAuthenticateIssuerModal, showTrustDecisionModal, preferredMethod,
            trustRegistryResult } = this.state
        // console.log('selectedCredentialRecord', selectedCredentialRecord)

        const declineReasonList = selectedCredentialRecord && selectedCredentialRecord.state === CredentialState.STATE_OFFER_RECEIVED
            ? offerDeclineReasonList
            : proposalDeclineReasonList

        let selectedSchema;
        if (Object.keys(selectedCredentialRecord).length > 0) {
            selectedSchema = this.props.SchemaReducer.selectedSchema
        }
        return (
            <>

                <div className="row gutters-sm min-h-100">
                    <div className="col-md-12dnsName col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:negotiateCredential.credentialRequestList")}</h1>
                            </div>
                            <div className="card-body">
                                {requestList &&
                                    <TableComponents
                                        onChangePage={this.onChangePage}
                                        showElementDetails={this.showDetails}
                                        tHead={tHeadNegotiate}
                                        tableContent={requestList}
                                        totalRecords={totalRecords}
                                        selectedRecord={selectedRequest}
                                        sorting={this.sorting}
                                        searching={this.onSearch}
                                        refresh={this.refresh}
                                    />

                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            {/* Component for show specific credential details */}
                            {selectedCredentialRecord && !isRePropose && !isNegotiateProposal &&
                                <NegotiateCredentialDetails
                                    key={selectedRequest?.id}
                                    selectedCredentialRecord={selectedRequest}
                                    handleDecline={this.handleDeclineProposal}
                                    handleRePropose={this.handleReProposeComponent}
                                    handleCounterProposal={this.handleCounterProposalComponent}
                                    handleSendOfferNegotiate={this.handleSendOfferNegotiate}
                                    isOfferAccepted={isOfferAccepted}
                                    isProblemReport={isProblemReported}
                                    handleTrustDecision={(credentialExchangeId: string) => this.handleTrustDecision(credentialExchangeId)}
                                    trustRegistryResult={trustRegistryResult}
                                />
                            }
                            {selectedCredentialRecord && isRePropose &&
                                <ReProposeCredential
                                    key={selectedCredentialRecord.id}
                                    selectedCredentialRecord={selectedCredentialRecord}
                                    problemReport={myCredentialsAction}
                                    handleCredentialProposal={this.handleCredentialProposal}
                                    handleCancel={this.handleReProposeCancel}
                                />
                            }
                            {selectedCredentialRecord
                                && isNegotiateProposal
                                && Object.keys(selectedSchema).length
                                && <CounterProposal
                                    key={selectedCredentialRecord.id}
                                    selectedCredentialRecord={selectedCredentialRecord}
                                    NegotiateCredentialAction={NegotiateCredentialAction}
                                    handleCancel={this.handleCounterProposalCancel}
                                    selectedSchema={selectedSchema}
                                />
                            }
                        </div>
                    </div>
                </div>

                {
                    Object.keys(selectedCredentialRecord).length && isModalProblemReportShow &&
                    <ProblemReportComponent
                        isModalShow={isModalProblemReportShow}
                        reasonList={declineReasonList}
                        selectedRecordId={selectedCredentialRecord.credentialExchangeId}
                        selectedRecordGivenName={selectedCredentialRecord?.connectionName}
                        closeModal={this.handleDeclineModalClose}
                        handleProblemReportSubmit={this.handleProblemReportSubmit}
                        modalTitle={t("screens:negotiateCredential.credentialDeclined")}
                        credentialState={t(`screens:negotiateCredential.${camelCase(selectedCredentialRecord?.state)}`)}
                    />
                }
                {
                    showAuthenticateIssuerModal &&
                    <AuthenticateIssuerModal
                        isModalShow={showAuthenticateIssuerModal}
                        closeModal={this.handleAuthenticateIssuerModalClose}
                        selectedCredentialRecord={selectedCredentialRecord}
                        handleVerifyTrustRegistry={(result, credentialExchangeId) => this.handleVerifyTrustRegistry(result, credentialExchangeId)}
                        isOfferAccepted={isOfferAccepted}
                        handleDIDAuthenticate={(did) => this.handleCheckDIDAuthentic(did)}
                        handleGetDIDDoc={(did) => this.handleGetDIDDoc(did)}
                        handleCheckCredentialIssuanceAuthority={(did, credentialDefinitionId) => this.handleCheckCredentialIssuanceAuthority(did, credentialDefinitionId)}
                        orgProfileReducer={orgProfileReducer}
                        LoaderReducer={LoaderReducer}
                    />
                }
                {
                    showTrustDecisionModal &&
                    <TrustDecisionModal
                        isModalShow={showTrustDecisionModal}
                        closeModal={this.handleTrustDecisionModalClose}
                        preferredMethod={preferredMethod}
                        preferredMethodChange={this.handlePreferredMethodChange}
                        handleVerifyIssuerInTrustRegistry={this.handleVerifyIssuerInTrustRegistry}
                    />
                }
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const NegotiateCredentialReducer = state.NegotiateCredentialReducer;
    const LoginReducer = state.LoginReducer;
    const SchemaReducer = state.SchemaReducer
    const MyCredentialReducer = state.MyCredentialReducer;
    const CredentialProposalReducer = state.CredentialProposalReducer
    const orgProfileReducer = state.orgProfileReducer
    const LoaderReducer = state.LoaderReducer
    return {
        NegotiateCredentialReducer,
        LoginReducer,
        SchemaReducer,
        MyCredentialReducer,
        CredentialProposalReducer,
        orgProfileReducer,
        LoaderReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        NegotiateCredentialAction: bindActionCreators(NegotiateCredentialAction, dispatch),
        loginAction: bindActionCreators(loginAction, dispatch),
        schemaAction: bindActionCreators(schemaAction, dispatch),
        myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
        credentialProposalAction: bindActionCreators(credentialProposalAction, dispatch),
        OrgProfileAction: bindActionCreators(OrgProfileAction, dispatch)
    }
}

const negotiateCredential = connect(mapStateToProps, mapDispatchToProps)(NegotiateCredential);
export { negotiateCredential as NegotiateCredential };
