import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';

import images from "src/utils/images";
import TypeaheadComponent from './TypeaheadComponent';
import { t } from 'i18next';

export interface ICommentReportProps {
    isModalShow: boolean,
    closeModal: any,
    handleCommentReportSubmit?: any,
    commentList: string[]
    selectedReceivedCredData?: any,
    modalTitle: string,
    credentialName: string
}

export interface ICommentReportState {
    clearTypeAhead: boolean,
    selectedComment: string
}

export default class CommentReportComponent extends React.Component<ICommentReportProps, ICommentReportState> {
    constructor(props: ICommentReportProps) {
        super(props);

        this.state = {
            clearTypeAhead: false,
            selectedComment: ''
        }

        this.handleChangeComment = this.handleChangeComment.bind(this);
        this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this)
    }

    handleCancel() {
        this.setState({
            selectedComment: ''
        })
        this.props.closeModal()
    }

    handleChangeComment(selectedOptions: React.DOMAttributes<HTMLInputElement> | any) {
        let selectedData = selectedOptions[0];
        this.setState({
            selectedComment: selectedData,
        })
    }

    handleCommentSubmit() {
        const { selectedComment } = this.state
        const { selectedReceivedCredData } = this.props
        this.props.handleCommentReportSubmit(selectedReceivedCredData, selectedComment)
    }

    handleInputCommentChange() {
        this.setState({
            selectedComment: ''
        })
    }

    public render() {
        const { isModalShow, commentList, modalTitle, credentialName } = this.props;
        const { clearTypeAhead, selectedComment } = this.state

        return (
            <Modal isOpen={isModalShow} className="nb-modal animated slideInRight" toggle={this.props.closeModal}>

                <ModalBody>
                    <div className="row gutters-sm min-h-100">
                        <div className="col-md-12 col-lg-12 mb-30">
                            <div className="w-100">
                                <div className="card-header bg-white border-bottom-0">
                                    <h1 className="nb-title">{modalTitle}</h1>
                                </div>
                                <div className="card-body">
                                    <div className="mb-3">
                                        <h6>{t("screens:commentReportModal.promptQuestion")} {`"${credentialName}" ?`}</h6>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <p>{t("screens:commentReportModal.promptNote")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <div className="form-group">
                                                {

                                                    <TypeaheadComponent
                                                        data={commentList}
                                                        onChange={this.handleChangeComment}
                                                        onInputChange={this.handleInputCommentChange}
                                                        clearTypeahead={clearTypeAhead}
                                                        placeholder={`${t("screens:commentReportModal.select")}`}
                                                        disabled={false} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4">
                                            <button type="button"
                                                className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                data-dismiss="modal"
                                                onClick={(e) => this.handleCancel()}
                                            >{t("screens:commentReportModal.cancel")}</button>
                                        </div>
                                        <div className="col-sm-6">
                                            <button type="button"
                                                className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                data-dismiss="modal"
                                                onClick={(e) => this.handleCommentSubmit()}
                                                disabled={!selectedComment}
                                            >{t("screens:commentReportModal.submit")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
