import { t } from "i18next"
import _ from "lodash"
import { useEffect, useState } from "react";
import { AttributeDetails } from "src/types/common.interface";

export const PresentationDetails = ({ PresentationNegotiateReducer }: any) => {
    const { selectedPresentationRecord } = PresentationNegotiateReducer;

    const [connectionName, setConnectionName] = useState<string>('')
    const [requestedAttributes, setRequestedAttributes] = useState<any>([])
    const [requestedPredicates, setRequestedPredicates] = useState<any>([])
    const [errorMessage, setErrorMessage] = useState<string>('')

    useEffect(() => {
        const restrictionsArray: Array<any> = [];
        const requestedAttributesDetails: Array<any> = [];
        const requestedPredicatesDetails: Array<any> = [];

        const requestDetails: any = !_.isEmpty(selectedPresentationRecord) && JSON.parse(selectedPresentationRecord.presentationRequest);

        /* pseudo code for get all credential definition in one array from request details*/
        if (!_.isEmpty(selectedPresentationRecord)) {

            for (const attributeData in requestDetails?.requested_attributes) {
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_attributes, attributeData)) {
                    const element = requestDetails.requested_attributes[attributeData];

                    // check if requested attribute is self attested or not
                    if (element.restrictions && element.restrictions.length) {
                        // not a self attested attribute                        
                        element.restrictions.map((restrictionData: any) => {
                            // check for duplicate restrictions
                            if (!restrictionsArray.find((x) => JSON.stringify(x) === JSON.stringify(restrictionData))) {
                                restrictionsArray.push(restrictionData)
                            }
                        })
                    }
                    else {
                        // self attested attribute assign dummy restriction
                        if (!restrictionsArray.find((x) => JSON.stringify(x) === JSON.stringify({ cred_def_id: '' })))
                            restrictionsArray.push({ cred_def_id: '' })
                    }
                }
            }

            for (const predicate in requestDetails.requested_predicates) {
                let predicateData: any = {}
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_predicates, predicate)) {
                    const { restrictions, ...otherPredicateData } = requestDetails.requested_predicates[predicate];
                    predicateData = { ...otherPredicateData }
                    const proofRequestPredicates: any = {}

                    restrictions.map((restrictionData: any) => {
                        proofRequestPredicates['credentialName'] = Object.keys(restrictionData)[0] === 'cred_def_id' ?
                            (Object.values(restrictionData)[0] as string).split(':')[4] :
                            (Object.values(restrictionData)[0] as string).split(':')[2]
                        proofRequestPredicates['referentName'] = predicate
                        proofRequestPredicates[Object.keys(restrictionData)[0]] = Object.values(restrictionData)[0]
                        proofRequestPredicates['credentialFields'] = predicateData
                    })
                    requestedPredicatesDetails.push(proofRequestPredicates)
                }
            }

            setConnectionName(selectedPresentationRecord.connectionName)
        }

        /* pseudo code for display the attribute names as well as display the credential name*/
        for (const key of restrictionsArray) {
            const proofRequestAttributes: any = {}
            proofRequestAttributes[`${Object.keys(key)}`] = Object.values(key)[0]
            proofRequestAttributes['credentialId'] = ""
            proofRequestAttributes['presentationExchangeId'] = selectedPresentationRecord.presentationExchangeId

            const attributes: AttributeDetails[] = []

            for (const attributeData in requestDetails.requested_attributes) {
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_attributes, attributeData)) {
                    const element = requestDetails.requested_attributes[attributeData];

                    // eslint-disable-next-line no-loop-func
                    if (element.restrictions && element.restrictions.length) {
                        element.restrictions.map((restrictionData: any) => {

                            if (Object.values(restrictionData)[0] === Object.values(key)[0]) {
                                attributes.push({
                                    name: element.name,
                                    referentName: attributeData,
                                    reveal: true,
                                })
                            }
                        })
                    }
                    else {
                        if (Object.values(key)[0] === '')
                            attributes.push({
                                name: element.name,
                                referentName: attributeData,
                            })
                    }
                }
            }

            proofRequestAttributes['credentialType'] = Object.keys(key)[0] === 'cred_def_id' ? 'Credential Definition' : 'Schema'
            proofRequestAttributes['credentialName'] = Object.keys(key)[0] === 'cred_def_id' ?
                (Object.values(key)[0] as string).split(':')[4] :
                (Object.values(key)[0] as string).split(':')[2]

            proofRequestAttributes['credentialFields'] = (typeof attributes) === 'string' ? [attributes] : attributes

            requestedAttributesDetails.push(proofRequestAttributes);

        }

        setRequestedAttributes(requestedAttributesDetails)
        setRequestedPredicates(requestedPredicatesDetails)
        setErrorMessage(selectedPresentationRecord.errorMsg)
    }, [selectedPresentationRecord])

    return (
        <>
            <ul className="fa-ul nb-list" key={selectedPresentationRecord.presentationExchangeId}>
                <li className="remove-underline">
                    <i className="fa-li nb-ico nb-verification-dark"></i>
                    <span className="h5">{t("screens:presentProof.proofRequest")}</span>
                </li>
                <li className="pl-1 pr-0">
                    {requestedAttributes.length > 0 ?
                        <div className="row">
                            <div className="col-sm-12">
                                <label className="font-weight-bold nb-label">{t("screens:presentProof.requestedAttributes")}</label>
                            </div>
                        </div> :
                        <></>
                    }
                    {requestedAttributes.length > 0 && requestedAttributes.map((element: any, index: number) => {
                        return (
                            <div className="row ml-3" key={index}>
                                <div className="col-sm-12 mb-2">
                                    <i className="fa-li nb-ico nb-organization"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.givenName")}:</label>
                                    <label>{connectionName} </label>
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <i className="fa-li nb-ico nb-credential"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.credentialType")}:</label>
                                    <label>{element.credentialType}</label>
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <i className="fa-li nb-ico nb-credential"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.credentialName")}:</label>
                                    <label>{element.credentialName ?? '-'}</label>
                                </div>
                                <div className="col-sm-12 mb-2">
                                    <i className="fa-li nb-ico nb-attribute"></i>
                                    <label className="font-weight-bold">{t("screens:presentProof.attributes")}:</label>
                                    {element.credentialFields?.map((item: string, index: number) => {
                                        return (<span key={index} className="badge badge-light nb-badge nb-badge-blue mr-2">{Object.values(item)[0]}</span>)
                                    })}
                                </div>
                            </div>
                        )
                    })}
                    {requestedPredicates.length > 0 ?
                        <div className="row mt-3">
                            <div className="col-sm-12">
                                <label className="font-weight-bold nb-label">{t("screens:presentProof.requestedPredicates")}</label>
                            </div>
                        </div> :
                        <></>
                    }
                    {
                        requestedPredicates.length > 0 && requestedPredicates.map((predicate: any, pIndex: number) => {
                            return (
                                <div className="row ml-3" key={pIndex}>
                                    <div className="col-sm-6 mb-2">
                                        <i className="fa-li nb-ico nb-credential"></i>
                                        <label className="font-weight-bold">{t("screens:presentProof.credentialName")}:</label>
                                        <label>{predicate.credentialName}</label>
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <i className="fa-li nb-ico nb-attribute"></i>
                                        <span className="font-weight-bold">{t("screens:presentProof.condition")}: <span className="badge badge-light nb-badge nb-badge-blue mr-2">{predicate.credentialFields.name}</span> {predicate.credentialFields.p_type} {predicate.credentialFields.p_value}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </li>
                <li className="remove-underline">
                    <i className="fa-li nb-ico nb-verification-dark"></i>
                    <label className="font-weight-bold">{t("screens:presentationDecline.abandonedReason")}:</label>
                    <label className="text-danger">{errorMessage?.split(':')[1]}</label>
                </li>
            </ul>

            {_.isEmpty(selectedPresentationRecord) &&
                <>
                    <div className="card-body">
                        <div className="blank-credential-details-body">
                            <label className="">{t("screens:presentProof.noRequestReceived")}</label>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
