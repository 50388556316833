import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CredentialOfferList } from "../components/CredentialOfferList";
import { credentialOfferAction } from "../actions/credentialOfferAction";
import { issuanceAction } from "src/View/IssueCredential/actions/issuanceAction";
import { connectionAction } from "src/View/Connection/actions/connectionAction";
import { TaskActions } from "src/View/Task/actions/taskActions";
import { credentialAction } from "src/View/Credentials/actions/credentialAction";
import SuccessPage from "../components/SuccessPage";
import { t } from 'i18next';
import { myCredentialsAction } from "src/View/MyCredentials/action/myCredentialsAction";
import { CredentialOfferDetails } from "../components/CredentialOfferDetails";
import CreateCredentialOffer from "../components/CreateCredentialOffer";


/* Interface for Props variables*/
interface ICredentialOfferProps {
  CredentialOfferReducer: any,
  CredentialOfferAction: any,
  connectionAction: any,
  ConnectionReducer: any,
  issuanceAction: any,
  IssuanceReducer: any,
  LoginReducer: any,
  TaskReducers: any,
  TaskActions: any,
  credentialAction: any,
  CredentialReducer: any,
  myCredentialReducer: any,
  myCredentialsAction: any,
}

/* Interface for local states variables*/
interface ICredentialOfferState {
  showProofDetails: boolean,
  selectedProofDetails: any,
  selectedConnectionName: string,
  requestedAttributesDetails: any[],
  requestedPredicatesDetails: any[],
  page: number,
  itemPerPage: number,
  searchText: string,
  status: any,
  sortValue: any,
  sortBy: any,
  showAttributeValues: boolean
  isDefault: boolean,
  presentationExchangeId: string,
  selectedCredentials: any[],
  credentialsToReveal: any[],
  showCredentialDetails: boolean
}

class CredentialOffer extends React.Component<ICredentialOfferProps, ICredentialOfferState> {
  constructor(props: ICredentialOfferProps) {
    super(props)
    /* Initialization of state variables*/
    this.state = {
      showProofDetails: false,
      selectedProofDetails: {},
      selectedConnectionName: "",
      requestedAttributesDetails: [],
      page: 1,
      itemPerPage: 10,
      searchText: "",
      status: '',
      sortValue: '',
      sortBy: '',
      showAttributeValues: true,
      isDefault: true,
      presentationExchangeId: '',
      requestedPredicatesDetails: [],
      selectedCredentials: [],
      credentialsToReveal: [],
      showCredentialDetails: false
    }

    this.selectedOfferRecord = this.selectedOfferRecord.bind(this)
    this.createNewOffer = this.createNewOffer.bind(this)
  }

  /* Method to call the get presentation requests method */
  componentDidMount() {
    this.props.CredentialOfferAction.offerCredentialComponent()
  }

  /* Method used to show selected proof request details */
  selectedOfferRecord(item: any) {
    this.props.issuanceAction.showSelectedCredentialDetails(item)
    this.props.CredentialOfferAction.offerCredentialComponent()
    this.setState({
      showCredentialDetails: true
    })
  }

  createNewOffer() {
    this.props.CredentialOfferAction.offerCredentialComponent()
    this.setState({
      showCredentialDetails: false
    })
  }

  /* UI to display the all the child component */
  public render() {
    const { credentialReqData: credentialOfferList, selectedCredentialRecord } = this.props.IssuanceReducer
    let { showSuccessOfferCredentialComponent } = this.props.CredentialOfferReducer;
    const { showCredentialDetails } = this.state

    return (
      <>
        <div className="row gutters-sm min-h-100 animated fadeIn">
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">

                <h1 className="nb-title">{t("screens:credentialOffer.credentialOfferList")}</h1>
                <button className="btn btn-sm btn-nb-outline-primary float-right"
                  onClick={this.createNewOffer}>
                  <i className="fa fa-plus"></i>&nbsp;
                  {t("screens:credentialOffer.create")}
                </button>

              </div>
              <div className="card-body">
                {/* Component for display the proof request list */}
                <CredentialOfferList
                  credentialOfferList={credentialOfferList}
                  selectedOfferRecord={this.selectedOfferRecord}
                />
              </div>
            </div>
          </div>

          {showSuccessOfferCredentialComponent ?
            <div className="col-md-12 col-lg-6 mb-30">
              <SuccessPage {...this.props} />
            </div>
            :
            <div className="col-md-12 col-lg-6 mb-30">
              <div className="card nb-card h-100">
                {
                  showCredentialDetails
                    ? <CredentialOfferDetails selectedCredentialRecord={selectedCredentialRecord} />
                    : <CreateCredentialOffer {...this.props} />
                }
              </div>
            </div>
          }

        </div>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  const CredentialOfferReducer = state.CredentialOfferReducer;
  const LoginReducer = state.LoginReducer;
  const IssuanceReducer = state.IssuanceReducer;
  const ConnectionReducer = state.ConnectionReducer;
  const TaskReducers = state.TaskReducers;
  const CredentialReducer = state.CredentialReducer;
  const myCredentialReducer = state.myCredentialReducer

  return { CredentialOfferReducer, LoginReducer, IssuanceReducer, ConnectionReducer, TaskReducers, CredentialReducer, myCredentialReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    CredentialOfferAction: bindActionCreators(credentialOfferAction, dispatch),
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
    connectionAction: bindActionCreators(connectionAction, dispatch),
    TaskActions: bindActionCreators(TaskActions, dispatch),
    credentialAction: bindActionCreators(credentialAction, dispatch),
    myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
  }
}

const credentialOffer = connect(mapStateToProps, mapDispatchToProps)(CredentialOffer);
export { credentialOffer as CredentialOffer };
