import _ from 'lodash';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import durationData from '../durationData.json';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { t } from 'i18next';

let QRCode = require('qrcode.react');

export interface IScheduleTaskProps {
  TaskReducers: any,
  TaskActions: any,
  connectionAction: any,
  ConnectionReducer: any,
  credentialAction: any,
  CredentialReducer: any,
  WorkflowReducer: any,
  IssuanceReducer: any,
}

export interface IScheduleTaskState {
  key: any,
  taskData: any,
  duration: any,
  durationTypes: any,
  clearDurationTypeahead: boolean,
  clearCredDefTypeahead: boolean,
  clickedCredDef: any,
  copiedQE: boolean,
  disabledButton: boolean,
  attributeArray: Array<any>
}
export default class ScheduleTask extends React.Component<IScheduleTaskProps, IScheduleTaskState> {
  constructor(props: IScheduleTaskProps) {
    super(props);

    this.state = {
      key: 'basicInfo',
      taskData: {},
      duration: durationData.days,
      durationTypes: durationData.type,
      clearDurationTypeahead: false,
      clearCredDefTypeahead: false,
      clickedCredDef: {},
      copiedQE: false,
      attributeArray: [],
      disabledButton: true,
    }
    this.nextStep = this.nextStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this);
    this.handleCreDefChange = this.handleCreDefChange.bind(this);
    this.listView = this.listView.bind(this);
    this.handleDurationTypeChange = this.handleDurationTypeChange.bind(this);
    this.attributeChange = this.attributeChange.bind(this);
    this.disabbledButton = this.disabbledButton.bind(this); 
  }
  componentWillUnmount() {
    this.setState({
      key: 'basicInfo',
      taskData: {},
      duration: durationData.days,
      durationTypes: durationData.type,
      clearDurationTypeahead: false,
      clearCredDefTypeahead: false,
      clickedCredDef: {},
      copiedQE: false,
      attributeArray: [],
      disabledButton: true,
    })
    this.props.TaskActions.clearFormScheduleTask()
  }

  nextStep(key: string | any) {
    this.setState({ key });
  }

  handleChange(event: any) {
    let { name, value } = event.target;
    const { taskData } = this.state;

    taskData[name] = value;
    this.setState({
      taskData: taskData,
    });
    this.disabbledButton();
  }

  handleDurationChange(selectedOption: any) {
    let fields = this.state.taskData;
    if (selectedOption[0] && selectedOption[0].tag) {
      fields["duration"] = selectedOption[0].tag;
    } else {
      fields["duration"] = ''
    }
    this.setState({
      taskData: fields
    })
    this.disabbledButton();
  }

  handleDurationTypeChange(selectedOption: any) {
    let fields = this.state.taskData;
    if (selectedOption[0] && selectedOption[0].tag) {
      fields["durationTypes"] = selectedOption[0].tag;
    } else {
      fields["durationTypes"] = ''
    }
    this.setState({
      taskData: fields
    })
    this.disabbledButton();
  }

  createBasicInfo(key: any, event: any) {
    const { scheduleTaskData } = this.props.TaskReducers;
    let { taskData } = this.state;
    if (taskData.duration) {
      let duration = taskData.durationTypes === 'Permanent' ? taskData.durationTypes : taskData.duration + ' ' + taskData.durationTypes;
      taskData.duration = duration;
      delete taskData.durationTypes;
    }
    if (taskData.startDate) {
      taskData.startDate = new Date(taskData.startDate).toUTCString();
    } else if (taskData.date) {
      taskData.date = new Date(taskData.date).toUTCString()
    }
    let finalData = {
      workflowId: scheduleTaskData.id,
      repetition: scheduleTaskData.repetition,
      method: scheduleTaskData.method,
      attributes: taskData,
      credDefId: '',
      credDefDescription: '',
      credDefIssuerName: ''
    }

    this.props.TaskActions.createBasicInfo(finalData)
    this.setState({ key });
  }

  handleCreDefChange(selectedCredDefOptions: any) {
    let selectedData = selectedCredDefOptions[0];
    this.props.TaskActions.attributesData(selectedData);
    this.setState({
      clickedCredDef: selectedData,
      attributeArray: [],
    })
  }

  updateScheduleTask(key: any, event: any) {
    const { createdScheduleTask } = this.props.TaskReducers;
    let { taskData, clickedCredDef, attributeArray } = this.state;
    let updateData = {
      workflowId: createdScheduleTask.verificationWorkflow,
      repetition: createdScheduleTask.repetition,
      method: createdScheduleTask.method,
      attributes: taskData,
      credDefId: clickedCredDef.credentialDefinitionId,
      credDefAttributes: attributeArray,
      credDefDescription: clickedCredDef.description,
      credDefIssuerName: clickedCredDef.issuerDid
    }
    this.props.TaskActions.updateScheduleTask(updateData, createdScheduleTask.id);
    this.setState({ key, attributeArray: [] })
  }

  listView(event: any) {
    this.setState({
      taskData: {},
      duration: durationData,
      clearDurationTypeahead: false,
      clearCredDefTypeahead: false,
      clickedCredDef: {},
    })
    this.props.TaskActions.clearFormScheduleTask()
  }

  onCopiedQRCode(value: any) {
    switch (value) {
      case 'copiedQrCode':
        return this.setState({ copiedQE: true })
      default:
        return null
    }

  }

  onAfterCopiedQRCode = (event: React.MouseEvent<HTMLButtonElement> | any) => {
    setTimeout(() => {
      this.setState({
        copiedQE: false,
      });
    }, 1000);
    event.preventDefault();
  }

  attributeChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { value } = event.target;
    const { attributeArray } = this.state
    const tempArray: Array<any> = attributeArray
    if (!attributeArray.includes(value)) {
      tempArray.push(value);
    } else {
      const index: any = attributeArray.indexOf(value)
      tempArray.splice(index, 1);
    }
    this.setState({ attributeArray: tempArray });
  }

  handleStartDate(value: any) {
    const { taskData } = this.state;

    taskData['startDate'] = value._d
    this.setState({
      taskData: taskData,
    });
    this.disabbledButton();
  }

  handleDate(value: any) {
    const { taskData } = this.state;

    taskData['date'] = value._d
    this.setState({
      taskData: taskData,
    });
    this.disabbledButton();
  }

  disabbledButton() {
    const {scheduleTaskData} = this.props.TaskReducers;
    const {taskData} = this.state;
    let workflowAttributeData = !_.isEmpty(scheduleTaskData) && JSON.parse(scheduleTaskData.workflowAttributes);
    let duplicate = false;
    
    if(!_.isEmpty(workflowAttributeData) && workflowAttributeData.includes('startDate')) {
      if(taskData.durationTypes === 'Permanent'){
        duplicate = !taskData.startDate || !taskData.durationTypes
      }else {
        duplicate = !taskData.startDate || !taskData.durationTypes || !taskData.duration;
      }
    }else if(!_.isEmpty(workflowAttributeData) && workflowAttributeData.includes('date')){
      duplicate = !taskData.date
    }
    
    this.setState({
      disabledButton: duplicate
    })
  }

  public render() {

    const { key, taskData, duration, clearDurationTypeahead, clearCredDefTypeahead, clickedCredDef, durationTypes, attributeArray, disabledButton } = this.state;
    const { scheduleTaskData, updatedScheduleTask, getAllCredDefForInstantTaskList, selectedCredDefData } = this.props.TaskReducers;
    let workflowAttributeData = !_.isEmpty(scheduleTaskData) && JSON.parse(scheduleTaskData.workflowAttributes);
    return (
      <>
        <div className="col-md-12 col-lg-9 mb-30">
          <div className="card nb-card h-100 shadow">
            <div className="card-header bg-white border-bottom-0">
              <div className="row">
                <div className="col-sm-8">
                  <h1 className="nb-title">{t("screens:createNewtask.addTaskWorkflow")}</h1>
                </div>
                <div className="col-sm-4 text-right">
                  {!_.isEmpty(updatedScheduleTask) &&
                    <button type="button" className="btn"
                      onClick={(e) => this.listView(e)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="nav nav-tabs nb-tab-arrow" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className={key === 'basicInfo' || taskData.date !== '' ? "nav-link active" : "nav-link"} id={key} data-toggle="tab" href="#basicInfoContent" role="tab" aria-controls="basicInfoContent" aria-selected="true" aria-disabled={key === 'credentialInfoContent'}>{t("screens:createNewtask.basicInfo")}</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className={key === 'credentialInfoContent' || !_.isEmpty(clickedCredDef) ? "nav-link active" : "nav-link"} id={key} data-toggle="tab" href="#credentialInfoContent" role="tab" aria-controls="credentialInfoContent" aria-selected="false" aria-disabled={key === 'verificationMethodContent'}>{t("screens:createNewtask.credentialInfo")}</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className={key === 'verificationMethodContent' ? "nav-link active" : "nav-link"} id={key} data-toggle="tab" href="#verificationMethodContent" role="tab" aria-controls="verificationMethodContent" aria-selected="false" aria-disabled={key === 'verificationMethodContent'}>{t("screens:createNewtask.verificationMethod")}</a>
                    </li>
                  </ul>
                  <div className="tab-content pt-3" id="myTabContent">
                    <div className={key === 'basicInfo' ? "tab-pane fade show active" : "tab-pane fade show"} id="basicInfo" role="tabpanel" aria-labelledby="basicInfo">
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <div className="media">
                            <img src="assets/icons/basic-info.svg" className="mr-3" alt="..." />
                            <div className="media-body">
                              <div className="row">
                                <div className="col-sm-12 mb-3">
                                  <h1 className="mt-0">{t("screens:createNewtask.basicInfoTitle")}</h1>
                                  <p className="text-muted">
                                  {t("screens:createNewtask.messageBasicInfo")}
                                  </p>
                                </div>
                              </div>
                              {!_.isEmpty(workflowAttributeData) && workflowAttributeData.includes('title') &&
                                <div className="row mt-3 mb-3">
                                  <div className="col-sm-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <label className="nb-label">{t("screens:createNewtask.titleText")}</label>
                                        <input type="text" className="form-control" name='title'
                                          autoComplete="off"
                                          value={taskData.title}
                                          onChange={(e) => this.handleChange(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className="row mb-3">
                                <div className="col-sm-12">
                                  {!_.isEmpty(scheduleTaskData) && scheduleTaskData.repetition === "RecurringTasks" ?
                                    <button type="button" className="btn btn-lg mr-3 mb-3 btn-outline-secondary"
                                      disabled={true}
                                    >{t("screens:createNewtask.recurringTasks")}</button>
                                    :
                                    !_.isEmpty(scheduleTaskData) && scheduleTaskData.repetition === "SingleTask" ?
                                      <button type="button" className="btn btn-lg mr-3 mb-3 btn-outline-secondary"
                                        disabled={true}
                                      >{t("screens:createNewtask.singleTask")}</button>
                                      :
                                      !_.isEmpty(scheduleTaskData) && scheduleTaskData.repetition === "Both" ?
                                        <button type="button" className="btn btn-lg mr-3 mb-3 btn-outline-secondary"
                                          disabled={true}
                                        >{t("screens:createNewtask.both")}</button>
                                        :
                                        <></>
                                  }
                                  {!_.isEmpty(scheduleTaskData) && scheduleTaskData.method === "InPerson" ?
                                    <button type="button" className="btn btn-lg mr-3 mb-3 btn-outline-secondary"
                                      disabled={true}
                                    >{t("screens:createNewtask.inPerson")}</button>
                                    :
                                    !_.isEmpty(scheduleTaskData) && scheduleTaskData.method === "Virtual" ?
                                      <button type="button" className="btn btn-lg mr-3 mb-3 btn-outline-secondary"
                                        disabled={true}
                                      >{t("screens:createNewtask.virtual")}</button>
                                      :
                                      !_.isEmpty(scheduleTaskData) && scheduleTaskData.method === "Both2" ?
                                        <button type="button" className="btn btn-lg mr-3 mb-3 btn-outline-secondary"
                                          disabled={true}
                                        >{t("screens:createNewtask.both")}</button>
                                        :
                                        <></>
                                  }
                                </div>
                              </div>
                              <div className="row mb-3">
                                {!_.isEmpty(workflowAttributeData) && workflowAttributeData.includes('date') &&
                                  <div className="col-sm-5">
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="media">
                                          <img src="assets/icons/calender.svg" className="align-self-center mr-3" alt="" />
                                          <div className="media-body">
                                            <label className="nb-label">{t("screens:createNewtask.date")}</label>
                                            {/* <input type="datetime-local" className="form-control" name='date'
                                              autoComplete="off"
                                              value={taskData.date}
                                              onChange={(e) => this.handleChange(e)}
                                            /> */}
                                            <Datetime
                                              onChange={(e) => this.handleDate(e)}
                                              input={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {!_.isEmpty(workflowAttributeData) && workflowAttributeData.includes('startDate') &&
                                  <>
                                    <div className="col-sm-6">
                                      <div className="card mb-3">
                                        <div className="card-body">
                                          <div className="media">
                                            <img src="assets/icons/calender.svg" className="align-self-center mr-3" alt="" />
                                            <div className="media-body">
                                              <div className="row">
                                                <div className="col-sm-12">
                                                  <label className="nb-label">{t("screens:createNewtask.startDate")}</label>
                                                </div>
                                                <div className="col-sm-12">
                                                  {/* <input type="datetime-local" className="form-control" name='startDate'
                                                    autoComplete="off"
                                                    value={taskData.startDate}
                                                    onChange={(e) => this.handleChange(e)}
                                                  /> */}
                                                  <Datetime
                                                    onChange={(e) => this.handleStartDate(e)}
                                                    input={false}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }
                                {!_.isEmpty(workflowAttributeData) && workflowAttributeData.includes('duration') &&
                                  <div className="col-sm-6">
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="media">
                                          <img src="assets/icons/loader.svg" className="align-self-center mr-3" alt="" />
                                          <div className="media-body">
                                            <div className="row">
                                              <div className="col-sm-12">
                                                <label className="nb-label">{t("screens:createNewtask.selectDuration")}</label>
                                              </div>
                                            </div>
                                            <div className="row">
                                              {taskData.durationTypes !== 'Permanent' &&
                                                <div className="col-sm-6">
                                                  <Typeahead data={duration} onChange={this.handleDurationChange} onInputChange={this.handleDurationChange}
                                                    clearTypeahead={clearDurationTypeahead} placeholder="" disabled={false} />
                                                </div>
                                              }
                                              <div className="col-sm-6">
                                                <Typeahead data={durationTypes} onChange={this.handleDurationTypeChange} onInputChange={this.handleDurationTypeChange}
                                                  clearTypeahead={clearDurationTypeahead} placeholder="" disabled={false} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                              {!_.isEmpty(workflowAttributeData) && workflowAttributeData.includes("location") &&
                                <div className="row mb-3">
                                  <div className="col-sm-12">
                                    <div className="card">
                                      <div className="card-body">
                                        <label className="nb-label">{t("screens:createNewtask.location")}</label>
                                        <input type="text" className="form-control" name='location'
                                          autoComplete="off"
                                          value={taskData.location}
                                          onChange={(e) => this.handleChange(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className="row">
                                <div className="col-sm-12 text-right">
                                  <button type="button" className="btn btn-link"
                                    onClick={(e) => { this.createBasicInfo('credentialInfoContent', e) }}
                                    disabled={ disabledButton
                                      // !_.isEmpty(workflowAttributeData) && workflowAttributeData.includes('startDate') ?
                                      // (!taskData.startDate || !taskData.durationTypes) : !taskData.date 
                                      //  ? true : false
                                      }
                                  >
                                    <img src="assets/icons/next-arrow.svg" alt="" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={key === 'credentialInfoContent' ? "tab-pane fade show active" : "tab-pane fade"} id="credentialInfoContent" role="tabpanel" aria-labelledby="credentialInfoContent">
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <div className="media">
                            <img src="assets/icons/credential-pad.svg" className="mr-3" alt="..." />
                            <div className="media-body">
                              <h1 className="mt-0">{t("screens:createNewtask.credentialInfoTitle")}</h1>
                              <p>
                              {t("screens:createNewtask.messageTask")}
                                                        </p>
                              <div className="row mt-3">
                                <div className="col-sm-12 mb-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <label className="nb-label">{t("screens:createNewtask.credentialRequired")}</label>
                                      {!_.isEmpty(getAllCredDefForInstantTaskList) ?
                                        <Typeahead data={getAllCredDefForInstantTaskList} onChange={this.handleCreDefChange} onInputChange={this.handleCreDefChange}
                                          clearTypeahead={clearCredDefTypeahead} placeholder={t("screens:createNewtask.selectCredential")} disabled={false} />
                                        :
                                        <input type="text" className="form-control" name='credential'
                                          value={taskData.credential}
                                          readOnly={true}
                                        />
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 mb-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <label className="nb-label">{t("screens:createNewtask.attribute")}:</label>
                                      {attributeArray.length <= 4 ?
                                        ''
                                        :
                                        <h6 className="text-center text-danger">{t("screens:createNewtask.messageAttribute")}</h6>
                                      }
                                      {
                                        !_.isEmpty(selectedCredDefData) && selectedCredDefData.schema && !_.isEmpty(selectedCredDefData.schema.attributes) &&
                                        selectedCredDefData.schema.attributes.map((value: any, index: any) => {
                                          return (
                                            <ul className="list-group list-group-flush">
                                              <li className="list-group-item p-0 border-bottom-0">
                                                <label className="align-self-center" htmlFor={JSON.parse(value).name}>
                                                  {JSON.parse(value).name}
                                                  <input type="checkbox" className="float-right mt-2" id={JSON.parse(value).name} key={index}
                                                    name="attributes"
                                                    value={JSON.parse(value).name}
                                                    onChange={(e) => this.attributeChange(e)}
                                                  /></label>
                                              </li>
                                            </ul>
                                          )
                                        })

                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 mb-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <label className="nb-label">{t("screens:createNewtask.credentialDefinition")}</label>
                                      <input type="text" className="form-control" name='credentialDefinition'
                                        value={!_.isEmpty(clickedCredDef) ? clickedCredDef.credentialDefinitionId : ''}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 mb-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <label className="nb-label">{t("screens:createNewtask.issuer")}</label>
                                      <input type="text" className="form-control" name='issuerName'
                                        value={!_.isEmpty(clickedCredDef) ? clickedCredDef.issuerDid : ''}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 text-right">
                                  <button type="button" className="btn btn-link"
                                    onClick={(e) => { this.updateScheduleTask('verificationMethodContent', e) }}
                                    disabled={!_.isEmpty(clickedCredDef) && attributeArray.length <= 4 && attributeArray.length > 0 ? false : true}
                                  >
                                    <img src="assets/icons/next-arrow.svg" alt="" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={key === 'verificationMethodContent' ? "tab-pane fade show active" : "tab-pane fade"} id="verificationMethodContent" role="tabpanel" aria-labelledby="verificationMethodContent">
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <div className="media">
                            <img src="assets/icons/verification-trigger.svg" className="mr-3" alt="..." />
                            <div className="media-body">
                              <h1 className="mt-0">{t("screens:createNewtask.verificationTrigger")}</h1>
                              <p>
                                {t("screens:createNewtask.messageVerify")} </p>
                              <div className="row mt-3 mb-3">
                                <div className="col-sm-2">

                                </div>
                                <div className="col-sm-8">
                                  <div className="card h-100">
                                    <div className="card-body text-center">
                                      <h6>{t("screens:createNewtask.qRcode")}</h6>
                                      {!_.isEmpty(updatedScheduleTask) &&
                                        <QRCode value={!_.isEmpty(updatedScheduleTask) ? updatedScheduleTask.proofRequestUrl : ''} size={200} />
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 align-self-center">
                                  <CopyToClipboard
                                    text={!_.isEmpty(updatedScheduleTask) && updatedScheduleTask.proofRequestUrl}
                                    onCopy={() => this.onCopiedQRCode('copiedQrCode')}
                                  >
                                    <div className="input-group-append">
                                      <button className="btn nb-link" type="button" onClick={this.onAfterCopiedQRCode}>
                                        {this.state.copiedQE ? (
                                          <span className="text-success">{t("screens:createNewtask.copied")}</span>
                                        ) : <i className="far fa-copy" />}
                                        {this.state.copiedQE ? '' : `${t("screens:createNewtask.copy")}`
                                        }
                                      </button>
                                    </div>
                                  </CopyToClipboard>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

