import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { workflowAction } from '../actions/workflowAction';
import { WorkflowList } from '../components/workflowList';

export interface IWorkflowProps {
  workflowAction: any,
  WorkflowReducer: any,
}

export interface IWorkflowState {
}

class Workflow extends React.Component<IWorkflowProps, IWorkflowState> {
  constructor(props: IWorkflowProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <>
          <WorkflowList {...this.props} />
        
      </>
    );
  }
}
function mapStateToProps(state: any) {
  const WorkflowReducer = state.WorkflowReducer;
  return { WorkflowReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    workflowAction: bindActionCreators(workflowAction, dispatch)
  }
}

const connectedWorkflow = connect(mapStateToProps, mapDispatchToProps)(Workflow);
export { connectedWorkflow as Workflow };
