import * as React from 'react';
import { connect } from 'react-redux';
import { LoginMonitoringList } from '../components/LoginMonitoringList';

export interface ILoginMonitoringProps {
}

export interface ILoginMonitoringState {
}

class LoginMonitoring extends React.Component<ILoginMonitoringProps, ILoginMonitoringState> {
  constructor(props: ILoginMonitoringProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <>
       <LoginMonitoringList/>
      </>
    );
  }
}



function mapStateToProps(state: any) {
    return {
    }
  }
  
  function mapDispatchToProps(dispatch: any) {
    return {
    }
  }
  
  const connectedLoginMonitoring = connect(mapStateToProps, mapDispatchToProps)(LoginMonitoring);
  export { connectedLoginMonitoring as LoginMonitoring };
