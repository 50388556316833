import { organizationConst, registerConst, userConstants } from "../../types";

const initialState = {
  selectedRecord: {},
  orgData: {},
  countries: [],
  showSuccessPage: false,
  states: []
}

export function RegisterReducer(state = initialState, action: any) {
  switch (action.type) {
    case registerConst.CLEAR_FORM:
      return Object.assign({}, state, {
        selectedRecord: {},
      })
    case organizationConst.GET_REGISTRY_DETAILS:
      return Object.assign({}, state, {
        orgData: action.payload.data.data,
        showSuccessPage: false,
      })
    case organizationConst.SHOW_SUBMIT_PAGE:
      return Object.assign({}, state, {
        showSuccessPage: true,
      })
    case organizationConst.HIDE_SUBMIT_PAGE:
      return Object.assign({}, state, {
        showSuccessPage: false,
      })
    case organizationConst.GET_ALL_COUNTRIES:
      return Object.assign({}, state, {
        countries: action.payload,
        showSuccessPage: false,
      })
    case organizationConst.GET_ALL_STATES:
      return Object.assign({}, state, {
        states: action.payload,
        showSuccessPage: false,
      })
    default:
      return state
  }
}

