import { chats } from "../../types";

const initialState = {
    chatsConnectionList: {},
    selectedChatConnection: {},
    totalConnections: 0,
    isNewMessageReceived: false,
    availableCredentialList: {},
    hasClaimPreviousPage: false,
    hasClaimNextPage: false,
    lastClaimPage: 0,
    nextClaimPage: 0,
    totalClaimItems: 0,
    previousClaimPage: 0,
    availableCredentialCount: 0,
    receivedCredentialCount: 0,
    isReadyToShowSelectedClaimCredential: false,
    selectedClaimCredential: {},
    issueClaimCredentialResponse: {},
    messageRecords: {}
}
export function ChatsReducer(state = initialState, action: any) {
    switch (action.type) {
        case chats.CHATS_CONNECTION_LIST:
            console.log("in reducer---", action.payload)
            return Object.assign({}, state, {
                chatsConnectionList: action.payload,
                selectedChatConnection: action.payload[0],
                totalConnections: action.totalConnections,
                isNewMessageReceived: false,
                fromPage: action.page,
                hasPreviousPage: action.hasPreviousPage,
                hasNextPage: action.hasNextPage,
                lastPage: action.lastPage,
                nextPage: action.nextPage,
            });
        case chats.SELECT_NEW_CONNECTION:
            return Object.assign({}, state, {
                selectedChatConnection: action.payload,
            });
        case chats.CHATS_SEND_BASIC_MESSAGE:
            return Object.assign({}, state, {
                chatsConnectionList: action.chatsConnectionList,
            });
        case chats.UPDATE_UNREAD_MESSAGE_STATUS:
            return Object.assign({}, state, {
                chatsConnectionList: action.chatsConnectionList,
            });
        case chats.ON_NOTIFICATION_UPDATE_LIST:
            return Object.assign({}, state, {
                selectedChatConnection: action.payload,
            });
        case chats.UNMOUNT_FROM_CHATS_DETAILS:
            return Object.assign({}, state, {
                chatsConnectionList: initialState.chatsConnectionList,
                selectedChatConnection: initialState.selectedChatConnection
            });
            // return initialState
        case chats.HIGHLIGHT_NOTIFICATION_STATUS:
            return Object.assign({}, state, {
                isNewMessageReceived: true,
                // selectedChatConnection: action.payload,
            });
        case chats.IMPORT_CLAIM_CREDENTIAL_LIST:
            return Object.assign({}, state, {
                availableCredentialList: action.payload.data,
                hasClaimPreviousPage: action.payload.hasPreviousPage,
                hasClaimNextPage: action.payload.hasNextPage,
                lastClaimPage: action.payload.lastPage,
                nextClaimPage: action.payload.nextPage,
                totalClaimItems: action.payload.totalItems,
                previousClaimPage: action.payload.previousPage,
                availableCredentialCount: action.availableCredentialCount,
                receivedCredentialCount: action.receivedCredentialCount,
                isReadyToShowSelectedClaimCredential: false
            })
        case chats.SELECTED_CLAIM_CREDENTIAL_DETAILS:
            return Object.assign({}, state, {
                isReadyToShowSelectedClaimCredential: true,
                selectedClaimCredential: action.payload
            });
        case chats.ISSUE_AVAILABLE_CLAIM_CREDENTIAL:
            return Object.assign({}, state, {
                issueClaimCredentialResponse: action.payload,
                isReadyToShowSelectedClaimCredential: false
            });
        case chats.GET_MESSAGES_BY_CONNECTION:
            return Object.assign({}, state, {
                messageRecords: action.payload
            });


        default:
            return state;
    }
}