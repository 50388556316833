import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { schemaAction } from '../actions/schemaAction';

export interface IPADefaultSchemaProps {
  schemaAction: any,
  SchemaReducer: any,
}
export interface IPADefaultSchemaState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  schemaSortBy: string,
}

class PADefaultSchema extends React.Component<IPADefaultSchemaProps, IPADefaultSchemaState> {
  constructor(props: IPADefaultSchemaProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      schemaSortBy: '',
    }
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, schemaSortBy } = this.state;

    this.props.schemaAction.getDefaultSchema(
      defaultSearchFields,
      sortingOrder,
      schemaSortBy,
      item_perPage,
      pageNo
    );
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, schemaSortBy } = this.state;
    this.props.schemaAction.getDefaultSchema(
      defaultSearchFields,
      sortingOrder,
      schemaSortBy,
      item_perPage,
      page
    );
  }

  sorting = (schemaSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', schemaSortBy: schemaSortBy }) : this.setState({ sortingOrder: 'DESC', schemaSortBy: schemaSortBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.schemaAction.getDefaultSchema(
      defaultSearchFields,
      sortValue,
      schemaSortBy,
      item_perPage,
      pageNo
    );
  }

  showDetails(schema: any) {
    this.props.schemaAction.selectedDefaultSchemas(schema)
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement> | any) => {
    const { pageNo, item_perPage, sortingOrder, schemaSortBy } = this.state
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.schemaAction.getDefaultSchema(
        event.target.value,
        sortingOrder,
        schemaSortBy,
        item_perPage,
        pageNo
      );
    }
  }



  public render() {
    const { tHeadSchema, defaultSchema, totalDefaultSchemas, selectedDefaultSchema, showDetailsofDefaultSchema } = this.props.SchemaReducer
    return (
      <>
        <div className="row animated fadeIn">
          <div className="col-lg-12 col-xl-5">
            <div className="card nb-card-noborder">
              <div className="card-header bg-white">
                <div className="card-title m-0">
                  <h1>Default Schema</h1>
                </div>
              </div>
              <div className="card-body">
                <TableComponents
                  onChangePage={this.onChangePage}
                  showElementDetails={this.showDetails}
                  tHead={tHeadSchema}
                  tableContent={defaultSchema}
                  totalRecords={totalDefaultSchemas}
                  selectedRecord={selectedDefaultSchema}
                  sorting={this.sorting}
                  searching={this.onSearch}
                />
              </div>
            </div>
          </div>
          {
            <div className="col-lg-12 col-xl-7">
              <div className="card nb-card-noborder">
                <div className="card-header bg-white">
                  <div className="card-title m-0">
                    <h1>Schema Layout</h1>
                  </div>
                </div>
                <form action="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card mb-3 bg-white">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Schema Name: {!_.isEmpty(selectedDefaultSchema) && selectedDefaultSchema.schemaName}</label>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Version: {!_.isEmpty(selectedDefaultSchema) && selectedDefaultSchema.schemaVersion}</label>
                                </div>
                              </div>
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label>Schema Items:</label>
                                  <div>
                                    {
                                      !_.isEmpty(selectedDefaultSchema) && selectedDefaultSchema.attributes.map((value: any, index: any) => {
                                        return (
                                          <div className="row" key={index}>
                                            <div className="col-sm-12">
                                              <div className="input-group mb-3" key={index}>
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text" id="basic-addon1">{JSON.parse(value).value ? JSON.parse(value).value : 'Format'}</span>
                                                </div>
                                                <input type="text" className="form-control bg-white"
                                                  placeholder="Value" aria-label="value"
                                                  aria-describedby="basic-addon1"
                                                  key={index} value={JSON.parse(value).name} disabled />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const SchemaReducer = state.SchemaReducer;
  return {
    SchemaReducer
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    schemaAction: bindActionCreators(schemaAction, dispatch),
  }
}

const connectedPADefaultSchema = connect(mapStateToProps, mapDispatchToProps)(PADefaultSchema);
export { connectedPADefaultSchema as PADefaultSchema };
