import _ from 'lodash';
import * as React from 'react';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { RoleType } from '../../types';
import { filterInternalIssuers, tHeadForEditInternalIssuersVerifiers, tHeadForInternalIssuersVerifiers } from '../constants';
import { InternalIssuerVerifierOptions } from '../interfaces';

export interface IInternalIssuersListProps {
  IssuerActions: any,
  IssuersReducers: any,
  editInternalIssuerVerifierData?: any,
}

export interface IInternalIssuersListState {
  internalIssuerOptions: InternalIssuerVerifierOptions
}

export default class InternalIssuersList extends React.Component<IInternalIssuersListProps, IInternalIssuersListState> {
  constructor(props: IInternalIssuersListProps) {
    super(props);

    this.state = {
      internalIssuerOptions: {
        defaultSearchFields: '',
        itemsPerPage: 10,
        pageNo: 1,
        sortingOrder: 'DESC',
        internalIssuerSortBy: '',
        filterStatus: 'all',
        type: RoleType.ISSUER_VERIFIER
      }
    }
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.viewInternalIssuerVerifiers = this.viewInternalIssuerVerifiers.bind(this);
  }
  
  onChangePage(page: any) {
    const { internalIssuerOptions } = this.state;
    internalIssuerOptions.pageNo = page
    this.props.IssuerActions.getInternalIssuersVerifiers(internalIssuerOptions);
  }

  showDetails(item: object | any) {
    this.props.IssuerActions.selectInternalIssuers(item);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { internalIssuerOptions } = this.state;
    if (event.key === 'Enter') {
      internalIssuerOptions.defaultSearchFields = event.target.value
      this.props.IssuerActions.getInternalIssuersVerifiers(internalIssuerOptions);
      this.setState({ internalIssuerOptions })
    }
  }

  sorting = (internalIssuerSortBy: string) => {
    const { internalIssuerOptions } = this.state;

    if (internalIssuerOptions.sortingOrder === 'DESC') {
      internalIssuerOptions.sortingOrder = 'ASC'
    }
    else {
      internalIssuerOptions.sortingOrder = 'DESC'
    }
    internalIssuerOptions.internalIssuerSortBy = internalIssuerSortBy

    this.props.IssuerActions.getInternalIssuersVerifiers(internalIssuerOptions);
  }


  refresh = (event: any) => {
    const initialInternalIssuerOptions = {
      defaultSearchFields: '',
      itemsPerPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      internalIssuerSortBy: '',
      filterStatus: 'all',
      type: RoleType.ISSUER_VERIFIER
    }

    this.props.IssuerActions.getInternalIssuersVerifiers(initialInternalIssuerOptions);
  }

  viewInternalIssuerVerifiers(item: object | any) {
    this.props.IssuerActions.viewInternalIssuerVerifiers(item);
  }


  filter = (filterValue: any) => {
    const { internalIssuerOptions } = this.state;
    internalIssuerOptions.filterStatus = filterValue
    this.props.IssuerActions.getInternalIssuersVerifiers(internalIssuerOptions);
  }

  public render() {
    const {
      internalIssuerVerifierData,
      totalInternalIssuersVerifiers,
      editInternalIssuerVerifier,
      selectedInternalIssuerVerifier,
      listViewOfConnection,
      viewInternalIssuerVerifier
    } = this.props.IssuersReducers;

    return (
      <>
        {
          <TableComponents
            onChangePage={this.onChangePage}
            showElementDetails={editInternalIssuerVerifier && !listViewOfConnection ? this.viewInternalIssuerVerifiers : this.showDetails}
            tHead={editInternalIssuerVerifier && !listViewOfConnection ? tHeadForEditInternalIssuersVerifiers : tHeadForInternalIssuersVerifiers}
            tableContent={internalIssuerVerifierData}
            totalRecords={totalInternalIssuersVerifiers}
            selectedRecord={
              editInternalIssuerVerifier && !listViewOfConnection && !_.isEmpty(this.props.editInternalIssuerVerifierData) ? this.props.editInternalIssuerVerifierData :
                !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier :
                  selectedInternalIssuerVerifier
            }
            sorting={this.sorting}
            searching={this.onSearch}
            refresh={this.refresh}
            filter={filterInternalIssuers}
            onChangeFilter={this.filter}

          />
        }
      </>
    );
  }
}
