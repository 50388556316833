import * as React from 'react';
import RegistrationLayout from '../../Register/components/RegistrationLayout';
import DefaultCredDefSuccess from './DefaultCredDefSuccess';
/* Interface for Props variables*/
export interface ISettingUpDashboardProps {
  loginAction: any,
  LoginReducer: any,
  createWalletAction: any,
  CreateWalletReducer: any,
  setDIDAction: any,
  setDIDReducer: any,
}
/* Interface for local states variables*/
export interface ISettingUpDashboardState {
}

export default class SettingUpDashboard extends React.Component<ISettingUpDashboardProps, ISettingUpDashboardState> {
  constructor(props: ISettingUpDashboardProps) {
    super(props);
    /* Initialization of state variables*/
    this.state = {
    }
  }

  public render() {
    const {
      showMsgLoginCredef,
      showMsgPassportCredDef,
      showMsgDriversLicenseCredDef
    } = this.props.setDIDReducer;

    return (
      <>
        <RegistrationLayout>
          <div className="row justify-content-center">
            <>
              <div className="col-sm-12 mb-3 text-center">
                <img src="assets/icons/waiting.svg" className="img img-fluid" alt="Waiting..." />
              </div>
              <div className="col-sm-12 text-center mb-5">
                <h1 className="mt-3">Setting Up Your Dashboard, Please Wait....</h1>
              </div>
              <div className="col-sm-12 col-lg-6">
                <h1 className="mb-2">
                  {
                    showMsgLoginCredef ?
                      <i className="fas fa-check-circle text-success-light mr-3"></i>
                      :
                      <i className="far fa-circle text-success mr-3"></i>
                  }
                  Create Login Credential Definition
                </h1>
                <h1 className="mb-2">
                  {console.log(`showMsgPassportCredDef`, showMsgPassportCredDef)}
                  {
                    showMsgPassportCredDef ?
                      <i className="fas fa-check-circle text-success-light mr-3"></i>
                      :
                      <i className="far fa-circle text-success mr-3"></i>
                  }
                  Create KYC Passport VCs
                </h1>
                <h1 className="mb-2">
                  {
                    showMsgDriversLicenseCredDef ?
                      <i className="fas fa-check-circle text-success-light mr-3"></i>
                      :
                      <i className="far fa-circle text-success mr-3"></i>
                  }
                  Create KYC Drivers License VCs
                </h1>
              </div>
            </>
          </div>
        </RegistrationLayout>
      </>
    );
  }
}
