import { t } from 'i18next';
 export const tHeadCredential = [{
    'name': `${t("screens:createNewCredential.headCredentialName")}`,
    'label': "tag"
  }, {
    'name': `${t("screens:createNewCredential.headDateCreated")}`,
    'label': "createDateTime"
  }, {
    'name': '',
    'label': "view"
  }];

  export const tHeadInternalCredential = [{
    'name': `${t("screens:internalCredentialList.headCredentialName")}`,
    // 'name':  `{t("screens:internalCredentialList.titleCredentialName")}`,
    'label': "tag"
  }, {
    'name': `${t("screens:internalCredentialList.headIssuer")}`,
    'label': "issuerNames"
  }, {
    'name': `${t("screens:internalCredentialList.headDept")}`,
    'label': "issuerDept"
  }, {
    'name': `${t("screens:internalCredentialList.headIssuance")}`,
    'label': "issuance"
  }, {
    'name': '',
    'label': "details"
  }];  
  
  export const tHeadHolderDetails = [{
    'name': `${t("screens:internalCredentialList.headHolder")}`,
    'label': "holderName"
  }, {
    'name': `${t("screens:internalCredentialList.headIssuedDate")}`,
    'label': "createDateTime"
  }, {
    'name': `${t("screens:internalCredentialList.headRoleName")}`,
    'label': "holderSource"
  }, {
    'name': `${t("screens:internalCredentialList.headProof")}`,
    'label': "holderProof"
  },  {
    'name': `${t("screens:internalCredentialList.headStatus")}`,
    'label': "status"
  },
];

export const filterInternalCredList = [{
  'name': `${t("screens:internalCredentialList.constAll")}`,
  'tag' : 'all'
}, {
  'name': `${t("screens:internalCredentialList.constRevocable")}`,
  'tag' : 'revoke'
}, {
  'name': `${t("screens:internalCredentialList.constNonRevocable")}`,
  'tag' : 'nonRevoke'
}
];

export const filterHolderList = [{
  'name': 'All',
  'tag' : 'all'
}, {
  'name': 'Active',
  'tag' : 'active'
}, {
  'name': 'Revocable',
  'tag' : 'revoked'
}
];

export const filterDefaultCredentials = [{
  'name': `${t("screens:issueCredentials.constAll")}`,
  'tag' : 'all'
}, {
  'name': `${t("screens:issueCredentials.constRevocable")}`,
  'tag' : 'revoke'
}, {
  'name': `${t("screens:issueCredentials.constNonRevocable")}`,
  'tag' : 'nonRevoke'
}
];

export const filterCredentials = [{
  'name': `${t("screens:createNewCredential.constAll")}`,
  'tag' : 'all'
}, {
  'name': `${t("screens:createNewCredential.constRevocable")}`,
  'tag' : 'revoke'
}, {
  'name': `${t("screens:createNewCredential.constNonRevocable")}`,
  'tag' : 'nonRevoke'
}
];
