import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, platformConfig } from "../../types";

export const PlatformConfigActions = {
  getPlatformConfigDetails() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/admin/platform-config`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(platformConfigData => {
            dispatch({
              type: platformConfig.GET_PLATFORM_CONFIG_DETAILS,
              payload: platformConfigData,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },
}