import { multiuseConnectionConst } from "../../types";
import { t } from 'i18next';

const initialState = {
    qrData: "",
    multiuseList: [],
    totalItems: 0,
    selectMultiuse: {},
    tHead: [],
    inviteNewMultiuse: false,
    emailSent: false,
    showErrorMessage: false,
    showErrorMessageName: false,
}

export function MultiuseReducer(state = initialState, action: any) {
    let tHead = [{
        'name': `${t("screens:invitationMultiuse.nameHead")}`,
        'label': "invitationName"
    }, {
        'name': `${t("screens:invitationMultiuse.statusHead")}`,
        'label': "status"
    }, {
        'name': `${t("screens:invitationMultiuse.emailHead")}`,
        'label': "email"
    }, {
        'name': `${t("screens:invitationMultiuse.typeHead")}`,
        'label': "type"
    }, {
        'name': `${t("screens:invitationMultiuse.dateHead")}`,
        'label': "createDateTime"
    }
    ];

    switch (action.type) {
        case multiuseConnectionConst.GET_MULTIUSE_QR:
            return Object.assign({}, state, {
                qrData: action.payload,
                showErrorMessage: false,
            });
        case multiuseConnectionConst.GET_MULTIUSE_LIST:
            return Object.assign({}, state, {
                multiuseList: action.tmpArray,
                totalItems: action.payload.data.data.totalItems,
                tHead: tHead,
                selectHolder: {},
                showErrorMessageName: false,
                showErrorMessage: false,
            });
        case multiuseConnectionConst.SELECT_MULTIUSE:
            return Object.assign({}, state, {
                selectMultiuse: action.payload,
            });
        case multiuseConnectionConst.CLEAR_SELECTION:
            return Object.assign({}, state, {
                selectMultiuse: {},
                inviteNewMultiuse: false,
            });
        case multiuseConnectionConst.SHOW_NEW_INVITE:
            return Object.assign({}, state, {
                inviteNewMultiuse: true,
                emailSent: false,
                showErrorMessage: false,
            });
        case multiuseConnectionConst.MULTIUSE_SENT:
            return Object.assign({}, state, {
                multiuseSent: true,
                showErrorMessage: false,
            });
        case multiuseConnectionConst.INVITE_NEW_MULTIUSE:
            return Object.assign({}, state, {
                inviteNewMultiuse: false,
            });
        default:
            return state
    }
}

