import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notificationAction } from '../actions/notificationAction'
import { NotificationList } from '../components/NotificationList';
import { socket, SocketContext } from 'src/contexts/SocketContext';
import _ from 'lodash';

export interface INotificationProps {
  notificationAction: any,
  NotificationReducer: any,
}
export interface INotificationState {
  page: number,
  itemPerPage: number,
  searchText: string
}

class Notification extends React.Component<INotificationProps, INotificationState> {
  static contextType?= SocketContext

  constructor(props: INotificationProps) {
    super(props);

    this.state = {
      page: 1,
      itemPerPage: 10,
      searchText: ""
    }
  }

  async componentDidMount() {
    await this.connectSocket()
    console.log('componentDidMount - Notification ')
  }

  async connectSocket() {
    const { page, itemPerPage, searchText } = this.state;

    this.getAllNotificationsList(page, itemPerPage, searchText);
    if (socket) {
      socket.on('notification', (socketId, payload) => {
        console.log(`Notification - socketId: ${socketId}  payload: ${JSON.stringify(payload)}`);
        if (payload && payload.data) {
          if (payload.data.type === 'Notification') {
            console.log(`Notification - receive Notification`)
            this.props.notificationAction.getCountNotification()    //TODO SOCKET UPDATE COUNT 
            this.getAllNotificationsList(page, itemPerPage, searchText);
          }
        }
      })
    } else {
      console.log("Could not estalished a connection")
    }
  }
  getAllNotificationsList(page: number, itemPerPage: number, searchText: string) {
    this.props.notificationAction.getAllNotificationsList(page, itemPerPage, searchText);
  }
  public render() {
    const { notificationList, totalNotifications } = this.props.NotificationReducer
    return (
      <>
        <NotificationList
          notificationList={notificationList}
          totalNotifications={totalNotifications}
        />
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const NotificationReducer = state.NotificationReducer;
  // const LoaderReducer = state.LoaderReducer
  return {
    NotificationReducer,
    // LoaderReducer,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    notificationAction: bindActionCreators(notificationAction, dispatch),
  }
}

const connectedNotification = connect(mapStateToProps, mapDispatchToProps)(Notification);
export { connectedNotification as Notification };