import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { EditAttributeComponent } from "./EditAttributesComponent";
import { SchemaDateSuffix } from "src/View/Schema/models/schema.enum";
import { displayDateFormat } from "src/utils/date.utils";


export const ReProposeCredential = ({ selectedCredentialRecord, problemReport, handleCredentialProposal, handleCancel }: any) => {

    const { credentialProposalDict, credentialDefinitions, connection, credentialExchangeId } = selectedCredentialRecord

    const attributes = !_.isEmpty(selectedCredentialRecord) && JSON.parse(credentialProposalDict).credential_proposal.attributes;
    const selectedSchemaDefinition = !_.isEmpty(selectedCredentialRecord) && credentialDefinitions.schema.attributes
    const selectedSchemaAttributes: any[] = []
    selectedSchemaDefinition.map((label: string) => {
        selectedSchemaAttributes.push(JSON.parse(label))
    })
    // console.log('attributes', attributes)
    // console.log('selectedSchemaDefinition', selectedSchemaDefinition)

    const [attributeValues, setAttributeValues] = useState<any[]>([]);
    const [textErrorIndexes, setTextErrorIndexes] = useState<number[]>([]);
    const [imageErrorIndexes, setImageErrorIndexes] = useState<number[]>([])

    useEffect(() => {
        if (attributes) {
            attributes.map((item: any) => {
                
                if((item.name).includes(SchemaDateSuffix.DATE_SUFFIX)){
                    item.value = displayDateFormat(item.value) 
                }
            })
            setAttributeValues(attributes);
        }
    }, []);

    const handleChange = (index: number, event: any) => {
        const { value } = event.target
        let formatError = false
        const updateAttributes = attributeValues
        updateAttributes[index].value = value;

        // console.log(selectedSchemaAttributes[index].value)
        const dataType = selectedSchemaAttributes[index].value

        if (dataType === 'DD/MM/YYYY' && (!/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(updateAttributes[index].value))) {
            formatError = true
        }

        if (dataType === 'number' && (!/^[0-9]*$/.test(updateAttributes[index].value))) {
            formatError = true
        }

        if (updateAttributes[index].value === '') {
            formatError = false
        }

        if (formatError && !textErrorIndexes.includes(index)) {
            setTextErrorIndexes([...textErrorIndexes, index]);
        }

        if (!formatError) {
            const indexFound = textErrorIndexes.indexOf(index);
            if (indexFound > -1) {
                setTextErrorIndexes(currentIndexes => currentIndexes.filter((_, i) => i !== indexFound));
            }
        }


        setAttributeValues([...updateAttributes])
    }

    const handleChangeImage = (index: number, event: any) => {
        const updateAttributes = attributeValues

        const iFile = event.target.files

        const indexFound = imageErrorIndexes.indexOf(index);

        if (iFile && iFile.length > 0) {
            let reader = new FileReader();
            const fSize = iFile[0].size
            const file = Math.round((fSize / 1024));

            // The size of the file.
            if (file >= 100) {
                if (indexFound === -1) {
                    setImageErrorIndexes([...imageErrorIndexes, index])
                }
                updateAttributes[index].value = '';
            }
            else {
                updateAttributes[index].value = event.target.value;
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () => {
                    updateAttributes[index].value = reader.result
                    setAttributeValues([...updateAttributes])
                };
                if (indexFound > -1) {
                    setImageErrorIndexes(currentIndexes => currentIndexes.filter((_, i) => i !== indexFound));
                }
            }
        }
    }

    const handleRePropose = async (credentialExchangeId: string) => {

        let schemaAttribute: any = [];

        selectedSchemaAttributes.forEach((attribute: any, idx: number) => {
            let jsonObj = {
                "name": attribute.name,
                "value": (attributeValues[idx].value === undefined || attributeValues[idx].value === null || attributeValues[idx].value === "") 
                    ? " "
                    :(attribute.name).includes('_dateint') 
                        ? (attributeValues[idx].value).split("/").reverse().join("") 
                        : attributeValues[idx].value 
            }
            if (attribute.value === 'image') {
                Object.assign(jsonObj, { "mime-type": "image/jpeg" })
            }
            schemaAttribute.push(jsonObj);
        });

        let finalData = {
            autoIssue: true,
            connectionId: selectedCredentialRecord.connectionIds,
            credDefId: credentialDefinitions.credentialDefinitionId,
            comment: '',
            attributes: schemaAttribute,
            selectedConnection: Object.keys(connection).length > 0 && connection.theirLabel,
            schemaId: (credentialDefinitions.schema) ? credentialDefinitions.schema.schemaLedgerId : JSON.parse(credentialProposalDict).schema_id
        }

        problemReport.sendProblemReportForCredentialExchange('Re-Proposal sent.', credentialExchangeId)
        handleCredentialProposal(finalData);

        // handleCancel();
    }

    return (
        <>
            <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:rePropose.credentialDetails")}</h1>
            </div>
            {!_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">

                        <EditAttributeComponent
                            selectedCredentialRecord={selectedCredentialRecord}
                            selectedSchemaAttributes={selectedSchemaAttributes}
                            attributeValues={attributeValues}
                            handleChange={handleChange}
                            handleChangeImage={handleChangeImage}
                            textErrorIndexes={textErrorIndexes}
                            imageErrorIndexes={imageErrorIndexes}
                        />
                        <>
                            {!_.isEmpty(selectedCredentialRecord) &&

                                <div className="row ml-2 mr-2">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                    onClick={() => handleRePropose(credentialExchangeId)}
                                                    disabled={textErrorIndexes.length > 0 || imageErrorIndexes.length > 0}
                                                >
                                                    {t("screens:negotiateCredential.rePropose")}
                                                </button>
                                            </div>

                                            <div className="col-md-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                    onClick={() => handleCancel()}
                                                >
                                                    {t("screens:negotiateCredential.cancel")}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            }
                        </>
                    </div>

                </>
            }
            {_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-header bg-white border-bottom-0">
                        <h1 className="nb-title">{t("screens:myCredentials.credentialDetails")}</h1>
                    </div>
                    <div className="card-body">
                        <div className="blank-credential-details-body">
                            <label className="">{t("screens:myCredentials.noRequestReceived")}</label>
                        </div>
                    </div>
                </>
            }
        </>
    )

}
