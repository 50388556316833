import { RoleType } from "../types";

export enum InternalIssuerFilterOptions {
  all = 'all',
  active = 'active',
  inActive = 'inActive'
}

export enum CredDefResponsibilityType {
  Issuance = 'issuance',
  Verification = 'verification',
  All = 'All'
}

export interface InternalIssuerVerifierOptions {
  defaultSearchFields: any,
  filterStatus: any,
  internalIssuerSortBy: any,
  sortingOrder: any,
  itemsPerPage: any,
  pageNo: any,
  type: RoleType
}

export interface ResponsibleVcs {
  credentialDefinitionId: string
  type: CredDefResponsibilityType
}

export enum IssueLoginCredentialInitiator {
  ADD = 'ADD',
  EXISTING = 'EXISTING'
}
