// Utilities
import { hexToRgb } from "./colors";
import { importAll } from "./import";
import { overrideObjects } from "./override";

/**
 * Base app colors that can be (partially) overridden via an override JSON file
 *
 * NOTE: Some calculations are applied later for derived colors, gradients, etc.
 */
export interface IAppColorsBase {
  /** Gradient (bottom) used in branded locations (ie. sidebar, login, etc) */
  gradientBrandEnd: string;
  /** Gradient (top) used in branded locations (ie. sidebar, login, etc) */
  gradientBrandStart: string;
  /** Gradient (bottom) used in buttons */
  gradientPrimaryEnd: string;
  /** Gradient (top) used in buttons */
  gradientPrimaryStart: string;
  /** Primary colour (hex) */
  primary: string;
}

/**
 * App colors can be overridden via an override JSON file (customizes CSS variables).
 *
 * NOTE: Some colours are derived from base colours (RGB conversions, etc).
 */
export interface IAppColors extends IAppColorsBase {
  /**
   * Primary colour (RGB equivalent)
   *
   * Necessary "duplicate" in order to apply opacity calulations within CSS 'rgba()' function!
   * @example "0, 139, 230"
   */
  primaryRgb: string;
}

/** CSS variable name map from overridable colors */
export const CssColorVariables: Record<keyof IAppColors, string> = {
  gradientBrandEnd: "--gradient-brand-end",
  gradientBrandStart: "--gradient-brand-start",
  gradientPrimaryEnd: "--gradient-primary-end",
  gradientPrimaryStart: "--gradient-primary-start",
  primary: "--color-primary",
  primaryRgb: "--color-primary-rgb",
};

const baseColors: IAppColorsBase = {
  primary: "#008BE6",
  gradientBrandEnd: "#682C92",
  gradientBrandStart: "var(--color-primary)",
  gradientPrimaryEnd: "#14AAE4",
  gradientPrimaryStart: "var(--color-primary)",
};

// Dynamically import color override during build step
const overrideColorContext = require.context("src/overrides", false, /\.(json)$/); // prettier-ignore
// NOTE: Colour override file MUST be named "colors.json"!
const overrideColorFiles = importAll(overrideColorContext, false);

const overriddenBaseColors = overrideObjects<IAppColorsBase>(baseColors, overrideColorFiles.colors ?? {});

// Apply additional calculated colours after overrides
const primaryColorRgb = hexToRgb(overriddenBaseColors.primary);
const colors: IAppColors = {
  ...overriddenBaseColors,
  primaryRgb: primaryColorRgb ? `${primaryColorRgb.r}, ${primaryColorRgb.g}, ${primaryColorRgb.b}` : "",
};

export { colors };
