import * as React from 'react';
import { holderAction } from '../actions/holderAction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import AddHolder from '../components/AddHolder';
import { HolderDetails } from '../components/HolderDetails';
import { socket, SocketContext } from 'src/contexts/SocketContext';
import { t } from 'i18next';

export interface IClientProps {
  holderAction: any,
  HolderReducer: any,
}

export interface IClientState {
  defaultSearchFields: any,
  item_perPage: number,
  sortingOrder: string,
  clientSortyBy: string,
  pageNo: number,
}
class Client extends React.Component<IClientProps, IClientState> {
  static contextType?= SocketContext

  constructor(props: IClientProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      sortingOrder: 'DESC',
      clientSortyBy: '',
      pageNo: 1,
    }
    this.save = this.save.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.filter = this.filter.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  async componentDidMount() {
    await this.connectSocket()
    // console.log('componentDidMount - Client ')
  }

  async connectSocket() {
    const { defaultSearchFields, item_perPage, sortingOrder, clientSortyBy, pageNo } = this.state;
    this.props.holderAction.getQRdata("CLIENT");
    this.props.holderAction.getHolderList("CLIENT", defaultSearchFields, item_perPage, pageNo, 'client', clientSortyBy, sortingOrder);
    if (socket) {
      socket.on('notification', (socketId, payload) => {
        console.log(`CLIENT - notification - socketId: ${socketId} payload: ${JSON.stringify(payload)}`);
        if (payload && payload.data) {
          if (payload.data.type === 'Connection') {
            console.log(`Client - receive Notification`)
            // this.props.notificationAction.getCountNotification()
            this.props.holderAction.getQRdata("CLIENT");
         }
        }
      })
    } else {
      console.log("Could not estalished a connection")
    }
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortingOrder, clientSortyBy, pageNo } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.holderAction.getHolderList("CLIENT", event.target.value, item_perPage, pageNo, 'client', clientSortyBy, sortingOrder);
    }
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, clientSortyBy } = this.state;
    this.props.holderAction.getHolderList("CLIENT", defaultSearchFields, item_perPage, page, 'client', clientSortyBy, sortingOrder);
  }

  sorting = (clientSortyBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', clientSortyBy: clientSortyBy }) : this.setState({ sortingOrder: 'DESC', clientSortyBy: clientSortyBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.holderAction.getHolderList("CLIENT", defaultSearchFields, item_perPage, pageNo, 'client', clientSortyBy, sortValue);
  }


  save(formData: any) {
    this.props.holderAction.createHolder("CLIENT", formData);
  }

  showDetails(client: any) {
    this.props.holderAction.selectEmployee(client, "client")
  }

  filter() {
    console.log("filter");
  }

  refresh() {
    const { defaultSearchFields, item_perPage, sortingOrder, clientSortyBy, pageNo } = this.state;
    this.props.holderAction.getHolderList("CLIENT", defaultSearchFields, item_perPage, pageNo, 'client', clientSortyBy, sortingOrder);
  }

  public render() {
    const { qrData, tHead, holderList, totalItems, selectHolder } = this.props.HolderReducer;
    return (
      <div className="row gutters-sm min-h-100 animated fadeIn">
        <div className="col-md-12 col-lg-6 mb-30">
          <div className="card nb-card h-100">
            <div className="card-header bg-white border-bottom-0">
              <h1 className="nb-title">{t("screens:onboardingNewHolder.newClients")}</h1>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <TableComponents
                    onChangePage={this.onChangePage}
                    showElementDetails={this.showDetails}
                    tHead={tHead}
                    tableContent={holderList}
                    totalRecords={totalItems}
                    selectedRecord={selectHolder}
                    sorting={this.sorting}
                    searching={this.onSearch}
                    // filter={this.filter}
                    refresh={this.refresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 mb-30">
          {Object.keys(selectHolder).length > 0 ?
            <HolderDetails HolderReducer={this.props.HolderReducer} holderAction={this.props.holderAction} />
            :
            <AddHolder createHolder={this.save} qrData={qrData} HolderReducer={this.props.HolderReducer} holderAction={this.props.holderAction} type="CLIENT" field="Client" />
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { loggingIn } = state.LoginReducer;
  const HolderReducer = state.HolderReducer;
  return { loggingIn, HolderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    holderAction: bindActionCreators(holderAction, dispatch),
  }
}

const connectedAddClient = connect(mapStateToProps, mapDispatchToProps)(Client);
export { connectedAddClient as Client };
