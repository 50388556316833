import axios from "axios";
import { config } from "../../../config";
import { credentialNegotiate, loaderConst, offerNegotiateInitiator } from "src/View/types";
import { loginAction } from "src/View/Login/actions/loginAction";
import { ListScreenOptions } from "src/types/common.interface";
import { CredentialRole, CredentialState } from "src/View/commonConst";
import _ from "lodash";
import store from "src/store";
import { t } from "i18next";
import { toastr } from "react-redux-toastr";
import { schemaAction } from "src/View/Schema/actions/schemaAction";
import { TrustRegistryResultOptions } from "../interfaces/TrustRegistryResult";


export const NegotiateCredentialAction = {

    getAllByOrgId(orgId: number, listScreenOptions: ListScreenOptions) {
        return async (dispatch: any) => {
            // debugger;
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })

                let token = localStorage.getItem('token');

                return await axios.get(`${config.apiUrl}/issue-credential/organization/${orgId}`,
                    {
                        headers: { "Authorization": `Bearer ${token}` }, params: {
                            page: listScreenOptions.pageNo,
                            items_per_page: listScreenOptions.itemsPerPage,
                            search_text: listScreenOptions.search,
                            credential_sort: listScreenOptions.sortBy,
                            sort_value: listScreenOptions.sort,
                            filter_text: listScreenOptions.filter
                        }
                    })
                    .then(async credentialsRequest => {
                        let updatedPayload: any = credentialsRequest.data.data.data
                        updatedPayload.map(async (element: any, index: any) => {

                            if (element.role && element.role === CredentialRole.ROLE_HOLDER && element.state === CredentialState.STATE_ACKED) {
                                updatedPayload[index].state = 'Stored in Wallet'
                            }
                            if (element.credentialDefinitionId === null) {
                                updatedPayload[index].credentialDefinitionId = JSON.parse(element.credentialProposalDict).cred_def_id;
                            }
                            if (!_.isEmpty(element.connection)) {
                                if (element.connection.id !== null) {
                                    updatedPayload[index].connectionName = element.connection.theirLabel;
                                } else {
                                    updatedPayload[index].connectionName = "";
                                }
                            }
                            else {
                                updatedPayload[index].connectionName = "";
                            }
                        })

                        dispatch({
                            type: credentialNegotiate.GET_ALL_NEGOTIATE_CREDENTIALS_RECORDS,
                            payload: credentialsRequest,
                            updatedPayload
                        })

                        if (updatedPayload.length) {
                            dispatch({
                                type: credentialNegotiate.SELECTED_NEGOTIATE_CREDENTIAL_RECORD,
                                payload: updatedPayload[0]
                            })
                            const defaultSelectedRecord = updatedPayload[0]
                            const schemaId = defaultSelectedRecord.credentialDefinitions && Object.keys(defaultSelectedRecord.credentialDefinitions).length
                                ? defaultSelectedRecord.credentialDefinitions.schemaLedgerId
                                : JSON.parse(defaultSelectedRecord.credentialProposalDict)?.schema_id

                            dispatch(schemaAction.getSchemaById(schemaId))
                        }
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);

                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                throw error;
            }
        }
    },

    acceptCredentialOffer(credentialExchangeId: string) {
        return async (dispatch: any) => {
            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Payload to accept credential offer */
                const payload = {
                    credentialExchangeId
                }
                /* API call for accepting credential offer and dispatch the accept credential offer action */
                return await axios.post(`${config.apiUrl}/issue-credential/send-request`, payload, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(async (acceptCredentialResponse) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Checking the response and then attach the response to payload */
                        if (acceptCredentialResponse.data) {
                            dispatch({
                                type: credentialNegotiate.ACCEPT_CREDENTIAL_OFFER,
                                payload: acceptCredentialResponse
                            })
                            this.showSelectedCredentialDetails(acceptCredentialResponse.data.data)
                            /* Payload for get updated credential list */
                            const listScreenOptions = {
                                search: '',
                                itemsPerPage: 10,
                                pageNo: 1,
                                sortBy: 'id',
                                sort: 'DESC',
                                filter: 'all'
                            }
                            const { organization } = await store.getState().LoginReducer.loggedInUser
                            this.getAllByOrgId(organization.id, listScreenOptions)

                            const credentialRecords = await store.getState().NegotiateCredentialReducer.credentialRecords

                            if (credentialRecords.length) {
                                dispatch({
                                    type: credentialNegotiate.SELECTED_NEGOTIATE_CREDENTIAL_RECORD,
                                    payload: credentialRecords[0]
                                })
                            }
                        }
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    showSelectedCredentialDetails(selectedCredentialDetails: any) {
        return async (dispatch: any) => {
            try {
                return (
                    /* This dispatches method dispatch the stored credential details. */
                    dispatch({
                        type: credentialNegotiate.SELECTED_NEGOTIATE_CREDENTIAL_RECORD,
                        payload: selectedCredentialDetails
                    })
                )
            } catch (error) {
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    sendCredentialOfferOrNegotiate(finalData: any, initiator: offerNegotiateInitiator) {
        return async (dispatch: any) => {
            // debugger;
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.post(`${config.apiUrl}/issue-credential/records/send-offer`, finalData,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(async response => {
                        dispatch({
                            type: credentialNegotiate.CREDENTIAL_OFFER_SENT,
                            payload: response
                        })

                        const listScreenOptions = {
                            search: '',
                            itemsPerPage: 10,
                            pageNo: 1,
                            sortBy: 'id',
                            sort: 'DESC',
                            filter: 'all'
                        }
                        const { organization } = await store.getState().LoginReducer.loggedInUser
                        if (initiator === offerNegotiateInitiator.negotiate) {
                            // Reload the credential negotiation screen
                            this.getAllByOrgId(organization.id, listScreenOptions)
                        }
                        if (initiator === offerNegotiateInitiator.offer) {
                            // Reload the credential offer screen
                            listScreenOptions.filter = CredentialState.STATE_OFFER_SENT
                            this.getAllByOrgId(organization.id, listScreenOptions)
                        }

                        const credentialRecords = await store.getState().NegotiateCredentialReducer.credentialRecords

                        if (credentialRecords.length) {
                            dispatch({
                                type: credentialNegotiate.SELECTED_NEGOTIATE_CREDENTIAL_RECORD,
                                payload: credentialRecords[0]
                            })
                        }

                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        toastr.success(`${t("screens:issueCredentials.toastMsgOfferSent")}`, ``);
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                throw error;
            }
        }
    },


    updateTrustRegistryResult(trustRegistryResult: TrustRegistryResultOptions, credentialExchangeId: string) {
        return async (dispatch: any) => {
            // debugger;
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.patch(`${config.apiUrl}/issue-credential/${credentialExchangeId}/update`, trustRegistryResult,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(async response => {
                        dispatch({
                            type: credentialNegotiate.UPDATE_TRUST_REGISTRY_RESULT_STATE,
                            payload: response
                        })

                        const listScreenOptions = {
                            search: '',
                            itemsPerPage: 10,
                            pageNo: 1,
                            sortBy: 'id',
                            sort: 'DESC',
                            filter: 'all'
                        }
                        const { organization } = await store.getState().LoginReducer.loggedInUser
                        this.getAllByOrgId(organization.id, listScreenOptions)
                        
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                throw error;
            }
        }
    },

}
