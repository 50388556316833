import { credentialOfferConstants } from "../../types";

const initialState = {
    loading: true,
    hasNextPage: false,
    hasPreviousPage: false,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    totalItems: 0,
    showSuccessOfferCredentialComponent: false,
    proposedCredential: {},
    connectionName: ''
}

export function CredentialOfferReducer(state = initialState, action: any) {


    switch (action.type) {
        case credentialOfferConstants.CLEAR_CREDENTIAL_OFFER:
            return Object.assign({}, state, {
                filledCredentialValue: {},
            });
        case credentialOfferConstants.SUCCESS_OFFER_CREDENTIAL:
            return Object.assign({}, state, {
                showSuccessOfferCredentialComponent: true
            })

        case credentialOfferConstants.CREATE_ANOTHER_CREDENTIAL_OFFER:
            return Object.assign({}, state, {
                showSuccessOfferCredentialComponent: false
            })

        case credentialOfferConstants.OFFERED_CREDENTIAL:
            return Object.assign({}, state, {
                proposedCredential: action.payload,
                connectionName: action.connectionName,
            })
        default:
            return state;
    }
}
