import { presentProof } from "../../types";
import { t } from 'i18next';

const initialState = {
    presentProofRequestList: [],
    totalProofRequests: 0,
    storedCredentialList: [],
    totalStoredCredentials: 0,
    tHead: [],
    selectedProofRequestDetails: {},
    selectedCredentialForProofDetails: [],
    sendProofRequestResponse: {},
    sendProofSuccess: false,
    credentialsForPresentationRequest: [],
    isDeclineSent: false
}
export function PresentProofReducer(state = initialState, action: any) {
    let tHead = [{
        'name': `${t("screens:presentProof.headDate")}`,
        'label': "createDateTime"
    }, {
        'name': `${t("screens:presentProof.headGivenName")}`,
        'label': "connectionName"
    }, {
        'name': `${t("screens:presentProof.headStatus")}`,
        'label': "state"
    }, {
        'name': '',
        'label': "details"
    },];

    switch (action.type) {
        /* Get all present proof request list */
        case presentProof.GET_ALL_PRESENT_PROOF_REQUEST_LIST:
            return Object.assign({},
                state,
                {
                    presentProofRequestList: action.payload.data,
                    totalProofRequests: action.payload.totalItems,
                    tHead: tHead,
                });
        /* Get all available credential list */
        case presentProof.GET_ALL_AVAILABLE_CREDENTIAL_LIST:
            return Object.assign({},
                state,
                {
                    storedCredentialList: action.payload.data,
                    totalStoredCredentials: action.payload.totalItems,
                    sendProofSuccess: false
                });
        /* Store the selected proof request details */
        case presentProof.SHOW_SELECTED_PROOF_REQUEST_DETAILS:
            return Object.assign({}, state,
                {
                    selectedProofRequestDetails: action.payload,
                });
        /* Store the selected credential details */
        case presentProof.SHOW_SELECTED_CREDENTIAL_FOR_PROOF_DETAILS:
            return Object.assign({}, state,
                {
                    selectedCredentialForProofDetails: action.payload,
                });
        /* Send presentation proof */
        case presentProof.SEND_PROOF_REQUEST:
            return Object.assign({}, state,
                {
                    sendProofRequestResponse: action.payload,
                    sendProofSuccess: true,
                    isDeclineSent: false
                });
        /* Close the success message */
        case presentProof.CLOSE_SUCCESS:
            return Object.assign({}, state,
                {
                    sendProofSuccess: false,
                    isDeclineSent: false,
                });
        case presentProof.FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST:
            return Object.assign({}, state,
                {
                    credentialsForPresentationRequest: action.payload,
                    sendProofSuccess: false,
                    isDeclineSent: false
                });

        case presentProof.DECLINED_PROOF_REQUEST:
            return Object.assign({}, state, {
                isDeclineSent: true,
                sendProofSuccess: false
            });

        case presentProof.CLOSE_DECLINE_SENT:
            return Object.assign({}, state,
                {
                    sendProofSuccess: false,
                    isDeclineSent: false,
                });

        default:
            return state
    }

}