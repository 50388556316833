import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ListScreenOptions } from "src/types/common.interface";
import { PresentProofState } from "src/View/commonConst";
import TableComponents from "../../../Common Components/Components/TableComponent";
import { presentationRequestAction } from "../actions/presentationRequestAction";

/* Interface for Props variables*/
interface IRequestListProps {
    presentationRequestAction: any,
    PresentationRequestReducer: any,
    presentationRequestList: any,
    getAllPresentationRequests: any,
    onShowProofDetails:any
}

/* Interface for local states variables*/
interface IRequestListState {
    listScreenOptions: ListScreenOptions
}

class RequestList extends React.Component<IRequestListProps, IRequestListState> {
    constructor(props: IRequestListProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            listScreenOptions: {
                pageNo: 1,
                itemsPerPage: 10,
                filter: PresentProofState.STATE_REQUEST_SENT
            }
        }

        /* Binding the method */
        this.onChangePage = this.onChangePage.bind(this)
        this.search = this.search.bind(this)
        this.refresh = this.refresh.bind(this)
        this.selectedReceivedProofRequestRecord = this.selectedReceivedProofRequestRecord.bind(this)
    }    

    /*Method used to set selected page no form pagination */
    onChangePage(page: number) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page
        this.setState({ listScreenOptions })
        this.props.presentationRequestAction.getAllPresentProofRequest(listScreenOptions);
    }

    
    /* Method used to show selected proof request details */
    async selectedReceivedProofRequestRecord(item: any) {
        this.props.presentationRequestAction.showSelectedCredentialDetails(item)
        await this.props.onShowProofDetails(item)
    }

    /* Method used to search the content from list */
    search(event: React.KeyboardEvent<HTMLInputElement> | any) {
        const { listScreenOptions } = this.state;
        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions })
            this.props.presentationRequestAction.getAllPresentProofRequest(listScreenOptions);
        }
    }

    /* Method used to refresh the present proof request list */
    refresh() {
        const defaultPayload = {
            pageNo: 1,
            itemsPerPage: 10,
            filter: PresentProofState.STATE_REQUEST_SENT
        }
        this.setState({ listScreenOptions: { ...defaultPayload } })
        this.props.presentationRequestAction.getAllPresentProofRequest(defaultPayload);
    }

    /* UI to show proof request list */
    public render() {
        const { tHead, presentationList, totalPresentations, selectedPresentationRecord } = this.props.PresentationRequestReducer;
        return (
            <>
                <div>
                    <TableComponents
                        onChangePage={this.onChangePage}
                        showElementDetails={this.selectedReceivedProofRequestRecord}
                        tHead={tHead}
                        tableContent={presentationList}
                        totalRecords={totalPresentations}
                        searching={this.search}
                        refresh={this.refresh}
                        selectedRecord={selectedPresentationRecord}
                    />
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentationRequestReducer = state.PresentationRequestReducer;

    return { PresentationRequestReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentationRequestAction: bindActionCreators(presentationRequestAction, dispatch),
    }
}

const requestList = connect(mapStateToProps, mapDispatchToProps)(RequestList);
export { requestList as RequestList };
