import _ from 'lodash';
import * as React from 'react';
import { t } from 'i18next';
import { CredDefResponsibilityType } from '../interfaces';

export interface IInternalIssuerVerifierDetailsProps {
  IssuerActions: any,
  IssuersReducers: any,
  LoginReducer: any
}

export interface IInternalIssuerVerifierDetailsState {
}

export default class InternalIssuerVerifierDetails extends React.Component<IInternalIssuerVerifierDetailsProps, IInternalIssuerVerifierDetailsState> {
  constructor(props: IInternalIssuerVerifierDetailsProps) {
    super(props);

    this.state = {}
  }

  editConnection(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any, selectedInternalIssuerVerifier: any): void {
    this.props.IssuerActions.editInternalIssuerVerifiers(selectedInternalIssuerVerifier);
  }

  public render() {

    let {
      selectedInternalIssuerVerifier,
      totalInternalIssuersVerifiers,
    } = this.props.IssuersReducers;

    const { loggedInUser } = this.props.LoginReducer;
    let subscriptionId = loggedInUser.organization.subscription.id

    return (
      <>
        <div className="card nb-card mb-30">
          <div className="card-body">
            <h2>
              {
                subscriptionId === 1
                  ? t("screens:existingIssuersVerifiers.totalInternalIssuer")
                  : subscriptionId === 2
                    ? t("screens:existingIssuersVerifiers.totalInternalVerifier")
                    : t("screens:existingIssuersVerifiers.totalInternalIssuerVerifier")
              }
            </h2>
            <div className="total-count">{totalInternalIssuersVerifiers}</div>
          </div>
        </div>
        <div className="card nb-card shadow">
          <div className="card-header bg-white border-bottom-0">
            <h1 className="nb-title">
              {
                subscriptionId === 1
                  ? t("screens:existingIssuersVerifiers.issuerDetails")
                  : subscriptionId === 2
                    ? t("screens:existingIssuersVerifiers.verifierDetails")
                    : t("screens:existingIssuersVerifiers.issuerVerifierDetails")
              }
            </h1>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush mb-3">
              <li className="list-group-item">
                <div className="row">
                  <div className="col-sm-8">
                    <label className="font-weight-bold">
                      {t("screens:existingIssuersVerifiers.givenName")}
                      :</label>
                    <div>{!_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.name}</div>
                  </div>
                  <div className="col-sm-4">
                    {/* <img src={!_.isEmpty(selectedInternalIssuers) && selectedInternalIssuers.profilePic} width="50" height="50" className="user-icon float-right" /> */}
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <label className="nb-label">{t("screens:existingIssuersVerifiers.employeeId")}:</label>
                <div>{!_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.empId}</div>
              </li>
              <li className="list-group-item">
                <label className="nb-label">{t("screens:existingIssuersVerifiers.department")}:</label>
                <div>{!_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.department}</div>
              </li>
              <li className="list-group-item">
                <label className="nb-label">{t("screens:existingIssuersVerifiers.position")}:</label>
                <div>{!_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.position}</div>
              </li>
              <li className="list-group-item">
                <label className="nb-label">{t("screens:existingIssuersVerifiers.dateAdded")}:</label>
                <div>{!_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.dateAdded}</div>
              </li>
              <li className="list-group-item">
                <label className="nb-label">{t("screens:existingIssuersVerifiers.role")}:</label>
                <div>{!_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.role.name}</div>
              </li>
              <li className="list-group-item">
                <label className="nb-label">{t("screens:existingIssuersVerifiers.status")}:</label>
                <div>{
                  !_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.isActive === true ? `${t("screens:addIssuerVerifier.active")}` :
                    !_.isEmpty(selectedInternalIssuerVerifier) && selectedInternalIssuerVerifier.isActive === false ? `${t("screens:addIssuerVerifier.inActive")}` : ''
                }</div>
              </li>

            </ul>
            <div>
              {
                (!_.isEmpty(selectedInternalIssuerVerifier) && (selectedInternalIssuerVerifier.role.id === 3 || selectedInternalIssuerVerifier.role.id === 5))
                && <>
                  <label className="nb-label">{t("screens:existingIssuersVerifiers.responsibleIssuanceVCs")}:</label>
                  <div className="card mb-3">
                    <div className="card-body">
                      {!_.isEmpty(selectedInternalIssuerVerifier)
                        && !_.isEmpty(selectedInternalIssuerVerifier.responsibleVcs)
                        && selectedInternalIssuerVerifier.responsibleVcs.map((ele: any, index: any) => {
                          if (ele.credentialDefinition !== null) {
                            return (
                              <>
                                {
                                  ele && ele.type === CredDefResponsibilityType.Issuance &&
                                  <div key={index}><i className="fas fa-check-circle text-nb"></i> {
                                    (ele.credentialDefinition && ele.credentialDefinition.credentialDefinitionId).split(':')[4]
                                  }
                                  </div>
                                }
                              </>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                </>
              }
              {(!_.isEmpty(selectedInternalIssuerVerifier) && (selectedInternalIssuerVerifier.role.id === 4 || selectedInternalIssuerVerifier.role.id === 5))
                && <>
                  <label className="nb-label">{t("screens:existingIssuersVerifiers.responsibleVerificationVCs")}:</label>
                  <div className="card mb-3">
                    <div className="card-body">
                      {!_.isEmpty(selectedInternalIssuerVerifier)
                        && !_.isEmpty(selectedInternalIssuerVerifier.responsibleVcs)
                        && selectedInternalIssuerVerifier.responsibleVcs.map((ele: any, index: any) => {
                          return (
                            <>
                              {
                                ele && ele.type === CredDefResponsibilityType.Verification &&
                                <div key={index}><i className="fas fa-check-circle text-nb"></i> {
                                  (ele.credentialDefinition.credentialDefinitionId).split(':')[4]
                                }
                                </div>
                              }
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </>

              }
            </div>
            <div>
              <button type="button" className="btn btn-sm btn-block btn-nb-outline-primary rounded-pill"
                onClick={(e) => this.editConnection(e, selectedInternalIssuerVerifier)}
                disabled={_.isEmpty(selectedInternalIssuerVerifier) ? true : false}
              >
                {t("screens:existingIssuersVerifiers.openToEdit")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
