import { t } from 'i18next';

export const tHeadforInternalIssuers = [{
  'name': `${t("screens:existingIssuersVerifiers.constIssuer")}`,
  'label': "issuerName"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constDepartment")}`,
  'label': "department"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constStatus")}`,
  'label': "isActive"
},
{
  'name': '',
  'label': "details"
}];

export const tHeadForInternalIssuersVerifiers = [{
  'name': `${t("screens:existingIssuersVerifiers.constGivenName")}`,
  'label': "issuerName"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constDepartment")}`,
  'label': "department"
}, {
  'name': `${t("screens:existingIssuersVerifiers.role")}`,
  'label': "userRole"
},{
  'name': `${t("screens:existingIssuersVerifiers.constStatus")}`,
  'label': "isActive"
}, {
  'name': '',
  'label': "details"
}];


export const tHeadforEditInternalIssuers = [{
  'name': `${t("screens:existingIssuersVerifiers.constIssuer")}`,
  'label': "issuerName"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constDateAdded")}`,
  'label': "dateAdded"
}, {
  'name': '',
  'label': "view"
}];

export const tHeadForEditInternalIssuersVerifiers = [{
  'name': `${t("screens:existingIssuersVerifiers.constGivenName")}`,
  'label': "issuerName"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constDateAdded")}`,
  'label': "dateAdded"
}, {
  'name': `${t("screens:existingIssuersVerifiers.role")}`,
  'label': "userRole"
}, {
  'name': '',
  'label': "view"
}];


export const filterInternalIssuers = [{
  'name': `${t("screens:existingIssuersVerifiers.constAll")}`,
  'tag': 'all'
}, {
  'name': `${t("screens:existingIssuersVerifiers.constActive")}`,
  'tag': 'active'
}, {
  'name': `${t("screens:existingIssuersVerifiers.constInActive")}`,
  'tag': 'inActive'
}
];

export const tHeadForInternalVerifiers = [{
  'name': `${t("screens:existingIssuersVerifiers.constVerifier")}`,
  'label': "issuerName"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constDepartment")}`,
  'label': "department"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constStatus")}`,
  'label': "isActive"
},
{
  'name': '',
  'label': "details"
}];


export const tHeadForEditInternalVerifiers = [{
  'name': `${t("screens:existingIssuersVerifiers.constVerifier")}`,
  'label': "issuerName"
}, {
  'name': `${t("screens:existingIssuersVerifiers.constDateAdded")}`,
  'label': "dateAdded"
}, {
  'name': '',
  'label': "view"
}];


export const filterInternalVerifiers = [{
  'name': `${t("screens:existingIssuersVerifiers.constAll")}`,
  'tag': 'all'
}, {
  'name': `${t("screens:existingIssuersVerifiers.constActive")}`,
  'tag': 'active'
}, {
  'name': `${t("screens:existingIssuersVerifiers.constInActive")}`,
  'tag': 'inActive'
}
];
