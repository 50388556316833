import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import CreateSchema from '../../Schema/components/CreateSchema';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ICreateCredentialProps {
  credentialAction: any,
  schemaAction: any,
  CredentialReducer: any,
  SchemaReducer: any,
}

export interface ICreateCredentialState {
  credDefShow: boolean,
  isEdited: boolean,
  newCredentialList: Array<any>,
  isSelected: boolean,
  selectedSchema: any,
  checked: boolean,
  credDefData: any,
  revocable: boolean,
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  isTagNameDuplicate: boolean,
  sortingOrder: string,
  CredentialSortBy: string,
  clearTypeahead: boolean,
  currentStep: number,
  email: any,
  username: any,
  password: any,
  schemaFormData: any,
  schemaFromLedger: boolean,
  key: string,
  duplicateTagNames: boolean,
  tagName: string,
  description: string,
  tagError: string | undefined,
}

class CreateCredential extends React.Component<ICreateCredentialProps, ICreateCredentialState> {
  constructor(props: ICreateCredentialProps) {
    super(props);

    this.state = {
      credDefShow: false,
      isEdited: false,
      newCredentialList: [],
      isSelected: false,
      selectedSchema: '',
      checked: false,
      credDefData: {},
      revocable: false,
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      isTagNameDuplicate: false,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
      clearTypeahead: false,
      currentStep: 1,
      email: '',
      username: '',
      password: '',
      schemaFormData: {},
      schemaFromLedger: true,
      key: "Schema-tab",
      duplicateTagNames: false,
      tagName: '',
      description: '',
      tagError: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSchemaChange = this.handleSchemaChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onReovcable = this.onReovcable.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.showCredDefForm = this.showCredDefForm.bind(this);
    this.skipNow = this.skipNow.bind(this);
  }

  showCredDefForm() {
    this.props.credentialAction.showForm();
    this.setState({
      schemaFromLedger: true,
      selectedSchema: {},
      isSelected: false
    })
  }

  handleSelect(key: string) {
    const { schemaLedgerId, newSchema } = this.props.SchemaReducer;
    this.setState({ key });
  }

  handleSchemaChange(selectedOptions: any) {
    let selectedData = selectedOptions[0];
    this.setState({
      isSelected: true,
      selectedSchema: selectedData,
    })
  }

  handleInputChange() {
    this.setState({
      isSelected: false
    })
  }

  selectSchemaLedger() {
    this.props.schemaAction.selectSchemaLedger(this.state.selectedSchema)
    this.handleSelect("profile-tab")
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let credDefData = this.state.credDefData;
    let error
    const regexp = /^\S*$/;

    if (event.target.name === 'tagName') {
      if (!regexp.test(event.target.value)) {
        error = "*Must be alphanumeric with optional '_' or '-'.";
      }
      this.setState({
        tagError: error
      });
      this.setState({ tagName: event.target.value });
    } else {
      this.setState({ description: event.target.value });
    }
    // Here check Duplicate Tag name
    let duplicateTagName = false;

    const { showSuccessPage, createdCredDef, credDef } = this.props.CredentialReducer;
    if (!_.isEmpty(credDef)) {
      credDef.map((tagname: any, index: any) => {
        if (tagname.tag === event.target.value) {
          duplicateTagName = true;
        }
      })
      this.setState({
        duplicateTagNames: duplicateTagName,
      });
    }
  }

  onReovcable = () => {
    this.setState({
      revocable: !this.state.revocable,
    })
  }

  saveDetails() {
    let { credDefData, selectedSchema, revocable, tagName, description } = this.state;
    const { schemaLedgerId } = this.props.SchemaReducer
    let finalData = {
      schema_id: schemaLedgerId,
      tag: tagName,
      support_revocation: revocable,
      revocation_registry_size: 100,
      description: description
    }
    this.props.credentialAction.saveCredential(finalData);
    this.setState({
      isSelected: false,
      tagName: '',
      description: '',
      revocable: false,
      checked: false,
      selectedSchema: '',
    })
  }

  handleCancel(event: React.MouseEvent<HTMLButtonElement> | any) {
    event.preventDefault();
    this.props.credentialAction.showForm();
    this.setState({
      credDefShow: false,
      isEdited: false,
      isSelected: false,
      selectedSchema: {},
      tagName: '',
      description: '',
      revocable: false,
      clearTypeahead: true,
      schemaFromLedger: true,
    })
  }

  hideSchemaFromLedger = () => {
    this.props.schemaAction.getSchemaList();
    this.setState({
      schemaFromLedger: false
    })
  }
  createNewCredential = () => {
    this.props.credentialAction.showCreateCredentialPage()
    this.handleSelect('Schema-tab')
  }

  skipNow() {
    this.setState({
      credDefShow: false,
      isEdited: false,
      newCredentialList: [],
      isSelected: false,
      selectedSchema: '',
      checked: false,
      // credDefData: {},
      revocable: false,
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      isTagNameDuplicate: false,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
      clearTypeahead: false,
      currentStep: 1,
      email: '',
      username: '',
      password: '',
      schemaFormData: {},
      schemaFromLedger: true,
      key: "Schema-tab",
      tagName: '',
      description: '',
    })
  }

  public render() {
    let {
      credDefData,
      revocable,
      selectedSchema,
      isTagNameDuplicate,
      clearTypeahead,
      schemaFormData,
      schemaFromLedger,
      duplicateTagNames,
      tagName,
      description,
      tagError
    } = this.state;
    const {
      schemaLedgerId, schemaList } = this.props.SchemaReducer;
    const { showSuccessPage, createdCredDef, credDef } = this.props.CredentialReducer;
    return (
      <>
        {
          !showSuccessPage ?
            <>
              <div className="card nb-card h-100">
                <div className="card-header bg-white border-bottom-0">
                  <div className="card-title m-0">
                    <h1 className="nb-title">{t("screens:createNewCredential.title")}</h1>
                  </div>
                </div>
                <div className="card-body">
                  <ul className="nav nav-tabs nb-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className={this.state.key === "Schema-tab" ? "nav-link active" : "nav-link"} id={this.state.key} data-toggle="tab" href="#Schema" role="tab" aria-controls="Schema" aria-selected="true">{t("screens:createNewCredential.step1Schema")}</a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a className={this.state.key === "profile-tab" ? "nav-link active" : "nav-link"} id={this.state.key} data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" aria-disabled={schemaLedgerId.trim() !== '' ? true : false}>{t("screens:createNewCredential.step2CredDef")}</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className={this.state.key === "Schema-tab" ? "tab-pane fade show active pt-4" : "tab-pane fade show pt-4"} id="Schema" role="tabpanel" aria-labelledby="Schema-tab">
                      {
                        schemaFromLedger ?
                          <CreateSchema {...this.props} hideSchemaFromLedger={this.hideSchemaFromLedger} handleSelect={this.handleSelect} />
                          :
                          <>
                            <div>
                              <div className="card bg-light mb-3" style={{ minHeight: '245px' }}>
                                <div className="card-body">
                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label nb-label">{t("screens:createNewCredential.selectSchema")}<span className="text-danger">*</span></label>
                                    <div className="col-sm-9">
                                      <Typeahead data={schemaList} onChange={this.handleSchemaChange} onInputChange={this.handleInputChange}
                                        clearTypeahead={clearTypeahead} placeholder={t("screens:createNewCredential.selectSchema")} disabled={false} />
                                    </div>
                                  </div>
                                  {this.state.isSelected === true && selectedSchema && selectedSchema.schemaVersion &&
                                    <>
                                      <div className="form-group row">
                                        <label className="col-sm-3 col-form-label nb-label">{t("screens:createNewCredential.schemaVersion")}:<span className="text-danger">*</span></label>
                                        <div className="col-sm-9">
                                          <input type="text" className="form-control" value={selectedSchema && selectedSchema.schemaVersion} disabled />
                                        </div>
                                      </div>
                                      {
                                        selectedSchema && selectedSchema.attributes && selectedSchema.attributes.map((value: any, index: any) => {
                                          return (
                                            <div className="form-group row" key={index}>
                                              <div className="col-sm-6">
                                                <div className="form-group row">
                                                  <label className="col-sm-6 col-form-label">{t("screens:createNewCredential.attribute")} {index + 1}</label>
                                                  <div className="col-sm-6">
                                                    <input type="text" className="form-control mb-3" key={index} value={JSON.parse(value).name} disabled></input>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-sm-6">
                                                <div className="form-group row">
                                                  <label className="col-sm-6 col-form-label">{t("screens:createNewCredential.format")} </label>
                                                  <div className="col-sm-6">
                                                    <input type="text" className="form-control mb-3" value={JSON.parse(value).value} disabled></input>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </>}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6 text-left mt-5">
                                <div className="form-group">
                                  <button type="button" className="btn nb-link" onClick={(e) => this.showCredDefForm()}>{t("screens:createNewCredential.cancel")}</button>
                                </div>
                              </div>
                              <div className="col-sm-6 text-right mt-5">
                                <div className="form-group">
                                  <button type="button" className="btn nb-link" onClick={(e) => this.selectSchemaLedger()}
                                    disabled={selectedSchema && selectedSchema.attributes ? false : true}>{t("screens:createNewCredential.next")}</button>
                                </div>
                              </div>
                            </div>
                          </>
                      }
                    </div>
                    <div className={this.state.key === "profile-tab" ? "tab-pane fade show active p-4" : "tab-pane fade show p-4"} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div>
                        <small>
                          {t("screens:createNewCredential.messageLater")} <button
                            onClick={this.skipNow} className='text-info'
                          >{t("screens:createNewCredential.skipNow")}</button>.
                        </small>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label form-check-label nb-label" htmlFor="revocableCheck">
                        {t("screens:createNewCredential.revocable")}
                        </label>
                        <div className="col-sm-8 align-self-center">
                          <input id="revocableCheck" type="checkbox"
                            name="revocable"
                            checked={revocable}
                            onChange={this.onReovcable}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label nb-label">{t("screens:createNewCredential.schemaLedgerId")}</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" name="schemaLedgerId"
                            value={schemaLedgerId}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label nb-label">{t("screens:createNewCredential.credentialName")}</label>
                        <div className="col-sm-8">
                          <input type="text" className="form-control" name="tagName" placeholder={t("screens:createNewCredential.tagName")}
                            value={tagName}
                            onChange={this.handleChange}
                          />
                          <div className="text-danger">{tagError ?? ''}</div>
                          <div className="text-danger">{duplicateTagNames ? `${t("screens:createNewCredential.messageDuplicateName")}` : ''}</div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label nb-label">{t("screens:createNewCredential.credentialDefinition")}</label>
                        <div className="col-sm-8">
                          <textarea className="form-control" style={{ height: '250px' }} name="description" placeholder={t("screens:createNewCredential.description")}
                            value={description}
                            disabled={duplicateTagNames || tagError ? true : false}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="offset-lg-4 col-sm-8">
                          <button type="button" className="btn btn-block btn-nb-outline-primary rounded-pill"
                            disabled={schemaLedgerId.trim() !== '' && tagName.trim() && description.trim() ? false : true}
                            onClick={(e) => this.saveDetails()}
                          >{t("screens:createNewCredential.submit")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="card nb-card h-100 shadow">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 mt-5 mb-5 text-center">
                      <img alt="Config" className="img img-fluid" src={images.illustrationAddSuccess} />
                    </div>
                    <div className="col-sm-12 text-center mb-5">
                    {/* <h2 className="nb-label">A new credential '{createdCredDef.credentialDefinitionId.split(':')[4]}' was created successfully!</h2> */}
                      <h2 className="nb-label">{t("screens:createNewCredential.messageCreated1")} '{createdCredDef.credentialDefinitionId.split(':')[4]}' {t("screens:createNewCredential.messageCreated2")}</h2>
                    </div>
                    <div className="col-sm-12">
                      <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                        onClick={this.createNewCredential}>
                        {t("screens:createNewCredential.createAnotherCredential")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
        }

      </>
    );
  }
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps() {
  return {}
}

const createCredential = connect(mapStateToProps, mapDispatchToProps)(CreateCredential);
export { createCredential as CreateCredential };
