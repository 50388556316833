import _ from 'lodash';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Chart } from "react-google-charts";
import moment from 'moment';
import { loginAction } from '../../Login/actions/loginAction';
import { dashboardAction } from '../actions/dashboardAction';

export interface IVerifierDashboardProps {
  loginAction: any,
  LoginReducer: any,
  dashboardAction: any,
  DashboardReducer: any,
}

export interface IVerifierDashboardState {
  dateData: any,
  selectStartDate: any,
  selectEndDate: any,
}

class VerifierDashboard extends React.Component<IVerifierDashboardProps, IVerifierDashboardState> {
  constructor(props: IVerifierDashboardProps) {
    super(props);

    this.state = {
      dateData: {},
      selectStartDate: false,
      selectEndDate: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.dashboardAction.getAllCountforDashboard();
    this.props.loginAction.getCurrentUser();

  }

  handleChange(event: any) {
    const { dateData } = this.state;
    let fields = dateData;
    let selectStartDate = false;
    let selectEndDate = false;
    fields[event.target.name] = event.target.value;
    this.setState({
      dateData: fields,
    });

    this.props.dashboardAction.getAllCountforDashboard();
  }

  public render() {
    const { loggedInUser } = this.props.LoginReducer;
    const { countForDashboard } = this.props.DashboardReducer;
    const { dateData, selectStartDate, selectEndDate } = this.state;
    let showMonth = moment().clone().startOf('month').format('MMM-DD')
    let da = moment(showMonth).toDate();
    let speedameterCount: any = !_.isEmpty(countForDashboard) && parseInt(countForDashboard[2].verificationPercentage)
    let finalvalue: any = Math.round((180 / 100) * speedameterCount);
    console.log("countForDashboard", countForDashboard)
    console.log("loggedInUser in verifier", loggedInUser);
    return (
      <>
        <div className="row">
          <div className="col-sm-8"></div>
          {/* <div className="col-sm-4">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="startDate"
                placeholder="select"
                value={dateData.startDate !== '' ? dateData.startDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-prepend">
                <label className="input-group-text bg-white border-left-0 border-right-0" htmlFor="inputGroupSelect01">-</label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="endDate"
                value={dateData.endDate !== '' ? dateData.endDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-append">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <small className="text-danger">{selectStartDate ? 'Select Start Date' : ''}</small>
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col-md-12 col-lg-6 mb-3">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">Verification</h1>
              </div>
              <div className="card-body">
                <div className="speedo">
                  <div className="face">
                    <div className="needle-position">
                      <div className="counter-value">
                        {finalvalue}%
                      </div>
                      <div className="needle fast" style={{ transform: `rotate(${finalvalue}deg)` }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mb-3">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">Verification</h1>
              </div>
              <div className="card-body">
                <Chart
                  width={'100%'}
                  height={'100%'}
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Workflows', 'By QR Code', 'By Proof Request'],
                    ['Markting Promotion', 400, 300],
                    ['Meetings', 100, 200],
                    ['Occasional', 500, 600],
                    ['Webinars', 200, 600],
                  ]}
                  options={{
                    title: 'Workflows',
                    chartArea: { width: '60%' },
                    colors: ['#008BE6', '#9842D4'],
                    hAxis: {
                      title: '# of Proof Requests',
                      minValue: 0,
                    },
                    vAxis: {
                      title: 'City',
                    },
                  }}
                  // For tests
                  rootProps={{ 'data-testid': '1' }}
                />
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}


function mapStateToProps(state: any) {
  console.log("verifier state---", state);
  const LoginReducer = state.LoginReducer;
  const DashboardReducer = state.DashboardReducer;
  return { LoginReducer, DashboardReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    dashboardAction: bindActionCreators(dashboardAction, dispatch),
  }
}

const connectedVerifierDashboard = connect(mapStateToProps, mapDispatchToProps)(VerifierDashboard);
export { connectedVerifierDashboard as VerifierDashboard };