import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingAction } from '../actions/onboardingAction'
import { history } from '../../../index'
import _ from 'lodash';
import PageLoader from '../../../Common Components/Components/pageLoader';

// Utilities
import images from 'src/utils/images';

/* Interface for Props variables*/
export interface IOrganisationDetailsProps {
  onboardingAction: any,
  OnboardingReducer: any,
  ExistingOrganizationReducer: any,
  LoaderReducer: any,
}

class OrganisationDetails extends React.Component<IOrganisationDetailsProps> {
  constructor(props: IOrganisationDetailsProps) {
    super(props);

    /* Binding the method */
    this.rejectOnboarding = this.rejectOnboarding.bind(this);
    this.acceptOnboarding = this.acceptOnboarding.bind(this);
    this.backtoList = this.backtoList.bind(this);
    this.kompanyVerification = this.kompanyVerification.bind(this)
  }

  /* Default react life-cycle method, it calls after render method executed. */
  componentDidMount() {
    const { selectedOrg } = this.props.OnboardingReducer;
    if (_.isEmpty(selectedOrg)) {
      history.push('/organization-list')
    }
  }

  /* Method used to reject onboarding request. */
  rejectOnboarding() {
    const { selectedOrg } = this.props.OnboardingReducer
    this.props.onboardingAction.rejectOnboarding(selectedOrg.id);
  }

  /* Method used to accept onboarding request. */
  acceptOnboarding() {
    const { selectedOrg } = this.props.OnboardingReducer
    this.props.onboardingAction.acceptOnboarding(selectedOrg.id);
  }

  /* Method used to verify organization details by calling the Kompany API. */
  kompanyVerification() {
    const { selectedOrg } = this.props.OnboardingReducer
    this.props.onboardingAction.kompanyVerification(selectedOrg.id, selectedOrg.countryISO, selectedOrg.companyRegistrationNo);
  }

  /* Method to redirect the organization list page. */
  backtoList() {
    history.push('/organization-list')
  }

  public render() {
    const { selectedOrg } = this.props.OnboardingReducer;
    const { loader } = this.props.LoaderReducer;
    return (
      <>

        <div className="card nb-card-noborder">
          {
            loader ?
              <PageLoader></PageLoader>
              : null
          }
          <div className="card-header bg-white">
            <h1>ONBOARDING ORGANIZATION</h1>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label className="nb-label">Company Logo<span>*</span></label>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 col-lg-6">
                        <div className="card nb-card-image" style={{ backgroundImage: `url(${!_.isEmpty(selectedOrg) && selectedOrg.logoUrl !== null ? selectedOrg.logoUrl : images.placeholderRectangleClear})` }}>
                          <div className="card-body">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 verification-result">
                <div className="form-group">
                  {!selectedOrg.isKompanyVerified &&
                    <label className="nb-label text-warning"><i className="far fa-circle text-warning mr-2"></i> Verification is pending...</label>
                  }
                  {
                    selectedOrg.isKompanyVerified &&
                    <label className="nb-label text-success"><i className="fas fa-check-circle text-success mr-2"></i> Verification done successfully...</label>
                  }
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="nb-label">Display Name<span>*</span></label>
                  <input type="text" className="form-control" name="displayName"
                    value={selectedOrg.displayName}
                    readOnly={true} />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Organization Name<span>*</span></label>
                  <input type="text" className="form-control" name="orgName"
                    value={selectedOrg.orgName}
                    readOnly={true} />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Industry<span>*</span></label>
                  <input type="text" className="form-control" name="orgCategory" value={selectedOrg.orgCategory} readOnly={true} />
                </div>
              </div>
              { }
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Address</label>
                  <textarea className="form-control" name="address"
                    value={selectedOrg && selectedOrg.address === "undefined" ? '' : selectedOrg.address}
                    readOnly={true} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="nb-label">Role to Subscribe<span>*</span></label>
                  <div className="card">
                    <div className="card-body">
                      <div className="custom-control custom-radio">
                        <input type="radio" id="issuer" name="subscription" className="custom-control-input" value={1}
                          checked={selectedOrg && selectedOrg.subscription && selectedOrg.subscription.id === 1}
                          disabled={true}
                        />
                        <label className="custom-control-label" htmlFor="issuer">Issuer</label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input type="radio" id="verifier" name="subscription" className="custom-control-input" value={2}
                          checked={selectedOrg && selectedOrg.subscription && selectedOrg.subscription.id === 2}
                          disabled={true}
                        />
                        <label className="custom-control-label" htmlFor="verifier">Verifier</label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input type="radio" id="verifierandverifier" name="subscription" className="custom-control-input" value={3}
                          checked={selectedOrg && selectedOrg.subscription && selectedOrg.subscription.id === 3}
                          disabled={true}
                        />
                        <label className="custom-control-label" htmlFor="verifierandverifier">Issuer & Verifier</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Description<span>*</span></label>
                  <textarea className="form-control" name="description" style={{ height: '113px' }}
                    value={selectedOrg.description}
                    readOnly={true} />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Schemas<span>*</span></label>
                  <input type="text" id="schema" name="schema" className="form-control" value={selectedOrg.schemaCount} readOnly={true} />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Admin Name<span>*</span></label>
                  <input type="text" className="form-control" name="adminUsername"
                    value={selectedOrg.adminName}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Admin Email<span>*</span></label>
                  <input type="email" className="form-control" name="adminEmail"
                    value={selectedOrg.adminEmail}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Admin Contact<span>*</span></label>
                  <input type="text" className="form-control" name="adminContact"
                    value={selectedOrg.adminContact}
                    readOnly={true} />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Company Registration No<span>*</span></label>
                  <input type="text" className="form-control" name="companyRegistrationNo"
                    value={selectedOrg.companyRegistrationNo}
                    readOnly={true} />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="form-group">
                  <label className="nb-label">Company Registration Country ISO<span>*</span></label>
                  <input type="text" className="form-control" name="countryISO"
                    value={selectedOrg.countryISO}
                    readOnly={true} />
                </div>
              </div>
              {selectedOrg.state &&
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="form-group">
                    <label className="nb-label">State Code<span>*</span></label>
                    <input type="text" className="form-control" name="countryISO"
                      value={selectedOrg.state}
                      readOnly={true} />
                  </div>
                </div>
              }

              <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-6">
                <div className="row">
                  <div className="col-sm-4">
                    <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary" onClick={this.backtoList}>
                      BACK TO LIST
                    </button>
                  </div>
                  {!_.isEmpty(selectedOrg) && selectedOrg.isOnBoarded === 1 ? <><div className="col-sm-4">
                    <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary" onClick={this.rejectOnboarding}>
                      REJECT
                    </button>
                  </div>
                    <div className="col-sm-4">
                      {!selectedOrg.isKompanyVerified &&
                        <button type="button" className="btn btn-block rounded-pill btn-primary" onClick={this.kompanyVerification}>
                          Verify
                        </button>
                      }{selectedOrg.isKompanyVerified &&
                        <button type="button" className="btn btn-block rounded-pill btn-primary" onClick={this.acceptOnboarding}>
                          ACCEPT
                        </button>
                      }
                    </div></>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state: any) {
  const OnboardingReducer = state.OnboardingReducer;
  const ExistingOrganizationReducer = state.ExistingOrganizationReducer;
  const LoaderReducer = state.LoaderReducer;
  return { OnboardingReducer, ExistingOrganizationReducer, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    onboardingAction: bindActionCreators(onboardingAction, dispatch),
  }
}

const connectedOrganisationDetails = connect(mapStateToProps, mapDispatchToProps)(OrganisationDetails);
export { connectedOrganisationDetails as OrganisationDetails };
