export const tHeadAgentStatus = [{
    'name': 'Name',
    'label': "orgName"
}, {
    'name': 'END-POINT',
    'label': "agentEndpoint"
}, {
    'name': 'STATUS',
    'label': "agentStatus"
},];

export const filterAgentStatusList = [{
    'name': 'All',
    'tag' : 'all'
  },{
    'name': 'Running',
    'tag' : 'true'
  }, {
    'name': 'Stop',
    'tag' : 'false'
  }
  ];