import _ from 'lodash';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import TypeaheadComponent from '../../../Common Components/Components/TypeaheadComponent';
import LoginCredentialModal from './LoginCredentialModal';
import { t } from 'i18next';
import { APIInitiator } from '../actions/issuerActions';
import { CredDefResponsibilityType, IssueLoginCredentialInitiator, ResponsibleVcs } from '../interfaces';
import { hasDupsObjects } from '../utils/checkDuplicates';

export interface IAddIssuerVerifierProps {
  LoginReducer: any,
  IssuerActions: any,
  IssuersReducers: any,
  credentialAction: any,
  CredentialReducer: any,
  TaskActions: any,
  TaskReducers: any,
}

export interface IAddIssuerVerifierState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  CredentialSortBy: string,
  clearTypeaheadCredDef: boolean,
  isSelectedCredDef: boolean,
  clickedCredDef: any,
  VCArray: any,
  clearTypeaheadEmp: boolean,
  isSelectedEmp: boolean,
  clickedEmployee: any,
  issuerData: any,
  state: any,
  clearTypeaheadstate: boolean,
  isSelectedRole: boolean,
  clickedRole: any,
  clearTypeaheadRole: boolean,
  dateFormatError: boolean,
}

export default class AddIssuerVerifier extends React.Component<IAddIssuerVerifierProps, IAddIssuerVerifierState> {
  constructor(props: IAddIssuerVerifierProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
      clearTypeaheadCredDef: false,
      isSelectedCredDef: false,
      clickedCredDef: [],
      VCArray: [],
      clearTypeaheadEmp: false,
      isSelectedEmp: true,
      clickedEmployee: {},
      issuerData: {
        employeeId: '',
        department: '',
        position: '',
        dateAdded: '',
      },
      state: [
        { tag: `${t("screens:addIssuerVerifier.active")}` },
        { tag: `${t("screens:addIssuerVerifier.inActive")}` },
      ],
      clearTypeaheadstate: false,
      isSelectedRole: false,
      clickedRole: {},
      clearTypeaheadRole: false,
      dateFormatError: false
    }

    this.handleCredDefChange = this.handleCredDefChange.bind(this);
    this.handleCredDefInputChange = this.handleCredDefInputChange.bind(this);
    this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
    this.handleInputChangeEmp = this.handleInputChangeEmp.bind(this);
    this.showResponsibleVCs = this.showResponsibleVCs.bind(this);
    this.removeClick = this.removeClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.issueLoginCredential = this.issueLoginCredential.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleInputChangeRole = this.handleInputChangeRole.bind(this);
    this.closeForm = this.closeForm.bind(this);
  }

  componentDidMount() {
    const { loggedInUser } = this.props.LoginReducer;
    this.props.IssuerActions.addAnotherIssuer();
    this.props.IssuerActions.getAllEmployees();
    this.props.IssuerActions.getRole();
    this.props.TaskActions.getAllCreateCredDefByOrgId(loggedInUser.organization.id, CredDefResponsibilityType.All);
  }

  handleCredDefChange(type: CredDefResponsibilityType, selectedCredDefOptions: any) {

    let { VCArray } = this.state;
    let selectedData = selectedCredDefOptions[0];

    if (_.isEmpty(selectedData) || selectedData === null || selectedData === undefined) {
      console.log("not push")
    } else {
      selectedData.type = type
      VCArray.push({ ...selectedData });

      if (hasDupsObjects(VCArray)) {
        toastr.info(`${t("screens:addIssuerVerifier.duplicateRespVC")}`, ``);
        VCArray.splice(VCArray.length - 1, 1);
      } else {

        this.setState({
          isSelectedCredDef: true,
          clickedCredDef: VCArray,
          clearTypeaheadCredDef: true,
        })
      }

    }
  }

  handleCredDefInputChange() {

    this.setState({
      isSelectedCredDef: false,
    })
  }


  handleEmployeeChange(selectedCredDefOptions: any) {
    let selectedData = selectedCredDefOptions[0];
    this.setState({
      isSelectedEmp: true,
      clickedEmployee: selectedData,
    })
  }

  handleInputChangeEmp() {
    this.setState({
      isSelectedEmp: false
    })
  }

  showResponsibleVCs() {
    const { clickedCredDef } = this.state;
    clickedCredDef.map((field: any, index: any) => {
      return (
        <>
          <div className="row form-group">
            <div className="col-sm-10">
              <label className="btn btn-outline-secondary btn-block">{field.tag}</label>
            </div>
            <div className="col-sm-2 text-center">
              <button className="btn bnt-link"
                onClick={(e) => this.removeClick(e, index)}
              >
                <i className="fas fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </>
      )

    }
    )
  }

  removeClick(event: React.MouseEvent<HTMLButtonElement>, i: number) {
    event.preventDefault();
    let clickedCredDef = [...this.state.clickedCredDef];
    let VCArray = [...this.state.VCArray];
    clickedCredDef.splice(i, 1);
    VCArray.splice(i, 1);
    this.setState({ clickedCredDef, VCArray });
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { issuerData } = this.state;
    let dateFormatError = false
    let fields = issuerData;
    fields[event.target.name] = event.target.value;

    if (event.target.name === 'dateAdded') {
      let regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!fields[event.target.name].match(regEx)) {
        dateFormatError = true;
      }
    }

    this.setState({
      issuerData: fields,
      dateFormatError
    });
  }

  handleStateChange(selectedOptions: any) {
    let fields = this.state.issuerData;
    if (selectedOptions[0] && selectedOptions[0].tag) {
      fields["state"] = selectedOptions[0].tag;
    } else {
      fields["state"] = ''
    }
    this.setState({
      issuerData: fields,
    })
  }


  handleRoleChange(selectedRoleOptions: any) {
    // TODO console.log("selectedRoleOptions[0] ", `${selectedRoleOptions[0].name}`);
    // TODO `${t("screens:addIssuerVerifier.selectedRoleOptions[0]")}`
    let selectedData = selectedRoleOptions[0];
    this.setState({
      isSelectedRole: true,
      clickedRole: selectedData,
    })
  }

  handleInputChangeRole() {
    this.setState({
      isSelectedRole: false
    })
  }

  addIssuer(event: any) {
    const { issuerData, clickedCredDef, clickedEmployee, clickedRole } = this.state;

    let responsibleVcsList: ResponsibleVcs[] = []

    !_.isEmpty(clickedCredDef) && clickedCredDef.map((credentialDefinition: any) => {
      responsibleVcsList.push({
        credentialDefinitionId: credentialDefinition.id,
        type: credentialDefinition.type
      })
    })

    let finalData = {
      employeeId: issuerData.employeeId,
      department: issuerData.department,
      position: issuerData.position,
      dateAdded: issuerData.dateAdded,
      status: issuerData.state === 'active' ? true : false,
      responsibleVcs: responsibleVcsList,
      roleId: clickedRole.id,
      holderId: !_.isEmpty(clickedEmployee) && clickedEmployee.id,
    }
    
    // console.log('finalData:', finalData)
    this.props.IssuerActions.addIssuerVerifier(finalData, APIInitiator.Issuer);
  }

  issueLoginCredential(event: any) {
    const { addedIssuerVerifier } = this.props.IssuersReducers;
    this.props.IssuerActions.issueLoginCredential(addedIssuerVerifier.id, IssueLoginCredentialInitiator.ADD);

    this.setState({
      issuerData: {
        employeeId: '',
        department: '',
        position: '',
        dateAdded: '',
      },
      // clearTypeaheadCredDef: true,
      // clearTypeaheadEmp: true,
      // clearTypeaheadstate: true,
      clickedCredDef: {},
      clickedEmployee: {},
      VCArray: [],
      state: [
        { tag: `${t("screens:addIssuerVerifier.active")}` },
        { tag: `${t("screens:addIssuerVerifier.inActive")}` },
      ],
    })
  }

  closeForm(event: any) {

    this.props.IssuerActions.clearForm()
    this.setState({
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
      clearTypeaheadCredDef: false,
      isSelectedCredDef: false,
      clickedCredDef: [],
      VCArray: [],
      clearTypeaheadEmp: false,
      isSelectedEmp: true,
      clickedEmployee: {},
      issuerData: {
        employeeId: '',
        department: '',
        position: '',
        dateAdded: '',
      },
      state: [
        { tag: `${t("screens:addIssuerVerifier.active")}` },
        { tag: `${t("screens:addIssuerVerifier.inActive")}` },
      ],
      clearTypeaheadstate: false,
      isSelectedRole: false,
      clickedRole: {},
      clearTypeaheadRole: false,
    })
  }

  public render() {

    const { employeeData, addedIssuerVerifier, issueLoginCredential, roles } = this.props.IssuersReducers;
    const { loggedInUser } = this.props.LoginReducer;
    let subscriptionId = loggedInUser.organization.subscription.id

    const { createdCredDefsbyOrgId } = this.props.TaskReducers;
    const { clearTypeaheadCredDef, clickedCredDef, clearTypeaheadEmp, issuerData, state, clearTypeaheadstate, clearTypeaheadRole,
      clickedEmployee, clickedRole, dateFormatError } = this.state;

    let organizationSubscription;
    if (subscriptionId === 1) {
      organizationSubscription = roles.filter((x: any) => x.name.toLowerCase() === 'issuer')
    }
    else if (subscriptionId === 2) {
      organizationSubscription = roles.filter((x: any) => x.name.toLowerCase() === 'verifier')
    }
    else {
      organizationSubscription = roles
    }

    return (
      <>

        <div className="row gutters-sm min-h-100">
          <div className="col-sm-12">
            <div className="card nb-card">
              <div className="card-header bg-white border-bottom-0">
                <div className="row">
                  <div className="col-sm-8">
                    <h1 className="nb-title">
                      {
                        subscriptionId === 1
                          ? t("screens:addIssuerVerifier.issuerTitle")
                          : subscriptionId === 2
                            ? t("screens:addIssuerVerifier.verifierTitle")
                            : t("screens:addIssuerVerifier.bothTitle")
                      }
                    </h1>
                  </div>
                  <div className="col-sm-4 text-right">
                    <button type="button" className="btn"
                      onClick={(e) => this.closeForm(e)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:addIssuerVerifier.name")}</label>
                          {_.isEmpty(addedIssuerVerifier) ?
                            <TypeaheadComponent data={employeeData} onChange={this.handleEmployeeChange} onInputChange={this.handleInputChangeEmp}
                              clearTypeahead={clearTypeaheadEmp} placeholder="" disabled={false} />
                            :
                            <input type="text" className="form-control"
                              value={addedIssuerVerifier.name}
                              readOnly={!_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.name ? true : false}
                            />
                          }
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:addIssuerVerifier.employeeId")}</label>
                          <input type="text" className="form-control"
                            name="employeeId"
                            placeholder=""
                            autoComplete="off"
                            value={issuerData.employeeId !== '' ? addedIssuerVerifier.empId : issuerData.employeeId}
                            onChange={(e) => this.handleChange(e)}
                            readOnly={!_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.empId ? true : false}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:addIssuerVerifier.department")}</label>
                          <input type="text" className="form-control"
                            name="department"
                            placeholder=""
                            autoComplete="off"
                            value={issuerData.department !== '' ? addedIssuerVerifier.department : issuerData.department}
                            onChange={(e) => this.handleChange(e)}
                            readOnly={!_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.department ? true : false}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:addIssuerVerifier.position")}</label>
                          <input type="text" className="form-control"
                            name="position"
                            placeholder=""
                            autoComplete="off"
                            value={issuerData.position !== '' ? addedIssuerVerifier.position : issuerData.position}
                            onChange={(e) => this.handleChange(e)}
                            readOnly={!_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.position ? true : false}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:addIssuerVerifier.dateAdded")}</label>
                          <input type="date" className="form-control"
                            name="dateAdded"
                            value={issuerData.dateAdded !== '' ? addedIssuerVerifier.dateAdded : issuerData.dateAdded}
                            onChange={(e) => this.handleChange(e)}
                            readOnly={!_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.dateAdded ? true : false}
                          />
                          {
                            dateFormatError ?
                              <small className="text-danger">
                                <b>{t("screens:addIssuerVerifier.dateAdded")}</b> {t("screens:addIssuerVerifier.errorMsgDateFormat")}
                              </small> : null
                          }
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:addIssuerVerifier.status")}:</label>
                          {_.isEmpty(addedIssuerVerifier) ?
                            <TypeaheadComponent data={state} onChange={this.handleStateChange} onInputChange={this.handleStateChange}
                              clearTypeahead={clearTypeaheadstate} placeholder=""
                              disabled={false}
                            />
                            :
                            <input type="text" className="form-control"
                              value={addedIssuerVerifier.isActive === true ? `${t("screens:addIssuerVerifier.active")}` : `${t("screens:addIssuerVerifier.inActive")}`}
                              readOnly={!_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.isActive ? true : false}
                            />
                          }
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:addIssuerVerifier.role")}:</label>
                          {!_.isEmpty(roles) && _.isEmpty(addedIssuerVerifier) ?
                            <TypeaheadComponent data={organizationSubscription} onChange={this.handleRoleChange} onInputChange={this.handleInputChangeRole}
                              clearTypeahead={clearTypeaheadRole} placeholder=""
                              disabled={false}
                            />
                            :
                            <input type="text" className="form-control"
                              value={addedIssuerVerifier.role && addedIssuerVerifier.role.name}
                              readOnly={!_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.role && addedIssuerVerifier.role.name ? true : false}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="row">
                      {
                        (clickedRole?.id === 3 || clickedRole?.id === 5)
                        && <>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="nb-label">{t("screens:addIssuerVerifier.responsibleIssuanceVCs")}:</label>
                              <TypeaheadComponent
                                data={createdCredDefsbyOrgId}
                                onChange={this.handleCredDefChange.bind(this, CredDefResponsibilityType.Issuance)}
                                onInputChange={this.handleCredDefInputChange}
                                clearTypeahead={clearTypeaheadCredDef}
                                placeholder={t("screens:addIssuerVerifier.selectCredDef")}
                                disabled={
                                  !_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.name
                                    ? true
                                    : false
                                } />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <div className={!_.isEmpty(addedIssuerVerifier) ? "card bg-light" : "card"}>
                                <div className="card-body" style={{ minHeight: '230px' }}>
                                  {_.isEmpty(addedIssuerVerifier) ?
                                    !_.isEmpty(clickedCredDef) && clickedCredDef.map((responsibleVC: any, index: any) => {
                                      if (responsibleVC.type === CredDefResponsibilityType.Issuance) {
                                        return (
                                          <>
                                            <div className="row form-group" key={index}>
                                              <div className="col-sm-10">
                                                <label className="btn btn-outline-secondary btn-block">{responsibleVC.tag}</label>
                                              </div>
                                              <div className="col-sm-2 text-center">
                                                <button className="btn bnt-link"
                                                  onClick={(e) => this.removeClick(e, index)}
                                                >
                                                  <i className="fas fa-minus-circle text-grey-light"></i>
                                                </button>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })
                                    :
                                    addedIssuerVerifier.responsibleVcs.map((field: any) => {
                                      if (field.type === CredDefResponsibilityType.Issuance) {
                                        return (
                                          <>
                                            <div className="row form-group">
                                              <div className="col-sm-10">
                                                <label className="btn btn-outline-secondary btn-block">
                                                  {field.tag}
                                                </label>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {(clickedRole?.id === 4 || clickedRole?.id === 5)
                        ? <>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="nb-label">{t("screens:addIssuerVerifier.responsibleVerificationVCs")}:</label>
                              <TypeaheadComponent
                                data={createdCredDefsbyOrgId}
                                onChange={this.handleCredDefChange.bind(this, CredDefResponsibilityType.Verification)}
                                onInputChange={this.handleCredDefInputChange}
                                clearTypeahead={clearTypeaheadCredDef}
                                placeholder={t("screens:addIssuerVerifier.selectCredDef")}
                                disabled={
                                  !_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.name
                                    ? true
                                    : false} />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <div className={!_.isEmpty(addedIssuerVerifier) ? "card bg-light" : "card"}>
                                <div className="card-body" style={{ minHeight: '230px' }}>
                                  {_.isEmpty(addedIssuerVerifier) ?
                                    !_.isEmpty(clickedCredDef) && clickedCredDef.map((responsibleVC: any, index: any) => {
                                      if (responsibleVC.type === CredDefResponsibilityType.Verification) {
                                        return (
                                          <>
                                            <div className="row form-group" key={index}>
                                              <div className="col-sm-10">
                                                <label className="btn btn-outline-secondary btn-block">{responsibleVC.tag}</label>
                                              </div>
                                              <div className="col-sm-2 text-center">
                                                <button className="btn bnt-link"
                                                  onClick={(e) => this.removeClick(e, index)}
                                                >
                                                  <i className="fas fa-minus-circle text-grey-light"></i>
                                                </button>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })
                                    :
                                    addedIssuerVerifier.responsibleVcs.map((field: any) => {
                                      if (field.type === CredDefResponsibilityType.Verification) {
                                        return (
                                          <>
                                            <div className="row form-group">
                                              <div className="col-sm-10">
                                                <label className="btn btn-outline-secondary btn-block">
                                                  {field.tag}
                                                </label>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      }
                                    })
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        : <></>
                      }

                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'></div>
                  <div className='col-sm-3'></div>
                  <div className='col-sm-3'></div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      {
                        _.isEmpty(addedIssuerVerifier)
                          ? <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                            onClick={(e) => this.addIssuer(e)}
                            disabled={_.isEmpty(clickedEmployee) || !issuerData.employeeId || !issuerData.department ||
                              !issuerData.position || !issuerData.dateAdded || !issuerData.state || dateFormatError ||
                              _.isEmpty(clickedRole)
                              ? true
                              : false}
                          >{t("screens:addIssuerVerifier.save")}</button>
                          : <button className="btn btn-nb-gradient-primary btn-block rounded-pill"
                            onClick={(e) => this.issueLoginCredential(e)}
                          >{t("screens:addIssuerVerifier.issueLoginCredential")}</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          issueLoginCredential &&
          <LoginCredentialModal issueLoginCredential={issueLoginCredential} {...this.props}
            {...this.state}
          ></LoginCredentialModal>
        }
      </>
    );
  }
}
