import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TypeaheadComponent from '../../../Common Components/Components/TypeaheadComponent';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';
import { APIInitiator } from '../actions/issuerActions';
import { CredDefResponsibilityType, IssueLoginCredentialInitiator, ResponsibleVcs } from '../interfaces';
import { hasDupsObjects } from '../utils/checkDuplicates';

export interface IIssuerVerifierDetailsProps {
  IssuerActions: any,
  IssuersReducers: any,
  editInternalIssuerVerifierData: any,
  CredentialReducer: any,
  TaskActions: any,
  TaskReducers: any,
  LoginReducer: any
}

export interface IIssuerVerifierDetailsState {
  isEditIssuer: boolean,
  clearTypeaheadCredDef: boolean,
  isSelectedCredDef: boolean,
  clickedCredDef: any,
  VCArray: any,
  state: any,
  clearTypeaheadstate: boolean,
  issuerData: [{
    state: string
  }] | [],
  isLoginCredentialIssued: boolean
}

export default class IssuerVerifierDetails extends React.Component<IIssuerVerifierDetailsProps, IIssuerVerifierDetailsState> {
  constructor(props: IIssuerVerifierDetailsProps) {
    super(props);

    this.state = {
      isEditIssuer: false,
      clearTypeaheadCredDef: false,
      isSelectedCredDef: false,
      clickedCredDef: [],
      VCArray: [],
      state: [
        { tag: `${t("screens:addIssuerVerifier.active")}` },
        { tag: `${t("screens:addIssuerVerifier.inActive")}` },
      ],
      clearTypeaheadstate: false,
      issuerData: [{ state: this.props.editInternalIssuerVerifierData.isActive ? 'active' : 'inactive' }],
      isLoginCredentialIssued: this.props.editInternalIssuerVerifierData.isLoginCredential
    }

    this.listView = this.listView.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editIssuer = this.editIssuer.bind(this);
    this.removeClick = this.removeClick.bind(this);
    this.handleCredDefChange = this.handleCredDefChange.bind(this);
    this.handleCredDefInputChange = this.handleCredDefInputChange.bind(this);
    this.updateIssuer = this.updateIssuer.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleStateInputChange = this.handleStateInputChange.bind(this);
  }



  listView(event: any) {
    this.props.IssuerActions.listView();
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    // const { issuerData } = this.state;
    // let fields = issuerData;

    // fields[event.target.name] = event.target.value;
    // this.setState({
    //   issuerData: fields,
    // });
  }

  handleStateInputChange() {
    this.setState({
      issuerData: []
    })
  }

  editIssuer(event: any) {
    const { viewInternalIssuerVerifier } = this.props.IssuersReducers;
    const { editInternalIssuerVerifierData } = this.props

    const responsibleVCs = !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier.responsibleVcs : editInternalIssuerVerifierData.responsibleVcs
    const modifiedResponsibleVCList: any = []

    responsibleVCs.map((responsibleVCDetails: any) => {
      modifiedResponsibleVCList.push({
        cd_id: responsibleVCDetails.cd_id || responsibleVCDetails.credentialDefinition.id,
        rVC_type: responsibleVCDetails.rVC_type || responsibleVCDetails.type,
        credentialDefinitionId: responsibleVCDetails.credentialDefinition.credentialDefinitionId || responsibleVCDetails.credentialDefinition.tag
      })
    })

    this.setState({
      isEditIssuer: true,
      VCArray: modifiedResponsibleVCList
    })

  }

  removeClick(event: any, index: any) {
    event.preventDefault();

    let clickedCredDef = [...this.state.clickedCredDef];

    let VCArray = [...this.state.VCArray];

    clickedCredDef.splice(index, 1);
    VCArray.splice(index, 1);
    this.setState({ clickedCredDef, VCArray });
  }

  handleCredDefChange(type: CredDefResponsibilityType, selectedCredDefOptions: any) {
    let { VCArray } = this.state;
    let selectedData = selectedCredDefOptions[0];

    if (_.isEmpty(selectedData) || selectedData === null || selectedData === undefined) {
      console.log("not push")
    } else {
      const modifiedSelectedData = {
        cd_id: selectedData.id,
        rVC_type: type,
        credentialDefinitionId: selectedData.credentialDefinitionId
      }

      selectedData.type = type
      VCArray.push({ ...modifiedSelectedData });

      if (hasDupsObjects(VCArray)) {
        toastr.info(`${t("screens:addIssuerVerifier.duplicateRespVC")}`, ``);
        VCArray.splice(VCArray.length - 1, 1);
      } else {
        this.setState({
          isSelectedCredDef: true,
          clickedCredDef: VCArray,
          clearTypeaheadCredDef: true,
        })
      }
    }
  }

  handleCredDefInputChange() {

    this.setState({
      isSelectedCredDef: false,
    })
  }

  handleStateChange(selectedOptions: any) {

    let fields = this.state.issuerData;
    if (selectedOptions[0] && selectedOptions[0].tag) {
      fields[0] = { state: selectedOptions[0].tag }
    } else {
      fields = []
    }
    this.setState({
      issuerData: fields
    })
  }

  updateIssuer(event: any) {
    const { VCArray, issuerData } = this.state;
    const { viewInternalIssuerVerifier } = this.props.IssuersReducers;
    const { editInternalIssuerVerifierData } = this.props
    let responsibleVcsList: ResponsibleVcs[] = []

    let finalData;
    !_.isEmpty(VCArray) && VCArray.map((credentialDefinition: any) => {
      responsibleVcsList.push({
        credentialDefinitionId: credentialDefinition.cd_id,
        type: credentialDefinition.rVC_type
      })
    })

    if (!_.isEmpty(viewInternalIssuerVerifier)) {
      finalData = {
        employeeId: viewInternalIssuerVerifier.empId,
        department: viewInternalIssuerVerifier.department,
        position: viewInternalIssuerVerifier.position,
        dateAdded: viewInternalIssuerVerifier.dateAdded,
        status: issuerData[0] && issuerData[0].state === 'active' ? true : false,
        responsibleVcs: responsibleVcsList,
        roleId: viewInternalIssuerVerifier.role.id,
        holderId: viewInternalIssuerVerifier.id
      }
    } else {
      finalData = {
        employeeId: editInternalIssuerVerifierData.empId,
        department: editInternalIssuerVerifierData.department,
        position: editInternalIssuerVerifierData.position,
        dateAdded: editInternalIssuerVerifierData.dateAdded,
        status: issuerData[0] && issuerData[0].state === 'active' ? true : false,
        responsibleVcs: responsibleVcsList,
        roleId: editInternalIssuerVerifierData.role.id,
        holderId: editInternalIssuerVerifierData.id
      }
    }

    // console.log('finalData', finalData)

    this.props.IssuerActions.updateIssuerVerifier(finalData, APIInitiator.Issuer);
    this.setState({ isEditIssuer: false });
  }

  issueLoginCredential(event: any) {
    const { viewInternalIssuerVerifier } = this.props.IssuersReducers;
    const { editInternalIssuerVerifierData } = this.props
    const holderId = editInternalIssuerVerifierData ? editInternalIssuerVerifierData.id : viewInternalIssuerVerifier.id

    this.props.IssuerActions.issueLoginCredential(holderId, IssueLoginCredentialInitiator.EXISTING);
    this.setState({
      isLoginCredentialIssued: true
    })
  }

  handleCancel(event: any) {
    event.preventDefault()
    this.setState({
      isEditIssuer: false
    })
  }


  public render() {
    const { viewInternalIssuerVerifier } = this.props.IssuersReducers;
    const { isEditIssuer, clearTypeaheadCredDef, VCArray, state, clearTypeaheadstate, issuerData, isLoginCredentialIssued } = this.state;
    const { editInternalIssuerVerifierData } = this.props
    const { createdCredDefsbyOrgId } = this.props.TaskReducers;
    const { loggedInUser } = this.props.LoginReducer;
    let subscriptionId = loggedInUser.organization.subscription.id

    return (
      <div className="card nb-card h-100 shadow">
        <div className="card-header bg-white border-bottom-0">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="nb-title">{
                subscriptionId === 1
                  ? t("screens:existingIssuersVerifiers.issuerDetails")
                  : subscriptionId === 2
                    ? t("screens:existingIssuersVerifiers.verifierDetails")
                    : t("screens:existingIssuersVerifiers.issuerVerifierDetails")
              }
              </h1>
            </div>
            <div className="col-sm-4 text-right">
              <button type="button" className="btn"
                onClick={(e) => this.listView(e)}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="card-body p-5">
          <div className="row mb-5">
            <div className="col-sm-6">
              <h4>{
                !_.isEmpty(editInternalIssuerVerifierData) ? editInternalIssuerVerifierData.issuerName :
                  !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier.name : ''
              }</h4>
              <p>{
                !_.isEmpty(editInternalIssuerVerifierData) ? editInternalIssuerVerifierData.department :
                  !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier.department : ''
              }
              </p>
            </div>
            <div className="col-sm-3 text-right">
              <img src={images.placeholderProfile} alt="profile" className="user-icon" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:existingIssuersVerifiers.employeeId")}:</label>
                    <input type="text" className="form-control"
                      name="employeeId"
                      placeholder={t("screens:existingIssuersVerifiers.employeeId")}
                      value={
                        !_.isEmpty(editInternalIssuerVerifierData) ? editInternalIssuerVerifierData.empId :
                          !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier.empId : ''
                      }
                      onChange={(e) => this.handleChange(e)}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:existingIssuersVerifiers.department")}:</label>
                    <input type="text" className="form-control"
                      name="department"
                      placeholder={t("screens:existingIssuersVerifiers.department")}
                      value={
                        !_.isEmpty(editInternalIssuerVerifierData) ? editInternalIssuerVerifierData.department :
                          !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier.department : ''
                      }
                      onChange={(e) => this.handleChange(e)}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:existingIssuersVerifiers.dateAdded")}:</label>
                    <input type="date" className="form-control"
                      name="date"
                      value={
                        !_.isEmpty(editInternalIssuerVerifierData) ? editInternalIssuerVerifierData.dateAdded :
                          !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier.dateAdded : ''
                      }
                      onChange={(e) => this.handleChange(e)}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:existingIssuersVerifiers.role")}:</label>
                    <input type="text" className="form-control"
                      value={
                        !_.isEmpty(editInternalIssuerVerifierData) ? editInternalIssuerVerifierData.role?.name :
                          !_.isEmpty(viewInternalIssuerVerifier) ? viewInternalIssuerVerifier.role?.name : ''
                      }
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:existingIssuersVerifiers.status")}:</label>
                    {isEditIssuer ?
                      <TypeaheadComponent data={state} onChange={this.handleStateChange} onInputChange={this.handleStateInputChange}
                        clearTypeahead={clearTypeaheadstate} placeholder={t("screens:existingIssuersVerifiers.status")}
                        disabled={false}
                        selectedValue={issuerData}
                      />
                      :
                      <input type="text" className="form-control"
                        value={
                          (!_.isEmpty(editInternalIssuerVerifierData) && editInternalIssuerVerifierData.isActive)
                            || (!_.isEmpty(viewInternalIssuerVerifier) && viewInternalIssuerVerifier.isActive)
                            ? `${t("screens:addIssuerVerifier.active")}`
                            : `${t("screens:addIssuerVerifier.inActive")}`
                        }
                        readOnly={isEditIssuer ? false : true}
                      />
                    }
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    {isEditIssuer ?
                      <>
                        <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                          onClick={(e) => this.updateIssuer(e)}
                        >{t("screens:existingIssuersVerifiers.update")}</button>
                        <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                          onClick={(e) => this.handleCancel(e)}
                        >{t("screens:existingIssuersVerifiers.cancel")}</button>

                      </>
                      :
                      <>
                        <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                          onClick={(e) => this.editIssuer(e)}
                        >{t("screens:existingIssuersVerifiers.edit")}</button>

                        {
                          (editInternalIssuerVerifierData.isLoginCredential === false || viewInternalIssuerVerifier.isLoginCredential === false)
                          && isLoginCredentialIssued === false
                          &&
                          <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                            onClick={(e) => this.issueLoginCredential(e)}
                          >{t("screens:existingIssuersVerifiers.issueLoginCredential")}</button>
                        }
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
            {console.log('editInternalIssuerVerifierData', editInternalIssuerVerifierData)}
            {console.log('viewInternalIssuerVerifier', viewInternalIssuerVerifier)}
            <div className="col-sm-6">
              <div className="row">
                {
                  ((editInternalIssuerVerifierData.role?.id === 3 || editInternalIssuerVerifierData.role?.id === 5)
                    || (viewInternalIssuerVerifier.role?.id === 3 || viewInternalIssuerVerifier.role?.id === 5))
                  && <>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="nb-label">{t("screens:existingIssuersVerifiers.responsibleIssuanceVCs")}:</label>
                        <TypeaheadComponent
                          data={createdCredDefsbyOrgId}
                          onChange={this.handleCredDefChange.bind(this, CredDefResponsibilityType.Issuance)}
                          onInputChange={this.handleCredDefInputChange}
                          clearTypeahead={clearTypeaheadCredDef}
                          placeholder={t("screens:existingIssuersVerifiers.selectCredDef")}
                          disabled={isEditIssuer ? false : true}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="card">
                          <div className="card-body" style={{ minHeight: '345px' }}>
                            {!isEditIssuer ?
                              !_.isEmpty(editInternalIssuerVerifierData) && !_.isEmpty(editInternalIssuerVerifierData.responsibleVcs) ?
                                editInternalIssuerVerifierData.responsibleVcs.map((field: any, index: any) => {
                                  if (field.type === CredDefResponsibilityType.Issuance)
                                    return (
                                      <>
                                        <div className="row form-group">
                                          <div className="col-sm-10">
                                            <label className="btn btn-outline-secondary btn-block">
                                              {(field.credentialDefinition.credentialDefinitionId).split(':')[4]}
                                            </label>
                                          </div>
                                          <div className="col-sm-2 text-center">
                                            <button className="btn bnt-link"
                                              onClick={e => this.removeClick(e, index)}
                                              disabled={isEditIssuer ? false : true}
                                            >
                                              <i className="fas fa-minus-circle"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )

                                })
                                :
                                !_.isEmpty(viewInternalIssuerVerifier) && !_.isEmpty(viewInternalIssuerVerifier.responsibleVcs) &&
                                viewInternalIssuerVerifier.responsibleVcs.map((field: any, index: any) => {
                                  if (field.type === CredDefResponsibilityType.Issuance)
                                    return (
                                      <>
                                        <div className="row form-group">
                                          <div className="col-sm-10">
                                            <label className="btn btn-outline-secondary btn-block">
                                              {(field.credentialDefinition.credentialDefinitionId).split(':')[4]}
                                            </label>
                                          </div>
                                          <div className="col-sm-2 text-center">
                                            <button className="btn bnt-link"
                                              onClick={e => this.removeClick(e, index)}
                                              disabled={isEditIssuer ? false : true}
                                            >
                                              <i className="fas fa-minus-circle"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )

                                })
                              :
                              !_.isEmpty(VCArray) && VCArray.map((field: any, index: any) => {
                                if (field.rVC_type === CredDefResponsibilityType.Issuance)
                                  return (
                                    <>
                                      <div className="row form-group">
                                        <div className="col-sm-10">
                                          <label className="btn btn-outline-secondary btn-block">
                                            {(field.credentialDefinitionId).split(':')[4]}
                                          </label>
                                        </div>
                                        <div className="col-sm-2 text-center">
                                          <button className="btn bnt-link"
                                            onClick={e => this.removeClick(e, index)}
                                            disabled={isEditIssuer ? false : true}
                                          >
                                            <i className="fas fa-minus-circle"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {
                  ((editInternalIssuerVerifierData.role?.id === 4 || editInternalIssuerVerifierData.role?.id === 5)
                    || (viewInternalIssuerVerifier.role?.id === 4 || viewInternalIssuerVerifier.role?.id === 5))
                  && <>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="nb-label">{t("screens:existingIssuersVerifiers.responsibleVerificationVCs")}:</label>
                        <TypeaheadComponent
                          data={createdCredDefsbyOrgId}
                          onChange={this.handleCredDefChange.bind(this, CredDefResponsibilityType.Verification)}
                          onInputChange={this.handleCredDefInputChange}
                          clearTypeahead={clearTypeaheadCredDef}
                          placeholder={t("screens:existingIssuersVerifiers.selectCredDef")}
                          disabled={isEditIssuer ? false : true}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="card">
                          <div className="card-body" style={{ minHeight: '345px' }}>
                            {!isEditIssuer ?
                              !_.isEmpty(editInternalIssuerVerifierData) && !_.isEmpty(editInternalIssuerVerifierData.responsibleVcs) ?
                                editInternalIssuerVerifierData.responsibleVcs.map((field: any, index: any) => {
                                  if (field.type === CredDefResponsibilityType.Verification)
                                    return (
                                      <>
                                        <div className="row form-group">
                                          <div className="col-sm-10">
                                            <label className="btn btn-outline-secondary btn-block">
                                              {(field.credentialDefinition.credentialDefinitionId).split(':')[4]}
                                            </label>
                                          </div>
                                          <div className="col-sm-2 text-center">
                                            <button className="btn bnt-link"
                                              onClick={e => this.removeClick(e, index)}
                                              disabled={isEditIssuer ? false : true}
                                            >
                                              <i className="fas fa-minus-circle"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )

                                })
                                :
                                !_.isEmpty(viewInternalIssuerVerifier) && !_.isEmpty(viewInternalIssuerVerifier.responsibleVcs) &&
                                viewInternalIssuerVerifier.responsibleVcs.map((field: any, index: any) => {
                                  if (field.type === CredDefResponsibilityType.Verification)
                                    return (
                                      <>
                                        <div className="row form-group">
                                          <div className="col-sm-10">
                                            <label className="btn btn-outline-secondary btn-block">{(field.credentialDefinition.credentialDefinitionId).split(':')[4]}</label>
                                          </div>
                                          <div className="col-sm-2 text-center">
                                            <button className="btn bnt-link"
                                              onClick={e => this.removeClick(e, index)}
                                              disabled={isEditIssuer ? false : true}
                                            >
                                              <i className="fas fa-minus-circle"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )

                                })
                              :
                              !_.isEmpty(VCArray) && VCArray.map((field: any, index: any) => {

                                if (field.rVC_type === CredDefResponsibilityType.Verification)
                                  return (
                                    <>
                                      <div className="row form-group">
                                        <div className="col-sm-10">
                                          <label className="btn btn-outline-secondary btn-block">{(field.credentialDefinitionId).split(':')[4]}</label>
                                        </div>
                                        <div className="col-sm-2 text-center">
                                          <button className="btn bnt-link"
                                            onClick={e => this.removeClick(e, index)}
                                            disabled={isEditIssuer ? false : true}
                                          >
                                            <i className="fas fa-minus-circle"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


}

function mapStateToProps(state: any) {
  const CredentialReducer = state.CredentialReducer;
  return {
    CredentialReducer
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    // credentialAction: bindActionCreators(credentialAction, dispatch),
  }
}

const connectedIssuerDetails = connect(mapStateToProps, mapDispatchToProps)(IssuerVerifierDetails)
export { connectedIssuerDetails as IssuerDetails }
