import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectionAction } from '../../Connection/actions/connectionAction';
import { credentialAction } from '../../Credentials/actions/credentialAction';
import { issuanceAction } from '../../IssueCredential/actions/issuanceAction';
import { workflowAction } from '../../Verification-Workflow/actions/workflowAction';
import { TaskActions } from '../actions/taskActions';
import TaskTypeList from '../components/TaskTypeList';

export interface ITaskProps {
  TaskReducers: any,
  TaskActions: any,
  connectionAction: any,
  ConnectionReducer: any,
  credentialAction: any,
  CredentialReducer: any,
  workflowAction: any,
  WorkflowReducer: any,
  IssuanceReducer: any,
  issuanceAction: any,
  LoginReducer: any
}

export interface ITaskState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  sortBy: string,
}

class Task extends React.Component<ITaskProps, ITaskState> {
  constructor(props: ITaskProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      sortBy: '',
    }
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, sortBy } = this.state;
    this.props.workflowAction.getWorkflowList(defaultSearchFields, sortingOrder, sortBy, item_perPage, pageNo);
    // this.props.issuanceAction.getAllCredential('',10,1)
    this.props.TaskActions.getAllCredDefForInstantTask();
  }
  public render() {
    return (
      <>
        <TaskTypeList {...this.props} />
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const TaskReducers = state.TaskReducers;
  const ConnectionReducer = state.ConnectionReducer;
  const CredentialReducer = state.CredentialReducer;
  const WorkflowReducer = state.WorkflowReducer;
  const IssuanceReducer = state.IssuanceReducer;
  const LoginReducer = state.LoginReducer
  return {
    TaskReducers, ConnectionReducer, CredentialReducer, WorkflowReducer, IssuanceReducer, LoginReducer
  }
}

function mapDispatchToProps(dispatch: any) {

  return {
    TaskActions: bindActionCreators(TaskActions, dispatch),
    connectionAction: bindActionCreators(connectionAction, dispatch),
    credentialAction: bindActionCreators(credentialAction, dispatch),
    workflowAction: bindActionCreators(workflowAction, dispatch),
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
  }
}

const connectedTask = connect(mapStateToProps, mapDispatchToProps)(Task);
export { connectedTask as Task }