import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectionAction } from '../actions/connectionAction';
import _ from 'lodash';
import {
  issuanceAction
} from '../../IssueCredential/actions/issuanceAction';
import { schemaAction } from '../../Schema/actions/schemaAction';
import ExistingConnectionDetailsDeleted from '../components/ExistingConnectionDetails';
import ExistingConnectionsListDeleted from '../components/ExistingConnectionsListDeleted';
import EditConnectionDeleted from '../components/EditConnectionDeleted';
import { t } from 'i18next';


export interface IConnectionDeletedProps {
  connectionAction: any,
  ConnectionReducer: any,
  issuanceAction: any,
  schemaAction: any,
  credentialAction: any,
  CredentialReducer: any,
  selectedConnection: any,
}

export interface IConnectionDeletedState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  connectionSortBy: string,
  filterValue: any,
  isDeleted: any,
}

class ConnectionDeletedComponent extends React.Component<IConnectionDeletedProps, IConnectionDeletedState> {
  constructor(props: IConnectionDeletedProps) {
    super(props);
    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      filterValue: 'all',
      isDeleted: 'true',
    }
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, connectionSortBy, filterValue, isDeleted } = this.state;
    this.props.connectionAction.getAllConnectionsDeleted(defaultSearchFields, filterValue, sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
  }

  componentDidUpdate(prevProps: Readonly<IConnectionDeletedProps>, prevState: Readonly<IConnectionDeletedState>, snapshot?: any): void {
    if (prevProps.ConnectionReducer.selectedConnection !== this.props.ConnectionReducer.selectedConnection) {
      this.props.connectionAction.selectConnection(this.props.ConnectionReducer.selectedConnection);
    }
  }

  componentWillUnmount() {
    this.props.connectionAction.defaultPage()
  }

  public render() {
    let {
      editConnection,
      selectedConnection,
      tHeadforEditConnection,
      listViewOfConnection, selectedConnectionForEdit, totalConnections
    } = this.props.ConnectionReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-md-12 col-lg-8 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:deletedConnections.connectionDetails")}</h1>
                <p>{totalConnections !== undefined ? totalConnections : 0} {t("screens:deletedConnections.totalDeletedConnections")}</p>
              </div>
              <div className="card-body">
                <ExistingConnectionsListDeleted  {...this.props} />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-4 mb-30">
            {!_.isEmpty(selectedConnection) &&
              <ExistingConnectionDetailsDeleted {...this.props} />
            }
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const ConnectionReducer = state.ConnectionReducer;
  return { ConnectionReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    connectionAction: bindActionCreators(connectionAction, dispatch),
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
    schemaAction: bindActionCreators(schemaAction, dispatch),
  }
}

const ConnectionDeleteds = connect(mapStateToProps, mapDispatchToProps)(ConnectionDeletedComponent);
export { ConnectionDeleteds as ConnectionDeletedComponent };
