import axios from "axios";
import { toastr } from "react-redux-toastr";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, organizationConst } from "../../types";
import { history } from '../../../index'
import _ from "lodash";
import { t } from 'i18next';
import moment from "moment";

export const onboardingAction = {

  /**
   * Method for add new organization
   * @APIUrl  /organization/add-organization
   * @param formData It includes organization form fields
   * @returns Add new organization response
   */
  addOrganizationInvitation(formData: any) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call to add new organization */
        return await axios.post(`${config.apiUrl}/organization/add-organization`, formData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            /* Dispatch to clear the data of the dropdown */
            dispatch({
              type: organizationConst.CLEAR_TYPEAHEAD
            })
            /* Inform the response message after send organization invitation on UI */
            toastr.success(`Invitation sent successfully to`, `${data.data.data.addUser.email}`);
            setTimeout(() => {
              /* create random password method call */
              this.getInitialPassword();
            }, 2000);
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Catch the error and throw it on frontend */
        throw error;
      }
    }
  },

  /**
   * Method for add external organization
   * @APIUrl  /add-external-organization
   * @param formData It includes external organization form fields
   * @returns Add external organization response
   */
  addExternalOrganization(formData: any) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call to add external organization */
        return await axios.post(`${config.apiUrl}/organization/add-external-organization`, formData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Catch the error and throw it on frontend */
        throw error;
      }
    }
  },

  /**
   * Method to create a random password
   * @APIUrl /registry/create-random-password
   * @returns It return the created random password
   */
  getInitialPassword() {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call create random password for first time login */
        return await axios.get(`${config.apiUrl}/registry/create-random-password`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            /*  After getting success response from API call Dispatch the get initial password response */
            dispatch({
              type: organizationConst.GET_INITIAL_PASSWORD,
              payload: data
            })
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method for get all organization request
   * @APIUrl /organization/organizations?search_text={defaultSearchFields}&status={status}&sortValue={sortingOrder}&onboardRequestSort={organizationSortBy}&itemsPerPage={item_perPage}&page={pageNo}
   * @param defaultSearchFields Parameter for searching the data in table.
   * @param status Parameter for mentation the request status.
   * @param sortingOrder Parameter for set sorting order e.q ASC or DECD.
   * @param organizationSortBy Parameter for set sort by field e.q organization name.
   * @param item_perPage Parameter for set how many item required for per page.
   * @param pageNo Parameter for set current page no pagination 
   * @returns It return the organization request list
   */
  getAllOrganizationsRequest(
    defaultSearchFields: any,
    status: any,
    sortingOrder: string,
    organizationSortBy: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call to get all organization request and dispatch the its response */
        return await axios.get(`${config.apiUrl}/organization/organizations?search_text=${defaultSearchFields}&status=${status}&sortValue=${sortingOrder}&onboardRequestSort=${organizationSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            /* Boundary condition for get organization request list response */
            let OnboardingList = (!_.isEmpty(data) && data.data && data.data.data && data.data.data.data);
            OnboardingList.forEach((element: any, index: any) => {
              OnboardingList[index].imageUrl = element.logoUrl;
            });
            dispatch({
              type: organizationConst.GET_ALL_REQUEST,
              payload: data,
              OnboardingList,
            })
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch((error: { response: any; }) => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method for reject onboard request
   * @APIUrl /admin/reject-onboard-request/{requestId}
   * @param requestId Parameter for reject specific request
   * @returns It return request reject response
   */
  rejectOnboarding(requestId: any) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call to reject specific onboard request and dispatch the its response */
        return await axios.put(`${config.apiUrl}/admin/reject-onboard-request/${requestId}`, '',
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            dispatch({
              type: organizationConst.ONBOARDING_ORGANIZATION_REJECTED,
              payload: data
            })
            /* Inform the response message after reject request on UI*/
            toastr.info(`${data.data.data.orgName}`, `${data.data.message}`);
            /* Redirect to organization list page */
            history.push('/organization-list')
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },
  /**
   * Method for accept onboard request
   * @APIUrl /admin/accept-onboard-request/{orgId}
   * @param orgId Parameter for accept specific request
   * @returns It return accept onboard request list
   */
  acceptOnboarding(orgId: any) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* The API call to accept the specific onboard request and dispatch its response */
        return await axios.put(`${config.apiUrl}/admin/accept-onboard-request/${orgId}`, '',
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            dispatch({
              type: organizationConst.ONBOARDING_ORGANIZATION_ACCEPTED,
              payload: data
            })
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Inform the response message after accepting the onboard request on UI*/
            toastr.info(`${data.data.data.orgName}`, `${data.data.message}`);
            /* Redirect the organization list page */
            history.push('/organization-list')

          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method for getting the details of specific organization
   * @APIUrl /organization/organizations/{orgId}
   * @param orgId Parameter for getting specific organization
   * @param page 
   * @returns It return specific organization details
   */
  showDetailSelectedExistingOrganization(orgId: object | any, page: string) {
    return async (dispatch: any) => {
      try {
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call for getting specific organization details and dispatch its response */
        return await axios.get(`${config.apiUrl}/organization/organizations/${orgId}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(selectedOnboardingOrganization => {
            dispatch({
              type: organizationConst.SELECTED_ONBOARDING_ORGANIZATION,
              payload: selectedOnboardingOrganization
            })
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Redirect the organization details page */
            history.push('/organization-details');
          })
          .catch((error: { response: any; }) => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method for getting organization details
   * @APIUrl /organization/organization/emailId?emailId={defaultSearchFields}
   * @param defaultSearchFields Parameter for searching the data in table
   * @param type Parameter for checking the organization type
   * @returns It return the organization list
   */
  getEmailIdandOrgName(
    defaultSearchFields: any,
    type: any,
  ) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* API call to get organization details by passing the email id and search text and get response and dispatch the organization details */
        return await axios.get(`${config.apiUrl}/organization/organization/emailId?emailId=${encodeURIComponent(defaultSearchFields)}`,
          { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(data => {
            /* Getting response and check is empty or not as well as check type of user, and then dispatch the related action */
            if (!_.isEmpty(data) && data.data && data.data.data &&
              data.data.data.data && !_.isEmpty(data.data.data.data) && type === 'orgName') {
              dispatch({
                type: organizationConst.SHOW_MESSAGE_FOR_ORGNAME
              })
            } else if (!_.isEmpty(data) && data.data && data.data.data &&
              data.data.data.data && !_.isEmpty(data.data.data.data) && type === 'adminEmail') {
              dispatch({
                type: organizationConst.SHOW_MESSAGE_FOR_ADMIN_EMAIL
              })
            } else {
              if (type === 'orgName') {
                dispatch({
                  type: organizationConst.HIDE_MESSAGE_FOR_ORGNAME
                })
              } else {
                dispatch({
                  type: organizationConst.HIDE_MESSAGE_FOR_ADMIN_EMAIL
                })
              }
            }
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method for generate the connection invitation for organization connection
   * @APIUrl /connection/{connection type}/create
   * @param type = connection type (e.q ORGANIZATION)
   * @returns generated connection invitation
   */
  generateConnectionInvitation(type: string) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');

        /* Api call for generate the connection invitation */
        return await axios.post(`${config.apiUrl}/connection/${type}/create`, "",
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(connectionInvitationResponse => {

            /* After getting success response from API call, validate it and then 
             dispatch the generated connection invitation. */
            if (connectionInvitationResponse.data.data) {
              return (
                dispatch({
                  type: organizationConst.GENERATE_CONNECTION_INVITATION,
                  payload: connectionInvitationResponse.data.data
                })
              )
            }
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error;
      }
    }
  },

  /**
   * Method for get all onboarded organization list
   * @APIUrl /organization/onboard
   * @returns onboard organization list
   */
  getAllOnboardOrganizationList() {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');

        /* Api call for get all onboarded organization list */
        return await axios.get(`${config.apiUrl}/organization/onboard`, { headers: { "Authorization": `Bearer ${token}` } })
          .then((organizationList) => {
            /* After getting success response from API call, validate it and then 
             dispatch the onboarded organization list. */
            if (organizationList.data) {
              return (
                dispatch({
                  type: organizationConst.GET_ALL_ONBOARD_ORGANIZATION,
                  payload: organizationList.data.data
                })
              )
            }
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  /**
   * Method for send invitation to organization
   * @APIUrl /organization/connection-invitation/sent
   * @param sendInvitationBody 
   * @returns  Connection invitation send response
   */
  sendInvitationToOrganization(type: string, sendInvitationBody: any) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* Dispatch method to start the page loader on organization connection page */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })

        return await axios.post(`${config.apiUrl}/connection/${type}/create`, "",
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(connectionInvitationResponse => {

            /* After getting success response from API call, validate it and then 
             dispatch the generated connection invitation. */
            if (connectionInvitationResponse.data.data) {
              sendInvitationBody['connectionInvitation'] = connectionInvitationResponse.data.data.connectionUrl
              sendInvitationBody['connectionId'] = connectionInvitationResponse.data.data.connectionId
              /* Api call for send organization connection invitation */
              return axios.post(`${config.apiUrl}/organization/connection-invitation/send`, sendInvitationBody,
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(sendInvitationResponse => {
                  dispatch({
                    type: organizationConst.SEND_INVITATION_TO_ORGANIZATION,
                  })
                  /* Call updated organization connection list */
                  this.getOrganizationConnectionList("", 10, 1)
                  /* Inform the response message after sending the organization invitation on UI*/
                  toastr.success(sendInvitationResponse.data.message, ``)
                  dispatch({
                    type: loaderConst.LOADER_FALSE
                  })
                })
            }
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
          })

      } catch (error) {
        /* Dispatch method to stop the page loader on connection organization connection page */
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  /**
   * Method for get all organization connection request list
   * @APIUrl /organization/connections?page={pageNo}&items_per_page={item_perPage}&search_text={defaultSearchFields}
   * @param defaultSearchFields This parameter use for searching the content in database
   * @param item_perPage This parameter use for set how many item per page required
   * @param pageNo this parameter use for pagination to set current page
   * @returns Get all organization connection list
   */
  getOrganizationConnectionList(defaultSearchFields: string, item_perPage: number, pageNo: number) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/organization/connections?page=${pageNo}&items_per_page=${item_perPage}&search_text=${defaultSearchFields}`, { headers: { "Authorization": `Bearer ${token}` } })
          .then((organizationConnectionList) => {
            /* After getting success response from API call, validate it and then 
            dispatch the organization connection list response. */
            if (organizationConnectionList.data) {
              /* Object for dispatching the get all organization connection list */

              organizationConnectionList.data.data.data.map((element: any) => {
                return (
                  element['adminEmail'] = element && element.organizationDetails && element.organizationDetails.adminEmail,
                  element['connectionName'] = element && element.organizationDetails && element.organizationDetails.orgName,
                  element['status'] = `${t(`screens:onboardingNewOrganization.${element.status.charAt(0).toLowerCase() + element.status.substr(1)}`)}`,
                  element['createDateTime'] = moment(element.createDateTime).format('MM-DD-YYYY')
                )
              })
              const connectionListPayload = {
                organizationConnectionList: organizationConnectionList.data.data.data,
                totalOrganizationConnection: organizationConnectionList.data.data.totalItems
              }
              return (
                dispatch({
                  type: organizationConst.GET_ALL_ORGANIZATION_CONNECTION,
                  payload: connectionListPayload
                })
              )
            }
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  getOrganizationConnectionListReceived(defaultSearchFields: string, item_perPage: number, pageNo: number) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/organization/connections-received?page=${pageNo}&items_per_page=${item_perPage}&search_text=${defaultSearchFields}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then((invitationList) => {

            /* After getting success response from API call, validate it and then 
            dispatch the organization connection list response. */
            if (invitationList.data) {
              /* Object for dispatching the get all organization connection list */

              invitationList.data.data.data.map((element: any) => {
                element['toOrgName'] = element.toOrgName
                element['toOrgEmail'] = element.toOrgEmail
                element['invitationUrl'] = element.invitationUrl
                element['status'] = `${t(`screens:onboardingNewOrganization.${element.status.charAt(0).toLowerCase() + element.status.substr(1)}`)}`
                element['createDateTime'] = moment(element.createDateTime, 'YYYY-MM-DD HH:mm:ss.SSS Z').format();
              })

              dispatch({
                type: loaderConst.LOADER_FALSE
              })

              if (invitationList.data.data.data.length > 0) {
                this.showSelectedInvitationDetails(invitationList.data.data.data[0])
              }
              return (
                dispatch({
                  type: organizationConst.GET_ALL_INVITATION,
                  payload: invitationList
                })
              )
            }
          })
          .catch(error => {
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },


  /**
   * Method for accept connection invitation by organization
   * @APIUrl /connection-invitation/accept
   * @param connectionInvitation requesting organization connection invitation url for accept connection
   * @returns connection invitation accept response
   */
  // acceptConnection(connectionInvitation: string) {
  acceptConnection(connectionInvitation: any) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* Payload for accepting connection invitation which include connection invitation */
        const apiPayload = connectionInvitation

        /* Dispatch method to start the page loader on organization connection page */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        return await axios.post(`${config.apiUrl}/organization/connection-invitation/accept`, apiPayload, { headers: { "Authorization": `Bearer ${token}` } })
          .then(acceptInvitationResponse => {
            /* After getting success response from API call, validate it and then 
             dispatch the organization connection list response. */
            if (acceptInvitationResponse.data) {
              /* Call updated organization connection list */

              this.getOrganizationConnectionListReceived("", 10, 1)

              /* Dispatch method to stop the page loader on organization connection page */
              dispatch({
                type: loaderConst.LOADER_FALSE
              })
              /* Inform the response message after accept connection on UI*/
              toastr.success(acceptInvitationResponse.data.message, ``)
              return (
                dispatch({
                  type: organizationConst.ACCEPT_INVITATION,
                  payload: acceptInvitationResponse.data.data,
                  orgName: connectionInvitation.toOrgName
                })
              )
            }
          })
          .catch(error => {
            /* Dispatch method to stop the page loader on organization connection page */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
          })

      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  /**
   * Method to verify organization details by calling Kompany API.
   * @param orgId Parameter used to update organization details.
   * @param countryISO Parameter used to get country.
   * @param companyRegistrationNo parameter used to get company registration details.
   * @returns 
   */
  kompanyVerification(orgId: number, countryISO: string, companyRegistrationNo: string) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* Payload for accepting connection invitation which include connection invitation */
        const apiPayload = {
          orgId: orgId,
          countryISO: countryISO,
          companyRegistrationNo: companyRegistrationNo
        }
        /* Dispatch method to start the page loader on organization connection page */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        return await axios.post(`${config.apiUrl}/organization/kompany-verification`, apiPayload, { headers: { "Authorization": `Bearer ${token}` } })
          .then(kompanyVerificationResponse => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            dispatch({
              type: organizationConst.KOMPANY_VERIFICATION,
              payload: kompanyVerificationResponse.data
            })
            this.showDetailSelectedExistingOrganization(orgId, '');
            if (kompanyVerificationResponse.data.code === 200) {
              toastr.info('', `${kompanyVerificationResponse.data.message}`);
            } else {
              toastr.error('', `${kompanyVerificationResponse.data.message}`);
            }
          })
          .catch(error => {
            /* Dispatch method to stop the page loader on organization connection page */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
          })

      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  onCloseSuccess() {
    return async (dispatch: any) => {
      try {
        return (
          dispatch({
            type: organizationConst.CLOSE_SUCCESS,
          })
        )

      } catch (error) {
        throw error
      }
    }
  },

  /**
  * Method for save accept connection invitation by organization
  * @APIUrl /connection-invitation/accept
  * @param connectionInvitation requesting organization connection invitation url for save accept connection
  * @returns connection invitation accept response
  */

  saveConnectionInvitation(connectionInvitation: any) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token = localStorage.getItem('token');
        /* Payload for accepting connection invitation which include connection invitation */
        const apiPayload = connectionInvitation
        /* Dispatch method to start the page loader on organization connection page */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        return await axios.post(`${config.apiUrl}/organization/connection-invitation/save`, apiPayload, { headers: { "Authorization": `Bearer ${token}` } })
          .then(saveInvitationResponse => {
            if (saveInvitationResponse.data) {
              /* Call updated organization connection list */
              this.getOrganizationConnectionListReceived("", 10, 1)
              /* Dispatch method to stop the page loader on organization connection page */
              dispatch({
                type: loaderConst.LOADER_FALSE
              })
              /* Inform the response message after accept connection on UI*/
              toastr.success(saveInvitationResponse.data.message, ``)
              return (
                dispatch({
                  type: organizationConst.SAVE_INVITATION,
                  payload: saveInvitationResponse.data.data
                })
              )
            }
          })
          .catch(error => {
            /* Dispatch method to stop the page loader on organization connection page */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  showSelectedInvitationDetails(selectedInvitationDetails: object | any) {
    return async (dispatch: any) => {
      dispatch({
        type: organizationConst.SHOW_SELECTED_INVITATION_DETAILS,
        payload: selectedInvitationDetails
      })
    }
  },

  clearInvitation() {
    return async (dispatch: any) => {
      dispatch({
        type: organizationConst.CLEAR_SELECTED_INVITATION_DETAILS
      })
    }
  },

  inviteNewOrganization() {
    return async (dispatch: any) => {
      dispatch({
        type: organizationConst.INVITE_NEW_ORG,
      })
    }
  },

  getOrgEmailId(
    defaultSearchFields: any,
    type: any,
  ) {
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');

        return await axios.get(`${config.apiUrl}/organization/check-email?search=${defaultSearchFields}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(dataOrg => {
           if (!_.isEmpty(dataOrg) && dataOrg.data && dataOrg.data.data && dataOrg.data.data && !_.isEmpty(dataOrg.data.data)) {
              if (type === 'email') {
                dispatch({
                  type: organizationConst.CHECK_EXISTING_ORG_EMAILID,
                  payload: type,
                })
              } 
            } else {
              console.log("DATA ORG - not EXIST ")
                dispatch({
                  type: organizationConst.HIDE_CHECK_EXISTING_ORG_EMAILID
                })
              }
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error
      }
    }
  }
}