import * as React from 'react';
import { holderAction } from '../actions/holderAction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { HolderDetails } from '../components/HolderDetails';
import AddHolder from '../components/AddHolder';
import { socket, SocketContext } from 'src/contexts/SocketContext';
import { t } from 'i18next';

export interface IEmployeeProps {
  holderAction: any,
  HolderReducer: any,
}

export interface IEmployeeState {
  defaultSearchFields: any,
  item_perPage: number,
  sortingOrder: string,
  employeeSortBy: string,
  pageNo: number,
}

class Employee extends React.Component<IEmployeeProps, IEmployeeState> {
  static contextType?= SocketContext

  constructor(props: IEmployeeProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      sortingOrder: 'DESC',
      employeeSortBy: '',
      pageNo: 1,
    }
    this.save = this.save.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.refresh = this.refresh.bind(this);
    this.filter = this.filter.bind(this);
  }

  async componentDidMount() {
    await this.connectSocket()
    console.log('componentDidMount - Employee')
  }

  async connectSocket() {
    const { defaultSearchFields, item_perPage, sortingOrder, employeeSortBy, pageNo } = this.state;
    this.props.holderAction.getQRdata("EMPLOYEE");
    this.props.holderAction.getHolderList("EMPLOYEE", defaultSearchFields, item_perPage, pageNo, 'employee', employeeSortBy, sortingOrder);
    if (socket) {
      socket.on('notification', (socketId, payload) => {
        if (payload && payload.data) {
          if (payload.data.type === 'Connection') {
            // this.props.notificationAction.getCountNotification()
            this.props.holderAction.getQRdata("EMPLOYEE");
            this.props.holderAction.getHolderList("EMPLOYEE", defaultSearchFields, item_perPage, pageNo, 'employee', employeeSortBy, sortingOrder);
          }
        }
      })
    } else {
      console.log("Could not estalished a connection")
    }
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortingOrder, employeeSortBy, pageNo } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.holderAction.getHolderList("EMPLOYEE", event.target.value, item_perPage, pageNo, 'employee', employeeSortBy, sortingOrder);
    }
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, employeeSortBy } = this.state;
    this.props.holderAction.getHolderList("EMPLOYEE", defaultSearchFields, item_perPage, page, 'employee', employeeSortBy, sortingOrder);
  }

  sorting = (employeeSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', employeeSortBy: employeeSortBy }) : this.setState({ sortingOrder: 'DESC', employeeSortBy: employeeSortBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.holderAction.getHolderList("EMPLOYEE", defaultSearchFields, item_perPage, pageNo, 'employee', employeeSortBy, sortValue);
  }


  save(formData: any) {
    this.props.holderAction.createHolder("EMPLOYEE", formData);
  }

  showDetails(employee: any) {
    this.props.holderAction.selectEmployee(employee, "employee")
  }

  refresh() {
    this.setState({
      defaultSearchFields: '',
      item_perPage: 10,
      sortingOrder: 'DESC',
      employeeSortBy: '',
      pageNo: 1,
    })
    this.props.holderAction.getQRdata("EMPLOYEE");
    this.props.holderAction.getHolderList("EMPLOYEE", '', 10, 1, 'employee', '', 'DESC');
  }

  filter() {
    console.log("filter")
  }
  public render() {
    const { qrData, tHead, holderList, totalItems, selectHolder } = this.props.HolderReducer;
    return (
      <div className="row gutters-sm min-h-100 animated fadeIn">
        <div className="col-md-12 col-lg-6 mb-30">
          <div className="card nb-card h-100">
            <div className="card-header bg-white border-bottom-0">
              <h1 className="nb-title">{t("screens:onboardingNewHolder.newEmployees")}</h1>
            </div>
            <div className="card-body">
              <TableComponents
                onChangePage={this.onChangePage}
                showElementDetails={this.showDetails}
                tHead={tHead}
                tableContent={holderList}
                totalRecords={totalItems}
                selectedRecord={selectHolder}
                sorting={this.sorting}
                searching={this.onSearch}
                // filter={this.filter}
                refresh={this.refresh}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 mb-30">
          {Object.keys(selectHolder).length > 0 ?
            <HolderDetails HolderReducer={this.props.HolderReducer} holderAction={this.props.holderAction} />
            :
            <AddHolder createHolder={this.save} qrData={qrData} HolderReducer={this.props.HolderReducer} holderAction={this.props.holderAction} type="EMPLOYEE" field="Employee" />
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { loggingIn } = state.LoginReducer;
  const HolderReducer = state.HolderReducer;
  return { loggingIn, HolderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    holderAction: bindActionCreators(holderAction, dispatch),
  }
}

const connectedAddEmployee = connect(mapStateToProps, mapDispatchToProps)(Employee);
export { connectedAddEmployee as Employee };
