import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ListScreenOptions } from "src/types/common.interface";
import { PresentProofState } from "src/View/commonConst";
import TableComponents from "../../../Common Components/Components/TableComponent";
import { presentationProposalAction } from "../action/presentationProposalAction";

/* Interface for Props variables*/
interface IProposalListProps {
    presentationProposalAction: any,
    PresentationProposalReducer: any,
    presentationProposalList: any,
    getAllPresentationRequests: any
}

/* Interface for local states variables*/
interface IProposalListState {
    listScreenOptions: ListScreenOptions
}

class ProposalList extends React.Component<IProposalListProps, IProposalListState> {
    constructor(props: IProposalListProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            listScreenOptions: {
                pageNo: 1,
                itemsPerPage: 10,
                filter: PresentProofState.STATE_PROPOSAL_SENT
            }
        }

        /* Binding the method */
        this.onChangePage = this.onChangePage.bind(this)
        this.selectedPresentationRecord = this.selectedPresentationRecord.bind(this)
        this.search = this.search.bind(this)
        this.refresh = this.refresh.bind(this)
    }    

    /*Method used to set selected page no form pagination */
    onChangePage(page: number) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page
        this.setState({ listScreenOptions })
        this.props.presentationProposalAction.getAllPresentationRequests(listScreenOptions);
    }

    /* Method used to show selected proof request details */
    selectedPresentationRecord(item: any) {
        this.props.presentationProposalAction.showSelectedCredentialDetails(item)
    }

    /* Method used to search the content from list */
    search(event: React.KeyboardEvent<HTMLInputElement> | any) {
        const { listScreenOptions } = this.state;
        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions })
            this.props.presentationProposalAction.getAllPresentationRequests(listScreenOptions);
        }
    }

    /* Method used to refresh the present proof request list */
    refresh() {
        const defaultPayload = {
            pageNo: 1,
            itemsPerPage: 10,
            filter: PresentProofState.STATE_PROPOSAL_SENT
        }
        this.setState({ listScreenOptions: { ...defaultPayload } })
        this.props.presentationProposalAction.getAllPresentationRequests(defaultPayload);
    }

    /* UI to show proof request list */
    public render() {
        const { tHead, presentationList, totalPresentations, selectedPresentationRecord } = this.props.PresentationProposalReducer;
        return (
            <>
                <div>
                    <TableComponents
                        onChangePage={this.onChangePage}
                        showElementDetails={this.selectedPresentationRecord}
                        tHead={tHead}
                        tableContent={presentationList}
                        totalRecords={totalPresentations}
                        searching={this.search}
                        refresh={this.refresh}
                        selectedRecord={selectedPresentationRecord}
                    />
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentationProposalReducer = state.PresentationProposalReducer;

    return { PresentationProposalReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentationProposalAction: bindActionCreators(presentationProposalAction, dispatch),
    }
}

const proposalList = connect(mapStateToProps, mapDispatchToProps)(ProposalList);
export { proposalList as ProposalList };
