import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { presentationProposalAction } from "../action/presentationProposalAction";
import { ProposalList } from "../components/ProposalList";
import CreatePresentationProposal from "../components/CreatePresentationProposal";
import { TaskActions } from "src/View/Task/actions/taskActions";
import { connectionAction } from "src/View/Connection/actions/connectionAction";
import { credentialAction } from "src/View/Credentials/actions/credentialAction";
import { myCredentialsAction } from "src/View/MyCredentials/action/myCredentialsAction";
import { t } from 'i18next';
import { ListScreenOptions } from "src/types/common.interface";
import { PresentProofState } from "src/View/commonConst";
import { presentationNegotiateAction } from "src/View/PresentationNegotiate/actions/PresentationNegotiateAction";

/* Interface for Props variables*/
interface IPresentationProposalProps {
    PresentationProposalReducer: any,
    presentationProposalAction: any,
    MyCredentialReducer: any,
    myCredentialsAction: any,
    TaskReducers: any,
    TaskActions: any,
    connectionAction: any,
    ConnectionReducer: any,
    credentialAction: any,
    CredentialReducer: any,
    LoginReducer: any,
    // presentProofAction: any
    presentationNegotiateAction: any
}

/* Interface for local states variables*/
interface IPresentationProposalState {
    showProofDetails: boolean,
    selectedProofDetails: any,
    selectedConnectionName: string,
    requestedAttributesDetails: any[],
    requestedPredicatesDetails: any[],
    listScreenOptions: ListScreenOptions
    showAttributeValues: boolean
    isDefault: boolean,
    presentationExchangeId: string,
    selectedCredentials: any[],
    credentialsToReveal: any[],
}

class PresentationProposal extends React.Component<IPresentationProposalProps, IPresentationProposalState> {
    constructor(props: IPresentationProposalProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            showProofDetails: false,
            selectedProofDetails: {},
            selectedConnectionName: "",
            requestedAttributesDetails: [],
            listScreenOptions: {
                pageNo: 1,
                itemsPerPage: 10,
                filter: PresentProofState.STATE_PROPOSAL_SENT
            },
            showAttributeValues: true,
            isDefault: true,
            presentationExchangeId: '',
            requestedPredicatesDetails: [],
            selectedCredentials: [],
            credentialsToReveal: [],
        }
    }

    /* Method to call the get presentation requests method */
    componentDidMount() {
        const { listScreenOptions } = this.state
        this.getAllPresentationProposals(listScreenOptions)
    }

    /* Method to get presentation records*/
    getAllPresentationProposals(listScreenOptions: ListScreenOptions) {
        this.props.presentationProposalAction.getAllPresentationRequests(listScreenOptions);
    }


    /* UI to display the all the child component */
    public render() {
        const { presentationList } = this.props.PresentationProposalReducer

        return (
            <>
                <div className="row gutters-sm min-h-100 animated fadeIn">
                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:newProposal.presentationProposalList")}</h1>
                            </div>
                            <div className="col-sm-12">

                                {/* Component for display the proof request list */}
                                <ProposalList
                                    presentationProposalList={presentationList}
                                    getAllPresentationRequests={this.getAllPresentationProposals}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            <>
                                <div className="card-header bg-white border-bottom-0">
                                    <h1 className="nb-title">{t("screens:newProposal.newProposal")}</h1>
                                </div>
                                <div className="card-body">
                                    <CreatePresentationProposal {...this.props} />


                                </div>
                            </>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentationProposalReducer = state.PresentationProposalReducer;
    const TaskReducers = state.TaskReducers;
    const ConnectionReducer = state.ConnectionReducer;
    const CredentialReducer = state.CredentialReducer;
    const MyCredentialReducer = state.MyCredentialReducer;
    const LoginReducer = state.LoginReducer;

    return { PresentationProposalReducer, TaskReducers, ConnectionReducer, CredentialReducer, MyCredentialReducer, LoginReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentationProposalAction: bindActionCreators(presentationProposalAction, dispatch),
        TaskActions: bindActionCreators(TaskActions, dispatch),
        connectionAction: bindActionCreators(connectionAction, dispatch),
        credentialAction: bindActionCreators(credentialAction, dispatch),
        myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
        presentationNegotiateAction: bindActionCreators(presentationNegotiateAction, dispatch),
    }
}

const presentationProposal = connect(mapStateToProps, mapDispatchToProps)(PresentationProposal);
export { presentationProposal as PresentationProposal };
