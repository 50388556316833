import { t } from "i18next"
import { Modal, ModalBody } from "reactstrap"
import { PreferredTrustMethod } from "../constants"

interface TrustDecisionProps {
    isModalShow: boolean
    preferredMethod: PreferredTrustMethod
    closeModal: () => void
    preferredMethodChange: (e: any) => void
    handleVerifyIssuerInTrustRegistry: () => void
}

export const TrustDecisionModal = ({
    isModalShow,
    preferredMethod,
    closeModal,
    preferredMethodChange,
    handleVerifyIssuerInTrustRegistry
}: TrustDecisionProps) => {
    return (
        <Modal isOpen={isModalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="nb-modal"
            toggle={closeModal}
        >
            <ModalBody>
                <div className="col-sm-12 p-3">

                    <div className="row">
                        <div className="col-sm-12 p-3">
                            <div className="text-center">
                                <h3>{t("screens:trustDecisionModal.title")}</h3>
                                <small className="text-muted">
                                    {t("screens:trustDecisionModal.description")}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 p-3">
                            <h5 className="form-group">{t("screens:trustDecisionModal.selectionLabel")}</h5>
                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    id={PreferredTrustMethod.Trust_Decision_Helper}
                                    name={PreferredTrustMethod.Trust_Decision_Helper}
                                    className="custom-control-input"
                                    value='Schema'
                                    onChange={(e) => preferredMethodChange(e)}
                                    checked={preferredMethod === PreferredTrustMethod.Trust_Decision_Helper}
                                />
                                <label className="custom-control-label" htmlFor={PreferredTrustMethod.Trust_Decision_Helper}>
                                    {t("screens:trustDecisionModal.trustDecisionOption")}<br />
                                    <small className="text-muted">{t("screens:trustDecisionModal.trustDecisionOptionNote")}</small>
                                </label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input
                                    type="radio"
                                    id={PreferredTrustMethod.Accept_Credential}
                                    name={PreferredTrustMethod.Accept_Credential}
                                    className="custom-control-input"
                                    value='Credential Definition'
                                    onChange={(e) => preferredMethodChange(e)}
                                    checked={preferredMethod === PreferredTrustMethod.Accept_Credential}
                                />
                                <label className="custom-control-label" htmlFor={PreferredTrustMethod.Accept_Credential}>
                                    {t("screens:trustDecisionModal.acceptCredentialOption")}<br />
                                    <small className="text-muted">{t("screens:trustDecisionModal.acceptCredentialOptionNote")}</small>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <div className="btn-group">

                                <button
                                    type="button"
                                    className="btn text-primary mr-2"
                                    onClick={closeModal}
                                >
                                    {t("screens:trustDecisionModal.cancel")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-nb-outline-primary rounded-pill"
                                    onClick={handleVerifyIssuerInTrustRegistry}
                                >
                                    {t("screens:trustDecisionModal.next")}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </ModalBody>

        </Modal>
    )
}
