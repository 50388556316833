import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { myCredentialsAction } from "../action/myCredentialsAction";
import { t } from 'i18next';
import { displayDateFormat } from "src/utils/date.utils";
import { SchemaDateSuffix } from "src/View/Schema/models/schema.enum";

/* Interface for Props variables*/
interface ICredentialDetailProps {
    MyCredentialReducer: any,
    myCredentialsAction: any,
}
/* Interface for local states variables*/
interface ICredentialDetailState {
    acceptCredential: boolean,
}
class CredentialDetail extends React.Component<ICredentialDetailProps, ICredentialDetailState> {
    constructor(props: ICredentialDetailProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            acceptCredential: false,
        }
        /* Binding the method */
        this.acceptCredential = this.acceptCredential.bind(this);
        this.handleProblemReportSubmit = this.handleProblemReportSubmit.bind(this)
    }

    /*Method to get first request details from list */
    componentDidMount() {
        const { credentialOfferReqData } = this.props.MyCredentialReducer;
        if (credentialOfferReqData.length > 0) {
            this.props.myCredentialsAction.showSelectedCredentialDetails(credentialOfferReqData[0])
        }
    }

    /* Method to accept credential offer. */
    acceptCredential(credentialExchangeId: string) {
        this.props.myCredentialsAction.acceptCredentialOffer(credentialExchangeId);
    }

    handleProblemReportSubmit(reason: string, exchangeId: string) {
        this.props.myCredentialsAction.sendProblemReportForCredentialExchange(reason, exchangeId)
    }

    public render() {
        const { selectedCredentialDetails } = this.props.MyCredentialReducer;
        const attributes = !_.isEmpty(selectedCredentialDetails) && JSON.parse(selectedCredentialDetails.credentialProposalDict).credential_proposal.attributes;

        return (
            <>
                <div className="card nb-card h-100">
                    {!_.isEmpty(selectedCredentialDetails) &&
                        <>
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:myCredentials.credentialDetails")}</h1>
                            </div>
                            <div className="card-body">
                                <div className="mb-5">
                                    <ul className="nb-list">
                                        <li className="pl-1 pr-0">
                                            <div>
                                                <label className="font-weight-bold">{t("screens:myCredentials.credentialName")}: </label>
                                                <label className="font-weight-bold">{!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails?.credentialDefinitionId?.split(':')[4]} </label>
                                            </div>
                                        </li>
                                        <li className="pl-1 pr-0">
                                            <div>
                                                <label className="font-weight-bold">{t("screens:myCredentials.givenName")}: </label>
                                                <label className="font-weight-bold">{!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.connectionName}</label>
                                            </div>
                                        </li>
                                        <li className="pl-1 pr-0 ">
                                            <>
                                                <label className="font-weight-bold">{t("screens:myCredentials.credentialValues")}: </label>
                                                <ul>
                                                    {selectedCredentialDetails && attributes.map((item: any, index: any) => {
                                                        if (!item.hasOwnProperty("mime-type")) {
                                                            return <li key={index} className="remove-underline"><label className="font-weight-bold">{item.name}: {(item.name).includes(SchemaDateSuffix.DATE_SUFFIX) 
                                                                ? displayDateFormat(item.value)
                                                                : item.value}</label></li>
                                                        } else {
                                                            return <>
                                                                <li key={index} className="remove-underline">
                                                                    <label className="font-weight-bold">{item.name}: <img src={item.value} alt={item.name} className="preview" height="50"></img></label>
                                                                </li>
                                                            </>
                                                        }
                                                    })}
                                                </ul>
                                            </>
                                        </li>
                                    </ul>
                                </div>
                                <>
                                    {!_.isEmpty(selectedCredentialDetails) && 
                                        <>
                                        {/* <button type="button" className="btn btn-nb-outline-primary rounded-pill" onClick={() => this.acceptCredential(selectedCredentialDetails.credentialExchangeId)}>
                                           {t("screens:myCredentials.deleteCredential")}
                                        </button> */}
                                        </>
                                    }
                                </>
                            </div>
                            
                        </>
                    }
                    {_.isEmpty(selectedCredentialDetails) &&
                        <>
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:myCredentials.credentialDetails")}</h1>
                            </div>
                            <div className="card-body">
                                <div className="blank-credential-details-body">
                                    <label className="">{t("screens:myCredentials.noRequestReceived")}</label>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const MyCredentialReducer = state.MyCredentialReducer;
    return {
        MyCredentialReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
    }
}

const credentialDetail = connect(mapStateToProps, mapDispatchToProps)(CredentialDetail);
export { credentialDetail as CredentialDetail };
