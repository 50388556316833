import Axios from "axios"
import { toastr } from "react-redux-toastr"
import { config } from "../../../config"
import { loginAction } from "../../Login/actions/loginAction"
import { connectionAction } from "../../Connection/actions/connectionAction"
import {
  credentialNegotiationConstants,
  issuanceConst,
  issueKycCredentials,
  loaderConst,
  offerNegotiateInitiator,
  userConstants
} from "../../types"
import _ from "lodash"
import { t } from 'i18next';
import { ListScreenOptions } from "src/types/common.interface"
import store from "src/store"
import { CredentialRole, CredentialState } from "src/View/commonConst"

export const issuanceAction = {
  selectedCredential(record: object | any, page: string) {
    return async (dispatch: any) => {
      dispatch({
        type: issuanceConst.SELECTED_ISSUANCE_CREDENTIAL,
        payload: { record, page }
      })
    }
  },

  clearIssuanceCredential() {
    return async (dispatch: any) => {
      dispatch({
        type: issuanceConst.CLEAR_ISSUANCE_CREDENTIAL,
      })
      dispatch({
        type: userConstants.CLEAR_SCHEMA
      })
    }
  },

  saveDetails(finalData: any, credentialName: any) {
    return async (dispatch: any) => {
      // debugger;
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let finalDatas = {
          auto_issue: true,
          connection_id: finalData.connection_id,
          cred_def_id: finalData.cred_def_id,
          comment: '',
          attributes: finalData.attributes
        }

        let connectionName = finalData.selectedConnection;
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/issue-credential/send-offer`, finalDatas,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async issueCredential => {
            dispatch({
              type: issuanceConst.ISSUE_CREDENTIAL,
              payload: issueCredential,
              connectionName
            })
            dispatch({
              type: issuanceConst.SUCCESS_ISSUE_CREDENTIAL
            })
            const listScreenOptions = {
              search: '',
              itemsPerPage: 10,
              pageNo: 1,
              sortBy: 'id',
              sort: 'DESC',
              filter: 'all'
            }
            const { organization } = await store.getState().LoginReducer.loggedInUser
            // Reload the credential offer screen
            this.getAllByOrgId(organization.id, listScreenOptions)
            dispatch(connectionAction.getAllIssueCredentialByConnectionId(issueCredential.data.data.connection_id, '', '', '', 0, 0, ''))
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            // TODO toastr.success(`${issueCredential.data.message}`, `${credentialName}`);
            toastr.success(`${t("screens:issueCredentials.toastMsgIssueCredOffer")}`, `${credentialName}`);
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllCredential(
    defaultSearchFields: any,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      // debugger;
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.get(`${config.apiUrl}/cred-def/get-all?search_text=${defaultSearchFields}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async credentials => {
            dispatch({
              type: issuanceConst.GET_ALL_CREDENTIALS,
              payload: credentials
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  issueCredential(finalData: any, id: any) {
    return async (dispatch: any) => {
      // debugger;
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/issue-credential/records/issue`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async response => {
            console.log("getAllIssueKycCredentials-----------------", response)
            dispatch({
              type: issuanceConst.ISSUE_CREDENTIAL,
              payload: response
            })

            const listScreenOptions = {
              search: '',
              itemsPerPage: 10,
              pageNo: 1,
              sortBy: 'id',
              sort: 'DESC',
              filter: 'all'
            }
            this.getAllCredentialRequest(listScreenOptions)

            // toastr.success(`Credential Issued Successfully`, ``);
            toastr.success(`${t("screens:issueCredentials.toastMsgCredIssued")}`, ``);
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  sendCredentialOfferOrNegotiate(finalData: any, initiator: offerNegotiateInitiator) {
    return async (dispatch: any) => {
      // debugger;
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/issue-credential/records/send-offer`, finalData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async response => {
            dispatch({
              type: issuanceConst.OFFER_CREDENTIAL_ISSUE,
              payload: response
            })

            const listScreenOptions = {
              search: '',
              itemsPerPage: 10,
              pageNo: 1,
              sortBy: 'id',
              sort: 'DESC',
              filter: 'all'
            }
            const { organization } = await store.getState().LoginReducer.loggedInUser
            if (initiator === offerNegotiateInitiator.offer) {
              // Reload the credential offer screen
              this.getAllByOrgId(organization.id, listScreenOptions)
            }
            if (initiator === offerNegotiateInitiator.negotiate) {
              // Reload the credential negotiation screen
              listScreenOptions.filter = 'proposal_received'
              this.getAllByOrgId(organization.id, listScreenOptions)
            }
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            toastr.success(`${t("screens:issueCredentials.toastMsgOfferSent")}`, ``);
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectedIssueCredentialByWaitingList(issueCredential: any) {
    return async (dispatch: any) => {
      // debugger;
      try {
        dispatch({
          type: issuanceConst.SELECTE_ISSUE_CREDENTIAL_BY_WAITING_LIST,
          payload: issueCredential
        })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllCredentialRequest(listScreenOptions: ListScreenOptions) {
    return async (dispatch: any) => {

      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        // /issue-credential/records/credential-requests?page=1&itemsPerPage=10&search_text=na&status=all&sortValue=ASC&CredRequestSort=id
        return await Axios.get(`${config.apiUrl}/issue-credential/records/credential-requests`,
          {
            headers: { "Authorization": `Bearer ${token}` }, params: {
              page: listScreenOptions.pageNo,
              items_per_page: listScreenOptions.itemsPerPage,
              search_text: listScreenOptions.search,
              cred_request_sort: listScreenOptions.sortBy,
              sort_value: listScreenOptions.sort,
              filter_text: listScreenOptions.filter
            }
          })
          .then(async credentialsRequest => {
            let updatedPayload: any = credentialsRequest.data.data.data
            updatedPayload.map(async (element: any, index: any) => {

              if (element.credentialDefinitionId === null) {
                updatedPayload[index].credentialDefinitionId = JSON.parse(element.credentialProposalDict).cred_def_id;
              }
              if (!_.isEmpty(element.connection)) {
                // out-of-band
                if (element.connection.id !== null) {
                  updatedPayload[index].connectionName = element.connection.theirLabel;
                } else {
                  updatedPayload[index].connectionName = "";
                }
              }
            })
            dispatch({
              type: issuanceConst.GET_ALL_CREDENTIALS_REQUEST,
              payload: credentialsRequest,
              updatedPayload
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  issueCredentialComponent() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: issuanceConst.ISSUE_ANOTHER_CREDENTIAL,
        })
      } catch (error) {
        throw error;
      }
    }
  },

  showKycDetailsModal(item: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: issueKycCredentials.SHOW_KYC_DETAILS_MODAL,
          payload: item,
        })
      } catch (error) {
        throw error;
      }
    }
  },

  closeKycDetailsModal() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: issueKycCredentials.CLOSE_KYC_DETAILS_MODAL,
        })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllByOrgId(orgId: number, listScreenOptions: ListScreenOptions) {
    return async (dispatch: any) => {
      // debugger;
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })

        let token = localStorage.getItem('token');

        return await Axios.get(`${config.apiUrl}/issue-credential/organization/${orgId}`,
          {
            headers: { "Authorization": `Bearer ${token}` }, params: {
              page: listScreenOptions.pageNo,
              items_per_page: listScreenOptions.itemsPerPage,
              search_text: listScreenOptions.search,
              credential_sort: listScreenOptions.sortBy,
              sort_value: listScreenOptions.sort,
              filter_text: listScreenOptions.filter
            }
          })
          .then(async credentialsRequest => {
            let updatedPayload: any = credentialsRequest.data.data.data
            updatedPayload.map(async (element: any, index: any) => {

              if (element.role && element.role === CredentialRole.ROLE_HOLDER && element.state === CredentialState.STATE_ACKED) {
                updatedPayload[index].state = 'Stored in Wallet'
              }
              if (element.credentialDefinitionId === null) {
                updatedPayload[index].credentialDefinitionId = JSON.parse(element.credentialProposalDict).cred_def_id;
              }
              if (!_.isEmpty(element.connection)) {
                if (element.connection.id !== null) {
                  updatedPayload[index].connectionName = element.connection.theirLabel;
                } else {
                  updatedPayload[index].connectionName = "";
                }
              }
              else {
                updatedPayload[index].connectionName = "";
              }
            })
            dispatch({
              type: issuanceConst.GET_ALL_CREDENTIALS_RECORD,
              payload: credentialsRequest,
              updatedPayload
            })
            dispatch({
              type: issuanceConst.GET_ALL_CREDENTIAL_PROPOSAL,
              payload: credentialsRequest,
              updatedPayload
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  showNegotiateProposalModal(item: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: credentialNegotiationConstants.SHOW_NEGOTIATE_PROPOSAL_MODAL,
          payload: item,
        })
      } catch (error) {
        throw error;
      }
    }
  },

  closeNegotiateProposalModal() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: credentialNegotiationConstants.CLOSE_NEGOTIATE_PROPOSAL_MODAL,
        })
      } catch (error) {
        throw error;
      }
    }
  },

  showSelectedCredentialDetails(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: issuanceConst.SELECTED_CREDENTIAL_DETAILS,
        payload: item
      })
    }
  }
}
