import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import NoRecordFoundComponent from './NoRecordFound';
import PaginationComponent from './Pagination';
import SearchInput from './SearchInput';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';
import { CredentialState } from 'src/View/commonConst';

// Table component is required following props 
// For Pagination - onChangePage(), totalRecords
// showElementDetails - View the particular record on modal
// tHead - Header of table 
// tableContent - TBoday data
// selectedRecord - To show which record is selected 
// sorting - for sorting table
// searching - for searching 
// filter - for add filter on contents
// refresh - for call get api
//editElementOfScheduleTask - for Schedule Task List
// onChangeFilter = on Change for Filter

export interface ITableComponentsProps {
  onChangePage?: Function | any,
  showElementDetails?: Function | any,
  sorting?: Function | any,
  searching?: Function | any
  filter?: Function | any
  refresh?: Function | any,
  tHead: Array<any>,
  tableContent: any,
  selectedRecord?: any,
  totalRecords?: number | any,
  showElementOffer?: any,
  showElementIssue?: any,
  deleteElementDetails?: any,
  changeIssuer?: Function | any,
  editElementOfScheduleTask?: Function | any,
  checkVerificationStatus?: Function | any,
  onChangeFilter?: Function | any,
  isNewMessageReceived?: boolean | any,
  onStopOrganization?: Function | any,
  onStartOrganization?: Function | any,
  onRemoveOrganization?: Function | any,
  showTotalRecords?: boolean | any
}

export interface ITableComponentsState {
  sortingOrder: any,
  SortBy: any,
  selectedValue: any,
  selectedIssuerData: any,
  clearTypeaheadstate: boolean,
  selectedIndex: any,
  issuerDeptIndex: any,
}

export default class TableComponents extends React.Component<ITableComponentsProps, ITableComponentsState> {
  constructor(props: ITableComponentsProps) {
    super(props);

    this.state = {
      sortingOrder: 'DESC',
      SortBy: '',
      selectedValue: '',
      selectedIssuerData: {},
      clearTypeaheadstate: false,
      selectedIndex: 0,
      issuerDeptIndex: 0,
    }
  }

  public render() {
    let {
      onChangePage,
      showElementDetails,
      searching,
      filter,
      tHead,
      tableContent,
      selectedRecord,
      totalRecords,
      refresh,
      showElementIssue,
      showElementOffer,
      deleteElementDetails,
      onChangeFilter,
      isNewMessageReceived,
      onStopOrganization,
      onStartOrganization,
      onRemoveOrganization,
      showTotalRecords
    } = this.props;

    return (
      <>
        <div className='row'>
          <div className='col-lg-12'>
            {searching ?
              <SearchInput search={searching} filter={filter} refresh={refresh} filterChange={onChangeFilter} isNewMessageReceived={isNewMessageReceived} showTotalRecords={showTotalRecords} totalRecords={totalRecords} /> :
              <></>
            }

            <div className="table-responsive">
              <table className="table nb-table">
                <thead>
                  <tr>
                    {tHead.map((ele: any, index: any) => {
                      let newtmp = Object.values(ele)[0];
                      return (
                        <th key={index} className={
                          newtmp === 'INVITATION KEY' ? 'hidden-250' : newtmp === 'ACTIVE' || newtmp === 'ACTION' || newtmp === 'ON-LEDGER' || newtmp === 'IS PUBLIC DID' ||
                            newtmp === 'OPT-IN' || newtmp === 'KYC RESULT' || newtmp === 'STATUS' || newtmp === 'Status' || newtmp === 'STATUT' || newtmp === 'Statut' || newtmp === '1ST KYC STATUS' || newtmp === '2ND KYC STATUS'
                            || newtmp === 'ISSUANCE' || newtmp === 'DETAILS' || newtmp === 'HOLDER DETAILS' || newtmp === 'NUMBER OF 1ST KYC' || newtmp === 'NUMBER OF 2ND KYC'
                            || newtmp === 'VERIFY DETAILS' || newtmp === 'DRIVER LICENSE' || newtmp === 'PASSPORT' || newtmp === 'PROOF-REQUEST' || newtmp === 'DEMANDE DE PREUVE' || newtmp === 'NAVIGATE' ? 'text-center' : ''}
                        // onClick={() => sorting(ele.label)}
                        >
                          {ele.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableContent.length > 0 && tableContent.map((ele: any, indexx: any) => {
                    let tmpArray = Object.keys(ele);
                    return (
                      <tr className={selectedRecord && selectedRecord.id === ele.id ? 'activeRow' : ""} key={indexx}>
                        {tHead.length > 0 && tHead.map((key: any, index: any) => {
                          if (Object.values(key)[1] === 'details') {
                            return (
                              <td className="text-center" key={index}>
                                <button className="btn btn-sm btn-nb-gradient-primary"
                                  onClick={(e) => showElementDetails(ele)}
                                  disabled={ele.state === "request_sent" ? true : false}
                                >
                                  <i className="fas fa-eye"></i> {t("screens:tableComponent.details")}
                                </button>
                              </td>
                            );
                          } else if (Object.values(key)[1] === 'view') {
                            return (
                              <td key={index}>
                                <button className="btn btn-sm btn-nb-outline-primary"
                                  onClick={(e) => showElementDetails(ele)}
                                >
                                  {t("screens:tableComponent.view")}
                                </button>
                              </td>
                            );
                          }
                          else if (Object.values(key)[1] === 'credViewBtn') {
                            return (
                              <td key={index} className="text-center">
                                <button className="btn btn-sm btn-nb-outline-primary"
                                  onClick={(e) => showElementDetails(ele)}
                                  disabled={ele.isRevoked ? true : false}
                                >
                                  {t("screens:tableComponent.viewDetails")}
                                </button>
                              </td>
                            )
                          }
                          else if (Object.values(key)[1] === 'proposalViewBtn') {
                            return (
                              <td key={index} className="text-center">
                                <button className="btn btn-sm btn-nb-gradient-primary"
                                  onClick={(e) => showElementDetails(ele)}
                                  disabled={ele.isRevoked ? true : false}
                                >
                                  <i className="fas fa-eye"></i>&nbsp;{t("screens:tableComponent.verifyDetails")}
                                </button>
                              </td>
                            )
                          }
                          else if (Object.values(key)[1] === 'revokeViewBtn') {
                            return (
                              <td key={index} className="text-center">
                                <button className="btn btn-sm btn-nb-gradient-primary"
                                  onClick={(e) => showElementDetails(ele)}
                                >
                                  <i className="fas fa-eye"></i>&nbsp;{t("screens:tableComponent.verifyDetails")}
                                </button>
                              </td>
                            )
                          }
                          else if (Object.values(key)[1] === 'edit') {
                            return (
                              <td key={index}>
                                <button className="btn btn-sm btn-nb-outline-primary"
                                  onClick={(e) => showElementDetails(ele)}
                                >
                                  {t("screens:tableComponent.edit")}
                                </button>
                              </td>
                            )
                          } else if (Object.values(key)[1] === 'actions') {
                            return (
                              <td className="text-center" key={index}>
                                <button type="button" className="btn nb-link mr-1" onClick={(e) => showElementDetails(ele)}>
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                                <button type="button" className="btn nb-link"
                                  onClick={(e) => deleteElementDetails(ele)}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </td>
                            )
                          } else if (Object.values(key)[1] === 'threeBtn') {
                            return (
                              <td className="text-center" key={index}>

                                <button type="button" className="btn nb-link mr-1" onClick={(e) => showElementDetails(ele)}
                                  disabled={ele['credentialRequired'] === '--' ? true : false}
                                >
                                  <i className="fas fa-eye" ></i>
                                </button>
                                {/* <button type="button" className="btn nb-link mr-1">
                                  <i className="fas fa-pencil-alt" onClick={(e) => editElementOfScheduleTask(ele)}></i>
                                </button>
                                <button type="button" className="btn nb-link"
                                  onClick={(e) => deleteElementDetails(ele)}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </button> */}
                              </td>
                            )
                          } else if (Object.values(key)[1] === 'register') {
                            return (
                              <td className="text-center" key={index}>
                                {ele.isNym === true && ele.agentSpinupStatus === 2 ?
                                  <label className="badge badge-success font-weight-normal"><h6>{t("screens:tableComponent.onboarded")}</h6></label>
                                  :
                                  <button type="button" className="btn btn-sm btn-nb-outline-primary"
                                    onClick={(e) => showElementDetails(ele)}
                                  >
                                    {t("screens:tableComponent.register")}
                                  </button>
                                }
                              </td>
                            )
                          }
                          else if (Object.values(key)[1] === 'issuerNames') {
                            return (
                              <td key={index}>
                                <select className="custom-select slimScroll" multiple>
                                  {
                                    !_.isEmpty(ele) && !_.isEmpty(ele.issuerNames) ?
                                      ele.issuerNames.map((names: any, namesidx: any) => {
                                        return (
                                          <option value={namesidx}>{names.name}</option>
                                        )
                                      })
                                      :
                                      <option value="0">-----</option>
                                  }
                                </select>
                              </td>
                            )
                          } else if (Object.values(key)[1] === 'issuerDept') {
                            return (
                              <td key={index}>
                                <select className="custom-select slimScroll" multiple>
                                  {
                                    !_.isEmpty(ele) && !_.isEmpty(ele.issuerDept) ?
                                      ele.issuerDept.map((dept: any, deptidx: any) => {
                                        return (
                                          <option value={deptidx}>{dept.dept}</option>
                                        )
                                      })
                                      :
                                      <option value="0">-----</option>
                                  }
                                </select>
                              </td>
                            )

                          } else if (Object.values(key)[1] === 'updateOrganizationStatus') {
                            // console.log("ele-----", ele);
                            return (
                              <td className="text-center">
                                <p>
                                  <h2>
                                    <span className='mr-2'>
                                      <button disabled={ele.organizationRunningStatus === 'Stop' || ele.organizationRunningStatus === 'Removed'} onClick={(e) => onStopOrganization(ele)}><i className="far fa-stop-circle text-warning" title='Stop organization'></i></button>
                                    </span>
                                    <span className='mr-2'>
                                      <button disabled={ele.organizationRunningStatus === 'Active' || ele.organizationRunningStatus === 'Removed'} onClick={(e) => onStartOrganization(ele)}><i className="far fa-play-circle text-success" title='Start organization'></i></button>
                                    </span>
                                    <span>
                                      <button disabled={ele.organizationRunningStatus === 'Removed'} onClick={(e) => onRemoveOrganization(ele)}><i className="far fa-trash-alt text-danger" title='Remove organization'></i></button>
                                    </span>
                                  </h2>
                                </p>
                              </td>
                            )
                          } else if (Object.values(key)[1] === 'delete') {
                            return (
                              <td className="text-center" key={index}>
                                <button type="button" className="btn nb-link"
                                  onClick={(e) => deleteElementDetails(ele)}>
                                  <i className="far fa-trash-alt text-danger"></i>
                                </button>
                              </td>
                            )
                            // TODO Redirect DETAILS - NAVIGATE
                          } else if (Object.values(key)[1] === 'navigate') {
                            return (
                              <td className="text-center" key={index}>
                                <button type="button" className="btn nb-link"
                                  onClick={(e) => deleteElementDetails(ele)}>
                                  <i className="far fa-trash-alt text-danger"></i>
                                </button>
                              </td>
                            )


                          }
                          return tmpArray.length > 0 && tmpArray.map((item: any, index: any) => {
                            let tmp = Object.values(key)[1];
                            if (tmp === item) {
                              return (
                                <td key={index} className={
                                  tmp === 'state'
                                    ? `capitalize text-center ${ele[item] === CredentialState.STATE_ACKED && 'text-success'} ${(ele[item] === CredentialState.STATE_CREDENTIAL_REVOKED || ele[item] === 'revoked') && 'text-danger'} 
                                    ${ele[item] !== CredentialState.STATE_ACKED && ele[item] !== CredentialState.STATE_CREDENTIAL_REVOKED && 'text-primary'}`
                                    : tmp === 'connectionDID'
                                      ? 'hidden-250'
                                      : tmp === 'connectionId' ? 'hidden-250'
                                        : tmp === 'isActive' || tmp === 'isNym' || tmp === 'agentSpinupStatus' || tmp === 'optIn' || tmp === 'kycResult' || tmp === 'activeState' || tmp === 'connectionStatus'
                                          || tmp === 'firstKycStatus' || tmp === 'secondKycStatus' || tmp === 'status' || tmp === 'verificationStatus' || tmp === 'verifiedStatus' || tmp === 'isOnBoarded' || tmp === 'kycStatus'
                                          || tmp === 'agentStatus' || tmp === 'issuance' || tmp === 'kycDrivingLicenceCount' || tmp === 'kycPassportCount' || tmp === 'organizationRunningStatus'
                                          ? 'text-center' :
                                          ''
                                } style={{ color: ele[item] === 'sent' ? '#76c5bc' : ele[item] === 'failed' ? '#c0322f' : 'none' }}

                                >
                                  {
                                    item === 'createDateTime' ?
                                      // moment(ele[item]).format('MM-DD-YYYY') :
                                      moment(ele[item]).format('DD MMM YYYY hh:mm A') :
                                      item === 'credentialDefinitionId' ? ele[item].split(':')[4] :
                                        item === 'verified' && ele[item] === "true" && (ele['proofState'] === 'verified') ? <p className="text-success">{t("screens:tableComponent.verified")}</p> :
                                          item === 'verified' && ele[item] === "false" ? <p className="text-danger">{t("screens:tableComponent.revoked")}</p> :
                                            item === 'verified' && ((ele[item] === "true" || ele[item] === "" || ele[item] === null) && (ele['state'] === 'presentation_acked' || ele['proofState'] === 'presentation_acked')) ? <p className="text-success">{t("screens:tableComponent.done")}</p> :
                                              item === 'verified' && (ele[item] === "" || ele[item] === null) && (ele['state'] === 'abandoned' || ele['proofState'] === 'abandoned') ? <p className="text-warning">{t("screens:tableComponent.declined")}</p> :
                                                item === 'verified' && (ele[item] === "" || ele[item] === null) ? <p className="text-warning">{t("screens:tableComponent.pending")}</p> :
                                                  item === 'state' && ele[item] === t("screens:myCredentials.constStoredWallet") ? <span className='text-success'>{ele[item]}</span> :
                                                    item === 'state' && ele[item] === t("screens:myCredentials.constCredentialRevoked") ? <span className='text-danger'>{ele[item]}</span> :
                                                      item === 'state' ? ele[item].replace(/_/g, ' ') :
                                                        item === 'orgName' ? <><img alt='logo' src={ele['imageUrl'] ? ele['imageUrl'] : ele['imageUrl'] === null ? images.placeholderRectangleSolid : ele['logoUrl'] === null ? images.placeholderRectangleSolid : ele['logoUrl']} className="user-image" /> {ele['orgName']} </> :
                                                          item === 'name' ? <><img alt='profile' src={ele['profilePic'] && ele['profilePic'] !== null ? ele['profilePic'] : images.placeholderRectangleSolid} className="user-image" /> {ele['name']} </> :
                                                            item === 'isOnBoarded' && ele[item] === 1 ? <label className="badge badge-warning font-weight-normal"><h6>{t("screens:tableComponent.pending")}</h6></label> :
                                                              item === 'isOnBoarded' && ele[item] === 2 ? <label className="badge badge-success font-weight-normal"><h6>{t("screens:tableComponent.accepted")}</h6></label> :
                                                                item === 'isOnBoarded' && ele[item] === 3 ? <label className="badge badge-danger font-weight-normal"><h6>{t("screens:tableComponent.reject")}</h6></label> :
                                                                  item === 'isOnBoarded' && ele[item] === 0 ? <label className="badge badge-secondary font-weight-normal"><h6>{t("screens:tableComponent.requestSent")}</h6></label> :
                                                                    item === 'firstKycStatus' && ele[item] === "" ? 'N/A' :
                                                                      item === 'firstKycStatus' && ele[item] === "passed" ? <i className="fa fa-check text-success-light"></i> :
                                                                        item === 'firstKycStatus' && ele[item] === "failed" ? <label className="badge badge-warning font-weight-normal"><h6>{t("screens:tableComponent.pending")}</h6></label> :

                                                                          item === 'secondKycStatus' && ele[item] === "" ? 'N/A' :
                                                                            item === 'secondKycStatus' && ele[item] === "passed" ? <i className="fa fa-check text-success-light"></i> :
                                                                              item === 'secondKycStatus' && ele[item] === "failed" ? <label className="badge badge-warning font-weight-normal"><h6>{t("screens:tableComponent.pending")}</h6></label> :

                                                                                item === 'firstKycExpiryDate' && ele[item] === "" ? 'N/A' :
                                                                                  item === 'secondKycExpiryDate' && ele[item] === "" ? 'N/A' :
                                                                                    // TODO item === 'connectionStatus' && ele[item] === "Sent" ? <span className="text-primary">{ele[item].charAt(0).toUpperCase() + ele[item].slice(1)}</span> :
                                                                                    //   item === 'connectionStatus' && ele[item] === "Connected" ? <span className="text-success">{ele[item].charAt(0).toUpperCase() + ele[item].slice(1)}</span> :
                                                                                    item === 'connectionStatus' && ele[item] === "Sent" ? <span className="text-primary">{t("screens:tableComponent.sent")}</span> :
                                                                                      item === 'connectionStatus' && ele[item] === "Connected" ? <span className="text-success">{t("screens:tableComponent.connected")}</span> :
                                                                                        // TODO NBOK-892 item === 'connectionStatus' && ele[item] === "Received" ? <span className="text-success">{t("screens:tableComponent.received")}</span> :
                                                                                        item === 'status' && ele[item] === 0 ? <i className="fas fa-check-circle text-success"></i> :
                                                                                          item === 'status' && ele[item] === 1 ? <i className="fas fa-pause-circle text-warning"></i> :
                                                                                            item === 'showKycDetails' ?
                                                                                              <button className="btn btn-sm btn-nb-outline-primary" onClick={(e) => showElementDetails(ele)}><i className="fas fa-eye"></i> {t("screens:tableComponent.details")} </button>
                                                                                              :
                                                                                              item === 'kycStatus' && ele[item] === "" ? 'N/A' :
                                                                                                item === 'kycStatus' && ele[item] === 'offer_sent' ? <span className="badge badge-success">{t("screens:tableComponent.offerSent")}</span> :
                                                                                                  item === 'kycStatus' && ele[item] === 'credential_acked' || item === 'kycStatus' && ele[item] === 'credential_issued' ?
                                                                                                    <span className="badge badge-success">{t("screens:tableComponent.issued")}</span> :
                                                                                                    item === 'kycStatus' && ele[item] === 'request_received' ? <span className="badge badge-danger">{t("screens:tableComponent.pendingKyc")}</span> :
                                                                                                      item === 'kycStatus' && ele[item] === 'proposal_received' ? <span className="badge badge-warning">{t("screens:tableComponent.proposalReceivedKyc")}</span> :

                                                                                                        item === 'kycResult' && ele[item] === "" ? 'N/A' :
                                                                                                          item === 'kycResult' && ele[item] === 'passed' ? <i className="fas fa-check-circle text-success"></i> :
                                                                                                            item === 'kycResult' && ele[item] === 'failed' ? <i className="fas fa-check-circle text-success"></i> :
                                                                                                              item === 'issuebtnkycStatus' && ele[item] === 'offer_sent' ? <button type="button" className="btn btn-link" disabled={true} >{t("screens:tableComponent.offerSentKyc")}</button> :
                                                                                                                item === 'issuebtnkycStatus' && ele[item] === 'Approved' ? '' :
                                                                                                                  item === 'issuebtnkycStatus' && ele[item] === 'credential_acked' ? <button type="button" className="btn btn-link" disabled={true} >{t("screens:tableComponent.savedInWallet")}</button> :
                                                                                                                    item === 'issuebtnkycStatus' && ele[item] === 'credential_issued' ? <button type="button" className="btn btn-link" disabled={true} >{t("screens:tableComponent.credIssuedt")}</button> :
                                                                                                                      item === 'issuebtnkycStatus' && ele[item] === 'proposal_received' ? <button type="button" className="btn btn-link" onClick={(e) => showElementOffer(ele)}>{t("screens:tableComponent.offer")}</button> :
                                                                                                                        item === 'issuebtnkycStatus' && ele[item] === 'request_received' ? <button type="button" className="btn btn-link" onClick={(e) => showElementIssue(ele)}>{t("screens:tableComponent.issue")}</button> :

                                                                                                                          item === 'isActive' && ele[item] === true ? <i className="fas fa-check-circle text-success"></i> :
                                                                                                                            item === 'isActive' && ele[item] === false ? <i className="fas fa-times-circle text-danger"></i> :
                                                                                                                              item === 'agentSpinupStatus' && ele[item] === 2 ? <i className="fas fa-check-circle text-success-light"></i> :
                                                                                                                                item === 'agentSpinupStatus' && ele[item] === 0 ? <i className="fas fa-times-circle text-danger"></i> :
                                                                                                                                  item === 'agentSpinupStatus' && ele[item] === 1 ? <i className="fas fa-times-circle text-danger"></i> :
                                                                                                                                    item === 'isNym' && ele[item] === true ? <i className="fas fa-check-circle text-success-light"></i> :
                                                                                                                                      item === 'isNym' && ele[item] === false ? <i className="fas fa-times-circle text-danger"></i> :
                                                                                                                                        item === 'agentStatus' && ele[item] === "true" ? <span className="text-success">{t("screens:tableComponent.running")}</span> :
                                                                                                                                          item === 'agentStatus' && ele[item] === "false" ? <span className="text-danger">{t("screens:tableComponent.stop")}</span> :
                                                                                                                                            item === 'activeState' && ele[item] === "true" ? <i className="fas fa-check-circle text-success"></i> :
                                                                                                                                              item === 'activeState' && ele[item] === "false" ? <i className="fas fa-check-circle text-success"></i> :
                                                                                                                                                item === 'verifiedStatus' && ele[item] === "verified" ? <h5><span className="badge badge-success">{t("screens:tableComponent.verified")}</span></h5> :
                                                                                                                                                  item === 'verifiedStatus' && ele[item] === "failed" ? <h5><span className="badge badge-danger">{t("screens:tableComponent.failed")}</span></h5> :
                                                                                                                                                    item === 'optIn' && ele[item] === true ? <i className="fas fa-check-circle text-success"></i> :
                                                                                                                                                      item === 'optIn' && ele[item] === false ? <i className="fas fa-times-circle text-danger"></i> :
                                                                                                                                                        item === 'issuance' && ele[item] === null ? 0 :
                                                                                                                                                          item === 'startDate' && ele[item] !== null ? moment(ele[item]).local().format('MM-DD-YYYY HH:MM') :
                                                                                                                                                            item === 'agentEndpoint' && ele[item] == null ? 'N/A' : item === 'agentEndpoint' && ele[item] !== null ? item === 'agentEndpoint' && ele[item] :
                                                                                                                                                              item === 'verificationStatus' && ele[item] === 'verified' ? <span className="text-success">{t("screens:tableComponent.credentialVerified")}</span> :
                                                                                                                                                                item === 'verificationStatus' && ele[item] === 'request_sent' ? <span className="text-warning">{t("screens:tableComponent.requestSentVerif")}</span> :
                                                                                                                                                                  item === 'verificationStatus' && ele[item] === 'presentation_received' ? <span className="text-primary">{t("screens:tableComponent.proofReceived")}</span> :
                                                                                                                                                                    item === 'verificationStatus' && ele[item] === 'proposal_received' ? <span className="text-secondary">{t("screens:tableComponent.proposalReceived")}</span> :
                                                                                                                                                                      item === 'stverified' && ele[item] === "true" ? <i className="fas fa-check-circle text-success"></i> :
                                                                                                                                                                        item === 'stverified' && ele[item] === "false" ? <i className="fas fa-times-circle text-danger"></i> :
                                                                                                                                                                          item === 'stverified' && ele[item] == null ? <span className="badge badge-danger">{t("screens:tableComponent.pendingVerif")}</span> :
                                                                                                                                                                            item === "totalMessageReceived" && ele[item] > 0 ? <><h5><span className="badge badge-danger">{ele[item]}</span></h5></> :
                                                                                                                                                                              item === "lastReceivedMessageDate" ? ele[item] !== "--" ? moment(ele[item]).format('MM-DD-YYYY') : '--' :
                                                                                                                                                                                item === "organizationRunningStatus" && ele[item] === 'Stop' ? <label className="badge badge-warning font-weight-normal"><h6>{t("screens:tableComponent.stopStatus")}</h6></label> :
                                                                                                                                                                                  // ele[item] === 'Active' ? <label className="badge badge-success font-weight-normal"><h6>{t("screens:tableComponent.activeStatus")}</h6></label> :
                                                                                                                                                                                  ele[item] === 'Active' ? <span className="badge badge-light nb-badge nb-badge-green-tc"><i className="fa fa-2xs fa-solid fa-circle  mr-2"></i>{t("screens:tableComponent.activeStatus")}</span> :
                                                                                                                                                                                    ele[item] === 'Removed' ? <label className="badge badge-danger font-weight-normal"><h6>{t("screens:tableComponent.removedStatus")}</h6></label> :
                                                                                                                                                                                    ele[item] === 'Sent' ? <span className="badge badge-light nb-badge nb-badge-blue"><i className="fa fa-2xs fa-solid fa-circle  mr-2"></i>{t("screens:tableComponent.sentStatus")}</span> :
                                                                                                                                                                                      ele[item]


                                  }
                                </td>
                              );
                            }
                          });
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-sm-12 text-center">
            {tableContent.length === 0 ?
              <NoRecordFoundComponent />
              :
              totalRecords > 10 ?
                <PaginationComponent
                  items={totalRecords} onChangePage={onChangePage}
                  initialPage={1} />
                : null
            }
          </div>
        </div>
      </>
    );
  }

}
