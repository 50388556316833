import * as React from 'react';
import { adminAction } from '../actions/adminAction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import AddAdmin from '../components/AddAdmin';
import { AdminDetails } from '../components/AdminDetails';
import { t } from 'i18next';
import { socket, SocketContext } from 'src/contexts/SocketContext';

export interface IAdminProps {
  adminAction: any,
  AdminReducer: any,
}

export interface IAdminState {
  defaultSearchFields: any,
  item_perPage: number,
  sortingOrder: string,
  adminSortBy: string,
  pageNo: number,
}
class Admin extends React.Component<IAdminProps, IAdminState> {
  static contextType?= SocketContext
  
  constructor(props: IAdminProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      sortingOrder: 'DESC',
      adminSortBy: '',
      pageNo: 1,
    }
    this.save = this.save.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.filter = this.filter.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  async componentDidMount() {
    await this.connectSocket()
    // console.log('componentDidMount - Admin ')
  }

  async connectSocket() {
    const { defaultSearchFields, item_perPage, sortingOrder, adminSortBy, pageNo } = this.state;
    this.props.adminAction.getAdminsByOrgId("ADMIN", defaultSearchFields, item_perPage, pageNo, 'admin', adminSortBy, sortingOrder);
    this.props.adminAction.getQRdata("ADMIN");
    if (socket) {
      socket.on('notification', (socketId, payload) => {
        console.log(`Admin - SOCKET - notification - socketId: ${socketId}  payload: ${JSON.stringify(payload)}`);
        if (payload && payload.data) {
          if (payload.data.type === 'Connection') {
            console.log(`Admin - receive Notification`)
            // this.props.notificationAction.getCountNotification()
            this.props.adminAction.getAdminsByOrgId("ADMIN", defaultSearchFields, item_perPage, pageNo, 'admin', adminSortBy, sortingOrder);
          }
        }
      })
    } else {
      console.log("Could not estalished a connection")
    }
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortingOrder, adminSortBy, pageNo } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.adminAction.getAdminsByOrgId("ADMIN", event.target.value, item_perPage, pageNo, 'admin', adminSortBy, sortingOrder);
    }
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, adminSortBy } = this.state;
    this.props.adminAction.getAdminsByOrgId("ADMIN", defaultSearchFields, item_perPage, page, 'admin', adminSortBy, sortingOrder);
  }

  sorting = (adminSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', adminSortBy: adminSortBy }) : this.setState({ sortingOrder: 'DESC', adminSortBy: adminSortBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.adminAction.getAdminsByOrgId("ADMIN", defaultSearchFields, item_perPage, pageNo, 'admin', adminSortBy, sortValue);
  }

  save(formData: any) {
    this.props.adminAction.createAdmin("ADMIN", formData);
  }

  showDetails(admin: any) {
    this.props.adminAction.selectAdmin(admin, "admin")
  }

  filter() {
    console.log("filter");
  }

  refresh() {
    this.setState({
      defaultSearchFields: '',
      item_perPage: 10,
      sortingOrder: 'DESC',
      adminSortBy: '',
      pageNo: 1
    })
    this.props.adminAction.getAdminsByOrgId("ADMIN", '', 10, 1, 'admin', '', 'DESC');
  }

  public render() {
    const { qrData, tHead, adminList, totalItems, selectAdmin } = this.props.AdminReducer;
    return (
      <div className="row gutters-sm min-h-100 animated fadeIn">
        <div className="col-md-12 col-lg-6 mb-30">
          <div className="card nb-card h-100">
            <div className="card-header bg-white border-bottom-0">
              <h1 className="nb-title">{t("screens:onboardingNewAdmins.title")}</h1>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  <TableComponents
                    onChangePage={this.onChangePage}
                    showElementDetails={this.showDetails}
                    tHead={tHead}
                    tableContent={adminList}
                    totalRecords={totalItems}
                    selectedRecord={selectAdmin}
                    sorting={this.sorting}
                    searching={this.onSearch}
                    // filter={this.filter}
                    refresh={this.refresh}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 mb-30">
          {Object.keys(selectAdmin).length > 0 ?
            <AdminDetails AdminReducer={this.props.AdminReducer} adminAction={this.props.adminAction} />
            :
            <AddAdmin createAdmin={this.save} qrData={qrData} AdminReducer={this.props.AdminReducer} adminAction={this.props.adminAction} type="ADMIN" field="Admin" />
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const { loggingIn } = state.LoginReducer;
  const AdminReducer = state.AdminReducer;
  return { loggingIn, AdminReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    adminAction: bindActionCreators(adminAction, dispatch),
  }
}

const connectedAddAdmin = connect(mapStateToProps, mapDispatchToProps)(Admin);
export { connectedAddAdmin as Admin };
