import _, { bind } from 'lodash';
import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginAction } from '../../Login/actions/loginAction';
import { Chart } from "react-google-charts";
import { dashboardAction } from '../actions/dashboardAction';
import moment from 'moment';
import { t } from 'i18next';

export interface IBothDashboardProps {
  loginAction: any,
  LoginReducer: any,
  dashboardAction: any,
  DashboardReducer: any,
}

export interface IBothDashboardState {
  dateData: any,
  selectStartDate: any,
  selectEndDate: any,
}

class BothDashboard extends React.Component<IBothDashboardProps, IBothDashboardState> {
  constructor(props: IBothDashboardProps) {
    super(props);
    this.state = {
      dateData: {},
      selectStartDate: false,
      selectEndDate: false,
    }

    this.handleChange = this.handleChange.bind(this);
  }


  componentDidMount() {
    this.props.dashboardAction.getAllCountforDashboard();
    this.props.loginAction.getCurrentUser();
  }

  handleChange(event: any) {
    const { dateData } = this.state;
    let fields = dateData;
    let selectStartDate = false;
    let selectEndDate = false;
    fields[event.target.name] = event.target.value;
    this.setState({
      dateData: fields,
    });

    this.props.dashboardAction.getAllCountforDashboard();
  }


  public render() {
    const { countForDashboard } = this.props.DashboardReducer;
    const { dateData, selectStartDate, selectEndDate } = this.state;
    const { loggedInUser } = this.props.LoginReducer;
    // let startDate =moment().clone().startOf('month').format('YYYY-MM-DD');
    // let endDate = moment().clone().endOf('month').format('YYYY-MM-DD');
    let currentMonth = new Date().getMonth();
    let showMonth = moment().clone().startOf('month').format('MMM-DD')
    let da = moment(showMonth).toDate();
    let dateforChart = moment(da).day(5).format('MMM-DD');
    let credentialCount = !_.isEmpty(countForDashboard) && countForDashboard[1].credentialPercentage.credentialIssuanceCount + countForDashboard[1].credentialPercentage.credentialRequestCount +
      countForDashboard[1].credentialPercentage.credentialRevokeCount + countForDashboard[1].credentialPercentage.credentialWaitingCount;
    let speedameterCount: any = !_.isEmpty(countForDashboard) && parseInt(countForDashboard[2].verificationPercentage)
    let finalvalue: any = Math.round((180 / 100) * speedameterCount);
    let credentialPercentage: any = !_.isEmpty(countForDashboard) && countForDashboard[1].credentialPercentage;
    return (
      <>
        <div className="row">
          <div className="col-sm-8"></div>
          {/* <div className="col-sm-4">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="startDate"
                placeholder="select"
                value={dateData.startDate !== '' ? dateData.startDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-prepend">
                <label className="input-group-text bg-white border-left-0 border-right-0" htmlFor="inputGroupSelect01">-</label>
              </div>
              <input type="date" className="form-control border-right-0"
                name="endDate"
                value={dateData.endDate !== '' ? dateData.endDate : ''}
                onChange={(e) => this.handleChange(e)}
              />
              <div className="input-group-append">
                <label className="input-group-text" htmlFor="inputGroupSelect01"><i className="fas fa-chevron-right"></i></label>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <small className="text-danger">{selectStartDate ? 'Select Start Date' : ''}</small>
          </div> */}
        </div>
        <div className="row gutters-sm animated fadeIn">
          <div className="col-md-12 col-lg-9 mb-30">
            <div className="card nb-card mb-3 h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:dashboard.credentialsTitle")}</h1>
              </div>
              <div className="card-body">
                <Chart
                  width={'100%'}
                  height={'400px'}
                  chartType="AreaChart"
                  loader={<div>{t("screens:dashboard.loadingChart")}</div>}

                  data={credentialPercentage}
                  options={{
                    title: `${t("screens:dashboard.credential")}`,
                    hAxis: { title: `${t("screens:dashboard.month")}`, titleTextStyle: { color: '#333' } },
                    vAxis: { minValue: 0 },
                    chartArea: { width: '80%', height: '80%' },
                    colors: ['#803DF8', '#254D91', '#9842D4', '#008BE6']
                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-3 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:dashboard.connectionsTitle")}</h1>
              </div>
              <div className="card-body">
                {!_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderOutsideCount !== 0 || !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderInsideCount !== 0 ?
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      ['OrgType', 'Count'],
                      ['Outside Organization', !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderOutsideCount],
                      ['Inside Organization', !_.isEmpty(countForDashboard) && countForDashboard[0].connectionPercentage.holderInsideCount],
                    ]}
                    options={{
                      title: `${t("screens:dashboard.connections")}`,
                      pieHole: 0.4,
                    }}
                    rootProps={{ 'data-testid': '3' }}
                  />
                  :
                  <h1 className="text-primary text-center">{t("screens:dashboard.noConnections")}</h1>
                }
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row gutters-sm">
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">Verification</h1>
              </div>
              <div className="card-body">
                <div className="speedo">
                  <div className="face">
                    <div className="needle-position">
                      <div className="counter-value">
                        {finalvalue}%
                      </div>
                      <div className="needle fast" style={{ transform: `rotate(${finalvalue}deg)` }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">Verification</h1>
              </div>
              <div className="card-body">
                <Chart
                  width={'100%'}
                  height={'100%'}
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Workflows', 'By QR Code', 'By Proof Request'],
                    ['Markting Promotion', 400, 300],
                    ['Meetings', 100, 200],
                    ['Occasional', 500, 600],
                    ['Webinars', 200, 600],
                  ]}
                  options={{
                    title: 'Workflows',
                    chartArea: { width: '60%' },
                    colors: ['#008BE6', '#9842D4'],
                    hAxis: {
                      title: '# of Proof Requests',
                      minValue: 0,
                    },
                    vAxis: {
                      title: 'City',
                    },
                  }}
                  // For tests
                  rootProps={{ 'data-testid': '1' }}
                />
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const DashboardReducer = state.DashboardReducer;
  return { LoginReducer, DashboardReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    dashboardAction: bindActionCreators(dashboardAction, dispatch),
  }
}

const connectedBothDashboard = connect(mapStateToProps, mapDispatchToProps)(BothDashboard);
export { connectedBothDashboard as BothDashboard };