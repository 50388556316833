export function parseSchema(schemaId?: string): {
  name: string;
  version: string;
} {
  let name = '';
  let version = '';
  if (schemaId) {
    const schemaIdRegex = /(.*?):([0-9]):([a-zA-Z .\-_0-9]+):([a-z0-9._-]+)$/;
    const schemaIdParts = schemaId.match(schemaIdRegex);
    if (schemaIdParts?.length === 5) {
      name = `${schemaIdParts?.[3].replace(/_|-/g, ' ')}`
        .split(' ')
        .map(
          (schemaIdPart) =>
            schemaIdPart.charAt(0).toUpperCase() + schemaIdPart.substring(1),
        )
        .join(' ');
      version = schemaIdParts?.[4];
    }
  }
  return { name, version };
}

export function getConnectionName(
  connection: any | void,
): string | void {
  try {
    if (!connection) {
      return '';
    }
    return connection?.theirLabel || connection?.alias || connection?.invitation?.label;
  } catch (err) {
    return '';
  }
}
