import axios from "axios";
import _ from "lodash";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, holderConst } from "../../types";

export const holderAction = {
  getQRdata(type: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.CREDENTIAL_LIST_LOADER_TRUE
        })
        let token = localStorage.getItem('token');

        return await axios.post(`${config.apiUrl}/connection/${type}/create`, "",
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            if (data.data.data) {
              var res = data.data.data;
              return (
                dispatch({
                  type: holderConst.GET_HOLDER_QR,
                  payload: res.shorteningUrl
                })
              )
            }
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  createHolder(
    type: any,
    formData: any
  ) {
    return (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.CREDENTIAL_LIST_LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return axios.post(`${config.apiUrl}/holder/add/non-admin-users`, formData,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            dispatch({
              type: holderConst.EMAIL_SENT
            },
              this.getHolderList(type, "", 10, 1, type, '', 'DESC'))
            setTimeout(
              () => {
                dispatch({
                  type: holderConst.SHOW_NEW_INVITE
                })
              },
              3000
            );
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getHolderList(
    type: any,
    defaultSearchFields: any,
    item_perPage: number,
    pageNo: number,
    page: string,
    sortBy: string,
    sortValue: string,
  ) {
    return async (dispatch: any) => {
      try {
        // dispatch({
        //   type: loaderConst.CREDENTIAL_LIST_LOADER_TRUE
        // })
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');

        return await axios.get(`${config.apiUrl}/holder/get-all-connection/by-orgID?search_text=${defaultSearchFields}&type=${type}&sortValue=${sortValue}&sortBy=${sortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            let tmpArray: any = [];
            tmpArray = !_.isEmpty(response) && response.data && response.data.data && response.data.data.data;
            tmpArray.map((ele: any, index: any) => {
              tmpArray[index].holderName = ele.name;
            })
            dispatch({
              type: holderConst.GET_HOLDER_LIST,
              payload: response,
              tmpArray,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectEmployee(data: any) {
    return async (dispatch: any) => {
      dispatch({
        type: holderConst.SELECT_HOLDER,
        payload: data
      })
    }
  },

  selectClient(data: any) {
    return async (dispatch: any) => {
      dispatch({
        type: holderConst.SELECT_CLIENT,
        payload: data
      })
    }
  },

  clearSelection() {
    return async (dispatch: any) => {
      dispatch({
        type: holderConst.CLEAR_SELECTION,
      })
    }
  },

  inviteNewHolder() {
    return async (dispatch: any) => {
      dispatch({
        type: holderConst.INVITE_NEW_HOLDER,
      })
    }
  },

  getHolderEmailId(
    defaultSearchFields: any,
    type: string,
  ) {
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');

        return await axios.get(`${config.apiUrl}/holder/Check-holder/by-email-or-name?holder=${defaultSearchFields}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            // return (
            if (!_.isEmpty(data) && data.data && data.data.data && data.data.data && !_.isEmpty(data.data.data)) {
              // console.log(`TEST Email holder Exists: ${JSON.stringify(data.data.data)}`)
              if (type === 'email') {
                dispatch({
                  type: holderConst.CHECK_EXISTING_HOLDER_EMAILID,
                  payload: type,
                })
              } else {
                dispatch({
                  type: holderConst.CHECK_EXISTING_HOLDER_NAME,
                  payload: type,
                })
              }
            } else {
              dispatch({
                type: holderConst.HIDE_CHECK_EXISTING_HOLDER_EMAILID
              })
              dispatch({
                type: holderConst.HIDE_CHECK_EXISTING_HOLDER_NAME
              })
            }
            // )
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },
}