import _ from 'lodash';
import * as React from 'react';
import CredentialValueComponent from 'src/Common Components/Components/CredentialValue';
import { getDifference } from 'src/utils/array';
import { CredDefTypes } from 'src/View/commonConst';
import TypeaheadComponent from '../../../Common Components/Components/TypeaheadComponent';
import { history } from "../../../index";
import { t } from 'i18next';

export interface ICreateCredentialProposalProps {
    TaskReducers: any,
    TaskActions: any,
    connectionAction: any,
    ConnectionReducer: any,
    credentialAction: any,
    CredentialReducer: any,
    LoginReducer: any,
    CredentialProposalAction: any,
    CredentialProposalReducer: any,
    myCredentialsAction: any
}

export interface ICreateCredentialProposalState {
    defaultSearchFields: any,
    itemsPerPage: number,
    pageNo: number,
    sortingOrder: string,
    connectionSortBy: string,
    CredentialSortBy: string,
    clearTypeAhead: boolean,
    clickedConnection: any,
    isSelectedCredDef: boolean,
    isSelectedSchema: boolean,
    connectionFilterValue: any,
    attributeArray: string[],
    clearTypeAheadOrganization: boolean,
    searchText: string,
    sortValue: string,
    sortBy: string,
    filterText: string,
    selfAttestedAttributeKey: string[],
    isDeleted: any,
    clearTypeAheadCredDef: boolean,

    selectedOrganization: any,
    selectedCredDef: any,
    credDefId: string,
    isModalShow: boolean,
    credentialValues: any,
}

export default class CreateCredentialProposal extends React.Component<ICreateCredentialProposalProps, ICreateCredentialProposalState> {
    constructor(props: ICreateCredentialProposalProps) {
        super(props);

        this.state = {
            searchText: "",
            sortValue: "",
            sortBy: "",
            filterText: "",
            defaultSearchFields: '',
            itemsPerPage: 10,
            pageNo: 1,
            sortingOrder: 'DESC',
            connectionSortBy: '',
            CredentialSortBy: '',
            clearTypeAhead: false,
            clickedConnection: {},
            isSelectedCredDef: false,
            isSelectedSchema: false,
            connectionFilterValue: 'all',
            clearTypeAheadCredDef: false,
            attributeArray: [],
            clearTypeAheadOrganization: false,
            selfAttestedAttributeKey: [],
            isDeleted: 'false',

            selectedOrganization: {},
            selectedCredDef: {},
            credDefId: '',
            isModalShow: false,
            credentialValues: [],
        }

        this.sendProposal = this.sendProposal.bind(this);
        this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
        this.handleOrganizationInputChange = this.handleOrganizationInputChange.bind(this);
        this.handleCredDefChange = this.handleCredDefChange.bind(this);
        this.handleCredDefInputChange = this.handleCredDefInputChange.bind(this);

    }

    componentDidMount() {
        const { pageNo, searchText } = this.state;

        // Get All connection by orgId
        const { loggedInUser } = this.props.LoginReducer;
        this.props.connectionAction.getAllConnectedOrganizations(loggedInUser.organization.id, pageNo, 0, searchText)

    }

    sendProposal(e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        selectedCredDef: any,
        selectedConnection: any,
    ): void {
        const { credentialValues } = this.state;
        let schemaAttribute: any = [];

        if (selectedCredDef.schema) {

            selectedCredDef.schema.attributes.forEach((attribute: any, idx: number) => {
                let jsonObj = {
                    "name": attribute.name,
                    "value": credentialValues[idx] === undefined || credentialValues[idx] === null || credentialValues[idx] === "" ? " " : credentialValues[idx],
                }
                if (attribute.value === 'image') {
                    Object.assign(jsonObj, { "mime-type": "image/jpeg" })
                }
                schemaAttribute.push(jsonObj);
            });
        }
       
        let finalData = {
            autoIssue: true,
            connectionId: Object.keys(selectedConnection).length > 0 ? selectedConnection.connectionId : selectedCredDef.connection.connectionId,
            credDefId: selectedCredDef.credentialDefinitionId,
            comment: '',
            attributes: schemaAttribute,
            selectedConnection: Object.keys(selectedConnection).length > 0 ? selectedConnection.theirLabel : selectedCredDef.connection.theirLabel,
            schemaId: (selectedCredDef.schema) ? selectedCredDef.schema.schemaLedgerId : JSON.parse(selectedCredDef.credentialProposalDict).schema_id
        }


        this.props.CredentialProposalAction.sendProposal(finalData);

        this.handleCancel(e);
    }

    handleOrganizationChange(selectedOrganization: any) {

        const selectedOrganizationData = selectedOrganization[0];

        if (selectedOrganizationData) {
            this.props.credentialAction.getCredentialDefinitionsByPairwiseDid(selectedOrganizationData.myDid, CredDefTypes.NOT_SPECIAL_PURPOSE)
        }

        this.setState({
            selectedOrganization: selectedOrganizationData
        })
    }

    /**
     * Method used to handle selected credential details
     * @param selectedCredDefOptions 
     */
    handleCredDefChange(selectedCredDef: any) {
        let selectedData = selectedCredDef[0];

        this.setState({
            selectedCredDef: selectedData,
            credDefId: selectedData && selectedData.credentialDefinitionId,
        })
    }

    /**
     * Method used to handle selected credential details
     */
    handleCredDefInputChange() {
        this.props.TaskActions.clearSelectedIssuerOrganizationName()
        this.setState({
            isSelectedCredDef: false,
            selectedCredDef: ''
        })
    }

    handleOrganizationInputChange() {
        this.setState({
            selectedOrganization: '',
            selectedCredDef: '',
            isSelectedCredDef: false,
        })
    }

    showModal = () => {
        this.setState({
            isModalShow: true,
        })
    }

    closeModal = () => {
        this.setState({
            isModalShow: false,
        })
    }

    handleCredentialValueSubmit = (credentialValue: any) => {
        this.setState({
            credentialValues: credentialValue
        })

    }

    handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
        event.preventDefault();
        this.setState({
            credentialValues: [],
            clearTypeAhead: true,
            clearTypeAheadCredDef: true,
            credDefId: '',
            selectedCredDef: {},
            selectedOrganization: {},

            // clearTypeAheadOrganization: true,

        })

        const historyState = history.location.state as any
        if (historyState?.selectedCredentialDetails) {
            let state = { ...historyState };
            delete state.selectedCredentialDetails;
            history.replace({ ...history.location, state });
        }
        this.props.CredentialProposalAction.clearCredentialProposal();
    }

    public render() {
        const { clearTypeAhead: clearTypeahead, clearTypeAheadCredDef, selectedCredDef, credentialValues, isModalShow, selectedOrganization } =
            this.state;
        const { connectedOrgs } = this.props.ConnectionReducer;

        const { credentialDefinitionList } = this.props.CredentialReducer

        let disabledButton = _.isEmpty(selectedCredDef) ? true :
            _.isEmpty(selectedOrganization) ? true :
                _.isEmpty(credentialValues) ? true : false;

        const historyState = history.location.state as any
        let selectedCredentialDetails: any;
        let attributes;
        let arrayDifference

        if (historyState && historyState.selectedCredentialDetails) {
            selectedCredentialDetails = historyState.selectedCredentialDetails
            attributes = JSON.parse(selectedCredentialDetails.credentialProposalDict).credential_proposal.attributes;
        }

        if (attributes) {
            arrayDifference = getDifference(credentialValues, attributes)
        }

        return (
            <>
                <div className="card-header bg-white border-bottom-0">
                    <h1 className="nb-title">{t("screens:credentialProposal.newCredentialProposal")}</h1>
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="nb-label">{t("screens:credentialProposal.givenName")}</label>
                                {
                                    selectedCredentialDetails?.connection?.theirLabel && selectedCredentialDetails?.connection?.theirDid ?
                                        <>
                                            <input type='text'
                                                className='form-control'
                                                disabled={true}
                                                value={`${selectedCredentialDetails?.connection?.theirLabel} (${selectedCredentialDetails.connection.theirDid})`}
                                            />
                                        </> :
                                        <TypeaheadComponent
                                            data={connectedOrgs}
                                            onChange={this.handleOrganizationChange}
                                            onInputChange={this.handleOrganizationInputChange}
                                            clearTypeahead={clearTypeahead}
                                            placeholder={t("screens:credentialProposal.selectConnectionList")}
                                            disabled={false}
                                        />
                                }

                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="nb-label">{t("screens:credentialProposal.credentialName")}</label>
                                {
                                    selectedCredentialDetails?.credentialDefinitionId ?
                                        <>
                                            <input type='text'
                                                className='form-control'
                                                disabled={true}
                                                value={`${selectedCredentialDetails?.credentialDefinitionId.split(':')[4]}`}
                                            />
                                        </> :
                                        <TypeaheadComponent
                                            onChange={this.handleCredDefChange}
                                            data={credentialDefinitionList}
                                            onInputChange={this.handleCredDefInputChange}
                                            clearTypeahead={clearTypeAheadCredDef}
                                            placeholder={t("screens:credentialProposal.selectCredDef")}
                                            disabled={false} />
                                }
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="nb-label">{t("screens:credentialProposal.credentialValues")}
                                    <button className="btn btn-link"
                                        onClick={this.showModal}
                                        disabled={!_.isEmpty(selectedCredDef) || selectedCredentialDetails ? false : true}
                                    >
                                        <i className="fas fa-pencil-alt"></i>
                                    </button>
                                </label>
                                <div className="card" style={{ minHeight: '300px' }}>
                                    <div className="card-body bg-light">
                                        {
                                            !_.isEmpty(credentialValues) && attributes && arrayDifference.length === 0 ?
                                                attributes.map((attribute: any, index: number) => {
                                                    return (
                                                        !attribute.hasOwnProperty("mime-type") ?
                                                            <li key={index}>{attribute.name}: {attribute.value}</li>
                                                            : <li key={index}>{attribute.name} :
                                                                <img src={attribute.value} className="preview" height="50" alt=''></img>
                                                            </li>
                                                    )
                                                })
                                                : attributes ?
                                                    attributes.map((attribute: any, index: number) => {
                                                        console.log('credentialValues[index]', credentialValues[index])
                                                        return (
                                                            !attribute.hasOwnProperty("mime-type") ?
                                                                <li key={index}>{attribute.name}: {credentialValues[index] ? credentialValues[index] : attribute.value}</li>
                                                                : <li key={index}>{attribute.name} :
                                                                    <img src={credentialValues[index]?.value ?? credentialValues[index]} className="preview" height="50" alt=''></img>
                                                                </li>
                                                        )
                                                    })
                                                    : <></>
                                        }
                                        {
                                            !_.isEmpty(credentialValues) && selectedCredDef && Object.keys(selectedCredDef).length >= 1 &&
                                            selectedCredDef.schema && selectedCredDef.schema.attributes &&
                                            selectedCredDef.schema.attributes.map((attribute: any, index: any) => {

                                                return (
                                                    attribute.value !== 'image' ?
                                                        <>
                                                            <li key={index}>
                                                                {attribute.name}({attribute.value}): {credentialValues[index]}
                                                            </li>
                                                        </> :
                                                        <>
                                                            <li key={index}>
                                                                {attribute.name}({attribute.value}) :
                                                                <img src={credentialValues[index]} className="preview" height="50" alt=''></img>
                                                            </li>
                                                        </>
                                                )
                                            })
                                        }
                                    </div>
                                    <CredentialValueComponent
                                        isModalShow={isModalShow}
                                        selectedConnection={selectedOrganization}
                                        selectedCredDef={(selectedCredDef && selectedCredDef.schema) ? selectedCredDef.schema
                                            : selectedCredentialDetails?.credentialProposalDict}
                                        handleCredentialValueSubmit={this.handleCredentialValueSubmit}
                                        closeModal={this.closeModal}
                                        isEdit={true}
                                        modalTitle={t("screens:credentialProposal.modalTitle")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-header bg-white border-bottom-0 mb-3">
                    <div className="row">
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                                onClick={(e) => this.handleCancel(e)}
                            >
                                {t("screens:credentialProposal.cancel")}
                            </button>
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                                onClick={(e) => this.sendProposal(e, Object.keys(selectedCredDef).length > 0 ? selectedCredDef : selectedCredentialDetails, selectedOrganization)}
                                disabled={selectedCredentialDetails ? false : disabledButton}
                            >
                                {t("screens:credentialProposal.sendProposal")}
                            </button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
