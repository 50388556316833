import _ from 'lodash';
import * as React from 'react';
import CredentialValueComponent from '../../../Common Components/Components/CredentialValue';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import { t } from 'i18next';
import { offerNegotiateInitiator } from 'src/View/types';

export interface IIssueCredentialComponentProps {
  CredentialReducer: any,
  ConnectionReducer: any,
  issuanceAction: any,
  IssuanceReducer: any,
  TaskActions: any,
  TaskReducers: any,
}

export interface IIssueCredentialComponentState {
  clearTypeahead: boolean,
  isSelected: boolean,
  selectedCredDef: any,
  credDefId: any,
  connectionId: any,
  selectedConnection: any,
  isModalShow: boolean,
  credentialValues: any,
  theirDid: string,
}

export default class IssueCredentialComponent extends React.Component<IIssueCredentialComponentProps, IIssueCredentialComponentState> {
  constructor(props: IIssueCredentialComponentProps) {
    super(props);

    this.state = {
      clearTypeahead: false,
      isSelected: false,
      selectedCredDef: {},
      credDefId: '',
      selectedConnection: {},
      connectionId: '',
      isModalShow: false,
      credentialValues: [],
      theirDid: '',
    }

    this.handleCredDefChange = this.handleCredDefChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.handleInputChangeForConnection = this.handleInputChangeForConnection.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCredentialValueSubmit = this.handleCredentialValueSubmit.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.issueCredentialByWaitingList = this.issueCredentialByWaitingList.bind(this);
  }

  handleCredDefChange(selectedOptions: any) {
    let selectedData = selectedOptions[0];
    this.setState({
      clearTypeahead: false,
      isSelected: true,
      selectedCredDef: selectedData,
      credDefId: selectedData && selectedData.credentialDefinitionId,
    })
  }

  handleInputChange() {
    this.setState({
      isSelected: false,
      credDefId: ''
    })
  }

  handleConnectionChange(selectedOptions: any) {
    let selectedData = selectedOptions[0];
    this.setState({
      clearTypeahead: false,
      isSelected: true,
      selectedConnection: selectedData,
      connectionId: selectedData && selectedData.connectionId,
      theirDid: selectedData && selectedData.theirDid,
    })
  }

  handleInputChangeForConnection() {
    this.setState({
      isSelected: false,
      connectionId: ''
    })
  }
  showModal = () => {
    this.setState({
      isModalShow: true,
    })
  }
  closeModal = () => {
    this.setState({
      isModalShow: false,
    })
  }

  handleCredentialValueSubmit = (credentialValue: any) => {
    this.setState({
      credentialValues: credentialValue
    })

  }
  issueCredentialByWaitingList(e: any,
    credentialExchangeId: any,): void {
    let finalData = {
      credentialExchangeId: credentialExchangeId
    }
    this.props.issuanceAction.sendCredentialOfferOrNegotiate(finalData, offerNegotiateInitiator.offer)
    this.handleCancel(e);
  }

  saveDetails(e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedCredDef: any,
    selectedConnection: any,
  ): void {
    const { credentialValues } = this.state;

    let schemaAttribute: any = [];

    selectedCredDef.schema.attributes.forEach((ele: any, idx: number) => {
      let jsonObj = {
        "name": JSON.parse(ele).name,
        "value": credentialValues[idx] === undefined || credentialValues[idx] === null || credentialValues[idx] === "" ? " " : credentialValues[idx],
      }
      if (JSON.parse(ele).value === 'image') {
        Object.assign(jsonObj, { "mime-type": "image/jpeg" })
      }
      schemaAttribute.push(jsonObj);
    });

    let finalData = {
      auto_issue: true,
      connection_id: selectedConnection.connectionId,
      cred_def_id: selectedCredDef.credentialDefinitionId,
      comment: '',
      attributes: schemaAttribute,
      selectedConnection: selectedConnection.theirLabel
    }

    this.props.issuanceAction.saveDetails(finalData, selectedCredDef.tag);
    this.handleCancel(e);
  }

  handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
    event.preventDefault();
    this.setState({
      credentialValues: [],
      isSelected: false,
      credDefId: '',
      selectedCredDef: {},
      clearTypeahead: true,
      connectionId: '',
      theirDid: ''
    })
    this.props.issuanceAction.clearIssuanceCredential();
  }

  public render() {
    let { connectionList } = this.props.ConnectionReducer;
    let { credentialName, connectionName, requesterDID, filledCredentialValue, credentialExchangeId } = this.props.IssuanceReducer;

    let {
      clearTypeahead,
      selectedCredDef,
      isModalShow,
      selectedConnection,
      credentialValues,
      theirDid
    } = this.state;
    let credentialValueLength: number = credentialValues && credentialValues.length;
    let selectedSchemaLength: number = selectedCredDef && selectedCredDef.schema && selectedCredDef.schema.attributes && selectedCredDef.schema.attributes.length;
    if (credentialValueLength === selectedSchemaLength) {
      for (var i = 0; i < credentialValues.length; i++) {
        if (credentialValues[i] !== "") {
        } else {
        }
      }
    }
    let disabledButton = _.isEmpty(selectedCredDef) ? true : _.isEmpty(selectedConnection) ? true : _.isEmpty(credentialValues) ? true : false;
    const { createdCredDefsbyOrgId } = this.props.TaskReducers;

    return (
      <>

        <div className="card nb-card h-100">
          <div className="card-header bg-white border-bottom-0">
            <h1 className="nb-title">{t("screens:issueCredentials.credentialOffer")}</h1>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="nb-label">{t("screens:issueCredentials.name")}</label>
                  {console.log('createdCredDefsbyOrgId', createdCredDefsbyOrgId)}
                  {credentialName ?
                    <input type="text" className="form-control"
                      name="credentialName"
                      value={credentialName}
                      disabled
                    />
                    :
                    <Typeahead data={createdCredDefsbyOrgId} onChange={this.handleCredDefChange} onInputChange={this.handleInputChange}
                      clearTypeahead={clearTypeahead} placeholder={t("screens:issueCredentials.selectCredentialName")} disabled={false} />
                  }
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="nb-label">{t("screens:issueCredentials.givenName")}</label>
                  {connectionName ?
                    <input type="text" className="form-control"
                      name="connectionName"
                      value={connectionName}
                      disabled
                    />
                    :
                    <Typeahead data={connectionList} onChange={this.handleConnectionChange} onInputChange={this.handleInputChangeForConnection}
                      clearTypeahead={clearTypeahead} placeholder={t("screens:issueCredentials.selectConnection")} disabled={false} />
                  }
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="nb-label">{t("screens:issueCredentials.connectionDID")}</label>
                  <input type="text" className="form-control"
                    name="connectionDID"
                    value={theirDid ? theirDid : requesterDID}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="nb-label">{t("screens:issueCredentials.credentialValues")}
                    <button className="btn btn-link"
                      onClick={this.showModal}
                      disabled={!_.isEmpty(selectedCredDef) ? false : true}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                  </label>
                  <div className="card" style={{ minHeight: '300px' }}>
                    <div className="card-body bg-light">
                      {!_.isEmpty(filledCredentialValue) ? filledCredentialValue.credential_proposal &&
                        filledCredentialValue.credential_proposal.attributes.map((ele: any, index: any) => {
                          return (
                            <>
                              <li key={index}>{ele.name}:{ele.value}</li>
                            </>
                          )
                        })
                        :
                        !_.isEmpty(credentialValues) && selectedCredDef && Object.keys(selectedCredDef).length >= 1 &&
                        selectedCredDef.schema && selectedCredDef.schema.attributes &&
                        selectedCredDef.schema.attributes.map((ele: any, index: any) => {
                          return (
                            JSON.parse(ele).value !== 'image' ?
                              <>
                                <li key={index}>
                                  {JSON.parse(ele).name}({JSON.parse(ele).value}) : {credentialValues[index]}
                                </li>
                              </> :
                              <>
                                <li key={index}>
                                  {JSON.parse(ele).name}({JSON.parse(ele).value}) :
                                  <img src={credentialValues[index]} className="preview" height="50" alt=''></img>
                                </li>
                              </>
                          )
                        })
                      }
                    </div>
                    <CredentialValueComponent
                      isModalShow={isModalShow}
                      selectedConnection={selectedConnection}
                      selectedCredDef={selectedCredDef && selectedCredDef.schema}
                      handleCredentialValueSubmit={this.handleCredentialValueSubmit}
                      closeModal={this.closeModal}
                      isEdit={true}
                      modalTitle={''}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-header bg-white border-bottom-0 mb-3">
            <div className="row">
              <div className="col-sm-6">
                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                  onClick={(e) => this.handleCancel(e)}
                >
                  {t("screens:issueCredentials.cancel")}
                </button>
              </div>
              <div className="col-sm-6">
                <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                  onClick={(e) => !_.isEmpty(filledCredentialValue) ?
                    this.issueCredentialByWaitingList(e, credentialExchangeId) :
                    this.saveDetails(e, selectedCredDef, selectedConnection)}
                  disabled={!_.isEmpty(filledCredentialValue) ? false : disabledButton}
                >
                  {t("screens:issueCredentials.offer")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
