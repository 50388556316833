import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { presentProofAction } from "../action/presentProofAction";
import { t } from 'i18next';
import ProblemReportComponent from "src/Common Components/Components/ProblemReportModal";

/* Interface for Props variables*/
interface IGenerateProofProps {
    PresentProofReducer: any,
    presentProofAction: any,
    attributeValues: any,
    predicateValues: any
    presentationExchangeId: string,
    credentialsToReveal: any,
    // onChangeRevealAttributes: any,  // SELECTIVE DISCLOSURE removed till ACA-Py issue gets resolved
    onChangeSelfAttributeValue: any,
    connectionDetails: any,

}

interface IGenerateProofState {
    credentialsToReveal: any,
    selfAttestedAttributeValue: string[],
    selfAttestedAttributeKey: string[],
    isModalProblemReportShow: boolean,
    declineReasonList: string[]
}

class GenerateProof extends React.Component<IGenerateProofProps, IGenerateProofState> {
    constructor(props: IGenerateProofProps) {
        super(props)

        this.state = {
            credentialsToReveal: props.credentialsToReveal,
            selfAttestedAttributeKey: [],
            selfAttestedAttributeValue: [],
            isModalProblemReportShow: false,
            declineReasonList: [
                `${t("screens:presentProof.unTrust")}`,
                `${t("screens:presentProof.noCredential")}`,   
                `${t("screens:presentProof.noInterest")}`   
            ],
        }

        /* Binding the method */
        this.sendProof = this.sendProof.bind(this)
        this.handleProofRequestDecline = this.handleProofRequestDecline.bind(this)
        this.handleProblemReportSubmit = this.handleProblemReportSubmit.bind(this)
        this.reProposeProofRequest = this.reProposeProofRequest.bind(this)
    }

    /**
     * Method to send the proof.
     */
    sendProof() {
        const credentials: any = []
        const predicates: any = []
        const { attributeValues, presentationExchangeId, predicateValues } = this.props
        const { selfAttestedAttributeKey, selfAttestedAttributeValue } = this.state

        let selfAttestedAttributes: any = {};

        attributeValues.map((credentialData: any) => {

            credentialData.credentialFields.map((cred: any) => {

                if (cred.reveal !== undefined) {
                    const credentialItem: any = {}
                    credentialItem['credentialReferent'] = credentialData.credentialId
                    credentialItem['credentialId'] = (credentialData).hasOwnProperty('cred_def_id') ?
                        credentialData['cred_def_id'] :
                        credentialData['schema_id']
                    credentialItem['reveal'] = cred.reveal
                    credentialItem['attributeKey'] = cred.referentName
                    credentials.push(credentialItem)
                }
                else {
                    Object.assign(selfAttestedAttributes, {
                        [cred.referentName]: cred.value ? cred.value : ''
                    })
                }
            })
        })

        predicateValues.map((predicateData: any) => {
            const predicateItem: any = {}
            predicateItem['credentialReferent'] = predicateData.credentialId
            predicateItem['credentialId'] = (predicateData).hasOwnProperty('cred_def_id') ?
                predicateData['cred_def_id'] :
                predicateData['schema_id']
            predicateItem['attributeKey'] = predicateData.referentName
            predicates.push(predicateItem)
        })

        if (selfAttestedAttributeKey.length > 0) {
            selfAttestedAttributeKey.map((attr: any, index: any) => {
                Object.assign(selfAttestedAttributes, {
                    [attr]: selfAttestedAttributeValue[index]
                        ? selfAttestedAttributeValue[index]
                        : ''
                })
            })
        }

        const proofPresentation = {
            "credentials": credentials,
            'predicates': predicates,
            selfAttestedAttributes,
            "presentationExchangeId": presentationExchangeId
        }

        /* Call the send proof API */
        this.props.presentProofAction.sendPresentation(proofPresentation)
    }

    removeSelfAttestedAttribute(event: React.MouseEvent<HTMLButtonElement>, i: number) {
        let keys = [...this.state.selfAttestedAttributeKey];
        let values = [...this.state.selfAttestedAttributeValue];
        keys.splice(i, 1);
        values.splice(i, 1);

        if (!keys.includes("") && !values.includes("")) {
            if (keys.length === values.length) {
                return this.setState({ selfAttestedAttributeKey: keys, selfAttestedAttributeValue: values })
            }
        }

        this.setState({ selfAttestedAttributeKey: keys, selfAttestedAttributeValue: values })
        event.preventDefault();
    }


    onChangeSelfAttributeKey(event: React.ChangeEvent<HTMLInputElement> | any, index: any) {
        event.preventDefault();
        let updateField = this.state.selfAttestedAttributeKey;
        updateField[event.target.name] = (event.target.value);

        this.setState({
            selfAttestedAttributeKey: updateField,
        })
    }

    onChangeSelfAttributeValue(event: React.ChangeEvent<HTMLInputElement> | any, index: any) {
        event.preventDefault();
        let updateField = this.state.selfAttestedAttributeValue;
        updateField[event.target.name] = (event.target.value);

        this.setState({
            selfAttestedAttributeValue: updateField,
        })
        this.props.onChangeSelfAttributeValue(event)
    }

    createSelfAttestedAttribute() {
        const { selfAttestedAttributeKey, selfAttestedAttributeValue } = this.state;

        return selfAttestedAttributeKey.map((field: any, index: any) => {
            return index === 0 ?
                <div key={index}>
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="form-group row">
                                <div className="col-sm-10">

                                    <input type="text" className="form-control"
                                        name={index} id="attributeKey"
                                        value={field}
                                        onChange={(e) => this.onChangeSelfAttributeKey(e, index)}
                                    />
                                </div>
                                <div className="col-sm-2">:
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <input type="text" className="form-control"
                                name={index} id="attributeValue"
                                value={selfAttestedAttributeValue[index]}
                                onChange={(e) => this.onChangeSelfAttributeValue(e, index)}
                            />
                        </div>

                        <div className="col-sm-2">
                            <div className="form-group">
                                <button className="btn float-right"
                                    onClick={(e) => this.removeSelfAttestedAttribute(e, index)}
                                ><i className="fas fa-minus-circle" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                // -------------------------------
                <div key={index}>
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="form-group row">
                                <div className="col-sm-10">

                                    <input type="text" className="form-control"
                                        name={index} id="attributeKey"
                                        value={field}
                                        onChange={(e) => this.onChangeSelfAttributeKey(e, index)}
                                    />
                                </div>
                                <div className="col-sm-2">:
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <input type="text" className="form-control"
                                name={index} id="attributeValue"
                                value={selfAttestedAttributeValue[index]}
                                onChange={(e) => this.onChangeSelfAttributeValue(e, index)}
                            />
                        </div>

                        <div className="col-sm-2">
                            <div className="form-group">
                                <button className="btn float-right"
                                    onClick={(e) => this.removeSelfAttestedAttribute(e, index)}
                                ><i className="fas fa-minus-circle" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        })
    }

    addSelfAttestedAttribute(event: any) {
        let updateValue: any = this.state.selfAttestedAttributeKey;
        let updateFormat: any = this.state.selfAttestedAttributeValue;
        updateValue.push('');
        updateFormat.push('');
        this.setState({
            selfAttestedAttributeKey: updateValue,
            selfAttestedAttributeValue: updateFormat
        })
        // this.isBtnDisable();
        event.preventDefault();
    }

    handleProofRequestDecline() {
        this.setState({ isModalProblemReportShow: true })
    }

    closeModal = () => {
        this.setState({
            isModalProblemReportShow: false,
        })
    }

    handleProblemReportSubmit(reason: string, exchangeId: string) {
        this.props.presentProofAction.sendProblemReportForPresentationExchange(reason, exchangeId)
        this.closeModal()
    }

    reProposeProofRequest(attributeValues: any) {
        const pickedConnectionDetails = (({ id, connectionId, invitationKey, myDid, state, theirDid, theirLabel }) =>
            ({ id, connectionId, invitationKey, myDid, state, theirDid, theirLabel }))(this.props.connectionDetails);
        this.props.presentProofAction.reProposeProofRequest(attributeValues, pickedConnectionDetails)
    }


    /* UI to show selected credential attribute and its value */
    public render() {
        const { attributeValues, connectionDetails } = this.props
        const { selfAttestedAttributeKey, isModalProblemReportShow, declineReasonList } = this.state

        return (
            <>
                <ul className="fa-ul nb-list">
                    <li className="mt-3 remove-underline">
                        <i className="fa-li nb-ico nb-credentials"></i>
                        <span className="h5">{t("screens:presentProof.generatedPresentProof")}</span>
                    </li>
                    <li>

                        <div className="col-sm-12 mt-3">
                            {!_.isEmpty(attributeValues) && attributeValues.map((credential: any, credentialIndex: number) => {

                                return (
                                    <>
                                        <ul key={credentialIndex}>
                                            <li className="remove-underline">
                                                <div className="row ml-3">
                                                    <div className="col-sm-6">
                                                        <i className="fa-li nb-ico nb-credential"></i>
                                                        <label className="font-weight-bold">{t("screens:presentProof.credentialName")}:</label>
                                                        <label className="font-weight-bold">{credential.credentialName} </label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="remove-underline">
                                                <div className="row ml-3">
                                                    <div className="col-sm-12">
                                                        <i className="fa-li nb-ico nb-attribute"></i>
                                                        <label className="font-weight-bold">{t("screens:presentProof.attributeValues")}:</label>
                                                        <ul>
                                                            {!credential.hasOwnProperty('credentialFields') &&
                                                                <>
                                                                    <li>
                                                                        <div className="blank-credential-details-body">
                                                                            <label className="">{t("screens:presentProof.noCredentialAvailable")}:</label>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            }
                                                            {!_.isEmpty(credential) && credential.hasOwnProperty('credentialFields') &&
                                                                credential?.credentialFields.map((credentialData: any, credentialDetailIndex: number) => {

                                                                    return (
                                                                        (credential.credentialFields && credential.cred_def_id) || credential.schema_id ?
                                                                            credentialData.value?.includes('data:image/') ?
                                                                                <li key={credentialDetailIndex}>
                                                                                    <label className="font-weight-bold">
                                                                                        {/* SELECTIVE DISCLOSURE removed till ACA-Py issue gets resolved */}
                                                                                        {/* <input type="checkbox"
                                                                                            id='Credential'
                                                                                            key={`attributes-${credentialIndex}`}
                                                                                            name={`${credential.credentialId}--${credentialIndex}--${credentialData.name}`}
                                                                                            checked={credentialData.reveal}
                                                                                            onChange={(e) => this.props.onChangeRevealAttributes(e)}
                                                                                        />  */}
                                                                                        {credentialData.name}:&nbsp;
                                                                                        <img src={credentialData.value} alt={credentialData.name} className="preview" height="50"></img>
                                                                                    </label>
                                                                                </li> :
                                                                                <li key={credentialDetailIndex}>
                                                                                    <label className="font-weight-bold">
                                                                                        {/* SELECTIVE DISCLOSURE removed till ACA-Py issue gets resolved */}

                                                                                        {/* <input type="checkbox"
                                                                                            id='Credential'
                                                                                            key={`attributes-${credentialIndex}`}
                                                                                            name={`${credential.credentialId}--${credentialIndex}--${credentialData.name}`}
                                                                                            checked={credentialData.reveal}
                                                                                            onChange={(e) => this.props.onChangeRevealAttributes(e)}
                                                                                        />  */}
                                                                                        {credentialData.name}: {credentialData.value}
                                                                                    </label>
                                                                                </li> :

                                                                            <li key={credentialDetailIndex}>
                                                                                <div className="form-group row">
                                                                                    <label className="col-sm-3 font-weight-bold col-form-label">
                                                                                        {credentialData.name}: </label>
                                                                                    <div className="col-sm-9">
                                                                                        <input type="text" className="form-control"
                                                                                            name={`${credentialIndex}-${credentialDetailIndex}`} id="attributeValue"
                                                                                            value={credentialData.value}
                                                                                            onChange={(e) => this.onChangeSelfAttributeValue(e, credentialDetailIndex)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                    )
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </div>


                                            </li>

                                        </ul>
                                    </>
                                )
                            })}
                        </div>
                        {selfAttestedAttributeKey.length > 0 ?
                            <li className="remove-underline">
                                <div className="row ml-3">
                                    <div className="col-sm-12">
                                        <i className="fa-li nb-ico nb-attribute"></i>
                                        <label className="font-weight-bold">{t("screens:presentProof.selfAttestedAttributes")}:</label>
                                        <ul>
                                            {this.createSelfAttestedAttribute()}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            : ''
                        }
                    </li>
                </ul>
                <div className="row">
                    <div className="col-sm-3 mt-2">
                    </div>
                    <div className="col-sm-3 mt-2">
                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                            disabled={(_.isEmpty(attributeValues[0]) && attributeValues[0].hasOwnProperty('credentialFields')) ||
                                attributeValues[0].credentialId === ''}
                            onClick={this.sendProof}>
                            {t("screens:presentProof.send")}
                        </button>
                    </div>
                    <div className="col-sm-3 mt-2">
                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                            disabled={(_.isEmpty(attributeValues[0]) && attributeValues[0].hasOwnProperty('credentialFields')) ||
                                attributeValues[0].presentationExchangeId === ''}
                            onClick={this.handleProofRequestDecline}>
                            {t("screens:presentProof.decline")}
                        </button>
                    </div>
                    <div className="col-sm-3 mt-2">
                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill mt-2"
                            disabled={(_.isEmpty(attributeValues[0]) && attributeValues[0].hasOwnProperty('credentialFields')) ||
                                attributeValues[0].presentationExchangeId === ''}
                            onClick={() => this.reProposeProofRequest(attributeValues)}>
                            {t("screens:presentProof.rePropose")}
                        </button>
                    </div>

                </div>

                {
                    isModalProblemReportShow ?
                        <ProblemReportComponent
                            isModalShow={isModalProblemReportShow}
                            reasonList={declineReasonList}
                            selectedRecordId={attributeValues[0].presentationExchangeId}
                            selectedRecordGivenName={connectionDetails.theirLabel}
                            closeModal={this.closeModal}
                            handleProblemReportSubmit={this.handleProblemReportSubmit}
                            modalTitle={''}
                            credentialState={''}

                        />
                        : <></>
                }
            </>

        )
    }

}

function mapStateToProps(state: any) {
    const PresentProofReducer = state.PresentProofReducer;
    return { PresentProofReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentProofAction: bindActionCreators(presentProofAction, dispatch),
    }
}

const generateProof = connect(mapStateToProps, mapDispatchToProps)(GenerateProof);
export { generateProof as GenerateProof };
