import axios from "axios";
import { toastr } from "react-redux-toastr";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, workflowConst } from "../../types";
import { t } from 'i18next';

export const workflowAction = {

  getWorkflowList(
    defaultSearchFields: any,
    sortingOrder: string,
    sortBy: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/verifier/get-verification-workflow?search_text=${defaultSearchFields}&sortValue=${sortingOrder}&onboardRequestSort=${sortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            dispatch({
              type: workflowConst.WORKFLOW_LIST,
              payload: data.data.data
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);

            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectWorkflow(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: workflowConst.SELECT_WORKFLOW,
        payload: item
      })
    }
  },

  createNewWorkflow(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: workflowConst.CREATE_WORKFLOW,
        payload: item
      })
    }
  },

  createWorkflow(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        const formData = new FormData()
        formData.append('method', finalData.method)
        formData.append('repetition', finalData.repetition)
        formData.append('verificationTrigger', finalData.verificationTrigger)
        formData.append('verificationType', finalData.verificationType)
        formData.append('workflowAttributes', JSON.stringify(finalData.workflowAttributes))
        formData.append('workflowImage', finalData.workflowImage)
        formData.append('workflowName', finalData.workflowName)

        return await axios.post(`${config.apiUrl}/verifier/create-verification-workflow`, formData,
          { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }, })
          .then(response => {
            dispatch({
              type: workflowConst.DEFAULT_CREATE_WORKFLOW,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })

            this.getWorkflowList('', 'DESC', '', 10, 1);
            // toastr.success(`Verification workflow created successfully`, ``)
            toastr.success(`${t("screens:newWorkflow.toastMsgCreated")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  updateWorkflow(id: any, finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        const formData = new FormData()
        formData.append('method', finalData.method)
        formData.append('repetition', finalData.repetition)
        formData.append('verificationTrigger', finalData.verificationTrigger)
        formData.append('verificationType', finalData.verificationType)
        formData.append('workflowAttributes', JSON.stringify(finalData.workflowAttributes))
        formData.append('workflowImage', finalData.workflowImage)
        formData.append('workflowName', finalData.workflowName)
        return await axios.put(`${config.apiUrl}/verifier/verification-workflow/${id}`, formData,
          { headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: workflowConst.UPDATE_WORKFLOW,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })

            this.getWorkflowList('', 'DESC', '', 10, 1);
            // toastr.success(`Verification workflow updated successfully`, ``)
            toastr.success(`${t("screens:newWorkflow.toastMsgUpdated")}`, ``)

          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  deleteWorkflow(id: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.put(`${config.apiUrl}/verifier/delete-verification-workflow/${id}`, '',
          { headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: workflowConst.DELETE_WORKFLOW,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })

            this.getWorkflowList('', 'DESC', '', 10, 1);
            // toastr.warning(`Verification workflow deleted successfully`, ``)
            toastr.success(`${t("screens:newWorkflow.toastMsgDeleted")}`, ``)

          })
          .catch(error => {
            console.log("error", error.response);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  showAddWorkflowForm() {
    return async (dispatch: any) => {
      dispatch({
        type: workflowConst.SHOW_WORKFLOW
      })
    }
  },
}