import axios from "axios";
import { toastr } from "react-redux-toastr";
import { config } from "../../../../config";
import { loginAction } from "../../../Login/actions/loginAction";
import { emailTemplate, loaderConst } from "../../../types";

export const emailTemplateActions = {

  getAllEmailTemplates() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/email/templates`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(emailTemplatesList => {
            dispatch({
              type: emailTemplate.GET_EMAIL_PURPOSE,
              payload: emailTemplatesList
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getEmailTemplatesById(id: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/email/template/${id}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(emailTemp => {
            dispatch({
              type: emailTemplate.GET_SELECTED_EMAIL_TEMPLATE,
              payload: emailTemp
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  updateEmailTemplate(id: any, imageData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.put(`${config.apiUrl}/email/template/${id}`, imageData,
          { headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token}` }, })
          .then(async finalEmailData => {
            dispatch({
              type: emailTemplate.UPDATE_EMAIL_TEMPLATE,
              payload: finalEmailData
            })
            // this.getAllEmailTemplates();
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            toastr.success(`${finalEmailData.data.message}`,``);
          }).catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

}
