import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';

export interface ICredentialDetailProps {
  credentialAction: any,
  schemaAction: any,
  CredentialReducer: any,
  hideClose?: boolean,
}

export interface ICredentialDetailState {
  credDefShow: boolean,
  isEdited: boolean,
  newCredentialList: Array<any>,
  isSelected: boolean,
  selectedSchema: any,
  checked: boolean,
  credDefData: {
    tagName: any,
    description: any,
  },
  revocable: boolean,
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  isTagNameDuplicate: boolean,
  sortingOrder: string,
  CredentialSortBy: string,
  clearTypeahead: boolean,
}
class CredentialDetail extends React.Component<ICredentialDetailProps, ICredentialDetailState> {
  constructor(props: ICredentialDetailProps) {
    super(props);

    this.state = {
      credDefShow: false,
      isEdited: false,
      newCredentialList: [],
      isSelected: false,
      selectedSchema: '',
      checked: false,
      credDefData: {
        tagName: '',
        description: '',
      },
      revocable: false,
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      isTagNameDuplicate: false,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
      clearTypeahead: false,
    }
    this.handleCancel = this.handleCancel.bind(this);
    this.showCredDefForm = this.showCredDefForm.bind(this);

  }

  handleCancel(event: React.MouseEvent<HTMLButtonElement> | any) {
    event.preventDefault();
    this.setState({
      credDefShow: false,
      isEdited: false,
      isSelected: false,
      selectedSchema: {},
      credDefData: {
        tagName: '',
        description: '',
      },
      revocable: false,
      clearTypeahead: true,
    })
  }

  showCredDefForm() {
    this.props.credentialAction.showForm()
  }

  public render() {
    const {
      selectedCredentialDetails
    } = this.props.CredentialReducer;

    return (
      <div className="card nb-card-noborder">
        <div className="card-header bg-white">
          <div className="card-title m-0">
            <div className="row">
              <div className="col-sm-8">
                <h1>{t("screens:createNewCredential.credentialDetails")}</h1>
              </div>
              <div className="col-sm-4">
                {this.props.hideClose ? <></> :
                  <button className="btn float-right">
                    <i className="fas fa-times" onClick={this.showCredDefForm}></i>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form action="#">
            <div className="row">
              <div className="col-sm-6">
                <div className="card mb-3 bg-light">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="form-group">
                            <label className="nb-label">{t("screens:createNewCredential.schemaName")}</label>
                            <input type="text" className="form-control" value={!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.schema.schemaName} disabled></input>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="nb-label">{t("screens:createNewCredential.schemaVersion")}</label>
                          <input type="text" className="form-control" value={!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.schema.schemaVersion} disabled />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <>
                          {
                            !_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails && selectedCredentialDetails.schema.attributes.map((value: any, index: any) => {
                              return (
                                <div className="row" key={index}>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label className="nb-label">{t("screens:createNewCredential.attribute")} {index + 1}</label>
                                      <input type="text" className="form-control" key={index} value={JSON.parse(value).name} disabled />
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <label className="nb-label">{t("screens:createNewCredential.format")}</label>
                                      <input type="text" className="form-control" value={JSON.parse(value).value ? JSON.parse(value).value : 'Format'} disabled />
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="revocable"
                          checked={!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.supportRevocation}
                        />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                          {t("screens:createNewCredential.revocable")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        {t("screens:createNewCredential.credentialName")}
                      </label>
                      <input type="text" className="form-control" name={t("screens:createNewCredential.tagName")}
                        value={!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.tag} tabIndex={3}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>{t("screens:createNewCredential.description")}</label>
                      <textarea className="form-control" style={{ height: '100px' }} name="description"
                        value={(!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.description) || `t("screens:createNewCredential.description")`} tabIndex={4}
                        disabled
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>{t("screens:createNewCredential.schemaLedgerId")}</label>
                      <input type="text" className="form-control" name="tagName"
                        value={!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.schemaLedgerId}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>{t("screens:createNewCredential.credDefLedgerId")}</label>
                      <input type="text" className="form-control" name="tagName"
                        value={!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.credentialDefinitionId}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
function mapStateToProps() {
  return {}
}

function mapDispatchToProps() {
  return {}
}

const createCredentialDetail = connect(mapStateToProps, mapDispatchToProps)(CredentialDetail);
export { createCredentialDetail as CredentialDetail };