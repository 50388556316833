import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { myCredentials } from "src/View/types";
import TableComponents from "../../../Common Components/Components/TableComponent";
import { filterMyCredentialStatusList } from "../myCredentialConstant";
import { myCredentialsAction } from "../action/myCredentialsAction";

/* Interface for Props variables*/
interface IMyCredentialListProps {
    MyCredentialReducer: any,
    myCredentialsAction: any,
}
/* Interface for local states variables*/
interface IMyCredentialListState {
    pageNo: number
    itemsPerPage: number
    searchText: string
    sortValue: string
    sortBy: string,
    filterText: myCredentials
}

class MyCredentialList extends React.Component<IMyCredentialListProps, IMyCredentialListState> {
    constructor(props: IMyCredentialListProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            pageNo: 1,
            itemsPerPage: 10,
            searchText: "",
            sortValue: "",
            sortBy: "",
            filterText: myCredentials.stored
        }
        this.filter = this.filter.bind(this);
    }

    /* Default lifecycle method to call get all credential list */
    componentDidMount() {
        const { pageNo, itemsPerPage, searchText, sortValue, sortBy, filterText } = this.state
        const { credentialOfferReqData } = this.props.MyCredentialReducer;
        this.props.myCredentialsAction.getAllCredentialOfferList({ pageNo, itemsPerPage, searchText, sortValue, sortBy, filterText });
        if (credentialOfferReqData.length > 0) {
            this.props.myCredentialsAction.showSelectedCredentialDetails(credentialOfferReqData[0])
        }
    }

    /* Method used for on page change and then get updated API list */
    onChangePage = (page: any) => {
        const { itemsPerPage, searchText, sortValue, sortBy, filterText } = this.state;
        this.props.myCredentialsAction.getAllCredentialOfferList({ pageNo: page, itemsPerPage, searchText, sortValue, sortBy, filterText });
    }

    /* Method to store selected credential details */
    selectedReceivedCredentialRecord = (item: any) => {
        this.props.myCredentialsAction.showSelectedCredentialDetails(item)
    }

    /* Method to search data in list and update the UI */
    search = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { pageNo, itemsPerPage, sortValue, sortBy, filterText } = this.state;
        if (event.key === 'Enter') {
            this.setState({ searchText: event.target.value });
            const searchText: string = event.target.value
            this.props.myCredentialsAction.getAllCredentialOfferList({ pageNo, itemsPerPage, searchText, sortValue, sortBy, filterText });
        }
    }
    filter = (selectedFilterValue: myCredentials) => {
        const { pageNo, itemsPerPage, searchText, sortValue, sortBy } = this.state;
        this.setState({ filterText: selectedFilterValue })
        this.props.myCredentialsAction.getAllCredentialOfferList({ pageNo, itemsPerPage, searchText, sortValue, sortBy, filterText: selectedFilterValue });
    }

    /* Method to refresh the credential list */
    refresh = () => {
        const defaultPayload = {
            pageNo: 1,
            itemsPerPage: 10,
            searchText: "",
            sortValue: "",
            sortBy: "",
            filterText: myCredentials.stored
        }
        this.setState({ ...defaultPayload })

        this.props.myCredentialsAction.getAllCredentialOfferList(defaultPayload);
    }

    public render() {
        const { credentialOfferReqData, tHead, totalCredentialOfferReq, selectedCredentialDetails } = this.props.MyCredentialReducer;
        return (
            <div>
                <TableComponents
                    onChangePage={this.onChangePage}
                    showElementDetails={this.selectedReceivedCredentialRecord}
                    tHead={tHead}
                    tableContent={credentialOfferReqData}
                    totalRecords={totalCredentialOfferReq}
                    searching={this.search}
                    refresh={this.refresh}
                    filter={filterMyCredentialStatusList}
                    onChangeFilter={this.filter}
                    selectedRecord={selectedCredentialDetails}
                />
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    const MyCredentialReducer = state.MyCredentialReducer;
    return {
        MyCredentialReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
    }
}

const myCredentialList = connect(mapStateToProps, mapDispatchToProps)(MyCredentialList);
export { myCredentialList as MyCredentialList };
