import { workflowConst } from "../../types";
import { t } from 'i18next';

const initialState = {
  workflowList: [],
  tHead: [],
  selectedWorkflow: {},
  createNewWorkflow: false,
  isEditable: false,
  updateWorkflow: false,
  firstTitle:false,
}
// TODO workflow
export function WorkflowReducer(state = initialState, action: any) {
  let tHead = [{
    'name': `${t("screens:newWorkflow.workflow")}`,
    'label': "workflowName"
  }, {
    'name': `${t("screens:newWorkflow.createdBy")}`,
    'label': "name"
  }, {
    'name': `${t("screens:newWorkflow.status")}`,
    'label': "status"
  }, {
    'name': '',
    'label': "actions"
  }];
  switch (action.type) {
    case workflowConst.WORKFLOW_LIST:
      return Object.assign({}, state, {
        workflowList: action.payload.data,
        totalItems: action.payload.totalItems,
        tHead: tHead,
        createNewWorkflow: false,
        selectedWorkflow: {},
        firstTitle: true,
        
      });
    case workflowConst.SELECT_WORKFLOW:
      return Object.assign({}, state, {
        selectedWorkflow: action.payload,
        isEditable: true,
        createNewWorkflow: false,
        updateWorkflow: true,
        firstTitle: false,
      });
    case workflowConst.CREATE_WORKFLOW:
      return Object.assign({},
        state,
        {
          createNewWorkflow: true,
          isEditable: false,
        })
    case workflowConst.DEFAULT_CREATE_WORKFLOW:
      return Object.assign({},
        state,
        {
          createNewWorkflow: false,
        })
    case workflowConst.UPDATE_WORKFLOW:
      return Object.assign({},
        state,
        {
          createNewWorkflow: false,
          updateWorkflow: false,
          isEditable: false,
          firstTitle: true,
        })
    case workflowConst.DELETE_WORKFLOW:
      return Object.assign({},
        state,
        {})
    case workflowConst.SHOW_WORKFLOW:
      return Object.assign({},
        state,
        {
          createNewWorkflow: false,
          selectedWorkflow: {},
          updateWorkflow: false,
          isEditable: false,
          firstTitle: true,
        })
    default:
      return state
  }
}

