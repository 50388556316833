import { t } from "i18next";
import { presentationNegotiateConstants } from "src/View/types";

const initialState = {
    credentialsForPresentationRequest: [],
    sendProofSuccess: false,
    isDeclineSent: false,
    verifyProofStatus: {},
    hideVerifyButton: false,
    presentationList: [],
    totalPresentations: 0,
    tHead: [],
    sendProofRequestResponse: {},
    selectedPresentationRecord: {},
    showPresentProof: false,
    connectionRecord: {},
    isRePropose: false,
    selectedPresentationRequest: {}
}

export function PresentationNegotiateReducer(state = initialState, action: any) {
    let tHead = [{
        'name': `${t("screens:newProposal.date")}`,
        'label': "createDateTime"
    }, {
        'name': `${t("screens:newProposal.givenName")}`,
        'label': "connectionName"
    }, {
        'name': `${t("screens:newProposal.status")}`,
        'label': "state"
    }, {
        'name': ``,
        'label': "details"
    }]

    switch (action.type) {

        case presentationNegotiateConstants.GET_ALL_PRESENTATION_LIST:
            return Object.assign({}, state, {
                presentationList: action.tmpArray,
                totalPresentations: action.payload.data.data.totalItems,
                tHead: tHead,
                selectedPresentationRecord: action.selectedPresentationRecord
            });

        case presentationNegotiateConstants.SEND_PROOF_REQUEST:
            return Object.assign({}, state,
                {
                    sendProofRequestResponse: action.payload,
                    sendProofSuccess: true,
                    // isDeclineSent: false
                });

        case presentationNegotiateConstants.SELECTED_NEGOTIATE_RECORD:
            return Object.assign({}, state, {
                selectedPresentationRecord: action.payload,
                hideVerifyButton: false,
                isRePropose: false
            })

        case presentationNegotiateConstants.FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST:
            console.log('action.payload', action.payload)
            return Object.assign({}, state,
                {
                    credentialsForPresentationRequest: action.payload,
                    sendProofSuccess: false,
                    // isDeclineSent: false
                });

        case presentationNegotiateConstants.CLOSE_SUCCESS:
            return Object.assign({}, state,
                {
                    sendProofSuccess: false,
                    // isDeclineSent: false,
                });

        case presentationNegotiateConstants.PRESENT_PROOF_VERIFIED:
            return Object.assign({}, state, {
                verifyProofStatus: action.payload.data.data.state,
                showPresentProof: false,
                hideVerifyButton: true,
            });

        case presentationNegotiateConstants.RE_PROPOSE_PROOF_REQUEST:
            return Object.assign({}, state, {
                selectedPresentationRequest: action.payload.selectedPresentationRecord,
                connectionRecord: action.payload.connectionDetails,
                isRePropose: true
            });

        default:
            return state
    }
}