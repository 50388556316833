import axios from "axios";
import i18n from "i18next";

// Utilities
import { config } from "src/config";
import { sleep } from "src/utils/sleep";
import { LANGUAGES } from "./index";

// Types
import { Locales, LocalizationResource } from "./index";

/**
 * Fetch a remote help localization resource bundle
 *
 * @param   locale - Locale code
 * @returns Help localization bundle
 */
const fetchHelpLocalization = async (
  locale: Locales
): Promise<LocalizationResource | null> => {
  try {
    const { data } = await axios.get(`${config.helpGuideUrl}/${locale}.json`);

    return data;
  } catch (e) {
    console.log(`Error fetching '${locale}' help localization bundle`, e);

    return null;
  }
};

/**
 * Load remote help localization guides/bundles
 */
const loadHelpLocalizations = async (): Promise<void> => {
  await sleep(1000);

  for (const language of LANGUAGES) {
    const resource = await fetchHelpLocalization(language.code);
    if (!resource) continue;

    i18n.addResourceBundle(language.code, "help", resource);
  }
};

export { loadHelpLocalizations };
