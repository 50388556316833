import React from "react";
import { basicMessageConstant } from "../../commonConst";
import { t } from 'i18next';

interface IChatMessagesProps {
    selectedChatConnection: any,
    sendBasicMessage: any,
    chatsConnectionList: any,
    chatsAction: any,
    onShowClaimCredential: any,
    messageRecords: any,
    handleScroll: any
}
interface IChatMessagesState {
    basicMessage: string

}
export default class ChatMessages extends React.Component<IChatMessagesProps, IChatMessagesState> {

    constructor(props: IChatMessagesProps) {
        super(props)
        this.state = {
            basicMessage: ''
        }
        // Bind all the methods.
        this.claimCredential = this.claimCredential.bind(this)
        this.onMessageChange = this.onMessageChange.bind(this)
        this.sendBasicMessage = this.sendBasicMessage.bind(this)
        this.onScroll = this.onScroll.bind(this)
    }

    /**
     * Method used to update the issue claim credential flag
     */
    claimCredential() {
        this.props.onShowClaimCredential()
    }

    /**
     * Method used set the send message value
     * @param event 
     */
    onMessageChange(event: React.ChangeEvent<HTMLInputElement> | any) {
        this.setState({ basicMessage: event.target.value })
    }

    /**
     * Method used to send basic message.
     */
    sendBasicMessage() {
        const { basicMessage } = this.state
        this.props.sendBasicMessage(basicMessage)
        this.setState({ basicMessage: '' })
    }

    componentDidMount() {
        // SOCKET DISABLE FIREBASE
        //  type === "BASIC_MESSAGE"
        const { selectedChatConnection, chatsConnectionList } = this.props
        //  ?? const { record, type } = message.data.firebaseMessaging.payload?.data || {}
        // if (type === "BASIC_MESSAGE") {
        //     if (messageDetails.state === 'received') {
        //         if (selectedChatConnection.connectionId === messageDetails.connectionId) {
        //             console.log("in if")
        // this.props.chatsAction.onReceivedNotification(chatsConnectionList, messageDetails, selectedChatConnection)
        // } else {
        //     console.log("in else")
        this.props.chatsAction.highlightNotificationReceived(selectedChatConnection)
        // }
    }

    // /* Event listener for get received notification message details. */
    // navigator.serviceWorker.addEventListener("message", (message) => {
    //     const { selectedChatConnection, chatsConnectionList } = this.props
    //     if (message && message.data.firebaseMessaging) {
    //         const { record, type } = message.data.firebaseMessaging.payload?.data || {}
    //         const messageDetails = record && JSON.parse(record)
    //         if (type === "BASIC_MESSAGE") {
    //             if (messageDetails.state === 'received') {
    //                 if (selectedChatConnection.connectionId === messageDetails.connectionId) {
    //                     console.log("in if")
    //                     this.props.chatsAction.onReceivedNotification(chatsConnectionList, messageDetails, selectedChatConnection)
    //                 } else {
    //                     console.log("in else")
    //                     this.props.chatsAction.highlightNotificationReceived(selectedChatConnection)
    //                 }
    //             }
    //         }
    //     }
    // })
    /**
     * Method called on scroll to get messages
     * @param event 
     */
    onScroll(event: any) {
        let element = event.target
        if (element.scrollHeight - Math.abs(element.scrollTop) === element.clientHeight) {
            this.props.handleScroll()
        }
    }

    public render() {
        const { selectedChatConnection, messageRecords } = this.props
        const { basicMessage } = this.state
        return (
            <>
                <div className="col-md-12 col-lg-6 mb-30">
                    <div className="card nb-card h-100">
                        <div className="card-header bg-white">
                            <div className="row">
                                <div className="col-sm-8">
                                    <h1 className="nb-title">{t("screens:chat.chats")}</h1>
                                </div>
                                {/* Disable CLAIM_CREDENTIAL_BUTTON
                                <div className="col-sm-4">
                                    <div className='mt-auto float-right'>
                                        <button type="button" className="btn btn-sm btn-nb-primary rounded-pill"
                                            onClick={this.claimCredential}
                                            disabled={selectedChatConnection && selectedChatConnection.basicMessages && selectedChatConnection.basicMessages.length === 0}
                                        >
                                            {basicMessageConstant.CLAIM_CREDENTIAL_BUTTON}
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="card-body chat-scroll" onScroll={(event) => this.onScroll(event)}>
                            {
                                messageRecords && messageRecords.length > 0 ?
                                    messageRecords && messageRecords.length > 0 && messageRecords.map((message: any) => {
                                        if (message.state === 'received' && message.content !== '') {
                                            return (

                                                <div className="flex-row justify-content-start chat-msg">
                                                    <div className="received-chat-text-body ">
                                                        <p className='mb-2'>
                                                            <label className="received-message-background"> {message.content}</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        } else if (message.state === 'sent' && message.content !== '') {
                                            return (
                                                <div className="flex-row justify-content-end chat-msg">
                                                    <div className="sent-chat-text-body ">
                                                        <p className='mb-2 '>
                                                            <label className="sent-message-background"> {message.content}</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                    : <div className='text-muted'> <em>{t("screens:chat.messageNoMessageYet")}</em></div>
                            }
                        </div>

                        <div className="card-footer bg-white">
                            <div className="row">
                                <div className="col-sm-10">
                                    <textarea className="form-control" value={basicMessage} name="description" placeholder={t("screens:chat.inputMessage")} onChange={(event) => this.onMessageChange(event)} />
                                </div>
                                <div className="col-sm-2">
                                    <button type="button" className="btn btn-sm btn-nb-primary" onClick={this.sendBasicMessage}>
                                        <i className="nb-send-message" ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}