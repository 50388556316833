import * as React from 'react';
import CropImageModal from '../../../Register/components/cropImageModal';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import _ from 'lodash';
import draftToHtml from 'draftjs-to-html';
import { hashConfig } from '../../../../config';
import htmlToDraft from 'html-to-draftjs'
import { toastr } from 'react-redux-toastr';

// Utilities
import images from 'src/utils/images';

export interface IEditEmailTemplatesProps {
  emailTemplateActions: any,
  EmailTemplatesReducers: any,
}

export interface IEditEmailTemplatesState {
  croppedImageUrl: any,
  showCroppedImage: boolean,
  src: any,
  isModalShow: boolean,
  crop: any,
  croppedImage: any,
  editorState: any,
  editStateforSignature: any,
  showEditor: boolean,
  showEmailContentEditor: boolean,
  isEdit: boolean,
}

export default class EditEmailTemplates extends React.Component<IEditEmailTemplatesProps, IEditEmailTemplatesState> {
  private imageRef: any;
  private fileUrl: any;
  constructor(props: IEditEmailTemplatesProps) {
    super(props);

    this.state = {
      croppedImageUrl: '',
      showCroppedImage: false,
      src: null,
      isModalShow: false,
      crop: {
        unit: '%',
        width: 30,
        height: 30,
        x: 25,
        y: 25,
      },
      croppedImage: "",
      editorState: EditorState.createEmpty(),
      editStateforSignature: EditorState.createEmpty(),
      showEditor: true,
      showEmailContentEditor: true,
      isEdit: false,
    }
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.makeClientCrop = this.makeClientCrop.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);
    this.dataURLtoFile = this.dataURLtoFile.bind(this);
    this.save = this.save.bind(this);
    this.clearCroppedImage = this.clearCroppedImage.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.showHideEditor = this.showHideEditor.bind(this);
    this.showHideEmailContentEditor = this.showHideEmailContentEditor.bind(this);
    this.updateEmailTemplate = this.updateEmailTemplate.bind(this);
    this.onSignatureChange = this.onSignatureChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  shouldComponentUpdate(newProps: IEditEmailTemplatesProps) {
    if (newProps !== this.props) {
      const { selecteEmailTemp, updatedEmailTemp } = newProps.EmailTemplatesReducers;

      if ((selecteEmailTemp && selecteEmailTemp.emailBanner !== undefined && selecteEmailTemp.emailBanner !== null) || (updatedEmailTemp && updatedEmailTemp.emailBanner !== undefined && updatedEmailTemp.emailBanner !== null)) {
        this.setState({ croppedImage: selecteEmailTemp.emailBanner });
      }
      if ((selecteEmailTemp && selecteEmailTemp.emailContent !== undefined && selecteEmailTemp.emailContent !== null) || (updatedEmailTemp && updatedEmailTemp.emailContent !== undefined && updatedEmailTemp.emailContent !== null)) {
        let rawContent = !_.isEmpty(selecteEmailTemp) ? selecteEmailTemp.emailContent : !_.isEmpty(updatedEmailTemp) && updatedEmailTemp.emailContent;
        const contentState = htmlToDraft(rawContent)
        if (contentState) {
          const content = ContentState.createFromBlockArray(contentState.contentBlocks);
          const newEditorState = EditorState.createWithContent(content);
          this.setState({
            editorState: newEditorState,
          })
        }
      } else {
        this.setState({
          editorState: EditorState.createEmpty()
        })
      }
      if ((selecteEmailTemp && selecteEmailTemp.emailSignature !== undefined && selecteEmailTemp.emailSignature !== null) || (updatedEmailTemp && updatedEmailTemp.emailSignature !== undefined && updatedEmailTemp.emailSignature !== null)) {
        let rawContent = !_.isEmpty(selecteEmailTemp) ? selecteEmailTemp.emailSignature : !_.isEmpty(updatedEmailTemp) && updatedEmailTemp.emailSignature;
        const contentState = htmlToDraft(rawContent);
        if (contentState) {
          const content = ContentState.createFromBlockArray(contentState.contentBlocks);
          const newemailSignature = EditorState.createWithContent(content);
          this.setState({
            editStateforSignature: newemailSignature,
          })
        }
      } else {
        this.setState({
          editStateforSignature: EditorState.createEmpty(),
        })
      }
    }

    return true
  }

  updateEmailTemplate(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any, id: any): void {
    e.preventDefault()
    let emailContent = convertToRaw(this.state.editorState.getCurrentContent());
    let emailSignature = convertToRaw(this.state.editStateforSignature.getCurrentContent());
    const emailContentmarkup = draftToHtml(
      emailContent,
      hashConfig
    );
    const emailSignaturemarkup = draftToHtml(
      emailSignature,
      hashConfig
    );

    const fieldData = new FormData()
    fieldData.append('emailBanner', this.state.croppedImage)
    fieldData.append('emailContent', emailContentmarkup);
    fieldData.append('emailSignature', emailSignaturemarkup);

    this.props.emailTemplateActions.updateEmailTemplate(id, fieldData)
    this.setState({
      editorState: EditorState.createEmpty(),
      editStateforSignature: EditorState.createEmpty(),
      // croppedImageUrl: "",
      showCroppedImage: false,
      isEdit: false,
    })
  }

  onEditorChange(editorState: any) {
    this.setState({
      editorState: editorState,
    })
  }

  onSignatureChange(item: any) {
    this.setState({
      editStateforSignature: item
    })
  }

  showHideEmailContentEditor() {

    const { showEmailContentEditor } = this.state;
    this.setState({
      showEmailContentEditor: !showEmailContentEditor
    })
  }

  showHideEditor() {
    const { showEditor } = this.state;
    this.setState({
      showEditor: !showEditor
    })
  }

  onSelectFile(event: React.ChangeEvent<HTMLInputElement> | any) {
    var fileName = event.target.value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      if (event.target.files && event.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result, isModalShow: true })
        );
        reader.readAsDataURL(event.target.files[0]);
      }
      event.target.value = null;
    } else {
      toastr.info(`Only jpg/jpeg and png files are allowed!`, ``);
    }

  };

  // onSelectFile(event: React.ChangeEvent<HTMLInputElement> | any) {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () =>
  //       this.setState({ src: reader.result, isModalShow: true })
  //     );
  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  //   event.target.value = null;
  // };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'cropped.jpg')
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');

    });
  }

  dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage })
  }

  save() {
    this.setState({ showCroppedImage: true, isModalShow: false, src: null })
  }

  cancel() {
    this.setState({ isModalShow: false })
  }

  clearCroppedImage() {
    this.setState({ croppedImageUrl: "", showCroppedImage: false })
  }

  handleCancel(event: any) {
    this.setState({ isEdit: false })

  }

  editEmailTemplate(event: any) {
    this.setState({
      isEdit: true,
    })
  }

  public render() {
    const { croppedImageUrl, editStateforSignature, showCroppedImage, isModalShow, src, crop, editorState, showEditor, showEmailContentEditor, isEdit } = this.state;
    const {
      selecteEmailTemp, updatedEmailTemp,
    } = this.props.EmailTemplatesReducers;

    return (

      <div className="card nb-card-noborder h-100">
        <div className="card-header bg-white">
          <h1>Edit Email Templates - {!_.isEmpty(selecteEmailTemp) ? selecteEmailTemp.emailPurpose : !_.isEmpty(updatedEmailTemp) && updatedEmailTemp.emailPurpose}
          </h1>
        </div>
        <div className="card-body">
          <div className="col-sm-12">
            <label>Email Banner</label>
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-9">
                    {showCroppedImage && (
                      <div className="card nb-card-image" style={{ backgroundImage: `url(${croppedImageUrl})` }}>
                        <div className="Card-body"></div>
                      </div>
                    )}
                    {
                      showCroppedImage === false ?
                        <div className="card nb-card-image" style={{ backgroundImage: `url(${_.isEmpty(selecteEmailTemp) ? croppedImageUrl : !_.isEmpty(selecteEmailTemp) ? selecteEmailTemp.emailBanner : _.isEmpty(updatedEmailTemp) ? selecteEmailTemp.emailBanner : !_.isEmpty(updatedEmailTemp) ? updatedEmailTemp.emailBanner : images.placeholderRectangleClear})` }}>
                          <div className="card-body"></div>
                        </div>
                        : null
                    }
                  </div>
                  <div className="col-sm-3 align-self-end">
                    {showCroppedImage === false && isEdit === true ? <div>
                      <div className="upload-btn-wrapper float-right">
                        <button>CHANGE IMAGE</button>
                        <input type="file" accept="image/png,image/gif,image/jpeg"

                          onChange={this.onSelectFile} name="emailBanner" />
                      </div>
                    </div>
                      :
                      isEdit === true &&
                      <div>
                        <button className="btn btn-outline-primary" onClick={this.clearCroppedImage}>Cancel</button>
                      </div>
                    }
                    {isModalShow && <CropImageModal
                      isModalShow={isModalShow}
                      src={src}
                      crop={crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                      save={this.save}
                      cancel={this.cancel}
                    />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className={isEdit ? "card mb-3" : "card mb-3 bg-light"}>
              <div className="card-header bg-white border-bottom-0">
                Email Content
              </div>
              <div className="card-body">
                {showEmailContentEditor ?
                  <Editor
                    editorState={editorState}
                    toolbarClassName="demo-toolbar-custom"
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor-custom"
                    onEditorStateChange={(newEditorState) => this.onEditorChange(newEditorState)}
                    toolbarOnFocus
                    spellCheck={true}
                    readOnly={isEdit ? false : true}
                  />
                  : null
                }
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className={isEdit ? "card mb-3" : "card mb-3 bg-light"}>
              <div className="card-header bg-white border-bottom-0">
                Email Signature
              </div>
              <div className="card-body">
                {showEditor ?
                  <Editor
                    editorState={editStateforSignature}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onSignatureChange}
                    toolbarOnFocus
                    readOnly={isEdit ? false : true}
                  />
                  : null
                }
              </div>
            </div>
          </div>
          <div className="col-sm-12 align-self-end">
            <div className="row">
              <div className="col-sm-4">
                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                  onClick={this.handleCancel}
                >
                  CANCEL
                                    </button>
              </div>
              <div className="col-sm-4">
                {isEdit ?

                  <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                    onClick={(e) => !_.isEmpty(updatedEmailTemp) ? this.updateEmailTemplate(e, updatedEmailTemp.id) : !_.isEmpty(selecteEmailTemp) && this.updateEmailTemplate(e, selecteEmailTemp.id)}
                  // disabled={showCroppedImage === false}
                  >
                    UPDATE
                </button>
                  :
                  <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                    onClick={(e) => this.editEmailTemplate(e)}
                  // disabled={showCroppedImage === false}
                  >
                    EDIT
                                    </button>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
