import _ from 'lodash';
import * as React from 'react';
import CredentialValueComponent from 'src/Common Components/Components/CredentialValue';
import TypeaheadComponent from '../../../Common Components/Components/TypeaheadComponent';
import { t } from 'i18next';
import { ListScreenOptions } from 'src/types/common.interface';
import { CredDefResponsibilityType } from 'src/View/IssuersVerifiers/interfaces';

export interface ICreateCredentialOfferProps {
    credentialAction: any,
    TaskActions: any,
    CredentialOfferAction: any,
    connectionAction: any,

    TaskReducers: any,
    ConnectionReducer: any,
    IssuanceReducer: any,
    LoginReducer: any
}

export interface ICreateCredentialOfferState {
    clearTypeAhead: boolean,
    isSelected: boolean,
    selectedCredDef: any,
    credDefId: any,
    connectionId: any,
    selectedConnection: any,
    isModalShow: boolean,
    credentialValues: any,
    theirDid: string,
    listScreenOptions: ListScreenOptions,
    isDeleted: boolean
}

export default class CreateCredentialOffer extends React.Component<ICreateCredentialOfferProps, ICreateCredentialOfferState> {
    constructor(props: ICreateCredentialOfferProps) {
        super(props);

        this.state = {
            clearTypeAhead: false,
            isSelected: false,
            selectedCredDef: {},
            credDefId: '',
            selectedConnection: {},
            connectionId: '',
            isModalShow: false,
            credentialValues: [],
            theirDid: '',
            listScreenOptions: {
                pageNo: 1,
                itemsPerPage: 10,
                search: '',
                filter: '',
                sort: '',
                sortBy: ''
            },
            isDeleted: false,
        }

        this.sendOffer = this.sendOffer.bind(this);
        this.handleConnectionChange = this.handleConnectionChange.bind(this);
        // this.handleOrganizationInputChange = this.handleOrganizationInputChange.bind(this);
        this.handleCredDefChange = this.handleCredDefChange.bind(this);
        // this.handleCredDefInputChange = this.handleCredDefInputChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangeForConnection = this.handleInputChangeForConnection.bind(this)

    }

    componentDidMount() {
        const { loggedInUser } = this.props.LoginReducer;
        const { listScreenOptions, isDeleted } = this.state
        this.props.connectionAction.getAllConnections(
            listScreenOptions.search,
            listScreenOptions.filter,
            listScreenOptions.sort,
            listScreenOptions.sortBy,
            0,
            listScreenOptions.pageNo,
            isDeleted);

        this.props.TaskActions.getAllCreateCredDefByOrgId(loggedInUser.organization.id, CredDefResponsibilityType.Issuance);
    }

    sendOffer(e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        selectedCredDef: any,
        selectedConnection: any,
    ): void {
        console.log('send Offer')

        const { credentialValues } = this.state;

        let schemaAttribute: any = [];

        selectedCredDef.schema.attributes.forEach((ele: any, idx: number) => {
            let jsonObj = {
                "name": JSON.parse(ele).name,
                "value": credentialValues[idx] === undefined || credentialValues[idx] === null || credentialValues[idx] === "" 
                    ? " " 
                    : (JSON.parse(ele).name).includes('_dateint') 
                        ? credentialValues[idx].split("/").reverse().join("") 
                        :credentialValues[idx],
            }
            if (JSON.parse(ele).value === 'image') {
                Object.assign(jsonObj, { "mime-type": "image/jpeg" })
            }
            schemaAttribute.push(jsonObj);
        });
        
        let finalData = {
            auto_issue: true,
            connection_id: selectedConnection.connectionId,
            cred_def_id: selectedCredDef.credentialDefinitionId,
            comment: '',
            attributes: schemaAttribute,
            selectedConnection: selectedConnection.theirLabel
        }

        this.props.CredentialOfferAction.sendOffer(finalData, selectedCredDef.tag);
        this.handleCancel(e);

    }


    /**
     * Method used to handle selected credential details
     * @param selectedCredDefOptions 
     */
    handleCredDefChange(selectedCredDef: any) {
        let selectedData = selectedCredDef[0];

        this.setState({
            selectedCredDef: selectedData,
            credDefId: selectedData && selectedData.credentialDefinitionId,
        })
    }

    showModal = () => {
        this.setState({
            isModalShow: true,
        })
    }
    closeModal = () => {
        this.setState({
            isModalShow: false,
        })
    }

    handleInputChange() {
        this.setState({
            isSelected: false,
            credDefId: ''
        })
    }

    handleConnectionChange(selectedOptions: any) {
        let selectedData = selectedOptions[0];
        this.setState({
            clearTypeAhead: false,
            isSelected: true,
            selectedConnection: selectedData,
            connectionId: selectedData && selectedData.connectionId,
            theirDid: selectedData && selectedData.theirDid,
        })
    }

    handleInputChangeForConnection() {
        this.setState({
            isSelected: false,
            connectionId: ''
        })
    }

    handleCredentialValueSubmit = (credentialValue: any) => {
        this.setState({
            credentialValues: credentialValue
        })
    }

    handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
        event.preventDefault();
        this.setState({
            credentialValues: [],
            isSelected: false,
            credDefId: '',
            selectedCredDef: {},
            clearTypeAhead: true,
            connectionId: '',
            theirDid: ''
        })
        this.props.CredentialOfferAction.clearIssuanceCredential();
    }

    public render() {
        const { connectionList } = this.props.ConnectionReducer;
        const {
            clearTypeAhead,
            selectedCredDef,
            isModalShow,
            selectedConnection,
            credentialValues,
            theirDid
        } = this.state;
        const { createdCredDefsbyOrgId } = this.props.TaskReducers;
        let { filledCredentialValue } = this.props.IssuanceReducer;
        let disabledButton = _.isEmpty(selectedCredDef) ? true : _.isEmpty(selectedConnection) ? true : _.isEmpty(credentialValues) ? true : false;

        return (
            <>
                <div className="card-header bg-white border-bottom-0">
                    <h1 className="nb-title">{t("screens:credentialOffer.newCredentialOffer")}</h1>
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="nb-label">{t("screens:issueCredentials.name")}</label>
                                {console.log('createdCredDefsbyOrgId', createdCredDefsbyOrgId)}
                                {
                                    <TypeaheadComponent
                                        data={createdCredDefsbyOrgId}
                                        onChange={this.handleCredDefChange}
                                        onInputChange={this.handleInputChange}
                                        clearTypeahead={clearTypeAhead}
                                        placeholder={t("screens:issueCredentials.selectCredentialName")}
                                        disabled={false}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="nb-label">{t("screens:issueCredentials.givenName")}</label>
                                {
                                    <TypeaheadComponent
                                        data={connectionList}
                                        onChange={this.handleConnectionChange}
                                        onInputChange={this.handleInputChangeForConnection}
                                        clearTypeahead={clearTypeAhead} placeholder={t("screens:issueCredentials.selectConnection")}
                                        disabled={false}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="nb-label">{t("screens:issueCredentials.connectionDID")}</label>
                                <input type="text" className="form-control"
                                    name="connectionDID"
                                    value={theirDid}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="nb-label">{t("screens:issueCredentials.credentialValues")}
                                    <button className="btn btn-link"
                                        onClick={this.showModal}
                                        disabled={!_.isEmpty(selectedCredDef) ? false : true}
                                    >
                                        <i className="fas fa-pencil-alt"></i>
                                    </button>
                                </label>
                                <div className="card" style={{ minHeight: '300px' }}>
                                    <div className="card-body bg-light">
                                        {!_.isEmpty(filledCredentialValue) ? filledCredentialValue.credential_proposal &&
                                            filledCredentialValue.credential_proposal.attributes.map((ele: any, index: any) => {
                                                return (
                                                    <>
                                                        <li key={index}>{ele.name}:{ele.value}</li>
                                                    </>
                                                )
                                            })
                                            :
                                            !_.isEmpty(credentialValues) && selectedCredDef && Object.keys(selectedCredDef).length >= 1 &&
                                            selectedCredDef.schema && selectedCredDef.schema.attributes &&
                                            selectedCredDef.schema.attributes.map((ele: any, index: any) => {
                                                return (
                                                    JSON.parse(ele).value !== 'image' ?
                                                        <>
                                                            <li key={index}>
                                                                {JSON.parse(ele).name}({JSON.parse(ele).value}) : {credentialValues[index]}
                                                            </li>
                                                        </> :
                                                        <>
                                                            <li key={index}>
                                                                {JSON.parse(ele).name}({JSON.parse(ele).value}) :
                                                                <img src={credentialValues[index]} className="preview" height="50" alt=''></img>
                                                            </li>
                                                        </>
                                                )
                                            })
                                        }
                                    </div>
                                    <CredentialValueComponent
                                        isModalShow={isModalShow}
                                        selectedConnection={selectedConnection}
                                        selectedCredDef={selectedCredDef && selectedCredDef.schema}
                                        handleCredentialValueSubmit={this.handleCredentialValueSubmit}
                                        closeModal={this.closeModal}
                                        isEdit={true}
                                        modalTitle={'Offer credential'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-header bg-white border-bottom-0 mb-3">
                    <div className="row">
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                                onClick={(e) => this.handleCancel(e)}
                            >
                                {t("screens:issueCredentials.cancel")}
                            </button>
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                            onClick={(e) => _.isEmpty(filledCredentialValue) && !_.isEmpty(credentialValues) &&
                                this.sendOffer(e, selectedCredDef, selectedConnection)}
                            disabled={!_.isEmpty(filledCredentialValue) ? false : disabledButton}
                            >
                                {t("screens:issueCredentials.offer")}
                            </button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
