import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectionAction } from '../actions/connectionAction';
import _ from 'lodash';
import {
  issuanceAction
} from '../../IssueCredential/actions/issuanceAction';
import { schemaAction } from '../../Schema/actions/schemaAction';
import ExistingConnectionsList from '../components/ExistingConnectionsList';
import ExistingConnectionDetails from '../components/ExistingConnectionDetails';
import ExistingConnectionMultiuse from '../components/ExistingConnectionMultiuse';
import { t } from 'i18next';
import NoRecordFoundComponent from 'src/Common Components/Components/NoRecordFound';


export interface IConnectionProps {
  connectionAction: any,
  ConnectionReducer: any,
  issuanceAction: any,
  schemaAction: any,
  credentialAction: any,
  CredentialReducer: any,
  selectedConnection: any,
}

export interface IConnectionState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  connectionSortBy: string,
  filterValue: any,
  isDeleted: any,
  theirPublicDid: string,
  theirLabel: string,
  emailNotification: string,
  isEditAttributes: boolean,
  isCredentialSelected: boolean,
  isReceivedCredentialSelected: boolean,
  errors: any;
}

class ConnectionComponent extends React.Component<IConnectionProps, IConnectionState> {
  constructor(props: IConnectionProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      filterValue: 'all',
      isDeleted: 'false',
      theirPublicDid: props.ConnectionReducer?.selectedConnection?.theirPublicDid,
      theirLabel: props.ConnectionReducer?.selectedConnection?.theirLabel,
      emailNotification: props.ConnectionReducer?.selectedConnection?.holders?.email ?
        props.ConnectionReducer?.selectedConnection?.holders?.email : "-",
      isEditAttributes: false,
      isCredentialSelected: false,
      isReceivedCredentialSelected: false,
      errors: {},
    }
    this.handlePublicDidChange = this.handlePublicDidChange.bind(this)
    this.handleConnectionChange = this.handleConnectionChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, connectionSortBy, filterValue, isDeleted } = this.state;
    this.props.connectionAction.getAllConnections(defaultSearchFields, filterValue, sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
  }

  componentDidUpdate(prevProps: Readonly<IConnectionProps>, prevState: Readonly<IConnectionState>, snapshot?: any): void {

    if (prevProps.ConnectionReducer.selectedConnection.theirPublicDid !== this.props.ConnectionReducer.selectedConnection.theirPublicDid) {
      this.setState({ theirPublicDid: this.props.ConnectionReducer.selectedConnection.theirPublicDid ?? '' })
    }

    if (prevProps.ConnectionReducer.selectedConnection.theirLabel !== this.props.ConnectionReducer.selectedConnection.theirLabel) {
      this.setState({ theirLabel: this.props.ConnectionReducer.selectedConnection.theirLabel ?? '' })

      if (this.props.ConnectionReducer.selectedConnection.holders?.email) {
        this.setState({ emailNotification: this.props.ConnectionReducer.selectedConnection.holders.email ?? '' })
      } else {
        this.setState({ emailNotification: '-' })
      }
    }

    if (prevProps.ConnectionReducer.selectedConnection !== this.props.ConnectionReducer.selectedConnection) {
      this.props.connectionAction.selectConnection(this.props.ConnectionReducer.selectedConnection);
    }
  }

  componentWillUnmount() {
    this.props.connectionAction.defaultPage()
  }

  handlePublicDidChange(event: React.DOMAttributes<HTMLInputElement> | any) {
    let { theirPublicDid, isEditAttributes } = this.state
    theirPublicDid = event.target.value

    this.setState({ theirPublicDid })

  }

  handleConnectionChange(event: React.DOMAttributes<HTMLInputElement> | any) {
    let { theirLabel } = this.state
    theirLabel = event.target.value

    this.setState({ theirLabel })
  }

  handleEmailChange(event: React.DOMAttributes<HTMLInputElement> | any) {
    let { emailNotification } = this.state
    emailNotification = event.target.value

    let errors: any = {};

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (emailNotification && !pattern.test(emailNotification)) {
      errors["email"] = `${t("screens:onboardingNewAdmins.messageErrorEmail")}`;
    }

    this.setState({ emailNotification, errors })
  }

  handleUpdate(event: any) {
    event.preventDefault()

    const { selectedConnection } = this.props.ConnectionReducer
    let { isEditAttributes, theirPublicDid, theirLabel, emailNotification } = this.state

    if (!isEditAttributes) {
      this.setState({ isEditAttributes: true })
    }
    else {
      if (theirPublicDid !== '' || theirLabel !== '' || emailNotification !== '') {
        emailNotification = emailNotification ? emailNotification : '-'
        let updateAttributes = {
          theirPublicDid,
          connectionName: theirLabel,
          emailNotification
        }
        // console.log(`updateAttributes =>  ${JSON.stringify(updateAttributes)}`)
        this.props.connectionAction.updateAttributes(selectedConnection.theirDid, updateAttributes)
        this.setState({ isEditAttributes: false })
      }
    }
  }

  selectedCredentialRecord = (record: any) => {
    // this.props.issuanceAction.selectedCredential(record, 'connections');
    this.props.credentialAction.selectCredential(record, "credDef")
    this.props.schemaAction.getSchemaById(record.schemaLedgerId);
    this.setState({
      isCredentialSelected: true,
      isReceivedCredentialSelected: false
    })
  }

  public render() {
    const { isEditAttributes, theirPublicDid, theirLabel, emailNotification, errors } = this.state
    let { selectedConnection, totalConnections } = this.props.ConnectionReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-md-12 col-lg-8 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:existingConnections.myConnections")}
                  <>
                    <span className="nb-badge nb-badge-blue nb-badge-circle ml-2">
                      {totalConnections !== undefined ? totalConnections : 0}
                    </span>
                  </>
                </h1>
              </div>
              <div className="card-body">
                <ExistingConnectionsList {...this.props} />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 mb-30">
            <div className="card nb-card mb-30">
              <div className="card-body">
                <h2 className="nb-title">{t("screens:existingConnections.updateAttributes")}</h2>
                {selectedConnection.invitationMode === 'once' &&
                  <>
                    <div className="card-body">
                      <div className='form-group'>
                        <label className="nb-label">{t("screens:existingConnections.updatePublicDID")}</label>
                        <input type='text'
                          className='form-control'
                          placeholder={t("screens:existingConnections.enterPublicDID")}
                          onChange={this.handlePublicDidChange}
                          value={theirPublicDid}
                        />
                      </div>
                      <div className='form-group'>

                        <label className="nb-label">{t("screens:existingConnections.updateConnectionName")}</label>
                        <input type='text'
                          className='form-control'
                          placeholder={t("screens:existingConnections.enterConnectionName")}
                          onChange={this.handleConnectionChange}
                          value={theirLabel}
                        />
                      </div>
                      <div className='form-group'>
                        <label className="nb-label">{t("screens:existingConnections.updateEmail")}</label>
                        <input type='text'
                          className='form-control'
                          placeholder={emailNotification ? emailNotification : `${t("screens:existingConnections.enterEmail")}`}
                          onChange={this.handleEmailChange}
                          value={emailNotification}
                        />
                        {
                          errors?.email ?
                            <div className="text-danger">
                              <small><i className="fas fa-info-circle mr-1"></i>{errors.email}</small>
                            </div>
                            : null
                        }
                      </div>
                      <button
                        className="btn btn-sm btn-block btn-nb-outline-primary rounded-pill"
                        onClick={this.handleUpdate}
                        disabled={Object.keys(errors).length > 0}
                      >
                        {
                          isEditAttributes ?
                            t("screens:existingConnections.save")
                            : t("screens:existingConnections.update")
                        }
                      </button>
                    </div>
                  </>}
              </div>
            </div>
            {((selectedConnection.invitationMode === 'once')
              ? <ExistingConnectionDetails {...this.props} />
              : <ExistingConnectionMultiuse {...this.props} />)
            }
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const ConnectionReducer = state.ConnectionReducer;
  return { ConnectionReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    connectionAction: bindActionCreators(connectionAction, dispatch),
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
    schemaAction: bindActionCreators(schemaAction, dispatch),
  }
}

const connections = connect(mapStateToProps, mapDispatchToProps)(ConnectionComponent);
export { connections as ConnectionComponent };