import { taskConst } from "../../types";
import { tHeadScheduledTaskShowDetails } from "../constant";

const initialState = {
  showPresentProof: false,
  instantTaskList: [],
  totalInstantTaskList: 0,
  selectedWFTaskList: [],
  totalSelectedWFTaskList: 0,
  proofData: {},
  verifyProofStatus: {},
  hideVerifyButton: false,
  showScheduleTaskList: false,
  showInstantTaskList: true,
  showAutomatedProofs: false,
  tHeadScheduledTaskShowDetails: [],
  dataToShowScheduledTask: [],
  checkVerificationStatusDetails: [],
  workFlowId: '',
  automatedPresentations: [],
  totalAutomatedPresentations: 0
}
export function InstantTaskReducers(state = initialState, action: any) {

  switch (action.type) {
    case taskConst.GET_ALL_INSTANT_TASK:
      return Object.assign({}, state, {
        instantTaskList: action.tmpArray,
        totalInstantTaskList: action.payload.data.data.totalItems,
        hideVerifyButton: false,
        proofData: action.tmpArray[0]
      });
    case taskConst.SHOW_PRESENT_PROOF:
      return Object.assign({}, state, {
        showPresentProof: true,
        proofData: action.payload,
        hideVerifyButton: false
      });
    case taskConst.CLOSE_PRESENT_PROOF:
      return Object.assign({}, state, {
        showPresentProof: false,
        proofData: {},
      });
    case taskConst.PRESENT_PROOF_VERIFIED:
      return Object.assign({}, state, {
        verifyProofStatus: action.payload.data.data.state,
        proofData: {},
        showPresentProof: false,
        hideVerifyButton: true,
      });
    case taskConst.GET_LIST_BY_SELECTED_WORK_FLOW:
      return Object.assign({}, state, {
        showInstantTaskList: false,
        showAutomatedProofs: false,
        selectedWFTaskList: action.tmpArray,
        totalSelectedWFTaskList: action.payload.data.data.totalItems,
        showScheduleTaskList: true,
        checkVerificationStatusDetails: [],
        dataToShowScheduledTask: [],
        tHeadScheduledTaskShowDetails: [],
        workFlowId: action.workFlowId
      });
    case taskConst.SHOW_INSTANT_TASK_LIST:
      return Object.assign({}, state, {
        showInstantTaskList: true,
        showScheduleTaskList: false,
        showAutomatedProofs: false,
        selectedWFTaskList: [],
        dataToShowScheduledTask: {},
        tHeadScheduledTaskShowDetails: []
      });
    case taskConst.SHOW_DETAILS_OF_SCHEDULED_TASK:
      return Object.assign({}, state, {
        dataToShowScheduledTask: action.payload,
        tHeadScheduledTaskShowDetails: tHeadScheduledTaskShowDetails
      });
    case taskConst.CHECK_VERIFICATION_STATUS:
      return Object.assign({}, state, {
        checkVerificationStatusDetails: action.payload.data.data.data,
      });

    case taskConst.SHOW_NORMAL_TABLE:
      return Object.assign({}, state, {
        showInstantTaskList: true,
        showScheduleTaskList: false,
        showAutomatedProofs: false,
        selectedWFTaskList: [],
        checkVerificationStatusDetails: {},
        dataToShowScheduledTask: {},
      });

    case taskConst.CLEAR_FORM:
      return Object.assign({}, state, {
        showInstantTaskList: true,
        showAutomatedProofs: false,
        showScheduleTaskList: false,
        selectedWFTaskList: [],
        checkVerificationStatusDetails: {},
        dataToShowScheduledTask: {},
      })
    case taskConst.GET_ALL_AUTOMATED_PRESENTATIONS:
      return Object.assign({}, state, {
        showAutomatedProofs: true,
        showInstantTaskList:false,
        showScheduleTaskList: false,
        automatedPresentations: action.tmpArray,
        totalAutomatedPresentations: action.payload.data.data.totalItems,
        hideVerifyButton: false,
        proofData: action.tmpArray[0]
      });
      case taskConst.SHOW_AUTOMATED_PROOFS:
        return Object.assign({}, state, {
          showInstantTaskList: false,
          showScheduleTaskList: false,
          showAutomatedProofs: true,
          selectedWFTaskList: [],
          dataToShowScheduledTask: {},
          tHeadScheduledTaskShowDetails: [],
          // automatedPresentations: [],
        });
    default:
      return state
  }
}