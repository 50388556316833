import { t } from "i18next"
import images from "src/utils/images"

export const DNSEntries = ({ ownerName, dnsEntries, publicDnsEntries, handleDnsEntriesReload }: any) => {
    return (<>
        <div className="col-md-12 mb-30">
            <div className="card nb-card h-100">
                <div className="card-header border-bottom-0" style={{ backgroundColor: '#F0F5FC' }}>
                    <div className="row">
                        <div className="col-sm-11">
                            <div className='media mb-3'>
                                <img src="assets/icons/dns-icon-header.svg" className="align-self-center mr-3" alt='badge' />
                                <div className='media-body align-self-center'>
                                    <h5><b>{t('screens:profile.dnsEntries')}</b></h5>
                                    <small className="text-muted">{t('screens:profile.dnsEntriesDescription')}</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1">
                            <button
                                className="btn btn-default"
                                onClick={handleDnsEntriesReload}
                            >
                                <i className="fa fa-repeat" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">

                        <div className="col-sm-12 form-group">
                            <p>
                                <i className="fa fa-user"></i>&nbsp;
                                {t("screens:profile.labelOrgName")}
                            </p>
                            <p>{ownerName}</p>
                            
                        </div>
                        {
                            dnsEntries
                            && dnsEntries.length > 0
                            && dnsEntries.map((record: any) => {
                                const recordLabel = `${record.name.split('.')[0]} ${record.type}`
                                const indexOfPublic = publicDnsEntries.findIndex((x: any) => x.entry.split('.')[0] === record.name.split('.')[0])
                               
                                return <div className="col-sm-12 form-group">
                                    <p>
                                        <i className="nb-ico nb-dns-entries-icon"></i>&nbsp;
                                        <span className='nb-label'>{recordLabel}</span>
                                    </p>
                                    <p>
                                        <label className='nb-label'>{record.data}&nbsp;
                                            {
                                                publicDnsEntries[indexOfPublic]?.valid
                                                    ? <img src={images.certified} className='verified' alt='certified' width="30" />
                                                    : <img src={images.certifiedGray} className='verified' alt='not certified' width="30" />
                                            }
                                        </label>
                                    </p>
                                </div>

                            })
                        }
                        <div className="col-sm-12 form-group pt-5">

                            <div className="alert-light">
                                <div className="row">
                                    <div className="col-sm-1 text-right">
                                        <i className="fa fa-info-circle"></i>&nbsp;
                                    </div>
                                    <div className="col-sm-11">
                                        {t("screens:profile.dnsEntriesNote1")}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-12 form-group">

                            <div className="alert-light">
                                <div className="row">
                                    <div className="col-sm-1 text-right">
                                        <i className="fa fa-info-circle"></i>&nbsp;
                                    </div>
                                    <div className="col-sm-11">
                                        {t("screens:profile.dnsEntriesNote2")}
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
