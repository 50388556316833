import { revokeCredentialConstants } from "src/View/types"
import { revokeCredentialTableHeadings } from "../constants"

const initialState = {
    issuedCredentialRecords: {},
    hasPreviousPage: false,
    hasNextPage: false,
    lastPage: 0,
    nextPage: 0,
    totalCredentialRecords: 0,
    previousPage: 0,
    tHeadRevokeCredential: [],
    selectedCredentialRecord: {},
    selectedSchema: {}
}

export const RevokeCredentialReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case revokeCredentialConstants.GET_ALL_ISSUED_CREDENTIALS_RECORDS:
            return Object.assign({}, state, {
                issuedCredentialRecords: action.updatedPayload,
                hasPreviousPage: action.payload.data.data.hasPreviousPage,
                hasNextPage: action.payload.data.data.hasNextPage,
                lastPage: action.payload.data.data.lastPage,
                nextPage: action.payload.data.data.nextPage,
                totalCredentialRecords: action.payload.data.data.totalItems,
                previousPage: action.payload.data.data.previousPage,
                tHeadRevokeCredential: revokeCredentialTableHeadings,
                selectedReceivedCredData: action.updatedPayload[0]
            })

        case revokeCredentialConstants.SELECTED_ISSUED_CREDENTIAL_RECORD:
            return Object.assign({}, state, {
                selectedCredentialRecord: action.payload,
                selectedSchema: action.selectedSchema
            })

        case revokeCredentialConstants.SELECTED_RECEIVED_CREDENTIAL:
            return Object.assign({}, state,
                {
                    selectedReceivedCredData: action.payload.data.data.data,                    
                })

        default:
            return state;
    }
}
