import { importCredentials, loaderConst } from "../../types"
import Axios from "axios"
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { toastr } from "react-redux-toastr";

interface AddNewImportCredentialsRecord {
    connectionIds: string,
    credentialDefinitionId: string,
    attributeValues: string
}

export const importCredentialsAction = {
    /**
     * Method to call get all import credential records
     * @returns It return all import credential records list
     */
    getImportCredentialList(searchFields: string, item_perPage: number, pageNo: number) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await Axios.get(`${config.apiUrl}/claim-credentials/get-all-import-credential-records/by-organization?search_text=${searchFields}&itemsPerPage=${item_perPage}&page=${pageNo}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(getAllImportCredentialsRecords => {
                        getAllImportCredentialsRecords.data.data.data.map((element: any) => {
                            if (element.connectionIds && element.connectionIds.theirLabel) {
                                return (element['connectionName'] = element.connectionIds.theirLabel)
                            } else {
                                return (element['connectionName'] = 'NA')
                            }
                        })
                        dispatch({
                            type: importCredentials.GET_IMPORT_CREDENTIAL_LIST,
                            payload: getAllImportCredentialsRecords,
                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);

                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        throw error
                    })

            } catch (error) {
                throw error;
            }
        }
    },

    /**
     * Method to save new import credential record
     * @param addNewImportCredentialsRecord 
     * @returns It return the save record response
     */
    addNewImportCredentialsRecord(addNewImportCredentialsRecord: AddNewImportCredentialsRecord) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await Axios.post(`${config.apiUrl}/claim-credentials/add-import-credentials-record`, addNewImportCredentialsRecord,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(async importCredentialsResponse => {
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: importCredentials.ADD_NEW_IMPORT_CREDENTIALS_RECORD,
                            payload: importCredentialsResponse.data
                        })
                        this.getImportCredentialList('', 10, 1)
                        toastr.success(`${importCredentialsResponse.data.message}`, '');
                        // toastr.success(`${t("screens:importCredential.messageSuccess")}`, ``),

                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                console.error('Error in add new credential record:::', error)
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                throw error
            }
        }
    },

    /**
     * Method to dispatch the import another credential action
     * @returns 
     */
    importAnotherCredential() {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: importCredentials.IMPORT_ANOTHER_CREDENTIAL
                })

            } catch (error) {
                console.error('Error in import another credential:::', error)
                throw error
            }
        }
    },

    /**
     * Method to show selected import credential details
     * @param selectedRecord Parameter includes selected record details
     * @returns 
     */
    showSelectedImportCredentialRecord(selectedRecord: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: importCredentials.SHOW_SELECTED_IMPORT_CREDENTIAL_RECORD,
                    payload: selectedRecord
                })

            } catch (error) {
                console.error('Error in show import credential:::', error)
                throw error
            }
        }
    },

    /**
     * Method to remove selected import credential record
     * @returns 
     */
    removeSelectedImportCredentialRecord() {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: importCredentials.REMOVE_SELECTED_IMPORT_CREDENTIAL_RECORD,
                })
            } catch (error) {
                console.error('Error in remove import credential record:::', error)
                throw error
            }
        }
    }
}