import { t } from "i18next"
import _ from "lodash"

export const EditAttributeComponent = ({
    selectedCredentialRecord,
    selectedSchemaAttributes,
    attributeValues,
    handleChange,
    handleChangeImage,
    textErrorIndexes,
    imageErrorIndexes,
    highlightedFieldNames
}: any) => {
    return (
        <div className="mb-5">
            <div className="col-md-12">

                <ul className="nb-list">
                    <li className="pl-1 pr-0">
                        <div>
                            <label className="font-weight-bold">{t("screens:negotiateCredential.credentialName")}: </label>
                            <label className="font-weight-bold">{!_.isEmpty(selectedCredentialRecord)
                                && selectedCredentialRecord?.credentialDefinitionId?.split(':')[4]} </label>
                        </div>
                    </li>
                    <li className="pl-1 pr-0">
                        <div>
                            <label className="font-weight-bold">{t("screens:negotiateCredential.givenName")}: </label>
                            <label className="font-weight-bold">{!_.isEmpty(selectedCredentialRecord)
                                && selectedCredentialRecord.connectionName}</label>
                        </div>
                    </li>
                    <li className="pl-1 pr-0 ">
                        <>
                            <label className="font-weight-bold">{t("screens:negotiateCredential.credentialDataTitle")}: </label>
                            <ul>
                                {selectedCredentialRecord && attributeValues.map((item: any, index: any) => {
                                    // console.log('selectedSchemaAttributes[index]==', selectedSchemaAttributes[index].value)
                                    if (selectedSchemaAttributes[index].value !== 'image') {
                                        return <li key={index} className="remove-underline">
                                            <label className="font-weight-bold">{item.name}</label>
                                            <input
                                                type='text'
                                                className={`${highlightedFieldNames && highlightedFieldNames.includes(item.name) ? 'highlighted-input' : ''} form-control `}
                                                name={item.name}
                                                value={item.value}
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                            {
                                                textErrorIndexes.includes(index) ?
                                                    <small className="text-danger">
                                                        <b>{item.name}</b> {t("screens:issueCredentials.messageNotMatchFormat")} ({selectedSchemaAttributes[index].value}) {t("screens:issueCredentials.messageSpecifiedFormat")}
                                                    </small> : null
                                            }
                                        </li>
                                    } else {
                                        return (<>
                                            <li key={index} className="remove-underline">
                                                <label className="font-weight-bold">{item.name}:
                                                    {/* <img src={item.value} alt={item.name} className="preview" height="80"></img> */}
                                                </label>
                                                <input type="file" className="form-control a" accept="image/png,image/gif,image/jpeg"
                                                    placeholder={item.value}
                                                    onChange={(e) => handleChangeImage(index, e)}
                                                />
                                                {
                                                    imageErrorIndexes.includes(index)
                                                        ? <small className="text-danger">
                                                            {t("screens:issueCredentials.messageImageSize")}
                                                        </small>
                                                        : <img src={item.value}
                                                            alt={item.name}
                                                            className="preview mt-2"
                                                            height="80" />
                                                }
                                            </li>
                                        </>)
                                    }
                                })}
                            </ul>
                        </>
                    </li>
                </ul>
            </div>
        </div>
    )
}
