import * as React from 'react';
import { connect } from 'react-redux';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import CredentialValueComponent from '../../../Common Components/Components/CredentialValue';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { importCredentialsAction } from '../actions/ImportCredentialAction';
import { addNewImportCredentialRecord } from '../../commonConst';

export interface IShowImportCredentialsDetailsProps {
    ImportCredentialsReducer: any,
    importCredentialsAction: any
}

export interface IShowImportCredentialsDetailsState {

}

class ShowImportCredentialsDetails extends React.Component<IShowImportCredentialsDetailsProps, IShowImportCredentialsDetailsState> {
    constructor(props: IShowImportCredentialsDetailsProps) {
        super(props);

        this.state = {


        }
        this.handleCancel = this.handleCancel.bind(this)
    }

    handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
        this.props.importCredentialsAction.removeSelectedImportCredentialRecord()
    }


    public render() {
        const { connectionName, credentialDefinitionId, connectionIds, attributeValues } = this.props.ImportCredentialsReducer.selectedImportCredential
        return (
            <>
                <div className="card nb-card">
                    <div className="card-header bg-white border-bottom-0">
                        <h1 className="nb-title">{addNewImportCredentialRecord.IMPORT_CREDENTIAL_DETAILS}</h1>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CREDENTIAL_NAME}</label>
                                    <input type="text" className="form-control"
                                        name="connectionId"
                                        value={credentialDefinitionId}
                                        disabled
                                    />

                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CONNECTION_NAME}</label>

                                    <input type="text" className="form-control"
                                        name="connectionId"
                                        value={connectionName}
                                        disabled
                                    />

                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CONNECTION_ID}</label>
                                    <input type="text" className="form-control"
                                        name="connectionId"
                                        value={connectionIds.connectionId}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label className="nb-label">{addNewImportCredentialRecord.CREDENTIAL_VALUE}

                                    </label>
                                    <div className="card" style={{ minHeight: '258px' }}>
                                        <div className="card-body bg-light">
                                            {
                                                Object.keys(JSON.parse(attributeValues)).map((ele: any, index: any) => {
                                                    return (
                                                        <>
                                                            <li key={index}>{ele}: {JSON.parse(attributeValues)[ele]}</li>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                                    onClick={(e) => this.handleCancel(e)}
                                >
                                    {addNewImportCredentialRecord.CANCEL_BUTTON}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


function mapStateToProps(state: any) {
    return {
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        importCredentialsAction: bindActionCreators(importCredentialsAction, dispatch),

    }
}

const connectedShowImportCredentialsDetails = connect(mapStateToProps, mapDispatchToProps)(ShowImportCredentialsDetails);
export { connectedShowImportCredentialsDetails as ShowImportCredentialsDetails };
