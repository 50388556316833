import * as React from 'react';
import { t } from 'i18next';

export default class NoRecordFoundComponent extends React.Component {

  public render() {
    return (
      <div className="p-3 text-center" style={{ border: '1px dashed #ddd', color: '#ddd' }}>
        <div><i className="far fa-2x fa-folder-open"></i></div>
        <span>{t("screens:noRecordsFound.noRecordsAvailable")}</span>
      </div>
    );
  }
}