import * as React from 'react';
import { connect } from 'react-redux';
import './AddAdmin.css';
import _ from 'lodash';
import { history } from '../../../index'

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

let QRCode = require('qrcode.react');

/* Interface for Props variables*/
export interface IAddAdminProps {
  createAdmin: Function,
  AdminReducer: any,
  adminAction: any,
  type: string,
  field: string,
  qrData: string,
}
/* Interface for State variables*/
export interface IAddAdminState {
  formData: any,
  errors: any,
}

export default class AddAdmin extends React.Component<IAddAdminProps, IAddAdminState> {
  constructor(props: IAddAdminProps) {
    super(props);
    /* Initialization of state variables*/
    this.state = {
      formData: {},
      errors: {},
    }
    /* Bind the methods */
    this.handleChange = this.handleChange.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
    this.inviteNewAdmin = this.inviteNewAdmin.bind(this);
  }

  /**
   * Method to use get event from text field and set the values in state variable
   * @param event Parameter to get event from text field. 
   */
  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let formData = this.state.formData;
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData
    });
    if (this.validateForm()) {
      formData['connectionInvitation'] = this.props.qrData;
      formData['type'] = this.props.type;
    }
    if (event.target.name === 'email') {
      /* API call to check admin email. */
      this.props.adminAction.getAdminEmailId(event.target.value, 'email')
    }
  }

  /**
   * Method to send connection invitation
   */
  sendInvitation(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { formData } = this.state;
    this.props.createAdmin(formData)
  }

  /**
   * Method to validate the form field.
   * @returns It returns the form validation result
   */
  validateForm() {
    let formData: any = this.state.formData;
    let errors: any = {};
    let formIsValid: any = true;

    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = `${t("screens:onboardingNewAdmins.messageErrorName")}`;
    }
    if (typeof formData["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(formData["email"])) {
        formIsValid = false;
        errors["email"] = `${t("screens:onboardingNewAdmins.messageErrorEmail")}`;
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  /**
   * Method to invite new admin.
   */
  inviteNewAdmin() {
    this.setState({ formData: {} })
    this.props.adminAction.inviteNewAdmin();
    this.props.adminAction.getQRdata(this.props.type);
  }

  handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
    event.preventDefault();
    this.setState({
      formData: {},
      errors: {}
    })
    // console.log(`TEST CANCEL - ADMIN formData: ${JSON.stringify(this.state.formData)} errors: ${JSON.stringify(this.state.errors)}`)
    history.push("/create-connection")
  }

  public render() {
    const { formData, errors } = this.state;
    const { qrData } = this.props;
    const { selectAdmin, inviteNewAdmin, emailSent, showErrorMessage, showErrorMessageName, isErrorInConnection } = this.props.AdminReducer;
    // console.log(`TEST CANCEL - ADMIN showErrorMessage:  ${showErrorMessage}`)
    return (
      <div className="card nb-card h-100">
        <div className="card-header bg-white border-bottom-0">
          {/* TODO replace field <h1 className="nb-title">{addNewAdmin.HEADER_TEXT} {field}</h1> */}
          <h1 className="nb-title">{t("screens:onboardingNewAdmins.addNewAdmin")}</h1>
        </div>
        <div className="card-body">
          {!inviteNewAdmin ?
            <>
              <div className="mb-5">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:onboardingNewAdmins.givenName")}</label>
                    <input type="text" className="form-control" name="name" value={selectAdmin.name ? selectAdmin.name : formData.name}
                      onChange={(e) => this.handleChange(e)} readOnly={selectAdmin.name || emailSent ? true : false} />
                    {
                      errors.name ?
                        <div className="text-danger">
                          <small><i className="fas fa-info-circle mr-1"></i>{errors.name}</small>
                        </div> :
                        showErrorMessageName ?
                          <div className="text-danger">
                            <small><i className="fas fa-info-circle mr-1"></i> {t("screens:onboardingNewAdmins.alreadyExist")} </small>
                          </div>
                          : null
                    }
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:onboardingNewAdmins.email")}</label>
                    <input type="email" className="form-control" name="email" value={selectAdmin.email ? selectAdmin.email : formData.email}
                      onChange={(e) => this.handleChange(e)} readOnly={selectAdmin.email || emailSent ? true : false} />
                    {
                      errors.email ?
                        <div className="text-danger">
                          <small><i className="fas fa-info-circle mr-1"></i>{errors.email}</small>
                        </div> :
                        showErrorMessage ?
                          <div className="text-danger">
                            <small><i className="fas fa-info-circle mr-1"></i> {t("screens:onboardingNewAdmins.alreadyExist")} </small>
                          </div>
                          : null

                    }
                  </div>
                </div>
                {
                  // <div className="col-sm-12 mb-3">
                  //   <div className="card">
                  //     <div className="card-body text-center">
                  //       {emailSent && <div className="invitation-sent">
                  //         <label className="invitation-sent-button"><i className="fas fa-check-circle"></i> {t("screens:onboardingNewAdmins.sent")} </label>
                  //       </div>}
                  //       {!_.isEmpty(qrData) ?
                  //         <QRCode value={selectAdmin.connectionInvitation ? selectAdmin.connectionInvitation : qrData} />
                  //         :
                  //         <ContentLoader></ContentLoader>
                  //       }
                  //     </div>
                  //   </div>
                  // </div>
                }
              </div>
              <div className="row ml-2 mr-2">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                        onClick={(e) => this.handleCancel(e)}>
                        {t("screens:onboardingNewAdmins.cancel")}
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <button className="btn btn-block rounded-pill btn-nb-outline-primary"
                        disabled={showErrorMessage || !_.isEmpty(errors) || !formData.name || !formData.email || _.isEmpty(qrData) || isErrorInConnection ? true : false}
                        onClick={(e) => this.sendInvitation(e)}>
                        {t("screens:onboardingNewAdmins.sendInvitation")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <div className="col-sm-12">
              <div className="text-center">
                <img src={images.illustrationAddSuccess} className="mb-5" alt='Add success' />
              </div>
              <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                onClick={this.inviteNewAdmin}>
                {/* {addNewAdmin.INVITE_NEW_BUTTON_TEXT} {type} */}
                {t("screens:onboardingNewAdmins.inviteNew")}
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {}
}

function mapDispatchToProps(dispatch: any) {
  return {
  }
}

const connectedAddAdmin = connect(mapStateToProps, mapDispatchToProps)(AddAdmin);
export { connectedAddAdmin as AddAdmin };
