import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { loginAction } from '../actions/loginAction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { version } from '../../../config';
import { history } from '../../../index'
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { t } from 'i18next';
import { socket, SocketContext } from 'src/contexts/SocketContext';

const { detect } = require('detect-browser');
const browser = detect();
let QRCode = require('qrcode.react');

/* Interface for Props variables*/
export interface IScanQRProps extends WithTranslation<["common", "screens"]> {
  loginAction: any,
  user: any,
  LoginReducer: any,
  LoaderReducer: any,
}

/* Interface for local states variables*/
export interface IScanQRState {
  loginData: {
    username: string,
    password: string,
  },
  submitted: boolean,
  pushNotificationToken: any,
  // fireBaseWarn: boolean,
  // fireBaseWarnMessage: string,
  // fireBaseWarnColor: string,
  socketId: string,
  proofRequestUrl: string
}

class ScanQR extends React.Component<IScanQRProps, IScanQRState> {
  static contextType?= SocketContext

  constructor(props: IScanQRProps) {
    super(props);
    /* Initialization of state variables*/
    this.state = {
      loginData: {
        username: '',
        password: '',
      },
      pushNotificationToken: '',
      submitted: false,
      // fireBaseWarn: false,
      // fireBaseWarnMessage: '',
      // fireBaseWarnColor: '',
      socketId: '',
      proofRequestUrl: ''
    }
  }

  async componentDidMount() {
    await this.connectSocket()
    // localStorage.setItem('token', token);
    // history.push('/orgAdmin-dashboard')
  }

  async connectSocket() {
    // console.log(`ScanQR (before !socket.connected) ----  socket.id: ${socket.id}  socket.connected: ${socket.connected}`)
    if (!socket.connected) {
      socket.connect()
      // console.log(`ScanQR (inside !socket.connected) ---- SOCKET  NEW Connection estalished (io) ${socket.id} ${socket.connected}`)
    }

    if (!socket.id) {
      socket.on('connect', async () => {
        // console.log(`*** ScanQR  (inside 'on.connect') NEW socket.id ${socket.id} ${socket.connected} `)
        await this.loginSocket()
      })
    } else {
      await this.loginSocket()
    }

    // socket.on('notification', (socketId, payload) => {
    //   console.log(`ScanQR Listen from BE - notification - socketId: ${socketId}  ${JSON.stringify(payload)}`);
    //   if (payload && payload.data) {
    //     if (payload.data.type === 'Notification') {
    //       console.log(`SOCKET receive Notification`)
    //     }
    //   }
    // })

    // SOCKET - Unregister Events
    // console.log('Unregistering Events....')
    // socket.off('connect')
    // socket.off('passlesslogin')
    // socket.off('notification')
    // });
    // })
  }

  async loginSocket() {
    await this.props.loginAction.getPresentProofRequest(socket.id);
    this.setState({ pushNotificationToken: socket.id });
    let proofRequestUrl = this.props.LoginReducer.presentProofRequest;
    this.setState({ pushNotificationToken: socket.id, proofRequestUrl });
    // console.log(`*** SOCKET QR  - Connected (socket.on) ${socket.id}`);
    // console.log(`proofRequestUrl: ${proofRequestUrl}`)

    socket.on('passlesslogin', (socketId, payload) => {
      // console.log(`SOCKET Listen from BE - inside passlesslogin - socketId: ${socketId}`);
      // console.log(`SOCKET Listen from BE - passlesslogin - payload: ${JSON.stringify(payload)}`);

      if (payload && payload.data) {
        if (payload.data.type === "LOGIN") {
          const userRecord = JSON.parse(payload.data.record);
          const loginPayload = userRecord.userDetails;
          const token = userRecord.accessToken;
          /* Method call to set current user in redux-store. */
          this.props.loginAction.setCurrentUser(loginPayload);
          // console.log(`userRecord => ${JSON.stringify(userRecord)} token: ${token}`);
          // console.log(`loginPayload  => ${JSON.stringify(loginPayload)}`)

          /* Based on user role, agent spin-up status and on-barding status set the routes for next page. */
          if (loginPayload.role.id !== 1 && loginPayload.organization.agentSpinupStatus === 0 && loginPayload.organization.isOnBoarded === 1) {
            localStorage.setItem('token', token);
            history.push('/pending-state')
          } else if (loginPayload.role.id !== 1 && loginPayload.organization.agentSpinupStatus === 0 && loginPayload.organization.isOnBoarded === 0) {
            // toastr.message(`Complete Your Registration Process`, `Please go to first time login`)
            toastr.message(`${t("screens:login.toastMsgRegistration1")}`, `${t("screens:login.toastMsgRegistration2")}`)
          } else if (loginPayload.organization.organizationRunningStatus !== 'Active') {
            // toastr.error(`Login failed`, `${loginPayload.organization.orgName} access has been blocked. Kindly contact the Administrator`)
            toastr.error(`${t("screens:login.toastMsgLoginFailed1")}`, `${loginPayload.organization.orgName} ${t("screens:login.toastMsgLoginFailed2")}`)
          } else {
            if (loginPayload.role.id === 1) {
              localStorage.setItem('token', token);
              history.push('/platformAdmin-dashboard')
            } else if (loginPayload.role.id === 2) {
              if (loginPayload.organization.isOnBoarded === 2 && loginPayload.organization.agentSpinupStatus === 0 && loginPayload.organization.isDashboard === false) {
                localStorage.setItem('token', token);
                history.push('/create-wallet')
              } else if (loginPayload.organization.agentSpinupStatus === 2 && loginPayload.organization.isDashboard === false) {
                localStorage.setItem('token', token);
                history.push('/create-wallet')
              } else if (loginPayload.organization.agentSpinupStatus === 2 && loginPayload.organization.isDashboard === true) {
                localStorage.setItem('token', token);
                if (!_.isEmpty(loginPayload.organization.subscription) && loginPayload.organization.subscription.id === 1) {
                  history.push('/orgAdmin-dashboard')
                } else if (!_.isEmpty(loginPayload.organization.subscription) && loginPayload.organization.subscription.id === 2) {
                  history.push('/orgAdmin-dashboard')
                } else if (!_.isEmpty(loginPayload.organization.subscription) && loginPayload.organization.subscription.id === 3) {
                  console.log(`*** BEFORE (/orgAdmin-dashboard) socket ID  => ${socketId}`)
                  history.push('/orgAdmin-dashboard')
                }
              } else if (loginPayload.organization.agentSpinupStatus === 0 && loginPayload.organization.isDashboard === false && loginPayload.organization.isOnBoarded === 3) {
                localStorage.setItem('token', token);
                history.push('/request-failed')
              }
            } else if (loginPayload.role.id === 3 && loginPayload.isActive) {
              localStorage.setItem('token', token);
              history.push('/issuer-dashboard')
            } else if (loginPayload.role.id === 4 && loginPayload.isActive) {
              localStorage.setItem('token', token);
              history.push('/verifier-dashboard')
            } else if (loginPayload.role.id === 5 && loginPayload.isActive) {
              localStorage.setItem('token', token);
              history.push('/both-dashboard')
            } else {
              // toastr.info(`Currently, you are not activated for a login`, ``);
              toastr.info(`${t("screens:login.toastMsgNotActivated")}`, ``);
            }
          }
        }
      }
    })
  }

  public render() {
    const { t } = this.props;
    const { proofRequestUrl } = this.state;

    return (
      <>
        <div className="text-center">
          <h1 className="mb-5 nb-title-700">{t("screens:login.title", { companyName: t("common:app.companyNameFull") })}</h1>
          <div className="card shadow m-auto" style={{ width: '340px' }}>
            <div className="card-body">
              {!proofRequestUrl ?
                <>
                  <div className="content-loader justify-content-center h-100 pt-4">
                    <span className="mt-7 align-self-start">
                      <i className="fas fa-3x fa-circle-notch fa-spin"></i>
                    </span>
                    <p className='text-warning'>{t("screens:login.qrGeneratingMessage")}</p>
                  </div>
                </>
                :
                <QRCode value={proofRequestUrl} size={300} />
              }
            </div>
          </div>
          <div className="mt-3">
            <h4 className="text-primary">{t("screens:login.qrScanPrompt")}</h4>
          </div>
          {/* // split screen <div className="mt-3">
            <a className="nb-link" href='/login'>{t("screens:login.qrBackButton")}</a>
          </div> */}
          <div className="mt-3">
            <a className="nb-link" href='/first-time-user'>{t("screens:login.qrBackButton")}</a>
          </div>
          <div>
            <small className="text-muted">{t("common:app.version", { version: version.version })}</small>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const LoaderReducer = state.LoaderReducer;
  return { LoginReducer, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
  }
}

const connectedScanQR = withTranslation(['common', "screens"])(connect(mapStateToProps, mapDispatchToProps)(ScanQR));
export { connectedScanQR as ScanQR };
