export const config = {
     apiUrl: 'https://demoapi.nborbit.com',
    // apiUrl: 'https://devapi.nborbit.com',
    // apiUrl: 'http://192.168.0.37:5000',
    // apiUrl: 'https://qaapi.nborbit.com'
    // apiUrl:'https://sandboxapi.nborbit.com'
    /**
     * Help guide localization bundle URL.
     *
     * NOTE: Locale bundles must be present at base URL and named with the locale code (ie. 'en.json').
     */
    helpGuideUrl: 'https://demo.nborbit.com/dummy/help',
}

export const version = {
    version: '0.6.13'
    // version: 'DEV-2.4'
    // version: 'SANDBOX-1.9'
}

export const hashConfig = {
    trigger: '#',
    separator: ' ',
}
