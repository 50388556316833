import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { connectionAction } from '../actions/connectionAction';
import _ from 'lodash';
import { t } from 'i18next';
import OnboardingDetails from '../components/OnboardingDetails';
import PendingConnectionsList from '../components/PendingConnetionsList';


export interface IOnboardingProps {
  connectionAction: any,
  ConnectionReducer: any,
  issuanceAction: any,
  schemaAction: any,
}

export interface IOnboardingState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  connectionSortBy: string,
  filterValue: any,
  isDeleted: any,
  theirPublicDid: string,
  isEditPublicDID: boolean,
}

class OnboardingComponent extends React.Component<IOnboardingProps, IOnboardingState> {
  constructor(props: IOnboardingProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      filterValue: 'all',
      isDeleted: 'false',
      theirPublicDid: props.ConnectionReducer?.selectedConnection?.theirPublicDid,
      isEditPublicDID: false
    }

    this.handleUpdatePublicDid = this.handleUpdatePublicDid.bind(this)
    this.handlePublicDidChange = this.handlePublicDidChange.bind(this)
  }

  componentDidMount(): void {
    const { defaultSearchFields, item_perPage, sortingOrder, connectionSortBy, filterValue, isDeleted, pageNo } = this.state;
    this.props.connectionAction.getAllPendingConnections(defaultSearchFields, filterValue, sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
  }

  componentDidUpdate(prevProps: Readonly<IOnboardingProps>, prevState: Readonly<IOnboardingState>, snapshot?: any): void {
    if (prevProps.ConnectionReducer.selectedConnection.theirPublicDid !== this.props.ConnectionReducer.selectedConnection.theirPublicDid) {
      this.setState({ theirPublicDid: this.props.ConnectionReducer.selectedConnection.theirPublicDid ?? '' })
    }
  }

  componentWillUnmount() {
    this.props.connectionAction.defaultPage()
  }

  handlePublicDidChange(event: React.DOMAttributes<HTMLInputElement> | any) {
    let { theirPublicDid } = this.state
    theirPublicDid = event.target.value

    this.setState({ theirPublicDid })

  }

  handleUpdatePublicDid(event: any) {
    event.preventDefault()

    const { selectedConnection } = this.props.ConnectionReducer
    let { theirPublicDid, isEditPublicDID } = this.state

    if (!isEditPublicDID) {
      this.setState({ isEditPublicDID: true })
    }
    else {
      if (theirPublicDid !== '') {
        this.props.connectionAction.updateTheirPublicDid(selectedConnection.theirDid, theirPublicDid)
        this.setState({ isEditPublicDID: false })
      }
    }
  }

  public render() {
    let {
      editConnection,
      selectedConnectionForEdit
    } = this.props.ConnectionReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100 animated fadeIn">
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:existingConnections.pendingConnections")}</h1>
              </div>
              <div className="col-sm-12">
                <PendingConnectionsList editConnection={editConnection} selectedConnectionForEdit={selectedConnectionForEdit} {...this.props} />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mb-30">
            <div className="card nb-card h-100">
                <OnboardingDetails {...this.props} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const ConnectionReducer = state.ConnectionReducer;
  return { ConnectionReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    connectionAction: bindActionCreators(connectionAction, dispatch),
  }
}

const onboarding = connect(mapStateToProps, mapDispatchToProps)(OnboardingComponent);
export { onboarding as OnboardingComponent };
