import _ from "lodash";

export async function transformProofObject(proofRecord: any, retrievedCredentials: any): Promise<{ displayObject: any, missingAttributeArray: any }> {

    const proofRequest = proofRecord && JSON.parse(proofRecord.presentationRequest);
    console.log('---proofRequest---', proofRequest)
    let displayObject: any = {};
    let requestedAttributes: any = {};
    let selfAttestedAttributes: any = {};

    const requestedAttributesKeys = Object.keys(
        proofRequest.requested_attributes,
    );
    // console.log('requestedAttributesKeys', requestedAttributesKeys)

    const requestedPredicatesKeys = Object.keys(
        proofRequest.requested_predicates,
    );
    // console.log('requestedPredicatesKeys', requestedPredicatesKeys)

    const missingAttributeArray: any = [];
    const restrictionsArray: Array<any> = [];
    const predicateRestrictionsArray: Array<any> = [];
    const mapRestrictionsArray: Array<any> = [];
    const mapPredicateRestrictionsArray: Array<any> = [];
    const mapKeyAttributes: any = {};
    const mapSelfKeyAttributes: any = {};
    const mapPredicateKeyAttributes: any = {};

    for (const key of requestedAttributesKeys) {
        const restrictions = proofRequest.requested_attributes[key].restrictions;

        // console.log('restrictions', restrictions)

        if (restrictions && restrictions.length > 0) {
            if (!restrictionsArray.includes(JSON.stringify(restrictions))) {
                restrictionsArray.push(JSON.stringify(restrictions));
                mapRestrictionsArray.push({
                    [key]: restrictions,
                });
            } else {
                const mapObject =
                    mapRestrictionsArray[
                    restrictionsArray.indexOf(JSON.stringify(restrictions))
                    ];
                mapRestrictionsArray[
                    restrictionsArray.indexOf(JSON.stringify(restrictions))
                ] = {
                    [`${Object.keys(mapObject)[0]}|${key}`]: restrictions,
                };
            }
            mapKeyAttributes[key] = proofRequest.requested_attributes[key].name
                ? [proofRequest.requested_attributes[key].name]
                : proofRequest.requested_attributes[key].names;
        } else {
            const selfKey = proofRequest.requested_attributes[key].name
            // console.log('selfKey', selfKey)
            if (selfKey) {
                selfAttestedAttributes[selfKey] = ''
            }
            mapSelfKeyAttributes[key] = proofRequest.requested_attributes[key].name
                ? [proofRequest.requested_attributes[key].name]
                : proofRequest.requested_attributes[key].names;
        }
    }

    for (const key of requestedPredicatesKeys) {
        const restrictions = proofRequest.requested_predicates[key].restrictions;

        // console.log('restrictions', restrictions)

        if (restrictions && restrictions.length > 0) {
            if (!predicateRestrictionsArray.includes(JSON.stringify(restrictions))) {
                predicateRestrictionsArray.push(JSON.stringify(restrictions));
                mapPredicateRestrictionsArray.push({
                    [key]: restrictions,
                });
            } else {
                const mapObject =
                    mapPredicateRestrictionsArray[
                    restrictionsArray.indexOf(JSON.stringify(restrictions))
                    ];
                mapPredicateRestrictionsArray[
                    restrictionsArray.indexOf(JSON.stringify(restrictions))
                ] = {
                    [`${Object.keys(mapObject)[0]}|${key}`]: restrictions,
                };
            }
            mapPredicateKeyAttributes[key] = proofRequest.requested_predicates[key].name
                ? [proofRequest.requested_predicates[key].name]
                : proofRequest.requested_predicates[key].names;
        }
    }

    // console.log('restrictionsArray', restrictionsArray)
    // console.log('predicateRestrictionsArray', predicateRestrictionsArray)
    // console.log('mapRestrictionsArray', mapRestrictionsArray)
    // console.log('selfAttestedAttributes', selfAttestedAttributes)
    // console.log('mapPredicateRestrictionsArray', mapPredicateRestrictionsArray)
    // console.log('mapSelfKeyAttributes', mapSelfKeyAttributes)

    const allCredObject: any[] = [];
    const credObject: any[] = []
    for await (const restriction of mapRestrictionsArray) {
        const key = Object.keys(restriction)[0].split('|')[0];
        // console.log('restriction', restriction)
        // console.log('key', key)
        if (retrievedCredentials.length) {
            retrievedCredentials.map((credential: any, index: number) => {
                const cred = { ...credential }
                // console.log('cred', cred)
                if ((cred.presentation_referents).includes(key)) {

                    if (allCredObject.filter((x: any) => (x.presentation_referents).includes(key)).length === 0) {
                        cred.isSelected = true;
                        allCredObject.push(cred)
                        // console.log('cred', cred)
                        const obj = {
                            [`${Object.keys(restriction)[0]}`]: [cred]
                        }

                        credObject.push({ ...obj })
                        // console.log('credObject', credObject)
                    }
                    else {
                        cred.isSelected = false;
                        allCredObject.push(cred)

                        const credIndex = credObject.indexOf(credObject.find((x: any) => Object.keys(x)[0] === Object.keys(restriction)[0]))
                        // console.log('credIndex', credIndex)
                        if (credIndex !== -1) {
                            credObject[credIndex][`${Object.keys(restriction)[0]}`].push(cred)
                        }
                    }
                    const subKeys = Object.keys(restriction)[0].split('|');
                    // console.log('subKeys', subKeys)
                    let attrs: any = {};
                    if (!cred.credentialInfo) {
                        cred.credentialInfo = {}
                    }
                    // Object.assign(cred.credentialInfo, attrs);
                    cred.credentialInfo = { attrs }
                    subKeys.map((shortKey) => {
                        // console.log('shortKey', shortKey)
                        const element = mapKeyAttributes[shortKey];

                        // console.log('element', element)
                        element.map((name: string) => {
                            // console.log('name', name)
                            attrs[name] = cred.cred_info.attrs[name]
                        });

                    });
                    // console.log('attrs', attrs)
                    // cred.credentialInfo['attrs'] = attrs
                    // console.log('cred.credentialInfo', cred.credentialInfo)
                }

            })
        }
        else {
            // console.log('else')
            const restrictionValues: any = (Object.values(restriction)[0])
            const cred: any = {
                isSelected: true,
                cred_info: restrictionValues?.[0]
            }
            cred.cred_info.attrs = {}

            const obj = {
                [`${Object.keys(restriction)[0]}`]: [cred]
            }
            credObject.push({ ...obj })
            // console.log('credObject', credObject)
            const subKeys = Object.keys(restriction)[0].split('|');
            // console.log('subKeys', subKeys)
            let attrs: any = {};
            if (!cred.credentialInfo) {
                cred.credentialInfo = {}
            }
            // Object.assign(cred.credentialInfo, attrs);
            cred.credentialInfo = { attrs }
            subKeys.map((shortKey) => {
                // console.log('shortKey', shortKey)
                const element = mapKeyAttributes[shortKey];

                // console.log('element', element)
                element.map((name: string) => {
                    // console.log('name', name)
                    attrs[name] = ''
                });

            });
        }
        // requestedAttributes[Object.keys(restriction)[0]] = credObject;
        requestedAttributes = credObject;

    }

    for await (const restriction of mapRestrictionsArray) {

        const indexFound = requestedAttributes.findIndex((x: any) => Object.keys(x)[0] === Object.keys(restriction)[0])
        if (indexFound === -1) {
            missingAttributeArray.push(Object.keys(restriction)[0])
            const restrictionValues: any = (Object.values(restriction)[0])
            const cred: any = {
                isSelected: true,
                cred_info: restrictionValues?.[0]
            }
            cred.cred_info.attrs = {}

            const obj = {
                [`${Object.keys(restriction)[0]}`]: [cred]
            }
            credObject.push({ ...obj })
            // console.log('credObject', credObject)
            const subKeys = Object.keys(restriction)[0].split('|');
            // console.log('subKeys', subKeys)
            let attrs: any = {};
            if (!cred.credentialInfo) {
                cred.credentialInfo = {}
            }
            // Object.assign(cred.credentialInfo, attrs);
            cred.credentialInfo = { attrs }
            subKeys.map((shortKey) => {
                // console.log('shortKey', shortKey)
                const element = mapKeyAttributes[shortKey];

                // console.log('element', element)
                element.map((name: string) => {
                    // console.log('name', name)
                    attrs[name] = ''
                });

            });
            requestedAttributes = credObject
        }
    }


    const allSelfKeyObject: any[] = [];
    const selfKeyObject: any = []

    Object.keys(mapSelfKeyAttributes).map((key: any, idx: number) => {
        // console.log('skey', key)
        if (retrievedCredentials.length) {
            retrievedCredentials.map((credential: any, index: number) => {
                const cred = { ...credential }
                // console.log('cred', cred)
                if ((cred.presentation_referents).includes(key)) {
                    // console.log('---', cred)
                    if (allSelfKeyObject.filter((x: any) => (x.presentation_referents).includes(key)).length === 0) {
                        cred.isSelected = true
                        allSelfKeyObject.push(cred)

                        const obj = {
                            [`${key}`]: [cred]
                        }
                        selfKeyObject.push({ ...obj })
                    }
                    else {

                        cred.isSelected = false;
                        allSelfKeyObject.push(cred)

                        const credIndex = selfKeyObject.indexOf(selfKeyObject.find((x: any) => Object.keys(x)[0] === key))
                        // console.log('credIndex', credIndex)
                        if (credIndex !== -1) {
                            selfKeyObject[credIndex][`${key}`].push(cred)
                        }
                    }
                    //  const subKeys = Object.keys(restriction)[0].split('|');
                    // // console.log('subKeys', subKeys)
                    let attrs: any = {};
                    if (!cred.credentialInfo) {
                        cred.credentialInfo = {}
                    }
                    cred.credentialInfo = { attrs }
                    const element = mapSelfKeyAttributes[key];

                    // console.log('element', element)
                    element.map((name: string) => {
                        // console.log('name', name)
                        attrs[name] = cred.cred_info.attrs[Object.keys(cred.cred_info.attrs).find((key) =>
                            key.toLowerCase() === name.toLowerCase())!]
                    });
                }
            })
        }

        const attrs: any = {}
        const element = mapSelfKeyAttributes[key]
        element.map((name: string) => {
            attrs[name] = ''
        })
        const manualObject = {
            "cred_info": null,
            "presentation_referents": [
                key
            ],
            "isSelected": true,
            "credentialInfo": {
                attrs
            }
        }

        // console.log('selfKeyObject len', selfKeyObject.length)

        if (selfKeyObject.includes(key)) {
            manualObject.isSelected = false
            selfKeyObject[idx][`${key}`].push({ ...manualObject })
        }
        else {
            manualObject.isSelected = true
            const obj = {
                [`${key}`]: [manualObject]
            }
            selfKeyObject.push({ ...obj })
        }
        // console.log('selfKeyObject', selfKeyObject)

    })


    displayObject.self_attested_attributes = selfKeyObject;
    displayObject.requested_attributes = requestedAttributes;
    // console.log('requestedAttributes', requestedAttributes)

    let requestedPredicates: any = [];
    const allPresObject: any[] = [];
    const presObject: any = []
    for await (const restriction of mapPredicateRestrictionsArray) {
        // console.log('res', restriction)
        const key = Object.keys(restriction)[0].split('|')[0];

        if (retrievedCredentials.length) {
            retrievedCredentials.map((credential: any, index: number) => {
                const cred = { ...credential }
                if ((cred.presentation_referents).includes(key)) {
                    // console.log('==', allPresObject.filter((x: any) => (x.presentation_referents).includes(key)))
                    if (allPresObject.filter((x: any) => (x.presentation_referents).includes(key)).length === 0) {
                        cred.isSelected = true;
                        allPresObject.push(cred)
                        const obj = {
                            [`${Object.keys(restriction)[0]}`]: [cred]
                        }

                        presObject.push({ ...obj })
                        // console.log('presObject', presObject)

                    }
                    else {
                        cred.isSelected = false;
                        allPresObject.push(cred)

                        const credIndex = presObject.indexOf(presObject.find((x: any) => Object.keys(x)[0] === Object.keys(restriction)[0]))
                        // console.log('credIndex', credIndex)
                        if (credIndex !== -1) {
                            presObject[credIndex][`${Object.keys(restriction)[0]}`].push(cred)
                        }
                    }
                    // console.log('presObject', presObject)
                    // console.log('allPresObject', allPresObject)


                    const subKeys = Object.keys(restriction)[0].split('|');
                    // console.log('subKeys', subKeys)
                    let matchAttrs: any = {};
                    if (cred.hasOwnProperty('credentialInfo')) {
                        cred.credentialInfo.predAttrs = matchAttrs
                    }
                    else {
                        cred['credentialInfo'] = { predAttrs: matchAttrs }
                    }

                    subKeys.map((shortKey) => {
                        // console.log('shortKey', shortKey)
                        const element = mapPredicateKeyAttributes[shortKey];

                        // console.log('element', element)
                        element.map((name: string) => {
                            // console.log('name', name)
                            const conditionalKey =
                                name +
                                ' ' +
                                proofRequest.requested_predicates[shortKey].p_type +
                                ' ' +
                                proofRequest.requested_predicates[shortKey].p_value;

                            // console.log('conditionalKey', conditionalKey)
                            // console.log('cred.cred_info.attrs[name];', cred.cred_info.attrs)
                            matchAttrs[conditionalKey] = cred.cred_info.attrs[name];
                        });
                        // console.log('attrs', attrs)
                    });
                }
            })

        }
        else {
            const restrictionValues: any = (Object.values(restriction)[0])

            const cred: any = {
                isSelected: true,
                cred_info: restrictionValues?.[0]
            }
            cred.cred_info.attrs = {}

            const obj = {
                [`${Object.keys(restriction)[0]}`]: [cred]
            }
            presObject.push({ ...obj })
            // console.log('credObject', credObject)
            const subKeys = Object.keys(restriction)[0].split('|');
            // console.log('subKeys', subKeys)

            let matchAttrs: any = {};
            // console.log('cred.credentialInfo', cred.credentialInfo)
            cred.credentialInfo = { predAttrs: matchAttrs }

            subKeys.map((shortKey) => {
                // console.log('shortKey', shortKey)
                const element = mapPredicateKeyAttributes[shortKey];

                // console.log('element', element)
                element.map((name: string) => {

                    // console.log('name', name)
                    const conditionalKey =
                        name +
                        ' ' +
                        proofRequest.requested_predicates[shortKey].p_type +
                        ' ' +
                        proofRequest.requested_predicates[shortKey].p_value;

                    // console.log('conditionalKey', conditionalKey)
                    // console.log('cred.cred_info.attrs[name];', cred.cred_info.attrs)
                    matchAttrs[conditionalKey] = ''

                });
            })
        }
        requestedPredicates = presObject
    }

    for await (const restriction of mapPredicateRestrictionsArray) {
        const indexFound = requestedPredicates.findIndex((x: any) => Object.keys(x)[0] === Object.keys(restriction)[0])
        if (indexFound === -1) {
            missingAttributeArray.push(Object.keys(restriction)[0])
            const restrictionValues: any = (Object.values(restriction)[0])

            const cred: any = {
                isSelected: true,
                cred_info: restrictionValues?.[0]
            }
            cred.cred_info.attrs = {}

            const obj = {
                [`${Object.keys(restriction)[0]}`]: [cred]
            }
            presObject.push({ ...obj })
            // console.log('credObject', credObject)
            const subKeys = Object.keys(restriction)[0].split('|');
            // console.log('subKeys', subKeys)

            let matchAttrs: any = {};
            // console.log('cred.credentialInfo', cred.credentialInfo)
            cred.credentialInfo = { predAttrs: matchAttrs }

            subKeys.map((shortKey) => {
                // console.log('shortKey', shortKey)
                const element = mapPredicateKeyAttributes[shortKey];

                // console.log('element', element)
                element.map((name: string) => {

                    // console.log('name', name)
                    const conditionalKey =
                        name +
                        ' ' +
                        proofRequest.requested_predicates[shortKey].p_type +
                        ' ' +
                        proofRequest.requested_predicates[shortKey].p_value;

                    // console.log('conditionalKey', conditionalKey)
                    // console.log('cred.cred_info.attrs[name];', cred.cred_info.attrs)
                    matchAttrs[conditionalKey] = ''

                });
            })
        }
    }


    displayObject.requested_predicates = requestedPredicates;

    console.log('displayObject', displayObject)
    console.log('missingAttributeArray', missingAttributeArray)

    return { displayObject, missingAttributeArray }
}

const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
        return result;
    }, {}); // empty object is the initial value for result object
};

export async function transformPresentation(data: any) {
    let presentationData = !_.isEmpty(data) && data.presentation && JSON.parse(data.presentation);

    if (presentationData) {
        const requestedAttributes = JSON.parse(data.presentationRequest).requested_attributes
        const requestedPredicates = JSON.parse(data.presentationRequest).requested_predicates

        let presentationReceived: any = []
        const credentialNamesList = presentationData.identifiers.map((identifier: any) => {
            return {
                credentialName: identifier.cred_def_id.split(':')[4],
                credentialDefinitionId: identifier.cred_def_id,
                schemaLedgerId: identifier.schema_id
            }
        })

        const revealedAttributes = presentationData.requested_proof.hasOwnProperty('revealed_attr_groups') ?
            presentationData.requested_proof.revealed_attr_groups :
            presentationData.requested_proof.revealed_attrs

        const isUnRevealedAttributes = presentationData.requested_proof.hasOwnProperty('unrevealed_attrs')

        const predicates = Object.keys(presentationData.requested_proof.predicates).length > 0
        const selfAttestedAttributes = Object.keys(presentationData.requested_proof.self_attested_attrs).length > 0

        if (isUnRevealedAttributes) {
            const unrevealedAttrsInfo = presentationData.requested_proof.unrevealed_attrs
            Object.keys(unrevealedAttrsInfo).map((unAttrObjKey: any) => {
                const subProofIndex = unrevealedAttrsInfo[unAttrObjKey].sub_proof_index

                Object.keys(presentationData.proof['proofs'][subProofIndex].primary_proof.eq_proof.m).map((attrName: any) => {

                    if (attrName !== 'master_secret' && unAttrObjKey.split('_')[1] === attrName) {

                        Object.keys(requestedAttributes).map((attribute: any) => {
                            if (attribute === unAttrObjKey) {
                                presentationReceived.push({
                                    credentialName: credentialNamesList[subProofIndex].credentialName,
                                    reveal: false,
                                    [requestedAttributes[attribute].name]: presentationData.proof['proofs'][subProofIndex].primary_proof.eq_proof.m[attrName]
                                })
                            }
                        })

                    }

                })

            })
        }

        if (predicates) {
            const predicatesInfo = presentationData.requested_proof.predicates
            Object.keys(predicatesInfo).map((predicateObjKey: any) => {
                const subProofIndex = predicatesInfo[predicateObjKey].sub_proof_index

                for (const key in presentationData.proof['proofs'][subProofIndex]) {
                    if (Object.prototype.hasOwnProperty.call(presentationData.proof['proofs'][subProofIndex], key)) {
                        const proofElements = presentationData.proof['proofs'][subProofIndex][key];

                        for (const proofElement in proofElements) {
                            if (Object.prototype.hasOwnProperty.call(proofElements, proofElement)) {
                                const element = proofElements[proofElement];

                                if (proofElement === 'eq_proof' && !element.hasOwnProperty('revealed_attrs')) {
                                    presentationReceived.push({ attributes: proofElements.m })
                                }

                                if (proofElement === 'ge_proofs') {
                                    element.map((predicates: any) => {
                                        Object.keys(requestedPredicates).map((predicate: any) => {
                                            const firstUnderscoreIndex = predicate.indexOf("_");
                                            const secondLastUnderscoreIndex = predicate.lastIndexOf("_", predicate.lastIndexOf("_") - 1);
                                            let extractedSubstring;
                                            if (firstUnderscoreIndex !== -1 && secondLastUnderscoreIndex !== -1) {
                                                extractedSubstring = predicate.substring(firstUnderscoreIndex + 1, secondLastUnderscoreIndex);
                                            }
                                            if (predicates.predicate.attr_name === extractedSubstring) {
                                                presentationReceived.push({
                                                    predicates: {
                                                        credentialName: credentialNamesList[subProofIndex].credentialName,
                                                        attr_name: requestedPredicates[predicate].name,
                                                        p_type: predicates.predicate.p_type,
                                                        value: predicates.predicate.value
                                                    }
                                                })
                                            }
                                        })

                                    })
                                }

                            }
                        }

                    }
                }

            })

        }

        if (selfAttestedAttributes) {
            for (const key in presentationData.requested_proof.self_attested_attrs) {
                const value = presentationData.requested_proof.self_attested_attrs[key];

                if (requestedAttributes[key]) {
                    presentationReceived.push({
                        self_attested_attrs: {
                            [requestedAttributes[key].name]: value
                        }
                    })
                }
            }
        }

        for (let key in revealedAttributes) {
            const value = revealedAttributes[key];
            const subProofIndex = revealedAttributes[key].sub_proof_index

            Object.keys(requestedAttributes).map((attribute: any) => {
                if (attribute === key) {

                    presentationReceived.push({
                        credentialName: credentialNamesList[subProofIndex].credentialName,
                        reveal: true,
                        [requestedAttributes[attribute].name]: value.raw
                    })
                }
            })

        }

        // Group by color as key to the person array
        return groupBy(presentationReceived, 'credentialName');

    }
}