import React from "react";
import TableComponents from "../../../Common Components/Components/TableComponent";
import { filterExistingChatConnections, itemPerPageForChatsMessages, tHeadChatsConnectionList } from "../chatsConstants";

interface IChatsConnectionListProps {
    selectedChatConnection: any,
    onChangePage: any,
    chatsConnectionList: any,
    totalConnections: any
    showDetails: any,
    onSearch: any,
    chatsAction: any,
    refresh: any,
    onFilter: any,
    isNewMessageReceived: boolean
}
interface IChatsConnectionListState {
    itemPerPageForMessages: number
}
export default class ChatsConnectionList extends React.Component<IChatsConnectionListProps, IChatsConnectionListState> {
    constructor(props: IChatsConnectionListProps) {
        super(props)
        this.state = {
            itemPerPageForMessages: itemPerPageForChatsMessages
        }
        // Bind all the methods.
        this.onChangePage = this.onChangePage.bind(this)
        this.showDetails = this.showDetails.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.refresh = this.refresh.bind(this)
        this.onFilter = this.onFilter.bind(this)
    }

    /**
     * Method used to change the page no value in pagination
     * @param page 
     */
    onChangePage(page: any) {
        this.props.onChangePage(page)
    }

    /**
     * Method used to shoe the specific connection messages.
     * @param item 
     */
    showDetails(item: object | any) {
        const { chatsConnectionList } = this.props
        const { itemPerPageForMessages } = this.state
        this.props.showDetails(item)
        this.props.chatsAction.updateUnreadMessageStatus(item.connectionId, chatsConnectionList)
        this.props.chatsAction.getMessagesByConnectionId((item?.organization?.id), item.connectionId, itemPerPageForMessages)
    }
    /**
     * Method used to get selected filter value from dropdown
     * @param selectedFilterValue 
     */
    onFilter(selectedFilterValue: any) {
        this.props.onFilter(selectedFilterValue)
    }

    /**
     * Method used to search record from database based on entered text.
     * @param event 
     */
    onSearch(event: React.KeyboardEvent<HTMLInputElement> | any) {
        if (event.key === 'Enter') {
            this.props.onSearch(event.target.value)
        }
    }

    /**
     * Method used to refresh the connection list
     */
    refresh() {
        this.props.refresh()
    }

    public render() {
        const { selectedChatConnection, chatsConnectionList, totalConnections, isNewMessageReceived } = this.props

        return (
            <>
                <TableComponents
                    selectedRecord={selectedChatConnection}
                    onChangePage={this.onChangePage}
                    tHead={tHeadChatsConnectionList}
                    tableContent={chatsConnectionList}
                    totalRecords={totalConnections}
                    showElementDetails={this.showDetails}
                    filter={filterExistingChatConnections}
                    onChangeFilter={this.onFilter}
                    searching={this.onSearch}
                    refresh={this.refresh}
                    isNewMessageReceived={isNewMessageReceived}
                />
            </>
        )

    }
}