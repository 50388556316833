import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { getDifference } from "src/utils/array";
import { offerNegotiateInitiator } from "src/View/types";
import { EditAttributeComponent } from "./EditAttributesComponent";
import { SchemaDateSuffix } from "src/View/Schema/models/schema.enum";
import { displayDateFormat } from "src/utils/date.utils";


export const CounterProposal = ({ selectedCredentialRecord, NegotiateCredentialAction, handleCancel, selectedSchema }: any) => {

    const { credentialProposalDict, credentialDefinitionId, credentialExchangeId } = selectedCredentialRecord

    const attributes = !_.isEmpty(selectedCredentialRecord) && JSON.parse(credentialProposalDict).credential_proposal.attributes;
    const selectedSchemaDefinition: any[] = selectedSchema && selectedSchema.attributes
    const selectedSchemaAttributes: any[] = []
    for (const label of selectedSchemaDefinition) {
        selectedSchemaAttributes.push(JSON.parse(label))
    }

    const [updatedAttributes, setUpdatedAttributes] = useState<any[]>([]);
    const [highlightedFieldNames, setHighlightedFieldNames] = useState<string[]>([]);
    const [textErrorIndexes, setTextErrorIndexes] = useState<number[]>([]);
    const [imageErrorIndexes, setImageErrorIndexes] = useState<number[]>([])

    useEffect(() => {
        if (attributes) {
            attributes.map((item: any) => {
                
                if((item.name).includes(SchemaDateSuffix.DATE_SUFFIX)){
                    item.value = displayDateFormat(item.value) 
                }
            })
            setUpdatedAttributes(attributes);
        }
    }, []);

    const handleChange = (index: number, event: any) => {
        let formatError = false
        const { name, value } = event.target
        const updateAttributes = updatedAttributes
        updateAttributes[index].value = value;

        const dataType = selectedSchemaAttributes[index].value

        if (dataType === 'DD/MM/YYYY' && (!/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(updateAttributes[index].value))) {
            formatError = true
        }

        if (dataType === 'number' && (!/^[0-9]*$/.test(updateAttributes[index].value))) {
            formatError = true
        }

        if (updateAttributes[index].value === '') {
            formatError = false
        }

        if (formatError && !textErrorIndexes.includes(index)) {
            setTextErrorIndexes([...textErrorIndexes, index]);
        }

        if (!formatError) {
            const indexFound = textErrorIndexes.indexOf(index);
            if (indexFound > -1) {
                setTextErrorIndexes(currentIndexes => currentIndexes.filter((_, i) => i !== indexFound));
            }
        }

        setUpdatedAttributes([...updateAttributes])

        const changedHighlightedFieldNames = highlightedFieldNames.includes(name)
            ? highlightedFieldNames
            : [...highlightedFieldNames, name]

        setHighlightedFieldNames([...changedHighlightedFieldNames])
    }

    const handleChangeImage = (index: number, event: any) => {
        const updateAttributes = updatedAttributes

        const iFile = event.target.files

        const indexFound = imageErrorIndexes.indexOf(index);

        if (iFile && iFile.length > 0) {
            let reader = new FileReader();
            const fSize = iFile[0].size
            const file = Math.round((fSize / 1024));

            // The size of the file.
            if (file >= 100) {
                if (indexFound === -1) {
                    setImageErrorIndexes([...imageErrorIndexes, index])
                }
                updateAttributes[index].value = '';
            }
            else {
                updateAttributes[index].value = event.target.value;
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = () => {
                    updateAttributes[index].value = reader.result
                    setUpdatedAttributes([...updateAttributes])
                };
                if (indexFound > -1) {
                    setImageErrorIndexes(currentIndexes => currentIndexes.filter((_, i) => i !== indexFound));
                }
            }
        }
    }

    const handleCounterProposal = async (credentialExchangeId: string) => {

        let credentialProposalDict = !_.isEmpty(selectedCredentialRecord) && selectedCredentialRecord.credentialProposalDict;
        let attributes = JSON.parse(credentialProposalDict).credential_proposal.attributes;
        const arrayDifference = getDifference(updatedAttributes, attributes)
        
        let credentialOfferOptions: any = { credentialExchangeId }
        if (arrayDifference.length > 0) {

            let schemaAttribute: any = [];
            selectedSchemaAttributes.forEach((ele: any, idx: number) => {
                let jsonObj = {
                    "name": ele.name,
                    "value": updatedAttributes[idx].value === undefined || updatedAttributes[idx].value === null || updatedAttributes[idx].value === "" 
                        ? " " 
                        : (ele.name).includes('_dateint') 
                        ? (updatedAttributes[idx].value).split("/").reverse().join("") 
                        : updatedAttributes[idx].value 
                }
                if (ele.value === 'image') {
                    Object.assign(jsonObj, { "mime-type": "image/jpeg" })
                }
                schemaAttribute.push(jsonObj);

            });

            credentialOfferOptions.negotiateOffer = {
                counterProposal: {
                    attributes: schemaAttribute,
                    credDefId: credentialDefinitionId
                }
            }

        }

        NegotiateCredentialAction.sendCredentialOfferOrNegotiate(credentialOfferOptions, offerNegotiateInitiator.negotiate);
        handleCancel()
    }

    return (
        <>
            <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:counterProposal.credentialDetails")}</h1>
            </div>
            {!_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">

                        <EditAttributeComponent
                            selectedCredentialRecord={selectedCredentialRecord}
                            selectedSchemaAttributes={selectedSchemaAttributes}
                            attributeValues={updatedAttributes}
                            handleChange={handleChange}
                            handleChangeImage={handleChangeImage}
                            textErrorIndexes={textErrorIndexes}
                            imageErrorIndexes={imageErrorIndexes}
                            highlightedFieldNames={highlightedFieldNames}
                        />
                        <>
                            {!_.isEmpty(selectedCredentialRecord) &&
                                <div className="row ml-2 mr-2">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-6">

                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                    onClick={() => handleCounterProposal(credentialExchangeId)}
                                                    disabled={textErrorIndexes.length > 0 || imageErrorIndexes.length > 0}
                                                >
                                                    {t("screens:counterProposal.offer")}
                                                </button>
                                            </div>
                                            <div className="col-md-6">

                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                    onClick={() => handleCancel()}
                                                >
                                                    {t("screens:counterProposal.cancel")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    </div>

                </>
            }
            {_.isEmpty(selectedCredentialRecord) &&
                <>
                    <div className="card-body">
                        <div className="blank-credential-details-body">
                            <label className="">{t("screens:counterProposal.noProposalSelectedOrReceived")}</label>
                        </div>
                    </div>
                </>
            }
        </>
    )

}