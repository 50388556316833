export const tHeadLoginMonitoringList = [{
  'name': 'GIVEN NAME',
  'label': "connectionName"
}, {
  'name': 'ROLE',
  'label': "role"
}, {
  'name': 'DATE',
  'label': "createDateTime"
}, {
  'name': 'ACTIVE',
  'label': "activeState"
}, {
  'name': 'STATUS',
  'label': "verifiedStatus"
}];

export const filterLoginMonitoringList = [{
  'name': 'All',
  'tag': 'all'
}, {
  'name': 'Verified',
  'tag': 'verified'
}, {
  'name': 'Not Verified',
  'tag': 'failed'
}
];