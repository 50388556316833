import * as React from 'react';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { filterGovernanceList, tHeadforGovRuleList } from '../constants';

export interface IGovRuleListProps {
  GovRuleAction: any,
  GovRuleReducer: any,
}

export interface IGovRuleListState {
  defaultSearchFields: any,
  item_perPage: any,
  pageNo: any,
  sortValue: any,
  governanceSortBy: any,
}

export default class GovRuleList extends React.Component<IGovRuleListProps, IGovRuleListState> {
  constructor(props: IGovRuleListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortValue: 'DESC',
      governanceSortBy: '',
    }
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.filter = this.filter.bind(this);
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortValue, governanceSortBy } = this.state;
    this.props.GovRuleAction.updatePageNo(page);
    this.props.GovRuleAction.getAllRuleList(defaultSearchFields, sortValue, governanceSortBy, item_perPage, page,);
  }

  showDetails(item: object | any) {
    this.props.GovRuleAction.selectGovRule(item);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortValue, governanceSortBy, pageNo } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.GovRuleAction.getAllRuleList(event.target.value, sortValue, governanceSortBy, item_perPage, pageNo,);
    }
  }

  sorting = (governanceSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo } = this.state;
    this.state.sortValue === 'DESC' ? this.setState({ sortValue: 'ASC', governanceSortBy: governanceSortBy }) : this.setState({ sortValue: 'DESC', governanceSortBy: governanceSortBy });
    let sortValue = this.state.sortValue === 'DESC' ? "ASC" : "DESC";
    this.props.GovRuleAction.getAllRuleList(defaultSearchFields, sortValue, governanceSortBy, item_perPage, pageNo,);
  }


  refresh = (event: any) => {
    const { defaultSearchFields, item_perPage, pageNo, sortValue, governanceSortBy } = this.state;
    this.props.GovRuleAction.getAllRuleList(defaultSearchFields, sortValue, governanceSortBy, item_perPage, pageNo,);


  }

  filter = (selectedfilterValue: any) => {
    const { defaultSearchFields, item_perPage, pageNo, sortValue, governanceSortBy } = this.state;
    switch (selectedfilterValue) {
      case 'id':
        this.props.GovRuleAction.getAllRuleList(defaultSearchFields, sortValue, selectedfilterValue, item_perPage, pageNo,);
        break;
      case 'ruleType':
        this.props.GovRuleAction.getAllRuleList(defaultSearchFields, sortValue, selectedfilterValue, item_perPage, pageNo,);
        break;
      case 'createDateTime':
        this.props.GovRuleAction.getAllRuleList(defaultSearchFields, sortValue, selectedfilterValue, item_perPage, pageNo,);
        break;

      default:
        this.props.GovRuleAction.getAllRuleList(defaultSearchFields, sortValue, governanceSortBy, item_perPage, pageNo,);
        break;
    }
  }

  public render() {
    const {
      ruleList,
      totalrules,
      selectedRule,
    } = this.props.GovRuleReducer;

    return (
      <>
        <TableComponents
          onChangePage={this.onChangePage}
          showElementDetails={this.showDetails}
          tHead={tHeadforGovRuleList}
          tableContent={ruleList.length > 0 && ruleList}
          totalRecords={totalrules}
          selectedRecord={selectedRule}
          sorting={this.sorting}
          searching={this.onSearch}
          refresh={this.refresh}
          filter={filterGovernanceList}
          onChangeFilter={this.filter}
        />
      </>
    );
  }
}
