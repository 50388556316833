import axios from "axios";
import { loaderConst, organizationConst, registerConst } from "../../types";
import { config } from '../../../config';
import { loginAction } from "../../Login/actions/loginAction";
import { toastr } from "react-redux-toastr";

export const RegistrationAction = {

  registration(formData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        return await axios.post(`${config.apiUrl}/registry/register-organization`, formData,
          { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(Response => {
            dispatch({
              type: organizationConst.SHOW_SUBMIT_PAGE,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            localStorage.removeItem('orgId')
            toastr.success('Registration Request submitted successfully', '')
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getRegistrationDetails(orgId: any) {
    return async (dispatch: any) => {
      try {
        return await axios.get(`${config.apiUrl}/organization/organization-details/${orgId}`,
          { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(data => {
            dispatch({
              type: organizationConst.GET_REGISTRY_DETAILS,
              payload: data
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  UpdateOrganization(formData: any, requestId: any, acceptOrganization?: boolean) {
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');
        return await axios.put(`${config.apiUrl}/admin/accept-onboard-request/${requestId}`, formData,
          { headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token}` } })
          .then(Response => {
            if (acceptOrganization) {
              return (
                dispatch({
                  type: organizationConst.SELECTED_EXISTINGORGANIZATION,
                  payload: Response.data.data
                })
              )
            } else {
              return (
                dispatch({
                  type: organizationConst.EDIT_EXISTING_ORGANIZATION,
                  payload: Response.data.data
                })
              )

            }

          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  clearForm() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: registerConst.CLEAR_FORM,
        })
      } catch (error) {
        throw error;
      }
    }
  },

  /**
   * Method to get all countries
   * @returns It returns all countries 
   */
  getAllCountries() {
    return async (dispatch: any) => {
      try {
        return await axios.get(`${config.apiUrl}/organization/countries`)
          .then(countries => {
            dispatch({
              type: organizationConst.GET_ALL_COUNTRIES,
              payload: countries.data
            })
          })

      } catch (error) {
        throw error
      }
    }
  },

  /**
   * Method used to get all states by country 
   * @param countryId Parameter used to get country id
   * @returns It returns the list of states by country
   */
  getAllStatesByCountry(countryId: number) {
    return async (dispatch: any) => {
      try {
        return await axios.get(`${config.apiUrl}/organization/states/${countryId}`)
          .then(states => {
            states.data.data.map((element: any) => {
              return (element['name'] = element.state)
            })
            dispatch({
              type: organizationConst.GET_ALL_STATES,
              payload: states.data
            })
          })

      } catch (error) {
        console.log("Error in get all countries:::", error)
        throw error
      }
    }
  }

}

