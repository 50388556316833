import axios from "axios";
import _ from "lodash";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, adminConnectionConst } from "../../types";

export const adminAction = {
  getQRdata(type: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.CREDENTIAL_LIST_LOADER_TRUE
        })
        let token = localStorage.getItem('token');

        return await axios.post(`${config.apiUrl}/connection/${type}/create`, "",
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            if (data.data.data) {
              var res = data.data.data;
              return (
                dispatch({
                  type: adminConnectionConst.GET_ADMIN_QR,
                  payload: res.shorteningUrl
                })
              )
            }
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  createAdmin(
    type: string,
    formData: any
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.CREDENTIAL_LIST_LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return axios.post(`${config.apiUrl}/admin`, formData,
        { headers: { "Authorization": `Bearer ${token}` } })
        .then(data => {
          dispatch({
            type: adminConnectionConst.EMAIL_SENT
          },
            this.getAdminsByOrgId(type, "", 10, 1, type, '', 'DESC'))
          setTimeout(
            () => {
              dispatch({
                type: adminConnectionConst.SHOW_NEW_INVITE
              })
            },
            3000
          );
        })
        .catch(error => {
          loginAction.handleResponse(error.response, dispatch);
          dispatch({
            type: loaderConst.CREDENTIAL_LIST_LOADER_FALSE
          })
        })
        } catch (error) {
          throw error;
      }
    }
  },

  getAdminsByOrgId(
    type: any,
    defaultSearchFields: any,
    item_perPage: number,
    pageNo: number,
    page: string,
    sortBy: string,
    sortValue: string,
  ) {
    return async (dispatch: any) => {
      try {
        // dispatch({
        //   type: loaderConst.CREDENTIAL_LIST_LOADER_TRUE
        // })
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');

        return await axios.get(`${config.apiUrl}/admin/user/connection?search_text=${defaultSearchFields}&type=${type}&sortValue=${sortValue}&sortBy=${sortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            let tmpArray: any = [];
            tmpArray = !_.isEmpty(response) && response.data && response.data.data && response.data.data.data;
            tmpArray.map((ele: any, index: any) => {
              tmpArray[index].adminName = ele.username;
              tmpArray[index].connectionStatus = ele.state === 'invitation' ? 'Sent' : (ele.state === 'active' ? 'Connected' : '');

            })
            dispatch({
              type: adminConnectionConst.GET_ADMIN_LIST,
              payload: response,
              tmpArray,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectAdmin(data: any) {
    return async (dispatch: any) => {
      dispatch({
        type: adminConnectionConst.SELECT_ADMIN,
        payload: data
      })
    }
  },

  clearSelection() {
    return async (dispatch: any) => {
      dispatch({
        type: adminConnectionConst.CLEAR_SELECTION,
      })
    }
  },

  inviteNewAdmin() {
    return async (dispatch: any) => {
      dispatch({
        type: adminConnectionConst.INVITE_NEW_ADMIN,
      })
    }
  },

  getAdminEmailId(
    defaultSearchFields: any,
    type: string,
  ) {
    return async (dispatch: any) => {
      try {
        let token = localStorage.getItem('token');

        return await axios.get(`${config.apiUrl}/admin/check-user?search=${defaultSearchFields}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(data => {
            if (!_.isEmpty(data) && data.data && data.data.data && data.data.data && !_.isEmpty(data.data.data)) {
              if (type === 'email') {
                dispatch({
                  type: adminConnectionConst.CHECK_EXISTING_ADMIN_EMAILID,
                  payload: type,
                })
              }
            } else {
              dispatch({
                type: adminConnectionConst.HIDE_CHECK_EXISTING_ADMIN_EMAILID
              })
              // dispatch({
              //   type: adminConnectionConst.HIDE_CHECK_EXISTING_ADMIN_NAME
              // })
            }
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: adminConnectionConst.ERROR_IN_CONNECTION
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },
}