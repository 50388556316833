import axios from "axios";
import { agentStatus, loaderConst } from "../../types";
import { config } from '../../../config';
import { loginAction } from "../../Login/actions/loginAction";
import _ from "lodash";

export const agentStatusAction = {
  getAllAgentStatusList(
    defaultSearchFields: any,
    status: any,
    sortValue: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/get-all/organization/agent-status?search_text=${defaultSearchFields}&status=${status}&sortValue=${sortValue}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(agentStatusListData => {
            let tempArray: any = [];
            tempArray = !_.isEmpty(agentStatusListData.data.data.data) && agentStatusListData.data.data.data;

            if (!_.isEmpty(tempArray)) {
              tempArray.map((ele: any, idx: any) => {
                tempArray[idx].agentStatus = ele.status
              })
            }
            dispatch({
              type: agentStatus.GET_ALL_AGENT_STATUS_LIST,
              payload: agentStatusListData,
              tempArray,
            })

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },
  updatePageNo(pageNo: number) {
    return async (dispatch: any) => {
      dispatch({
        type: agentStatus.UPDATE_PAGE_NO,
        payload: pageNo
      })
    }
  },
}