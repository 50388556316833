import Axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { dashboardConst, loaderConst } from "../../types";

export const dashboardAction = {

  /**
   * 
   * @param startDate start date to fetch the record to display on Dashboard 
   * @param endDate end date to fetch the record to display on Dashboard
   * @returns 
   */
  getAllCountforDashboard(
    startDate: any,
    endDate: any,
  ) {
    return async (dispatch: any) => {
      try {
        // Dispatch method to start displaying the loader
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        // return await Axios.get(`${config.apiUrl}/admin/dashboard-details/org-admin/${startDate}/${endDate}`,
        return await Axios.get(`${config.apiUrl}/admin/dashboard-details/org-admin`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(dashboardData => {

            // Dispatch method to get the Dashboard details for Org Admin
            dispatch({
              type: dashboardConst.GET_ALL_DASHBOARD_COUNT,
              payload: dashboardData
            })

            // Dispatch method to get the Platform admin Dashboard counts for KYC
            /* Note: Temporary called the Platform admin reducer to get details 
             * TO DO: While working on Mobile wallet for KYC flow we can combine the reducer.  
             */
            dispatch({
              type: dashboardConst.GET_ALL_PA_DASHBOARD_COUNT,
              payload: dashboardData
            })

            // Dispatch method to stop displaying the loader
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        console.error(`Error in [dashboardAction] - ${error}`)
        throw error;
      }
    }
  },

  getAllCountforPADashboard(
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');

        return await Axios.get(`${config.apiUrl}/admin/dashboard-details/platform-admin`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(dashboardData => {
            dispatch({
              type: dashboardConst.GET_ALL_PA_DASHBOARD_COUNT,
              payload: dashboardData
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            throw error
          })
      } catch (error) {
        throw error;
      }
    }
  },

}