import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { TaskActions } from "src/View/Task/actions/taskActions";
import { connectionAction } from "src/View/Connection/actions/connectionAction";
import { credentialAction } from "src/View/Credentials/actions/credentialAction";
import { myCredentialsAction } from "src/View/MyCredentials/action/myCredentialsAction";
import { t } from 'i18next';
import { presentProofAction } from "src/View/PresentProof/action/presentProofAction";
import { ListScreenOptions } from "src/types/common.interface";
import { PresentProofState } from "src/View/commonConst";
import { RequestList } from "../components/RequestList";
import CreatePresentationRequest from "../components/CreatePresentationRequest";
import { presentationRequestAction } from "../actions/presentationRequestAction";
import SuccessPresentationRequest from "../components/SuccessPresentationRequest";
import { PresentationRequestDetails } from "../components/PresentationRequestDetails";
import { AttributeDetails } from "src/types/proofs.interface";

/* Interface for Props variables*/
interface IPresentationRequestProps {
    PresentationRequestReducer: any,
    PresentationRequestAction: any,
    MyCredentialReducer: any,
    myCredentialsAction: any,
    TaskReducers: any,
    TaskActions: any,
    connectionAction: any,
    ConnectionReducer: any,
    credentialAction: any,
    CredentialReducer: any,
    LoginReducer: any,
    presentProofAction: any
}

/* Interface for local states variables*/
interface IPresentationRequestState {
    showProofDetails: boolean,
    selectedProofDetails: any,
    selectedConnectionName: string,
    requestedAttributesDetails: any[],
    requestedPredicatesDetails: any[],
    listScreenOptions: ListScreenOptions
    showAttributeValues: boolean
    isDefault: boolean,
    presentationExchangeId: string,
    selectedCredentials: any[],
    credentialsToReveal: any[],
    showCredentialDetails: boolean,
    connectionDetails: any,

}

class PresentationRequest extends React.Component<IPresentationRequestProps, IPresentationRequestState> {
    constructor(props: IPresentationRequestProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            showProofDetails: false,
            selectedProofDetails: {},
            selectedConnectionName: "",
            requestedAttributesDetails: [],
            listScreenOptions: {
                pageNo: 1,
                itemsPerPage: 10,
                filter: PresentProofState.STATE_REQUEST_SENT
            },
            showAttributeValues: true,
            isDefault: true,
            presentationExchangeId: '',
            requestedPredicatesDetails: [],
            selectedCredentials: [],
            credentialsToReveal: [],
            showCredentialDetails: false,
            connectionDetails: {}
        }
        this.onShowProofDetails = this.onShowProofDetails.bind(this)
        this.createNewRequest = this.createNewRequest.bind(this)
    }

    /* Method to call the get presentation requests method */
    componentDidMount() {
        const { listScreenOptions } = this.state
        this.getAllPresentationRequests(listScreenOptions)
    }

    /* Method to get presentation records*/
    getAllPresentationRequests(listScreenOptions: ListScreenOptions) {
        this.props.PresentationRequestAction.getAllPresentProofRequest(listScreenOptions);
    }

    createNewRequest() {
        this.props.PresentationRequestAction.createNewRequest()
        this.setState({
            showCredentialDetails: false
        })
    }

    onShowProofDetails(item: any) {
        const restrictionsArray: Array<any> = [];
        const requestedAttributesDetails: Array<any> = [];
        const requestedPredicatesDetails: Array<any> = [];
        const requestDetails: any = item && JSON.parse(item.presentationRequest);

        /* pseudo code for get all credential definition in one array from request details*/
        if (item) {

            for (const attributeData in requestDetails?.requested_attributes) {
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_attributes, attributeData)) {
                    const element = requestDetails.requested_attributes[attributeData];

                    // check if requested attribute is self attested or not
                    if (element.restrictions && element.restrictions.length) {
                        // not a self attested attribute                        
                        element.restrictions.map((restrictionData: any) => {
                            // check for duplicate restrictions
                            if (!restrictionsArray.find((x) => JSON.stringify(x) === JSON.stringify(restrictionData))) {
                                restrictionsArray.push(restrictionData)
                            }
                        })
                    }
                    else {
                        // self attested attribute assign dummy restriction
                        if (!restrictionsArray.find((x) => JSON.stringify(x) === JSON.stringify({ cred_def_id: '' })))
                            restrictionsArray.push({ cred_def_id: '' })
                    }
                }
            }

            for (const predicate in requestDetails.requested_predicates) {
                let predicateData: any = {}
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_predicates, predicate)) {
                    const { restrictions, ...otherPredicateData } = requestDetails.requested_predicates[predicate];
                    predicateData = { ...otherPredicateData }
                    const proofRequestPredicates: any = {}

                    restrictions.map((restrictionData: any) => {
                        proofRequestPredicates['credentialName'] = Object.keys(restrictionData)[0] === 'cred_def_id' ?
                            (Object.values(restrictionData)[0] as string).split(':')[4] :
                            (Object.values(restrictionData)[0] as string).split(':')[2]
                        proofRequestPredicates['referentName'] = predicate
                        proofRequestPredicates[Object.keys(restrictionData)[0]] = Object.values(restrictionData)[0]
                        proofRequestPredicates['credentialFields'] = predicateData
                    })
                    requestedPredicatesDetails.push(proofRequestPredicates)
                }
            }

            this.setState({ selectedConnectionName: item.connectionName, connectionDetails: item.connection })
        }

        /* pseudo code for display the attribute names as well as display the credential name*/
        for (const key of restrictionsArray) {
            const proofRequestAttributes: any = {}
            proofRequestAttributes[`${Object.keys(key)}`] = Object.values(key)[0]
            proofRequestAttributes['credentialId'] = ""
            proofRequestAttributes['presentationExchangeId'] = item.presentationExchangeId

            const attributes: AttributeDetails[] = []

            for (const attributeData in requestDetails.requested_attributes) {
                if (Object.prototype.hasOwnProperty.call(requestDetails.requested_attributes, attributeData)) {
                    const element = requestDetails.requested_attributes[attributeData];

                    // eslint-disable-next-line no-loop-func
                    if (element.restrictions && element.restrictions.length) {
                        element.restrictions.map((restrictionData: any) => {

                            if (Object.values(restrictionData)[0] === Object.values(key)[0]) {
                                attributes.push({
                                    name: element.name,
                                    referentName: attributeData,
                                    reveal: true,
                                })
                            }
                        })
                    }
                    else {
                        if (Object.values(key)[0] === '')
                            attributes.push({
                                name: element.name,
                                referentName: attributeData,
                            })
                    }
                }
            }

            proofRequestAttributes['credentialType'] = Object.keys(key)[0] === 'cred_def_id' ? 'Credential Definition' : 'Schema'
            proofRequestAttributes['credentialName'] = Object.keys(key)[0] === 'cred_def_id' ?
                (Object.values(key)[0] as string).split(':')[4] :
                (Object.values(key)[0] as string).split(':')[2]

            proofRequestAttributes['credentialFields'] = (typeof attributes) === 'string' ? [attributes] : attributes

            requestedAttributesDetails.push(proofRequestAttributes);
        }

        this.setState({
            requestedAttributesDetails,
            requestedPredicatesDetails,
            showCredentialDetails: true,
            presentationExchangeId: item.presentationExchangeId
        })
    }

    /* UI to display the all the child component */
    public render() {
        const { presentationList, createNewRequest } = this.props.PresentationRequestReducer
        const { showCredentialDetails, requestedAttributesDetails, requestedPredicatesDetails, selectedConnectionName, presentationExchangeId } = this.state

        return (
            <>
                <div className="row gutters-sm min-h-100 animated fadeIn">
                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:newProofRequest.presentationRequestList")}</h1>
                                <button className="btn btn-sm btn-nb-outline-primary float-right"
                                    onClick={this.createNewRequest}>
                                    <i className="fa fa-plus"></i>&nbsp;
                                    {t("screens:newProofRequest.create")}
                                </button>
                            </div>
                            <div className="card-body">

                                {/* Component for display the proof request list */}
                                <RequestList
                                    onShowProofDetails={this.onShowProofDetails}
                                    presentationRequestList={presentationList}
                                    getAllPresentationRequests={this.getAllPresentationRequests}
                                />

                            </div>
                        </div>
                    </div>
                    {createNewRequest ?
                        <div className="col-md-12 col-lg-7 mb-30">
                            <SuccessPresentationRequest {...this.props} />
                        </div>
                        :
                        <div className="col-md-12 col-lg-7 mb-30">
                            <div className="card nb-card h-100">
                                {
                                    showCredentialDetails
                                        ? <PresentationRequestDetails
                                            selectedProofDetails={{ requestedAttributesDetails, requestedPredicatesDetails }}
                                            selectedConnectionName={selectedConnectionName}
                                            presentationExchangeId={presentationExchangeId}
                                        />
                                        : <CreatePresentationRequest {...this.props} />
                                }
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentationRequestReducer = state.PresentationRequestReducer;
    const TaskReducers = state.TaskReducers;
    const ConnectionReducer = state.ConnectionReducer;
    const CredentialReducer = state.CredentialReducer;
    const MyCredentialReducer = state.MyCredentialReducer;
    const LoginReducer = state.LoginReducer;

    return { PresentationRequestReducer, TaskReducers, ConnectionReducer, CredentialReducer, MyCredentialReducer, LoginReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        PresentationRequestAction: bindActionCreators(presentationRequestAction, dispatch),
        TaskActions: bindActionCreators(TaskActions, dispatch),
        connectionAction: bindActionCreators(connectionAction, dispatch),
        credentialAction: bindActionCreators(credentialAction, dispatch),
        myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
        presentProofAction: bindActionCreators(presentProofAction, dispatch),
    }
}

const presentationRequest = connect(mapStateToProps, mapDispatchToProps)(PresentationRequest);
export { presentationRequest as PresentationRequest };
