import _ from 'lodash';
import * as React from 'react';
import TableComponents from '../../../../Common Components/Components/TableComponent';
import { tHeadEmailTemplates } from '../constants';

export interface IEmailTemplatesListProps {
    EmailTemplatesReducers: any,
    emailTemplateActions: any,
}

export interface IEmailTemplatesListState {
    defaultSearchFields: any,
    item_perPage: any,
    pageNo: any,
    sortingOrder: any,
    connectionSortBy: any,
}

export default class EmailTemplatesList extends React.Component<IEmailTemplatesListProps, IEmailTemplatesListState> {
    constructor(props: IEmailTemplatesListProps) {
        super(props);

        this.state = {
            defaultSearchFields: '',
            item_perPage: 10,
            pageNo: 1,
            sortingOrder: 'DESC',
            connectionSortBy: '',
        }
        this.showDetails = this.showDetails.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.sorting = this.sorting.bind(this);
    }

    componentDidMount() {
        this.props.emailTemplateActions.getAllEmailTemplates();
    }

    onChangePage(page: any) { }

    showDetails(item: object | any) {
        this.props.emailTemplateActions.getEmailTemplatesById(item.id);
    }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    }

    sorting = (connectionSortBy: string) => { }


    refresh = (event: any) => {
    }

    public render() {

        const {
            emailTemplatesList, selecteEmailTemp, updatedEmailTemp
        } = this.props.EmailTemplatesReducers;
        return (
            <>
                <TableComponents
                    showElementDetails={this.showDetails}
                    tHead={tHeadEmailTemplates}
                    tableContent={emailTemplatesList}
                    selectedRecord={!_.isEmpty(updatedEmailTemp) ? updatedEmailTemp : selecteEmailTemp}
                />
            </>
        );
    }
}
