import { t } from "i18next";
import React from "react";
import { Card, CardBody } from "reactstrap";

export default function ClickableCard({ onClick, inviteFor, inviteMessage }: any) {
    return (
        <Card onClick={onClick} style={{ cursor: "pointer" }}>
            <CardBody style={{height:"160px"}}>
                <div>
                    <label className="badge badge-secondary font-weight-normal">{t("screens:existingConnections.invite")}</label>
                </div>
                <span className="h5 mr-2">{inviteFor}</span>
                <img src="assets/icons/next-arrow.svg" alt="" />
                <p className="text-lg-start">{inviteMessage}</p>
            </CardBody>
        </Card>
    );
}
