import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PlatformConfigActions } from '../action/platformConfigAction';
import PlatformConfigDetails from '../components/platformConfigDetails';

export interface IPlatformConfigProps {
  PlatformConfigActions: any,
  PlatformConfigReducers: any
}

export interface IPlatformConfigState {
}

class PlatformConfig extends React.Component<IPlatformConfigProps, IPlatformConfigState> {
  constructor(props: IPlatformConfigProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <>
        <PlatformConfigDetails {...this.props} />
      </>
    );
  }
}



function mapStateToProps(state: any) {
  const PlatformConfigReducers = state.PlatformConfigReducers;
  const LoaderReducer = state.LoaderReducer

  return {
    PlatformConfigReducers,
    LoaderReducer
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    PlatformConfigActions: bindActionCreators(PlatformConfigActions, dispatch),
  }

}

const connectedPlatformConfig = connect(mapStateToProps, mapDispatchToProps)(PlatformConfig);
export { connectedPlatformConfig as PlatformConfig };