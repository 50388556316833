import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { onboardingAction } from '../actions/onboardingAction';
import { OrganizationConnection } from '../Components/OrganizationConnection';
import { socket, SocketContext } from 'src/contexts/SocketContext';
import { t } from 'i18next';

/* Interface for connection details */
interface ConnectionDetails {
    toOrgName: string
    toOrgEmail: string
    invitationUrl: string
}

/* Interface for Props variables*/
export interface IClientProps {
    onboardingAction: any,
    OnboardingReducer: any,
}

/* Interface for local states variables*/
export interface IClientState {
    defaultSearchFields: string,
    item_perPage: number,
    pageNo: number,
    showDetails: boolean
    connectionDetails: ConnectionDetails
}

class ConnectNewOrganization extends React.Component<IClientProps, IClientState> {
    static contextType?= SocketContext

    constructor(props: IClientProps) {
        super(props);

        /* Initialization of state variables*/
        this.state = {
            defaultSearchFields: '',
            item_perPage: 10,
            pageNo: 1,
            showDetails: false,
            connectionDetails: {
                toOrgName: "",
                toOrgEmail: "",
                invitationUrl: ""
            }
        }
        /* Binding the method */
        this.onSearch = this.onSearch.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.showConnectionInvitation = this.showConnectionInvitation.bind(this)
        this.refresh = this.refresh.bind(this);
        this.onCloseForm = this.onCloseForm.bind(this)
    }

    async componentDidMount() {

        await this.connectSocket()
        console.log('componentDidMount - Create New Org ')
    }

    async connectSocket() {
        const { defaultSearchFields, item_perPage, pageNo } = this.state;
        /* Get all organization connection list action method call */
        this.props.onboardingAction.getOrganizationConnectionList(defaultSearchFields, item_perPage, pageNo);
        if (socket) {
            socket.on('notification', (socketId, payload) => {
                console.log(`Create New Org - notification - socketId: ${socketId}  payload: ${JSON.stringify(payload)}`);
                if (payload && payload.data) {
                    if (payload.data.type === 'Connection') {
                        console.log(` Create New Org - receive Notification Connection`)
                        // this.props.notificationAction.getCountNotification()
                        this.props.onboardingAction.getOrganizationConnectionList(defaultSearchFields, item_perPage, pageNo);
                    }
                }
            })
        } else {
            console.log("Could not estalished a connection")
        }
    }

    /**
     * Method for searching specific connection in the list
     * @param event use for getting the typed value in search textbox
     */
    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { item_perPage, pageNo } = this.state;
        /* Capture the enter key event and call the get organization connection list API with search value */
        if (event.key === 'Enter') {
            this.setState({ defaultSearchFields: event.target.value });
            this.props.onboardingAction.getOrganizationConnectionList(event.target.value, item_perPage, pageNo);
        }
    }

    /**
     * Method used to page change on pagination
     * @param page use for changed page no.
     */
    onChangePage(page: any) {
        const { defaultSearchFields, item_perPage } = this.state;
        this.setState({ pageNo: page });
        this.props.onboardingAction.getOrganizationConnectionList(defaultSearchFields, item_perPage, page);
    }

    /**
     * Method for view single connection details
     * @param connection used for getting selected connection invitation
     */
    showConnectionInvitation(connection: any) {
        const connectionDetails: ConnectionDetails = {
            toOrgName: "",
            toOrgEmail: "",
            invitationUrl: ""
        }
        connectionDetails.toOrgEmail = connection.toOrgEmail;
        connectionDetails.toOrgName = connection.toOrgName;
        connectionDetails.invitationUrl = connection.invitationUrl
        this.setState({
            showDetails: true,
            connectionDetails
        })
    }

    /* Method for refresh or getting updated organization connection list */
    refresh() {
        this.setState({
            defaultSearchFields: '',
            item_perPage: 10,
            pageNo: 1,
        })
        this.props.onboardingAction.getOrganizationConnectionList('', 10, 1);
    }

    /* Method used for close the connection details form */
    onCloseForm() {
        this.setState({ showDetails: false })
    }

    public render() {
        const { defaultSearchFields, item_perPage, pageNo, showDetails, connectionDetails } = this.state
        const { organizationConnectionList, totalOrganizationConnection, connectionTHead } = this.props.OnboardingReducer;

        return (
            <>
                <div className="row gutters-sm min-h-100 animated fadeIn">
                    <div className="col-md-12 col-lg-6 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:onboardingNewOrganization.invitedOrganizations")}</h1>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {organizationConnectionList &&
                                        <div className="col-sm-12">
                                            {/* Table component call for organization connection list */}
                                            <TableComponents
                                                onChangePage={this.onChangePage}
                                                showElementDetails={this.showConnectionInvitation}
                                                tHead={connectionTHead}
                                                tableContent={organizationConnectionList}
                                                totalRecords={totalOrganizationConnection}
                                                searching={this.onSearch}
                                                refresh={this.refresh}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 mb-30">
                        <>
                            {/* Send connection invitation component call */}
                            <OrganizationConnection
                                defaultSearchFields={defaultSearchFields}
                                item_perPage={item_perPage}
                                pageNo={pageNo}
                            />
                        </>

                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state: any) {
    const { loggingIn } = state.LoginReducer;
    const OnboardingReducer = state.OnboardingReducer;

    return { loggingIn, OnboardingReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        onboardingAction: bindActionCreators(onboardingAction, dispatch),
    }
}

const connectNewOrganization = connect(mapStateToProps, mapDispatchToProps)(ConnectNewOrganization);
export { connectNewOrganization as Organization };
