import { govRule, userConstants } from "../../types";
import { tHeadforGovRuleList } from "../constants";

const initialState = {
  ruleList: [],
  tHeadforGovRuleList: [],
  selectedRule: {},
  totalrules: 0,
  fromPage: 'GovRule',
  hasNextPage: 0,
  hasPreviousPage: 0,
  lastPage: 0,
  previousPage: 0,
  saveButton: true,
  modalShow: false
}

export function GovRuleReducer(state = initialState, action: any) {
  switch (action.type) {
    case userConstants.LOADING:
      return Object.assign({},
        state,
        {
          loading: true
        });

    case govRule.GET_ALL_RULE_LIST:
      return Object.assign({}, state, {
        ruleList: action.payload.data.data.data,
        tHeadforGovRuleList: tHeadforGovRuleList,
        hasNextPage: action.payload.data.data.hasNextPage,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        previousPage: action.payload.data.data.previousPage,
        totalrules: action.payload.data.data.totalItems,
        modalShow: false,
        fromPage: 'GovRule',
      });
    case govRule.SELECTED_GOV_RULE:
      return Object.assign({}, state, {
        selectedRule: action.payload.item
      })
    case govRule.CLEAR_SELECTED_RULE:
      return Object.assign({}, state, {
        selectedRule: {},
        modalShow: false
      })
    case govRule.OPEN_SAVE_BUTTON:
      return Object.assign({}, state, {
        saveButton: false
      })
    case govRule.AFTER_SAVE_RULE:
      return Object.assign({}, state, {
        modalShow: true
      })

    default:
      return state
  }
}
