import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';

import images from "src/utils/images";
import TypeaheadComponent from './TypeaheadComponent';
import { t } from 'i18next';

export interface IProblemReportProps {
    isModalShow: boolean,
    closeModal: any,
    handleProblemReportSubmit?: any,
    reasonList: string[],
    selectedRecordId: string,
    selectedRecordGivenName: string
    modalTitle: string,
    credentialState: string
}

export interface IProblemReportState {
    clearTypeAhead: boolean,
    selectedReason: string,
}

export default class ProblemReportComponent extends React.Component<IProblemReportProps, IProblemReportState> {
    constructor(props: IProblemReportProps) {
        super(props);

        this.state = {
            clearTypeAhead: false,
            selectedReason: ''
        }

        this.handleChangeReason = this.handleChangeReason.bind(this);
        this.handleReasonForActionSubmit = this.handleReasonForActionSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this)
        this.handleInputReasonChange = this.handleInputReasonChange.bind(this)
    }

    handleCancel() {
        this.setState({
            selectedReason: ''
        })
        this.props.closeModal()
    }

    handleChangeReason(selectedOptions: React.DOMAttributes<HTMLInputElement> | any) {
        let selectedData = selectedOptions[0];
        this.setState({
            selectedReason: selectedData,
        })
    }

    handleChangeImage(event: React.DOMAttributes<HTMLInputElement> | any) {
    }

    handleReasonForActionSubmit() {
        const { selectedReason } = this.state
        const { selectedRecordId } = this.props
        this.props.handleProblemReportSubmit(selectedReason, selectedRecordId)
    }

    handleInputReasonChange() {
        this.setState({
            selectedReason: ''
        })
    }

    public render() {
        const { isModalShow, reasonList, selectedRecordId, modalTitle, credentialState } = this.props;
        const { clearTypeAhead, selectedReason } = this.state

        return (
            <Modal isOpen={isModalShow} className="nb-modal animated slideInRight" toggle={this.props.closeModal}>

                <ModalBody>
                    <div className="row gutters-sm min-h-100">
                        <div className="col-md-12 col-lg-12 mb-30">
                            <div className="w-100">
                                <div className="card-header bg-white border-bottom-0">
                                    <h1 className="nb-title">{modalTitle}</h1>
                                </div>
                                <div className="card-body">

                                    <div className="mb-3">
                                        <h6>{t("screens:problemReportModal.promptQuestion")} {credentialState}.</h6>
                                    </div>
                                    <div className="row">

                                        {
                                            <div className="col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <TypeaheadComponent
                                                        data={reasonList}
                                                        onChange={this.handleChangeReason}
                                                        onInputChange={this.handleInputReasonChange}
                                                        clearTypeahead={clearTypeAhead}
                                                        placeholder={t("screens:problemReportModal.selectReason")}
                                                        disabled={false} />
                                                </div>
                                            </div>

                                        }

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-4">
                                            <button type="button"
                                                className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                data-dismiss="modal"
                                                onClick={(e) => this.handleCancel()}
                                            >{t("screens:problemReportModal.cancel")}</button>
                                        </div>
                                        <div className="col-sm-6">
                                            <button type="button"
                                                className="btn btn-block btn-nb-outline-primary rounded-pill"
                                                data-dismiss="modal"
                                                onClick={(e) => this.handleReasonForActionSubmit()}
                                                disabled={!selectedRecordId || !selectedReason}
                                            >{t("screens:problemReportModal.submit")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
