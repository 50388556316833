import _ from 'lodash';
import * as React from 'react';
import TypeaheadComponent from '../../../Common Components/Components/TypeaheadComponent';
import { t } from 'i18next';
import { CredDefResponsibilityType } from 'src/View/IssuersVerifiers/interfaces';

enum PredicateType {
  LessThan = '<',
  LessThanOrEqualTo = '<=',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
}
interface AttributeValue {
  name: string,
  value: string
}

interface AttributeFilter {
  schema_id?: string
  schema_issuer_did?: string
  schema_name?: string
  schema_version?: string
  issuer_did?: string
  cred_def_id?: string
  attributeValue?: AttributeValue
}
interface RequestedAttributes {
  policyName: string,
  attributeNames: string[],
  restrictions: AttributeFilter[]
}

interface RequestedPredicates {
  policyName?: string,
  attributeName?: string,
  predicateType?: PredicateType | undefined,
  predicateValue?: number | undefined,
  restrictions?: AttributeFilter[]
}

export interface IInstantTaskProps {
  TaskReducers: any,
  TaskActions: any,
  connectionAction: any,
  ConnectionReducer: any,
  credentialAction: any,
  CredentialReducer: any,
  LoginReducer: any
}

type PolicyType = 'Schema' | 'Credential Definition'
interface PolicyOptions {
  type: PolicyType,
  policyName: string,
  requestedAttributes: RequestedAttributes,
  requestedPredicates: RequestedPredicates[],
  selectedCredential?: any,
  isAllAttributesSelected?: boolean,
  selectedOrganization?: any,
}

export interface IInstantTaskState {
  taskData: any,
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  connectionSortBy: string,
  CredentialSortBy: string,
  clearTypeahead: boolean,
  clearTypeAheadCredDef: boolean,
  clearTypeAheadSchema: boolean,
  isSelected: boolean,
  clickedConnection: any,
  isSelectedCredDef: boolean,
  isSelectedSchema: boolean,
  connectionFilterValue: any,
  attributeArray: string[],
  policyOptions: PolicyOptions[],
  clearTypeAheadOrganization: boolean,
  isDeleted: any,
  isValidProofRequest: boolean,
  selfAttestedAttributeKey: string[]
}

export default class InstantTask extends React.Component<IInstantTaskProps, IInstantTaskState> {
  constructor(props: IInstantTaskProps) {
    super(props);

    this.state = {
      taskData: {
        requestReason: ''
      },
      defaultSearchFields: '',
      item_perPage: 0,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      CredentialSortBy: '',
      clearTypeahead: false,
      clearTypeAheadCredDef: false,
      clearTypeAheadSchema: false,
      isSelected: false,
      clickedConnection: {},
      isSelectedCredDef: false,
      isSelectedSchema: false,
      connectionFilterValue: 'all',
      policyOptions: [
        {
          policyName: '',
          type: 'Schema',
          requestedAttributes: {
            policyName: '',
            attributeNames: [],
            restrictions: [],
          },
          requestedPredicates: [],
          selectedCredential: {},
          isAllAttributesSelected: true,
        }],
      attributeArray: [],
      clearTypeAheadOrganization: false,
      isDeleted: 'false',
      isValidProofRequest: true,
      selfAttestedAttributeKey: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.sendInstantTask = this.sendInstantTask.bind(this);
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCredDefChange = this.handleCredDefChange.bind(this);
    this.handleCredDefInputChange = this.handleCredDefInputChange.bind(this);
    this.listView = this.listView.bind(this);
    this.handleSchemaChange = this.handleSchemaChange.bind(this);
    this.handleSchemaInputChange = this.handleSchemaInputChange.bind(this);
    this.allAttributesChange = this.allAttributesChange.bind(this);
    this.handlePredicateInputChange = this.handlePredicateInputChange.bind(this);
    this.handlePredicateValueChange = this.handlePredicateValueChange.bind(this);
    this.removePolicy = this.removePolicy.bind(this);
  }



  componentDidMount() {
    const { loggedInUser } = this.props.LoginReducer;
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, connectionSortBy, connectionFilterValue, isDeleted } = this.state;
    // Get All connection by orgId
    this.props.connectionAction.getAllConnections(defaultSearchFields, connectionFilterValue, sortingOrder, connectionSortBy, item_perPage, pageNo, isDeleted);
    // Get All credential by orgId
    // this.props.credentialAction.getAllCredentialByOrgId(defaultSearchFields, item_perPage, pageNo, 'credDef', sortingOrder, CredentialSortBy);
    // this.props.TaskActions.getAllCreateCredDefByOrgId();
    this.props.TaskActions.getAllCredDefForInstantTask();
    this.props.TaskActions.getAllSchemas(loggedInUser.organization.id, CredDefResponsibilityType.Verification);
    this.props.TaskActions.getAllOrganization();
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { taskData } = this.state;
    let updateTaskData = taskData;

    updateTaskData[event.target.name] = event.target.value;
    this.setState({
      taskData: updateTaskData,
    });
  }

  checkProofRequestData(policyOptions: any) {
    let allRequestedAttributes = policyOptions.map((a: any) => a.requestedAttributes.attributeNames);
    let allSelectedCredentials = policyOptions.map((a: any) =>
      a.selectedCredential.attributes ? a.selectedCredential.attributes : a.selectedCredential.schema.attributes
    );

    let imageCounter = 0;

    allSelectedCredentials.forEach((credential: any, idx: number) => {
      credential.forEach((attribute: any) => {
        const foundIndex = allRequestedAttributes[idx].findIndex((a: any) => a === JSON.parse(attribute).name)
        if (foundIndex !== -1) {
          if (JSON.parse(attribute).value === 'image') {
            imageCounter++
          }
        }
      })
    })

    return imageCounter
  }

  sendInstantTask(event: any) {
    const { clickedConnection, taskData, policyOptions, selfAttestedAttributeKey } = this.state;

    const imageCounter = this.checkProofRequestData(policyOptions)

    if (imageCounter <= 2) {
      // remove the empty attribute and predicate object and create a combine requested attributes form each policy attributes
      const attributes: any = []
      const predicates: any = []
      policyOptions.map((policyValue: PolicyOptions, policyIndex: number) => {
        policyValue.requestedAttributes.attributeNames.map((attributeName: string) => {

          if (attributeName !== '') {
            attributes.push({
              attributeName: attributeName,
              policyName: policyValue.requestedAttributes.policyName,
              restrictions: policyValue.requestedAttributes.restrictions
            })
          }
        })

        policyValue.requestedPredicates.map((predicatesValue: any) => {
          if (predicatesValue.predicateType !== undefined && predicatesValue.predicateValue !== undefined)
            predicates.push(predicatesValue)
        })
      })

      if (selfAttestedAttributeKey.length > 0) {
        selfAttestedAttributeKey.map((attribute: any) => {
          if (attribute && attribute !== '') {
            attributes.push({
              attributeName: attribute,
              restrictions: []
            })
          }
        })
      }

      // Remove the attribute from the requested attributes if it has an predicate attached
      attributes.forEach((attribute: any, attributeIndex: number) =>
        predicates.forEach((predicate: any) => {
          if (JSON.stringify(attribute.restrictions) === JSON.stringify(predicate.restrictions)) {
            if (attribute.attributeName === predicate.attributeName) {
              attributes.splice(attributeIndex, 1)
            }
          }
        })
      )

      const instantTaskOptions = {
        connectionId: !_.isEmpty(clickedConnection) ? clickedConnection.connectionId : '',
        requestReason: taskData.requestReason,
        attributes,
        predicates,
      }

      this.props.TaskActions.sendInstantTask(instantTaskOptions);

      this.setState({
        clickedConnection: {},
        taskData: {
          requestReason: '',
        },
        policyOptions: [
          {
            policyName: '',
            type: 'Schema',
            requestedAttributes: {
              policyName: '',
              attributeNames: [],
              restrictions: [],
            },
            requestedPredicates: [],
            selectedCredential: {},
            isAllAttributesSelected: false,
          }],
        clearTypeAheadSchema: true,
        selfAttestedAttributeKey: [],
        // clearTypeahead: true,
        // clearTypeaheadCredDef: true,
        isValidProofRequest: true
      })
    }
    else {
      this.setState({ isValidProofRequest: false })
    }
  }

  handleConnectionChange(selectedOptions: any) {
    let selectedData = selectedOptions[0];
    this.setState({
      isSelected: true,
      clickedConnection: selectedData,
    })
  }

  handleInputChange() {
    this.setState({
      isSelected: false
    })
  }

  /**
   * Method used to handle selected credential details
   * @param selectedCredDefOptions 
   */
  handleCredDefChange(index: any, selectedCredDef: any) {

    let selectedData = selectedCredDef[0];
    this.props.TaskActions.clearSelectedIssuerOrganizationName()
    const updatePolicyOptions = this.state.policyOptions

    if (selectedData) {
      this.props.TaskActions.getSelectedCredDefDetails(selectedData.credentialDefinitionId)

      const namesArray: string[] = []
      selectedData?.schema?.attributes.map((value: string) => {
        namesArray.push(JSON.parse(value).name)
      })

      updatePolicyOptions[index].selectedCredential = selectedData
      updatePolicyOptions[index].requestedAttributes = {
        policyName: selectedData?.schema?.schemaLedgerId,
        attributeNames: namesArray,
        restrictions: [{
          cred_def_id: selectedData?.credentialDefinitionId,
        }]
      }
    }
    else {
      updatePolicyOptions[index].selectedCredential = {}
      updatePolicyOptions[index].requestedAttributes = {
        policyName: '',
        attributeNames: [],
        restrictions: []
      }
    }

    this.setState({
      isSelectedCredDef: true,
      policyOptions: updatePolicyOptions
    })
  }

  handleSchemaChange(index: any, selectedSchema: any) {

    let selectedData = selectedSchema[0];
    this.props.TaskActions.clearSelectedIssuerOrganizationName()
    const updatePolicyOptions = this.state.policyOptions

    if (selectedData) {
      this.props.TaskActions.getSelectedSchemaDetails(selectedData.schemaLedgerId)

      const namesArray: string[] = []
      selectedData?.attributes.map((value: string) => {
        namesArray.push(JSON.parse(value).name)
      })

      updatePolicyOptions[index].selectedCredential = selectedData
      updatePolicyOptions[index].requestedAttributes = {
        policyName: selectedData.schemaLedgerId,
        attributeNames: namesArray,
        restrictions: [{
          schema_id: selectedData.schemaLedgerId,
        }]
      }

    }
    else {
      updatePolicyOptions[index].selectedCredential = {}
      updatePolicyOptions[index].requestedAttributes = {
        policyName: '',
        attributeNames: [],
        restrictions: []
      }
    }

    this.setState({
      isSelectedSchema: true,
      policyOptions: updatePolicyOptions
    })
  }

  /**
   * Method used to handle selected credential details
   */
  handleCredDefInputChange() {
    this.props.TaskActions.clearSelectedIssuerOrganizationName()
    this.setState({
      isSelectedCredDef: false
    })
  }

  handleSchemaInputChange(data: any, event: any) {
    this.props.TaskActions.clearSelectedIssuerOrganizationName()
    this.setState({
      isSelectedSchema: false
    })
  }

  requestTypeChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let updatePolicyOptions = this.state.policyOptions;
    updatePolicyOptions[event.target.name].type = event.target.value;

    this.setState({
      isSelectedCredDef: false,
      isSelectedSchema: false,
      policyOptions: updatePolicyOptions
    });
  }

  allAttributesChange(policyIndex: number, event: React.ChangeEvent<HTMLInputElement> | any) {
    const updatePolicyOptions = this.state.policyOptions

    console.log(updatePolicyOptions[policyIndex].isAllAttributesSelected)

    const selectedCredentialsAttributes = updatePolicyOptions[policyIndex].type === 'Credential Definition' ?
      updatePolicyOptions[policyIndex].selectedCredential.schema.attributes :
      updatePolicyOptions[policyIndex].selectedCredential.attributes

    if (!updatePolicyOptions[policyIndex].isAllAttributesSelected) {

      selectedCredentialsAttributes.map((attributeData: string) => {
        const attributeName = JSON.parse(attributeData).name

        if (!updatePolicyOptions[policyIndex].requestedAttributes.attributeNames.includes(attributeName)) {
          updatePolicyOptions[policyIndex].requestedAttributes.attributeNames.push(attributeName)
        }
      })
      updatePolicyOptions[policyIndex].isAllAttributesSelected = true
    }
    else {
      updatePolicyOptions[policyIndex].requestedAttributes.attributeNames = []
      updatePolicyOptions[policyIndex].isAllAttributesSelected = false
    }


    this.setState({
      policyOptions: updatePolicyOptions
    })
  }

  handlePredicateInputChange(policyIndex: number, index: number, event: React.ChangeEvent<HTMLInputElement> | any) {
    const { value, name } = event.target;
    let updatePolicyOptions = this.state.policyOptions;

    const attributeName = name

    const credentialId = updatePolicyOptions[policyIndex].type === 'Credential Definition' ?
      updatePolicyOptions[policyIndex].selectedCredential.credentialDefinitionId :
      updatePolicyOptions[policyIndex].selectedCredential.schemaLedgerId

    const predicateValues = Object.values(PredicateType)
    const predicateIndexFound = updatePolicyOptions[policyIndex].requestedPredicates.findIndex(x => x.attributeName === attributeName)

    if (!predicateValues.includes(value)) {
      updatePolicyOptions[policyIndex].requestedPredicates.splice(predicateIndexFound, 1)
    }
    else {
      if (predicateIndexFound > -1)
        updatePolicyOptions[policyIndex].requestedPredicates[predicateIndexFound].predicateType = value
      else
        updatePolicyOptions[policyIndex].requestedPredicates.push({
          policyName: credentialId,
          attributeName: attributeName,
          predicateType: value,
          restrictions: [{
            [updatePolicyOptions[policyIndex].type === 'Credential Definition' ? 'cred_def_id' : 'schema_id']: credentialId
          }],
          predicateValue: undefined
        })
    }

    this.setState({
      policyOptions: updatePolicyOptions
    });
  }

  handlePredicateValueChange(policyIndex: number, event: any) {
    const { value, name } = event.target;
    let updatePolicyOptions = this.state.policyOptions;

    const attributeName = name

    const credentialId = updatePolicyOptions[policyIndex].type === 'Credential Definition' ?
      updatePolicyOptions[policyIndex].selectedCredential.credentialDefinitionId :
      updatePolicyOptions[policyIndex].selectedCredential.schemaLedgerId
    const predicateIndex: any = updatePolicyOptions[policyIndex].requestedPredicates.findIndex(item => item.attributeName === attributeName)

    if (predicateIndex > -1) {
      updatePolicyOptions[policyIndex].requestedPredicates[predicateIndex].predicateValue = Number(value)
    }
    else {
      updatePolicyOptions[policyIndex].requestedPredicates.push({
        policyName: credentialId,
        attributeName: attributeName,
        predicateType: undefined,
        restrictions: [{
          [updatePolicyOptions[policyIndex].type === 'Credential Definition' ? 'cred_def_id' : 'schema_id']: credentialId
        }],
        predicateValue: Number(value)
      })
    }

    this.setState({
      policyOptions: updatePolicyOptions
    });
  }

  attributeSelectionChange(policyIndex: number, event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    const updatePolicyOptions = this.state.policyOptions


    const attributeArray = updatePolicyOptions[policyIndex].requestedAttributes.attributeNames
    const predicateArray = updatePolicyOptions[policyIndex].requestedPredicates
    const updateAttributeArray: Array<any> = attributeArray
    const updatePredicateArray: Array<any> = predicateArray

    if (!attributeArray.includes(value)) {
      updateAttributeArray.push(value);
      updatePredicateArray.push({
        attributeName: value,
        policyName: updatePolicyOptions[policyIndex].policyName,
        predicateType: undefined,
        predicateValue: undefined,
      })
    } else {
      const attributeIndex: any = attributeArray.indexOf(value)
      const predicateIndex: any = predicateArray.findIndex(item => item.attributeName === value)
      updateAttributeArray.splice(attributeIndex, 1);
      updatePredicateArray.splice(predicateIndex, 1)
    }

    const attributes = updatePolicyOptions[policyIndex].selectedCredential.hasOwnProperty('schema') ?
      updatePolicyOptions[policyIndex].selectedCredential.schema.attributes :
      updatePolicyOptions[policyIndex].selectedCredential.attributes

    // update the selected Attribute & predicate array
    updatePolicyOptions[policyIndex].requestedAttributes.attributeNames = updateAttributeArray
    updatePolicyOptions[policyIndex].requestedPredicates = updatePredicateArray

    //update the all attribute selection flag 
    updatePolicyOptions[policyIndex].isAllAttributesSelected = attributeArray.length === attributes.length

    this.setState({
      attributeArray: updateAttributeArray,
      policyOptions: updatePolicyOptions
    });
  }

  handleSelected(data: any) {
  }

  handleOrganizationChange(index: any, selectedOrganization: any) {
    let selectedOrganizationData = selectedOrganization[0];
    this.props.TaskActions.clearSelectedIssuerOrganizationName()
    const updatePolicyOptions = this.state.policyOptions

    if (selectedOrganizationData) {
      this.props.TaskActions.getSelectedOrganizationCredentials(selectedOrganizationData.id, CredDefResponsibilityType.Verification)
    }

    updatePolicyOptions[index].selectedOrganization = selectedOrganizationData

    this.setState({
      policyOptions: updatePolicyOptions
    })

  }

  handleOrganizationInputChange(event: any) {

  }

  addPolicy(event: React.MouseEvent<HTMLButtonElement>) {
    const updatePolicyOptions = this.state.policyOptions
    updatePolicyOptions.push({
      policyName: '',
      type: 'Schema',
      requestedAttributes: {
        policyName: '',
        attributeNames: [],
        restrictions: []
      },
      requestedPredicates: [],
      isAllAttributesSelected: true,
      selectedCredential: {}
    })
    this.setState({
      policyOptions: updatePolicyOptions
    })
    // this.isBtnDisable();
    event.preventDefault();
  }

  removePolicy(event: any, index: number) {
    event.preventDefault();

    const previousPolicyOptions = [...this.state.policyOptions]
    previousPolicyOptions.splice(index, 1);

    this.setState({
      policyOptions: previousPolicyOptions
    })
  }

  createPolicyDetails() {
    const { policyOptions, clearTypeAheadCredDef, clearTypeAheadOrganization, clearTypeAheadSchema } = this.state
    const { schemasForInstantTaskList, credentialDefinitionListByOrganization } = this.props.TaskReducers
    const { organizationList } = this.props.TaskReducers
    const predicates = ['<=', '<', '>', '>=']


    return policyOptions.map((policyValue: PolicyOptions, policyIndex: number) => {

      let attributes: any
      if (policyValue.selectedCredential.hasOwnProperty('schema')) {
        attributes = policyValue.selectedCredential.schema.attributes
      }
      else {
        attributes = policyValue.selectedCredential.attributes
      }

      return <div key={policyIndex}>

        <div className='row'>
          <div className='col-sm-12'>
            <div className='form-group'>
              <div className="card">
                <div className="card-body" >
                  <div className='row'>
                    <div className='col-md-9'></div>
                    <div className='col-md-3'>
                      <button className="btn float-right"
                        onClick={(e) => this.removePolicy(e, policyIndex)}
                      ><i className="fas fa-minus-circle" />
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="nb-label">{t("screens:createNewtask.selectPolicyType")}<span>*</span></label>
                        <div className="card">
                          <div className="card-body" >
                            <div className="custom-control custom-radio">
                              <input type="radio" id={`Schema-${policyIndex}`} name={`${policyIndex}`} className="custom-control-input" value='Schema'
                                onChange={(e) => this.requestTypeChange(e)} checked={policyOptions[policyIndex].type === 'Schema'}
                              // disabled={false} 
                              />
                              <label className="custom-control-label" htmlFor={`Schema-${policyIndex}`}>{t("screens:createNewtask.schema")}</label>
                            </div>
                            <div className="custom-control custom-radio">
                              <input type="radio" id={`Credential Definition-${policyIndex}`} name={`${policyIndex}`} className="custom-control-input" value='Credential Definition'
                                onChange={(e) => this.requestTypeChange(e)} checked={policyValue.type === 'Credential Definition'}
                              // disabled={false}
                              />
                              <label className="custom-control-label" htmlFor={`Credential Definition-${policyIndex}`}>{t("screens:createNewtask.credentialDefinition")}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      {
                        policyValue.type === 'Credential Definition' ?
                          <div className='form-group'>
                            <label className="nb-label">Issuer Organization</label>
                            <TypeaheadComponent
                              idx={`organization-${policyIndex}`}
                              data={organizationList}
                              onChange={this.handleOrganizationChange.bind(this, policyIndex)}
                              onInputChange={this.handleOrganizationInputChange}
                              clearTypeahead={clearTypeAheadOrganization} placeholder={t("screens:createNewtask.selectOrganization")} disabled={false} />
                          </div> :
                          ''
                      }
                      <div className="form-group">
                        <label className="nb-label">{policyValue.type === 'Credential Definition' ? `${t("screens:createNewtask.credentialDefinition")}` : `${t("screens:createNewtask.schema")}`}</label>
                        <TypeaheadComponent
                          idx={`${policyValue.type}-${policyIndex}`}
                          onChange={policyValue.type === 'Credential Definition' ? this.handleCredDefChange.bind(this, policyIndex) : this.handleSchemaChange.bind(this, policyIndex)}
                          data={policyValue.type === 'Credential Definition' ? credentialDefinitionListByOrganization : schemasForInstantTaskList}
                          onInputChange={policyValue.type === 'Credential Definition' ? this.handleCredDefInputChange : this.handleSchemaInputChange}
                          clearTypeahead={policyValue.type === 'Credential Definition' ? clearTypeAheadCredDef : clearTypeAheadSchema}
                          placeholder={policyValue.type === 'Credential Definition' ? `${t("screens:createNewtask.credentialDefinition")}` : `${t("screens:createNewtask.schema")}`}
                          disabled={false} />
                      </div>

                    </div>
                  </div>

                  {!_.isEmpty(attributes) && (policyValue.requestedAttributes) ?
                    <div className='row'>
                      <div className="col-sm-12">
                        <div className="table-responsive">
                          <table className="table border">
                            <thead>
                              <tr>
                                <th>
                                  <label className="align-self-center" htmlFor=''>
                                    <input type="checkbox" id='allAttributes' key='allAttributes'
                                      name={`allAttributes-${policyIndex}`}
                                      checked={policyValue.isAllAttributesSelected}
                                      onChange={(e) => this.allAttributesChange(policyIndex, e)}
                                    /></label>
                                </th>
                                <th>{t("screens:createNewtask.names")}</th>
                                <th>{t("screens:createNewtask.operator")}</th>
                                <th>{t("screens:createNewtask.value")}</th>
                              </tr>
                            </thead>

                            {
                              !_.isEmpty(attributes) && attributes.map((attribute: any, index: any) => {
                                const isAttributeSelected = (policyValue.requestedAttributes.attributeNames).filter(f => f === (JSON.parse(attribute).name)).length > 0
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>
                                        <label className="align-self-center" htmlFor={JSON.parse(attribute).name}>
                                          <input type="checkbox" id={JSON.parse(attribute).name}
                                            name={`attributesCheck-${policyIndex}-${index}`}
                                            value={JSON.parse(attribute).name}
                                            onChange={(e) => this.attributeSelectionChange(policyIndex, e)}
                                            checked={isAttributeSelected}
                                          /></label>
                                      </td>
                                      <td><p className={!isAttributeSelected ? 'text-muted' : ''}>{JSON.parse(attribute).name}</p></td>
                                      <td>
                                        <select id="predicates"
                                          className='form-control'
                                          name={`${JSON.parse(attribute).name}`}
                                          onChange={(attribute) => this.handlePredicateInputChange(policyIndex, index, attribute)} disabled={!isAttributeSelected}>
                                          <option value=""></option>
                                          {
                                            predicates.map((value: string, preIdx: number) => {
                                              return <option key={preIdx} value={value}>{value}</option>
                                            })
                                          }
                                        </select>
                                      </td>
                                      <td>
                                        <input type="text" className="form-control"
                                          name={`${JSON.parse(attribute).name}`}
                                          autoComplete="off"
                                          onChange={(e) => this.handlePredicateValueChange(policyIndex, e)}
                                          disabled={!isAttributeSelected}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                )

                              })
                            }
                          </table>
                        </div>
                      </div>
                    </div> :
                    <div></div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

    })
  }

  listView(event: any) {
    this.setState({
      taskData: {},
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      connectionSortBy: '',
      CredentialSortBy: '',
      clearTypeahead: false,
      clearTypeAheadCredDef: false,
      clearTypeAheadSchema: false,
      isSelected: false,
      clickedConnection: {},
      isSelectedCredDef: false,
      isSelectedSchema: false,
      clearTypeAheadOrganization: false,
    })
    this.props.TaskActions.clearForm()
  }

  onChangeSelfAttributeKey(event: React.ChangeEvent<HTMLInputElement> | any, index: any) {
    event.preventDefault();
    let updateField = this.state.selfAttestedAttributeKey;
    updateField[event.target.name] = (event.target.value);

    this.setState({
      selfAttestedAttributeKey: updateField,
    })
  }

  removeSelfAttestedAttribute(event: React.MouseEvent<HTMLButtonElement>, i: number) {
    event.preventDefault();

    let keys = [...this.state.selfAttestedAttributeKey];
    keys.splice(i, 1);

    return this.setState({ selfAttestedAttributeKey: keys })
  }

  createSelfAttestedAttribute() {
    const { selfAttestedAttributeKey } = this.state;

    return selfAttestedAttributeKey.map((field: any, index: any) => {
      return index === 0 ?
        <div key={index}>
          <div className="row">
            <label className="col-sm-1 col-form-label nb-label pr-0"> {index + 1}. <span className='text-danger'>*</span></label>
            <div className="col-sm-6">

              <input type="text" className="form-control"
                name={index} id="attributeKey"
                value={field}
                onChange={(e) => this.onChangeSelfAttributeKey(e, index)}
              />

            </div>
            <div className="col-sm-2">
              <button className="btn float-left"
                onClick={(e) => this.removeSelfAttestedAttribute(e, index)}
              ><i className="fas fa-minus-circle" />
              </button>
            </div>
          </div>
        </div>
        :
        // -------------------------------
        <div key={index}>
          <div className="row">
            <label className="col-sm-1 col-form-label nb-label pr-0"> {index + 1}. <span className='text-danger'>*</span></label>
            <div className="col-sm-6">

              <input type="text" className="form-control"
                name={index} id="attributeKey"
                value={field}
                onChange={(e) => this.onChangeSelfAttributeKey(e, index)}
              />

            </div>
            <div className="col-sm-2">
              <button className="btn float-left"
                onClick={(e) => this.removeSelfAttestedAttribute(e, index)}
              ><i className="fas fa-minus-circle" />
              </button>
            </div>
          </div>
        </div>
    })
  }

  addSelfAttestedAttribute(event: any) {
    let updateValue: any = this.state.selfAttestedAttributeKey;
    updateValue.push('');
    this.setState({
      selfAttestedAttributeKey: updateValue,
    })
    event.preventDefault();
  }


  public render() {
    const { taskData, clearTypeahead, clickedConnection, policyOptions, isValidProofRequest, selfAttestedAttributeKey } = this.state;
    const { connectionList } = this.props.ConnectionReducer;

    return (
      <>
        <div className="col-md-12 col-lg-9 mb-30">
          <div className="card nb-card h-100">
            <div className="card-header bg-white border-bottom-0">
              <div className="row">
                <div className="col-sm-8">
                  <h1 className="nb-title">{t("screens:createNewtask.instantTask")}</h1>
                </div>
                <div className="col-sm-4 text-right">
                  <button type="button" className="btn"
                    onClick={(e) => this.listView(e)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body p-5">
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="nb-label">{t("screens:createNewtask.connectionList")}</label>
                        <TypeaheadComponent data={connectionList} onChange={this.handleConnectionChange} onInputChange={this.handleInputChange}
                          clearTypeahead={clearTypeahead} placeholder={t("screens:createNewtask.selectConnectionList")} disabled={false} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:createNewtask.requestReason")}</label>
                    <input type="text" className="form-control"
                      name="requestReason"
                      autoComplete="off"
                      value={taskData.requestReason}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  {
                    this.createPolicyDetails()
                  }
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button className="btn btn-block btn-nb-outline-primary rounded-pill"
                        onClick={(e) => this.addPolicy(e)}
                      >{t("screens:createNewtask.addCredential")}</button>
                    </div>
                  </div>
                </div>
              </div>

              {/* SELF-ATTESTED ATTRIBUTES */}
              {selfAttestedAttributeKey.length > 0 ?
                <li className="remove-underline">
                  <div className="row ml-3">
                    <div className="col-sm-12">
                      <label className="nb-label">{t("screens:createNewtask.additionalAttributes")}:</label>
                      <ul>
                        {this.createSelfAttestedAttribute()}
                      </ul>
                    </div>
                  </div>
                </li>
                : ''
              }


              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group mt-5">
                    <button className="btn btn-block btn-nb-outline-primary rounded-pill"
                      onClick={(e) => this.addSelfAttestedAttribute(e)}
                    >{t("screens:createNewtask.addSelfAttribute")}</button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 mt-3">
                  <div className='text-danger mb-2'>{!isValidProofRequest ? t("screens:createNewtask.imageErrorMessage") : ''}</div>
                  <button type="button" className="btn btn-block btn-nb-outline-primary rounded-pill"
                    onClick={(e) => this.sendInstantTask(e)}
                    disabled={
                      _.isEmpty(clickedConnection)
                        ? true
                        : _.isEmpty(policyOptions[0]?.selectedCredential)
                          ? true
                          : _.isEmpty(taskData)
                            ? true
                            : selfAttestedAttributeKey.findIndex((x: any) => x === '' || !x) !== -1
                              ? true
                              : policyOptions.findIndex((x: any) => Object.keys(x.selectedCredential).length === 0) !== -1
                                ? true
                                : false
                    }
                  >{t("screens:createNewtask.send")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
