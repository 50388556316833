import { createWalletConst } from "../../types";


const initialState = {
    createdWallet:{},
    errorAgentSpinup: false,
}

export function CreateWalletReducer(state = initialState, action: any) {
  switch (action.type) {
    case createWalletConst.CREATE_WALLET:
      return Object.assign({},
        state,
        {
          createdWallet: action.payload.data.data,
          errorAgentSpinup: false
        });
    case createWalletConst.ERROR_AGENT_SPINUP:
      return Object.assign({},
        state, 
        {
          errorAgentSpinup: true
        })
    default:
      return state
  }
}

