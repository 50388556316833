import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { presentProofRequest } from "../../commonConst";
import { presentProofAction } from "../../PresentProof/action/presentProofAction";

// Utilities
import images from "src/utils/images";
import { t } from 'i18next';

class SendProofSuccess extends React.Component {

    /* UI to show the success message after send proof presentation */
    public render() {
        return (
            <>
                <div className="col-sm-12">

                    <div className="text-center">
                        <div className="mb-5 mt-5">
                            <img src={images.illustrationSuccess} alt="Success" />
                        </div>
                        <div>
                            <h3 className="nb-label">{t("screens:presentProof.sendProofSuccessfully")}</h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentProofReducer = state.PresentProofReducer;

    return { PresentProofReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentProofAction: bindActionCreators(presentProofAction, dispatch),
    }
}

const sendProofSuccess = connect(mapStateToProps, mapDispatchToProps)(SendProofSuccess);
export { sendProofSuccess as SendProofSuccess };
