import { t } from "i18next";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NegotiateCredentialAction } from "src/View/CredentialNegotiate/actions/NegotiateCredentialAction";
import { loginAction } from "src/View/Login/actions/loginAction";
import { schemaAction } from "src/View/Schema/actions/schemaAction";
import { CredentialDetails } from "../components/CredentialDetails";
import { DeclinedCredentialList } from "../components/DeclinedCredentialList";

interface IDeclinedCredentialsProps {
    LoginReducer: any,
    NegotiateCredentialReducer: any,
    NegotiateCredentialAction: any,
    schemaAction: any
}

interface IDeclinedCredentialsState {

}

class DeclinedCredentials extends React.Component<IDeclinedCredentialsProps, IDeclinedCredentialsState> {
    constructor(props: IDeclinedCredentialsProps) {
        super(props);

        this.state = {}

    }

    render(): React.ReactNode {
        return (
            <>
                <div className="row gutters-sm min-h-100">
                    <div className="col-md-12 col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:declinedCredentials.declinedCredentialsList")}</h1>
                            </div>
                            <div className="card-body">
                                {/* Component for get credential request list. */}
                                <DeclinedCredentialList {...this.props} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            {/* Component for show specific credential details */}
                            <CredentialDetails {...this.props} />
                        </div>
                    </div>

                </div>
            </>
        )
    }

}

function mapStateToProps(state: any) {
    const LoginReducer = state.LoginReducer;
    const NegotiateCredentialReducer = state.NegotiateCredentialReducer
    return {
        LoginReducer, NegotiateCredentialReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        NegotiateCredentialAction: bindActionCreators(NegotiateCredentialAction, dispatch),
        loginAction: bindActionCreators(loginAction, dispatch),
        schemaAction: bindActionCreators(schemaAction, dispatch),
    }
}

const declinedCredentials = connect(mapStateToProps, mapDispatchToProps)(DeclinedCredentials);
export { declinedCredentials as DeclinedCredentials };
