
import { Component } from 'react'
import { BrowserRouter, Router, Switch, Route, Redirect } from 'react-router-dom';
import { ConnectionComponent } from './View/Connection/container/Connection';
import { connect } from 'react-redux';
import { history } from './index'
import { loginAction } from './View/Login/actions/loginAction'
import { bindActionCreators } from 'redux';
import { connectionAction } from './View/Connection/actions/connectionAction';
import Layout from './Common Components/DefaultLayout/Layout';
import { Employee } from './View/Holder/container/Employee';
import { Client } from './View/Holder/container/Client';
import { CredentialComponent } from './View/Credentials/container/Credentials';
import { CredentialSchemaDefComponent } from './View/Credentials/container/CredentialsSchemaDef';
import { NewOrganization } from './View/Onboarding/Components/NewOrganization';
import { RegisterOrganization } from './View/Onboarding/Components/RegisterOrganization';
import { OrganizationList } from './View/Onboarding/Components/OrganizationList';
import { OrganisationDetails } from './View/Onboarding/Components/OrganisationDetails';
import { IssueCredentials } from './View/IssueCredential/container/IssueCredentials';
import { EmailTemplates } from './View/Settings/EmailTemplates/container/EmailTemplates';
import { PlatformConfig } from './View/Platform-Config/container/Platform-config';
import { OnLedger } from './View/Ledger/container/Onledger';
import { LoginMonitoring } from './View/LoginMonitoring/container/LoginMonitoring';
import { AgentStatus } from './View/AgentsStatus/container/AgentStatus';
import { Workflow } from './View/Verification-Workflow/container/workflow';
import PendingRequest from './View/Register/components/PendingRequest';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { EmptyCart } from './Common Components/Components/EmptyCart';
import RequestFailed from './View/Register/components/RequestFailed';
import { Profile } from './View/Settings/ProfileSetting/container/Profile';
import { Task } from './View/Task/container/Task';
import { IssuerVerifier } from './View/IssuersVerifiers/container/IssuerVerifier';
import { InternalIssuersVerifiers } from './View/IssuersVerifiers/container/InternalIssuersVerifiers';
import { IssuerDashboard } from './View/Dashboard/components/IssuerDashboard';
import { VerifierDashboard } from './View/Dashboard/components/VerifierDashboard';
import { OrgAdminDashboard } from './View/Dashboard/components/OrgAdminDashboard';
import { BothDashboard } from './View/Dashboard/components/BothDashboard';
import { PlatformAdminDashboard } from './View/Dashboard/components/PlatformAdminDashboard';
import { PAConnectionComponent } from './View/Connection/container/PAConnection';
import { PACredentialComponent } from './View/Credentials/container/PACredential';
import { PADefaultSchema } from './View/Schema/components/PADefaultSchema';
import { GovRuleComponent } from './View/Governance/container/GovRule';
import { AcceptCredentials } from './View/MyCredentials/container/MyCredentials';
import { Organization } from './View/Onboarding/container/ConnectNewOrganization';
import { PresentProof } from './View/PresentProof/container/PresentProof';
import { ImportCredentials } from './View/ClaimCredential/container/ImportCredentials';
import { Chats } from './View/Chats/container/chats';
import { Admin } from './View/Admin/container/Admin';
import AcceptInvite from './View/Onboarding/container/AcceptInvite';
import { PresentationProposal } from './View/PresentationProposal/container/PresentationProposal'
import { VerificationMonitoring } from './View/VerificationMonitoring/container/VerificationMonitoring';
import { ConnectionDeletedComponent } from './View/Connection/container/ConnectionDeleted';
import { t } from 'i18next';
import { CredentialProposal } from './View/CredentialProposal/container/CredentialProposal';
import { NegotiateProposal } from './View/IssueCredential/components/NegotiateProposal';
import { Notification } from './View/Notification/container/Notification';
import { notificationAction } from './View/Notification/actions/notificationAction';
import { NegotiateCredential } from './View/CredentialNegotiate/container/NegotiateCredential';
import { RevokeCredential } from './View/RevokeCredential/container/RevokeCredential';
import { DeclinedCredentials } from './View/DeclinedCredentials/container/DeclinedCredentials';
import { OnboardingComponent } from './View/Connection/container/Onboarging';
import { CredentialOffer } from './View/CredentialOffer/container/CredentialOffer';
import { PresentationRequest } from './View/PresentationRequest/container/PresentationRequest';
import { PresentationNegotiate } from './View/PresentationNegotiate/container/PresentationNegotiate';
import { PresentationDecline } from './View/PresentationDecline/container/PresentationDecline';
import { socket, SocketContext } from 'src/contexts/SocketContext';
import { Multiuse } from './View/Multiuse/container/Multiuse';


interface Props {
  connectionAction: any,
  loginAction: any,
  updateConnectionList: Function,
  LoaderReducer: any,
  LoginReducer: any,
  ConnectionReducer: any,
  NotificationReducer: any,
  notificationAction: any,
  getCountNotification: Function | any
}

interface State {
}


export class App extends Component<Props, State> {
  static contextType?= SocketContext

  constructor(props: Props) {
    super(props);
    this.state = {
    }

  }

  render() {
     const { loggedInUser } = this.props.LoginReducer;
    const { connectionList } = this.props.ConnectionReducer;
    let runningRole: number = 0
    if (localStorage.getItem('token')) {
      if (!_.isEmpty(loggedInUser) && loggedInUser.role.id === 1) {
        runningRole = 1;
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.role.id === 2) {
        runningRole = 2;
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.role.id === 3) {
        runningRole = 3;
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.role.id === 4) {
        runningRole = 4;
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.role.id === 5) {
        runningRole = 5;
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.role.id === 6) {
        runningRole = 6;
      } else if (!_.isEmpty(loggedInUser) && loggedInUser.role.id === 7) {
        runningRole = 7;
      } else {
        console.log("no role found for routing");
      }
    }

    let userLength = localStorage.length;
    
    socket.on('notification', (socketId: any, payload: { data: { type: string; record: any; }; }) => {
      //  console.log(`SOCKET App - notification - socketId: ${socketId} payload: ${JSON.stringify(payload)}`);

      if (payload && payload.data) {
        if (payload.data.type === 'Connection') {
          // console.log(`SOCKET App receive Notification - Connection`)
          this.props.connectionAction.updateConnectionList(payload.data.record, connectionList);
        } else if (payload.data.type === 'Credential') {
          // console.log(`SOCKET App receive Notification - Credential`)
          this.props.connectionAction.updateReceivedCredentialList(payload.data.record, connectionList)
        }
      }
    })

    if (!_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.id !== 1 && loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isOnBoarded === 1) {
      return (
        <Route exact path="/pending-state" component={PendingRequest} />
      )
    } else if (!_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.id !== 1 && loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isOnBoarded === 0) {
      toastr.message(`${t("screens:app.toastMsg1")}`, `${t("screens:app.toastMsg2")}`)
    } else if (!_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.id !== 1 && loggedInUser.organization.agentSpinupStatus === 0 && loggedInUser.organization.isOnBoarded === 3) {
      return (
        <Route exact path="/request-failed" component={RequestFailed} />
      )
    } else {
      return (
      //  <SocketProvider>
        <Layout  {...this.props}>
          <BrowserRouter>
            <Router history={history}>
              <Switch>
                {
                  userLength === 0 ?
                    <Redirect from="*" to="/" />
                    : null
                }
                {
                  // Routes for Platform Admin
                  runningRole === 1 && !_.isEmpty(loggedInUser) && loggedInUser.organization.isDashboard === true &&
                  <>
                    <Route exact path="/platformAdmin-dashboard" component={PlatformAdminDashboard} />
                    <Route exact path="/platformAdmin-connection" component={PAConnectionComponent} />
                    <Route exact path="/platformAdmin-cred-def" component={PACredentialComponent} />
                    <Route exact path="/platform-config" component={PlatformConfig} />
                    <Route exact path="/agent-status" component={AgentStatus} />
                    <Route exact path="/onboard-organization" component={NewOrganization} />
                    <Route exact path="/organization-list" component={OrganizationList} />
                    <Route exact path="/on-ledger" component={OnLedger} />
                    <Route exact path='/register-organization' component={RegisterOrganization} />
                    <Route exact path="/platformAdmin-schema" component={PADefaultSchema} />
                    <Route exact path="/create-schema-credential-def" component={CredentialSchemaDefComponent} />
                    <Route exact path="/login-monitoring" component={LoginMonitoring} />
                    <Route exact path="/organization-details" component={OrganisationDetails} />
                    <Route exact path="/email-templates" component={EmailTemplates} />
                    <Route exact path="*" component={EmptyCart} />
                  </>
                }
                {
                  // Routes for Org Admin
                  (runningRole === 2) && !_.isEmpty(loggedInUser) && loggedInUser.organization.isDashboard === true &&
                  <>
                    {/* common */}
                    <Route exact path="/orgAdmin-dashboard" component={OrgAdminDashboard} />
                    <Route exact path="/connections" component={ConnectionComponent} />
                    <Route exact path="/profile-settings" component={Profile} />
                    <Route exact path="/create-connection" component={OnboardingComponent} />
                    <Route exact path="/add-employee" component={Employee} />
                    <Route exact path="/add-admin" component={Admin} />
                    <Route exact path="/add-client" component={Client} />
                    <Route exact path="/add-organization" component={Organization} />
                    <Route exact path="/add-multiuse" component={Multiuse} />
                    <Route exact path="/accept-invite" component={AcceptInvite} />
                    <Route exact path="/deleted-connections" component={ConnectionDeletedComponent} />
                    <Route exact path="/organization-credentials/wallet" component={AcceptCredentials} />
                    <Route exact path="/chats" component={Chats} />
                    <Route exact path="/notification" component={Notification} />
                    {/* Issuer */}
                    {/* <Route exact path="/add-issuer-verifier" component={IssuerVerifier} /> */}
                    <Route exact path="/employee" component={IssuerVerifier} />
                    <Route exact path="/existing-issuers-verifiers" component={InternalIssuersVerifiers} />
                    <Route exact path="/configuration/schema-cred" component={CredentialComponent} />
                    <Route exact path="/organization-credentials/request" component={CredentialProposal} />
                    <Route exact path="/credential-issuance/offer" component={CredentialOffer} />
                    <Route exact path="/credential-issuance/revoke" component={RevokeCredential} />
                    <Route exact path="/credential-issuance/negotiate" component={NegotiateCredential} />
                    <Route exact path="/credential-issuance/completed" component={DeclinedCredentials} />
                    {/* <Route exact path="/internal-credentials-list" component={InternalCredentialList} /> */}
                    <Route exact path="/import-credentials" component={ImportCredentials} />
                    {/* Verifier */}
                    <Route exact path="/proofs/scheduled" component={Workflow} />
                    <Route exact path="/organization-credentials/share" component={PresentationProposal} />
                    <Route exact path="/credential-verification/request" component={PresentationRequest} />
                    <Route exact path="/credential-verification/negotiate" component={PresentationNegotiate} />
                    <Route exact path="/credential-verification/completed" component={PresentationDecline} />
                    <Route exact path="/rule-list" component={GovRuleComponent} />
                    <Route exact path="*" component={EmptyCart} />
                  </>
                }
                {
                  // Routes for Issuer User
                  runningRole === 3 && !_.isEmpty(loggedInUser) && loggedInUser.organization.isDashboard === true &&
                  <>
                    <Route exact path="/issuer-dashboard" component={IssuerDashboard} />
                    {/* <Route exact path="/internal-credentials-list" component={InternalCredentialList} /> */}
                    <Route exact path="/existing-issuers-verifiers" component={InternalIssuersVerifiers} />
                    <Route exact path="/configuration/schema-cred" component={CredentialComponent} />
                    <Route exact path="/credential-issuance/offer" component={IssueCredentials} />
                    <Route exact path="/organization-credentials/request" component={CredentialProposal} />
                    <Route exact path="/credential-issuance/negotiate" component={NegotiateProposal} />
                    <Route exact path="/profile-settings" component={Profile} />
                    <Route exact path="/connections" component={ConnectionComponent} />
                    <Route exact path="*" component={EmptyCart} />
                    <Route exact path="/organization-credentials/wallet" component={AcceptCredentials} />
                    <Route exact path="/deleted-connections" component={ConnectionDeletedComponent} />
                    <Route exact path="/notification" component={Notification} />
                    <Route exact path="/chats" component={Chats} />
                  </>

                }
                {
                  // Routes for Verifier User
                  runningRole === 4 && !_.isEmpty(loggedInUser) && loggedInUser.organization.isDashboard === true &&
                  <>
                    <Route exact path="/verifier-dashboard" component={VerifierDashboard} />
                    <Route exact path="/existing-issuers-verifiers" component={InternalIssuersVerifiers} />
                    <Route exact path="/presentation-proposal" component={PresentationProposal} />
                    <Route exact path="/verification-workflow" component={Workflow} />
                    <Route exact path="/task-type" component={Task} />
                    <Route exact path="/verification-monitoring" component={VerificationMonitoring} />
                    <Route exact path="/profile-settings" component={Profile} />
                    <Route exact path="/present-proof" component={PresentProof} />
                    <Route exact path="/connections" component={ConnectionComponent} />
                    <Route exact path="*" component={EmptyCart} />
                    <Route exact path="/deleted-connections" component={ConnectionDeletedComponent} />
                    <Route exact path="/notification" component={Notification} />
                    <Route exact path="/chats" component={Chats} />
                  </>

                }
                {
                  // Routes for Both (Issuer and Verifier)
                  runningRole === 5 && !_.isEmpty(loggedInUser) && loggedInUser.organization.isDashboard === true &&
                  <>
                    <Route exact path="/both-dashboard" component={BothDashboard} />
                    {/* <Route exact path="/internal-credentials-list" component={InternalCredentialList} /> */}
                    <Route exact path="/existing-users" component={InternalIssuersVerifiers} />
                    <Route exact path="/configuration/schema-cred" component={CredentialComponent} />
                    <Route exact path="/credential-issuance/offer" component={IssueCredentials} />
                    <Route exact path="/credential-issuance/request" component={CredentialProposal} />
                    <Route exact path="/credential-issuance/negotiate" component={NegotiateProposal} />
                    <Route exact path="/profile-settings" component={Profile} />
                    <Route exact path="/connections" component={ConnectionComponent} />
                    <Route exact path="/verifier-dashboard" component={VerifierDashboard} />
                    <Route exact path="/presentation-proposal" component={PresentationProposal} />
                    <Route exact path="/verification-workflow" component={Workflow} />
                    <Route exact path="/task-type" component={Task} />
                    <Route exact path="/verification-monitoring" component={VerificationMonitoring} />
                    <Route exact path="/present-proof" component={PresentProof} />
                    <Route exact path="/organization-credentials/wallet" component={AcceptCredentials} />
                    <Route exact path="/deleted-connections" component={ConnectionDeletedComponent} />
                    <Route exact path="/notification" component={Notification} />
                    <Route exact path="/chats" component={Chats} />
                    <Route exact path="*" component={EmptyCart} />
                  </>
                }
              </Switch>
            </Router>
          </BrowserRouter>
        </Layout>
        // </SocketProvider>
      )
    }

  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  const ConnectionReducer = state.ConnectionReducer;
  const LoaderReducer = state.LoaderReducer;
  const NotificationReducer = state.NotificationReducer;

  return { ConnectionReducer, LoaderReducer, LoginReducer, NotificationReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
    connectionAction: bindActionCreators(connectionAction, dispatch),
    notificationAction: bindActionCreators(notificationAction, dispatch),
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(App);
