import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';

// Components
import CropImageModal from '../../../Register/components/cropImageModal';

// Utilities
import { LANGUAGES, Locales, setLocalization } from 'src/localization';
import images from 'src/utils/images';
import { t } from 'i18next';
import { DIDDocEntries } from './DIDDocEntries';
import { DNSEntries } from './DNSEntries';

export interface IOrgProfileProps extends WithTranslation<["common", "screens"]> {
  LoginReducer: any,
  OrgProfileAction: any,
  orgProfileReducer: any,
  SchemaReducer: any,
  schemaAction: any,
  loginAction: any,
}

export interface IOrgProfileState {
  formData: any,
  src: any,
  crop: any,
  isModalShow: boolean,
  croppedImageUrl: any,
  showCroppedImage: boolean,
  croppedImage: any,
  isEditProfile: boolean,
  errors: any,
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  schemaSortBy: string,
  showIssuerCretedList: boolean,
  showshowCredDefCreatedCreatedList: boolean,
  showCredentialIssuedList: boolean,
  showProfileInfo: boolean,
  dnsEntries: any,
  didDetails: any,
  dnsName: string,
  ownerName: string,
  publicDID: string,
  publicDnsEntries: any,
  showDIDDocEntries: boolean,
  showDNSEntries: boolean
}

class OrgProfile extends React.Component<IOrgProfileProps, IOrgProfileState> {
  private imageRef: any;
  private fileUrl: any;

  constructor(props: IOrgProfileProps) {
    super(props);

    const { i18n } = props;

    const language = LANGUAGES.find((l) => l.code === i18n.language);

    this.state = {
      formData: {
        language: language?.code,
      },
      src: null,
      crop: {
        unit: '%',
        width: 30,
        height: 30,
        x: 25,
        y: 25,
        // aspect: 16 / 9,
      },
      croppedImageUrl: "",
      showCroppedImage: false,
      croppedImage: "",
      isModalShow: false,
      isEditProfile: false,
      errors: {},
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      schemaSortBy: '',
      showIssuerCretedList: true,
      showshowCredDefCreatedCreatedList: false,
      showCredentialIssuedList: false,
      showProfileInfo: true,
      dnsEntries: [],
      didDetails: {},
      dnsName: '',
      ownerName: '',
      publicDID: '',
      publicDnsEntries: [],
      showDIDDocEntries: false,
      showDNSEntries: false
    }

    this.onSelectFile = this.onSelectFile.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.makeClientCrop = this.makeClientCrop.bind(this);
    this.getCroppedImg = this.getCroppedImg.bind(this);
    this.save = this.save.bind(this);
    this.dataURLtoFile = this.dataURLtoFile.bind(this);
    this.clearCroppedImage = this.clearCroppedImage.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sorting = this.sorting.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.cancel = this.cancel.bind(this);
    this.showIssuerCreated = this.showIssuerCreated.bind(this);
    this.showCredDefCreated = this.showCredDefCreated.bind(this);
    this.handleProfileInfoSection = this.handleProfileInfoSection.bind(this)
    this.handleDIDEntriesSection = this.handleDIDEntriesSection.bind(this)
    this.handleDNSEntriesSection = this.handleDNSEntriesSection.bind(this)
    this.handleDidDocReload = this.handleDidDocReload.bind(this)
    this.handleDnsEntriesReload = this.handleDnsEntriesReload.bind(this)
    // this.showCredentialIssued = this.showCredentialIssued.bind(this);
  }

  componentDidMount(): void {
    const { did } = this.props.LoginReducer.loggedInUser.organization

    this.props.OrgProfileAction.getDIDDoc(did)

    const { formData } = this.state
    const { loggedInUser } = this.props.LoginReducer;

    formData["connectionId"] = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.did;
    formData["role"] = !_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.id;
    formData["employeeId"] = !_.isEmpty(loggedInUser) && loggedInUser.id;

    formData["dnsName"] = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.dnsName;
    formData["trustRegistryUrl"] = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.trustRegistryUrl;

    this.setState({
      formData
    }, () => {
      if (this.state.formData.dnsName !== null && this.state.formData.dnsName !== '')
        this.props.OrgProfileAction.getDNSEntries(this.props.LoginReducer.loggedInUser.organization.did)
    })
  }

  componentDidUpdate(prevProps: Readonly<IOrgProfileProps>, prevState: Readonly<IOrgProfileState>, snapshot?: any): void {

    if (JSON.stringify(prevProps.orgProfileReducer.didDetails) !== JSON.stringify(prevState.didDetails)) {
      if (this.props.orgProfileReducer?.didDetails?.metadata?.trust_anchor
        && this.props.orgProfileReducer?.didDetails?.metadata?.owner_name
      ) {
        let { dnsName, publicDID, ownerName } = this.state
        dnsName = this.props.orgProfileReducer.didDetails.metadata.trust_anchor
        ownerName = this.props.orgProfileReducer.didDetails.metadata.owner_name
        publicDID = this.props.orgProfileReducer.didDetails.did_document.id
        this.setState({
          dnsName,
          ownerName,
          publicDID,
          didDetails: this.props.orgProfileReducer.didDetails
        }, () => {
          if (prevProps.orgProfileReducer.dnsEntries !== this.props.orgProfileReducer.dnsEntries
            || this.props.orgProfileReducer.dnsEntries !== this.state.dnsEntries) {
            this.props.OrgProfileAction.getDNSEntries(this.props.LoginReducer.loggedInUser.organization.did)
          }
        })
      }
    }

    if (prevProps.orgProfileReducer.dnsEntries !== this.props.orgProfileReducer.dnsEntries) {
      this.setState({
        dnsEntries: this.props.orgProfileReducer.dnsEntries
      }, () => {
        this.props.OrgProfileAction.getPublicDNSEntries(this.props.LoginReducer.loggedInUser.organization.did)
      })
    }

    if (prevProps.orgProfileReducer.publicDnsEntries !== this.props.orgProfileReducer.publicDnsEntries) {
      this.setState({
        publicDnsEntries: this.props.orgProfileReducer.publicDnsEntries
      })
    }
  }

  onSelectFile(event: React.ChangeEvent<HTMLInputElement> | any) {
    var fileName = event.target.value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      if (event.target.files && event.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.setState({ src: reader.result, isModalShow: true })
        }
        );
        reader.readAsDataURL(event.target.files[0]);
      }
      event.target.value = null;
    } else {
      // toastr.info(`Only jpg/jpeg and png files are allowed!`, ``);
      toastr.info(`${t("screens:profile.toastMsgImage")}`, ``);
    }

  };

  clearCroppedImage() {
    const { loggedInUser } = this.props.LoginReducer;
    this.setState({
      croppedImageUrl: !_.isEmpty(loggedInUser) && loggedInUser.organization && (loggedInUser.organization.logoUrl),
      croppedImage: '',
      showCroppedImage: false
    })
  }

  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage })
  }

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          this.dataURLtoFile(reader.result, 'cropped.jpg')
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  save() {
    this.setState({ showCroppedImage: true, isModalShow: false, src: null })
  }

  cancel() {
    this.setState({ isModalShow: false })
  }

  editProfile() {

    const { formData } = this.state
    const { loggedInUser } = this.props.LoginReducer;

    formData["connectionId"] = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.did;
    formData["role"] = !_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.id;
    formData["employeeId"] = !_.isEmpty(loggedInUser) && loggedInUser.id;

    formData["dnsName"] = formData['dnsName'] ? formData['dnsName'] : !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.dnsName;
    formData["trustRegistryUrl"] = formData['trustRegistryUrl'] ? formData['trustRegistryUrl'] : !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.trustRegistryUrl;

    this.setState({
      isEditProfile: true,
      formData
    })

  }


  handleChange(event: any) {
    const { formData } = this.state;
    const { loggedInUser } = this.props.LoginReducer;

    if (!formData.department && !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.orgCategory) {
      formData["department"] = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.orgCategory;
    }

    if (!formData.position && !_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.name) {
      formData["position"] = !_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.name;
    }

    if (!formData.logoUrl && !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.logoUrl) {
      formData["logoUrl"] = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.logoUrl;
    }

    formData[event.target.name] = event.target.value;

    formData["connectionId"] = !_.isEmpty(loggedInUser) && loggedInUser.organization && loggedInUser.organization.did;
    formData["role"] = !_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.id;
    formData["employeeId"] = !_.isEmpty(loggedInUser) && loggedInUser.id;

    this.setState({
      formData: formData
    })
    // this.validateForm();
  }

  /** Handle changing language */
  handleLanguageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value) return;

    this.setState({
      formData: {
        ...this.state.formData,
        language: value,
      }
    })

    // TODO: Temporarily update localization immediately upon selection UNTIL profile API supports updating!
    setLocalization(value as Locales);
  }

  updateProfile() {
    const { croppedImage, formData } = this.state;
    const fieldData = new FormData()

    if (croppedImage) {
      fieldData.append('profileLogo', croppedImage)
    }
    if (formData.dnsName) {
      fieldData.append('dnsName', formData.dnsName)
    }
    if (formData.trustRegistryUrl) {
      fieldData.append('trustRegistryUrl', formData.trustRegistryUrl)
    }
    this.props.OrgProfileAction.updateProfile(fieldData);

    // TODO Image `url(${croppedImageUrl})`
    this.setState({
      isEditProfile: false,
      showCroppedImage: true,
    })
    const { did } = this.props.LoginReducer.loggedInUser.organization

    this.props.OrgProfileAction.getDIDDoc(did)

  }

  handleDidDocReload() {
    this.props.OrgProfileAction.getDIDDoc(this.props.LoginReducer.loggedInUser.organization.did)
  }

  handleDnsEntriesReload() {
    this.props.OrgProfileAction.getDNSEntries(this.props.LoginReducer.loggedInUser.organization.did)
  }

  validateForm() {

    let formData: any = this.state.formData;
    let errors: any = {};
    let formIsValid: any = true;

    if (!formData["department"]) {
      formIsValid = false;
      // errors["department"] = "*Please enter department.";
      errors["department"] = `${t("screens:profile.messageErrorDepartment")}`;

    }

    if (!formData["position"]) {
      formIsValid = false;
      // errors["position"] = "*Please enter position.";
      errors["position"] = `${t("screens:profile.messageErrorPosition")}`;
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, schemaSortBy } = this.state;
    this.props.schemaAction.getAllSchema(defaultSearchFields, item_perPage, page, 'schemas', sortingOrder, schemaSortBy);
  }

  sorting = (schemaSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', schemaSortBy: schemaSortBy }) : this.setState({ sortingOrder: 'DESC', schemaSortBy: schemaSortBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.schemaAction.getAllSchema(defaultSearchFields, item_perPage, pageNo, 'schemas', sortValue, schemaSortBy);
  }

  showDetails(selecteditem: any) {
    console.log("selectedItem", selecteditem)
    // this.props.schemaAction.selectSchema(schema, 'credDef')
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement> | any) => {
    const { pageNo, item_perPage, sortingOrder, schemaSortBy } = this.state
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.schemaAction.getAllSchema(event.target.value, item_perPage, pageNo, 'schemas', sortingOrder, schemaSortBy);
    }
  }

  showIssuerCreated() {
    this.setState({
      showIssuerCretedList: true,
      showshowCredDefCreatedCreatedList: false,
      showCredentialIssuedList: false,
    })

  }

  showCredDefCreated() {
    this.setState({
      showIssuerCretedList: false,
      showshowCredDefCreatedCreatedList: true,
      showCredentialIssuedList: false,
    })
  }

  showCredentialIssued() {
    this.setState({
      showIssuerCretedList: false,
      showshowCredDefCreatedCreatedList: false,
      showCredentialIssuedList: true,
    })
  }

  handleProfileInfoSection() {
    this.setState({
      showProfileInfo: true,
      showDIDDocEntries: false,
      showDNSEntries: false
    })
  }

  handleDIDEntriesSection() {
    this.setState({
      showProfileInfo: false,
      showDIDDocEntries: true,
      showDNSEntries: false
    })
  }

  handleDNSEntriesSection() {
    this.setState({
      showProfileInfo: false,
      showDIDDocEntries: false,
      showDNSEntries: true
    })
  }

  public render() {
    const { t } = this.props;
    const { loggedInUser } = this.props.LoginReducer;
    const { crop, croppedImageUrl, src, isModalShow, showCroppedImage, isEditProfile, formData,
      showProfileInfo, dnsEntries, publicDID, ownerName, dnsName, publicDnsEntries,
      showDIDDocEntries, showDNSEntries
    } = this.state;
    // const disableFields = _.isEmpty(loggedInUser);
    const { profileData } = this.props.orgProfileReducer;


    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-sm-12 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:profile.profileSetting")}</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className='col-sm-3'>
                    <div className='col-sm-12'>
                      <label className='nb-label form-group' >{t("screens:profile.orgSetting")}</label>
                    </div>
                    <div className='col-sm-12'>
                      <button
                        onClick={this.handleProfileInfoSection}
                        className={`${showProfileInfo ? 'text-info' : 'text-muted'} form-group`}
                      >
                        <i className='fa fa-user'></i>&nbsp;
                        {t("screens:profile.profileInformation")}
                      </button>
                    </div>
                    <div className='col-sm-12'>
                      <button
                        onClick={this.handleDIDEntriesSection}
                        className={`${showDIDDocEntries ? 'text-info' : 'text-muted'} form-group`}
                      >
                        <i className='fa fa-th'></i>&nbsp;
                        {t("screens:profile.didEntriesSection")}
                      </button>
                    </div>
                    <div className='col-sm-12'>
                      <button
                        onClick={this.handleDNSEntriesSection}
                        className={`${showDNSEntries ? 'text-info' : 'text-muted'} form-group`}
                      >
                        <i className='fa fa-globe'></i>&nbsp;
                        {t("screens:profile.dnsEntriesSection")}
                      </button>
                    </div>

                  </div>
                  <div className='col-sm-9'>
                    {
                      showProfileInfo
                      &&
                      <div className="col-md-12 mb-30">
                        <div className="card nb-card h-100">
                          <div className="card-header border-bottom-0" style={{ backgroundColor: '#F0F5FC' }}>
                            <h1 >{t("screens:profile.profileInformation")}</h1>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-4">
                                {croppedImageUrl && showCroppedImage && (
                                  <div className="card nb-card-image" style={{ backgroundImage: `url(${croppedImageUrl})` }}>
                                    <div className="card-body"></div>
                                  </div>
                                )}
                                {
                                  showCroppedImage === false ?
                                    <div className="card nb-card-image" style={{ backgroundImage: `url(${!_.isEmpty(loggedInUser) && (loggedInUser.organization.logoUrl !== null || loggedInUser.organization.logoUrl !== undefined) ? loggedInUser.organization.logoUrl : !_.isEmpty(loggedInUser) && loggedInUser.organization.logoUrl !== null ? loggedInUser.organization.logoUrl : images.placeholderRectangleClear})` }}>
                                      <div className="card-body"></div>
                                    </div>
                                    : null
                                }
                              </div>
                              {console.log('croppedImageUrl ===>', croppedImageUrl)}
                              <div className="col-md-8">
                                <h5 className="mt-0">{!_.isEmpty(loggedInUser) && loggedInUser.organization.displayName}</h5>
                                <small className='text-muted'>{t("screens:profile.orgNameNote")}</small>
                                <div className="row mt-3">
                                  <div className="col-sm-6">
                                    {isEditProfile && showCroppedImage === false ?
                                      <div>
                                        <div className="upload-btn-wrapper">
                                          <button className="btn btn-nb-outline-primary"
                                            disabled={isEditProfile ? false : true}>{t("screens:profile.changeImageButton")}</button>
                                          <input type="file" accept="image/png,image/gif,image/jpeg"
                                            onChange={this.onSelectFile} name="profileLogo" disabled={isEditProfile ? false : true} />
                                        </div>
                                      </div>
                                      : isEditProfile && showCroppedImage === true &&
                                      <div>
                                        <button className="btn btn-sm btn-nb-outline-primary" onClick={this.clearCroppedImage}>
                                          {t("common:choices.cancel")}
                                        </button>
                                      </div>
                                    }
                                  </div>
                                  {isModalShow && <CropImageModal
                                    isModalShow={isModalShow}
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                    save={this.save}
                                    cancel={this.cancel}
                                  />}

                                </div>
                              </div>
                            </div>
                            <div className="row mt-5">
                  
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label className="nb-label">{t("screens:profile.emailLabel")}</label>
                                  <div className="input-group global-search rounded-pill" style={{ backgroundColor: '#f8f9fb' }}>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="basic-addon1"><i className='fa fa-envelope'></i></span>
                                    </div>
                                    <input type="text" className="form-control"
                                      name='email'
                                      value={
                                        !_.isEmpty(loggedInUser) ? loggedInUser.organization.adminEmail : ' '}
                                      readOnly={true}
                                    />
                                  </div>
                                </div>
                              </div>


                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label className="nb-label">{t("screens:profile.subscriptionLabel")}</label>
                                  <div className="input-group global-search rounded-pill" style={{ backgroundColor: '#f8f9fb' }}>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="basic-addon1"><i className='fa fa-shield'></i></span>
                                    </div>
                                    <input type="text" className="form-control"
                                      name='subscription'
                                      value={
                                        !_.isEmpty(loggedInUser) && loggedInUser.organization.subscription.id === 1 ? `${t("screens:profile.subscriptionIssuer")}` : loggedInUser.organization.subscription.id === 2 ? `${t("screens:profile.subscriptionVerifier")}` : loggedInUser.organization.subscription.id === 3 ? `${t("screens:profile.subscriptionBoth")}` : ' '
                                      }
                                      readOnly={true}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </div>
                                  <small className='text-muted'>{t("screens:profile.orgRolesNote")}</small>
                                </div>
                                <div className="text-danger">{this.state.errors.position}</div>
                              </div>

                              <div className='col-sm-6'>
                                <div className="form-group">
                                  <label className="nb-label">{t("screens:profile.domainNameLabel")}</label>
                                  <div className="input-group global-search rounded-pill" style={{ backgroundColor: '#f8f9fb' }}>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="basic-addon1"><i className='fa fa-server'></i></span>
                                    </div>
                                    <input type="text" className="form-control"
                                      name='dnsName'
                                      value={formData.dnsName}
                                      readOnly={!isEditProfile}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </div>

                                </div>
                                <div className="text-danger">{this.state.errors.position}</div>
                              </div>
                              <div className='col-sm-6'>
                                <div className="form-group">
                                  <label className="nb-label">{t("screens:profile.trustRegistryLabel")}</label>
                                  <div className="input-group global-search rounded-pill" style={{ backgroundColor: '#f8f9fb' }}>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="basic-addon1"><i className='fa fa-server'></i></span>
                                    </div>
                                    <input type="text" className="form-control"
                                      name='trustRegistryUrl'
                                      value={formData.trustRegistryUrl}
                                      readOnly={!isEditProfile}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </div>

                                </div>
                                <div className="text-danger">{this.state.errors.position}</div>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label className="nb-label">{t("screens:profile.languageLabel")}</label>
                                  <div className="input-group global-search rounded-pill" style={{ backgroundColor: '#f8f9fb' }}>
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="basic-addon1"><i className='fa fa-language'></i></span>
                                    </div>
                                    <Form.Control as="select" value={formData.language} onChange={this.handleLanguageChange}>
                                      {LANGUAGES.map((language) => (
                                        <option key={language.code} value={language.code}>{language.title}</option>
                                      ))}
                                    </Form.Control>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-12 form-group">
                              </div>
                            
                              <div className='col-sm-6'>

                              </div>
                              <div className="col-md-6 text-right">
                                {!isEditProfile ?
                                  <button type="button" className=" btn btn-nb-outline-primary"
                                    onClick={this.editProfile}
                                  >{t("screens:profile.editProfileButton")}</button>
                                  :
                                  <button type="button" className="btn btn-nb-outline-primary"
                                    onClick={this.updateProfile}
                                    disabled={showCroppedImage === false && !formData.dnsName && !formData.trustRegistryUrl ? true : false}
                                  >{t("common:actions.update")}</button>
                                }
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {
                      showDIDDocEntries
                      && <DIDDocEntries
                        dnsName={dnsName}
                        ownerName={ownerName}
                        publicDID={publicDID ? publicDID : loggedInUser.organization.did}
                        handleDidDocReload={this.handleDidDocReload}
                      />
                    }

                    {
                      showDNSEntries
                      && <DNSEntries
                        ownerName={ownerName}
                        dnsEntries={dnsEntries}
                        publicDnsEntries={publicDnsEntries}
                        handleDnsEntriesReload={this.handleDnsEntriesReload}
                      />
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </>
    );
  }
}

const connectedProfileScreen = withTranslation(["common", "screens"])(OrgProfile);
export default connectedProfileScreen;

