import * as React from 'react';
import Typeahead from '../../../Common Components/Components/TypeaheadComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingAction } from '../actions/onboardingAction';
import TableComponents from '../../../Common Components/Components/TableComponent';
import PageLoader from '../../../Common Components/Components/pageLoader';


export interface IOrganizationListProps {
  onboardingAction: any,
  OnboardingReducer: any,
  LoaderReducer: any,
}

export interface IOrganizationListState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: any,
  organizationSortBy: any,
  filterValue: any,
}

class OrganizationList extends React.Component<IOrganizationListProps, IOrganizationListState> {
  constructor(props: IOrganizationListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      organizationSortBy: '',
      filterValue: 'all'
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sorting = this.sorting.bind(this);
  }

  componentDidMount() {
    let { defaultSearchFields, sortingOrder, organizationSortBy, item_perPage, pageNo, filterValue } = this.state;
    this.props.onboardingAction.getAllOrganizationsRequest(defaultSearchFields, filterValue, sortingOrder, organizationSortBy, item_perPage, pageNo,)
  }

  onChangePage(page: any) {

    const { defaultSearchFields, item_perPage, organizationSortBy, sortingOrder, filterValue } = this.state;
    this.props.onboardingAction.getAllOrganizationsRequest(defaultSearchFields, filterValue, sortingOrder, organizationSortBy, item_perPage, page,)
  }

  showDetails(item: any) {
    this.props.onboardingAction.showDetailSelectedExistingOrganization(item.id);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortingOrder, organizationSortBy, filterValue, pageNo } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.onboardingAction.getAllOrganizationsRequest(event.target.value, filterValue, sortingOrder, organizationSortBy, item_perPage, pageNo,)
    }
  }

  sorting = (connectionSortBy: string) => {
    const { defaultSearchFields, item_perPage, sortingOrder, organizationSortBy, filterValue, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', organizationSortBy: connectionSortBy }) : this.setState({ sortingOrder: 'DESC', organizationSortBy: connectionSortBy });
    this.props.onboardingAction.getAllOrganizationsRequest(defaultSearchFields, filterValue, sortingOrder, organizationSortBy, item_perPage, pageNo,)
  }


  refresh = (event: any) => {
    let { defaultSearchFields, sortingOrder, organizationSortBy, item_perPage, filterValue, pageNo } = this.state;

    this.props.onboardingAction.getAllOrganizationsRequest(defaultSearchFields, filterValue, sortingOrder, organizationSortBy, item_perPage, pageNo,)
  }

  filter = (selectedFilterValue: any) => {
    let { defaultSearchFields, sortingOrder, organizationSortBy, item_perPage, filterValue, pageNo } = this.state;
    this.setState({filterValue:selectedFilterValue})
    this.props.onboardingAction.getAllOrganizationsRequest(defaultSearchFields, selectedFilterValue, sortingOrder, organizationSortBy, item_perPage, pageNo,)
  }

  public render() {
    const { tHead, organizationList, totalOrganization, selectedOrganization } = this.props.OnboardingReducer;
    const { loader } = this.props.LoaderReducer;
    const filterOrganizationList = [{
      'name': 'All',
      'tag' : 'all'
    }, {
      'name': 'Invitation Send',
      'tag' : 'invitationSend'
    }, {
      'name': 'Registered',
      'tag' : 'registered'
    }, {
      'name': 'On Boarded',
      'tag' : 'onBoarded'
    }, {
      'name': 'Rejected',
      'tag' : 'rejected'
    },
    ];
    return (
      <div>
        {
          loader ?
            <PageLoader></PageLoader>
            : null
        }
        <div className="card nb-card-noborder">
          <div className="card-header bg-white">
            <h1>Verify Details</h1>
          </div>
          <div className="card-body">
            {
              <TableComponents
                onChangePage={this.onChangePage}
                showElementDetails={this.showDetails}
                tHead={tHead}
                tableContent={organizationList}
                totalRecords={totalOrganization}
                selectedRecord={selectedOrganization}
                sorting={this.sorting}
                searching={this.onSearch}
                filter={filterOrganizationList}
                onChangeFilter={this.filter}
                refresh={this.refresh}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const OnboardingReducer = state.OnboardingReducer;
  const LoaderReducer = state.LoaderReducer;
  return { OnboardingReducer, LoaderReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    onboardingAction: bindActionCreators(onboardingAction, dispatch),
  }
}

const connectedOrganizationList = connect(mapStateToProps, mapDispatchToProps)(OrganizationList);
export { connectedOrganizationList as OrganizationList };
