import { platformConfig } from "../../types";

const initialState = {
    platformConfigData: {}
}
export function PlatformConfigReducers(state = initialState, action: any) {
    switch (action.type) {
        case platformConfig.GET_PLATFORM_CONFIG_DETAILS:
            return Object.assign({}, state, {
                platformConfigData: action.payload.data.data,
            })
        default:
            return state
    }
}