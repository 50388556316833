import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { govRule, loaderConst } from "../../types";

export const GovRuleAction = {
  getAllRuleList(
    defaultSearchFields: any,
    sortValue: string,
    governanceSortBy: string,
    item_perPage: number,
    pageNo: number,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/governance/get-all-by-orgid?search_text=${defaultSearchFields}&sortValue=${sortValue}&governanceSortBy=${governanceSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(getAllRules => {
            dispatch({
              type: govRule.GET_ALL_RULE_LIST,
              payload: getAllRules,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },
  saveNewRule(
    newRuleDetails: any
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.post(`${config.apiUrl}/governance/create`, newRuleDetails,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(saveNewRule => {
            dispatch({
              type: govRule.SAVE_NEW_RULE,
              payload: saveNewRule,
            })
            dispatch({
              type: govRule.AFTER_SAVE_RULE
            })
            this.getAllRuleList('','DESC','id',10,1);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectGovRule(item: object | any, page: string) {
    return async (dispatch: any) => {
      dispatch({
        type: govRule.SELECTED_GOV_RULE,
        payload: { item, page }
      })
    }
  },

  updatePageNo(pageNo: number) {
    return async (dispatch: any) => {
      dispatch({
        type: govRule.UPDATE_PAGE_NO,
        payload: pageNo
      })
    }
  },

  clearSelectedRule() {
    return async (dispatch: any) => {
      dispatch({
        type: govRule.CLEAR_SELECTED_RULE,
        // payload: 
      })
    }
  },
  openSaveButton() {
    return async (dispatch: any) => {
      dispatch({
        type: govRule.OPEN_SAVE_BUTTON,
        // payload: 
      })
    }
  }
}