type OverrideObject = Record<string, any>;

/**
 * Overlay an object onto another (deep merge)
 *
 * NOTE: Drops all additional keys from overlay object!
 *
 * Source: https://replit.com/@KendallRoth/override-constants
 *
 * @param   baseObj    - Base object (defines valid keys)
 * @param   overlayObj - Overlay object (overrides keys)
 * @param   validator  - Validator function for override values
 * @returns Merged object
 */
export const overrideObjects = <T extends Record<string, any>>(
  baseObj: T,
  overlayObj?: OverrideObject,
  validator?: (value: any) => boolean,
): T => {
  if (!overlayObj) return baseObj;

  return Object.keys(baseObj).reduce((accum, key) => {
    const value = overlayObj[key];

    // Only overwrite if new value passes validation!
    const isValid = typeof validator === 'function' ? validator(value) : true;

    // NOTE: Technically duplicate of 'Object.keys()' but ensures safety...
    const baseHasKey = Boolean(baseObj[key]);

    // Use original value for missing, invalid, or extra overrides
    if (!value || !isValid || !baseHasKey) {
      return accum;
    }
    // Support nested objects (recursion)
    else if (typeof value === 'object' && !Array.isArray(value)) {
      return { ...accum, [key]: overrideObjects(baseObj[key], value) };
    }
    // Overwrite primitive values
    else {
      return { ...accum, [key]: value };
    }
  }, baseObj);
};
