import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Utilities
import { IHelpGuideContext, HelpGuideContext } from "src/contexts";

interface IHelpGuideHookOptions {
  /** Whether modal should be dismissed on screen transition */
  cleanup: boolean;
}

/**
 * Expose help guide managerr context as hook
 *
 * @param   options - Help guide hook options
 * @returns Help guide manager
 */
const useHelpGuide = (options?: IHelpGuideHookOptions): IHelpGuideContext => {
  const helpGuide = useContext(HelpGuideContext);

  const { cleanup: shouldRemoveOnTransition = true } = options ?? {};
  const location = useLocation();

  useEffect(() => {
    if (!shouldRemoveOnTransition) return;

    helpGuide.closeGuide();
    // NOTE: Referencing 'closeNotification' here will cause infinite loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return helpGuide;
};

export { useHelpGuide };
