import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { config } from "../../../../config";
import { loginAction } from "../../../Login/actions/loginAction";
import { loaderConst, orgProfileConstants, settingUpDashboardConst } from "../../../types";
import { t } from 'i18next';

export const OrgProfileAction = {

  updateProfile(finalData: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/admin/profile/setting`, finalData,
          { headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token} ` } })
          .then(response => {
            dispatch({
              type: settingUpDashboardConst.UPDATE_ORG_PROFILE,
              payload: response
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            toastr.success(`${t("screens:profile.toastMsgUpdated")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getAllActivityofUser() {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.get(`${config.apiUrl}/admin/activities`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(activityList => {
            dispatch({
              type: settingUpDashboardConst.GET_ALL_ACTIVITY_COUNT,
              payload: activityList
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            loginAction.handleResponse(error.response, dispatch);

            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getDIDDoc(did: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        const body = {
          did
        }
        return await Axios.post(`${config.apiUrl}/admin/org/get-did-doc`, body,
          { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token} ` } })
          .then(response => {
            dispatch({
              type: orgProfileConstants.SET_DID_DETAILS,
              payload: response
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            // toastr.success(`${t("screens:profile.toastMsgUpdated")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getDNSEntries(did: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/admin/org/get-dns-entries-to-create`, { did },
          { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token} ` } })
          .then(response => {
            dispatch({
              type: orgProfileConstants.SET_DNS_ENTRIES,
              payload: response
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            // toastr.success(`${t("screens:profile.toastMsgUpdated")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

    getPublicDNSEntries(did: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/admin/org/get-public-dns-entries`, { did },
          { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token} ` } })
          .then(response => {
            dispatch({
              type: orgProfileConstants.SET_PUBLIC_DNS_ENTRIES,
              payload: response
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            // toastr.success(`${t("screens:profile.toastMsgUpdated")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  checkDIDAuthentic(did: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/admin/org/check-did-authentic`, { did },
          { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token} ` } })
          .then(response => {
            console.log('did authentic response', response)
            dispatch({
              type: orgProfileConstants.CHECK_DID_AUTHENTIC,
              payload: response
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            // toastr.success(`${t("screens:profile.toastMsgUpdated")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },
  

  getIssuanceAuthorization(did:string, credentialDefinitionId:string){
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await Axios.post(`${config.apiUrl}/admin/org/get-issuance-authorization`, { did, credentialDefinitionId },
          { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token} ` } })
          .then(response => {
            dispatch({
              type: orgProfileConstants.SET_ISSUANCE_AUTHORITY,
              payload: response
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            // toastr.success(`${t("screens:profile.toastMsgUpdated")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  }

}