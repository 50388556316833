import axios from "axios";
import { toastr } from "react-redux-toastr";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, settingUpDashboardConst } from "../../types";
import { history } from '../../../index';

import _ from "lodash";


export const setDIDAction = {

  /**
   * Method used to integrate API to create default cred-defs
   * @param orgId Parameter used to create default cred-defs with specific organization
   * @returns After completing all the API calls then it gets logged out
   */
  settingUpDashboard(orgId: any) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');


        // create cred def for password-less login VC
        return await axios.post(`${config.apiUrl}/admin/create-passwordless-login-cred-defs/${orgId}`, '',
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async loginCredDefs => {

            if (!_.isEmpty(loginCredDefs) && loginCredDefs.data && loginCredDefs.data.isPasswordlessLoginCredDef === true) {
              dispatch({
                type: settingUpDashboardConst.SHOW_MSG_LOGIN_CREDDEF,
              })

              // create cred def for passport KYC
              return await axios.post(`${config.apiUrl}/admin/cred-defs/passport-vc/org/${orgId}`, '',
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(async passportCredDefResponse => {

                  if (!_.isEmpty(passportCredDefResponse) && passportCredDefResponse.data?.isPasswordlessLoginCredDef === true &&
                    passportCredDefResponse.data?.isPassportCredDef === true) {
                    dispatch({
                      type: settingUpDashboardConst.SHOW_MSG_KYC_PASSPORT_CRED_DEF
                    })

                    // create cred def for drivers license KYC
                    return await axios.post(`${config.apiUrl}/admin/cred-defs/drivers-license/org/${orgId}`, '',
                      { headers: { "Authorization": `Bearer ${token}` } })
                      .then(async driversLicenseCredDefResponse => {
                        if (!_.isEmpty(driversLicenseCredDefResponse) &&
                          driversLicenseCredDefResponse.data?.isPasswordlessLoginCredDef === true &&
                          driversLicenseCredDefResponse.data?.isPassportCredDef === true &&
                          driversLicenseCredDefResponse.data?.isDriversLicenseCredDef === true
                        ) {
                          dispatch({
                            type: settingUpDashboardConst.SHOW_MSG_KYC_DRIVERS_LICENSE_CRED_DEF
                          })


                          return await axios.post(`${config.apiUrl}/organization/platform-api-key?org_id=${orgId}`, '',
                            { headers: { "Authorization": `Bearer ${token}` } })
                            .then(async apiKeyResponse => {

                              /**
                               * API integration for send organization details
                               */

                              await axios.post(`${config.apiUrl}/organization/send-organization-details`, '',
                                { headers: { "Authorization": `Bearer ${token}` } })
                                .then(async sendOrganizationDetailsResponse => {
                                  /**
                                   * Method call for connect with platform admin
                                   */
                                  return await axios.post(`${config.apiUrl}/connection/for-organization/with-platform-admin`, '',
                                    { headers: { "Authorization": `Bearer ${token}` } })
                                    .then(async connectionResponse => {
                                      localStorage.clear();
                                      history.push('/success');
                                      dispatch({
                                        type: settingUpDashboardConst.CONNECT_WITH_PLATFORM_ADMIN
                                      })
                                      // Wait for few seconds before redirecting to Dashboard
                                      dispatch({
                                        type: settingUpDashboardConst.DEFAULT_CRED_DEF_CREATED
                                      })
                                      dispatch({
                                        type: loaderConst.LOADER_FALSE
                                      })
                                      toastr.success('Dashboard Created Successfully', ` `);
                                      window.location.reload();
                                    })
                                })
                            })

                        }
                      })
                  }
                })
            }
          })

          .catch(error => {
            console.log("error in setting up dashboard", error.response)
            loginAction.handleResponse(error.response, dispatch);
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },
}
