import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingAction } from '../actions/onboardingAction';
import { history } from '../../../index'
import _ from 'lodash';
// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

/* Interface for Props variables*/
export interface IOrganizationDetailsProps {
    OnboardingReducer: any,
    onboardingAction: any,
    LoginReducer: any,
    defaultSearchFields: string,
    item_perPage: number
    pageNo: number,
}

/* Interface for local states variables*/
export interface IOrganizationDetailsState {
    formData: any,
    errors: any,
    invitationSendSuccess: boolean,
}

class OrganizationConnection extends React.Component<IOrganizationDetailsProps, IOrganizationDetailsState> {
    constructor(props: IOrganizationDetailsProps) {
        super(props);

        /* Initialization of state variables*/
        this.state = {
            formData: {},
            errors: {},
            invitationSendSuccess: false
        }
        /* Binding the method */
        this.handleChange = this.handleChange.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);
        this.inviteNewOrganization = this.inviteNewOrganization.bind(this);
    }


    /**
     * Method for send connection invitation
     */
    sendInvitation() {
        /* destructure for LoginReducer reducer*/
        const { loggedInUser } = this.props.LoginReducer;
        const { organization } = loggedInUser;
        const { formData } = this.state;

        /**
         * Payload for send connection invitation api which include
         * @param fromOrgId : use for current logged in organization id
         * @param toOrgName: name for the organization that will receive the invite
         * @param toOrgEmail: email for the organization that will receive the invite
         */
        const sendInvitationParameter = {
            "fromOrgId": organization.id,
            "toOrgName": formData["name"],
            "toOrgEmail": formData["email"]
        }
        /* Send invitation to organization action method call */
        this.props.onboardingAction.sendInvitationToOrganization("ORGANIZATION", sendInvitationParameter)

        /* Reset send connection invitation related the local state variable */
        this.setState({
            formData: {},
            errors: {},
            invitationSendSuccess: true,
        })
    }

    /**
     * Method to invite new organization
     */
    inviteNewOrganization() {
        this.updateState();
        // this.props.onboardingAction.inviteNewOrganization();
    }

    /**
    * Update the invitationSendSuccess state value.
    */
    updateState() {
        this.setState({
            formData: {},
            errors: {},
            invitationSendSuccess: false
        })
    }
    /**
 * Method to use get event from text field and set the values in state variable
 * @param event Parameter to get event from text field. 
 */
    handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
        let formData = this.state.formData;
        formData[event.target.name] = event.target.value;
        this.setState({
            formData: formData
        });
        this.validateForm()

        if (event.target.name === 'email') {
            this.props.onboardingAction.getOrgEmailId(event.target.value, 'email')
        }
    }

    handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
        event.preventDefault();
        this.setState({
            formData: {},
            errors: {}
        })
        // console.log(`TEST CANCEL - ORG formData: ${JSON.stringify(this.state.formData)} errors: ${JSON.stringify(this.state.errors)}`)

        history.push("/create-connection")
    }

    /**
   * Method to validate the form field.
   * @returns It returns the form validation result
   */
    validateForm() {
        let formData: any = this.state.formData;
        let errors: any = {};
        let formIsValid: any = true;

        if (!formData["name"]) {
            formIsValid = false;
            errors["name"] = `${t("screens:onboardingNewOrganization.messageErrorName")}`;
        }

        if (typeof formData["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData["email"])) {
                formIsValid = false;
                errors["email"] = `${t("screens:onboardingNewOrganization.messageErrorEmail")}`;
            }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    public render() {
        const { formData, errors, invitationSendSuccess } = this.state;
        // const { selectOrganization, showErrorMessage, inviteNewOrganization } = this.props.OnboardingReducer;
        const { selectOrganization, showErrorMessage } = this.props.OnboardingReducer;
        // console.log(`TEST CANCEL - ORG - showErrorMessage:  ${showErrorMessage}`)
        return (
            <div className="card nb-card h-100">
                <div className="card-header bg-white border-bottom-0">
                    <h1 className="nb-title">{t("screens:onboardingNewOrganization.addNewOrganization")}</h1>
                </div>
                <div className="card-body">
                    {!invitationSendSuccess ?
                        <>
                            <div className="mb-5">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="nb-label">{t("screens:onboardingNewOrganization.givenName")}</label>
                                        <input type="text" className="form-control" name="name" value={selectOrganization.name ? selectOrganization.name : formData.name}
                                            // onChange={(e) => this.handleChange(e)} readOnly={selectOrganization.email || invitationSendSuccess ? true : false} />
                                            onChange={(e) => this.handleChange(e)}/>
                                        {
                                            errors.name ?
                                                <div className="text-danger">
                                                    <small><i className="fas fa-info-circle mr-1"></i>{errors.name}</small>
                                                </div> :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="nb-label">{t("screens:acceptInvitation.email")}</label>
                                        <input type="email" className="form-control" name="email" value={selectOrganization.email ? selectOrganization.email : formData.email}
                                            // onChange={(e) => this.handleChange(e)} readOnly={selectOrganization.email || emailSent ? true : false} />
                                            // onChange={(e) => this.handleChange(e)} readOnly={selectOrganization.email || invitationSendSuccess ? true : false} />
                                            onChange={(e) => this.handleChange(e)}/>
                                        {
                                            errors.email ?
                                                <div className="text-danger">
                                                    <small><i className="fas fa-info-circle mr-1"></i>{errors.email}</small>
                                                </div> :
                                                showErrorMessage ?
                                                    <div className="text-danger">
                                                        <small><i className="fas fa-info-circle mr-1"></i> {t("screens:onboardingNewAdmins.alreadyExist")} </small>
                                                    </div> :
                                                    null
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="row ml-2 mr-2">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                                                onClick={(e) => this.handleCancel(e)}>
                                                {t("screens:onboardingNewOrganization.cancel")}
                                            </button>
                                        </div>
                                        <div className="col-sm-6">
                                            <button className="btn btn-block rounded-pill btn-nb-outline-primary"
                                                disabled={showErrorMessage || !_.isEmpty(errors) || !formData.name || !formData.email ? true : false}
                                                onClick={this.sendInvitation}>
                                                {t("screens:onboardingNewOrganization.sendInvitation")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        // For connection invitation success message...
                        <div className="col-sm-12">
                            <div className="text-center">
                                <img alt="Add success" src={images.illustrationAddSuccess} className="mb-5" />
                            </div>
                            <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                                onClick={this.inviteNewOrganization}>
                                {t("screens:onboardingNewOrganization.inviteNewOrganization")}
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    const OnboardingReducer = state.OnboardingReducer;
    const LoginReducer = state.LoginReducer;

    return { OnboardingReducer, LoginReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        onboardingAction: bindActionCreators(onboardingAction, dispatch)
    }
}

const organizationConnection = connect(mapStateToProps, mapDispatchToProps)(OrganizationConnection);
export { organizationConnection as OrganizationConnection };