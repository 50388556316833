import { credentialNegotiationConstants, issuanceConst, issueKycCredentials } from "../../types";
import { negotiateProposalTableHeadings, proposalTableHeadings, tHeadCredentialList } from "../constants";

const initialState = {
  loading: true,
  selectCredential: {},
  credentialsData: [],
  hasNextPage: false,
  hasPreviousPage: false,
  lastPage: 0,
  nextPage: 0,
  previousPage: 0,
  totalItems: 0,
  tHead: [],
  credentialName: '',
  connectionName: '',
  selectedConnectionId: '',
  filledCredentialValue: {},
  credentialReqData: [],
  totalCredentialReq: 0,
  credentialExchangeId: '',
  showIssueCredential: false,
  issueKycCredentialsList: [],
  showKycDetailsModal: false,
  kycData: {},
  connectionNames: '',
  issuedCredential: {},
  requesterDID: '',
  showCredentialViewModal: false,
  credentialRecords: [],
  totalCredentialRecords: 0,
  showNegotiateProposalModal: false,
  selectedCredentialRecord: {},
  selectedSchema: {},
  tHeadNegotiate: []
}

export function IssuanceReducer(state = initialState, action: any) {


  switch (action.type) {
    case issuanceConst.SELECTED_ISSUANCE_CREDENTIAL:
      return Object.assign({}, state, {
        selectCredential: action.payload.record,
        fromPage: action.payload.page
      });
    case issuanceConst.CLEAR_ISSUANCE_CREDENTIAL:
      return Object.assign({}, state, {
        selectCredential: {},
        credentialName: '',
        connectionName: '',
        selectedConnectionId: '',
        credentialValue: [],
        filledCredentialValue: {},
        requesterDID: '',
      });
    case issuanceConst.ISSUE_CREDENTIAL:
      return Object.assign({}, state, {
        issuedCredential: action.payload,
        connectionNames: action.connectionName,
      })
    case issuanceConst.OFFER_CREDENTIAL_ISSUE:
      return Object.assign({}, state, {
        offeredCredential: action.payload
      })
    case issuanceConst.GET_ALL_CREDENTIALS:
      return Object.assign({}, state, {
        credentialsData: action.payload.data.data.data,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        hasNextPage: action.payload.data.data.hasNextPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        totalItems: action.payload.data.data.totalItems,
        previousPage: action.payload.data.data.previousPage,
        tHead: tHeadCredentialList,
      })
    case issuanceConst.SELECTE_ISSUE_CREDENTIAL_BY_WAITING_LIST:
      return Object.assign({}, state, {
        showCredentialViewModal: true,
        showIssueCredential: false,
        credentialName: action.payload.credentialDefinitionId.split(':')[4],
        connectionName: `${action.payload.connectionName} (${action.payload.connection.theirDid})`,
        requesterDID: action.payload.connection.theirDid,
        selectedConnectionId: action.payload.connectionIds,
        filledCredentialValue: JSON.parse(action.payload.credentialProposalDict),
        credentialExchangeId: action.payload.credentialExchangeId
      })
    case issuanceConst.GET_ALL_CREDENTIALS_REQUEST:
      return Object.assign({}, state, {
        credentialReqData: action.updatedPayload,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        hasNextPage: action.payload.data.data.hasNextPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        totalCredentialReq: action.payload.data.data.totalItems,
        previousPage: action.payload.data.data.previousPage,
        tHead: proposalTableHeadings,
      })
    case issuanceConst.GET_ALL_CREDENTIALS_RECORD:
      return Object.assign({}, state, {
        credentialRecords: action.updatedPayload,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        hasNextPage: action.payload.data.data.hasNextPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        totalCredentialRecords: action.payload.data.data.totalItems,
        previousPage: action.payload.data.data.previousPage,
        tHead: tHeadCredentialList,
      })
    case issuanceConst.SUCCESS_ISSUE_CREDENTIAL:
      return Object.assign({}, state, {
        showIssueCredential: true
      })
    case issuanceConst.ISSUE_ANOTHER_CREDENTIAL:
      return Object.assign({}, state, {
        showIssueCredential: false
      })
    case issueKycCredentials.ISSUE_KYC_CREDENTIALS:
      return Object.assign({}, state, {
        issueKycCredentialsList: action.tmpArray,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        hasNextPage: action.payload.data.data.hasNextPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        totalIssueKycCredential: action.payload.data.data.totalItems,
        previousPage: action.payload.data.data.previousPage,
      })
    case issueKycCredentials.SHOW_KYC_DETAILS_MODAL:
      return Object.assign({}, state, {
        showKycDetailsModal: true,
        kycData: action.payload,
      })

    case issuanceConst.GET_ALL_CREDENTIAL_PROPOSAL:
      return Object.assign({}, state, {
        credentialRecords: action.updatedPayload,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        hasNextPage: action.payload.data.data.hasNextPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        totalCredentialRecords: action.payload.data.data.totalItems,
        previousPage: action.payload.data.data.previousPage,
        tHeadNegotiate: negotiateProposalTableHeadings,
      })
    case issueKycCredentials.CLOSE_KYC_DETAILS_MODAL:
      return Object.assign({}, state, {
        showKycDetailsModal: false,
        kycData: {},
      })

    case credentialNegotiationConstants.SHOW_NEGOTIATE_PROPOSAL_MODAL:
      return Object.assign({}, state, {
        showNegotiateProposalModal: true,
        selectedCredentialRecord: action.payload,
        selectedSchema: action.selectedSchema
      })

    case credentialNegotiationConstants.CLOSE_NEGOTIATE_PROPOSAL_MODAL:
      return Object.assign({}, state, {
        showNegotiateProposalModal: false,
        selectedCredentialRecord: {},
        selectedSchema: {}
      })

    case issuanceConst.SELECTED_CREDENTIAL_DETAILS:
      return Object.assign({}, state, {
        selectedCredentialRecord: action.payload,
      })

    default:
      return state;
  }
}