import * as React from 'react';
import { multiuseAction } from '../actions/multiuseAction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { AddMultiuse } from '../components/AddMultiuse';
import { t } from 'i18next';
import { socket, SocketContext } from 'src/contexts/SocketContext';

export interface IMultiuseProps {
    multiuseAction: any,
    MultiuseReducer: any,
}

export interface IMultiuseState {
    defaultSearchFields: any,
    item_perPage: number,
    pageNo: number,
    sortingOrder: string,
}
class Multiuse extends React.Component<IMultiuseProps, IMultiuseState> {
    static contextType?= SocketContext

    constructor(props: IMultiuseProps) {
        super(props);

        this.state = {
            defaultSearchFields: '',
            item_perPage: 10,
            sortingOrder: 'DESC',
            pageNo: 1,
        }
        this.onSearch = this.onSearch.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        await this.connectSocket()
    }

    async connectSocket() {
        const { defaultSearchFields, item_perPage, sortingOrder, pageNo } = this.state;
        this.props.multiuseAction.getMultiuseList("MULTIUSE", defaultSearchFields, item_perPage, pageNo, sortingOrder);

        if (socket) {
            socket.on('notification', (socketId, payload) => {
                if (payload && payload.data) {
                    if (payload.data.type === 'Connection') {
                        this.props.multiuseAction.getMultiuseList("MULTIUSE", defaultSearchFields, item_perPage, pageNo, sortingOrder);
                    }
                }
            })
        } else {
            console.log("Could not estalished a connection")
        }
    }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { defaultSearchFields, item_perPage, sortingOrder, pageNo } = this.state;
        if (event.key === 'Enter') {
            this.setState({ defaultSearchFields: event.target.value });
            this.props.multiuseAction.getMultiuseList("MULTIUSE", event.target.value, item_perPage, pageNo, sortingOrder);
        }
    }

    onChangePage(page: any) {
        const { defaultSearchFields, item_perPage, sortingOrder } = this.state;
        this.props.multiuseAction.getMultiuseList("MULTIUSE", defaultSearchFields, item_perPage, page, sortingOrder);
    }

    refresh() {
        this.setState({
            defaultSearchFields: '',
            item_perPage: 10,
            sortingOrder: 'DESC',
            pageNo: 1,
        })
        this.props.multiuseAction.getMultiuseList("MULTIUSE", '', 10, 1, 'DESC');
    }

    public render() {
        const { tHead, multiuseList, totalItems } = this.props.MultiuseReducer;
        return (
            <div className="row gutters-sm min-h-100 animated fadeIn">
                <div className="col-md-12 col-lg-6 mb-30">
                    <div className="card nb-card h-100">
                        <div className="card-header bg-white border-bottom-0">
                            <h1 className="nb-title">{t("screens:invitationMultiuse.pendingMultiuse")}</h1>
                        </div>
                        <div className="card-body">
                            <TableComponents
                                onChangePage={this.onChangePage}
                                tHead={tHead}
                                tableContent={multiuseList}
                                totalRecords={totalItems}
                                searching={this.onSearch}
                                refresh={this.refresh}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-6 mb-30">
                    <>
                        <AddMultiuse  MultiuseReducer={this.props.MultiuseReducer} multiuseAction={this.props.multiuseAction} type="MULTIUSE" />
                    </>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state: any) {
    const { loggingIn } = state.LoginReducer;
    const MultiuseReducer = state.MultiuseReducer;
    return { loggingIn, MultiuseReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        multiuseAction: bindActionCreators(multiuseAction, dispatch),
    }
}

const connectedAddMultiuse = connect(mapStateToProps, mapDispatchToProps)(Multiuse);
export { connectedAddMultiuse as Multiuse };
