import { userConstants, verification } from "../../types";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('user');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
const initialState = loadState ? {
  loggedIn: false,
  user: loadState(),
  loggedInUser: {},
  reLogin: false,
  presentProofRequest: {},
  loggedInRecord: {},
} : {}

const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return Object.assign({},
        state,
        {
          loggedIn: true,
          user: action.payload
        });
    case userConstants.LOGIN_SUCCESS:
      return Object.assign({},
        state,
        {
          loggedIn: true,
          loggedInUser: action.payload,
          reLogin: false
        });
    case userConstants.LOGIN_FAILURE:
      return Object.assign({},
        state,
        {});
    case userConstants.GET_LOGGED_USER:
      return Object.assign({},
        state,
        {
          loggedIn: true,
          loggedInUser: action.payload
        })
    case userConstants.LOGOUT:
      return Object.assign({},
        state,
        {
          loggedIn: false,
          user: {},
          loggedInUser: {}
        });
    case userConstants.RE_LOGIN:
      return Object.assign({},
        state,
        {
          reLogin: true
        });

    case verification.GET_PRESENT_PROOF_REQUEST:
      return Object.assign({},
        state,
        {
          presentProofRequest: action.payload.data.data
        })
    case userConstants.SAVE_LOGIN_DATA:
      return Object.assign({},
        state,
        {
          loggedInRecord: action.payload.data.data
        })
    default:
      return state
  }
}

export default loginReducer