import React from 'react'
import Header from './Header/Header'
import SidebarComponent from './Sidebar/SidebarComponent'
import './Layout.css';
import _ from 'lodash';
import { socket, SocketContext } from 'src/contexts/SocketContext';

export interface ILayoutProps {
  children: any,
  LoginReducer: any,
  NotificationReducer: any,
  notificationAction: any,
  getCountNotification: Function | any
}

export interface ILayoutState {
  sidebarOpen: boolean
}

class Layout extends React.Component<ILayoutProps, ILayoutState> {
  static contextType?= SocketContext

  constructor(props: ILayoutProps) {
    super(props)

    this.state = {
      sidebarOpen: true,
    }

    this.openSidebarHandler = this.openSidebarHandler.bind(this)
    this.closeSidebarHandler = this.closeSidebarHandler.bind(this)

  }

  async componentDidMount() {

    const { loggedInUser } = this.props.LoginReducer;

    if (loggedInUser?.role?.id !== 1) {
      await this.connectSocket()
      this.props.notificationAction.getCountNotification()
    }
  }

  async connectSocket() {
    if (socket) {
      socket.on('notification', (socketId, payload) => {
        console.log(`Layout - SOCKET - notification - socketId: ${socketId} payload: ${JSON.stringify(payload)}`);
        if (payload && payload.data) {
          if (payload.data.type === 'Notification') {
            console.log(`Layout - SOCKET receive Notification`)
            this.props.notificationAction.getCountNotification()
          }
        }
      })
    } else {
      console.log("Could not estalished a connection")
    }
  }

  openSidebarHandler() {
    this.setState({
      sidebarOpen: true
    })
  }

  closeSidebarHandler() {
    this.setState({
      sidebarOpen: false
    })
  }

  refresh() {
    this.props.notificationAction.getCountNotification()
  }

  render() {
    const { loggedInUser } = this.props.LoginReducer
    const { totalUnreadNotifications } = this.props.NotificationReducer

    return (
      <div className={!_.isEmpty(loggedInUser) && loggedInUser.role.id === 1 ? 'bg-admin defaultLayout slimScroll' : 'defaultLayout slimScroll'}>
        {
          !_.isEmpty(loggedInUser) && loggedInUser.role && loggedInUser.role.id === 1 && loggedInUser.organization.isDashboard === true ?
            <SidebarComponent cssClass={this.state.sidebarOpen ? 'nb-admin show' : 'nb-admin hide'} sidebarOpen={this.state.sidebarOpen}></SidebarComponent>
            :
            <SidebarComponent cssClass={this.state.sidebarOpen ? 'pro-secondary show' : 'pro-secondary hide'} sidebarOpen={this.state.sidebarOpen}></SidebarComponent>

        }
        <div className="main">
          <Header openSidebarHandler={this.openSidebarHandler} loggedInUser={loggedInUser} totalUnreadNotifications={totalUnreadNotifications} />
          {/* <div className="container-fuild p-30 h-90vh" onClick={this.closeSidebarHandler}> */}
          <div className="container-fuild p-30 h-90vh">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }

}
export default Layout;
