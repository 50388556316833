import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import CreateNewWorkFlow from './CreateNewWorkFlow';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface IWorkflowListProps {
  workflowAction: any,
  WorkflowReducer: any,
}

export interface IWorkflowListState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  sortBy: string,
}

class WorkflowList extends React.Component<IWorkflowListProps, IWorkflowListState> {
  constructor(props: IWorkflowListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      sortBy: '',
    }
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
    this.createNewWorkFlow = this.createNewWorkFlow.bind(this);
    this.deleteElementDetails = this.deleteElementDetails.bind(this);
    this.showAddWorkflowForm = this.showAddWorkflowForm.bind(this);
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, sortBy } = this.state;
    this.props.workflowAction.getWorkflowList(defaultSearchFields, sortingOrder, sortBy, item_perPage, pageNo);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, sortingOrder, sortBy, pageNo } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.workflowAction.getWorkflowList(event.target.value, sortingOrder, sortBy, item_perPage, pageNo);
    }
  }

  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, sortBy } = this.state;
    this.props.workflowAction.getWorkflowList(defaultSearchFields, sortingOrder, sortBy, item_perPage, page);
  }

  sorting = (sortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', sortBy: sortBy }) : this.setState({ sortingOrder: 'DESC', sortBy: sortBy });
    // let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.workflowAction.getWorkflowList(defaultSearchFields, sortBy, sortBy, item_perPage, pageNo);
  }

  showDetails(item: any) {
    this.props.workflowAction.selectWorkflow(item)
  }

  deleteElementDetails(item: any) {
    this.props.workflowAction.deleteWorkflow(item.id)
  }

  createNewWorkFlow() {
    this.props.workflowAction.createNewWorkflow()
  }

  showAddWorkflowForm() {
    this.props.workflowAction.showAddWorkflowForm();
  }

  refresh() {
    // const { page, itemPerPage, searchText } = this.state
    const defaultPayload = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      sortBy: '',
    }
    this.setState(defaultPayload)

    this.props.workflowAction.getWorkflowList(defaultPayload.defaultSearchFields, defaultPayload.sortingOrder, defaultPayload.sortBy, 
      defaultPayload.item_perPage, defaultPayload.pageNo);
}

  public render() {
    const { tHead, totalItems, workflowList, selectedWorkflow, createNewWorkflow, isEditable, updateWorkflow, firstTitle } = this.props.WorkflowReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100 animated fadeIn">
          <div className="col-md-12 col-lg-5 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:newWorkflow.existingWorkflow")}</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <TableComponents
                      onChangePage={this.onChangePage}
                      showElementDetails={this.showDetails}
                      tHead={tHead}
                      tableContent={workflowList}
                      totalRecords={totalItems}
                      sorting={this.sorting}
                      searching={this.onSearch}
                      deleteElementDetails={this.deleteElementDetails}
                      refresh={this.refresh}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-7 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <div className="row">
                  <div className="col-sm-8">
                    {
                      updateWorkflow && isEditable ? 
                      <h1 className="nb-title">{t("screens:newWorkflow.title")}</h1> :
                      createNewWorkflow ? <h1 className="nb-title">{t("screens:newWorkflow.createNewWorkflow")}</h1> :
                      firstTitle ? <h1 className="nb-title">{t("screens:newWorkflow.createNewWorkflow")}</h1> :
                      ''
                    }
                  </div>
                  <div className="col-sm-4">
                    <button className="btn float-right">
                      <i className="fas fa-times" onClick={this.showAddWorkflowForm}></i>
                    </button>
                  </div>
                </div>
              </div>
              {
                createNewWorkflow === true || !_.isEmpty(selectedWorkflow) ?
                  <CreateNewWorkFlow {...this.props} />
                  :
                  <div className="card-body p-5">
                    <div className="row">
                      <div className="col-sm-12 mb-5 text-center">
                        <img alt="Config" className="img img-fluid" src={images.illustrationConfig} />
                      </div>
                      <div className="col-sm-12">
                        <button className="btn btn-nb-gradient-primary btn-block rounded-pill"
                          onClick={this.createNewWorkFlow}
                        >{t("screens:newWorkflow.createWorkflow")}</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const WorkflowReducer = state.WorkflowReducer;
  return { WorkflowReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
  }
}

const connectedWorkflowList = connect(mapStateToProps, mapDispatchToProps)(WorkflowList);
export { connectedWorkflowList as WorkflowList };
