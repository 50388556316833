import React from "react";
import { ListScreenOptions } from "src/types/common.interface";
import { CredentialState } from "src/View/commonConst";
import TableComponents from "../../../Common Components/Components/TableComponent";

/* Interface for Props variables*/
interface IDeclinedCredentialListProps {
    LoginReducer: any,
    NegotiateCredentialReducer: any,
    NegotiateCredentialAction: any,
    schemaAction: any
}
/* Interface for local states variables*/
interface IDeclinedCredentialListState {
    listScreenOptions: ListScreenOptions,
    selectedCredentialRecord: any
}

export class DeclinedCredentialList extends React.Component<IDeclinedCredentialListProps, IDeclinedCredentialListState> {
    constructor(props: IDeclinedCredentialListProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            listScreenOptions: {
                search: '',
                itemsPerPage: 10,
                pageNo: 1,
                sortBy: 'id',
                sort: 'DESC',
                filter: CredentialState.STATE_ABANDONED
            },
            selectedCredentialRecord: {}
        }

        this.onChangePage = this.onChangePage.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.sorting = this.sorting.bind(this);

    }

    /* Default lifecycle method to call get all credential list */
    componentDidMount() {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;
        const { credentialRecords } = this.props.NegotiateCredentialReducer;
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)

        if (credentialRecords.length > 0) {
            this.props.NegotiateCredentialAction.showSelectedCredentialDetails(credentialRecords[0])
        }

    }

    onChangePage(page: any) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page
        const { loggedInUser } = this.props.LoginReducer;
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
    }

    showDetails(item: any) {
        this.props.NegotiateCredentialAction.showSelectedCredentialDetails(item)
        const schemaId = item.credentialDefinitions && Object.keys(item.credentialDefinitions).length
            ? item.credentialDefinitions.schemaLedgerId
            : JSON.parse(item.credentialProposalDict)?.schema_id

        this.props.schemaAction.getSchemaById(schemaId)

        this.setState({
            selectedCredentialRecord: item,
        })
    }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;

        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions });
            this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
        }
    }

    sorting = (itemSortBy: string) => {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;

        listScreenOptions.sort = (listScreenOptions.sort === 'DESC') ? 'ASC' : 'DESC'
        listScreenOptions.sortBy = itemSortBy
        this.setState({ listScreenOptions })
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
    }

    refresh = () => {
        const listScreenOptions = {
            search: '',
            itemsPerPage: 10,
            pageNo: 1,
            sortBy: 'id',
            sort: 'DESC',
            filter: CredentialState.STATE_ABANDONED
        }
        const { loggedInUser } = this.props.LoginReducer;

        this.setState({ listScreenOptions })
        this.props.NegotiateCredentialAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
        const { credentialRecords } = this.props.NegotiateCredentialReducer;
        if (credentialRecords.length > 0) {
            this.props.NegotiateCredentialAction.showSelectedCredentialDetails(credentialRecords[0])
        }
    }

    public render() {
        const { credentialRecords, tHeadNegotiate, totalCredentialRecords, selectedCredentialRecord } = this.props.NegotiateCredentialReducer;
        return (
            <div>
                <TableComponents
                    onChangePage={this.onChangePage}
                    showElementDetails={this.showDetails}
                    tHead={tHeadNegotiate}
                    tableContent={credentialRecords}
                    totalRecords={totalCredentialRecords}
                    selectedRecord={selectedCredentialRecord}
                    sorting={this.sorting}
                    searching={this.onSearch}
                    refresh={this.refresh}
                />
            </div>
        );
    }
}
