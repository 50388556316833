import * as React from 'react';
import { connect } from 'react-redux';
import './AddMultiuse.css'
import { history } from '../../../index'
import _ from 'lodash';

// Utilities
import { t } from 'i18next';
import CopyToClipboard from 'react-copy-to-clipboard';

let QRCode = require('qrcode.react');


/* Interface for Props variables*/
export interface IQRMultiuseProps {
    MultiuseReducer: any,
    multiuseAction: any,
    qrData: string,
}

/* Interface for local states variables*/
export interface IQRMultiuseState {
    copiedQR: boolean
}

export default class QRMultiuse extends React.Component<IQRMultiuseProps, IQRMultiuseState> {
    constructor(props: IQRMultiuseProps) {
        super(props);

        /* Initialization of state variables*/
        this.state = {
            copiedQR: false
        }
        /* Binding the method */
        this.redirectConnections = this.redirectConnections.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    redirectConnections() {
        this.setState({})
        history.push("/connections")
    }

    handleClose() {
        this.setState({})
        history.push("/create-connection")
    }

    onCopiedQRCode(value: any) {
        switch (value) {
            case 'copiedQR':
                return this.setState({ copiedQR: true })
            default:
                return null
        }
    }

    onAfterCopiedQRCode = (event: React.MouseEvent<HTMLButtonElement> | any) => {
        setTimeout(() => {
            this.setState({
                copiedQR: false,
            });
        }, 1000);
        event.preventDefault();
    }

    public render() {
        const { copiedQR } = this.state;
        return (
            <>
                <div className="card-header bg-white border-bottom-0 m-b-20">
                    <h1 className="nb-h2">{t("screens:invitationMultiuse.invitationCreated")}</h1>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4 text-right">
                        <QRCode value={this.props.qrData} />
                    </div>
                    <div className="col-sm-6">
                        <label className="nb-label m-b-20">{t("screens:invitationMultiuse.copyInvitation")}</label>
                        <div className="row">
                            <div className="col-sm-12 align-self-center">
                                <CopyToClipboard
                                    text={this.props.qrData}
                                    onCopy={() => this.onCopiedQRCode('copiedQR')}
                                >
                                    <div className="col-sm-4 align-self-center">
                                        <button className="btn btn-sm btn-nb-outline-primary" type="button" onClick={this.onAfterCopiedQRCode}>
                                            {copiedQR ? (
                                                <span className="text-success">{t("screens:invitationMultiuse.copied")}</span>
                                            ) : <i className="far fa-copy" />}
                                            {copiedQR ? '' : ` ${t("screens:invitationMultiuse.copy")}`
                                            }
                                        </button>
                                    </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-12 mt-3">
                                    <button className="btn btn-block rounded-pill btn-nb-outline-primary"
                                        onClick={this.redirectConnections}>
                                        {t("screens:invitationMultiuse.connections")}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mt-3">
                                    <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                                        onClick={this.handleClose}>
                                        {t("screens:invitationMultiuse.close")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state: any) {
    return {}
}

function mapDispatchToProps(dispatch: any) {
    return {}
}

const qrMultiuse = connect(mapStateToProps, mapDispatchToProps)(QRMultiuse);
export { qrMultiuse as QRMultiuse };