import _ from "lodash";
import { issuanceConst, userConstants } from "../../types";
import { tHeadCredential } from "../constants";

const initialState = {
  credDefGet: true,
  credDef: [],
  selectedCredentialDetails: {},
  modalShow: false,
  fromPage: 'credDef',
  totalCredentials: 0,
  tHeadCredential: [],
  selectedSchema: {},
  revokedCredential: false,
  showSuccessPage: false,
  internalCredDefList: [],
  selectedinternalCredDef: {},
  isHolderModalShow: false,
  holderDetailsList: [],
  totalHolders: 0,
  selectedHolderDetails: {},
  createdCredDef: {},
  createdCredDefDb: {},
  credentialDefinitionList:[]
}

export function CredentialReducer(state = initialState, action: any) {
  switch (action.type) {
    case userConstants.LOADING:
      return Object.assign({},
        state,
        {
          loading: true
        });
    case userConstants.GETALL_REQUEST:
      return Object.assign({},
        state,
        {
          credDefGet: true,
          tHeadCredential: tHeadCredential,

        });
    case userConstants.GETALL_SUCCESS_CREDEF:
      return Object.assign({}, state, {
        credDef: action.payload.data.data.data,
        tHeadCredential: tHeadCredential,
        hasNextPage: action.payload.data.data.hasNextPage,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        previousPage: action.payload.data.data.previousPage,
        totalCredentials: action.payload.data.data.totalItems,
        selectedCredentialDetails: action.payload.data.data.data[0],
        modalShow: false,
        fromPage: 'credDef',
      });
    case userConstants.GETALL_DEFAULT_SUCCESS_CREDEF:
      return Object.assign({}, state, {
        credDef: action.payload.data.data.data,
        tHeadCredential: tHeadCredential,
        hasNextPage: action.payload.data.data.hasNextPage,
        hasPreviousPage: action.payload.data.data.hasPreviousPage,
        lastPage: action.payload.data.data.lastPage,
        nextPage: action.payload.data.data.nextPage,
        previousPage: action.payload.data.data.previousPage,
        totalCredentials: action.payload.data.data.totalItems,
        selectedCredentialDetails: action.payload.data.data.data[0],
        modalShow: false,
        fromPage: 'credDef',
      });
    case userConstants.GETALL_FAILURE:
      return Object.assign({},
        state,
        {
          error: action.error
        });
    case userConstants.SELECTED_CREDDEF:
      return Object.assign({},
        state,
        {
          selectedCredentialDetails: action.payload.item,
          modalShow: true,
          fromPage: 'dashboard',

        });
    case userConstants.SHOW_CREDDEF_FROM:
      return Object.assign({},
        state,
        {
          modalShow: false,
          selectedSchemaDatail: {}
        });
    // case userConstants.REVOKE_CREDENTIAL:
    //   let statusofRevocation = !_.isEmpty(action.payload) && !_.isEmpty(action.payload.data) && !_.isEmpty(action.payload.data.data) && action.payload.data.data.isRevoked === true ? true : false ;
    //   return Object.assign({}, state, {
    //     revokedCredential: statusofRevocation,
    //   })
    case userConstants.SHOW_SUCCESS_CREDENTIAL_PAGE:
      return Object.assign({},
        state,
        {
          showSuccessPage: true,
        })
    case userConstants.CREATE_CREDENTIAL_PAGE:
      return Object.assign({},
        state,
        {
          showSuccessPage: false,
        })
    case userConstants.GETALL_INTERNAL_CREDENTIALS:
      return Object.assign({},
        state,
        {
          internalCredDefList: action.tempArray,
          totalInternalCredDef: action.payload.data.data.totalItems,
          selectedinternalCredDef: !_.isEmpty(action.tempArray) ? action.tempArray[0] : [],
        })
    case userConstants.SELECT_INTERNAL_CREDENTIAL:
      return Object.assign({},
        state,
        {
          selectedinternalCredDef: action.payload,
        })
    case userConstants.GETALL_HOLDER_DETAILS:
      return Object.assign({},
        state,
        {
          holderDetailsList: action.tempArray,
          totalHolders: action.payload.data.data.totalItems,
          isHolderModalShow: true,
          selectedHolderDetails: action.tempArray[0],
        })
    case userConstants.CLEAR_HOLDER_DETAIL_LIST:
      return Object.assign({},
        state,
        {
          holderDetailsList: [],
          totalHolders: 0,
          isHolderModalShow: false,
          selectedHolderDetails: {},
        })
    case userConstants.CREATED_CREDDEF:
      return Object.assign({},
        state,
        {
          createdCredDef: action.payload.data.data
        })
    case userConstants.CREATED_CREDDEF_DB:
      return Object.assign({},
        state,
        {
          createdCredDefDb: action.payload.data.data
        })
    case userConstants.TABLE_SCROLL_DATA:

      return Object.assign({},
        state,
        {
          selectedDept: action.payload.selectedRecord,
          selectedIndex: action.payload.index,
        })

    case issuanceConst.GET_ALL_CRED_DEF_BY_PAIRWISE_DID:
      return Object.assign({},
        state,
        {
          credentialDefinitionList: action.payload.data.data         
        })
    default:
      return state
  }
}

