import { t } from "i18next";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CommentReportComponent from "src/Common Components/Components/CommentReportModal";
import TableComponents from "src/Common Components/Components/TableComponent";
import { ListScreenOptions } from "src/types/common.interface";
import { loginAction } from "src/View/Login/actions/loginAction";
import { schemaAction } from "src/View/Schema/actions/schemaAction";
import { revokeCredentialActions } from "../actions/revoke-credential.actions";
import { CredentialDetails } from "../component/credential-details.component";

interface IRevokeCredentialProps {
    revokeCredentialActions: any,
    RevokeCredentialReducer: any,
    LoginReducer: any,
    schemaAction: any,
}

interface IRevokeCredentialState {
    listScreenOptions: ListScreenOptions,
    isRevokeCommentModal: boolean,
    commentList: string[]
}

class RevokeCredential extends React.Component<IRevokeCredentialProps, IRevokeCredentialState> {
    constructor(props: IRevokeCredentialProps) {
        super(props);

        this.state = {
            listScreenOptions: {
                search: '',
                itemsPerPage: 10,
                pageNo: 1,
                sortBy: 'id',
                sort: 'DESC',
            },
            isRevokeCommentModal: false,
            commentList: [
                `${t("screens:existingConnections.commentFraud")}`,
                `${t("screens:existingConnections.commentInvalid")}`
            ]
        }

        this.onChangePage = this.onChangePage.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.sorting = this.sorting.bind(this);

        this.revokeCredential = this.revokeCredential.bind(this);
        this.handleRevokeCredentialSubmit = this.handleRevokeCredentialSubmit.bind(this);

    }

    componentDidMount(): void {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;
        const { issuedCredentialRecords } = this.props.RevokeCredentialReducer;
        this.props.revokeCredentialActions.issuedCredentialListById(loggedInUser.organization.id, listScreenOptions)

        if (issuedCredentialRecords.length > 0) {
            this.props.revokeCredentialActions.showSelectedCredentialDetails(issuedCredentialRecords[0])
        }
    }

    onChangePage(page: any) {
        const { listScreenOptions } = this.state;
        listScreenOptions.pageNo = page
        const { loggedInUser } = this.props.LoginReducer;
        this.props.revokeCredentialActions.issuedCredentialListById(loggedInUser.organization.id, listScreenOptions)
    }

    showDetails(item: any) {
        this.props.revokeCredentialActions.showSelectedCredentialDetails(item)
        console.log('item.credentialExchangeId', item.credentialExchangeId)
        
        this.props.revokeCredentialActions.selectedReceivedCredentialRecord(item.credentialExchangeId)
        const schemaId = item.credentialDefinitions && Object.keys(item.credentialDefinitions).length
            ? item.credentialDefinitions.schemaLedgerId
            : JSON.parse(item.credentialProposalDict)?.schema_id

        this.props.schemaAction.getSchemaById(schemaId)

        // this.setState({
        //     selectedCredentialRecord: item,
        //     isRePropose: false,
        //     isNegotiateProposal: false
        // })
    }

    onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;

        if (event.key === 'Enter') {
            listScreenOptions.search = event.target.value
            this.setState({ listScreenOptions });
            this.props.revokeCredentialActions.issuedCredentialListById(loggedInUser.organization.id, listScreenOptions)
        }
    }

    sorting = (itemSortBy: string) => {
        const { listScreenOptions } = this.state;
        const { loggedInUser } = this.props.LoginReducer;

        listScreenOptions.sort = (listScreenOptions.sort === 'DESC') ? 'ASC' : 'DESC'
        listScreenOptions.sortBy = itemSortBy
        this.setState({ listScreenOptions })
        this.props.revokeCredentialActions.issuedCredentialListById(loggedInUser.organization.id, listScreenOptions)
    }

    refresh = () => {
        const listScreenOptions = {
            search: '',
            itemsPerPage: 10,
            pageNo: 1,
            sortBy: 'id',
            sort: 'DESC',
            filter: 'all'
        }
        const { loggedInUser } = this.props.LoginReducer;

        this.setState({ listScreenOptions })
        this.props.revokeCredentialActions.issuedCredentialListById(loggedInUser.organization.id, listScreenOptions)
        const { issuedCredentialRecords } = this.props.RevokeCredentialReducer;
        if (issuedCredentialRecords.length > 0) {
            this.props.revokeCredentialActions.showSelectedCredentialDetails(issuedCredentialRecords[0])
        }
    }

    revokeCredential() {
        this.setState({
            isRevokeCommentModal: true
        })
    }

    closeModal = () => {
        this.setState({
            isRevokeCommentModal: false,
        })
    }

    handleRevokeCredentialSubmit(selectedReceivedCredData: any, comment: string) {
        console.log('selectedReceivedCredData', selectedReceivedCredData)
        this.props.revokeCredentialActions.revokeCredential(selectedReceivedCredData, comment);
        this.setState({
            isRevokeCommentModal: false
        })
    }

    render(): React.ReactNode {
        const { tHeadRevokeCredential, issuedCredentialRecords, totalCredentialRecords, selectedCredentialRecord, selectedReceivedCredData } = this.props.RevokeCredentialReducer
        const { isRevokeCommentModal, commentList } = this.state

        return (
            <>

                <div className="row gutters-sm min-h-100">
                    <div className="col-md-12 col-lg-7 mb-30">
                        <div className="card nb-card h-100">
                            <div className="card-header bg-white border-bottom-0">
                                <h1 className="nb-title">{t("screens:revokeCredential.issuedCredentialList")}</h1>
                            </div>
                            <div className="card-body">
                                <TableComponents
                                    onChangePage={this.onChangePage}
                                    showElementDetails={this.showDetails}
                                    tHead={tHeadRevokeCredential}
                                    tableContent={issuedCredentialRecords}
                                    totalRecords={totalCredentialRecords}
                                    selectedRecord={selectedCredentialRecord}
                                    sorting={this.sorting}
                                    searching={this.onSearch}
                                    refresh={this.refresh}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-5 mb-30">
                        <div className="card nb-card h-100">
                            {/* Component for show specific credential details */}
                            {selectedCredentialRecord &&
                                <CredentialDetails
                                    key={selectedCredentialRecord.id}
                                    selectedCredentialDetails={selectedCredentialRecord}
                                    revokeCredential={this.revokeCredential}
                                />
                            }
                            {isRevokeCommentModal &&
                                <CommentReportComponent
                                    isModalShow={isRevokeCommentModal}
                                    commentList={commentList}
                                    selectedReceivedCredData={selectedReceivedCredData}
                                    closeModal={this.closeModal}
                                    handleCommentReportSubmit={this.handleRevokeCredentialSubmit}
                                    modalTitle={t("screens:revokeCredential.revokeCredentialModalTitle")}
                                    credentialName={selectedCredentialRecord.credentialDefinitions.tag}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

function mapStateToProps(state: any) {
    const RevokeCredentialReducer = state.RevokeCredentialReducer
    const LoginReducer = state.LoginReducer;
    return {
        RevokeCredentialReducer, LoginReducer
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        revokeCredentialActions: bindActionCreators(revokeCredentialActions, dispatch),
        loginAction: bindActionCreators(loginAction, dispatch),
        schemaAction: bindActionCreators(schemaAction, dispatch),
    }
}

const revokeCredential = connect(mapStateToProps, mapDispatchToProps)(RevokeCredential);
export { revokeCredential as RevokeCredential };
