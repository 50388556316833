import _ from 'lodash';
import * as React from 'react';
import { history } from '../../../index'

// Utilities
import { t } from 'i18next';
import ClickableCard from 'src/Common Components/Components/ClickableCard';

export interface IOnboardingDetailsProps {
    ConnectionReducer: any,
    connectionAction: any,
}

export interface IOnboardingDetailsState {
}

export default class OnboardingDetails extends React.Component<IOnboardingDetailsProps, IOnboardingDetailsState> {
    constructor(props: IOnboardingDetailsProps) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

        const { selectedConnection } = this.props.ConnectionReducer
        if (!_.isEmpty(selectedConnection)) {
            this.props.connectionAction.getAllIssueCredentialByConnectionId(selectedConnection.connectionId, '', '', '', 0, 0, '')
        }
    }

    addEmployee(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any): void {
        history.push("/add-employee")
    }

    addAdmin(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any): void {
        history.push("/add-admin")
    }

    addOrganization(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any): void {
        history.push("/add-organization")
    }

    addClient(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any): void {
        history.push("/add-client")
    }

    addMultiUse(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any): void {
        history.push("/add-multiuse")
    }

    public render() {
        return (
            <>
                <div className="card nb-card h-100">
                    {/* <div className="row"> */}
                    <div className="card-header bg-white border-bottom-0">
                        <h1 className="nb-title">{t("screens:existingConnections.onboarding")}</h1>
                    </div>
                    <div className='row pl-3 pt-5 pr-3'>
                        <div className="col-md-12 col-lg-6">
                            <ClickableCard
                                onClick={this.addEmployee}
                                inviteFor={t("screens:existingConnections.newEmployee")}
                                inviteMessage={t("screens:existingConnections.newEmployeemsg")}
                            />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <ClickableCard
                                onClick={this.addAdmin}
                                inviteFor={t("screens:existingConnections.newAdmin")}
                                inviteMessage={t("screens:existingConnections.newAdminmsg")}
                            />
                        </div>
                    </div>
                    <div className='row pl-3 pt-5 pr-3'>
                        <div className="col-md-12 col-lg-6">
                            <ClickableCard
                                onClick={this.addOrganization}
                                inviteFor={t("screens:existingConnections.newOrganization")}
                                inviteMessage={t("screens:existingConnections.newOrganizationmsg")}
                            />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <ClickableCard
                                onClick={this.addClient}
                                inviteFor={t("screens:existingConnections.newClient")}
                                inviteMessage={t("screens:existingConnections.newClientmsg")}
                            />
                        </div>
                    </div>
                    <div className='row pl-3 pt-5 pr-3'>
                        <div className="col-md-12 col-lg-12">
                            <ClickableCard
                                onClick={this.addMultiUse}
                                inviteFor={t("screens:existingConnections.newMultiUse")}
                                inviteMessage={t("screens:existingConnections.newMultiUsemsg")}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
