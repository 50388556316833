import { t } from 'i18next';
import _ from 'lodash';
import * as React from 'react';
import ProblemReportComponent from 'src/Common Components/Components/ProblemReportModal';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { tHeadInstantTaskList } from '../constant';
import ProofDetailsModal from './ProofDetailsModal';

export interface IInstantTaskListProps {
  getAllInstantTaskAction: any,
  InstantTaskReducers: any,
  presentProofAction: any
}

export interface IInstantTaskListState {
  defaultSearchFields: any,
  item_perPage: any,
  pageNo: any,
  sortingOrder: any,
  instantTaskSortBy: any,
  status: any,
  isModalProblemReportShow: boolean,
  declineReasonList: string[]
}

export default class InstantTaskList extends React.Component<IInstantTaskListProps, IInstantTaskListState> {
  constructor(props: IInstantTaskListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      instantTaskSortBy: '',
      status: 'all',
      isModalProblemReportShow: false,
      declineReasonList: [
        `${t("screens:verificationMonitoring.invalidPresentation")}`
      ]
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sorting = this.sorting.bind(this);
    this.refresh = this.refresh.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDeclinePresentation = this.handleDeclinePresentation.bind(this)
    this.handleDeclineModalClose = this.handleDeclineModalClose.bind(this)
    this.handleProblemReportSubmit = this.handleProblemReportSubmit.bind(this)
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, instantTaskSortBy, status } = this.state;
    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultSearchFields, status, sortingOrder, instantTaskSortBy, item_perPage, pageNo
    );
  }


  onChangePage(page: any) {
    const { defaultSearchFields, item_perPage, sortingOrder, instantTaskSortBy, status } = this.state;
    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultSearchFields, status, sortingOrder, instantTaskSortBy, item_perPage, page
    );
  }

  showDetails(item: object | any) {
    this.props.getAllInstantTaskAction.selectedTask(item);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { item_perPage, pageNo, sortingOrder, instantTaskSortBy, status } = this.state;
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value })
      this.props.getAllInstantTaskAction.getAllInstantTask(
        event.target.value, status, sortingOrder, instantTaskSortBy, item_perPage, pageNo
      );
    }
  }

  sorting = (connectionSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, instantTaskSortBy, status } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', instantTaskSortBy: connectionSortBy }) :
      this.setState({ sortingOrder: 'DESC', instantTaskSortBy: connectionSortBy });
    // let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultSearchFields, status, sortingOrder, instantTaskSortBy, item_perPage, pageNo
    );
  }


  refresh = () => {
    // const { defaultSearchFields, item_perPage, pageNo, sortingOrder, instantTaskSortBy, status } = this.state;
    const defaultPayload = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      instantTaskSortBy: '',
      status: 'all'
    }
    this.setState(defaultPayload)

    this.props.getAllInstantTaskAction.getAllInstantTask(
      defaultPayload.defaultSearchFields, defaultPayload.status, defaultPayload.sortingOrder, defaultPayload.instantTaskSortBy,
      defaultPayload.item_perPage, defaultPayload.pageNo
    );
  }

  closeModal = () => {
    this.props.getAllInstantTaskAction.closeModal();
  }

  handleDeclinePresentation() {
    this.setState({ isModalProblemReportShow: true })
  }

  handleDeclineModalClose() {

    this.setState({
      isModalProblemReportShow: false,
    })

    this.props.getAllInstantTaskAction.closeModal();
  }

  handleProblemReportSubmit(reason: string, exchangeId: string) {
    this.props.presentProofAction.sendProblemReportForPresentationExchange(reason, exchangeId)
    this.handleDeclineModalClose()
    this.refresh()
  }


  public render() {
    const { instantTaskList, totalInstantTaskList, showPresentProof, proofData, hideVerifyButton } = this.props.InstantTaskReducers;
    const { isModalProblemReportShow, declineReasonList } = this.state

    return (
      <>
        {
          <TableComponents
            onChangePage={this.onChangePage}
            showElementDetails={this.showDetails}
            tHead={tHeadInstantTaskList}
            tableContent={!_.isEmpty(instantTaskList) && instantTaskList}
            totalRecords={totalInstantTaskList}
            sorting={this.sorting}
            searching={this.onSearch}
            selectedRecord={proofData}
            // filter={this.filter}
            refresh={this.refresh}
          />
        }
        {!isModalProblemReportShow && showPresentProof ?
          <ProofDetailsModal
            key={proofData.presentationExchangeId}
            data={proofData}
            closeModal={this.closeModal}
            isOpen={showPresentProof}
            action={this.props.getAllInstantTaskAction}
            reducer={this.props.InstantTaskReducers}
            hideVerifyButton={hideVerifyButton}
            handleDeclinePresentation={this.handleDeclinePresentation}
          /> :
          <ProblemReportComponent
            isModalShow={isModalProblemReportShow}
            reasonList={declineReasonList}
            selectedRecordId={proofData?.presentationExchangeId}
            selectedRecordGivenName={proofData?.connectionName}
            closeModal={this.handleDeclineModalClose}
            handleProblemReportSubmit={this.handleProblemReportSubmit}
            modalTitle={''}
            credentialState={''}
          />
        }

      </>
    );
  }
}

