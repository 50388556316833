import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { ScanQR } from './ScanQR';

// Utilities
import images from "src/utils/images";
import { loginAction } from '../actions/loginAction'

export interface IProps extends WithTranslation<["common", "screens"]> {
  loginAction: any,
  user: any,
  LoginReducer: any,
  NotificationReducer: any,
  notificationAction: any,
  getCountNotification: Function | any
}

class QRLoginPage extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {

    return (
      <div className="row justify-content-center h-100 w-100" style={{ position: 'absolute' }}>
        <div className="col-lg-6 d-none d-lg-flex login-bg justify-content-center" style={{ overflow: 'hidden' }}>
          <div className="d-flex align-self-center">
            <img alt="Logo" src={images.companyLogoWhite} width="500px" />
          </div>
        </div>
        <div className="col-lg-6 p-5">
          <img alt="Logo" className="m-auto d-block d-lg-none" src={images.companyLogo} width="250px" />
          <div className="p-5">
              <ScanQR {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  return { LoginReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
  }
}
const connectedAuthenticate = withTranslation(["common", "screens"])(connect(mapStateToProps, mapDispatchToProps)(QRLoginPage));
export { connectedAuthenticate as QRLoginPage };