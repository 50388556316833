import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableComponents from "../../../Common Components/Components/TableComponent";
import { presentProofAction } from "../action/presentProofAction";

/* Interface for Props variables*/
interface IProofRequestListProps {
    presentProofAction: any,
    PresentProofReducer: any,
    onShowProofDetails: any,
    presentProofRequestList: any,
    getAllPresentationRequests: any
}

/* Interface for local states variables*/
interface IProofRequestListState {
    page: number,
    itemPerPage: number,
    searchText: string
}

class ProofRequestList extends React.Component<IProofRequestListProps, IProofRequestListState> {
    constructor(props: IProofRequestListProps) {
        super(props)
        /* Initialization of state variables*/
        this.state = {
            page: 1,
            itemPerPage: 10,
            searchText: ""
        }
        /* Binding the method */
        this.onChangePage = this.onChangePage.bind(this)
        this.selectedReceivedProofRequestRecord = this.selectedReceivedProofRequestRecord.bind(this)
        this.search = this.search.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    /* Default lifecycle method used to show 1st proof request details. */
    componentDidMount() {
        const { presentProofRequestList } = this.props
        this.props.onShowProofDetails(presentProofRequestList[0])
    }

    /*Method used to set selected page no form pagination */
    onChangePage(page: number) {
        const { itemPerPage, searchText } = this.state;
        this.setState({ page })
        this.props.presentProofAction.getAllPresentProofRequest(page, itemPerPage, searchText);
    }

    /* Method used to show selected proof request details */
    async selectedReceivedProofRequestRecord(item: any) {
        this.props.presentProofAction.showSelectedProofRequestDetails(item)
        await this.props.onShowProofDetails(item)
    }

    /* Method used to search the content from list */
    search(event: React.KeyboardEvent<HTMLInputElement> | any) {
        const { page, itemPerPage } = this.state;
        if (event.key === 'Enter') {
            this.setState({ searchText: event.target.value })
            this.props.presentProofAction.getAllPresentProofRequest(page, itemPerPage, event.target.value);
        }
    }

    /* Method used to refresh the present proof request list */
    refresh() {
        // const { page, itemPerPage, searchText } = this.state
        const defaultPayload = {
            page: 1,
            itemPerPage: 10,
            searchText: ""
        }
        this.setState(defaultPayload)
        this.props.presentProofAction.getAllPresentProofRequest(defaultPayload.page, defaultPayload.itemPerPage, defaultPayload.searchText);
    }

    /* UI to show proof request list */
    public render() {
        const { tHead, presentProofRequestList, totalProofRequests, selectedProofRequestDetails } = this.props.PresentProofReducer;
        // console.log('presentProofRequestList:', presentProofRequestList)
        return (
            <>
                <div>
                    <TableComponents
                        onChangePage={this.onChangePage}
                        showElementDetails={this.selectedReceivedProofRequestRecord}
                        tHead={tHead}
                        tableContent={presentProofRequestList}
                        totalRecords={totalProofRequests}
                        searching={this.search}
                        refresh={this.refresh}
                        selectedRecord={selectedProofRequestDetails}
                        key={selectedProofRequestDetails}
                    />
                </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    const PresentProofReducer = state.PresentProofReducer;

    return { PresentProofReducer }
}

function mapDispatchToProps(dispatch: any) {
    return {
        presentProofAction: bindActionCreators(presentProofAction, dispatch),
    }
}

const proofRequestList = connect(mapStateToProps, mapDispatchToProps)(ProofRequestList);
export { proofRequestList as ProofRequestList };
