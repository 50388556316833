import * as React from 'react';
import { connect } from 'react-redux';
import './AddHolder.css';
import _ from 'lodash';
import { history } from '../../../index'

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

let QRCode = require('qrcode.react');

/* Interface for Props variables*/
export interface IAddHolderProps {
  createHolder: Function,
  qrData: string,
  HolderReducer: any,
  holderAction: any,
  type: string,
  field: string,
}
/* Interface for State variables*/
export interface IAddHolderState {
  formData: any,
  errors: any,
  inviteNewHolder : boolean
}

export default class AddHolder extends React.Component<IAddHolderProps, IAddHolderState> {
  constructor(props: IAddHolderProps) {
    super(props);
    /* Initialization of state variables*/
    this.state = {
      formData: {},
      errors: {},
      inviteNewHolder : false
    }
    /* Bind the methods */
    this.handleChange = this.handleChange.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
    this.inviteNewHolder = this.inviteNewHolder.bind(this);
  }

  /**
   * Method to use get event from text field and set the values in state variable
   * @param event Parameter to get event from text field. 
   */
  handleChange(event: React.ChangeEvent<HTMLInputElement> | any) {
    let formData = this.state.formData;
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData
    });
    if (this.validateForm()) {
      formData['connectionInvitation'] = this.props.qrData;
      formData['type'] = this.props.type;
    }
    if (event.target.name === 'email') {
      /* API call to check holder email. */
      this.props.holderAction.getHolderEmailId(event.target.value, 'email')
    }
    // if (event.target.name === 'name') {
    //   /* API call to check holder email. */
    //   this.props.holderAction.getHolderEmailId(event.target.value, 'name')
    // }

  }

  /**
   * Method to send connection invitation
   */
  sendInvitation(event: React.ChangeEvent<HTMLInputElement> | any) {
    const { formData } = this.state;
    this.setState({
      inviteNewHolder : true
    });
    this.props.createHolder(formData)
  }

  /**
   * Method to validate the form field.
   * @returns It returns the form validation result
   */
  validateForm() {
    let formData: any = this.state.formData;
    let errors: any = {};
    let formIsValid: any = true;

    if (typeof formData["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(formData["email"])) {
        formIsValid = false;
        errors["email"] = `${t(`screens:onboardingNewHolder.messageValidEmail`)}`
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  /**
   * Method to invite new holder.
   */
  inviteNewHolder() {
    this.setState({ formData: {}, inviteNewHolder: false })
    this.props.holderAction.inviteNewHolder();
    this.props.holderAction.getQRdata(this.props.type);
  }

  handleCancel(event: React.DOMAttributes<HTMLButtonElement> | any) {
    event.preventDefault();
    this.setState({
      formData: {},
      errors: {},
      inviteNewHolder : false
    })
    // console.log(`TEST CANCEL - HOLDER -formData: ${JSON.stringify(this.state.formData)} errors: ${JSON.stringify(this.state.errors)}`)
    history.push("/create-connection")
  }


  public render() {
    const { formData, errors, inviteNewHolder  } = this.state;
    const { qrData, type, field } = this.props;
    const { selectHolder, emailSent, showErrorMessage, showErrorMessageName } = this.props.HolderReducer;
    return (
      <div className="card nb-card h-100">
        <div className="card-header bg-white border-bottom-0">
          <h1 className="nb-title">{t("screens:onboardingNewHolder.addNew")} {t(`screens:onboardingNewHolder.${field.charAt(0).toLowerCase() + field.substr(1)}`)}</h1>
        </div>
        <div className="card-body">
          {!inviteNewHolder ?
            <>
              <div className="mb-5">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:onboardingNewHolder.givenName")}</label>
                    <input type="text" className="form-control" name="name" value={selectHolder.name ? selectHolder.name : formData.name}
                      onChange={(e) => this.handleChange(e)} readOnly={selectHolder.name || emailSent ? true : false} />
                    {
                      errors.name ?
                        <div className="text-danger">
                          <small><i className="fas fa-info-circle mr-1"></i>{errors.name}</small>
                        </div> :
                        showErrorMessageName ?
                          <div className="text-danger">
                            <small><i className="fas fa-info-circle mr-1"></i> {t("screens:onboardingNewHolder.messageAlreadyExist")} </small>
                          </div>
                          : null
                    }
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="nb-label">{t("screens:onboardingNewHolder.emailAddress")}</label>
                    <input type="email" className="form-control" name="email" value={selectHolder.email ? selectHolder.email : formData.email}
                      onChange={(e) => this.handleChange(e)} readOnly={selectHolder.email || emailSent ? true : false} />
                    {
                      errors.email ?
                        <div className="text-danger">
                          <small><i className="fas fa-info-circle mr-1"></i>{errors.email}</small>
                        </div> :
                        showErrorMessage ?
                          <div className="text-danger">
                            <small><i className="fas fa-info-circle mr-1"></i> {t("screens:onboardingNewHolder.messageAlreadyExist")} </small>
                          </div>
                          : null

                    }
                  </div>
                </div>
                <div className="col-sm-12 mb-3">
                  <div className="card">
                    {/* <div className="card-body text-center">
                      {emailSent && <div className="invitation-sent">
                        <label className="invitation-sent-button"><i className="fas fa-check-circle"></i> {t("screens:onboardingNewHolder.sent")} </label>
                      </div>}
                      {!_.isEmpty(qrData) ?
                        <QRCode value={selectHolder.connectionInvitation ? selectHolder.connectionInvitation : qrData} />
                        :
                        <ContentLoader></ContentLoader>
                      }
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row ml-2 mr-2">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                        onClick={(e) => this.handleCancel(e)}>
                        {t("screens:onboardingNewHolder.cancel")}
                      </button>
                    </div>
                    <div className="col-sm-6">
                      <button className="btn btn-block rounded-pill btn-nb-outline-primary"
                        disabled={showErrorMessage || !_.isEmpty(errors) || !formData.name || !formData.email || _.isEmpty(qrData) ? true : false}
                        onClick={(e) => this.sendInvitation(e)}>
                        {t("screens:onboardingNewHolder.sendInvitation")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <div className="col-sm-12">
              <div className="text-center">
                <img alt="Add success" src={images.illustrationAddSuccess} className="mb-5" />
              </div>
              <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                onClick={this.inviteNewHolder}>
                {t("screens:onboardingNewHolder.inviteNew")} {(t(`screens:onboardingNewHolder.${type.toLowerCase()}`)).toUpperCase()}
              </button>
            </div>
          }
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {}
}

function mapDispatchToProps(dispatch: any) {
  return {
  }
}

const connectedAddHolder = connect(mapStateToProps, mapDispatchToProps)(AddHolder);
export { connectedAddHolder as AddHolder };
