import { presentationProposalConstants } from "../../types";
import { t } from 'i18next';

const initialState = {
    presentationList: [],
    totalPresentations: 0,
    tHead: [],
    selectedPresentationRecord: {},
    createNewProposal: false,

}
export function PresentationProposalReducer(state = initialState, action: any) {
    let tHead = [{
        'name': `${t("screens:newProposal.date")}`,
        'label': "createDateTime"
    }, {
        'name': `${t("screens:newProposal.givenName")}`,
        'label': "connectionName"
    }, {
        'name': `${t("screens:newProposal.status")}`,
        'label': "state"
    }, {
        'name': ``,
        'label': "details"
    }]

    switch (action.type) {

        case presentationProposalConstants.SHOW_NEW_PROPOSAL:
            return Object.assign({}, state, {
                createNewProposal: true,
                emailSent: false,
                showErrorMessage: false,
            });
        case presentationProposalConstants.CREATE_NEW_PROPOSAL:
            return Object.assign({}, state, {
                createNewProposal: false,
            });
        /* Get all present proof request list */
        case presentationProposalConstants.GET_ALL_PRESENTATION_PROPOSAL_LIST:
            return Object.assign({}, state, {
                presentationList: action.tmpArray,
                totalPresentations: action.payload.totalItems,
                tHead: tHead,
                selectedPresentationRecord: action.tmpArray?.[0],
            });

        case presentationProposalConstants.SELECTED_PRESENTATION_PROPOSAL_RECORD:
            return Object.assign({}, state, {
                selectedPresentationRecord: action.payload
            })

        default:
            return state
    }

}
