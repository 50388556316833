import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, notificationConstants } from "../../types";
import { history } from '../../../index'
import { t } from 'i18next';
import _ from "lodash";
import { CredentialState, PresentProofState } from "src/View/commonConst";

export const notificationAction = {

    /**
     * Method to get all notification
     * @param searchText 
     * @param itemPerPage 
     * @param page 
     * @param filterValue 
     * @returns It return the notification list
     */
    getAllNotificationsList(
        page: number,
        itemPerPage: number,
        searchText: string
    ) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.get(`${config.apiUrl}/notification/get-all-notifications?page=${page}&item_per_page=${itemPerPage}&search_text=${searchText}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then((notificationList) => {
                        let tempArray: any = [];
                        tempArray = !_.isEmpty(notificationList) && notificationList.data && notificationList.data.data.data;
                        const totalNotifications = notificationList.data.data.totalItems

                        tempArray.map((item: any, index: any) => {

                            let type = (item.notificationType).replace(' ', '').toLowerCase()

                            tempArray[index].initialNotificationType = type
                            tempArray[index].notificationType = `${t(`screens:notification.${type.includes('proof') ? 'proof' : type}`)}`
                            tempArray[index].isRead = item.isRead === true ? `${t("screens:notification.readYes")}` : `${t("screens:notification.readNo")}`
                            if (item.notificationType !== 'Basic Message') {
                                tempArray[index].notificationMessage = `${t(`screens:notification.${item.notificationMessage}`)}`
                            } else {
                                tempArray[index].notificationMessage = item.notificationMessage
                            }

                        })
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: notificationConstants.GET_ALL_NOTIFICATIONS,
                            payload: notificationList.data.data, totalNotifications
                        })
                        this.getCountNotification()
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                console.error('Error in get all connection list action', error)
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                throw error
            }
        }
    },

    getCountNotification() {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.get(`${config.apiUrl}/notification/get-count-notifications`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then((totalNotifications) => {
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: notificationConstants.GET_COUNT_NOTIFICATIONS,
                            payload: totalNotifications.data
                        })
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                console.error('Error in get all count notification action', error)
                // dispatch({
                //     type: loaderConst.LOADER_FALSE
                // })
                throw error
            }
        }
    },

    deleteNotification(selectedNotification: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.delete(`${config.apiUrl}/notification/delete/${selectedNotification}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then((notificationDeleted) => {
                        return (
                            dispatch({
                                type: notificationConstants.NOTIFICATION_DELETED
                            }),
                            this.getAllNotificationsList(1, 10, ''),
                            dispatch({
                                type: loaderConst.LOADER_FALSE
                            })
                        )
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        throw error
                    })
            } catch (error) {
                console.error('Error delete notification action', error)
                // dispatch({
                //     type: loaderConst.LOADER_FALSE
                // })
                throw error
            }
        }
    },

    selectNotification(selectedNotification: any) {
        console.log('selectedNotification', selectedNotification)
        return async (dispatch: any) => {
            try {
                switch (selectedNotification.notificationType) {
                    case notificationConstants.NOTIFICATION_CONNECTION:
                        history.push("/connections", { selectedNotification })
                        break

                    case notificationConstants.NOTIFICATION_CREDENTIAL:

                        switch (selectedNotification.resultic.state) {

                            case CredentialState.STATE_ABANDONED:
                                history.push("/credential-issuance/completed", { selectedNotification })
                                break;

                            case CredentialState.STATE_CREDENTIAL_RECEIVED:
                                history.push("organization-credentials/wallet", { selectedNotification })
                                break;

                            case CredentialState.STATE_CREDENTIAL_REVOKED:
                                history.push("organization-credentials/wallet", { selectedNotification })
                                break;

                            default:
                                history.push("/credential-issuance/negotiate", { selectedNotification })
                                break;
                        }

                        break
                    case notificationConstants.NOTIFICATION_PROOF:
                        const recordTypeState = selectedNotification.initialNotificationType === 'proofproposal'
                            ? selectedNotification.resultpp.state
                            : selectedNotification.resultPpItw.state

                        switch (recordTypeState) {

                            case PresentProofState.STATE_ABANDONED:
                                history.push("/credential-verification/completed", { selectedNotification })
                                break;

                            default:
                                history.push("/credential-verification/negotiate", { selectedNotification })
                                break;
                        }

                        break
                    case notificationConstants.NOTIFICATION_BASIC_MESSAGE:
                        history.push("/chats", { selectedNotification })
                        break
                    default:
                        history.push("/notification", { selectedNotification })
                        break
                }

                this.updateReadMessage(selectedNotification)

                return (
                    dispatch({
                        type: notificationConstants.SELECTED_NOTIFICATION,
                        payload: selectedNotification
                    })
                )
            } catch (error) {
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    updateReadMessage(selectedNotification: any) {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let token = localStorage.getItem('token');
                return await axios.put(`${config.apiUrl}/notification/update-read-message/${selectedNotification.id}`, '',
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then((updatedNotification) => {
                        return (
                            dispatch({
                                type: notificationConstants.NOTIFICATION_READ,
                                payload: updatedNotification,
                            }),
                            this.getAllNotificationsList(1, 10, ''),
                            dispatch({
                                type: loaderConst.LOADER_FALSE
                            })
                        )
                    })
                    .catch(error => {
                        loginAction.handleResponse(error.response, dispatch);
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                    })
            } catch (error) {
                console.error('Error in updating the read notification status', error);
                throw error
            }
        }
    },

    listView() {
        console.log('List View Notifications')
        return async (dispatch: any) => {
            dispatch({
                type: notificationConstants.LIST_VIEW_NOTIFICATION
            })
            // history.push('/edit-connections')
        }
    },
}