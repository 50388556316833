import * as React from 'react';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { filterCredentials, tHeadInternalCredential } from '../constants';

export interface ICredentialListProps {
  credentialAction: any,
  CredentialReducer: any,
  InternalCredential?: boolean,
  schemaAction: any,
}

export interface ICredentialListState {
  defaultSearchFields: any,
  supportRevocation: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  CredentialSortBy: string,
}

export default class CredentialList extends React.Component<ICredentialListProps, ICredentialListState> {
  constructor(props: ICredentialListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      supportRevocation: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
    }
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.sorting = this.sorting.bind(this);
  }

  onChangePage(page: any) {
    const { defaultSearchFields, supportRevocation, item_perPage, sortingOrder, CredentialSortBy } = this.state;

    this.props.credentialAction.getAllCredentialByOrgId(defaultSearchFields, supportRevocation, sortingOrder, CredentialSortBy, item_perPage, page);
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { pageNo, item_perPage, sortingOrder, CredentialSortBy, supportRevocation } = this.state
    if (event.key === 'Enter') {
      this.setState({ defaultSearchFields: event.target.value });
      this.props.credentialAction.getAllCredentialByOrgId(event.target.value, supportRevocation, sortingOrder, CredentialSortBy, item_perPage, pageNo,);
    }
  }

  sorting = (CredentialSortBy: string) => {
    const { defaultSearchFields, item_perPage, pageNo, supportRevocation } = this.state;
    this.state.sortingOrder === 'DESC' ? this.setState({ sortingOrder: 'ASC', CredentialSortBy: CredentialSortBy }) : this.setState({ sortingOrder: 'DESC', CredentialSortBy: CredentialSortBy });
    let sortValue = this.state.sortingOrder === 'DESC' ? "ASC" : "DESC";
    this.props.credentialAction.getAllCredentialByOrgId(defaultSearchFields, supportRevocation, sortValue, CredentialSortBy, item_perPage, pageNo);
  }

  showDetails(credDef: any) {
    this.props.credentialAction.selectCredential(credDef, "credDef")
  }

  refresh = (event: any) => {
    const defaultPayload = {
      defaultSearchFields: '',
      supportRevocation: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      CredentialSortBy: '',
    }
    this.setState(defaultPayload)
    this.props.credentialAction.getAllCredentialByOrgId(defaultPayload.defaultSearchFields, defaultPayload.supportRevocation,
      defaultPayload.sortingOrder, defaultPayload.CredentialSortBy, defaultPayload.item_perPage, defaultPayload.pageNo);
    this.props.schemaAction.getSchemaList();
  }

  filter = (selectedFilterValue: any) => {
    const { defaultSearchFields, item_perPage, pageNo, sortingOrder, CredentialSortBy } = this.state;
    this.setState({ supportRevocation: selectedFilterValue })
    this.props.credentialAction.getAllCredentialByOrgId(defaultSearchFields, selectedFilterValue, sortingOrder, CredentialSortBy, item_perPage, pageNo);
    this.props.schemaAction.getSchemaList();
  }

  public render() {
    let { tHeadCredential, credDef, totalCredentials } = this.props.CredentialReducer;
    let { InternalCredential } = this.props;
    return (
      <>
        <TableComponents
          onChangePage={this.onChangePage}
          showElementDetails={this.showDetails}
          tHead={InternalCredential ? tHeadInternalCredential : tHeadCredential}
          tableContent={credDef.length > 0 && credDef}
          totalRecords={totalCredentials}
          sorting={this.sorting}
          searching={this.onSearch}
          filter={filterCredentials}
          onChangeFilter={this.filter}
          refresh={this.refresh}
        />
      </>
    );
  }
}
