import * as React from 'react';
import _ from 'lodash';
import { t } from 'i18next';

export interface ICreateSchemaProps {
  credentialAction: any,
  schemaAction: any,
  CredentialReducer: any,
  SchemaReducer: any,
  handleSelect: any,
  hideSchemaFromLedger: any,
}

export interface ICreateSchemaState {
  schemaShow: boolean,
  isEdited: boolean,
  values: Array<any>,
  format: Array<any>,
  getSchemaData: Array<any>,
  newSchemaList: Array<any>,
  schemaData: {
    schemaName: string,
    schemaVersion: string,
  },
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortingOrder: string,
  schemaSortBy: string,
  submitted: boolean,
  isDisable: boolean,
  isDuplicate: boolean,
  // fireBaseWarn: boolean,
  // fireBaseWarnMessage: string,
  // fireBaseWarnColor: string,
  indexOf: any,
  duplicateIndex: any,
  duplicateSchema: boolean,
  duplicateVersion: boolean,
  errors: any,
  isImageAttributeMax: boolean,
}


export default class CreateSchema extends React.Component<ICreateSchemaProps, ICreateSchemaState> {
  constructor(props: ICreateSchemaProps) {
    super(props);

    this.state = {
      schemaShow: false,
      isEdited: false,
      values: [''],
      format: [''],
      getSchemaData: [],
      newSchemaList: [],
      schemaData: {
        schemaName: '',
        schemaVersion: '',
      },
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      schemaSortBy: '',
      submitted: false,
      isDisable: true,
      isDuplicate: false,
      // fireBaseWarn: false,
      // fireBaseWarnMessage: '',
      // fireBaseWarnColor: '',
      indexOf: 0,
      duplicateIndex: 0,
      duplicateSchema: false,
      duplicateVersion: false,
      errors: {},
      isImageAttributeMax: false,
    }
    this.onChanges = this.onChanges.bind(this);
    this.addField = this.addField.bind(this);
    this.createSchemaAttribute = this.createSchemaAttribute.bind(this);
    this.removeClick = this.removeClick.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isBtnDisable = this.isBtnDisable.bind(this);
  }

  isBtnDisable = () => {
    let values = this.state.values;
    let format = this.state.format;
    let schemaData = this.state.schemaData;
    if (schemaData.schemaName !== "" && schemaData.schemaVersion !== "") {
      for (var i = 0; i < values.length; i++) {
        if (values[i] === "" || values[i] === "Format") {
          this.setState({
            isDisable: true
          })
          break;
        } else {
          for (var j = 0; j < format.length; j++) {
            if (format[j] === "" || format[j] === "Format") {
              this.setState({
                isDisable: true
              })
              break;
            } else {
              this.setState({
                isDisable: false
              })
            }
          }
        }
      }
    } else {
      this.setState({
        isDisable: true
      })
    }
  }

  onChanges(event: React.ChangeEvent<HTMLInputElement> | any, index: any) {
    event.preventDefault();
    // debugger
    let updateField = this.state.values;
    updateField[event.target.name] = event.target.value;

    let duplicate = false;
    let newField = updateField.filter((function (el) {
      return el.trim()
    }))

    if (event.target.value !== '') {
      const s = new Set(newField);

      if (newField.length !== s.size) {
        duplicate = true;
      }
    }

    if (event.target.value !== '') {
      var upperCaseNames = updateField.map(function (value: string) {
        return value.toUpperCase().trim();
      });

      duplicate = upperCaseNames.some(function (item, idx) {
        return upperCaseNames.indexOf(item) !== idx
      });
    }

    // duplicate = _.uniq(updatefield).length !== updatefield.length;

    this.setState({
      values: updateField,
      isDuplicate: duplicate,
      duplicateIndex: index,
    })
    this.isBtnDisable();
  }

  onSelect(event: React.ChangeEvent<HTMLInputElement> | any) {
    let updateField = this.state.format;
    let isImageAttributeMax = this.state.isImageAttributeMax
    updateField[event.target.name] = event.target.value;
    let counter = 0
    let errors: any = {};

    for (const string of updateField) {
      if (string === 'image')
        counter++;
    }

    if (counter > 2) {
      errors.imageAttributes = `${t("screens:createNewCredential.messageAttributes")}`
      isImageAttributeMax = true
    }
    else {
      delete errors.imageAttributes;
      isImageAttributeMax = false
    }

    this.setState({
      format: updateField,
      errors,
      isImageAttributeMax,
    })
    this.isBtnDisable();
  }

  addField(event: React.MouseEvent<HTMLButtonElement>) {
    let updateValue: any = this.state.values;
    let updateFormat: any = this.state.format;
    let isImageAttributeMax = this.state.isImageAttributeMax

    let counter = 0
    let errors: any = {};

    for (const string of updateFormat) {
      if (string === 'image')
        counter++;
    }

    if (counter > 2) {
      errors.imageAttributes = `${t("screens:createNewCredential.messageAttributes")}`
      isImageAttributeMax = true
    }
    else {
      delete errors.imageAttributes;
      isImageAttributeMax = false
    }

    updateValue.push('');
    updateFormat.push('');
    this.setState({
      values: updateValue,
      format: updateFormat,
      errors,
      isImageAttributeMax,
    })
    this.isBtnDisable();
    event.preventDefault();
  }

  createSchemaAttribute() {
    const { values, format, isDuplicate, duplicateIndex } = this.state;
    return values.map((field: any, index: any) => {

      return index === 0 ?
        <div key={index}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label nb-label pr-0">{t("screens:createNewCredential.attribute")} 1</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control"
                    name={index} id="schemaAttri"
                    value={field}
                    onChange={(e) => this.onChanges(e, index)}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label nb-label pr-0">{t("screens:createNewCredential.format")}</label>
                <div className="col-sm-8">
                  <select className="form-control"
                    onChange={(e) => this.onSelect(e)}
                    name={index}
                    value={format[index]}
                  >
                    <option value='Format'>{t("screens:createNewCredential.selectFormat")}</option>
                    <option value='text'>{t("screens:createNewCredential.formatText")}</option>
                    <option value='number'>{t("screens:createNewCredential.formatNumber")}</option>
                    <option value='DD/MM/YYYY'>{t("screens:createNewCredential.formatDate")} (DD/MM/YYYY)</option>
                    <option value='image'>{t("screens:createNewCredential.formatImage")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <div className="form-group">
                <button className="btn float-right" onClick={(e) => this.removeClick(e, index)}
                  disabled={values.length > 1 ? false : true}
                ><i className="fas fa-minus-circle" />
                </button>
              </div>
            </div>
          </div>
        </div>
        :
        // -------------------------------
        <div key={index}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label nb-label pr-0">{t("screens:createNewCredential.attribute")} {index + 1}</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control"
                    name={index} id="schemaAttri"
                    // placeholder={`Schema Item ${index + 1}`}
                    value={field}
                    onChange={(e) => this.onChanges(e, index)}
                  />
                  {isDuplicate === true && duplicateIndex === index ? <small className="text-danger">
                    {t("screens:createNewCredential.duplicateAttribute")}
                  </small> : null}
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label nb-label pr-0">{t("screens:createNewCredential.format")}</label>
                <div className="col-sm-8">
                  <select className="form-control"
                    name={index}
                    value={format[index]}
                    onChange={(e) => this.onSelect(e)}>
                    <option value='Format'>{t("screens:createNewCredential.selectFormat")}</option>
                    <option value='text'>{t("screens:createNewCredential.formatText")}</option>
                    <option value='number'>{t("screens:createNewCredential.formatNumber")}</option>
                    <option value='DD/MM/YYYY'>{t("screens:createNewCredential.formatDate")} (DD/MM/YYYY)</option>
                    <option value='image'>{t("screens:createNewCredential.formatImage")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <div className="form-group">
                <button className="btn float-right"
                  onClick={(e) => this.removeClick(e, index)}
                >
                  <i className="fas fa-minus-circle" />
                </button>
              </div>
            </div>
          </div>
        </div>
    }
    )
  }

  removeClick(event: React.MouseEvent<HTMLButtonElement>, i: number) {
    let values = [...this.state.values];
    let format = [...this.state.format];
    let errors = this.state.errors
    values.splice(i, 1);
    format.splice(i, 1);

    let isImageAttributeMax = this.state.isImageAttributeMax

    let counter = 0

    for (const string of format) {
      if (string === 'image')
        counter++;
    }
    if (!values.includes("") && !format.includes("")) {
      if (values.length === format.length) {

        if (counter > 2) {
          errors.imageAttributes = `${t("screens:createNewCredential.messageAttributes")}`
          isImageAttributeMax = true
        }
        else {
          delete errors.imageAttributes;
          isImageAttributeMax = false
        }

        return this.setState({ isDisable: false, values, format, isDuplicate: false, isImageAttributeMax })
      }
    }

    this.setState({ values, format });
    this.isBtnDisable();
    event.preventDefault();
  }

  saveDetails(event: React.ChangeEvent<HTMLInputElement> | any) {
    event.preventDefault();
    const { values, format, schemaData } = this.state;
    let saveAttribute: any = [];
    values.forEach((ele, idx) => {
      let jsonObj = {
        "name": format[idx] === 'DD/MM/YYYY' ? `${ele}_dateint`: ele,
        "value": format[idx]
      }
      saveAttribute.push(jsonObj);
    });
    let x: any;
    if (schemaData.schemaVersion) {
      x = parseFloat(schemaData.schemaVersion).toFixed(1);
    }
    let finalData = {
      "schema_name": schemaData.schemaName,
      "schema_version": x.toString(),
      "attributes": saveAttribute
    }
    this.props.schemaAction.saveSchema(finalData);
    this.setState({
      values: [''],
      format: [''],
      schemaData: {
        schemaName: '',
        schemaVersion: '',
      },
      isEdited: false
    })
    this.props.handleSelect("profile-tab")
  }

  showSchema(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({
      schemaShow: true
    })
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist();
    const { name, value } = event.target;
    const { schemaData } = this.state;
    const { schemaList } = this.props.SchemaReducer;
    let duplicateSchemaName = false;
    let duplicateVersionNumber = false;

    if (!_.isEmpty(schemaList) && name === 'schemaName') {
      // debugger
      schemaList.map((ele: any, index: any) => {
        if (ele.schemaName === value) {
          duplicateSchemaName = true;
        }
      })

      // this.setState({
      //   duplicateSchema: duplicateSchemaName
      // })
      this.validForm();
    }
    if (name === "schemaVersion") {
      var decimal_index = event.target.value.indexOf('.');
      if (event.target.valueAsNumber < 1 || value === '.') {
        return;
      }
      if (decimal_index > -1) {
        var decimals = event.target.value.substring(decimal_index, event.target.value.length + 1);
        if (decimals.length > 2) {
          return;
        }
      }
      if (!_.isEmpty(schemaList)) {
        schemaList.map((ele: any, index: any) => {
          if (ele.schemaName === schemaData.schemaName) {
            if (ele.schemaVersion === value) {
              duplicateVersionNumber = true;
            }
          }
        })
        // this.setState({
        //   duplicateVersion: duplicateVersionNumber
        // })
      }
      this.validForm();
    }
    if (event.target.value === "") {
      this.setState({
        schemaData: {
          ...schemaData,
          [name]: value
        },
        isEdited: true,
        isDisable: true,
        duplicateSchema: duplicateSchemaName,
        duplicateVersion: duplicateVersionNumber,
      })
    } else {
      this.setState({
        schemaData: {
          ...schemaData,
          [name]: value
        },
        isEdited: true,
        duplicateSchema: duplicateSchemaName,
        duplicateVersion: duplicateVersionNumber,
      })
      this.isBtnDisable();
    }
  }

  validForm() {
    let formData: any = this.state.schemaData;
    let errors: any = {};
    let formIsValid: any = true;
    const regexp = /^\S*$/;

    if (formData["schemaName"]) {
      if (!regexp.test(formData["schemaName"])) {
        formIsValid = false;
        errors["schemaName"] = `${t("screens:createNewCredential.messageSchemaName")}`;
      }
    }

    if (typeof formData["schemaVersion"] !== "undefined" && formData["schemaVersion"] !== '') {
      if (!formData["schemaVersion"].match(/^[-+]?[0-9]+\.[0-9]*$/)) {
        formIsValid = false;
        errors["schemaVersion"] = `${t("screens:createNewCredential.messageSchemaVersion")}`;
      }
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  public render() {
    let {
      schemaData,
      values,
      isDisable,
      isDuplicate,
      duplicateVersion,
      errors,
      isImageAttributeMax,
    } = this.state;

    return (
      <>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label nb-label">{t("screens:createNewCredential.schemaName")}</label>
          <div className="col-sm-9">
            <input type="text" className="form-control" autoComplete="off"
              name='schemaName' value={schemaData.schemaName}
              onChange={(e) => this.handleChange(e)} required
            />
            <small className="text-danger">{errors.schemaName ?? ''}</small>

            <div className="text-right nb-link">
              <small
                className="font-weight-bold"
                style={{ cursor: 'pointer' }}
                onClick={this.props.hideSchemaFromLedger}>
                {t("screens:createNewCredential.chooseSchemaLedger")}
              </small>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label nb-label">{t("screens:createNewCredential.schemaVersion")}</label>
          <div className="col-sm-9">
            <input type="text" className="form-control" autoComplete="off" pattern="[0-9]"
              name='schemaVersion' value={schemaData.schemaVersion}
              min="1"
              onChange={(e) => this.handleChange(e)} required
            />
            <small className="text-danger">{duplicateVersion ? `${t("screens:createNewCredential.messageDuplicateVersion")}` : ''}</small>
            <small className="text-danger">{errors.schemaVersion ? `${t("screens:createNewCredential.messageSchemaVersion")}` : ''}</small>
          </div>
        </div>
        {
          this.createSchemaAttribute()
        }

        <small className="text-danger">{errors.imageAttributes ? `${t("screens:createNewCredential.messageAttributes")}` : ''}</small>
        <div className="row mt-3">
          <div className="col-sm-6">
            <div className="form-group">
              <button className="btn btn-block btn-nb-outline-primary rounded-pill"
                disabled={!values[0] || isDuplicate || isImageAttributeMax}
                onClick={(e) => this.addField(e)}
              >{t("screens:createNewCredential.moreItem")}</button>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <button className="btn btn-block btn-nb-outline-primary rounded-pill"
                disabled={isDisable ?
                  true : isDuplicate ?
                    true : duplicateVersion ?
                      true : this.state.errors.schemaVersion ?
                        true : errors.schemaName ?
                          true : errors.isImageAttributeMax ?
                            true : errors.imageAttributes ?
                              true : false
                }
                onClick={(e) => this.saveDetails(e)}
              >{t("screens:createNewCredential.finish")}</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
