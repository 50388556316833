// Utilities
import { CssColorVariables, IAppColors } from "./theme";

export interface IColorRgb {
  r: number;
  g: number;
  b: number;
  a?: number;
}

/**
 * Convert a hex string to a RGB spectrum (supports short/full codes with optional alpha)
 *
 * @source  https://stackoverflow.com/a/5624139/4206438
 * @param   hex - Input hex string
 * @returns Equivalent RGB spectrum
 */
export const hexToRgb = (hex: string): IColorRgb | null => {
  // Hex may be shorthand form (ie. "#fff")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])?$/i;
  const fullHex = hex.replace(shorthandRegex, (m, r, g, b, a) => {
    const base = r + r + g + g + b + b;
    return a ? base + a + a : base;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(fullHex);
  if (!result) return null;

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: result[4] ? parseInt(result[4], 16) : undefined,
  };
};

/**
 * Set/update CSS color variables on ':body' selector
 *
 * @param colors - CSS color variables
 */
export const setColorCssVariables = (colors: Partial<IAppColors>) => {
  // NOTE: This directly adds the variables as inline styles on the HTML root tag, which
  //         may not be the best behaviour (works for now though).
  //       Additionally, colours are only set on page load, which again works for now.

  Object.entries(colors).forEach(([key, value]) => {
    const cssVariable = CssColorVariables[key as keyof IAppColors];
    if (!cssVariable || !value) return;
    document.documentElement.style.setProperty(cssVariable, value);
  });
};
