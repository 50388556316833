import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginAction } from '../../View/Login/actions/loginAction';

export interface IEmptyCartProps {
  loginAction: any,
  LoginReducer:any,
  
}

export interface IEmptyCartState {
}

class EmptyCart extends React.Component<IEmptyCartProps, IEmptyCartState> {
  constructor(props: IEmptyCartProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount(){
    this.props.loginAction.getCurrentUser()
  }

  public render() {
    const {loggedInUser} = this.props.LoginReducer;
    return (
      <>
        {/* <div className="card mt-3">
          <div className="card-body">
            <div className="row">

              You have no access to this process
             </div>
          </div>
        </div> */}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const LoginReducer = state.LoginReducer;
  return {LoginReducer}
}

function mapDispatchToProps(dispatch: any) {
  return {
    loginAction: bindActionCreators(loginAction, dispatch),
  }
}

const connectedEmptyCart = connect(mapStateToProps, mapDispatchToProps)(EmptyCart)
export { connectedEmptyCart as EmptyCart }

// import * as React from 'react';

// export default class EmptyCart extends React.Component {

//   public render() {
//     return (
//         <div className="card mt-3">
//         <div className="card-body">
//           <div className="row">

//             You have no access to this process
//           </div>
//         </div>
//       </div>
//     );
//   }
// }