/**
 * Capitalize a string
 *
 * @param   input - Input string
 * @returns Capitalized string
 */
export const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

/**
 * Convert a kebab or snake string to camel case
 *
 * @param   input - Input string (kebab or snake)
 * @returns Camel case string
 */
export const camelCase = (input: string): string => {
  return input
    .toLowerCase()
    .replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
};

/**
 * Convert a camel or pascal case string to kebab case
 *
 * @source  https://stackoverflow.com/a/67243723/4206438
 * @param   input - Input string (camel or pascal)
 * @returns Kebab case string
 */
export const kebabCase = (input: string): string => {
  return input.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())
}

/**
 * Safely parse a JSON string
 *
 * @param   data         - JSON string data
 * @param   defaultValue - Optional default value (if parsing fails)
 * @returns Parsed JSON data
 */
export const parseJson = (
  data: string,
  defaultValue?: Record<string, any>,
): Record<string, any> | null => {
  if (!data) return defaultValue ?? null;

  try {
    return JSON.parse(data);
  } catch {
    return defaultValue ?? null;
  }
};


export const getOperatorFromProofPredicateKey = (predicateString: string) => {
  const predicateOperators = ['<', '<=', '>', '>=']
  const predicateStringArray = predicateString.split(' ')

  return predicateStringArray.filter(function (obj: any) {
    return predicateOperators.indexOf(obj) !== -1;
  })[0];
}
