import { settingUpDashboardConst } from "../../types";


// debugger
const initialState = {
  settingupDashboardData: {},
  showDashboard: false,
  showMsgLoginCredef: false,
  showMsgDriversLicenseCredDef: false,
  showMsgPassportCredDef: false,
  isConnectWithPlatformAdmin: false,
}

export function setDIDReducer(state = initialState, action: any) {
  switch (action.type) {
    case settingUpDashboardConst.DEFAULT_CRED_DEF_CREATED:
      return Object.assign({},
        state, {
        showMsgLoginCredef: false,
        showMsgDriversLicenseCredDef: false,
        showMsgPassportCredDef: false,
        showDashboard: true
      });

    case settingUpDashboardConst.SHOW_MSG_LOGIN_CREDDEF:
      return Object.assign({},
        state, {
        showMsgLoginCredef: true
      });

    case settingUpDashboardConst.SHOW_MSG_KYC_DRIVERS_LICENSE_CRED_DEF:
      return Object.assign({},
        state, {
        showMsgDriversLicenseCredDef: true,
      });

    case settingUpDashboardConst.SHOW_MSG_KYC_PASSPORT_CRED_DEF:
      return Object.assign({},
        state, {
        showMsgPassportCredDef: true,
      });

    case settingUpDashboardConst.CONNECT_WITH_PLATFORM_ADMIN:
      return Object.assign({},
        state, {
        isConnectWithPlatformAdmin: true,
      });

    default:
      return state
  }
}

