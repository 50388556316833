import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, presentationRequestConstants } from "../../types"
import { t } from 'i18next';
import _ from "lodash";
import { ListScreenOptions } from "src/types/common.interface";

export const presentationRequestAction = {

    createNewRequest() {
        return async (dispatch: any) => {
            dispatch({
                type: presentationRequestConstants.CREATE_NEW_REQUEST,
            })
        }
    },

    /**
     * Method to get present proof request list
     * @param page Parameter for current page no for pagination.
     * @param itemPerPage Parameter for setting the how many number of item required per page.
     * @param searchText This parameter use for searching the content in database.
     * @returns Get all present proof records.
     */
    getAllPresentProofRequest(listScreenOptions: ListScreenOptions) {
        return async (dispatch: any) => {
            try {
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Get authentication token from local storage */
                let token = localStorage.getItem('token');

                const params = {
                    page: listScreenOptions.pageNo,
                    items_per_page: listScreenOptions.itemsPerPage,
                    ...(listScreenOptions.search && { search_text: listScreenOptions.search }),
                    ...(listScreenOptions.filter && { filter_text: listScreenOptions.filter }),
                }

                /* API call to get proof request records and dispatch the get all present proof request list */
                return await axios.get(`${config.apiUrl}/verifier/proof-requests-sent-received`,
                    {
                        headers: { "Authorization": `Bearer ${token}` },
                        params
                    })
                    .then(getAllList => {
                        /* Add the connection name in response */
                        getAllList.data.data.data.map((element: any) => {
                            // out-of-band
                            if (element.connectionId) {
                                (element['connectionName'] = element.connection?.theirLabel)
                            } else {
                                (element['connectionName'] = "")
                            }
                            if (!element.hasOwnProperty('requestReason')) {
                                element.requestReason = 'Proof Request'
                            }
                            return element
                        })
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentationRequestConstants.GET_ALL_PRESENTATION_REQUEST_LIST,
                            payload: getAllList.data.data
                        })
                        this.showSelectedCredentialDetails(getAllList.data.data.data[0])
                    }).catch((error: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch)
                    })

            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                /* Handle the error and throw it */
                throw error
            }
        }
    },


    /**
     * Method to send the proof presentation.
     * @param proofPresentation Parameter includes the send proof presentation payload
     * @returns It return the response of send proof request API
     */
    createPresentationProposal(proofPresentation: any) {
        return async (dispatch: any) => {
            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                return await axios.post(`${config.apiUrl}/verifier/prover/send-proposal`, proofPresentation, {
                    headers: { "Authorization": `Bearer ${token}` }
                })
                    .then((presentationProposalResponse: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentationRequestConstants.CREATE_PRESENTATION_PROPOSAL,
                            payload: presentationProposalResponse.data.data.data
                        })

                        const getAllListAPIParameter = {
                            pageNo: 1,
                            itemsPerPage: 10,
                        }
                        this.getAllPresentProofRequest(getAllListAPIParameter)
                        dispatch({
                            type: presentationRequestConstants.SHOW_NEW_REQUEST
                        })
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                throw error;
            }
        }
    },

    showSelectedCredentialDetails(item: any) {
        return async (dispatch: any) => {
            dispatch({
                type: presentationRequestConstants.SELECTED_PRESENTATION_REQUEST_RECORD,
                payload: item
            })
        }
    }

}
