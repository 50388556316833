import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { workflowAction } from '../../Verification-Workflow/actions/workflowAction';
import { getAllInstantTaskAction } from '../action/InstantTaskListAction';
import AutomatedTaskList from '../components/AutomatedTaskList';
import InstantTaskList from '../components/InstantTaskList';
import ScheduleTaskList from '../components/ScheduleTaskList';
import ScheduleTaskListDetailsTable from '../components/ScheduleTaskListDetailsTable';
import { tHeadVerificationDetails } from '../constant';
import { t } from 'i18next';
import { presentProofAction } from 'src/View/PresentProof/action/presentProofAction';

interface IVerificationMonitoringProps {
  getAllInstantTaskAction: any,
  InstantTaskReducers: any,
  workflowAction: any,
  WorkflowReducer: any,
  presentProofAction: any
}
interface IVerificationMonitoringState {
  defaultSearchFields: any
  item_perPage: any,
  pageNo: any,
  sortingOrder: any,
  sortBy: any,
  key: any,
  isUpcoming: boolean,
  isAll: boolean,
  isOngoing: boolean,
  isFinished: boolean,
  selectedWorkflowId: any,
}

class VerificationMonitoring extends Component<IVerificationMonitoringProps, IVerificationMonitoringState> {
  constructor(props: IVerificationMonitoringProps) {
    super(props);
    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      sortBy: '',
      key: 'all',
      isUpcoming: false,
      isAll: true,
      isOngoing: false,
      isFinished: false,
      selectedWorkflowId: {},
    }
    this.onSelectWorkFlow = this.onSelectWorkFlow.bind(this);
    this.onSelectAutomatedProofs = this.onSelectAutomatedProofs.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.showInstantTaskList = this.showInstantTaskList.bind(this);
    this.backToTable = this.backToTable.bind(this);
    this.checkVerificationStatus = this.checkVerificationStatus.bind(this);
    this.showAutomatedProofs = this.showAutomatedProofs.bind(this)
    // this.activeClass = this.activeClass.bind(this);
  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, sortingOrder, sortBy, pageNo } = this.state;
    localStorage.removeItem('selectedScheduleTask');
    this.props.workflowAction.getWorkflowList(defaultSearchFields, sortingOrder, sortBy, item_perPage, pageNo);
  }

  componentWillUnmount() {
    this.props.getAllInstantTaskAction.clearFormOfVM();
    this.setState({
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortingOrder: 'DESC',
      sortBy: '',
      key: 'all',
      isUpcoming: false,
      isAll: true,
      isOngoing: false,
      isFinished: false,
      selectedWorkflowId: {},
    })
  }

  onSelectWorkFlow(event: any, selectedWorkFlow: any) {
    this.setState({
      selectedWorkflowId: selectedWorkFlow,
      key: 'all',
      isAll: true,
    })
    const defaultItem = {
      itemsPerPage: 10,
      page: 1,
      search_text: '',
      scheduleTaskSort: '',
      sortValue: 'DESC',
      status: 'all',

    }

    this.props.getAllInstantTaskAction.getListByWorkFlow(defaultItem.itemsPerPage, defaultItem.page, defaultItem.search_text, defaultItem.scheduleTaskSort, defaultItem.sortValue, defaultItem.status, selectedWorkFlow.id)
  }

  onSelectAutomatedProofs(event: any) {

    const defaultItem = {
      itemsPerPage: 10,
      page: 1,
      search_text: '',
      automatedTaskSortBy: '',
      sortValue: 'DESC',
      status: 'all',

    }

    this.props.getAllInstantTaskAction.getAllAutomatedProofs(
      defaultItem.search_text, defaultItem.status, defaultItem.sortValue, defaultItem.automatedTaskSortBy, defaultItem.itemsPerPage, defaultItem.page
    )
  }

  nextStep(key: string | any) {
    const data = {
      itemsPerPage: 10,
      page: 1,
      search_text: '',
      scheduleTaskSort: '',
      sortValue: 'DESC',
    }

    switch (key) {
      case 'all':
        this.setState({ key: key, isAll: true, isUpcoming: false, isOngoing: false, isFinished: false })
        this.props.getAllInstantTaskAction.getListByWorkFlow(data.itemsPerPage, data.page, data.search_text, data.scheduleTaskSort, data.sortValue, 'all', this.state.selectedWorkflowId.id)
        break;
      case 'upcoming':
        this.setState({ key: key, isAll: false, isUpcoming: true, isOngoing: false, isFinished: false })
        this.props.getAllInstantTaskAction.getListByWorkFlow(data.itemsPerPage, data.page, data.search_text, data.scheduleTaskSort, data.sortValue, 'Upcoming', this.state.selectedWorkflowId.id)
        break;
      case 'ongoing':
        this.setState({ key: key, isAll: false, isUpcoming: false, isOngoing: true, isFinished: false })
        this.props.getAllInstantTaskAction.getListByWorkFlow(data.itemsPerPage, data.page, data.search_text, data.scheduleTaskSort, data.sortValue, 'OnGoing', this.state.selectedWorkflowId.id)
        break;
      case 'finished':
        this.setState({ key: key, isAll: false, isUpcoming: false, isOngoing: false, isFinished: true })
        this.props.getAllInstantTaskAction.getListByWorkFlow(data.itemsPerPage, data.page, data.search_text, data.scheduleTaskSort, data.sortValue, 'Finished', this.state.selectedWorkflowId.id)
        break;
      default:
        break;
    }
  }

  showInstantTaskList() {
    localStorage.removeItem('selectedScheduleTask');
    localStorage.removeItem('selectedAutomatedTask');
    this.props.getAllInstantTaskAction.showInstantTaskList()
  }

  showAutomatedProofs() {
    localStorage.removeItem('selectedScheduleTask');
    this.props.getAllInstantTaskAction.showAutomatedProofs()
  }

  backToTable() {
    this.props.getAllInstantTaskAction.backToTable();
  }

  checkVerificationStatus(details: any) {
    this.props.getAllInstantTaskAction.checkVerificationStatus(details.schedule_task_id);
  }
  // setClassForScheduleTask() {
  //   return "btn btn-lg bg-light btn-nb-gradient-primary btn-block"
  // }
  activeClass(element: any) {
    const { selectedWFTaskList } = this.props.InstantTaskReducers;
    // console.log("schedule task list-----", selectedWFTaskList);
    if (!_.isEmpty(selectedWFTaskList)) {
      selectedWFTaskList.forEach((item: any) => {
        if (item.schedule_task_verificationWorkflowId === element.id) {
          // console.log("-----------");
          localStorage.setItem('selectedScheduleTask', element.id);
          return "btn btn-lg bg-light btn-hover-gradient btn-block"
        }
      });
    } else {
      console.log("-------in else----");
    }
  }

  public render() {
    const { workflowList, } = this.props.WorkflowReducer
    const { selectedWFTaskList, showScheduleTaskList, showInstantTaskList, dataToShowScheduledTask, checkVerificationStatusDetails, showAutomatedProofs } = this.props.InstantTaskReducers;
    const { key, isUpcoming, isAll, isOngoing, isFinished, selectedWorkflowId } = this.state;
    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-md-12 col-lg-3 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:verificationMonitoring.workflows")}</h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 border-bottom mb-3">
                    <div className="form-group">
                      <button type="button" className={showInstantTaskList ? "btn btn-lg bg-light btn-nb-gradient-primary btn-block" : "btn btn-lg bg-light btn-hover-gradient btn-block"}
                        onClick={this.showInstantTaskList}
                      >{t("screens:verificationMonitoring.instantTasks")}</button>
                    </div>
                  </div>
                  <div className="col-sm-12 border-bottom mb-3">
                    <div className="form-group">
                      <button type="button" className={showAutomatedProofs ? "btn btn-lg bg-light btn-nb-gradient-primary btn-block" : "btn btn-lg bg-light btn-hover-gradient btn-block"}
                        onClick={this.showAutomatedProofs}
                      >{t("screens:verificationMonitoring.automatedProofs")}</button>
                    </div>
                  </div>
                  {
                    workflowList.map((element: any, index: any) => {
                      this.activeClass(element)
                      return (
                        <div className="col-sm-12">
                          <div className="form-group">
                            <button type="button" className={
                              !_.isEmpty(selectedWFTaskList) ? selectedWFTaskList.map((item: any, index: any) => {
                                return (
                                  item.schedule_task_verificationWorkflowId === element.id ? "btn btn-lg bg-light btn-nb-gradient-primary btn-block" : "btn btn-lg bg-light btn-hover-gradient btn-block"
                                )
                              })
                                :
                                localStorage.getItem('selectedScheduleTask') === (element.id).toString() ? "btn btn-lg bg-light btn-nb-gradient-primary btn-block"
                                  :
                                  "btn btn-lg bg-light btn-hover-gradient btn-block"
                            }
                              onClick={(e) => this.onSelectWorkFlow(e, element)}>{element.workflowName}</button>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
          {showInstantTaskList ?
            <div className="col-md-12 col-lg-9 mb-30">
              <div className="card nb-card h-100">
                <div className="card-header bg-white border-bottom-0">
                  <h1 className="nb-title">{t("screens:verificationMonitoring.verificationMonitoring")}</h1>
                </div>
                <div className="card-body">
                  <InstantTaskList {...this.props} />
                </div>
              </div>
            </div>
            : showAutomatedProofs ?
              <div className="col-md-12 col-lg-9 mb-30">
                <div className="card nb-card h-100">
                  <div className="card-header bg-white border-bottom-0">
                    <h1 className="nb-title">{t("screens:verificationMonitoring.verificationMonitoring")}</h1>
                  </div>
                  <div className="card-body">
                    <AutomatedTaskList {...this.props} />
                  </div>
                </div>
              </div> :
              showScheduleTaskList ?
                <div className="col-md-9 col-lg-9 mb-30">
                  <div className="card nb-card h-100">
                    <div className="card-header bg-white border-bottom-0">
                      <h1 className="nb-title">{t("screens:verificationMonitoring.verificationMonitoring")}</h1>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="table-responsive"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          {!_.isEmpty(checkVerificationStatusDetails) ?
                            <>
                              {/* <div className="row mb-3">
                              <div className="col-sm-12 text-right">
                                <button className="btn"
                                  onClick={this.backToTable}
                                ><i className="nb-ico nb-back-arrow-circle"
                                  style={{ height: '30px', width: '30px' }}></i></button>
                              </div>
                            </div> */}
                              <h3>
                                {
                                  !_.isEmpty(checkVerificationStatusDetails) && checkVerificationStatusDetails[0].scheduleTaskEntity &&
                                    checkVerificationStatusDetails[0].scheduleTaskEntity.attributes.hasOwnProperty('title') ? checkVerificationStatusDetails[0].scheduleTaskEntity.attributes.title : ' '
                                }
                              </h3>

                              <TableComponents
                                tHead={tHeadVerificationDetails}
                                tableContent={checkVerificationStatusDetails}
                              />
                            </>
                            :
                            <>
                              <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation"
                                  onClick={() => { this.nextStep('all') }}
                                >
                                  <a className={key === 'all' ? "nav-link active" : "nav-link"} id={key} data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true"
                                    aria-disabled={key === 'upcoming'}
                                  >{t("screens:verificationMonitoring.all")}</a>
                                </li>
                                <li className="nav-item" role="presentation"
                                  onClick={() => { this.nextStep('upcoming') }}
                                >
                                  <a className={key === 'upcoming' ? "nav-link active" : "nav-link"} id={key} data-toggle="tab" href="#upcoming" role="tab" aria-controls="upcoming" aria-selected="false"
                                    aria-disabled={key === 'ongoing'}
                                  >{t("screens:verificationMonitoring.upcoming")}</a>
                                </li>
                                <li className="nav-item" role="presentation"
                                  onClick={() => { this.nextStep('ongoing') }}
                                >
                                  <a className={key === 'ongoing' ? "nav-link active" : "nav-link"} id={key} data-toggle="tab" href="#ongoing" role="tab" aria-controls="ongoing" aria-selected="false"
                                    aria-disabled={key === 'finished'}
                                  >{t("screens:verificationMonitoring.ongoing")}</a>
                                </li>
                                <li className="nav-item" role="presentation"
                                  onClick={() => { this.nextStep('finished') }}
                                >
                                  <a className={key === 'finished' ? "nav-link active" : "nav-link"} id={key} data-toggle="tab" href="#finished" role="tab" aria-controls="finished" aria-selected="false"
                                  >{t("screens:verificationMonitoring.finished")}</a>
                                </li>
                              </ul>
                              <div className="tab-content pt-3" id="myTabContent">
                                {isAll ?
                                  <div className={key === 'all' ? "tab-pane fade show active" : "tab-pane fade"} id="all" role="tabpanel" aria-labelledby="all-tab">
                                    {
                                      _.isEmpty(dataToShowScheduledTask) ?
                                        <ScheduleTaskList {...this.props} status='all' selectedWorkFlow={selectedWorkflowId} />
                                        :
                                        <ScheduleTaskListDetailsTable
                                          dataToShowScheduledTask={dataToShowScheduledTask} {...this.props}
                                          checkVerificationStatus={this.checkVerificationStatus}
                                        />
                                    }

                                  </div>
                                  :
                                  isUpcoming ?
                                    <div className={key === 'upcoming' ? "tab-pane fade show active" : "tab-pane fade"} id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                                      {
                                        _.isEmpty(dataToShowScheduledTask) ?
                                          <ScheduleTaskList {...this.props} status='upcoming' selectedWorkFlow={selectedWorkflowId} />
                                          :
                                          <ScheduleTaskListDetailsTable
                                            dataToShowScheduledTask={dataToShowScheduledTask} {...this.props}
                                            checkVerificationStatus={this.checkVerificationStatus}
                                          />
                                      }
                                    </div>
                                    :
                                    isOngoing ?
                                      <div className={key === 'ongoing' ? "tab-pane fade show active" : "tab-pane fade"} id="ongoing" role="tabpanel" aria-labelledby="ongoing-tab">
                                        {
                                          _.isEmpty(dataToShowScheduledTask) ?
                                            <ScheduleTaskList {...this.props} status='ongoing' selectedWorkFlow={selectedWorkflowId} />
                                            :
                                            <ScheduleTaskListDetailsTable
                                              dataToShowScheduledTask={dataToShowScheduledTask} {...this.props}
                                              checkVerificationStatus={this.checkVerificationStatus}
                                            />
                                        }
                                      </div>
                                      :
                                      isFinished ?
                                        <div className={key === 'finished' ? "tab-pane fade show active" : "tab-pane fade"} id="finished" role="tabpanel" aria-labelledby="finished-tab">
                                          {
                                            _.isEmpty(dataToShowScheduledTask) ?
                                              <ScheduleTaskList {...this.props} status='finished' selectedWorkFlow={selectedWorkflowId} />
                                              :
                                              <ScheduleTaskListDetailsTable
                                                dataToShowScheduledTask={dataToShowScheduledTask} {...this.props}
                                                checkVerificationStatus={this.checkVerificationStatus}
                                              />
                                          }
                                        </div>
                                        :
                                        <></>
                                }
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <></>
          }
        </div>
      </>


    )
  }
}
function mapStateToProps(state: any) {

  return {
    InstantTaskReducers: state.InstantTaskReducers,
    WorkflowReducer: state.WorkflowReducer,
    PresentProofReducer: state.PresentProofReducer
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    getAllInstantTaskAction: bindActionCreators(getAllInstantTaskAction, dispatch),
    workflowAction: bindActionCreators(workflowAction, dispatch),
    presentProofAction: bindActionCreators(presentProofAction, dispatch)
  }
}

const verificationMonitoring = connect(mapStateToProps, mapDispatchToProps)(VerificationMonitoring);
export { verificationMonitoring as VerificationMonitoring }
