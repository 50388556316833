/**
 * Import all files from a webpack context
 *
 * NOTE: Webpack "require.context" function only accepts literals (no variables), so
 *         the webpack context must already be imported for this function!
 *
 * @param   context       - Webpack import context
 * @param   importDefault - Whether to reduce to default import (images, etc)
 * @returns Imported files
 *
 * @source https://stackoverflow.com/a/53025624/4206438
 * @example
 * const fileContext = require.context("src/assets/images", false, /\.(png|jpg)$/);
 * const files = importAll(fileContext);
 */
const importAll = (context: __WebpackModuleApi.RequireContext, importDefault = true): Record<string, any> => {
  return context.keys().reduce((accum, fileName) => {
    let safeFileName = fileName.replace("./", "");
    safeFileName = safeFileName.slice(0, safeFileName.lastIndexOf("."));
    const file = context(fileName);

    return {
      ...accum,
      [safeFileName]: importDefault ? file.default : file,
    };
  }, {});
}

export { importAll };
