import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';

export interface IHolderDetailsProps {
  HolderReducer: any,
  holderAction: any,
}

export interface IHolderDetailsState {
}

class HolderDetails extends React.Component<IHolderDetailsProps, IHolderDetailsState> {
  constructor(props: IHolderDetailsProps) {
    super(props);

    this.state = {
    }
    this.closeDetails = this.closeDetails.bind(this);
  }

  closeDetails() {
    this.props.holderAction.clearSelection();
  }

  public render() {
    const { selectHolder } = this.props.HolderReducer
    return (
      <div className="card nb-card h-100">
        <div className="card-header bg-white border-bottom-0">
          <h1 className="nb-title">{t(`screens:onboardingNewHolder.employeeDetails`)}<i className="fas fa-times float-right" onClick={this.closeDetails}></i></h1>
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className="row">
                <div className="col-sm-6">
                  <label>{t("screens:onboardingNewHolder.givenName")}:</label>
                </div>
                <div className="col-sm-6 text-right">
                  <label className="font-weight-bold">
                    {selectHolder.name}
                  </label>
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-sm-6">
                  <label>{t("screens:onboardingNewHolder.emailAddress")}:</label>
                </div>
                <div className="col-sm-6 text-right">
                  <label className="font-weight-bold">
                    {selectHolder.email}
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {}
}

function mapDispatchToProps(dispatch: any) {
  return {
    // holderAction: bindActionCreators(holderAction, dispatch),
  }
}

const connectedHolderDetails = connect(mapStateToProps, mapDispatchToProps)(HolderDetails);
export { connectedHolderDetails as HolderDetails };
