import * as React from 'react';
import PageLoader from '../../../Common Components/Components/pageLoader';
import { history } from "../../../index";

// Utilities
import images from 'src/utils/images';

export interface ISuccessPageProps {
  LoaderReducer: any,
}

export interface ISuccessPageState {
}

export default class SuccessPage extends React.Component<ISuccessPageProps, ISuccessPageState> {
  constructor(props: ISuccessPageProps) {
    super(props);

    this.state = {
    }
  }
  home = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => {
    console.log(`first-time-user - home`)
    history.push('/first-time-user')
  };

  componentDidMount() {

    console.log(`first-time-user - SuccessPage - componentDidMount`)
    setTimeout(() => {
      history.push('/first-time-user')
    }, 10000);
  }

  public render() {
    const { loader } = this.props.LoaderReducer;
    return (
      <>
        {
          loader ?
            <PageLoader></PageLoader>
            : null
        }
        <div className="text-center">
          <img alt="Success" className="mb-3" src={images.illustrationSuccess} />
          <h1>Submitted Successfully!</h1>
          <p>Please check your email for future verification.</p><br />
          <div className="alert alert-info" role="alert">
            <i className="fas fa-info-circle"></i> You will be onboarded on Northern Block Platform shortly. <button className="btn nb-link" onClick={this.home}>BACK TO HOME</button>
          </div>
        </div>
      </>
    );
  }
}
