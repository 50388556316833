// Utilities
import { importAll } from "./import";
import { overrideObjects } from "./override";
import { camelCase } from "./strings";

// Assets
import companyLogo from "src/assets/images/company_logo.png";
import companyLogoWhite from "src/assets/images/company_logo_white.png";
import illustrationAddSuccess from "src/assets/images/illustration_add_success.png";
import illustrationConfig from "src/assets/images/illustration_config.png";
import illustrationSuccess from "src/assets/images/illustration_success.png";
import placeholderProfile from "src/assets/images/placeholder_profile.png";
import placeholderRectangleClear from "src/assets/images/placeholder_rectangle_clear.png";
import placeholderRectangleSolid from "src/assets/images/placeholder_rectangle_solid.png";
import certifiedBlank from 'src/assets/images/certified-blank.png'
import certified from 'src/assets/images/certified.png'
import bgCertified from 'src/assets/images/bg-certified.png'
import certifiedGray from 'src/assets/images/certified-gray.png'
import credential from 'src/assets/images/credential.png'
import greenCheck from '../assets/images/green_check.png'
import idBadge from '../assets/images/clarity_id-badge-line.svg'
import refresh from '../assets/images/refresh.svg'
import titleIcon from '../assets/images/title-icon.svg'
import certificate from '../assets/images/certificate.png'

export interface IAppImages {
  /** Company logo (colourized) */
  companyLogo: string;
  /** Company logo (white version) */
  companyLogoWhite: string;
  /** Semi-specific success illustration after adding something */
  illustrationAddSuccess: string;
  /** Generic config illustration */
  illustrationConfig: string;
  /** General success image (no specific action) */
  illustrationSuccess: string;
  /** Placeholder profile image */
  placeholderProfile: string;
  /** Placeholder image (rectangular, clear background) */
  placeholderRectangleClear: string;
  /** Placeholder image (rectangular, solid background) */
  placeholderRectangleSolid: string;

  certifiedBlank: string;
  certified: string
  bgCertified: string
  certifiedGray: string;
  credential: string  

  greenCheck: string
  idBadge: string
  refresh:string;
  titleIcon: string
  certificate: string
}

// NOTE: Images MUST be "imported" instead of "required" to properly display!
const baseImages: IAppImages = {
  companyLogo,
  companyLogoWhite,
  illustrationAddSuccess,
  illustrationConfig,
  illustrationSuccess,
  placeholderProfile,
  placeholderRectangleClear,
  placeholderRectangleSolid,
  certifiedBlank,
  certified,
  bgCertified,
  certifiedGray,
  credential,
  greenCheck,
  idBadge,
  refresh,
  titleIcon,
  certificate
};

// Dynamically import all appropriate images files during build step
const overrideImageContext = require.context("src/overrides", false, /\.(png)$/); // prettier-ignore
const overrideImageFiles = importAll(overrideImageContext);

// Convert image names to camel case (required for overriding!)
const overrideImages = Object.keys(overrideImageFiles ?? {}).reduce(
  (accum, key) => {
    const camelKey = camelCase(key);
    return { ...accum, [camelKey]: overrideImageFiles[key] };
  },
  {} as Record<string, any>
);

const images = overrideObjects<IAppImages>(baseImages, overrideImages);

export default images;
