import { t } from "i18next";
import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import images from "src/utils/images";
import './AuthenticateIssuerModal.css'


interface AuthenticateIssuerModalProps {
    selectedCredentialRecord: any
    isModalShow: boolean
    isOfferAccepted: boolean
    orgProfileReducer: any
    LoaderReducer: any
    closeModal: () => void
    handleVerifyTrustRegistry: (match: boolean, credentialExchangeId: string) => void
    handleDIDAuthenticate: (did: string) => void
    handleGetDIDDoc: (did: string) => void
    handleCheckCredentialIssuanceAuthority: (did: string, credentialDefinitionId: string) => void
}

export const AuthenticateIssuerModal = ({
    selectedCredentialRecord,
    isModalShow,
    isOfferAccepted,
    orgProfileReducer,
    closeModal,
    handleVerifyTrustRegistry,
    handleDIDAuthenticate,
    handleCheckCredentialIssuanceAuthority,
    handleGetDIDDoc
}: AuthenticateIssuerModalProps) => {
    const { organization, connection, credentialExchangeId } = selectedCredentialRecord
    const [step, setStep] = useState<number>(1)
    const [displayStep, setDisplayStep] = useState<number>(1)

    let interval: any = undefined;
    const handleStep = (stepNo: number) => {
        setStep(stepNo)

        if (stepNo === 2) {
            handleDIDAuthenticate(selectedCredentialRecord.credentialDefinitionId.split(':')[0])
            setCheckingDID(true)
            setDisplayStep(1)
        }
        if (stepNo === 3) {
            setCheckingIssueAuthority(true)
            setProgress(0)
            setDisplayStep(2)
        }
    }


    const [checkingDID, setCheckingDID] = useState(false);
    const [authenticDID, setAuthenticDID] = useState(false);
    const [checkingIssueAuthority, setCheckingIssueAuthority] = useState(false);
    const [authorizedIssuer, setAuthorizedIssuer] = useState(false);
    const [progress, setProgress] = useState(0);
    const [authenticData, setAuthenticData] = useState<any>({})
    const [didDoc, setDidDoc] = useState<any>({})
    const [issuanceAuthorization, setIssuanceAuthorization] = useState<string>('')
    const [authenticateMatch, setAuthenticateMatch] = useState(true);
    const [credDefMatch, setCredDefMatch] = useState(true);

    useEffect(() => {
        handleGetDIDDoc(selectedCredentialRecord.credentialDefinitionId.split(':')[0])
    }, [])

    useEffect(() => {
        if (checkingDID) {
            interval = setInterval(() => {
                setProgress((prev) => prev + 1);
            }, 10);
        } else {
            clearInterval(interval);
        }
    }, [checkingDID]);

    useEffect(() => {
        if (checkingIssueAuthority) {
            interval = setInterval(() => {
                setProgress((prev) => prev + 1);
            }, 10);
        } else {
            clearInterval(interval);
        }
    }, [checkingIssueAuthority]);

    useEffect(() => {
        if (progress === 100) {
            setCheckingDID(false);
            clearInterval(interval);
            if (step === 2) {
                setAuthenticDID(true)
            }
            if (step === 3) {
                setAuthorizedIssuer(true)
                handleCheckCredentialIssuanceAuthority(authenticData.did, selectedCredentialRecord.credentialDefinitionId)
            }
        }
    }, [progress, interval]);

    useEffect(() => {
        if (orgProfileReducer.authenticData !== authenticData) {
            setAuthenticData(orgProfileReducer.authenticData)

        }

        if (orgProfileReducer.issuanceAuthorization && issuanceAuthorization !== orgProfileReducer.issuanceAuthorization) {
            setIssuanceAuthorization(orgProfileReducer.issuanceAuthorization)
        }

        if (orgProfileReducer.didDetails && didDoc !== orgProfileReducer.didDetails) {
            setDidDoc(orgProfileReducer.didDetails)
        }

    }, [orgProfileReducer.authenticData, orgProfileReducer.issuanceAuthorization, orgProfileReducer.didDetails])

    useEffect(() => {
        if ((Object.keys(didDoc).length > 0 && didDoc.metadata.owner_name !== authenticData.owner_name)
            || (Object.keys(didDoc).length > 0 && didDoc.did_document.id !== authenticData.did)) {
            console.log('========================> authenticData not matched')
            setAuthenticateMatch(false)
        }
        if ((Object.keys(didDoc).length > 0 && didDoc.metadata.owner_name === authenticData.owner_name)
            || (Object.keys(didDoc).length > 0 && didDoc.did_document.id === authenticData.did)) {
            setAuthenticateMatch(true)
        }
    }, [authenticData])

    useEffect(() => {
        if (authorizedIssuer) {
            if (!issuanceAuthorization || issuanceAuthorization === 'NOT_FOUND'
                || selectedCredentialRecord.credentialDefinitionId.split(':')[4] !== (issuanceAuthorization.split(':')[4])?.replace(/['"]+/g, "")) {
                console.log('===============> issuance not matched')
                setCredDefMatch(false)
            }
            if ((issuanceAuthorization)
                && selectedCredentialRecord.credentialDefinitionId.split(':')[4] === (issuanceAuthorization.split(':')[4])?.replace(/['"]+/g, "")) {
                setCredDefMatch(true)
            }
        }
    }, [issuanceAuthorization])

    return (
        <Modal isOpen={isModalShow}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="nb-modal animated slideInRight"
            toggle={closeModal}
        >
            <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }} style={{
                background: "linear-gradient(180deg, #333333, 0%, #525252 0.01%, #333333 120.2%)",
                borderRadius: "10px 10px 0px 0px"
            }}>
                <div className="d-flex justify-content-center mb-2">
                    <div className="col-sm-2 text-left" >

                    </div>
                    <div className="col-sm-8">
                        <div className="col-sm-12 form-group">
                            <h3 className="text-white">{t("screens:authenticateIssuerModal.modalTitle")}
                                <img src={images.certified} className="verified" alt='certified-blank' /></h3>
                        </div>
                        <div className="col-sm-12">
                            <small>
                                <p className="text-white">{t("screens:authenticateIssuerModal.step")} {displayStep}/2 {t("screens:authenticateIssuerModal.modalSubTitle1", { stepNo: step })}</p>
                            </small>
                        </div>
                    </div>
                    <div className="col-sm-2 text-right">
                        <button className="text-secondary fa fa-2x fa-times" onClick={closeModal}></button>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="row">

                    <div className="col-sm-4">

                        <img src={organization.logoUrl} alt={t("screens:authenticateIssuerModal.logo")} width="160px" />

                        <div className="col py-2 pb-5">
                            <div className="modal-title3 pt-4">{t("screens:authenticateIssuerModal.leftPanelTitle1")}</div>
                            <h2 className="modal-companyname py-3">{connection.theirLabel}</h2>
                            <hr />
                            <div className="modal-title3 pt-4">{t("screens:authenticateIssuerModal.leftPanelTitle2")}</div>
                            <h2 className="modal-companydid">{t("screens:authenticateIssuerModal.name")}: {Object.keys(didDoc).length > 0 && didDoc.metadata.owner_name}</h2>
                            <h2 className="modal-companydid">{t("screens:authenticateIssuerModal.publicDID")}: {Object.keys(didDoc).length > 0 && didDoc.did_document.id}</h2>
                            <h2 className="modal-companydid">{t("screens:authenticateIssuerModal.domainName")}: {Object.keys(didDoc).length > 0 && didDoc.metadata.trust_anchor}</h2>
                            <hr />
                            <div className="modal-title3 pt-4 ">{t("screens:authenticateIssuerModal.leftPanelTitle3")}</div>
                            <div className="modal-companydid py-2">
                                <div className="card nb-card h-100 p-3">
                                    <span>

                                        <img src={images.credential} className="verified" alt='credential' />&nbsp;
                                        {selectedCredentialRecord.credentialDefinitionId.split(':')[4]}
                                    </span>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-sm-8" style={{ background: '#EEF1F5' }}>
                        {
                            step === 1 &&
                            <>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className=" h-100 d-flex justify-content-center align-items-center form-group">
                                            <div>
                                                <h3 className="text-center">{t("screens:authenticateIssuerModal.step1Title1")} </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            step === 2 &&
                            <>
                                <div className="card bg-light m-3" style={{ maxWidth: "50rem", border: "none" }}>
                                    <img className="card-img" src={images.bgCertified} alt="Card" />
                                    {
                                        !authenticDID ?
                                            <div className="card-img-overlay px-5">
                                                <h6 className="card-title  pt-5 m-3" >{t("screens:authenticateIssuerModal.step2ProcessingTitle")}</h6>
                                                <p className="card-text m-3">{t("screens:authenticateIssuerModal.step2ProcessingSubTitle")}</p>
                                                <p className="card-text m-3">
                                                    <ProgressBar
                                                        className="nb-progress-bar mb-3"
                                                        now={progress}
                                                    /></p>
                                            </div>
                                            :
                                            Object.keys(authenticData).length > 0 ?
                                                <>
                                                    <div className="card-img-overlay px-3">

                                                        <p className="m-4 mb-5">
                                                            <h4 className="text-black">{t("screens:authenticateIssuerModal.step2Title")}</h4>
                                                            <p className="text-black">{t("screens:authenticateIssuerModal.step2TitleNote")}</p>
                                                        </p>
                                                        <div className="px-3">

                                                            <ul className="nb-list">
                                                                <li className="pl-1 pr-0">
                                                                    <div className="row px-3">

                                                                        <div className="col-sm-8">
                                                                            <p className="card-text">{t("screens:authenticateIssuerModal.name")}: {authenticData.owner_name}</p>
                                                                        </div>
                                                                        <div className="col-sm-4 text-right">
                                                                            {Object.keys(didDoc).length > 0 && didDoc.metadata.owner_name === authenticData.owner_name ?
                                                                                <>
                                                                                    <img src={images.certified} className="verified" alt='certified-blank' />
                                                                                    <span className="text-primary">{t("screens:authenticateIssuerModal.match")}</span>
                                                                                </> :
                                                                                <>
                                                                                    <img src={images.certifiedGray} className="verified" alt='certified-gray' />
                                                                                    <span className="text-danger">{t("screens:authenticateIssuerModal.misMatch")}</span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="pl-1 pr-0">
                                                                    <div className="row px-3">
                                                                        <div className="col-sm-8">
                                                                            <p className="card-text">{t("screens:authenticateIssuerModal.publicDID")}: {authenticData.did}</p>
                                                                        </div>
                                                                        <div className="col-sm-4 text-right">
                                                                            {Object.keys(didDoc).length > 0 && didDoc.did_document.id === authenticData.did ?
                                                                                <>
                                                                                    <img src={images.certified} className="verified" alt='certified-blank' />
                                                                                    <span className="text-primary">{t("screens:authenticateIssuerModal.match")}</span>
                                                                                </> :
                                                                                <>console.log('authenticData', authenticData);console.log('console.log(console.log('authenticData', authenticData);'authenticData', authenticData);.log('authenticData', authenticData);nsole.log('authenticData', authenticData);cData', authenticData);
                                                                                    <img src={images.certifiedGray} className="verified" alt='certified-gray' />
                                                                                    <span className="text-danger">{t("screens:authenticateIssuerModal.misMatch")}</span>
                                                                                </>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div style={{ background: '#EEF1F5' }}>
                                                        <div className="col-sm-12">

                                                            <p className="text-black">{t("screens:authenticateIssuerModal.step2FooterNote")}</p>
                                                            <p className="text-black">{t("screens:authenticateIssuerModal.step2FooterNote2")}</p>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="pl-2 pt-2">

                                                                <label className="align-self-center" htmlFor="trustRegistryName">
                                                                    <input type="checkbox"
                                                                        id='trustRegistryName'
                                                                        name='trustRegistryName'
                                                                        checked={true}
                                                                    />&nbsp;{t("screens:authenticateIssuerModal.trustRegistry")}: {authenticData.registry}</label>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </>
                                                : <div className="card-img-overlay px-3">
                                                    <h4 className="text-center">DID Document is not updated with DNS name or Trust Registry URL.</h4>
                                                </div>
                                    }
                                </div>
                            </>
                        }
                        {
                            step === 3 &&
                            <>
                                <div className="card bg-light m-3" style={{ maxWidth: "50rem", border: "none" }}>
                                    <img className="card-img" src={images.bgCertified} alt="Card" />
                                    {
                                        !authorizedIssuer ?
                                            <div className="card-img-overlay px-5">
                                                <h6 className="card-title  pt-5 m-3" >{t("screens:authenticateIssuerModal.step3ProcessingTitle")}</h6>
                                                <p className="card-text m-3">{t("screens:authenticateIssuerModal.step3ProcessingSubTitle")}</p>

                                                <p className="card-text m-3">
                                                    <ProgressBar
                                                        className="nb-progress-bar mb-3"
                                                        now={progress}
                                                    /></p>
                                            </div>
                                            :
                                            <>
                                                <div className="card-img-overlay px-3">
                                                    <p className="card-text m-2 mb-2">
                                                        <h5 className="text-black">{t("screens:authenticateIssuerModal.step3Section1Title")}</h5>
                                                        <p className="text-black">{t("screens:authenticateIssuerModal.step3Section1Description")}</p>
                                                    </p>

                                                    <div className="row px-3">
                                                        <div className="col-sm-9">
                                                            <p className="card-text">{t("screens:authenticateIssuerModal.name")}: {authenticData.owner_name}</p>
                                                        </div>
                                                        <div className="col-sm-3 text-right">
                                                            {Object.keys(didDoc).length > 0 && didDoc.metadata.owner_name === authenticData.owner_name ?
                                                                <>
                                                                    <img src={images.certified} className="verified-check" alt='certified-blank' />
                                                                    <span className="text-primary">{t("screens:authenticateIssuerModal.match")}</span>
                                                                </> :
                                                                <>
                                                                    <img src={images.certifiedGray} className="verified-check" alt='certified-blank' />
                                                                    <span className="text-danger">{t("screens:authenticateIssuerModal.misMatch")}</span>
                                                                </>

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row px-3">
                                                        <div className="col-sm-9">
                                                            <p className="card-text">{t("screens:authenticateIssuerModal.publicDID")}: {authenticData.did}</p>
                                                        </div>
                                                        <div className="col-sm-3 text-right">
                                                            {Object.keys(didDoc).length > 0 && didDoc.did_document.id === authenticData.did ?
                                                                <>
                                                                    <img src={images.certified} className="verified-check" alt='certified-blank' />
                                                                    <span className="text-primary">{t("screens:authenticateIssuerModal.match")}</span>
                                                                </> :
                                                                <>
                                                                    <img src={images.certifiedGray} className="verified-check" alt='certified-gray' />
                                                                    <span className="text-danger">{t("screens:authenticateIssuerModal.misMAatch")}</span>
                                                                </>

                                                            }
                                                        </div>
                                                    </div>
                                                    <p className="card-text m-2 mb-3">
                                                        <h5 className="text-black">{t("screens:authenticateIssuerModal.step3Section2Title")}</h5>
                                                        <p className="text-black">{t("screens:authenticateIssuerModal.step3Section2Description")}</p>
                                                    </p>
                                                    <div className="row px-3">
                                                        <div className="col-sm-9">
                                                            <p className="card-text">{t("screens:authenticateIssuerModal.trustRegistryConsulted")}: {authenticData.registry}</p>
                                                        </div>
                                                        <div className="col-sm-3 text-right">
                                                        </div>
                                                    </div>
                                                    <div className="row px-3">
                                                        <div className="col-sm-9">
                                                            <p className="card-text">{t("screens:authenticateIssuerModal.publicDID")}: {authenticData.did}</p>
                                                        </div>
                                                        <div className="col-sm-3 text-right">
                                                            {
                                                                Object.keys(didDoc).length > 0 && didDoc.did_document.id === authenticData.did ?
                                                                    <>
                                                                        <img src={images.certified} className="verified-check" alt='certified-blank' />
                                                                        <span className="text-primary">{t("screens:authenticateIssuerModal.match")}</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <img src={images.certifiedGray} className="verified-check" alt='certified-gray' />
                                                                        <span className="text-danger">{t("screens:authenticateIssuerModal.misMatch")}</span>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row px-3">
                                                        <div className="col-sm-9">
                                                            <p className="card-text">{t("screens:authenticateIssuerModal.credentialAuthorizedToIssue")}: {(issuanceAuthorization && issuanceAuthorization.split(':')[4])?.replace(/['"]+/g, "")}</p>
                                                        </div>
                                                        <div className="col-sm-3 text-right">
                                                            {
                                                                issuanceAuthorization
                                                                    && selectedCredentialRecord.credentialDefinitionId.split(':')[4] === (issuanceAuthorization.split(':')[4])?.replace(/['"]+/g, "") ?
                                                                    <>
                                                                        <img src={images.certified} className="verified-check" alt='certified' />&nbsp;
                                                                        <span className="text-primary">{t("screens:authenticateIssuerModal.authorized")}</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <img src={images.certifiedGray} className="verified-check" alt='certified-blank' />&nbsp;
                                                                        <span className="text-danger">{t("screens:authenticateIssuerModal.notAuthorized")}</span>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>

            </ModalBody>
            <ModalFooter >
                {step === 1 &&
                    <>
                        <button
                            type="button"
                            className="btn btn-nb-outline-primary rounded-pill"
                            onClick={() => handleStep(2)}
                        >
                            {t("screens:authenticateIssuerModal.step1RightButton")}
                        </button>
                    </>
                }
                {step === 2 && authenticDID && Object.keys(authenticData).length > 0 &&
                    <>
                        <button
                            type="button"
                            className="btn btn-nb-outline-primary rounded-pill"
                            onClick={() => handleStep(3)}
                        >
                            {t("screens:authenticateIssuerModal.step2RightButton")}
                        </button>
                    </>
                }
                {step === 2 && authenticDID && Object.keys(authenticData).length === 0 &&
                    <>
                        <button
                            type="button"
                            className="btn btn-nb-outline-primary rounded-pill"
                            onClick={() => handleVerifyTrustRegistry(false, credentialExchangeId)}
                        >
                            {t("screens:authenticateIssuerModal.close")}
                        </button>
                    </>
                }
                {step === 3 && authorizedIssuer &&
                    <>
                        <span></span>
                        <button
                            type="button"
                            className="btn btn-nb-outline-primary rounded-pill"
                            onClick={() => handleVerifyTrustRegistry(((authenticateMatch && credDefMatch) ? true : false), credentialExchangeId)}
                            disabled={isOfferAccepted}
                        >
                            {t("screens:authenticateIssuerModal.continue")}
                        </button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )
}