import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { emailTemplateActions } from '../actions/editEmailTemplatesAction';
import EditEmailTemplates from '../components/EditEmailTemplates';
import EmailTemplatesList from '../components/EmailTemplatesList';

export interface IEmailTemplatesProps {
  EmailTemplatesReducers: any,
  emailTemplateActions: any,
}

export interface IEmailTemplatesState {
}

class EmailTemplates extends React.Component<IEmailTemplatesProps, IEmailTemplatesState> {
  constructor(props: IEmailTemplatesProps) {
    super(props);

    this.state = {

    }
  }
  
componentDidMount() {
  this.props.emailTemplateActions.getAllEmailTemplates();
}

  public render() {
    return (
      <>
        <div className="row gutters-sm">
          <div className="col-md-12 col-lg-4 mb-30">
            <div className="card nb-card-noborder h-100">
              <div className="card-header bg-white">
                <h1>Email Templates</h1>
              </div>
              <div className="card-body">
                <EmailTemplatesList {...this.props} />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8 mb-30">
            <EditEmailTemplates {...this.props} />
          </div>

        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const EmailTemplatesReducers = state.EmailTemplatesReducers;
  return { EmailTemplatesReducers }
}

function mapDispatchToProps(dispatch: any) {
  return {
    emailTemplateActions: bindActionCreators(emailTemplateActions, dispatch),
  }
}

const connectedEmailTemplates = connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
export { connectedEmailTemplates as EmailTemplates };