export const tHeadforGovRuleList = [{
    'name': 'CATEGORY',
    'label': "ruleType"
}, {
    'name': 'DATE ADDED',
    'label': "createDateTime"
}, {
    'name': '',
    'label': "details"
}]

export const filterGovernanceList = [{
    'name': 'Id',
    'tag' : 'id'
  }, {
    'name': 'Rule Type',
    'tag' : 'ruleType'
  }, {
    'name': 'Created Date',
    'tag' : 'createDateTime'
  }
  ];