import { t } from 'i18next';

export const tHeadChatsConnectionList = [{
    'name': `${t("screens:chat.date")}`,
    'label': "lastReceivedMessageDate"
}, {
    'name': `${t("screens:chat.connection")}`,
    'label': "holderName"
}, {
    'name': `${t("screens:chat.connectionDID")}`,
    'label': "theirDid"
}, {
    'name': `${t("screens:chat.unread")}`,
    'label': "totalMessageReceived"
}, {
    'name': '',
    'label': "details"
}];

export const filterExistingChatConnections = [{
    'name': `${t("screens:chat.constAll")}`,
    'tag': 'all'
},
{
    'name': `${t("screens:chat.constAllEmployee")}`,
    'tag': 'employee'
}, {
    'name': `${t("screens:chat.constAllClient")}`,
    'tag': 'client'
}
];

export const itemPerPageForChatsMessages = 20
