import { holderConst } from "../../types";
import { t } from 'i18next';

const initialState = {
  qrData: "",
  holderList: [],
  totalItems: 0,
  selectHolder: {},
  // selectedClient:{},
  tHead: [],
  emailSent: false,
  // inviteNewClient: false,
  showErrorMessage: false,
  showErrorMessageName: false,
}

export function HolderReducer(state = initialState, action: any) {
  let tHead = [{
    'name': `${t("screens:onboardingNewHolder.headGivenName")}`,
    'label': "holderName"
  }, {
    'name': `${t("screens:onboardingNewHolder.headEmail")}`,
    'label': "email"
  }, {
    'name': `${t("screens:onboardingNewHolder.headStatus")}`,
    'label': "connectionStatus"
  }];
  switch (action.type) {
    case holderConst.GET_HOLDER_QR:
      return Object.assign({}, state, {
        qrData: action.payload,
        showErrorMessage: false,
      });
    case holderConst.GET_HOLDER_LIST:
      return Object.assign({}, state, {
        holderList: action.tmpArray,
        totalItems: action.payload.data.data.totalItems,
        tHead: tHead,
        selectHolder: {},
        showErrorMessageName: false,
        showErrorMessage: false,
      });
    case holderConst.SELECT_HOLDER:
      return Object.assign({}, state, {
        selectHolder: action.payload,
      });
    // case holderConst.SELECT_CLIENT:
    //   return Object.assign({}, state, {
    //     selectedClient: action.payload,
    //   });
    case holderConst.CLEAR_SELECTION:
      return Object.assign({}, state, {
        selectHolder: {},
        // inviteNewHolder: false,
        // selectedClient: {},
      });
    case holderConst.SHOW_NEW_INVITE:
      return Object.assign({}, state, {
        // inviteNewHolder: true,
        emailSent: false,
        showErrorMessage: false,
      });
    case holderConst.EMAIL_SENT:
      return Object.assign({}, state, {
        emailSent: true,
        showErrorMessage: false,
      });
    case holderConst.INVITE_NEW_HOLDER:
      return Object.assign({}, state, {
        // inviteNewHolder: false,
      });
    case holderConst.CHECK_EXISTING_HOLDER_EMAILID:
      return Object.assign({},
        state,
        {
          showErrorMessage: true,
        });
    case holderConst.CHECK_EXISTING_HOLDER_NAME:
      return Object.assign({},
        state,
        {
          showErrorMessageName: true,
        });
    case holderConst.HIDE_CHECK_EXISTING_HOLDER_EMAILID:
      return Object.assign({},
        state,
        {
          showErrorMessage: false,
        })
    case holderConst.HIDE_CHECK_EXISTING_HOLDER_NAME:
      return Object.assign({},
        state,
        {
          showErrorMessageName: false,
        })
    default:
      return state
  }
}

