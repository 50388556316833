import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { myCredential, loaderConst } from "../../types"
import { history } from '../../../index'
import { t } from 'i18next';

/* Interface for pagination in the credential list. */
interface ICredentialOfferList {
  pageNo: number,
  itemsPerPage: number,
  searchText: string,
  sortValue: string,
  sortBy: string,
  filterText: string
}

export const myCredentialsAction = {


  /**
   * Method to get all credential list which includes the offer received as well as stored credentials.
   * @param credentialOfferParameter Parameter includes search and pagination values.
   * @returns It returns the list of credentials.
   */
  getAllCredentialOfferList(credentialOfferParameter: ICredentialOfferList) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        const params = {
          page: credentialOfferParameter.pageNo,
          items_per_page: credentialOfferParameter.itemsPerPage,
          ...(credentialOfferParameter.searchText && { search_text: credentialOfferParameter.searchText }),
          ...(credentialOfferParameter.sortValue && { credential_sort: credentialOfferParameter.sortBy }),
          ...(credentialOfferParameter.sortBy && { sort_value: credentialOfferParameter.sortBy }),
          ...(credentialOfferParameter.filterText && { filter_text: credentialOfferParameter.filterText }),
        }

        /* API call to get all credential offer and saved credential list */
        return await axios.get(`${config.apiUrl}/issue-credential/records/organization-credentials`,
          {
            headers: { "Authorization": `Bearer ${token}` },
            params
          })
          .then((credentialList) => {
            /* After getting success response from API call, validate it and then 
            dispatch the get all credential list. */
            if (credentialList.data) {
              credentialList.data.data.data.map((element: any) => {
                // TODO Revocation element['state'] = `${t("screens:myCredentials.constStoredWallet")}`;
                if (element.state === "credential_acked") {
                  element['state'] = "Stored in Wallet";
                } else {
                  if (element.state === "credential_revoked") {
                    element['state'] = "Credential Revoked";
                  }
                }
                // out-of-band
                if (element.connection) {
                  element["connectionName"] = element.connection?.theirLabel;
                } else {
                  element["connectionName"] = "";
                }

              })
              /* Dispatch method to stop the page loader */
              dispatch({
                type: loaderConst.LOADER_FALSE
              })
              /* Method to call API which will get 1st request details. */
              if (credentialList.data.data.data.length > 0) {
                this.showSelectedCredentialDetails(credentialList.data.data.data[0])
              }
              return (
                dispatch({
                  type: myCredential.GET_ALL_CREDENTIAL_OFFER,
                  payload: credentialList.data.data
                })
              )
            }
          })
          .catch(error => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
            throw error
          })
      } catch (error) {
        /* Dispatch method to stop the page loader */
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  /**
   * Method to store selected credential details.
   * @param selectedCredentialDetails Parameter includes the selected credential details. 
   * @returns It returns the stored credential details.
   */
  showSelectedCredentialDetails(selectedCredentialDetails: any) {
    return async (dispatch: any) => {
      try {
        return (
          /* This dispatches method dispatch the stored credential details. */
          dispatch({
            type: myCredential.SHOW_SELECTED_CREDENTIAL_DETAILS,
            payload: selectedCredentialDetails
          })
        )
      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  /**
   * Method to accept credential offer.
   * @param credentialExchangeId This parameter is used for accepting specific credential.
   * @returns It returns the accept offer response.
   */
  acceptCredentialOffer(credentialExchangeId: string) {
    return async (dispatch: any) => {
      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Payload to accept credential offer */
        const payload = {
          credentialExchangeId
        }
        /* API call for accepting credential offer and dispatch the accept credential offer action */
        return await axios.post(`${config.apiUrl}/issue-credential/send-request`, payload, { headers: { "Authorization": `Bearer ${token}` } })
          .then((acceptCredentialResponse) => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Checking the response and then attach the response to payload */
            if (acceptCredentialResponse.data) {
              dispatch({
                type: myCredential.ACCEPT_CREDENTIAL_OFFER,
                payload: acceptCredentialResponse
              })
              // this.showSelectedCredentialDetails(acceptCredentialResponse.data.data)
              /* Payload for get updated credential list */
              const getAllCredentialList: ICredentialOfferList = {
                pageNo: 1,
                itemsPerPage: 10,
                searchText: "",
                sortValue: "",
                sortBy: "",
                filterText: "All"
              }
              /* Get updated credential offer list */
              this.getAllCredentialOfferList(getAllCredentialList)
            }
          })
          .catch((error) => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        /* Dispatch method to stop the page loader */
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  sendProblemReportForCredentialExchange(reason: string, credentialExchangeId: string) {
    return async (dispatch: any) => {

      try {
        /* Get authentication token from local storage */
        let token: string | null = localStorage.getItem('token');
        /* Dispatch method to start the page loader */
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        /* Payload to accept credential offer */
        const payload = {
          reason,
          credentialExchangeId
        }
        /* API call for accepting credential offer and dispatch the accept credential offer action */
        return await axios.post(`${config.apiUrl}/issue-credential/problem-report`, payload,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(async (credentialProblemReportResponse) => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Checking the response and then attach the response to payload */
            if (credentialProblemReportResponse.data) {
              dispatch({
                type: myCredential.CREDENTIAL_PROBLEM_REPORTED,
                payload: credentialProblemReportResponse
              })
              this.showSelectedCredentialDetails(credentialProblemReportResponse.data.data)
              /* Payload for get updated credential list */
              const getAllCredentialList: ICredentialOfferList = {
                pageNo: 1,
                itemsPerPage: 10,
                searchText: "",
                sortValue: "",
                sortBy: "",
                filterText: "All"
              }
              /* Get updated credential offer list */
              this.getAllCredentialOfferList(getAllCredentialList)

            }
          })
          .catch((error) => {
            /* Dispatch method to stop the page loader */
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            /* Catch the error and throw it on frontend */
            loginAction.handleResponse(error.response, dispatch);
          })
      } catch (error) {
        /* Dispatch method to stop the page loader */
        dispatch({
          type: loaderConst.LOADER_FALSE
        })
        /* Handle the error and throw it */
        throw error
      }
    }
  },

  // TODO - not sure we need this method 
  reProposeCredential(selectedCredentialDetails: any) {
    return async (dispatch: any) => {
      try {
        history.push("/credential-proposal", { selectedCredentialDetails });
        return (
          /* This dispatches method dispatch the stored credential details. */
          dispatch({
            type: myCredential.RE_PROPOSE_CREDENTIAL,
            payload: selectedCredentialDetails
          })
        )
      } catch (error) {
        /* Handle the error and throw it */
        throw error
      }
    }
  }
}
