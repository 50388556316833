import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, taskConst } from "../../types";
import { t } from 'i18next';

export const getAllInstantTaskAction = {
  getAllInstantTask(
    defaultSearchFields: any,
    status: any,
    sortingOrder: any,
    instantTaskSortBy: any,
    item_perPage: any,
    pageNo: any,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/verifier/get-all-instant-task?search_text=${defaultSearchFields}&status=${status}&sortValue=${sortingOrder}&instantTaskSort=${instantTaskSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(getAllList => {
            let tmpArray: any = [];
            tmpArray = !_.isEmpty(getAllList) && getAllList.data && getAllList.data.data && getAllList.data.data.data;
            tmpArray.map((ele: any, index: any) => {
              tmpArray[index].proofState = ele.state;
              tmpArray[index].state = `${t(`screens:verificationMonitoring.${ele.state.toLowerCase()}`)}`;
              // out-of-band
              if (ele.connectionId !== null && ele.connection) {
                tmpArray[index].connectionName = ele.connection.holders ? ele.connection.holders.name : ele.connection.theirLabel;
                tmpArray[index].theirDid = ele.connection.theirDid;
              } else {
                tmpArray[index].connectionName = "";
                tmpArray[index].theirDid = "";
              }
            })
            dispatch({
              type: taskConst.GET_ALL_INSTANT_TASK,
              tmpArray,
              payload: getAllList,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  selectedTask(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SHOW_PRESENT_PROOF,
        payload: item,
      })
    }
  },

  closeModal() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.CLOSE_PRESENT_PROOF,
      })
    }
  },

  verifyProof(presentationExchangeId: any, initiator: string) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })
        let token = localStorage.getItem('token');
        return await axios.post(`${config.apiUrl}/verifier/present-proof/records/verify-presentation`, presentationExchangeId,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            dispatch({
              type: taskConst.PRESENT_PROOF_VERIFIED,
              payload: response,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })

            initiator === taskConst.SHOW_INSTANT_TASK_LIST ?
              this.getAllInstantTask('', 'all', 'DESC', '', 10, 1) :
              this.getAllAutomatedProofs('', 'all', 'DESC', '', 10, 1)

            // toastr.success(`${response.data.message}`, ``)
            toastr.success(`${t("screens:verificationMonitoring.messagePresentProofSuc")}`, ``)
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
            loginAction.handleResponse(error.response, dispatch);
            throw error;
          })
      } catch (error) {
        throw error;
      }
    }
  },

  getListByWorkFlow(
    itemsPerPage: any,
    page: any,
    search_text: string,
    scheduleTaskSort: any,
    sortValue: any,
    status: any,
    workFlowId: number
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })

        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/verifier/get-all/schedule-task/${workFlowId}?search_text=${search_text}&status=${status}&sortValue=${sortValue}&scheduleTaskSort=${scheduleTaskSort}&itemsPerPage=${itemsPerPage}&page=${page}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(getAllList => {
            let tmpArray: any = [];
            tmpArray = getAllList.data.data.data
            if (!_.isEmpty(tmpArray)) {
              tmpArray.map(async (element: any, index: any) => {
                const obj: any = {
                  title: element.schedule_task_attributes.hasOwnProperty('title') ? element.schedule_task_attributes.title : '--',
                  startDate: element.schedule_task_attributes.hasOwnProperty('startDate') ? element.schedule_task_attributes.startDate : element.schedule_task_attributes.hasOwnProperty('date') ? element.schedule_task_attributes.date : '--',
                  duration: element.schedule_task_attributes.hasOwnProperty('duration') ? element.schedule_task_attributes.duration : '--',
                  credentialRequired: element.schedule_task_credDefId !== "" ? element.schedule_task_credDefId.split(':')[4] : '--',
                  schedule_task_id: element.schedule_task_id,
                }
                let tableHeadData: any = [];
                tableHeadData.push(obj);
                tmpArray[index].tableHeadData = tableHeadData;
              })
            }
            dispatch({
              type: taskConst.GET_LIST_BY_SELECTED_WORK_FLOW,
              tmpArray,
              workFlowId,
              payload: getAllList,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

  showInstantTaskList() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SHOW_INSTANT_TASK_LIST
      })
    }
  },

  showAutomatedProofs() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SHOW_AUTOMATED_PROOFS
      })
    }
  },

  showDetailsofScheduleTask(item: any) {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SHOW_DETAILS_OF_SCHEDULED_TASK,
        payload: item
      })
    }
  },

  checkVerificationStatus(schedule_task_id: any) {

    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE
        })

        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/verifier/get-all/schedule-task/visitors/${schedule_task_id}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(getAllList => {
            let tmpArray: any = [];
            tmpArray = getAllList.data.data.data
            if (!_.isEmpty(tmpArray)) {
              tmpArray.map(async (element: any, index: any) => {
                // out-of-band
                if (element.connectionId !== null) {
                  tmpArray[index].verificationconnectionId = !_.isEmpty(element.connection) && !_.isEmpty(element.connection.holders) && element.connection.holders.name !== null ? element.connection.holders.name : 'Unknown';
                  tmpArray[index].verificationTime = moment(element.createdAt).format('MM-DD-YYYY');
                  tmpArray[index].verificationStatus = element.state;
                  tmpArray[index].stverified = element.verified;
                } else {
                  tmpArray[index].verificationconnectionId = "";
                  tmpArray[index].verificationTime = "";
                  tmpArray[index].verificationStatus = "";
                  tmpArray[index].stverified = "";
                }
              })
            }
            if (_.isEmpty(getAllList.data.data.data)) {
              // toastr.info(`Currently no Visitors`, ``)
              toastr.info(`${t("screens:verificationMonitoring.messageNoVisitors")}`, ``)
            }
            dispatch({
              type: taskConst.CHECK_VERIFICATION_STATUS,
              // tmpArray,
              payload: getAllList,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }

  },

  backToTable() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.SHOW_NORMAL_TABLE
      })
    }
  },

  clearFormOfVM() {
    return async (dispatch: any) => {
      dispatch({
        type: taskConst.CLEAR_FORM
      })
    }
  },

  getAllAutomatedProofs(
    defaultSearchFields: any,
    status: any,
    sortingOrder: any,
    instantTaskSortBy: any,
    item_perPage: any,
    pageNo: any,
  ) {
    return async (dispatch: any) => {
      try {
        dispatch({
          type: loaderConst.LOADER_TRUE   
        })
        let token = localStorage.getItem('token');
        return await axios.get(`${config.apiUrl}/verifier/present-proofs?search_text=${defaultSearchFields}&status=${status}&sortValue=${sortingOrder}&instantTaskSort=${instantTaskSortBy}&itemsPerPage=${item_perPage}&page=${pageNo}`,
          { headers: { "Authorization": `Bearer ${token}` } })
          .then(getAllAutomatedProofsList => {

            let tmpArray: any = [];
            tmpArray = !_.isEmpty(getAllAutomatedProofsList) && getAllAutomatedProofsList.data && getAllAutomatedProofsList.data.data && getAllAutomatedProofsList.data.data.data;
            tmpArray.map((ele: any, index: any) => {
              tmpArray[index].proofState = ele.state;
              tmpArray[index].state = `${t(`screens:verificationMonitoring.${ele.state.toLowerCase()}`)}`;
              // out-of-band
              if (ele.connectionId !== null && ele.connections) {
                tmpArray[index].connectionName = ele.connections.holders ? ele.connections.holders.name : ele.connections.theirLabel;
                tmpArray[index].theirDid = ele.connections.theirDid;
              } else {
                tmpArray[index].connectionName = ""
                tmpArray[index].theirDid = "";
              }
            })
            dispatch({
              type: taskConst.GET_ALL_AUTOMATED_PRESENTATIONS,
              tmpArray,
              payload: getAllAutomatedProofsList,
            })
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
          .catch(error => {
            dispatch({
              type: loaderConst.LOADER_FALSE
            })
          })
      } catch (error) {
        throw error;
      }
    }
  },

}
