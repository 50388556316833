import * as React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody
} from 'reactstrap';
import _, { isEmpty } from 'lodash';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { getDifference } from 'src/utils/array';
import { t } from 'i18next';
import { offerNegotiateInitiator } from 'src/View/types';

export interface INegotiateProposalModalProps {
  isOpen: any,
  closeModal: any,
  data: any,
  issuanceAction: any,
  credentialState?: string,
  selectedSchema: any,
  handleDeclineProposal: any
}

export interface INegotiateProposalModalState {
  credentialValues: Array<any>,
  isEditForNegotiate: boolean,
  receivedCredentialValues: Array<any>,
  highlightedFieldNames: Array<any>,
  proposedSchemaDetails: any,
  isEditImage: boolean,
  imageErrorIndexes: Array<any>,
  textErrorIndexes: Array<any>
}

export default class NegotiateProposalModal extends React.Component<INegotiateProposalModalProps, INegotiateProposalModalState> {
  constructor(props: INegotiateProposalModalProps) {
    super(props);

    this.state = {
      credentialValues: [],
      isEditForNegotiate: false,
      receivedCredentialValues: [],
      highlightedFieldNames: [],
      proposedSchemaDetails: {},
      isEditImage: false,
      imageErrorIndexes: [],
      textErrorIndexes: [],

    }

    this.handleChange = this.handleChange.bind(this);
    this.handleCredentialValueSubmit = this.handleCredentialValueSubmit.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleEditNegotiate = this.handleEditNegotiate.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleSendOfferNegotiate = this.handleSendOfferNegotiate.bind(this)
    this.handleDeclineProposal = this.handleDeclineProposal.bind(this)
  }

  componentDidUpdate(previousProps: INegotiateProposalModalProps, previousState: any) {

    if (this.props.data && !isEmpty(this.props.data)) {
      let credentialProposalDict: any = !_.isEmpty(this.props.data) && this.props.data.credentialProposalDict;
      let attributes: any = JSON.parse(credentialProposalDict).credential_proposal.attributes;

      if (previousState.receivedCredentialValues.length === 0) {
        this.setState({
          credentialValues: attributes,
          receivedCredentialValues: attributes,
        })
      }
    }
  }

  handleChange(event: React.DOMAttributes<HTMLInputElement> | any, index: any, label: any) {
    const { credentialValues, highlightedFieldNames, textErrorIndexes } = this.state;
    let updateAttribute = credentialValues;
    let formatError = false
    updateAttribute[index].value = event.target.value;

    if (label === 'DD/MM/YYYY' && (!/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(updateAttribute[index].value))) {
      formatError = true
    }

    if (label === 'number' && (!/^[0-9]*$/.test(updateAttribute[index].value))) {
      formatError = true
    }

    if (updateAttribute[index] === '') {
      formatError = false
    }

    if (formatError && !textErrorIndexes.includes(index)) {
      textErrorIndexes.push(index)
    }

    if (!formatError) {
      const indexFound = textErrorIndexes.indexOf(index);
      if (indexFound > -1) {
        textErrorIndexes.splice(indexFound, 1);
      }
    }

    const name = event.target.name;
    this.setState({
      credentialValues: updateAttribute,
      highlightedFieldNames: highlightedFieldNames.includes(name)
        ? highlightedFieldNames
        : [...highlightedFieldNames, name],
      textErrorIndexes: textErrorIndexes
    })
  }

  handleChangeImage(event: React.DOMAttributes<HTMLInputElement> | any, index: any, label: any) {
    const { credentialValues, imageErrorIndexes } = this.state;
    let updateAttribute = credentialValues;
    const iFile = event.target.files

    const indexFound = imageErrorIndexes.indexOf(index);

    if (iFile && iFile.length > 0) {
      let reader = new FileReader();
      const fSize = iFile[0].size
      const file = Math.round((fSize / 1024));

      // The size of the file.
      if (file >= 100) {
        if (indexFound === -1) {
          imageErrorIndexes.push(index)
        }
        updateAttribute[index].value = '';
      }
      else {
        updateAttribute[index].value = event.target.value;
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          updateAttribute[index].value = reader.result
          this.setState({
            credentialValues: updateAttribute,
          })
        };
        if (indexFound > -1) {
          imageErrorIndexes.splice(indexFound, 1);
        }
      }
    }

    this.setState({
      imageErrorIndexes
    })
  }

  handleCredentialValueSubmit() {
    this.props.closeModal()
  }

  handleEditNegotiate() {
    const { credentialValues } = this.state
    const { selectedSchema } = this.props

    if (credentialValues.length !== selectedSchema.attributes.length) {

      selectedSchema?.attributes?.map((attribute: any, index: number) => {

        if (JSON.parse(attribute)?.name !== credentialValues[index]?.name) {
          credentialValues[index] = {
            name: JSON.parse(attribute)?.name,
            value: ''
          }
        }

      })
    }
    this.setState(prevState => ({
      isEditForNegotiate: !prevState.isEditForNegotiate
    }))
  }

  handleModalClose() {
    this.handleEditNegotiate()
    this.setState({
      receivedCredentialValues: [],
      credentialValues: [],
      highlightedFieldNames: [],
      isEditForNegotiate: false,
      isEditImage: false,
    })
    this.props.closeModal()

  }

  handleSendOfferNegotiate() {
    const { credentialValues } = this.state
    let credentialProposalDict = !_.isEmpty(this.props.data) && this.props.data.credentialProposalDict;
    let attributes = JSON.parse(credentialProposalDict).credential_proposal.attributes;
    const arrayDifference = getDifference(credentialValues, attributes)

    const { selectedSchema } = this.props

    let credentialOfferOptions: any = { credentialExchangeId: this.props.data.credentialExchangeId }
    if (arrayDifference.length > 0) {

      let schemaAttribute: any = [];
      selectedSchema.attributes.forEach((ele: any, idx: number) => {
        let jsonObj = {
          "name": JSON.parse(ele).name,
          "value": credentialValues[idx].value === undefined || credentialValues[idx].value === null || credentialValues[idx].value === "" ? " " :
            credentialValues[idx].value,
        }
        if (JSON.parse(ele).value === 'image') {
          Object.assign(jsonObj, { "mime-type": "image/jpeg" })
        }
        schemaAttribute.push(jsonObj);

      });

      credentialOfferOptions.negotiateOffer = {
        counterProposal: {
          attributes: schemaAttribute,
          credDefId: this.props.data.credentialDefinitionId
        }
      }

    }

    this.props.issuanceAction.sendCredentialOfferOrNegotiate(credentialOfferOptions, offerNegotiateInitiator.negotiate);
    this.handleModalClose()
  }

  handleCancel(event: React.DOMAttributes<HTMLInputElement> | any) {
    let credentialProposalDict = !_.isEmpty(this.props.data) && this.props.data.credentialProposalDict;
    let attributes = JSON.parse(credentialProposalDict).credential_proposal.attributes;

    this.setState({
      isEditForNegotiate: false,
      credentialValues: attributes,
      highlightedFieldNames: []
    })

  }

  handleEditImage(event: any) {
    this.setState({ isEditImage: true })
  }

  handleDeclineProposal() {
    this.props.handleDeclineProposal()
  }

  public render() {

    const { isOpen, closeModal, data, selectedSchema } = this.props;
    const { credentialValues, textErrorIndexes, imageErrorIndexes, isEditForNegotiate, highlightedFieldNames, isEditImage } = this.state

    return (
      <div>
        <Modal isOpen={isOpen} size="lg" className="nb-modal" toggle={closeModal}>
          <ModalHeader>
            <h5>{t("screens:issueCredentials.proposalDetails")}</h5>
          </ModalHeader>
          <ModalBody>
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><span className="nb-label">{t("screens:issueCredentials.givenName")}:</span> {data?.connectionName}</li>
              <li className="list-group-item"><span className="nb-label">{t("screens:issueCredentials.credentialName")}:</span> {data?.credentialDefinitionId?.split(':')[4]}</li>

              <li className="list-group-item"><h6 className="mb-3">{t("screens:issueCredentials.attributes")}:</h6>
                <div className="row">
                  {
                    selectedSchema?.attributes?.map((attribute: any, index: any) => {
                      return (
                        <div className="col-md-12 col-lg-12">
                          <div className="form-group" key={index}>
                            <label>{JSON.parse(attribute).name}</label>
                            {
                              JSON.parse(attribute).value !== 'image' ?
                                <input type="text"
                                  name={JSON.parse(attribute).name}
                                  className={`${highlightedFieldNames.includes(JSON.parse(attribute).name) ? 'highlighted-input' : ''} form-control `}
                                  value={_.isEmpty(credentialValues) ? '' : credentialValues[index]?.value}
                                  placeholder={JSON.parse(attribute).value}
                                  onChange={(e) => this.handleChange(e, index, JSON.parse(attribute).value)}
                                  disabled={!isEditForNegotiate}
                                />
                                : <>
                                  {((credentialValues[index]?.value)?.includes('image/png') || (credentialValues[index]?.value)?.includes('image/jpg') ||
                                    (credentialValues[index]?.value)?.includes('image/jpeg')) && !isEditImage ?
                                    <>
                                      <img src={credentialValues[index]?.value} alt={JSON.parse(attribute).name} className="preview mt-2"
                                        height="80" />
                                      {
                                        isEditForNegotiate ?
                                          <button className="btn float-right"
                                            onClick={(e) => this.handleEditImage(e)}
                                            disabled={!isEditForNegotiate}
                                          ><i className="fas fa-minus-circle" />
                                          </button> : <></>
                                      }
                                    </> :
                                    isEditImage ?
                                      <><input type="file" className="form-control" accept="image/png,image/gif,image/jpeg"
                                        placeholder={credentialValues[index]?.value}
                                        onChange={(e) => this.handleChangeImage(e, index, credentialValues[index]?.value)}
                                        disabled={!isEditForNegotiate}
                                      />
                                        {
                                          imageErrorIndexes.includes(index) ?
                                            <small className="text-danger">
                                              {t("screens:issueCredentials.messageImageSize")}
                                            </small> :
                                            <img src={credentialValues[index]?.value ? credentialValues[index]?.value : ''}
                                              alt={JSON.parse(attribute).name}
                                              className="preview mt-2"
                                              height="80"
                                            />
                                        }
                                      </>
                                      : ''
                                  }
                                </>
                            }
                            {
                              textErrorIndexes.includes(index) ?
                                <small className="text-danger">
                                  <b>{JSON.parse(attribute).name}</b> {t("screens:issueCredentials.messageNotMatchFormat")} ({JSON.parse(attribute).value}) {t("screens:issueCredentials.messageSpecifiedFormat")}
                                </small> : null
                            }
                          </div>
                        </div>
                      )
                    })
                  }

                </div>

              </li>
            </ul>
            <div className="row">
              <div className="col-sm-3">
                <button type="button" className={`btn btn-block rounded-pill ${!isEditForNegotiate ? 'btn-outline-secondary' : 'btn-secondary'}`}
                  onClick={this.handleEditNegotiate}
                >
                  {t("screens:issueCredentials.editNegotiate")}
                </button>
              </div>
              <div className="col-sm-2">
                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                  onClick={this.handleSendOfferNegotiate}
                  disabled={textErrorIndexes.length > 0 || imageErrorIndexes.length > 0}
                >
                  {t("screens:issueCredentials.offer")}
                </button>
              </div>
              <div className="col-sm-2">
                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                  onClick={this.handleDeclineProposal}
                >
                  {t("screens:issueCredentials.decline")}
                </button>
              </div>
              <div className="col-sm-2">
                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                  onClick={(e) => this.handleCancel(e)}
                >
                  {t("screens:issueCredentials.cancel")}
                </button>
              </div>
              <div className="col-sm-2">
                <button type="button" className="btn btn-block rounded-pill btn-outline-secondary"
                  onClick={this.handleModalClose}
                >
                  {t("screens:issueCredentials.close")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {}
}

function mapDispatchToProps(dispatch: any) {
  return {}
}

const negotiateProposalModal = connect(mapStateToProps, mapDispatchToProps)(NegotiateProposalModal);
export { negotiateProposalModal as NegotiateProposalModal };

