import axios from "axios";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { loaderConst, presentProof } from "../../types"
import { history } from '../../../index'

const defaultAllListAPIParameter = {
    page: 1,
    itemPerPage: 10,
    searchText: ""
}

export const presentProofAction = {

    /**
     * Method to get present proof request list
     * @param page Parameter for current page no for pagination.
     * @param itemPerPage Parameter for setting the how many number of item required per page.
     * @param searchText This parameter use for searching the content in database.
     * @returns Get all present proof records.
     */
    getAllPresentProofRequest(
        page: number,
        itemPerPage: number,
        searchText: string
    ) {
        return async (dispatch: any) => {
            try {
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Get authentication token from local storage */
                let token = localStorage.getItem('token');
                /* API call to get proof request records and dispatch the get all present proof request list */
                return await axios.get(`${config.apiUrl}/verifier/proof-requests-received?page=${page}&item_per_page=${itemPerPage}&search_text=${searchText}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(getAllList => {
                        /* Add the connection name in response */
                        getAllList.data.data.data.map((element: any) => {
                            // out-of-band
                            if (element.connectionId) {
                                return (element['connectionName'] = element.connection?.theirLabel)
                            } else {
                                return (element['connectionName'] = "")
                            }
                        })
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentProof.GET_ALL_PRESENT_PROOF_REQUEST_LIST,
                            payload: getAllList.data.data
                        })
                        this.showSelectedProofRequestDetails(getAllList.data.data.data[0])
                    }).catch((error: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch)
                    })

            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    /**
     * Method to  get all stored credentials
     * @param page Parameter for current page no for pagination.
     * @param itemPerPage Parameter for setting the how many number of item required per page.
     * @param searchText This parameter use for searching the content in database.
     * @param credentialDefinitionIds Parameter to get credential definition id.
     * @returns Get all stored credentials in wallet.
     */
    getAllStoredCredentials(
        page: number,
        itemPerPage: number,
        searchText: string,
        credentialDefinitionIds: Array<string>
    ) {
        return async (dispatch: any) => {
            try {
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Get authentication token from local storage */
                let token = localStorage.getItem('token');
                /* API call to get all stored credentials and dispatch the response */
                return await axios.get(`${config.apiUrl}/verifier/stored-credentials?credDefId=${credentialDefinitionIds}&page=${page}&item_per_page=${itemPerPage}&search_text=${searchText}`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(getAllAvailableCredentials => {
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentProof.GET_ALL_AVAILABLE_CREDENTIAL_LIST,
                            payload: getAllAvailableCredentials.data.data
                        })
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch)
                        throw error
                    })
            } catch (error) {
                throw error
            }
        }
    },

    /**
     * Method to store the selected proof request details
     * @param selectedProofRequestDetails 
     * @returns 
     */
    showSelectedProofRequestDetails(selectedProofRequestDetails: any) {
        return async (dispatch: any) => {
            try {
                return (
                    dispatch({
                        type: presentProof.SHOW_SELECTED_PROOF_REQUEST_DETAILS,
                        payload: selectedProofRequestDetails
                    })
                )
            } catch (error) {
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    /**
     * Method to store the selected credential details.
     * @param selectedCredentialDetails 
     * @returns It return the stored credential details
     */
    showSelectedCredentialForProofDetails(selectedCredentialForProofDetails: any) {
        return async (dispatch: any) => {
            try {
                return (
                    dispatch({
                        type: presentProof.SHOW_SELECTED_CREDENTIAL_FOR_PROOF_DETAILS,
                        payload: selectedCredentialForProofDetails
                    })
                )

            } catch (error) {
                /* Handle the error and throw it */
                throw error
            }
        }
    },

    /**
     * Method to send the proof presentation.
     * @param proofPresentation Parameter includes the send proof presentation payload
     * @returns It return the response of send proof request API
     */
    sendPresentation(proofPresentation: any) {
        
        return async (dispatch: any) => {
            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                return await axios.post(`${config.apiUrl}/verifier/send-presentation`, proofPresentation, { headers: { "Authorization": `Bearer ${token}` } })
                    .then((proofRequestResponse: any) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentProof.SEND_PROOF_REQUEST,
                            payload: proofRequestResponse.data.data.data
                        })

                        this.getAllPresentProofRequest(defaultAllListAPIParameter.page, defaultAllListAPIParameter.itemPerPage, defaultAllListAPIParameter.searchText)
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                throw error;
            }
        }
    },

    onCloseSuccess() {
        return async (dispatch: any) => {
            try {
                return (
                    dispatch({
                        type: presentProof.CLOSE_SUCCESS,
                    })
                )

            } catch (error) {
                throw error
            }
        }
    },

    fetchCredentialsForPresentationRequest(presentationExchangeId: string) {
        return async (dispatch: any) => {
            try {
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Get authentication token from local storage */
                let token = localStorage.getItem('token');

                return await axios.get(`${config.apiUrl}/verifier/records/${presentationExchangeId}/stored-credentials`,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(credentialsForPresentation => {
                        // console.log('\ncredentialsForPresentation\n', credentialsForPresentation.data)
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        dispatch({
                            type: presentProof.FETCH_CREDENTIALS_FOR_PRESENTATION_REQUEST,
                            payload: credentialsForPresentation.data
                        })
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch)
                        throw error
                    })
            } catch (error) {
                throw error
            }
        }
    },

    sendProblemReportForPresentationExchange(reason: string, presentationExchangeId: string) {
        return async (dispatch: any) => {

            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                /* Payload to accept credential offer */
                const payload = {
                    reason,
                    presentationExchangeId
                }
                /* API call for accepting credential offer and dispatch the accept credential offer action */
                return await axios.post(`${config.apiUrl}/verifier/present-proof/problem-report`, payload,
                    { headers: { "Authorization": `Bearer ${token}` } })
                    .then(async (problemReportResponse) => {

                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })

                        dispatch({
                            type: presentProof.DECLINED_PROOF_REQUEST,
                        })
                        
                        if (problemReportResponse.data) {
                            this.getAllPresentProofRequest(defaultAllListAPIParameter.page, defaultAllListAPIParameter.itemPerPage, defaultAllListAPIParameter.searchText)
                        }
                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                /* Handle the error and throw it */
                throw error
            }
        }
    },

}
