import _ from "lodash";
import { agentStatus } from "../../types";

const initialState = {
    loading: true,
    agentStatusList: [],
    hasNextPage: false,
    hasPreviousPage: false,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    fromPage: 'agentStatusList',
    loader: false,
}

export function AgentStatusReducer(state = initialState, action: any) {
    switch (action.type) {//use
        case agentStatus.GET_ALL_AGENT_STATUS_LIST:
            let temp;
            temp = Object.assign({}, state, {
                agentStatusList: !_.isEmpty(action.tempArray) ? action.tempArray : [],
                hasPreviousPage: action.payload.data.data.hasPreviousPage,
                hasNextPage: action.payload.data.data.hasNextPage,
                lastPage: action.payload.data.data.lastPage,
                nextPage: action.payload.data.data.nextPage,
                totalAgents: action.payload.data.data.totalItems,
                previousPage: action.payload.data.data.previousPage,
                fromPage: action.page,
            })
            return temp;
        default:
            return state
    }
}