import _ from 'lodash';
import * as React from 'react';
import { Modal } from 'react-bootstrap';

// Utilities
import images from 'src/utils/images';
import { t } from 'i18next';

export interface ILoginCredentialModalProps {
  IssuersReducers: any,
  IssuerActions: any,
  issueLoginCredential: any,
  LoginReducer: any
}

export interface ILoginCredentialModalState {
  clearTypeaheadCredDef: boolean,
  clearTypeaheadEmp: boolean,
  clearTypeaheadstate: boolean,
}

export default class LoginCredentialModal extends React.Component<ILoginCredentialModalProps, ILoginCredentialModalState> {
  constructor(props: ILoginCredentialModalProps) {
    super(props);

    this.state = {
      clearTypeaheadCredDef: true,
      clearTypeaheadEmp: true,
      clearTypeaheadstate: true,
    }
    this.addAnotherIssuer = this.addAnotherIssuer.bind(this);
  }
  addAnotherIssuer(event: any) {
    this.props.IssuerActions.addAnotherIssuer();
    this.setState({
      clearTypeaheadCredDef: false,
      clearTypeaheadEmp: false,
      clearTypeaheadstate: false,
    })
  }

  public render() {
    const { addedIssuerVerifier } = this.props.IssuersReducers;
    let name = !_.isEmpty(addedIssuerVerifier) && addedIssuerVerifier.name ? addedIssuerVerifier.name : '';
    const { loggedInUser } = this.props.LoginReducer;
    let subscriptionId = loggedInUser.organization.subscription.id

    return (
      <div>
        <Modal className="nb-modal-right" size="lg" show={this.props.issueLoginCredential}>
          <Modal.Header className="border-bottom-0">
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-4">
              <div className="row">
                <div className="col-sm-12 text-center mb-5">
                  <img alt="Success" src={images.illustrationSuccess} />
                </div>
                <div className="col-sm-12 text-center mb-5">
                  <h5>{t("screens:existingIssuersVerifiers.toastMsgIssueLogin")}</h5>
                </div>
                <div className="col-sm-12 text-center">
                  <button className="btn btn-block btn-nb-outline-primary rounded-pill"
                    onClick={(e) => this.addAnotherIssuer(e)}
                  >{
                      subscriptionId === 1
                        ? t("screens:existingIssuersVerifiers.addAnotherIssuer")
                        : subscriptionId === 2
                          ? t("screens:existingIssuersVerifiers.addAnotherVerifier")
                          :
                          t("screens:existingIssuersVerifiers.addAnotherIssuerVerifier")
                    }
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
