import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListScreenOptions } from 'src/types/common.interface';
import { schemaAction } from 'src/View/Schema/actions/schemaAction';
import TableComponents from '../../../Common Components/Components/TableComponent';
import { loginAction } from '../../Login/actions/loginAction';
import { issuanceAction } from '../actions/issuanceAction';

import NegotiateProposalModal from './NegotiateProposalModal';
import { t } from 'i18next';
import { myCredentialsAction } from 'src/View/MyCredentials/action/myCredentialsAction';
import ProblemReportComponent from 'src/Common Components/Components/ProblemReportModal';

export interface IIssueKycCredentialsProps {
  issuanceAction: any,
  IssuanceReducer: any,
  loginAction: any,
  LoginReducer: any,
  schemaAction: any,
  SchemaReducer: any,
  myCredentialsAction: any
}

export interface IIssueKycCredentialsState {

  showNegotiateProposalModal: boolean,
  showKycDetailsModalData: any,
  KYCCredStatus: any,
  listScreenOptions: ListScreenOptions,
  credentialValues: Array<any>,
  counterProposal?: {
    credDefId?: string
    attributes?: Array<any>
  },
  selectedCredentialRecord: any,
  isModalProblemReportShow: boolean,
  declineReasonList: string[]
}

class NegotiateProposal extends React.Component<IIssueKycCredentialsProps, IIssueKycCredentialsState> {
  constructor(props: IIssueKycCredentialsProps) {
    super(props);

    this.state = {
      showNegotiateProposalModal: false,
      showKycDetailsModalData: {},
      KYCCredStatus: 'all',
      listScreenOptions: {
        search: '',
        itemsPerPage: 10,
        pageNo: 1,
        sortBy: 'id',
        sort: 'DESC',
        filter: 'proposal_received'
      },
      credentialValues: [],
      selectedCredentialRecord: {},
      isModalProblemReportShow: false,
      declineReasonList: [
        `${t("screens:negotiateCredential.insufficientData")}`,
        `${t("screens:negotiateCredential.invalidData")}`
      ],
    }
    this.onChangePage = this.onChangePage.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.sorting = this.sorting.bind(this);
    this.showElementIssue = this.showElementIssue.bind(this);

    this.handleDeclineProposal = this.handleDeclineProposal.bind(this)
    this.handleDeclineModalClose = this.handleDeclineModalClose.bind(this)
    this.handleProblemReportSubmit = this.handleProblemReportSubmit.bind(this)
  }

  componentDidMount() {
    const { listScreenOptions } = this.state;
    const { loggedInUser } = this.props.LoginReducer;
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
    this.props.loginAction.getCurrentUser()

  }

  onChangePage(page: any) {
    const { listScreenOptions } = this.state;
    const { loggedInUser } = this.props.LoginReducer;
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
  }

  showDetails(item: any) {
    this.props.issuanceAction.showNegotiateProposalModal(item)
    this.props.schemaAction.getSchemaById(JSON.parse(item.credentialProposalDict)?.schema_id)

    this.setState({
      selectedCredentialRecord: item
    })
  }

  showElementIssue(item: any) {
    const { loggedInUser } = this.props.LoginReducer;

    if (item.issuebtnkycStatus === 'request_received') {

      let finalData = {
        comment: "string",
        credentialExchangeId: item.credentialExId,
      }
      this.props.issuanceAction.issueCredential(finalData, !_.isEmpty(loggedInUser) && loggedInUser.id);
    }
  }

  onSearch = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    const { listScreenOptions } = this.state;
    const { loggedInUser } = this.props.LoginReducer;

    if (event.key === 'Enter') {
      listScreenOptions.search = event.target.value
      this.setState({ listScreenOptions });
      this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
    }
  }

  sorting = (itemSortBy: string) => {
    const { listScreenOptions } = this.state;
    const { loggedInUser } = this.props.LoginReducer;

    listScreenOptions.sort = (listScreenOptions.sort === 'DESC') ? 'ASC' : 'DESC'
    listScreenOptions.sortBy = itemSortBy
    this.setState({ listScreenOptions })
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
  }

  refresh = () => {
    const listScreenOptions = {
      search: '',
      itemsPerPage: 10,
      pageNo: 1,
      sortBy: 'id',
      sort: 'DESC',
      filter: 'proposal_received'
    }
    const { loggedInUser } = this.props.LoginReducer;

    this.setState({ listScreenOptions })
    this.props.issuanceAction.getAllByOrgId(loggedInUser.organization.id, listScreenOptions)
  }

  closeModal = () => {
    this.props.issuanceAction.closeNegotiateProposalModal()
    this.refresh()
  }

  handleDeclineProposal() {
    this.setState({ isModalProblemReportShow: true })
  }

  handleDeclineModalClose() {

    this.setState({
      isModalProblemReportShow: false,
      showNegotiateProposalModal: false,
    })

    this.props.issuanceAction.closeNegotiateProposalModal()
  }

  handleProblemReportSubmit(reason: string, exchangeId: string) {
    this.props.myCredentialsAction.sendProblemReportForCredentialExchange(reason, exchangeId)
    this.handleDeclineModalClose()
    this.refresh()
  }


  public render() {
    const { credentialRecords, tHeadNegotiate, totalCredentialRecords, selectedCredentialRecord, showNegotiateProposalModal } = this.props.IssuanceReducer;
    const { isModalProblemReportShow, declineReasonList } = this.state
    let selectedSchema;
    if (Object.keys(selectedCredentialRecord).length > 0) {
      selectedSchema = this.props.SchemaReducer.selectedSchema
    }

    return (
      <>
        <div className="row gutters-sm min-h-100">
          <div className="col-md-12 col-lg-12 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <h1 className="nb-title">{t("screens:issueCredentials.negotiationOffer")}</h1>
              </div>
              <div className="card-body">
                <TableComponents
                  onChangePage={this.onChangePage}
                  showElementDetails={this.showDetails}
                  tHead={tHeadNegotiate}
                  tableContent={credentialRecords}
                  totalRecords={totalCredentialRecords}
                  selectedRecord={selectedCredentialRecord}
                  sorting={this.sorting}
                  searching={this.onSearch}
                  refresh={this.refresh}
                  showElementIssue={this.showElementIssue}
                />

              </div>
            </div>
          </div>
          {
            !isModalProblemReportShow && showNegotiateProposalModal ?
              <>
                <NegotiateProposalModal
                  isOpen={showNegotiateProposalModal}
                  closeModal={this.closeModal}
                  data={selectedCredentialRecord}
                  issuanceAction={this.props.issuanceAction}
                  selectedSchema={selectedSchema}
                  handleDeclineProposal={this.handleDeclineProposal}
                />
              </>
              :
              <ProblemReportComponent
                isModalShow={isModalProblemReportShow}
                reasonList={declineReasonList}
                selectedRecordId={selectedCredentialRecord.credentialExchangeId}
                selectedRecordGivenName={selectedCredentialRecord?.connectionName}
                closeModal={this.handleDeclineModalClose}
                handleProblemReportSubmit={this.handleProblemReportSubmit}
                modalTitle={''}
                credentialState={''}
              />
          }

        </div>
      </>

    );
  }
}
function mapStateToProps(state: any) {
  const IssuanceReducer = state.IssuanceReducer;
  const LoginReducer = state.LoginReducer;
  const SchemaReducer = state.SchemaReducer
  const MyCredentialReducer = state.MyCredentialReducer;
  return { IssuanceReducer, LoginReducer, SchemaReducer, MyCredentialReducer }
}

function mapDispatchToProps(dispatch: any) {
  return {
    issuanceAction: bindActionCreators(issuanceAction, dispatch),
    loginAction: bindActionCreators(loginAction, dispatch),
    schemaAction: bindActionCreators(schemaAction, dispatch),
    myCredentialsAction: bindActionCreators(myCredentialsAction, dispatch),
  }
}

const negotiateProposal = connect(mapStateToProps, mapDispatchToProps)(NegotiateProposal);
export { negotiateProposal as NegotiateProposal };
