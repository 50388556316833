import moment from 'moment';
import { NavDropdown } from 'react-bootstrap'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import './Header.css'
import _ from 'lodash';
import { history } from '../../..';

// Utilities
import images from 'src/utils/images';
import { socket, SocketContext } from 'src/contexts/SocketContext';


export interface IHeaderProps {
  openSidebarHandler?: any,
  loggedInUser: any,
  totalUnreadNotifications: any
}

const Header = (props: IHeaderProps) => {
  const { t } = useTranslation(["screens"]);

  const socket = useContext(SocketContext)

  let currentDate = new Date();

  let navDropdownIcon = (
    <img src={!_.isEmpty(props.loggedInUser) && props.loggedInUser.profileLogo !== null ? props.loggedInUser.profileLogo : images.placeholderProfile} className="user-image" />
  )
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
      {/* <form className="form-inline my-2">
        <div className="">
          <a href="#" className="btn btn-link sidebarButton" onClick={props.openSidebarHandler}><i className="fas fa-bars"></i></a>
        </div>
      </form> */}
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item mr-3">
            <a className="nav-link" href="#" aria-disabled="true">
              <span>{moment(currentDate).format('MMMM DD, YYYY')}</span>
            </a>
          </li>
          <NavDropdown title={navDropdownIcon} className="dropdown-toggle-icon-none mr-3" id="basic-nav-dropdown">
            {!_.isEmpty(props.loggedInUser) && props.loggedInUser.role.id !== 1 &&
              <>
                <NavDropdown.Item onClick={(e) => profileSetting(e)}>{t("screens:headerMenu.listItemProfile")}</NavDropdown.Item>
                <NavDropdown.Divider />
              </>
            }
            <>
              <NavDropdown.Item href='https://northern-block.gitbook.io/nb-orbit-product-documentation/getting-started/introduction'
                target='_blank'>{t("screens:headerMenu.listItemDocumentation")}</NavDropdown.Item>
              <NavDropdown.Divider />
            </>
            {!_.isEmpty(props.loggedInUser) && props.loggedInUser.role.id === 1 ?
              <NavDropdown.Item onClick={(e) => logutPAdmin(e)}>{t("screens:headerMenu.listItemLogout")}</NavDropdown.Item>
              :
              <NavDropdown.Item onClick={(e) => props.loggedInUser.isSecondLogin ? logutUserauthenticate(e) : logutUser(e)}>{t("screens:headerMenu.listItemLogout")}</NavDropdown.Item>
            }
          </NavDropdown>
          <li className="nav-item mr-3">
            <a className="nav-link position-relative" href="#" aria-disabled="true">
              <button type="button" className="btn btn-sm btn-nb-outline-primary" onClick={(e) => notifications(e)}>
                <i className="nb-ico nb-notification mr-1" />{props.totalUnreadNotifications !== undefined ? props.totalUnreadNotifications : 0}
              </button>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

const profileSetting = (event: any) => {
  history.push('/profile-settings')
}

const logutPAdmin = (event: any) => {
  localStorage.clear();
  history.push('/palogin')
}

const logutUser = (event: any) => {
  socket.disconnect()
  localStorage.clear();
  history.push('/first-time-user')
  // SOCKET GET NEW SOCKET TEST
  // history.replace('/first-time-user')
  // location.reload()
  // history.go(1)
}

const logutUserauthenticate = (event: any) => {
  socket.disconnect()
  localStorage.clear();
  history.push('/authenticate')
}

const notifications = (event: any) => {
  history.push('/notification')
}


export default Header;