import axios from "axios";
import { issuanceAction } from "../../IssueCredential/actions/issuanceAction";
import { config } from "../../../config";
import { loginAction } from "../../Login/actions/loginAction";
import { credentialProposal, loaderConst } from "../../types"
import { CredentialState } from "src/View/commonConst";
import { NegotiateCredentialAction } from "src/View/CredentialNegotiate/actions/NegotiateCredentialAction";
import store from "src/store";

export const credentialProposalAction = {

    createNewProposal() {
        return async (dispatch: any) => {
            dispatch({
                type: credentialProposal.CREATE_NEW_PROPOSAL,
            })
        }
    },

    clearCredentialProposal() {
        return async (dispatch: any) => {
            dispatch({
                type: credentialProposal.CLEAR_CREDENTIAL_PROPOSAL,
            })
            dispatch({
                type: credentialProposal.CLEAR_SCHEMA
            })
        }
    },

    sendProposal(proposalOptions: any) {
        return async (dispatch: any) => {
            try {
                /* Get authentication token from local storage */
                let token: string | null = localStorage.getItem('token');
                /* Dispatch method to start the page loader */
                dispatch({
                    type: loaderConst.LOADER_TRUE
                })
                let connectionName = proposalOptions.selectedConnection
                return await axios.post(`${config.apiUrl}/issue-credential/send-proposal`, proposalOptions, {
                    headers: { "Authorization": `Bearer ${token}` }
                })
                    .then(async (credentialProposalResponse: any) => {
                        dispatch({
                            type: credentialProposal.PROPOSED_CREDENTIAL,
                            payload: credentialProposalResponse,
                            connectionName
                        })
                        dispatch({
                            type: credentialProposal.SUCCESS_PROPOSE_CREDENTIAL
                        })
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })

                        const listScreenOptions = {
                            search: '',
                            itemsPerPage: 10,
                            pageNo: 1,
                            sortBy: 'id',
                            sort: 'DESC',
                            filter: CredentialState.STATE_PROPOSAL_SENT
                          }

                        dispatch(issuanceAction.getAllCredentialRequest(listScreenOptions))
                       
                        const negotiateScreenOptions = {
                            search: '',
                            itemsPerPage: 10,
                            pageNo: 1,
                            sortBy: 'id',
                            sort: 'DESC',
                            filter: 'all'
                        }
                        const { organization } = await store.getState().LoginReducer.loggedInUser

                        dispatch(NegotiateCredentialAction.getAllByOrgId(organization.id , negotiateScreenOptions))

                    })
                    .catch((error) => {
                        /* Dispatch method to stop the page loader */
                        dispatch({
                            type: loaderConst.LOADER_FALSE
                        })
                        /* Catch the error and throw it on frontend */
                        loginAction.handleResponse(error.response, dispatch);
                    })
            } catch (error) {
                /* Dispatch method to stop the page loader */
                dispatch({
                    type: loaderConst.LOADER_FALSE
                })
                throw error;
            }
        }
    },

    requestCredentialComponent() {
        return async (dispatch: any) => {
            try {
                dispatch({
                    type: credentialProposal.CREATE_ANOTHER_CREDENTIAL_PROPOSAL,
                })
            } catch (error) {
                throw error;
            }
        }
    },
}
