import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addNewImportCredentialRecord } from '../../commonConst';
import { importCredentialsAction } from '../actions/ImportCredentialAction';

// Utilities
import images from 'src/utils/images';

export interface IImportCredentialsSuccessProps {
    importCredentialsAction: any
}

export interface IImportCredentialsSuccessState {
}

class ImportCredentialsSuccess extends React.Component<IImportCredentialsSuccessProps, IImportCredentialsSuccessState> {
    constructor(props: IImportCredentialsSuccessProps) {
        super(props);

        this.state = {
        }
        this.importAnotherCredential = this.importAnotherCredential.bind(this)
    }

    importAnotherCredential() {
        this.props.importCredentialsAction.importAnotherCredential()
    }

    public render() {
        return (
            <>
                <div className="card h-100">
                    <div className="card-body text-center">
                        <div className="row">
                            <div className="col-sm-12 mb-5 mt-5">
                                <img src={images.illustrationSuccess} alt="Success" />
                            </div>
                            <div className="col-sm-12">
                                <h3 className="nb-label">{addNewImportCredentialRecord.SUCCESS_MESSAGE}</h3>
                            </div>
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-block rounded-pill btn-nb-outline-primary"
                                    onClick={this.importAnotherCredential}
                                >
                                    {addNewImportCredentialRecord.IMPORT_ANOTHER_CREDENTIAL_BUTTON}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}


function mapStateToProps(state: any) {
    return {
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        importCredentialsAction: bindActionCreators(importCredentialsAction, dispatch),
    }
}

const connectedImportCredentialsSuccess = connect(mapStateToProps, mapDispatchToProps)(ImportCredentialsSuccess);
export { connectedImportCredentialsSuccess as ImportCredentialsSuccess };
