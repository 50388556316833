import _ from 'lodash';
import * as React from 'react';

// Utilities
import { t } from 'i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import NoRecordFoundComponent from 'src/Common Components/Components/NoRecordFound';

let QRCode = require('qrcode.react');

export interface IExistingConnectionMultiuseProps {
  ConnectionReducer: any,
  connectionAction: any,
  selectedConnection: any
}

export interface IExistingConnectionMultiuseState {
  isCredentialSelected: boolean,
  count: number,
  isMoreSelected: boolean,
  isReceivedCredentialSelected: boolean,
  copiedQR: boolean
}

export default class ExistingConnectionMultiuse extends React.Component<IExistingConnectionMultiuseProps, IExistingConnectionMultiuseState> {
  constructor(props: IExistingConnectionMultiuseProps) {
    super(props);

    this.state = {
      isCredentialSelected: false,
      count: 4,
      isMoreSelected: false,
      isReceivedCredentialSelected: false,
      copiedQR: false
    }
  }


  componentDidMount() {

    const { selectedConnection } = this.props.ConnectionReducer
    // if (!_.isEmpty(selectedConnection)) {
    //   this.props.connectionAction.getAllIssueCredentialByConnectionId(selectedConnection.connectionId, '', '', '', 0, 0, '')
    // }
  }

  deleteConnection(e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any, selectedConnection: any): void {
    this.props.connectionAction.deleteConnection(selectedConnection.connectionId);
  }

  selectedReceivedCredentialRecord = async (credentialExchangeId: any) => {
    this.props.connectionAction.selectedReceivedCredentialRecord(credentialExchangeId)
    this.setState({
      isCredentialSelected: false,
      isReceivedCredentialSelected: true
    })
  }

  onCopiedQRCode(value: any) {
    switch (value) {
      case 'copiedQR':
        return this.setState({ copiedQR: true })
      default:
        return null
    }
  }

  onAfterCopiedQRCode = (event: React.MouseEvent<HTMLButtonElement> | any) => {
    setTimeout(() => {
      this.setState({
        copiedQR: false,
      });
    }, 1000);
    event.preventDefault();
  }

  public render() {
    const { selectedConnection } = this.props.ConnectionReducer;
    const qrData = (!_.isEmpty(selectedConnection) && (selectedConnection.holders) && (selectedConnection.holders.connectionInvitation))
    const { copiedQR } = this.state;

    return (
      <>
        <div className="card nb-card h-90vh">
          <div className="card-header bg-white border-bottom-0">
            <h2 className="nb-title">{t("screens:existingConnections.connectionDetails")}</h2>
          </div>

            <div className="card-body">
              <div className="list-group list-group-flush mb-3 ml-4">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="nb-label">{t("screens:existingConnections.invitationName")}</label>
                    <div>{selectedConnection.theirLabel ? selectedConnection.theirLabel : 'N/A'}</div>
                  </div>
                  <div className="col-sm-6">
                    <label className="nb-label">{t("screens:existingConnections.usedBy")}</label>
                    <div>{selectedConnection.type === '6' ? 'Client' : 'Employee'}</div>
                  </div>
                </div>
              </div>
              <div className="list-group list-group-flush mb-3 ml-4">
                <div className="row">
                  <div className="col-sm-12  mt-5">
                    <label className="nb-label">{t("screens:existingConnections.invitationDescription")}</label>
                    <div className="mb-5">{selectedConnection.invitationDescription ? selectedConnection.invitationDescription : ' '}</div>
                  </div>
                </div>
                <div className="form-group row">
                  {qrData &&
                    <div className="col-sm-6 text-right">
                      <QRCode value={qrData} />
                    </div>
                  }
                  <div className="col-sm-6">
                    <label className="nb-label m-b-20">{t("screens:existingConnections.copyInvitation")}</label>
                    <CopyToClipboard
                      text={qrData}
                      onCopy={() => this.onCopiedQRCode('copiedQR')}
                    >
                      <div className="col-sm-6 align-self-center">
                        <button className="btn btn-sm btn-nb-outline-primary" type="button" onClick={this.onAfterCopiedQRCode}>
                          {copiedQR ? (
                            <span className="text-success">{t("screens:invitationMultiuse.copied")}</span>
                          ) : <i className="far fa-copy" />}
                          {copiedQR ? '' : ` ${t("screens:invitationMultiuse.copy")}`
                          }
                        </button>
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}