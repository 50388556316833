import * as React from 'react';

export default class ContentLoader extends React.Component {

  public render() {
    return (
      <div className="content-loader justify-content-center h-100 pt-4">
        <span className="mt-7 align-self-start">
          <i className="fas fa-3x fa-circle-notch fa-spin"></i>
        </span>
      </div>
    );
  }
}
