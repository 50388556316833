import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";

export interface ITypeaheadProps {
  data: any,
  onChange: any,
  onInputChange?: any,
  clearTypeahead: any,
  placeholder: string,
  disabled: boolean,
  onMultiple?: any,
  defaultSelected?: any,
  selectedValue?: any,
  onKeyDown?: any,
  idx?: any,
}

export interface ITypeaheadState {
}

interface ICredDefRefs {
  _typeahead?: HTMLInputElement | any;
}

export default class TypeaheadComponent extends React.Component<ITypeaheadProps, ITypeaheadState> {
  private _typeahead: React.RefObject<HTMLInputElement> | any;
  constructor(props: ITypeaheadProps) {
    super(props);

    this.state = {}
    this._typeahead = React.createRef();
  }

  clearTypeahead = () => {
    this._typeahead.clear();
  }

  public render() {
    const { data, onChange, onInputChange, clearTypeahead, placeholder, disabled, onMultiple,
      defaultSelected, selectedValue, onKeyDown, idx } = this.props;
    if (clearTypeahead && this._typeahead.props) {
      this._typeahead.clear();
    }
    return (
      <div>
        <Typeahead
          id={!idx ? `selectCredentialName-${idx}` : `${idx}`}
          labelKey={(option: any) => `${option.connectionId ? `${option.holderName} (${option.theirDid})` :
            option.credentialDefinitionId ? (option.credentialDefinitionId).split(':')[4] :
                option.orgName ? `${option.orgName}: (${option.displayName})` :
                  option.name ? option.name :
                    option.schemaName ? option.schemaName :
                      option.tag ? option.tag :
                        option.state ? option.state :
                          option
            }`}

          options={data}
          onChange={onChange}
          ref={(ref) => this._typeahead = ref}
          onInputChange={onInputChange}
          paginate={true}
          paginationText="more..."
          maxHeight='300px'
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          disabled={disabled}
          multiple={onMultiple}
          defaultSelected={defaultSelected}
          selected={selectedValue}
        />
      </div>
    );
  }
}
