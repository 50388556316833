import _ from 'lodash';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { t } from 'i18next';

export interface ISearchInputProps {
  search: Function | any,
  filter?: Function | any,
  refresh?: Function | any,
  filterChange?: Function | any,
  isNewMessageReceived?: boolean,
  showTotalRecords?: boolean,
  totalRecords?: number
}

export interface ISearchInputState {
  showFilter: boolean,
}

export default class SearchInput extends React.Component<ISearchInputProps, ISearchInputState> {
  constructor(props: ISearchInputProps) {
    super(props);

    this.state = {
      showFilter: false,
    }
    this.showFilter = this.showFilter.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
  }


  showFilter(event: any) {
    const { showFilter } = this.state;
    this.setState({
      showFilter: !showFilter
    })
  }

  filterChanged(filterValue: any) {
    this.props.filterChange(filterValue)
  }

  public render() {
    const { filter, isNewMessageReceived, showTotalRecords, totalRecords } = this.props;
    return (
      <>
        <div className="row mb-3">
          <div className="col-sm-7">
            <div className="input-group global-search rounded-pill" style={{ backgroundColor: '#f8f9fb' }}>
              <div className="input-group-prepend">
                <span className="input-group-text border-0 bg-transparent pr-0" id="basic-addon1"><i className="nb-ico nb-search"></i></span>
              </div>
              <input type="text" className="form-control border-0 shadow-none nb-search-bar-bg"
                placeholder={t("screens:searchInput.searching")} aria-label="Search" aria-describedby="basic-addon1" onKeyDown={this.props.search} />
            </div>
          </div>
          <div className="col-sm-5 text-right">
            <div className="d-flex float-right">
              {
                this.props.refresh &&
                <button type="button" className="btn btn-light"
                  onClick={(e) => this.props.refresh(e)}
                ><i className="nb-ico nb-refresh"></i>{isNewMessageReceived ? <><span className='dot'></span></> : null}</button>
              }
              {showTotalRecords ? <div className=''><label className="nb-label">{t("screens:searchInput.total")}:{totalRecords}</label></div> : <></>}
              {
                this.props.filter &&
                <>
                  <Dropdown className="ml-3" drop="left">
                    <Dropdown.Toggle className="btn btn-light" id="dropdown-custom-1">
                    {t("screens:searchInput.filter")}
                      <i className="nb-ico nb-filter ml-3"></i></Dropdown.Toggle>
                    <Dropdown.Menu
                      id="dropdown-basic-button"
                      title="Dropdown button">
                      {
                        !_.isEmpty(filter) ?
                          filter.map((filtervalue: any, idx: any) => {
                            return (
                              <Dropdown.Item onClick={() => this.filterChanged(filtervalue.tag)}>{filtervalue.name}</Dropdown.Item>
                            )
                          })
                          :
                          <a className="dropdown-item" href="#">{t("screens:searchInput.noFilter")}</a>
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}
