import { t } from "i18next"
import _ from "lodash"
import DisplayCredentialCommentComponent from "src/Common Components/Components/Comments/DisplayCredentialComment"
import DisplayVPCredentialCommentComponent from "src/Common Components/Components/Comments/VerifiedPerson/DisplayCredentialComment"
import { CommentsCredential } from "src/Common Components/Components/Comments/enums"
import { SchemaDateSuffix } from "src/View/Schema/models/schema.enum"
import { displayDateFormat } from "src/utils/date.utils"
import { isJSON } from "src/utils/json"


export const CredentialDetails = ({ selectedCredentialDetails, revokeCredential }: any) => {

    const selectedSchemaAttributes: any[] = []

    const credentialProposalDictObj = !_.isEmpty(selectedCredentialDetails) && JSON.parse(selectedCredentialDetails.credentialProposalDict)

    const attributes = !_.isEmpty(selectedCredentialDetails) && credentialProposalDictObj.credential_proposal.attributes;

    const rawSelectedSchemaAttributes = !_.isEmpty(selectedCredentialDetails) && Object.keys(selectedCredentialDetails.credentialDefinitions).length &&
        selectedCredentialDetails.credentialDefinitions.schema.attributes

    rawSelectedSchemaAttributes && rawSelectedSchemaAttributes.map((label: string) => {
        selectedSchemaAttributes.push(JSON.parse(label))
    })

    return (
        <>
            <div className="card nb-card h-100">
                <div className="card-header bg-white border-bottom-0">
                    <h1 className="nb-title">{t("screens:revokeCredential.credentialDetails")}</h1>
                </div>
                {!_.isEmpty(selectedCredentialDetails) &&
                    <>
                        <div className="card-body">
                            <div className="mb-5">
                                <ul className="nb-list">
                                    <li className="pl-1 pr-0">
                                        <div>
                                            <label className="font-weight-bold">{t("screens:revokeCredential.credentialName")}: </label>
                                            <label className="font-weight-bold">{!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails?.credentialDefinitionId?.split(':')[4]} </label>
                                        </div>
                                    </li>
                                    <li className="pl-1 pr-0">
                                        <div>
                                            <label className="font-weight-bold">{t("screens:revokeCredential.givenName")}: </label>
                                            <label className="font-weight-bold">{!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.connectionName}</label>
                                        </div>
                                    </li>
                                    <li className="pl-1 pr-0 ">
                                        <>
                                            <label className="font-weight-bold">{t("screens:revokeCredential.credentialValues")}: </label>
                                            <ul>
                                                {selectedCredentialDetails && attributes.map((item: any, index: any) => {
                                                    const foundIndex = selectedSchemaAttributes.findIndex((x: any) => x.name === item.name)

                                                    if (!item.hasOwnProperty("mime-type")) {
                                                        return <li key={index} className="remove-underline">
                                                            <label className="font-weight-bold">{item.name} ({(selectedSchemaAttributes[foundIndex].value).toUpperCase()}): {(item.name).includes(SchemaDateSuffix.DATE_SUFFIX)
                                                                ? displayDateFormat(item.value)
                                                                : item.value}</label></li>
                                                    } else {
                                                        return <>
                                                            <li key={index} className="remove-underline">
                                                                <label className="font-weight-bold">{item.name} ({(selectedSchemaAttributes[foundIndex].value).toUpperCase()}): <img src={item.value} alt={item.name} className="preview" height="50"></img></label>
                                                            </li>
                                                        </>
                                                    }
                                                })}
                                            </ul>
                                        </>
                                    </li>
                                    <li className="pl-1 pr-0 ">
                                        <label className="font-weight-bold">{t("screens:negotiateCredential.commentTitle")}: </label>
                                        {
                                            isJSON(credentialProposalDictObj.comment)
                                                ? credentialProposalDictObj?.schema_id?.split(':')[2] === CommentsCredential.VERIFIED_PERSON
                                                    ? <DisplayVPCredentialCommentComponent data={JSON.parse(credentialProposalDictObj.comment)} />
                                                    : <DisplayCredentialCommentComponent data={(JSON.parse(credentialProposalDictObj.comment))} />
                                                : <p>{credentialProposalDictObj.comment}</p>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <>
                                {!_.isEmpty(selectedCredentialDetails) && selectedCredentialDetails.credentialDefinitions.supportRevocation &&
                                    <>
                                        <button type="button"
                                            // className="btn btn-nb-outline-primary rounded-pill"
                                            className={`btn rounded-pill ${selectedCredentialDetails.isRevoked ? 'btn-outline-secondary' : 'btn-nb-outline-primary'}`}
                                            onClick={() => revokeCredential()}
                                            disabled={selectedCredentialDetails.isRevoked}
                                        >
                                            {t("screens:revokeCredential.revokeCredential")}
                                        </button>
                                    </>
                                }
                            </>
                        </div>

                    </>
                }
                {_.isEmpty(selectedCredentialDetails) &&
                    <>
                        <div className="card-body">
                            <div className="blank-credential-details-body">
                                <label className="">{t("screens:revokeCredential.noCredentialsFound")}</label>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
