import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GovRuleList from '../components/GovRuleList';
import { GovRuleAction } from '../action/GovRuleAction';
import AddNewRule from '../components/GovRuleDetails';

// Utilities
import images from 'src/utils/images';

export interface IGovRuleListProps {
  GovRuleAction: any,
  GovRuleReducer: any,
}
export interface IGovRuleListState {
  defaultSearchFields: any,
  item_perPage: number,
  pageNo: number,
  sortValue: string,
  governanceSortBy: string,
  filterValue:any,
}

class GovRuleComponent extends React.Component<IGovRuleListProps, IGovRuleListState> {
  constructor(props: IGovRuleListProps) {
    super(props);

    this.state = {
      defaultSearchFields: '',
      item_perPage: 10,
      pageNo: 1,
      sortValue: 'DESC',
      governanceSortBy: '',
      filterValue:'id',
    }
    this.createNewRule = this.createNewRule.bind(this);

  }

  componentDidMount() {
    const { defaultSearchFields, item_perPage, pageNo, sortValue, governanceSortBy } = this.state;
    this.props.GovRuleAction.getAllRuleList(defaultSearchFields,sortValue, governanceSortBy,item_perPage, pageNo,);
  }
  createNewRule() {
    this.props.GovRuleAction.clearSelectedRule()
  }
  public render() {
    const {
      modalShow,
    } = this.props.GovRuleReducer;
    return (
      <>
        <div className="row gutters-sm min-h-100 animated fadeIn">
          <div className="col-md-12 col-lg-5 mb-30">
            <div className="card nb-card h-100">
              <div className="card-header bg-white border-bottom-0">
                <div className="card-title m-0">
                  <h1 className="nb-title">Rule list</h1>
                </div>
              </div>
              <div className="card-body">
                <GovRuleList {...this.props} />
              </div>
            </div>
          </div>
          {!modalShow ?

            <div className="col-md-12 col-lg-7 mb-30">
              <AddNewRule {...this.props} />
            </div>
            :
            <div className="col-md-12 col-lg-7 mb-30">
              <div className="card ng-card h-100">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 mb-5">
                      <div className="text-center">
                        <img alt="Add success" src={images.illustrationAddSuccess} className="img img-fluid" />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button className="btn btn-nb-outline-primary btn-block rounded-pill"
                        onClick={this.createNewRule}>
                        CREATE ANOTHER RULE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const GovRuleReducer = state.GovRuleReducer;
  const LoaderReducer = state.LoaderReducer
  return {
    GovRuleReducer,
    LoaderReducer,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    GovRuleAction: bindActionCreators(GovRuleAction, dispatch),
  }
}

const govRuleComponent = connect(mapStateToProps, mapDispatchToProps)(GovRuleComponent);
export { govRuleComponent as GovRuleComponent };
