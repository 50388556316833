import { onLedgerList } from "../../types";
import { tHeadOnLedgerList } from "../constants";

const initialState = {
  onLedgerList: [],
  tHead: [],
  stopOrganizationResponse: {},
  isOrganizationStop: false,
  totalOrganizationRecords: 0
}

export function OnLedgerListReducer(state = initialState, action: any) {
  switch (action.type) {
    case onLedgerList.GET_ALL_ONBOARD_ORGANIZATION_WITH_STATUS:
      return Object.assign({}, state, {
        onLedgerList: action.OnboardingList,
        tHead: tHeadOnLedgerList,
        totalOrganizationRecords: action.payload.data.data.totalItems,
      })
    case onLedgerList.STOP_ORGANIZATION:
      return Object.assign({}, state, {
        stopOrganizationResponse: action.payload,
        isOrganizationStop: true
      })

    default:
      return state;
  }
}